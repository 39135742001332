<app-mid-details
    [isCreate]="isCreate"
    [feePlans]="feePlans$ | async"
    [feePlansLoading]="feePlansLoading$ | async"
    [merchant]="merchant$ | async"
    [merchantLoading]="merchantLoading$ | async"
    [mid]="mid$ | async"
    [midLoading]="midLoading$ | async"
    [serviceFeeMids]="serviceFeeMids$ | async"
    [serviceFeeMidsLoading]="serviceFeeMidsLoading$ | async"
    [associatedMids]="associatedMids$ | async"
    [associatedMidsLoading]="associatedMidsLoading$ | async"
    [associatedMidsError]="associatedMidsError$ | async"
    [associatedTids]="associatedTids$ | async"
    [associatedTidsLoading]="associatedTidsLoading$ | async"
    [associatedTidsError]="associatedTidsError$ | async"
    [editMidError]="editMidError$ | async"
    [accounts]="accounts$ | async"
    [accountsLoading]="accountsLoading$ | async"
    (deleteMid)="deleteMid($event)"
    (updateMid)="updateMid($event)"
    (createMid)="createMid($event)"
    (back)="onBack()"
    (connectClover)="connectClover($event)">
</app-mid-details>
