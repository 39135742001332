<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="PayPalEventDeliveryOrderByEnum.Id">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PayPalEventDeliveryOrderByEnum.EventType">
        <lib-text-table-column>
            {{ element.eventType }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PayPalEventDeliveryOrderByEnum.CreateTime">
        <lib-date-time-label [dateUtc]="element.createTime"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'summary'">
        <lib-text-table-column>
            {{ element.summary }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <lib-status-chip
            class="paypal-event-statuses"
            [status]="element.status">
        </lib-status-chip>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





