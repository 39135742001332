import { IStore } from "@admin_app/storage/store";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import * as GooglePayKeysActions from "@admin_app/storage/google-pay-keys/google-pay-keys.actions";
import * as GooglePayKeysSelectors from "@admin_app/storage/google-pay-keys/google-pay-keys.selectors";

import * as GooglePayKeyActions from "@admin_app/storage/google-pay-key/google-pay-key.actions";

@Component({
    selector: "app-google-pay",
    templateUrl: "./google-pay.component.html",
    styleUrls: ["./google-pay.component.less"],
})
export class GooglePayComponent implements OnInit {

    googlePayKeys$ = this.store.select(GooglePayKeysSelectors.getGooglePayKeys);
    googlePayKeysLoading$ = this.store.select(GooglePayKeysSelectors.getGooglePayKeysLoading);
    googlePayKeysError$ = this.store.select(GooglePayKeysSelectors.getGooglePayKeysError);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new GooglePayKeysActions.GetPaymentProcessingCertificatesAction());
    }

    onAddNewKey() {
        this.store.dispatch(new GooglePayKeyActions.CreateNewKeyAction());
    }

    onRevoke(token: string) {
        this.store.dispatch(new GooglePayKeyActions.RevokePaymentProcessingCertificateKeyAction(token));
    }

    onRegister(token: string) {
        this.store.dispatch(new GooglePayKeyActions.RegisterKeyAction(token));
    }

    onDownload(token: string) {
        this.store.dispatch(new GooglePayKeyActions.GetPublicKeyFileAction(token));
    }

    onGetPublicKeyHash(token: string) {
        this.store.dispatch(new GooglePayKeyActions.GetPublicKeyHashAction(token));
    }

    onMakePrimary(token: string) {
        this.store.dispatch(new GooglePayKeyActions.MakeKeyPrimaryAction(token));
    }

}
