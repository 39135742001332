import { Component, Input } from "@angular/core";

@Component({
    selector: "app-tids-table-cell-content",
    templateUrl: "./tids-table-cell-content.component.html",
    styleUrls: ["./tids-table-cell-content.component.less"],
})
export class TidsTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;
    @Input() showingDeletedTids: boolean;

    get sortingKeyForDateDeleted(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.deletedUtc).getTime();
    }

}
