import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, withLatestFrom } from "rxjs/operators";
import {
    ActionTypes,
    PageChangedMerchantHistoryAction,
    GetMerchantHistoryAction,
    GetMerchantHistorySucceedAction,
    SortMerchantHistoryAction,
    FilterMerchantHistoryAction,
    GetMerchantHistoryFailAction
} from "./merchant-history.actions";
import { of } from "rxjs";
import { Filter, Sorting } from "./merchant-history.state";
import { getMerchantHistoryState } from "./merchant-history.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { LocalTimePoint } from "shared-lib";
import { MerchantsService } from "@admin_api/services/merchants.service";

@Injectable()
export class MerchantHistoryEffects {

    constructor(
        private actions$: Actions,
        private merchantsService: MerchantsService,
        private store: Store<IStore>
    ) {}

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortMerchantHistoryAction>(ActionTypes.SortMerchantHistory),
            switchMap(action => of(new GetMerchantHistoryAction(action.id, 1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterMerchantHistoryAction>(ActionTypes.FilterMerchantHistory),
            switchMap(action => of(new GetMerchantHistoryAction(action.id, 1, undefined, action.filter, undefined)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedMerchantHistoryAction>(ActionTypes.PageChangedMerchantHistory),
            switchMap(action => of(new GetMerchantHistoryAction(action.id, action.page)))
        ),
    );

    getMerchantHistory = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMerchantHistoryAction>(ActionTypes.GetMerchantHistory),
            withLatestFrom(this.store.select(getMerchantHistoryState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(action.id, sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.merchantsService.merchantChangesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetMerchantHistorySucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) => of(new GetMerchantHistoryFailAction(error)))
                );
            })
        ),
    );

    private getParams(merchantId: number, sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            merchantId,
            ...(
                sorting.orderDirection ? { OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection } : {}
            ),
            TID: filter.tid,
            MID: filter.mid,
            UserEmail: filter.userEmail,
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
