<div class="outer-container" (mousedown)="onMouseDown()">

    <div mat-dialog-title>
        <div>Request Settlement</div>
        <i class="fal fa-times global-dialog-close-button" (mousedown)="onCancel($event)"></i>
    </div>

    <div mat-dialog-content class="content">

        <form class="form" [formGroup]="requestSettlementForm" [libVituForm]="requestSettlementForm" (libVituFormSubmit)="onSubmit()">

            <div class="form-fields-container">

                <lib-vitu-form-field-date
                    class="form-input"
                    id="dateSingleField"
                    [formGroup]="requestSettlementForm"
                    formFieldName="dateSingleField"
                    label="Date"
                    restrictFuture
                    singleDateMode
                    readonly>
                </lib-vitu-form-field-date>

                <lib-vitu-form-field-input
                    id="amount"
                    [formGroup]="requestSettlementForm"
                    formFieldName="amount"
                    label="Amount"
                    type="twoDigitDecimal"
                    prefix="$"
                    [errorOverrides]="[{'amountInvalid': 'Amount invalid'}]">
                </lib-vitu-form-field-input>

            </div>

            <lib-modal-error-message *ngIf="data?.errorMessageSubject | async as errorMessage" [message]="errorMessage">
            </lib-modal-error-message>
        
            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()"
                    [isModal]="true">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Submit"
                    [neverDisable]="true">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </form>
    </div>

</div>
