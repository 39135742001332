import { Action } from "@ngrx/store";
import { Filter, Sorting } from "./fee-plans.state";
import { PagedDataDtoOfFeePlanDto } from "@admin_api/models/paged-data-dto-of-fee-plan-dto";

export enum ActionTypes {
    GetFeePlans = "[FEE_PLANS] GetFeePlans",
    GetFeePlansSucceed = "[FEE_PLANS] GetFeePlansSucceed",
    GetFeePlansFail = "[FEE_PLANS] GetFeePlansFail",
    SortFeePlans = "[FEE_PLANS] SortFeePlans",
    PageChangedFeePlans = "[FEE_PLANS] PageChangedFeePlans",
    UploadPlanCodes = "[FEE_PLANS] UploadPlanCodes"
}

export class GetFeePlansAction implements Action {

    constructor(
        public page: number,
        public pageSize?: number,
        public filter?: Filter,
        public sorting?: Sorting) {}

    readonly type = ActionTypes.GetFeePlans;
}

export class GetFeePlansSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfFeePlanDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetFeePlansSucceed;
}

export class GetFeePlansFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetFeePlansFail;
}

export class SortFeePlansAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortFeePlans;
}

export class PageChangedFeePlansAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedFeePlans;
}

export class UploadPlanCodesAction implements Action {

    constructor() {}

    readonly type = ActionTypes.UploadPlanCodes;
}
