<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Webhooks' }, { 'label': 'PayPal Events' }]">
    </lib-title-bar>

    <app-paypal-events-log-filters
        [filter]="filter"
        (filterChanged)="filterChanged.emit($event)">
    </app-paypal-events-log-filters>

    <lib-table-base
        class="events-table"
        [columnDefs]="eventsColumnDefs"
        [rowsData]="events"
        [rowsDataLoading]="loading"
        [rowsDataShowWhileLoading]="true"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading PayPal Events"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)"
        selectedRowProperty="id"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-paypal-events-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-paypal-events-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
