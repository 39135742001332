<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="DfmFileOrderByEnum.Id">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="DfmFileOrderByEnum.FullName">
        <lib-text-table-column>
            {{ element.fullName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="DfmFileOrderByEnum.Owner">
        <lib-text-table-column>
            {{ element.owner }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="DfmFileOrderByEnum.CreatedUtc">
        <lib-date-time-label [dateUtc]="element.createdUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="DfmFileOrderByEnum.UpdatedUtc">
        <lib-date-time-label [dateUtc]="element.updatedUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="DfmFileOrderByEnum.Status">
        <lib-status-chip
            class="dfm-file-statuses"
            [status]="element.status">
        </lib-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="'reprocess'">
        <lib-vitu-tooltip text="Reprocess">
            <i class="far fa-redo global-table-cell-image-link" (click)="onClickReprocess()"></i>
        </lib-vitu-tooltip>
    </ng-container>

    <ng-container *ngSwitchCase="'download'">
        <lib-vitu-tooltip text="Download">
            <i class="far fa-file-download global-table-cell-image-link" (click)="onClickDownload()"></i>
        </lib-vitu-tooltip>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
