import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, map, catchError, filter } from "rxjs/operators";
import {
    ActionTypes, ReprocessBinFileAction
} from "./bin-file.actions";
import { DownloadBinFileAction } from "./bin-file.actions";
import { ConfirmationModalComponent, ConfirmationModalData, GlobalSpinnerService, VituToastService, VituToastTone } from "shared-lib";
import { StrictHttpResponse } from "@admin_api/strict-http-response";
import { BinFilesService } from "@admin_api/services/bin-files.service";
import { of } from "rxjs";
import { GenericNoOpAction } from "../generic.actions";
import { GetBinFilesAction } from "../bin-files/bin-files.actions";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class BinFileEffects {

    constructor(
        private actions: Actions,
        private binFilesService: BinFilesService,
        private globalSpinner: GlobalSpinnerService,
        private toast: VituToastService,
        private dialog: MatDialog
    ) { }

    downloadBinFile = createEffect(() =>
        this.actions.pipe(
            ofType<DownloadBinFileAction>(ActionTypes.DownloadBinFile),
            switchMap(({id}) =>
                this.globalSpinner.apply(this.binFilesService.binFilesDownload$Response({id})
                    .pipe(
                        map((response: any) => {
                            const fileName = this.getDownloadFileName(response);
                            this.openFile(fileName, response);
                            return true;
                        })
                    )
                )
            )
        ),
    { dispatch: false });

    reprocessBinFile = createEffect(() =>
        this.actions.pipe(
            ofType<ReprocessBinFileAction>(ActionTypes.ReprocessBinFile),
            switchMap(({ id }) => this.dialog.open(ConfirmationModalComponent, {
                data: {
                    title: "Reprocess BIN File",
                    subtitle: "Are you sure you want to reprocess this BIN file?",
                    confirmButtonText: "Reprocess"
                } as ConfirmationModalData
            }).afterClosed().pipe(
                map((confirmed: boolean) => ({ id, confirmed })),
            )),
            filter(({ confirmed }) => confirmed),
            switchMap(({id}) =>
                this.globalSpinner.apply(this.binFilesService.binFilesReprocess({id})
                    .pipe(
                        switchMap(() => {
                            this.toast.open(`BIN file reprocess success.`, VituToastTone.Positive);
                            return of(new GetBinFilesAction(1));
                        }),
                        catchError(() => {
                            this.toast.open(`BIN file reprocess failed.`, VituToastTone.Negative);
                            return of(new GenericNoOpAction());
                        })
                    )
                )
            )
        ),
    );

    private getDownloadFileName(response: StrictHttpResponse<Blob>): string {
        let fileName = null;

        try {
            const contentDisposition = response.headers.get("content-disposition");
            const regEx = /filename=[\"]?([^\"]+?)[\"]?;/;
            const fileNameMatches = contentDisposition.match(regEx);
            if (Array.isArray(fileNameMatches) && (fileNameMatches.length === 2)) {
                fileName = fileNameMatches[1];
            }
            if (!fileName) {
                throw new Error();
            }
        }
        catch (error) {
            throw new Error("Unable to download file.");
        }

        return fileName;
    }

    private openFile(fileName: string, response: StrictHttpResponse<Blob>): void {

        try {
            const blob = new Blob([response.body], { type: "application/octet-stream" });
            const fileUrl = URL.createObjectURL(blob);
            const tempAnchorElement: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
            tempAnchorElement.href = fileUrl;
            tempAnchorElement.download = fileName;
            document.body.appendChild(tempAnchorElement);
            tempAnchorElement.click();
            document.body.removeChild(tempAnchorElement);
            URL.revokeObjectURL(fileUrl);
        }
        catch (error) {
            throw new Error("Unable to open file.");
        }

    }

}
