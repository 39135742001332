import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./shared/router-guards/auth.guard";
import { PermissionsGuard } from "./shared/router-guards/permissions.guard";

import { DashboardComponent } from "./pages/dashboard/dashboard.component";

import { DevicesComponent } from "./pages/devices/devices.component";
import { MidComponent } from "./pages/mid/mid.component";
import { TidComponent } from "./pages/tid/tid.component";
import { UserComponent } from "./pages/user/user.component";
import { FeePlansComponent } from "./pages/fee-plans/fee-plans.component";
import { FeePlanComponent } from "./pages/fee-plan/fee-plan.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { PageUnavailableComponent } from "./pages/page-unavailable/page-unavailable.component";
import { WebhookComponent } from "./pages/webhook/webhook.component";
import { WebhooksComponent } from "./pages/webhooks/webhooks.component";
import { EventHistoryComponent } from "./pages/event-history/event-history.component";
import { EventComponent } from "./pages/event/event.component";
import { FundingComponent } from "./pages/funding/funding.component";
import { FundingItemComponent } from "./pages/funding-item/funding-item.component";
import { GoogleCloudStorageComponent } from "./pages/google-cloud-storage/google-cloud-storage.component";
import { GooglePayComponent } from "./pages/google-pay/google-pay.component";
import { HelpComponent } from "./pages/help/help.component";
import { ErrorUiService } from "shared-lib";
import { TransactionsComponent } from "./pages/transactions/transactions.component";
import { TransactionComponent } from "./pages/transaction/transaction.component";
import { RoleComponent } from "./pages/role/role.component";
import { RolesComponent } from "./pages/roles/roles.component";
import { AccountsComponent } from "./pages/accounts/accounts.component";
import { AccountComponent } from "./pages/account/account.component";
import { DynamicDefaultRouteGuard } from "./shared/router-guards/default-dynamic-route.guard";
import { OrganizationsComponent } from "./pages/organizations/organizations.component";
import { OrganizationComponent } from "./pages/organization/organization.component";
import { PayPalEventsComponent } from "./pages/paypal-events/paypal-events.component";
import { PayPalSftpComponent } from "./pages/paypal-sftp/paypal-sftp.component";
import { AdjustmentsComponent } from "./pages/adjustments/adjustments.component";
import { AdjustmentComponent } from "./pages/adjustment/adjustment.component";
import { ApplePayComponent } from "./pages/apple-pay/apple-pay.component";
import { DisputesComponent } from "./pages/disputes/disputes.component";
import { DisputeComponent } from "./pages/dispute/dispute.component";
import { PaymentsComponent } from "./pages/payments/payments.component";

class DummyComponent {}

const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canActivateChild: [PermissionsGuard],
    children: [
      { path: "organizations/create", component: OrganizationComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/organizations/create", component: OrganizationComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/organizations/:id", component: OrganizationComponent,
            data: { requiredPermissions: [], forceReloadComponent: true }  },
      { path: "organizations/:organizationId/organizations", component: OrganizationComponent, data: { requiredPermissions: [] } },
      { path: "organizations/:organizationId/mids/create", component: MidComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/mids/:mid", component: MidComponent,
            data: { requiredPermissions: [], forceReloadComponent: true }  },
      { path: "organizations/:organizationId/mids", component: OrganizationComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/tids/create", component: TidComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/tids/:tid", component: TidComponent,
            data: { requiredPermissions: [], forceReloadComponent: true }  },
      { path: "organizations/:organizationId/tids", component: OrganizationComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/users/create", component: UserComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/users/:id", component: UserComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId/users", component: OrganizationComponent, data: { requiredPermissions: [] } },
      { path: "organizations/:organizationId/history", component: OrganizationComponent, data: { requiredPermissions: [] }  },
      { path: "organizations/:organizationId", component: OrganizationComponent,
            data: { requiredPermissions: [], forceReloadComponent: true }  },
      { path: "organizations", component: OrganizationsComponent,
            data: { requiredPermissions: [] } },
      { path: "payments", component: PaymentsComponent },
      { path: "transactions", component: TransactionsComponent },
      { path: "transactions/:id/history", component: TransactionComponent },
      { path: "transactions/:id/chargebacks", component: TransactionComponent },
      { path: "transactions/:id/retrievals", component: TransactionComponent },
      { path: "transactions/:id/fees", component: TransactionComponent },
      { path: "transactions/:id/additional-information", component: TransactionComponent },
      { path: "transactions/:id", component: TransactionComponent },
      { path: "devices", component: DevicesComponent },
      { path: "fee-plans/create", component: FeePlanComponent, data: { requiredPermissions: [] }  },
      { path: "fee-plans/:feePlanId", component: FeePlanComponent, data: { requiredPermissions: [] }  },
      { path: "fee-plans", component: FeePlansComponent },
      { path: "webhooks/event-history/:id", component: EventComponent, data: { requiredPermissions: [] }  },
      { path: "webhooks/event-history", component: EventHistoryComponent, data: { requiredPermissions: [] }  },
      { path: "webhooks/paypal-events", component: PayPalEventsComponent, data: { requiredPermissions: [] }  },
      { path: "webhooks/configure/create", component: WebhookComponent, data: { requiredPermissions: [] }  },
      { path: "webhooks/configure/:id", component: WebhookComponent, data: { requiredPermissions: [] }  },
      { path: "webhooks/configure", component: WebhooksComponent, data: { requiredPermissions: [] } },
      { path: "reports", component: ReportsComponent },
      { path: "funding/funding-history/:id", component: FundingItemComponent, data: { requiredPermissions: [] }  },
      { path: "funding/funding-history", component: FundingComponent },
      { path: "funding/adjustments/:token", component: AdjustmentComponent, data: { requiredPermissions: [] }  },
      { path: "funding/adjustments", component: AdjustmentsComponent },
      { path: "sftp-files/google-cloud-storage", component: GoogleCloudStorageComponent, data: { requiredPermissions: [] } },
      { path: "sftp-files/paypal-sftp", component: PayPalSftpComponent, data: { requiredPermissions: [] } },
      { path: "digital-wallets/google-pay", component: GooglePayComponent },
      { path: "digital-wallets/apple-pay", component: ApplePayComponent },
      { path: "help", component: HelpComponent },
      { path: "roles/create", component: RoleComponent, data: { requiredPermissions: [] }  },
      { path: "roles/:id", component: RoleComponent, data: { requiredPermissions: [] }  },
      { path: "roles", component: RolesComponent, data: { requiredPermissions: [] } },
      { path: "accounts/create", component: AccountComponent, data: { requiredPermissions: [] }  },
      { path: "accounts/:id", component: AccountComponent, data: { requiredPermissions: [] }  },
      { path: "accounts", component: AccountsComponent, data: { requiredPermissions: [] } },
      { path: "disputes/:id", component: DisputeComponent, data: { requiredPermissions: [] }  },
      { path: "disputes", component: DisputesComponent, data: { requiredPermissions: [] } },
      { path: "**", component: DummyComponent, canActivate: [DynamicDefaultRouteGuard], pathMatch: "full" }
    ]
  },
  { path: "forbidden", component: ForbiddenComponent },
  { path: "page-unavailable", component: PageUnavailableComponent },
  { path: "**", redirectTo: "dashboard", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
  providers: [AuthGuard, PermissionsGuard]
})
export class AppRoutingModule {

    constructor(errorUi: ErrorUiService) {
        errorUi.reloadConfig = {
            origin: window.location.origin,
            help: "/dashboard/help"
        };
    }

}
