import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActionButtonKind, LocalTimePoint } from "shared-lib";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { MerchantProcessorDto } from "@admin_api/models/merchant-processor-dto";
import { AdjustmentDto } from "@admin_api/models/adjustment-dto";
import { AdjustmentReasonEnum } from "@admin_api/models/adjustment-reason-enum";
import { CreateManualAdjustmentDto } from "@admin_api/models/create-manual-adjustment-dto";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-adjustment-details",
    templateUrl: "./adjustment-details.component.html",
    styleUrls: ["./adjustment-details.component.less"],
})
export class AdjustmentDetailsComponent implements OnChanges {

    @Input() isCreate: boolean;

    @Input() adjustment: AdjustmentDto;
    @Input() adjustmentLoading = false;

    @Input() merchants: Array<OrganizationDto> = [];
    @Input() merchantsLoading = false;
    @Input() merchantsError: Error;

    @Input() mids: Array<MerchantProcessorDto> = [];
    @Input() midsLoading = false;
    @Input() midsError: Error;

    @Output() createAdjustment = new EventEmitter<any>();
    @Output() deleteAdjustment = new EventEmitter<string>();
    @Output() cancel = new EventEmitter<void>();
    @Output() merchantChange = new EventEmitter<number>();

    readonly adjustmentDateInfoTip = `Adjustments will be included in the
funding on the adjustment date.`;

    readonly createAdjustmentDateInfoTip = `The cut off for same day
adjustments is 1pm (PT).`;

    LocalTimePoint = LocalTimePoint;

    uneditedFormConfig: any = {};

    pageTitle: string;

    ActionButtonKind = ActionButtonKind;

    reasons: Array<AdjustmentReasonEnum>;

    constructor(
        private fb: FormBuilder
    ) {
        this.reasons = Object.values(AdjustmentReasonEnum);
    }

    get crumbs() {
        const retVal: any = [ { label: "Funding" }, { label: "Adjustments" , link: "/dashboard/funding/adjustments" } ];
        retVal.push({ label: this.pageTitle });
        return retVal;
    }

    private adjustmentFormConfig: FormConfig<any> = {
        merchant: [null, [Validators.required]],
        mid: [null, [Validators.required]],
        adjustmentDate: [null, [Validators.required]],
        amount: [null, [Validators.required]],
        reason: [null, [Validators.required]],
        description: [null, [Validators.maxLength(45)]]
    };

    adjustmentForm = this.fb.group(this.adjustmentFormConfig);

    get formControls(): FormControls<any> { return this.adjustmentForm?.controls; }

    getFormControl(name: string): FormControl {
        return this.adjustmentForm?.controls[name] as FormControl;
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.isCreate && changes.isCreate.firstChange) {
            this.isCreate = changes.isCreate.currentValue;
            this.pageTitle = (this.isCreate ? "New" : "View") + " Adjustment";
        }

        if (changes.adjustment && this.adjustment) {
            this.uneditedFormConfig = {};
            this.adjustmentForm.patchValue(this.uneditedFormConfig);
            if (!this.isCreate) {
                this.adjustmentForm.markAllAsTouched();
            }
        }

        // Workaround needed for form using ValidatorsVituCustom:
        // ValidatorsVituCustom need to be run by forcing validation if anything they depend on changes,
        // for example as a result of the allowed values being retrieved asynchronously from server (& arriving late).
        // (TODO : Consolidate use of Angular Forms in shareable form base class which implements our common form
        // behaviour like this as default for all of our forms (instead of having to apply it in multiple places))
        this.validateFormControls(this.adjustmentForm);
    }

    get noMids(): boolean {
        return (!this.midsLoading) && !(this.mids?.length);
    }

    get emptyMidLabel(): string {
        const merchantId = this.adjustmentForm.get("merchant")?.value;
        return (merchantId != null) ? "Selected merchant contains no MIDs" : "Please select merchant first";
    }

    onMerchantChanged() {
        const merchantId = this.adjustmentForm.get("merchant")?.value;
        if (merchantId != null) {
            this.merchantChange.emit(merchantId);
        }
    }

    onSubmit() {
        if (this.isCreate) {
            const adjustmentDate = this.adjustmentForm.get("adjustmentDate").value?.from;
            let amount = this.adjustmentForm.get("amount").value;
            if (amount) {
                amount = parseFloat(amount);
            }
            const reason = this.adjustmentForm.get("reason").value;
            const merchantProcessorId = this.adjustmentForm.get("mid").value;
            const description = this.adjustmentForm.get("description").value;

            const createAdjustmentDto: CreateManualAdjustmentDto = {
                adjustmentDate,
                amount,
                reason,
                merchantProcessorId,
                description
            };

            this.createAdjustment.emit(createAdjustmentDto);
        }
    }

    onClickDelete() {
        if (this.adjustment) {
            this.deleteAdjustment.emit(this.adjustment.token);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

    private validateFormControls(formGroup: FormGroup): void {
        Object.keys(formGroup?.controls).forEach(field => {
            formGroup?.get(field)?.updateValueAndValidity();
        });
    }

}
