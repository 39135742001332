import { ConfigDto } from "@admin_app/storage/config/config.state";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-accounts-table-cell-content",
    templateUrl: "./accounts-table-cell-content.component.html",
    styleUrls: ["./accounts-table-cell-content.component.less"],
})
export class AccountsTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;
}
