import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnDef, TableSorting, Pager, LocalTimePoint } from "shared-lib";
import { Filter, Sorting } from "@admin_app/storage/paypal-sftp-files/paypal-sftp-files.state";
import { ReportFileDto } from "@admin_api/models/report-file-dto";
import { ReportFileOrderByEnum } from "@admin_api/models/report-file-order-by-enum";

@Component({
    selector: "app-paypal-sftp-log",
    templateUrl: "./paypal-sftp-log.component.html",
    styleUrls: ["./paypal-sftp-log.component.less"]
})
export class PayPalSftpLogComponent {

    @Input() files: Array<ReportFileDto> = [];
    @Input() filesLoading = false;
    @Input() filesError: Error;
    @Input() filesSorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() filesFilter: Filter;
    @Input() filesPager: Pager;

    @Output() filesSortingChanged = new EventEmitter<Sorting>();
    @Output() filesFilterChanged = new EventEmitter<Filter>();
    @Output() filesPageChanged = new EventEmitter<number>();
    @Output() reprocessFile = new EventEmitter<number>();
    @Output() downloadFile = new EventEmitter<number>();

    readonly filesColumnDefs: ColumnDef[];

    constructor() {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.filesColumnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: ReportFileOrderByEnum.Id, title: "File Ref", flexWidthBasisInPixels: 110, flexWidthGrow: 0, canSort: true},
            {id: ReportFileOrderByEnum.FullName, title: "Name", flexWidthBasisInPixels: 380, flexWidthGrow: 3, canSort: true},
            {id: ReportFileOrderByEnum.FileType, title: "File Type", flexWidthBasisInPixels: 200, flexWidthGrow: 1, canSort: true},
//            {id: ReportFileOrderByEnum.Owner, title: "Owner", flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: ReportFileOrderByEnum.CreatedUtc, title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: ReportFileOrderByEnum.UpdatedUtc, title: `Date Updated (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: ReportFileOrderByEnum.Status, title: "Status", flexWidthBasisInPixels: 110, flexWidthGrow: 1, canSort: true},
            {id: "reprocess", title: "Reprocess", flexWidthBasisInPixels: 40, flexWidthGrow: 1},
            {id: "download", title: "Download", flexWidthBasisInPixels: 40, flexWidthGrow: 1},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.filesSortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }
}
