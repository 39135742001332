import { Component } from "@angular/core";
import { IStore } from "@admin_app/storage/store";
import { Store } from "@ngrx/store";
import { getRoles, getRolesError, getRolesLoading } from "@admin_app/storage/roles/roles.selectors";
import { getConfig } from "@admin_app/storage/config/config.selectors";

@Component({
    selector: "app-roles",
    templateUrl: "./roles.component.html",
    styleUrls: [ "./roles.component.less" ]
})
export class RolesComponent {

    config$ = this.store.select(getConfig);

    roles$ = this.store.select(getRoles);

    loading$ = this.store.select(getRolesLoading);

    error$ = this.store.select(getRolesError);

    constructor(private store: Store<IStore>) {}

}
