<app-account-details
    [isCreate]="isCreate"
    [account]="account$ | async"
    [loading]="loading$ | async"
    [accountMerchantProcessors]="accountMerchantProcessors$ | async"
    [accountMerchantProcessorsLoading]="accountMerchantProcessorsLoading$ | async"
    [accountMerchantProcessorsError]="accountMerchantProcessorsError$ | async"
    (deleteAccount)="deleteAccount($event)"
    (updateAccount)="updateAccount($event)"
    (createAccount)="createAccount($event)"
    (back)="onBack()">
</app-account-details>
