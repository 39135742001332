/* tslint:disable */
/* eslint-disable */
export enum BusinessAttributeName {
  AFFILIATE_OF_PUBLICALLY_TRADED_ORGANIZATION = 'AFFILIATE_OF_PUBLICALLY_TRADED_ORGANIZATION',
  LIQUIDATION_BANKRUPTCY = 'LIQUIDATION_BANKRUPTCY',
  NON_PROFIT_ORGANIZATION = 'NON_PROFIT_ORGANIZATION',
  PASSIVE_ASSET = 'PASSIVE_ASSET',
  PASSIVE_INCOME = 'PASSIVE_INCOME',
  PRIMARY_EXCHANGE_NAME = 'PRIMARY_EXCHANGE_NAME',
  TICKER_SYMBOL = 'TICKER_SYMBOL',
  PUBLICALLY_TRADED_ORGANIZATION = 'PUBLICALLY_TRADED_ORGANIZATION',
  PUBLICALLY_TRADED_ORGANIZATION_AFFILIATE_NAME = 'PUBLICALLY_TRADED_ORGANIZATION_AFFILIATE_NAME',
  SUBSTANTIAL_US_OWNER = 'SUBSTANTIAL_US_OWNER',
  SUBJECT_TO_US_TAX = 'SUBJECT_TO_US_TAX'
}
