import { PaymentDto } from "@admin_api/models/payment-dto";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { PaymentOrderBy } from "@admin_api/models/payment-order-by";
import { DisputeTypeEnum } from "@admin_api/models/dispute-type-enum";
import { CardBrandEnum } from "@admin_api/models/card-brand-enum";
import { DateRange, Pager, createFilter, FilterBase, getInitialLocalFilterDateRangeLastTwoWeeks } from "shared-lib";
import { GatewayEnum } from "@admin_api/models/gateway-enum";
import { PaymentStatus } from "@admin_api/models/payment-status";
import { DigitalWalletEnum } from "@admin_api/models/digital-wallet-enum";

export class Sorting {
    orderBy: null | PaymentOrderBy = PaymentOrderBy.DateAndTime;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    localDateSettledInterval: DateRange = undefined;
    localDateFundedInterval: DateRange = undefined;
    amount: number = undefined;
    first6: string = undefined;
    last4: string = undefined;
    payerName: string = undefined;
    cardBrands: Array<CardBrandEnum> = undefined;
    disputeTypes: Array<DisputeTypeEnum> = undefined;
    token: string = undefined;
    statuses: Array<PaymentStatus> = undefined;
    gateways: Array<GatewayEnum> = undefined;
    tid: string = undefined;
    mid: string = undefined;
    merchantId: number = undefined;
    dba: string = undefined;
    digitalWallets: Array<DigitalWalletEnum> = undefined;
}

export class PaymentsState {
    loading = false;
    error = null;
    exportLoading = false;
    exportError = null;
    rows: PaymentDto[];
    filter = createFilter(Filter);
    pager = new Pager();
    sorting = new Sorting();
}
