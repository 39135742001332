import { SolutionWebhookDto } from "@admin_api/models/solution-webhook-dto";

export class WebhooksState {
    loading = false;
    error: Error = null;
    record: Array<SolutionWebhookDto> = [];
    secret = null;
    secretLoading = false;
    secretError = null;
}
