import { IStore } from "@admin_app/storage/store";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Filter, Sorting } from "@admin_app/storage/adjustments/adjustments.state";

import * as AdjustmentsActions from "@admin_app/storage/adjustments/adjustments.actions";
import * as AdjustmentsSelectors from "@admin_app/storage/adjustments/adjustments.selectors";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";
import { PagerLargeSinglePageSize } from "@admin_app/storage/common";

@Component({
    selector: "app-adjustments",
    templateUrl: "./adjustments.component.html",
    styleUrls: ["./adjustments.component.less"],
})
export class AdjustmentsComponent implements OnInit {

    adjustments$ = this.store.select(AdjustmentsSelectors.getAdjustments);
    adjustmentsLoading$ = this.store.select(AdjustmentsSelectors.getAdjustmentsLoading);
    adjustmentsError$ = this.store.select(AdjustmentsSelectors.getAdjustmentsError);

    pager$ = this.store.select(AdjustmentsSelectors.getAdjustmentsPager);
    sorting$ = this.store.select(AdjustmentsSelectors.getAdjustmentsSorting);
    filter$ = this.store.select(AdjustmentsSelectors.getAdjustmentsFilter);

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new AdjustmentsActions.SortAdjustmentsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new AdjustmentsActions.PageChangedAdjustmentsAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new AdjustmentsActions.FilterAdjustmentsAction(filter));
    }

}
