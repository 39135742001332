import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, withLatestFrom } from "rxjs/operators";
import {
    ActionTypes,
    PageChangedBinFilesAction,
    GetBinFilesAction,
    GetBinFilesSucceedAction,
    SortBinFilesAction,
    FilterBinFilesAction,
    GetBinFilesFailAction
} from "./bin-files.actions";
import { of } from "rxjs";
import { Filter, Sorting } from "./bin-files.state";
import { getBinFilesState } from "./bin-files.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { PagedDataDtoOfBinFileDto } from "@admin_api/models/paged-data-dto-of-bin-file-dto";
import { BinFilesService } from "@admin_api/services/bin-files.service";
import { LocalTimePoint } from "shared-lib";

@Injectable()
export class BinFilesEffects {

    constructor(
        private actions$: Actions,
        private binFilesService: BinFilesService,
        private store: Store<IStore>
    ) {}

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortBinFilesAction>(ActionTypes.SortBinFiles),
            switchMap(action => of(new GetBinFilesAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterBinFilesAction>(ActionTypes.FilterBinFiles),
            switchMap(action => of(new GetBinFilesAction(1, undefined, action.filter, undefined)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedBinFilesAction>(ActionTypes.PageChangedBinFiles),
            switchMap(action => of(new GetBinFilesAction(action.page)))
        ),
    );

    getBinFiles = createEffect(() =>
        this.actions$.pipe(
            ofType<GetBinFilesAction>(ActionTypes.GetBinFiles),
            withLatestFrom(this.store.select(getBinFilesState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.binFilesService.binFilesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetBinFilesSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) => of(new GetBinFilesFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                Statuses: filter.statuses
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
