<app-organizations-log
    [config]="config$ | async"
    [organizations]="organizations$ | async"
    [organizationsLoading]="organizationsLoading$ | async"
    [organizationsError]="organizationsError$ | async"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)"
    [filter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)">
</app-organizations-log>
