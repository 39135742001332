/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateRoleDto } from '../models/create-role-dto';
import { RoleDetailsDto } from '../models/role-details-dto';
import { RoleDto } from '../models/role-dto';
import { UpdateRoleDto } from '../models/update-role-dto';

@Injectable({
  providedIn: 'root',
})
export class OrganizationRolesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationRolesSearch
   */
  static readonly OrganizationRolesSearchPath = '/v1/admin/organization/{organizationId}/roles';

  /**
   * Get list of organization roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesSearch$Response(params: {
    organizationId: number;
  }): Observable<StrictHttpResponse<Array<RoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesSearchPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleDto>>;
      })
    );
  }

  /**
   * Get list of organization roles.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesSearch(params: {
    organizationId: number;
  }): Observable<Array<RoleDto>> {

    return this.organizationRolesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RoleDto>>) => r.body as Array<RoleDto>)
    );
  }

  /**
   * Path part for operation organizationRolesPost
   */
  static readonly OrganizationRolesPostPath = '/v1/admin/organization/{organizationId}/roles';

  /**
   * Create organization role.
   *
   * Similar to public API creates a role for organization/merchant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPost$Response(params: {
    organizationId: number;
    body: CreateRoleDto
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesPostPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create organization role.
   *
   * Similar to public API creates a role for organization/merchant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPost(params: {
    organizationId: number;
    body: CreateRoleDto
  }): Observable<number> {

    return this.organizationRolesPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation organizationRolesSearch2
   */
  static readonly OrganizationRolesSearch2Path = '/v1/admin/merchants/{organizationId}/roles';

  /**
   * Get list of organization roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesSearch2()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesSearch2$Response(params: {
    organizationId: number;
  }): Observable<StrictHttpResponse<Array<RoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesSearch2Path, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleDto>>;
      })
    );
  }

  /**
   * Get list of organization roles.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesSearch2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesSearch2(params: {
    organizationId: number;
  }): Observable<Array<RoleDto>> {

    return this.organizationRolesSearch2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RoleDto>>) => r.body as Array<RoleDto>)
    );
  }

  /**
   * Path part for operation organizationRolesPost2
   */
  static readonly OrganizationRolesPost2Path = '/v1/admin/merchants/{organizationId}/roles';

  /**
   * Create organization role.
   *
   * Similar to public API creates a role for organization/merchant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesPost2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPost2$Response(params: {
    organizationId: number;
    body: CreateRoleDto
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesPost2Path, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create organization role.
   *
   * Similar to public API creates a role for organization/merchant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesPost2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPost2(params: {
    organizationId: number;
    body: CreateRoleDto
  }): Observable<number> {

    return this.organizationRolesPost2$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation organizationRolesGet
   */
  static readonly OrganizationRolesGetPath = '/v1/admin/organization/{organizationId}/roles/{id}';

  /**
   * Get organization role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesGet$Response(params: {
    organizationId: number;
    id: number;
  }): Observable<StrictHttpResponse<RoleDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesGetPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleDetailsDto>;
      })
    );
  }

  /**
   * Get organization role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesGet(params: {
    organizationId: number;
    id: number;
  }): Observable<RoleDetailsDto> {

    return this.organizationRolesGet$Response(params).pipe(
      map((r: StrictHttpResponse<RoleDetailsDto>) => r.body as RoleDetailsDto)
    );
  }

  /**
   * Path part for operation organizationRolesPut
   */
  static readonly OrganizationRolesPutPath = '/v1/admin/organization/{organizationId}/roles/{id}';

  /**
   * Update organization role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPut$Response(params: {
    organizationId: number;
    id: number;
    body: UpdateRoleDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesPutPath, 'put');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update organization role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPut(params: {
    organizationId: number;
    id: number;
    body: UpdateRoleDto
  }): Observable<void> {

    return this.organizationRolesPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation organizationRolesDelete
   */
  static readonly OrganizationRolesDeletePath = '/v1/admin/organization/{organizationId}/roles/{id}';

  /**
   * Delete organization role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesDelete$Response(params: {
    organizationId: number;
    id: number;
    concurrencyToken?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesDeletePath, 'delete');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.query('concurrencyToken', params.concurrencyToken, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete organization role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesDelete(params: {
    organizationId: number;
    id: number;
    concurrencyToken?: number;
  }): Observable<void> {

    return this.organizationRolesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation organizationRolesGet2
   */
  static readonly OrganizationRolesGet2Path = '/v1/admin/merchants/{organizationId}/roles/{id}';

  /**
   * Get organization role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesGet2()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesGet2$Response(params: {
    organizationId: number;
    id: number;
  }): Observable<StrictHttpResponse<RoleDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesGet2Path, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleDetailsDto>;
      })
    );
  }

  /**
   * Get organization role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesGet2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesGet2(params: {
    organizationId: number;
    id: number;
  }): Observable<RoleDetailsDto> {

    return this.organizationRolesGet2$Response(params).pipe(
      map((r: StrictHttpResponse<RoleDetailsDto>) => r.body as RoleDetailsDto)
    );
  }

  /**
   * Path part for operation organizationRolesPut2
   */
  static readonly OrganizationRolesPut2Path = '/v1/admin/merchants/{organizationId}/roles/{id}';

  /**
   * Update organization role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesPut2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPut2$Response(params: {
    organizationId: number;
    id: number;
    body: UpdateRoleDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesPut2Path, 'put');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update organization role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesPut2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationRolesPut2(params: {
    organizationId: number;
    id: number;
    body: UpdateRoleDto
  }): Observable<void> {

    return this.organizationRolesPut2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation organizationRolesDelete2
   */
  static readonly OrganizationRolesDelete2Path = '/v1/admin/merchants/{organizationId}/roles/{id}';

  /**
   * Delete organization role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationRolesDelete2()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesDelete2$Response(params: {
    organizationId: number;
    id: number;
    concurrencyToken?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationRolesService.OrganizationRolesDelete2Path, 'delete');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.query('concurrencyToken', params.concurrencyToken, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete organization role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationRolesDelete2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationRolesDelete2(params: {
    organizationId: number;
    id: number;
    concurrencyToken?: number;
  }): Observable<void> {

    return this.organizationRolesDelete2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
