import { Component, Input } from "@angular/core";

@Component({
    selector: "app-account-mids-table-cell-content",
    templateUrl: "./account-mids-table-cell-content.component.html",
    styleUrls: ["./account-mids-table-cell-content.component.less"],
})
export class AccountMidsTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

}
