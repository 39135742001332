import { ErrorHandler, Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetCurrentUserAction, GetCurrentUserSucceedAction } from "./current-user.actions";
import { CurrentUserDto } from "./current-user.state";
import { GetConfigAction } from "../config/config.actions";
import { Router } from "@angular/router";
import { VituHttpErrorForbidden, AuthService, GlobalErrorHandler, ErrorUiService } from "shared-lib";

@Injectable()
export class CurrentUserEffects {

    constructor(
        private actions: Actions,
        private authService: AuthService,
        private router: Router,
        private errorHandler: ErrorHandler,
        private errorUi: ErrorUiService
    ) {}

    getCurrentUser = createEffect(() => this.actions.pipe(
            ofType<GetCurrentUserAction>(ActionTypes.GetCurrentUser),
            switchMap(() => from(this.authService.loadUserProfile())
                .pipe(
                    switchMap((userProfile: any) => {

                        const currentUserDto: CurrentUserDto = {
                            id: userProfile?.info?.sub,
                            name: userProfile?.info?.name,
                            permissions: [],
                            defaultRoute: ""
                        };

                        currentUserDto.defaultRoute = this.getDefaultRoute(currentUserDto.permissions);
                        return of(new GetCurrentUserSucceedAction(currentUserDto));
                    }),
                    catchError(error => {

                        if (error instanceof VituHttpErrorForbidden) {
                            this.router.navigateByUrl("/forbidden");
                        }

                        return of(null);
                    })
                )
            )
        ));

    getCurrentUserSucceed = createEffect(() => this.actions.pipe(
            ofType<GetCurrentUserSucceedAction>(ActionTypes.GetCurrentUserSucceed),
            switchMap((action) => {

                (this.errorHandler as GlobalErrorHandler).currentUser = action.record;

                // TODO : Perhaps a better place to trigger this action on app startup?
                return of(new GetConfigAction());
            })
        ));

    private getDefaultRoute(currentPermissions: string[]): string {

        // Hardcoded for now, unless we apply permissions over Admin UI
        // (in which case we can change it to dynamic using the 'currentPermissions'
        // as done in Merchant Panel)
        const defaultRoute = "/dashboard/organizations";

        if (this.errorUi) {
            this.errorUi.defaultRoute = defaultRoute;
        }

        return defaultRoute;
    }

}
