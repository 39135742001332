import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ColumnDef } from "shared-lib";
import { getCurrentUserPermissions } from "@admin_app/storage/current-user/current-user.selectors";
import { IStore } from "@admin_app/storage/store";
import { ConfigDto } from "@admin_app/storage/config/config.state";
import { GlobalRoleDto } from "@admin_api/models/global-role-dto";

@Component({
    selector: "app-role-log",
    templateUrl: "./role-log.component.html",
    styleUrls: [ "./role-log.component.less" ]
})
export class RoleLogComponent {

    @Input() config: ConfigDto;
    @Input() roles: GlobalRoleDto[];
    @Input() loading = false;
    @Input() error: Error;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    roleColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "id", title: "ID", flexWidthBasisInPixels: 150, flexWidthGrow: 0, canSort: true},
        { id: "name", title: "Name", flexWidthBasisInPixels: 400, flexWidthGrow: 0, canSort: true},
        { id: "solution", title: "Solution", flexWidthBasisInPixels: 220, flexWidthGrow: 0, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    currentUserPermissions$ = this.store.pipe(
        select(getCurrentUserPermissions)
    );

    onClickCreateRole() {
        this.router.navigate([`/dashboard/roles/create`]);
    }

    onRowSelected(role: GlobalRoleDto) {
        if (role.solution != null) {
            this.router.navigate([`/dashboard/roles/${role.id}`], { queryParams: { solution: role.solution }});
        }
        else {
            this.router.navigate([`/dashboard/roles/${role.id}`]);
        }
    }

    constructor(private router: Router, private store: Store<IStore>) {}

    hasCreateRolePermission(currentPermissions: string[]): boolean {

        return (currentPermissions.indexOf("core.roles.create") !== -1);
    }

}
