<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'name'">
        <lib-text-table-column>
            {{ element.name }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'valueNum'">
        <ng-container *ngIf="isCreate && element.isEditable; else valueSet;">
            <div class="value-pre" *ngIf="element.valueFormat === ValueFormatEnum.Dollar">$</div>
            <div class="value-wrapper">
                <input
                    *ngIf="element.valueFormat === ValueFormatEnum.Dollar"
                    type="text"
                    [libTwoDigitDecimalNumber]="true"
                    [libTwoDigitDecimalNumberAllowZeroNotNegative]="true"
                    [libTwoDigitDecimalNumberEmptyIsNull]="true"
                    class="value-num-input"
                    matInput
                    [(ngModel)]="customFeeTypeValueMap[element.id]"
                    (mousedown)="inputTouched = true;"
                    [ngClass]="{'invalid-value': !isCustomValueValid()}"
                    [disabled]="!element.isEnabled" />
                <input
                    *ngIf="element.valueFormat === ValueFormatEnum.Percentage"
                    type="text"
                    [libDecimalNumber]="true"
                    [libDecimalNumberEmptyIsNull]="true"
                    [libDecimalNumberAllowNegative]="false"
                    class="value-num-input"
                    matInput
                    [(ngModel)]="customFeeTypeValueMap[element.id]"
                    (mousedown)="inputTouched = true;"
                    [ngClass]="{'invalid-value': !isCustomValueValid()}"
                    [disabled]="!element.isEnabled" />
                <input
                    *ngIf="element.valueFormat === ValueFormatEnum.NA"
                    type="text"
                    [libDecimalNumber]="true"
                    [libDecimalNumberEmptyIsNull]="true"
                    [libDecimalNumberAllowNegative]="true"
                    class="value-num-input"
                    matInput
                    [(ngModel)]="customFeeTypeValueMap[element.id]"
                    (mousedown)="inputTouched = true;"
                    [ngClass]="{'invalid-value': !isCustomValueValid()}"
                    [disabled]="!element.isEnabled" />
            </div>
            <div class="value-post" *ngIf="element.valueFormat === ValueFormatEnum.Percentage">%</div>
        </ng-container>
        <ng-template #valueSet>
            <div *ngIf="element.valueFormat === ValueFormatEnum.Percentage">
                {{ element.valueNum | vituPercentage }}
            </div>
            <div *ngIf="element.valueFormat === ValueFormatEnum.Dollar">
                {{ element.valueNum | vituCurrencyUsd }}
            </div>
            <div *ngIf="element.valueFormat === ValueFormatEnum.NA">
                {{ element.valueNum ?? "—" }}
            </div>
        </ng-template>
        <lib-table-column-sort [key]="sortingKeyForValue"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'category'">
        <lib-text-table-column>
            {{ element.category }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'trigger'">
        <lib-text-table-column>
            {{ element.trigger }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'processor'">
        <lib-text-table-column>
            {{ element.processor }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'feeCollector'">
        <lib-text-table-column>
            {{ element.feeCollector }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'isAbsorbed'">
        <lib-vitu-slide-toggle
            [(checked)]="element.isAbsorbed"
            [disabled]="!isCreate || !element.canAbsorb">
        </lib-vitu-slide-toggle>
        <lib-table-column-sort [key]="sortingKeyForIsAbsorbed"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'isEnabled'">
        <lib-vitu-slide-toggle
            [(checked)]="element.isEnabled">
        </lib-vitu-slide-toggle>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
