import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getFundingItemState = (store: IStore) => store.fundingItem;

export const getFundingItem = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.record
);

export const getFundingItemLoading = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.loading
);

export const getFundingItemError = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.error
);

export const getFundingItemTransactions = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.transactions
);

export const getFundingItemTransactionsLoading = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.transactionsLoading
);

export const getFundingItemTransactionsError = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.transactionsError
);

export const getFundingItemTransactionsPager = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.transactionsPager
);

export const getFundingItemTransactionsSorting = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.transactionsSorting
);

export const getFundingItemTransactionsFilter = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.transactionsFilter
);

export const getFundingItemExportLoading = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.exportLoading
);

export const getFundingItemMidFees = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.midFees
);

export const getFundingItemMidFeesLoading = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.midFeesLoading
);

export const getFundingItemMidFeesError = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.midFeesError
);

export const getFundingItemAdjustments = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.adjustments
);

export const getFundingItemAdjustmentsLoading = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.adjustmentsLoading
);

export const getFundingItemAdjustmentsError = createSelector(
    getFundingItemState,
    fundingItem => fundingItem.adjustmentsError
);
