import { Action } from "@ngrx/store";

export enum ActionTypes {
    ShowInformationModal = "[APP] ShowInformationModal"
}

export class ShowInformationModalAction implements Action {

  constructor(public title: string, public message: string, public buttonText: string) {}

  readonly type = ActionTypes.ShowInformationModal;
}
