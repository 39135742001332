import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getFeePlansState = (store: IStore) => store.feePlans;

export const getFeePlans = createSelector(
    getFeePlansState,
    feePlansState => feePlansState.record,
);

export const getFeePlansLoading = createSelector(
    getFeePlansState,
    feePlansState => feePlansState.loading,
);

export const getFeePlansError = createSelector(
    getFeePlansState,
    feePlansState => feePlansState.error,
);

export const getFeePlansSorting = createSelector(
    getFeePlansState,
    feePlansState => feePlansState.sorting,
);

export const getFeePlansPager = createSelector(
    getFeePlansState,
    feePlansState => feePlansState.pager,
);
