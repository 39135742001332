import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getMidState = (store: IStore) => store.mid;

export const getMidInfo = createSelector(
    getMidState,
    midState => midState.record,
);

export const getMidLoading = createSelector(
    getMidState,
    midState => midState.loading,
);

export const getMidCloverDevices = createSelector(
    getMidState,
    midState => midState.cloverDevices
);

export const getMidCloverDevicesLoading = createSelector(
    getMidState,
    midState => midState.cloverDevicesLoading
);

export const getMidCloverDevicesError = createSelector(
    getMidState,
    midState => midState.cloverDevicesError
);

export const getReassignDeviceMid = createSelector(
    getMidState,
    midState => midState.reassignDeviceRecord
);

export const getReassignDeviceMidLoading = createSelector(
    getMidState,
    midState => midState.reassignDeviceLoading
);

export const getReassignDeviceMidError = createSelector(
    getMidState,
    midState => midState.reassignDeviceError
);

export const getEditMidError = createSelector(
    getMidState,
    midState => midState.editMidError
);
