<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'merchantName'">
        <lib-text-table-column>
            {{ element.merchantName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingResultsOrderBy.MID">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingResultsOrderBy.CreatedUtc">
        <lib-date-time-label [dateUtc]="element.createdUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'vituPayDepositAmount'">
        <lib-text-table-column>
            {{ element.vituPayDepositAmount | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'merchantDepositAmount'">
        <lib-text-table-column>
            {{ element.merchantDepositAmount | vituCurrencyUsd }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FundingResultsOrderBy.FundingStatus">
        <lib-funding-item-status-chip
            [status]="element.fundingStatus">
        </lib-funding-item-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="FundingResultsOrderBy.FundedDateUtc">
        <lib-date-time-label [dateUtc]="element.fundedDateUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
