<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Webhooks' }, { 'label': 'Configure' }]">
        <ng-container *ngIf="currentUserPermissions$ | async as currentUserPermissions">
            <lib-action-button *ngIf="hasCreatePermission(currentUserPermissions)"
                label="New Webhook" fontImage="far fa-plus-circle fa-bg" (click)="onClickCreate()">
            </lib-action-button>
        </ng-container>
    </lib-title-bar>

    <lib-table-base
        class="webhooks-table"
        [columnDefs]="webhookColumnDefs"
        [rowsData]="webhooks"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Webhooks"
        selectedRowProperty="id"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-webhooks-table-cell-content
                [columnId]="columnId"
                [element]="element"
                [config]="config"
                [toggleWebhookLoading]="toggleWebhooksLoading.indexOf(element.id) !== -1"
                (activeToggled)="onIsActiveToggled($event)">
            </app-webhooks-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
