import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { MaterialModule } from "./material.module";

import { environment } from "@admin_environments/environment";

import {
    ActionButtonModule,
    MultiStageExpansionPanelModule,
    TableBaseModule,
    StopPropagationModule,
    BusyIndicatorModule,
    GlobalSpinnerModule,
    VituFormModule,
    VituFormFieldModule,
    ModalErrorMessageModule,
    ErrorHandlerModule,
    DateTimeModule,
    StatusChipModule,
    PagerModule,
    VituToastModule,
    TextModule,
    TitleBarModule,
    FiltersBaseModule,
    VituTooltipModule,
    UserStatusIconModule,
    FundingItemStatusChipModule,
    TransactionStatusChipModule,
    BinaryStatusIconModule,
    VituInputModule,
    Environment,
    SpaceBetweenCapsModule,
    VituNumberFormatModule,
    CopyToClipboardButtonModule,
    SwitchArrayModule,
    PermissionsSwitchArrayModule,
    GroupedSwitchArrayModule,
    VituSlideToggleModule,
    VituSelectModule,
    VituDateModule,
    VituTimeModule
} from "shared-lib";
import { TransactionsTableComponent } from "./transactions-table/transactions-table.component";
import {
    TransactionsTableCellContentComponent
} from "./transactions-table/transactions-table-cell-content/transactions-table-cell-content.component";

const exportableModules = [
    FormsModule,
    ReactiveFormsModule,
    BusyIndicatorModule,
    GlobalSpinnerModule,
    MultiStageExpansionPanelModule,
    TableBaseModule,
    ActionButtonModule,
    StopPropagationModule,
    VituFormModule,
    VituFormFieldModule,
    ModalErrorMessageModule,
    ErrorHandlerModule,
    DateTimeModule,
    StatusChipModule,
    PagerModule,
    VituToastModule,
    TextModule,
    TitleBarModule,
    FiltersBaseModule,
    VituTooltipModule,
    UserStatusIconModule,
    FundingItemStatusChipModule,
    TransactionStatusChipModule,
    BinaryStatusIconModule,
    VituInputModule,
    SpaceBetweenCapsModule,
    VituNumberFormatModule,
    CopyToClipboardButtonModule,
    SwitchArrayModule,
    PermissionsSwitchArrayModule,
    GroupedSwitchArrayModule,
    VituSlideToggleModule,
    VituSelectModule,
    VituDateModule,
    VituTimeModule
];

const exportableDomEntities = [
    TransactionsTableComponent,
    TransactionsTableCellContentComponent
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule.forRoot(),
        ...exportableModules

    ],
    declarations: [
        ...exportableDomEntities,
    ],
    exports: [
        MaterialModule,
        ...exportableModules,
        ...exportableDomEntities
    ],
    providers: [
        { provide: Environment, useValue: environment }
    ]
})
export class SharedModule {
}
