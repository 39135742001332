import { BoundMerchantProcessorsResult } from "@admin_api/models/bound-merchant-processors-result";
import { FeePlanDetailModel } from "@admin_api/models/fee-plan-detail-model";

export class FeePlanState {
    record: FeePlanDetailModel = null;
    loading = false;
    error = null;
    merchantProcessors: Array<BoundMerchantProcessorsResult> = [];
    merchantProcessorsLoading = false;
    merchantProcessorsError = null;
}
