<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    suppressInitialRequest
    shortLayout>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-short-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateInterval"
                        label="Date Created"
                        [clearValue]="filter?._initialFilter?.localDateInterval"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

                <div class="form-field-container" id="typesFilterField">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="types"
                        isFilter
                        multiple
                        label="File Type"
                        [options]="fileTypeList"
                        [showReset]="filtersBase.resetFieldVisible('types')"
                        (handleReset)="filtersBase.resetField('types')"
                        [spaceBetweenCapsOnLabels]="true">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="statuses"
                        isFilter
                        multiple
                        label="Status"
                        [options]="fileStatusList"
                        [showReset]="filtersBase.resetFieldVisible('statuses')"
                        (handleReset)="filtersBase.resetField('statuses')"
                        [spaceBetweenCapsOnLabels]="true">
                    </lib-vitu-form-field-select>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
