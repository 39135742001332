import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, mergeMap, map } from "rxjs/operators";
import { throwError, of } from "rxjs";
import {
    ActionTypes, UpdateDeviceStatusFailAction
} from "./device.actions";
import { UpdateInventoryDeviceAction } from "../inventory/inventory.actions";
import { UpdateDeviceStatusAction, UpdateDeviceStatusSucceedAction } from "./device.actions";
import { InventoryService } from "@admin_api/services/inventory.service";
import { ChangePosTerminalStatusDto } from "@admin_api/models/change-pos-terminal-status-dto";

@Injectable()
export class DeviceEffects {

    constructor(
        private actions: Actions,
        private inventoryService: InventoryService
    ) { }

    updateDeviceStatus = createEffect(() => this.actions.pipe(
            ofType<UpdateDeviceStatusAction>(ActionTypes.UpdateDeviceStatus),
            switchMap(action => {
                const statusDto: ChangePosTerminalStatusDto = {
                    merchantId: action.device.merchantId,
                    status: action.status
                };
                return this.inventoryService.inventoryChangeStatus( {terminalId: action.device.id, body: statusDto }).pipe(
                    mergeMap(() => of(new UpdateDeviceStatusSucceedAction(action.device, action.status))),
                    catchError((error) => of(new UpdateDeviceStatusFailAction(error)))
                );
            })
        ));

    updateDeviceStatusSucceed = createEffect(() => this.actions.pipe(
            ofType<UpdateDeviceStatusSucceedAction>(ActionTypes.UpdateDeviceStatusSucceed),
            switchMap(action => {
                const device = {
                    ...action.device
                };
                if (device.posConfig) {
                    device.posConfig.status = action.status;
                }
                return of(new UpdateInventoryDeviceAction(device));
            })
        ));

}
