import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ConfigDto } from "@admin_app/storage/config/config.state";
import { WebhookDto } from "@admin_api/models/webhook-dto";

@Component({
    selector: "app-webhooks-table-cell-content",
    templateUrl: "./webhooks-table-cell-content.component.html",
    styleUrls: ["./webhooks-table-cell-content.component.less"],
})
export class WebhooksTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;
    @Input() config: ConfigDto;
    @Input() toggleWebhookLoading: boolean;
    @Output() activeToggled = new EventEmitter<{webhook: WebhookDto; undo: () => void}>();

    isActiveToggled(checked) {
        this.element.isActive = checked;

        const undo = () => {
            this.element.isActive = !this.element.isActive;
        };

        const updatedWebhook: WebhookDto = {
            ...this.element,
            isActive: checked
        };

        this.activeToggled.emit({
            webhook: updatedWebhook,
            undo
        });
    }

    getDisplayTextForSolution(): string {
        let text = "";
        if (this.element?.solution && this.config?.solutionLookup) {
            const found = this.config.solutionLookup.filter(item => (item.id === this.element.solution));
            if (found?.length) {
                text = found[0].name;
            }
        }
        return text;
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }
}
