import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getFeePlanState = (store: IStore) => store.feePlan;

export const getFeePlanInfo = createSelector(
    getFeePlanState,
    feePlanState => feePlanState.record,
);

export const getFeePlanLoading = createSelector(
    getFeePlanState,
    feePlanState => feePlanState.loading,
);

export const getFeePlanError = createSelector(
    getFeePlanState,
    feePlanState => feePlanState.error,
);

export const getFeePlanMerchantProcessors = createSelector(
    getFeePlanState,
    feePlanState => feePlanState.merchantProcessors,
);

export const getFeePlanMerchantProcessorsLoading = createSelector(
    getFeePlanState,
    feePlanState => feePlanState.merchantProcessorsLoading,
);

export const getFeePlanMerchantProcessorsError = createSelector(
    getFeePlanState,
    feePlanState => feePlanState.merchantProcessorsError,
);
