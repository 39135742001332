import { MidState } from "./mid.state";
import { ActionTypes } from "./mid.actions";

export const MidReducer = (
    state: MidState = new MidState(),
    action: any,
  ): MidState => {

    switch (action.type) {

        case ActionTypes.GetMid:
        case ActionTypes.GetNewMid: {
            return {
                ...state,
                record: null,
                loading: true
            };
        }

        case ActionTypes.GetMidSucceed: {
            return {
                ...state,
                record: action.mid,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetMidFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        case ActionTypes.ResetCloverDevices: {
            return {
                ...state,
                cloverDevices: null,
                cloverDevicesError: null,
                cloverDevicesLoading: false
            };
        }

        case ActionTypes.GetCloverDevices: {
            return {
                ...state,
                cloverDevices: [],
                cloverDevicesLoading: true,
            };
        }

        case ActionTypes.GetCloverDevicesSucceed: {
            return {
                ...state,
                cloverDevices: action.response,
                cloverDevicesLoading: false,
                cloverDevicesError: null
            };
        }

        case ActionTypes.GetCloverDevicesFail: {
            return {
                ...state,
                cloverDevices: [],
                cloverDevicesLoading: false,
                cloverDevicesError: action.error
            };
        }

        case ActionTypes.ResetReassignDeviceMid: {
            return {
                ...state,
                reassignDeviceRecord: null,
                reassignDeviceError: null,
                reassignDeviceLoading: false
            };
        }

        case ActionTypes.GetReassignDeviceMid: {
            return {
                ...state,
                reassignDeviceLoading: true
            };
        }

        case ActionTypes.GetReassignDeviceMidSucceed: {
            return {
                ...state,
                reassignDeviceRecord: action.mid,
                reassignDeviceLoading: false,
                reassignDeviceError: null
            };
        }

        case ActionTypes.GetReassignDeviceMidFail: {
            return {
                ...state,
                reassignDeviceRecord: null,
                reassignDeviceLoading: false,
                reassignDeviceError: action.error
            };
        }

        case ActionTypes.ResetEditMidErrors:
        case ActionTypes.CreateMidSucceed:
        case ActionTypes.UpdateMidSucceed: {
            return {
                ...state,
                editMidError: null
            };
        }

        case ActionTypes.CreateMidFail:
        case ActionTypes.UpdateMidFail: {
            return {
                ...state,
                editMidError: action.error
            };
        }

        default: {
            return state;
        }
    }

};
