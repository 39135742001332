import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getDeviceState = (store: IStore) => store.device;

export const getDevice = createSelector(
    getDeviceState,
    deviceState => deviceState.record,
);

export const getDeviceLoading = createSelector(
    getDeviceState,
    deviceState => deviceState.loading,
);
