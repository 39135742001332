import { Action } from "@ngrx/store";
import { BoundMerchantProcessorsResult } from "@admin_api/models/bound-merchant-processors-result";
import { FeePlanDetailModel } from "@admin_api/models/fee-plan-detail-model";
import { FeeTypeValue } from "@admin_api/models/fee-type-value";
import { FeePlanData } from "@admin_api/models/fee-plan-data";

export enum ActionTypes {
    GetFeePlan = "[FEE_PLAN] GetFeePlan",
    GetNewFeePlan = "[FEE_PLAN] GetNewFeePlan",
    GetFeePlanSucceed = "[FEE_PLAN] GetFeePlanSucceed",
    GetFeePlanFail = "[FEE_PLAN] GetFeePlanFail",
    UpdateFeePlan = "[FEE_PLAN] UpdateFeePlan",
    DeleteFeePlan = "[FEE_PLAN] DeleteFeePlan",
    CreateFeePlan = "[FEE_PLAN] CreateFeePlan",
    ResetFeePlanMerchantProcessors = "[FEE_PLAN] ResetFeePlanMerchantProcessors",
    GetFeePlanMerchantProcessors = "[FEE_PLAN] GetFeePlanMerchantProcessors",
    GetFeePlanMerchantProcessorsSucceed = "[FEE_PLAN] GetFeePlanMerchantProcessorsSucceed",
    GetFeePlanMerchantProcessorsFail = "[FEE_PLAN] GetFeePlanMerchantProcessorsFail",
}

export class GetFeePlanAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.GetFeePlan;
}

export class GetNewFeePlanAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewFeePlan;
}

export class GetFeePlanSucceedAction implements Action {

    constructor(public feePlan: FeePlanDetailModel, public isCreate = false) {}

    readonly type = ActionTypes.GetFeePlanSucceed;
}

export class GetFeePlanFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetFeePlanFail;
}

export class UpdateFeePlanAction implements Action {

    constructor(public id: number, public feePlan: any) {}

    readonly type = ActionTypes.UpdateFeePlan;
}

export class DeleteFeePlanAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DeleteFeePlan;
}

export class CreateFeePlanAction implements Action {

    constructor(public feePlan: FeePlanData, public feeTypeValues: Array<FeeTypeValue>) {}

    readonly type = ActionTypes.CreateFeePlan;
}

export class ResetFeePlanMerchantProcessorsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetFeePlanMerchantProcessors;
}

export class GetFeePlanMerchantProcessorsAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.GetFeePlanMerchantProcessors;
}

export class GetFeePlanMerchantProcessorsSucceedAction implements Action {

    constructor(
        public merchantProcessors: Array<BoundMerchantProcessorsResult>
    ) {}

    readonly type = ActionTypes.GetFeePlanMerchantProcessorsSucceed;
}

export class GetFeePlanMerchantProcessorsFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetFeePlanMerchantProcessorsFail;
}
