import { Action } from "@ngrx/store";
import { CreateMerchantTerminalDto } from "@admin_api/models/create-merchant-terminal-dto";
import { MerchantTerminalDetailsDto } from "@admin_api/models/merchant-terminal-details-dto";
import { UpdateMerchantTerminalDto } from "@admin_api/models/update-merchant-terminal-dto";
import { ApplePayConfigResponse } from "@admin_api/models/apple-pay-config-response";

export enum ActionTypes {
    GetTid = "[TID] GetTid",
    GetNewTid = "[TID] GetNewTid",
    GetTidSucceed = "[TID] GetTidSucceed",
    GetTidFail = "[TID] GetTidFail",
    UpdateTid = "[TID] UpdateTid",
    DeleteTid = "[TID] DeleteTid",
    CreateTid = "[TID] CreateTid",
    ReassignDevice = "[TID] ReassignDevice",
    PairDevice = "[TID] PairDevice",
    GetGooglePayMerchantId = "[TID] GetGooglePayMerchantId",
    GetGooglePayMerchantIdSucceed = "[TID] GetGooglePayMerchantIdSucceed",
    GetGooglePayMerchantIdFail = "[TID] GetGooglePayMerchantIdFail",
    GetApplePayMerchantId = "[TID] GetApplePayMerchantId",
    GetApplePayMerchantIdSucceed = "[TID] GetApplePayMerchantIdSucceed",
    GetApplePayMerchantIdFail = "[TID] GetApplePayMerchantIdFail",
    SyncApplePay = "[TID] SyncApplePay",
    SyncApplePaySucceed = "[TID] SyncApplePaySucceed",
    SyncApplePayFail = "[TID] SyncApplePayFail",
    GetDomainValidationFile = "[TID] GetDomainValidationFile"
}

export class GetTidAction implements Action {

    constructor(
        public id: number, public merchantId: number
    ) {}

    readonly type = ActionTypes.GetTid;
}

export class GetNewTidAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewTid;
}

export class GetTidSucceedAction implements Action {

    constructor(
        public tid: MerchantTerminalDetailsDto
    ) {}

    readonly type = ActionTypes.GetTidSucceed;
}

export class GetTidFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetTidFail;
}

export class UpdateTidAction implements Action {

    constructor(public id: number, public merchantId: number, public tid: UpdateMerchantTerminalDto) {}

    readonly type = ActionTypes.UpdateTid;
}

export class DeleteTidAction implements Action {

    constructor(public id: number, public merchantId: number) {}

    readonly type = ActionTypes.DeleteTid;
}

export class CreateTidAction implements Action {

    constructor(public merchantId: number, public tid: CreateMerchantTerminalDto) {}

    readonly type = ActionTypes.CreateTid;
}

export class ReassignDeviceAction implements Action {

    constructor(public merchantId: number, public merchantProcessorId: number, public merchantTerminalId: number) {}

    readonly type = ActionTypes.ReassignDevice;
}

export class PairDeviceAction implements Action {

    constructor(public merchantId: number, public merchantProcessorId: number, public merchantTerminalId: number) {}

    readonly type = ActionTypes.PairDevice;
}

export class GetGooglePayMerchantIdAction implements Action {

    constructor(public id: number, public merchantId: number) {}

    readonly type = ActionTypes.GetGooglePayMerchantId;
}

export class GetGooglePayMerchantIdSucceedAction implements Action {

    constructor(
        public googlePayMerchantId: string
    ) {}

    readonly type = ActionTypes.GetGooglePayMerchantIdSucceed;
}

export class GetGooglePayMerchantIdFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetGooglePayMerchantIdFail;
}

export class GetApplePayMerchantIdAction implements Action {

    constructor(public id: number, public merchantId: number) {}

    readonly type = ActionTypes.GetApplePayMerchantId;
}

export class GetApplePayMerchantIdSucceedAction implements Action {

    constructor(
        public applePayMerchantId: string
    ) {}

    readonly type = ActionTypes.GetApplePayMerchantIdSucceed;
}

export class GetApplePayMerchantIdFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetApplePayMerchantIdFail;
}

export class SyncApplePayAction implements Action {

    constructor(public id: number, public merchantId: number) {}

    readonly type = ActionTypes.SyncApplePay;
}

export class SyncApplePaySucceedAction implements Action {

    constructor(
        public applePayConfigResponse: ApplePayConfigResponse
    ) {}

    readonly type = ActionTypes.SyncApplePaySucceed;
}

export class SyncApplePayFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.SyncApplePayFail;
}

export class GetDomainValidationFileAction implements Action {

    constructor(public id: number, public merchantId: number) {}

    readonly type = ActionTypes.GetDomainValidationFile;
}
