import { ActionTypes } from "./current-user.actions";
import { CurrentUserState } from "./current-user.state";

export const CurrentUserReducer = (
    state: CurrentUserState = new CurrentUserState(),
    action: any,
): CurrentUserState => {

    switch (action.type) {

        case ActionTypes.GetCurrentUser: {

            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetCurrentUserSucceed: {

            return {
                ...state,
                loading: false,
                record: action.record,
                error: null
            };
        }

        case ActionTypes.GetCurrentUserFail: {

            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default:
            return state;
    }
};
