<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    suppressInitialRequest>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateInterval"
                        label="Date Created"
                        [clearValue]="filter?._initialFilter?.localDateInterval"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="fundingDepositsRef"
                        label="Funding Deposit Ref"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('fundingDepositsRef')"
                        (handleReset)="filtersBase.resetField('fundingDepositsRef')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="merchantIds"
                        isFilter
                        multiple
                        label="Merchant(s)"
                        labelEmpty="No Merchants"
                        [labelEmptyCondition]="noMerchants"
                        [options]="merchants"
                        optionLabelKey="name"
                        optionValueKey="id"
                        [showReset]="filtersBase.resetFieldVisible('merchantIds')"
                        (handleReset)="filtersBase.resetField('merchantIds')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="mid"
                        label="MID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('mid')"
                        (handleReset)="filtersBase.resetField('mid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateAdjustedInterval"
                        label="Adjustment Date"
                        [clearValue]="filter?._initialFilter?.localDateAdjustedInterval"
                        [infoTip]="adjustmentDateInfoTip"
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
