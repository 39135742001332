import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { LocalTimePoint } from "shared-lib";
import { Filter } from "@admin_app/storage/bin-files/bin-files.state";
import { BinFileStatusEnum } from "@admin_api/models/bin-file-status-enum";

@Component({
    selector: "app-bin-log-filters",
    templateUrl: "./bin-log-filters.component.html",
    styleUrls: ["./bin-log-filters.component.less"]
})
export class BinLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;

    LocalTimePoint = LocalTimePoint;

    binFileStatusList: Array<BinFileStatusEnum>;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.binFileStatusList = Object.values(BinFileStatusEnum);
    }

    filterForm = this.fb.group({
        localDateInterval: [null],
        statuses: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

}
