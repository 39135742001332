import { AccountsState } from "./accounts.state";
import { ActionTypes } from "./accounts.actions";

export const AccountsReducer = (
    state: AccountsState = new AccountsState(),
    action: any,
  ): AccountsState => {

    switch (action.type) {

        case ActionTypes.GetAccounts: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetAccountsSucceed: {
            return {
                ...state,
                record: action.accounts,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetAccountsFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        default: {
            return state;
        }
    }

};
