import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetStatementsAction, GetStatementsSucceedAction } from "./statements.actions";
import { of } from "rxjs";
import { BillingService } from "@admin_api/services/billing.service";

@Injectable()
export class StatementsEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService
    ) {}

    getStatements$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetStatementsAction>(ActionTypes.GetStatements),
            switchMap((action: GetStatementsAction) => this.billingService.reportStatementReportsAll(
                    {
                        merchantId: action.merchantId,
                        year: action.year,
                        month: action.month
                    }
                ).pipe(
                    catchError(() => of([])),
                    switchMap((statements) => of(new GetStatementsSucceedAction(statements)))
                ))
        )
    );

}
