import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getFeeTypesState = (store: IStore) => store.feeTypes;

export const getFeeTypes = createSelector(
    getFeeTypesState,
    feeTypesState => feeTypesState.record,
);

export const getFeeTypesLoading = createSelector(
    getFeeTypesState,
    feeTypesState => feeTypesState.loading,
);

export const getFeeTypesError = createSelector(
    getFeeTypesState,
    feeTypesState => feeTypesState.error,
);
