import { TidsState } from "./tids.state";
import { ActionTypes } from "./tids.actions";

export const TidsReducer = (
    state: TidsState = new TidsState(),
    action: any,
  ): TidsState => {

    switch (action.type) {

        case ActionTypes.ResetTids: {
            return {
                ...state,
                loading: false,
                record: [],
                error: null
            };
        }

        case ActionTypes.GetTids: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetTidsSucceed: {
            return {
                ...state,
                record: action.response.items,
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetTidsFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        case ActionTypes.ResetAssociatedTids: {
            return {
                ...state,
                associatedLoading: false,
                associatedRecord: [],
                associatedError: null
            };
        }

        case ActionTypes.GetAssociatedTids: {
            return {
                ...state,
                associatedLoading: true
            };
        }

        case ActionTypes.GetAssociatedTidsSucceed: {
            return {
                ...state,
                associatedRecord: action.response.items,
                associatedLoading: false,
                associatedError: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetAssociatedTidsFail: {
            return {
                ...state,
                associatedLoading: false,
                associatedError: action.error,
                associatedRecord: []
            };
        }

        default: {
            return state;
        }
    }

};
