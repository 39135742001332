import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from "@angular/router";


// By default Angular will not reload components on the same route (for efficency)
// so use the 'forceReloadComponent' in any cases where we need to.
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {

    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const reloadComponent = future.data.forceReloadComponent;
        return !reloadComponent && (future.routeConfig === curr.routeConfig);
    }

}
