<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'amount'">
        <lib-text-table-column>
            {{ element.amount | vituCurrencyUsd }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="element.amount ?? 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'refNumber'">
        <lib-text-table-column>
            {{ element.refNumber }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <lib-status-chip
            class="funding-item-settlement-statuses"
            [status]="element.status">
        </lib-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="'type'">
        <lib-text-table-column>
            {{ element.type }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
