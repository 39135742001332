import { Component, Input } from "@angular/core";
import { DateTimeFormatUtils } from "shared-lib";
import { DisputeOrderBy } from "@admin_api/models/dispute-order-by";

@Component({
    selector: "app-disputes-table-cell-content",
    templateUrl: "./disputes-table-cell-content.component.html",
    styleUrls: ["./disputes-table-cell-content.component.less"],
})
export class DisputesTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;

    DateTimeFormatUtils = DateTimeFormatUtils;
    DisputeOrderBy = DisputeOrderBy;

    get sortingKeyForRaiseDate(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.raiseDateUtc).getTime();
    }

    get sortingKeyForDueDate(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.dueDateUtc).getTime();
    }

    get sortingKeyForStatusDate(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.statusDateUtc).getTime();
    }

}
