import { AccountAgreementType } from "@admin_api/models/account-agreement-type";
import { AccountAttributeName } from "@admin_api/models/account-attribute-name";
import { AccountHolderIdentifierType } from "@admin_api/models/account-holder-identifier-type";
import { AccountHolderType } from "@admin_api/models/account-holder-type";
import { AccountNumberType } from "@admin_api/models/account-number-type";
import { AccountType } from "@admin_api/models/account-type";
import { AgreementType } from "@admin_api/models/agreement-type";
import { BusinessAddressType } from "@admin_api/models/business-address-type";
import { BusinessAttributeName } from "@admin_api/models/business-attribute-name";
import { BusinessDocumentType } from "@admin_api/models/business-document-type";
import { BusinessNameType } from "@admin_api/models/business-name-type";
import { BusinessPhoneType } from "@admin_api/models/business-phone-type";
import { BusinessSubType } from "@admin_api/models/business-sub-type";
import { BusinessType } from "@admin_api/models/business-type";
import { GenderType } from "@admin_api/models/gender-type";
import { NameType } from "@admin_api/models/name-type";
import { OfficeBearerRole } from "@admin_api/models/office-bearer-role";
import { PayPalConfigurationStatusEnum } from "@admin_api/models/pay-pal-configuration-status-enum";
import { PersonAddressType } from "@admin_api/models/person-address-type";
import { PersonAttributeName } from "@admin_api/models/person-attribute-name";
import { PersonDocumentType } from "@admin_api/models/person-document-type";
import { PersonNameType } from "@admin_api/models/person-name-type";
import { YesNoValue } from "@admin_api/models/yes-no-value";
import { Validators } from "@angular/forms";

const infoTip_i002_i022 = `The 2 character ISO 3166-1 code.
The country code for Great Britain is 'GB' and not 'UK'.
Use the 'C2' country code for China worldwide for comparable
uncontrolled price (CUP) method, bank card, and cross-border
transactions.
(eg. 'US')`;

const infoTip_i003 = `13 character PayPal account identifier.`;

const infoTip_i004 = `The organization to which this managed account
belongs in the partner’s hierarchy, as a path.
(eg. 'us/marketplaces', 'eu/uk/marketplaces')`;

const infoTip_i006 = `The name that appears on the buyer’s bank or
credit card statement. Supports only capital letters,
numbers, spaces, and the '.' , '-' , and '*' special
characters. Include one alphanumeric character
with special characters.`;

const infoTip_i007 = `The partner's unique ID for
this user in their system.`;

const infoTip_i010 = `Account Number format should conform
to the selected Account Number Type`;

const infoTip_i011 = `The routing transit number, or bank code, of the bank.
Typically used for domestic accounts only. For international
accounts, the IBAN includes the bank code.`;

const infoTip_i013 = `4 digit Merchant Category Code (MCC)
(eg. '5931' for 'Used Merchandise and Secondhand Stores')`;

const infoTip_i014 = `The highest level sub-division in a country, which is usually a province,
state, or ISO-3166-2 subdivision. Format for postal delivery.
Value, by country, is:
  - UK. A county.
  - US. A state.
  - Canada. A province.
(eg. 'CA' (and not 'California'))`;

const infoTip_i015 = `A city, town, or village.
Smaller than admin area 1.`;

const infoTip_i016 = `A sub-locality, suburb, neighborhood, or district.
Smaller than admin area 2. Street name
information is not always available but a
sub-locality or district can be a very small area.`;

const infoTip_i017 = `The neighborhood, ward, or district.
Smaller than admin area 3 or sub_locality.
Value is:
  - The postal sorting code for Guernsey and many
    French territories, such as French Guiana.
  - The fine-grained administrative levels in China.`;

const infoTip_i005_i012 = `3 character ISO-4217 code which
identifies the currency.
(eg. 'USD', 'GBP')`;

const infoTip_i018 = `The postal code, which is
the zip code or equivalent.`;

const infoTip_i019 = `When the party is a person,
the party's given, or first, name.`;

export const payPalSchemaOptions = {
    i001: { clientValidators: [] },
    i002: { clientValidators: [Validators.required, Validators.pattern(/^([A-Z]{2}|C2)$/)], infoTip: infoTip_i002_i022 },
    i003: { clientValidators: [Validators.pattern(/^[2-9A-HJ-NP-Z]{13}$/)], infoTip: infoTip_i003, ignored: true },
    i004: { clientValidators: [Validators.required, Validators.minLength(1), Validators.maxLength(127)], infoTip: infoTip_i004 },
    i005: { clientValidators: [Validators.pattern(/^[A-Za-z]{3}$/)],
        infoTip: infoTip_i005_i012 },
    i006: { clientValidators: [Validators.minLength(2), Validators.maxLength(11)], infoTip: infoTip_i006 },
    i007: { clientValidators: [Validators.maxLength(127)], infoTip: infoTip_i007 },
    i008: { clientValidators: [Validators.required] },
    i009: { clientValidators: [Validators.pattern(/^[A-Za-z]+$/)] },
    i010: { clientValidators: [Validators.required], infoTip: infoTip_i010 },
    i011: { clientValidators: [Validators.required, Validators.maxLength(34)], infoTip: infoTip_i011 },
    i012: { clientValidators: [Validators.required, Validators.pattern(/^[A-Za-z]{3}$/)],
        infoTip: infoTip_i005_i012 },
    i013: { clientValidators: [Validators.required, Validators.pattern(/^[0-9]{4}$/)], infoTip: infoTip_i013 },
    i014: { clientValidators: [], infoTip: infoTip_i014 },
    i015: { clientValidators: [Validators.required], infoTip: infoTip_i015 },
    i016: { clientValidators: [], infoTip: infoTip_i016 },
    i017: { clientValidators: [], infoTip: infoTip_i017 },
    i018: { clientValidators: [], infoTip: infoTip_i018 },
    i019: { clientValidators: [Validators.required], infoTip: infoTip_i019 },
    i020: { clientValidators: [], ignored: true },
    i021: { clientValidators: [], ignoredCreate: true },
    i022: { clientValidators: [Validators.pattern(/^([A-Z]{2}|C2)$/)], infoTip: infoTip_i002_i022 },
    b001: { clientValidators: [] },
    b002: { clientValidators: [Validators.required] },
    e001: { options: Object.values(AccountAgreementType), clientValidators: [Validators.required] },
    e002: { options: Object.values(AccountAttributeName), clientValidators: [Validators.required] },
    e003: { options: Object.values(BusinessAddressType), clientValidators: [Validators.required] },
    e004: { options: Object.values(AgreementType), clientValidators: [Validators.required] },
    e005: { options: Object.values(BusinessAttributeName), clientValidators: [Validators.required] },
    e006: { options: Object.values(BusinessDocumentType), clientValidators: [Validators.required] },
    e007: { options: Object.values(BusinessNameType), clientValidators: [Validators.required] },
    e008: { options: Object.values(BusinessPhoneType), clientValidators: [Validators.required] },
    e009: { options: Object.values(BusinessType), clientValidators: [Validators.required] },
    e010: { options: Object.values(AccountHolderIdentifierType), clientValidators: [Validators.required] },
    e011: { options: Object.values(AccountHolderType), clientValidators: [Validators.required] },
    e012: { options: Object.values(AccountNumberType), clientValidators: [Validators.required] },
    e013: { options: Object.values(AccountType), clientValidators: [] },
    e014: { options: Object.values(PersonAddressType), clientValidators: [Validators.required] },
    e015: { options: Object.values(AgreementType), clientValidators: [Validators.required] },
    e016: { options: Object.values(PersonAttributeName), clientValidators: [Validators.required] },
    e017: { options: Object.values(GenderType), clientValidators: [] },
    e018: { options: Object.values(PersonDocumentType), clientValidators: [Validators.required] },
    e019: { options: Object.values(PersonNameType), clientValidators: [Validators.required] },
    e020: { options: Object.values(PayPalConfigurationStatusEnum), clientValidators: [Validators.required] },
    e021: { options: Object.values(NameType), clientValidators: [Validators.required] },
    e022: { options: Object.values(YesNoValue), clientValidators: [Validators.required] },
    e023: { options: Object.values(BusinessSubType), clientValidators: [] },
    e024: { options: Object.values(OfficeBearerRole), clientValidators: [Validators.required] },
    n001: { min: 0, max: 100, clientValidators: [Validators.min(0), Validators.max(100)] },
    n002: { min: 0, max: 100, clientValidators: [Validators.required, Validators.min(0), Validators.max(100)] },
    d001: { restrictFuture: true, restrictToDateOfBirth: true, clientValidators: [Validators.required] },
    d002: { clientValidators: [] },
    t001: { clientValidators: [] }
};

export const payPalSchema = {
    customer: {
        accountId_i003: "string",
        agreements: [
            {
                acceptedTime_t001: "string",
                type_e001: "enum"
            }
        ],
        attributes: [
            {
                name_e002: "enum",
                value_e022: "string"
            }
        ],
        balances: [
            {
                available: {
                    currencyCode_i012: "string",
                    value_i008: "string"
                },
                reserved: {
                    currencyCode_i012: "string",
                    value_i008: "string"
                },
            }
        ],
        businessEntity: {
            addresses: [
                {
                    type_e003: "enum",
                    addressDetails: {
                        buildingName_i001: "string",
                        deliveryService_i001: "string",
                        streetName_i001: "string",
                        streetNumber_i001: "string",
                        streetType_i001: "string",
                        subBuilding_i001: "string"
                    },
                    addressLine1_i008: "string",
                    addressLine2_i001: "string",
                    addressLine3_i001: "string",
                    adminArea1_i014: "string",
                    adminArea2_i015: "string",
                    adminArea3_i016: "string",
                    adminArea4_i017: "string",
                    countryCode_i002: "string",
                    id_i020: "string",
                    postalCode_i018: "string"
                }
            ],
            agreements: [
                {
                    acceptedTime_t001: "string",
                    signature_i001: "string",
                    type_e004: "enum"
                }
            ],
            attributes: [
                {
                    name_e005: "enum",
                    value_i008: "string"
                }
            ],
            beneficialOwners: {
                individuals: [
                    {
                        addresses: [
                            {
                                type_e014: "enum",
                                addressDetails: {
                                    buildingName_i001: "string",
                                    deliveryService_i001: "string",
                                    streetName_i001: "string",
                                    streetNumber_i001: "string",
                                    streetType_i001: "string",
                                    subBuilding_i001: "string"
                                },
                                addressLine1_i008: "string",
                                addressLine2_i001: "string",
                                addressLine3_i001: "string",
                                adminArea1_i014: "string",
                                adminArea2_i015: "string",
                                adminArea3_i016: "string",
                                adminArea4_i017: "string",
                                countryCode_i002: "string",
                                id_i020: "string",
                                postalCode_i018: "string"
                            }
                        ],
                        birthDetails: {
                            dateOfBirth_d001: "string",
                            placeOfBirth: {
                                adminArea1_i014: "string",
                                adminArea2_i015: "string",
                                countryCode_i002: "string"
                            }
                        },
                        citizenship_i001: "string",
                        controllingInfluence_b001: "boolean",
                        gender_e017: "enum",
                        id_i020: "string",
                        identificationDocuments: [
                            {
                                type_e018: "enum",
                                expiryDate_d002: "string",
                                id_i020: "string",
                                identificationNumber_i001: "string",
                                issueDate_d002: "string",
                                issuingCountryCode_i002: "string",
                                partial_b001: "boolean"
                            }
                        ],
                        names: [
                            {
                                fullName_i001: "string",
                                givenName_i019: "string",
                                id_i020: "string",
                                middleName_i001: "string",
                                prefix_i001: "string",
                                suffix_i001: "string",
                                surname_i008: "string",
                                type_e019: "enum"
                            }
                        ],
                        percentageOfOwnership_n002: "number"
                    }
                ]
            },
            declarations: [
                {
                    name_e021: "enum",
                    value_e022: "enum"
                }
            ],
            emails: [
                {
                    email_i008: "string",
                    id_i020: "string"
                }
            ],
            identificationDocuments: [
                {
                    type_e006: "enum",
                    expiryDate_d002: "string",
                    id_i020: "string",
                    identificationNumber_i001: "string",
                    issueDate_d002: "string",
                    issuingCountryCode_i002: "string",
                    partial_b001: "boolean"
                }
            ],
            incorporationDetails: {
                companyLaw_i001: "string",
                incorporationCountryCode_i022: "string",
                incorporationDate_d002: "string",
                incorporationProvinceCode_i001: "string"
            },
            merchantCategoryCode_i013: "string",
            names: [
                {
                    businessName_i008: "string",
                    id_i020: "string",
                    type_e007: "enum"
                }
            ],
            officeBearers: [
                {
                    addresses: [
                        {
                            type_e014: "enum",
                            addressDetails: {
                                buildingName_i001: "string",
                                deliveryService_i001: "string",
                                streetName_i001: "string",
                                streetNumber_i001: "string",
                                streetType_i001: "string",
                                subBuilding_i001: "string"
                            },
                            addressLine1_i008: "string",
                            addressLine2_i001: "string",
                            addressLine3_i001: "string",
                            adminArea1_i014: "string",
                            adminArea2_i015: "string",
                            adminArea3_i016: "string",
                            adminArea4_i017: "string",
                            countryCode_i002: "string",
                            id_i020: "string",
                            postalCode_i018: "string"
                        }
                    ],
                    birthDetails: {
                        dateOfBirth_d001: "string",
                        placeOfBirth: {
                            adminArea1_i014: "string",
                            adminArea2_i015: "string",
                            countryCode_i002: "string"
                        }
                    },
                    citizenship_i001: "string",
                    gender_e017: "enum",
                    id_i020: "string",
                    identificationDocuments: [
                        {
                            type_e018: "enum",
                            expiryDate_d002: "string",
                            id_i020: "string",
                            identificationNumber_i001: "string",
                            issueDate_d002: "string",
                            issuingCountryCode_i002: "string",
                            partial_b001: "boolean"
                        }
                    ],
                    names: [
                        {
                            fullName_i001: "string",
                            givenName_i019: "string",
                            id_i020: "string",
                            middleName_i001: "string",
                            prefix_i001: "string",
                            suffix_i001: "string",
                            surname_i008: "string",
                            type_e019: "enum"
                        }
                    ],
                    role_e024: "enum"
                }
            ],
            phoneNumbers: [
                {
                    type_e008: "enum",
                    countryCode_i009: "string",
                    extensionNumber_i001: "string",
                    id_i020: "string",
                    nationalNumber_i001: "string"
                }
            ],
            principalOfficeAddress: {
                addressDetails: {
                    buildingName_i001: "string",
                    deliveryService_i001: "string",
                    streetName_i001: "string",
                    streetNumber_i001: "string",
                    streetType_i001: "string",
                    subBuilding_i001: "string"
                },
                addressLine1_i008: "string",
                addressLine2_i001: "string",
                addressLine3_i001: "string",
                adminArea1_i014: "string",
                adminArea2_i015: "string",
                adminArea3_i016: "string",
                adminArea4_i017: "string",
                countryCode_i002: "string",
                id_i020: "string",
                postalCode_i018: "string"
            },
            registeredBusinessAddress: {
                addressDetails: {
                    buildingName_i001: "string",
                    deliveryService_i001: "string",
                    streetName_i001: "string",
                    streetNumber_i001: "string",
                    streetType_i001: "string",
                    subBuilding_i001: "string"
                },
                addressLine1_i008: "string",
                addressLine2_i001: "string",
                addressLine3_i001: "string",
                adminArea1_i014: "string",
                adminArea2_i015: "string",
                adminArea3_i016: "string",
                adminArea4_i017: "string",
                countryCode_i002: "string",
                id_i020: "string",
                postalCode_i018: "string"
            },
            subType_e023: "enum",
            taxAddress: {
                addressDetails: {
                    buildingName_i001: "string",
                    deliveryService_i001: "string",
                    streetName_i001: "string",
                    streetNumber_i001: "string",
                    streetType_i001: "string",
                    subBuilding_i001: "string"
                },
                addressLine1_i008: "string",
                addressLine2_i001: "string",
                addressLine3_i001: "string",
                adminArea1_i014: "string",
                adminArea2_i015: "string",
                adminArea3_i016: "string",
                adminArea4_i017: "string",
                countryCode_i002: "string",
                id_i020: "string",
                postalCode_i018: "string"
            },
            type_e009: "enum",
            website_i001: "string"
        },
        financialInstruments: {
            banks: [
                {
                    accountHolderDetails: {
                        identifier: {
                            type_e010: "enum",
                            value_i001: "string"
                        },
                        name_i001: "string",
                        type_e011: "enum"
                    },
                    accountNumber_i010: "string",
                    accountNumberType_e012: "enum",
                    accountType_e013: "enum",
                    bankName_i008: "string",
                    bic_i001: "string",
                    branchCode_i001: "string",
                    branchLocation_i001: "string",
                    countryCode_i002: "string",
                    currencyCode_i012: "string",
                    id_i020: "string",
                    last4Digits_i001: "string",
                    routingNumber_i011: "string"
                }
            ]
        },
        individualOwners: [
            {
                addresses: [
                    {
                        type_e014: "enum",
                        addressDetails: {
                            buildingName_i001: "string",
                            deliveryService_i001: "string",
                            streetName_i001: "string",
                            streetNumber_i001: "string",
                            streetType_i001: "string",
                            subBuilding_i001: "string"
                        },
                        addressLine1_i008: "string",
                        addressLine2_i001: "string",
                        addressLine3_i001: "string",
                        adminArea1_i014: "string",
                        adminArea2_i015: "string",
                        adminArea3_i016: "string",
                        adminArea4_i017: "string",
                        countryCode_i002: "string",
                        id_i020: "string",
                        postalCode_i018: "string"
                    }
                ],
                agreements: [
                    {
                        acceptedTime_t001: "string",
                        signature_i001: "string",
                        type_e015: "enum"
                    }
                ],
                attributes: [
                    {
                        name_e016: "enum",
                        value_e022: "string"
                    }
                ],
                birthDetails: {
                    dateOfBirth_d001: "string",
                    placeOfBirth: {
                        adminArea1_i014: "string",
                        adminArea2_i015: "string",
                        countryCode_i002: "string"
                    }
                },
                citizenship_i001: "string",
                emails: [
                    {
                        email_i001: "string",
                        id_i020: "string"
                    }
                ],
                gender_e017: "enum",
                id_i020: "string",
                identificationDocuments: [
                    {
                        type_e018: "enum",
                        expiryDate_d002: "string",
                        id_i020: "string",
                        identificationNumber_i001: "string",
                        issueDate_d002: "string",
                        issuingCountryCode_i002: "string",
                        partial_b001: "boolean"
                    }
                ],
                names: [
                    {
                        fullName_i001: "string",
                        givenName_i019: "string",
                        id_i020: "string",
                        middleName_i001: "string",
                        prefix_i001: "string",
                        suffix_i001: "string",
                        surname_i008: "string",
                        type_e019: "enum"
                    }
                ],
                phoneNumbers: [
                    {
                        countryCode_i009: "string",
                        extensionNumber_i001: "string",
                        id_i020: "string",
                        nationalNumber_i001: "string"
                    }
                ],
                primaryResidence: {
                    addressDetails: {
                        buildingName_i001: "string",
                        deliveryService_i001: "string",
                        streetName_i001: "string",
                        streetNumber_i001: "string",
                        streetType_i001: "string",
                        subBuilding_i001: "string"
                    },
                    addressLine1_i008: "string",
                    addressLine2_i001: "string",
                    addressLine3_i001: "string",
                    adminArea1_i014: "string",
                    adminArea2_i015: "string",
                    adminArea3_i016: "string",
                    adminArea4_i017: "string",
                    countryCode_i002: "string",
                    id_i020: "string",
                    postalCode_i018: "string"
                },
                taxAddress: {
                    addressDetails: {
                        buildingName_i001: "string",
                        deliveryService_i001: "string",
                        streetName_i001: "string",
                        streetNumber_i001: "string",
                        streetType_i001: "string",
                        subBuilding_i001: "string"
                    },
                    addressLine1_i008: "string",
                    addressLine2_i001: "string",
                    addressLine3_i001: "string",
                    adminArea1_i014: "string",
                    adminArea2_i015: "string",
                    adminArea3_i016: "string",
                    adminArea4_i017: "string",
                    countryCode_i002: "string",
                    id_i020: "string",
                    postalCode_i018: "string"
                }
            }
        ],
        legalCountryCode_i002: "string",
        organization_i004: "string",
        primaryCurrencyCode_i005: "string",
        reserveConfig: {
            releaseReserve_b001: "boolean",
            rollingReservePercent_i001: "string",
            rollingReservePeriodDays_i001: "string"
        },
        secondaryCurrencyCodes: [
            {
                currencyCode_i005: "string"
            }
        ],
        softDescriptor_i006: "string",
        userId_i007: "string"
    },
    merchantId_i021: "string",
    status_e020: "enum"
};
