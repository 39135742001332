import { DeletedItemsInclusionEnum } from "@admin_api/models/deleted-items-inclusion-enum";
import { MerchantProcessorDto } from "@admin_api/models/merchant-processor-dto";
import { createFilter, FilterBase } from "shared-lib";

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    localShowDeleted = "No";
}

export class MidsState {
    loading = false;
    error = null;
    record: Array<MerchantProcessorDto> = [];
    filter = createFilter(Filter);
    serviceFeeMidsLoading = false;
    serviceFeeMidsError = null;
    serviceFeeMidsRecord: Array<MerchantProcessorDto> = [];
    associatedMidsLoading = false;
    associatedMidsError = null;
    associatedMidsRecord: Array<MerchantProcessorDto> = [];
}
