import { ReportType } from "@admin_app/shared/download-report-modal/report-type";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Report } from "../../../../shared/download-report-modal/report";

@Component({
    selector: "app-reports-table-cell-content",
    templateUrl: "./reports-table-cell-content.component.html",
    styleUrls: ["./reports-table-cell-content.component.less"],
})
export class ReportsTableCellContentComponent {

    @Input() columnId: string;
    @Input() report: Report;
    @Output() downloadReport = new EventEmitter<ReportType>();

    constructor() {}

    onClickDownload() {
        this.downloadReport.emit(this.report.type);
    }

}
