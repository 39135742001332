import { Action } from "@ngrx/store";
import { Filter, Sorting } from "./paypal-sftp-files.state";
import { PagedDataDtoOfReportFileDto } from "@admin_api/models/paged-data-dto-of-report-file-dto";

export enum ActionTypes {
    GetPayPalSftpFiles = "[PAYPAL_SFTP_FILES] GetPayPalSftpFiles",
    GetPayPalSftpFilesSucceed = "[PAYPAL_SFTP_FILES] GetPayPalSftpFilesSucceed",
    GetPayPalSftpFilesFail = "[PAYPAL_SFTP_FILES] GetPayPalSftpFilesFail",
    SortPayPalSftpFiles = "[PAYPAL_SFTP_FILES] SortPayPalSftpFiles",
    FilterPayPalSftpFiles = "[PAYPAL_SFTP_FILES] FilterPayPalSftpFiles",
    PageChangedPayPalSftpFiles = "[PAYPAL_SFTP_FILES] PageChangedPayPalSftpFiles"
}

export class GetPayPalSftpFilesAction implements Action {

    constructor(public page: number, public pageSize?: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetPayPalSftpFiles;
}

export class GetPayPalSftpFilesSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfReportFileDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetPayPalSftpFilesSucceed;
}

export class GetPayPalSftpFilesFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetPayPalSftpFilesFail;
}

export class SortPayPalSftpFilesAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortPayPalSftpFiles;
}

export class FilterPayPalSftpFilesAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterPayPalSftpFiles;
}

export class PageChangedPayPalSftpFilesAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedPayPalSftpFiles;
}
