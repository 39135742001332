import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { NavigateAction, PageLoadFailAction } from "../router/router.actions";
import { ConfirmationModalComponent, ConfirmationModalData,
    VituCommonErrorEntityIdMustBeNumber } from "shared-lib";
import { ActionTypes, CreateAccountAction, GetNewAccountAction, GetAccountAction,
    GetAccountFailAction, GetAccountSucceedAction, UpdateAccountAction, DeleteAccountAction,
    GetAccountMerchantProcessorsAction, GetAccountMerchantProcessorsSucceedAction,
    GetAccountMerchantProcessorsFailAction } from "./account.actions";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { GlobalSpinnerService } from "shared-lib";
import { AccountsService } from "@admin_api/services/accounts.service";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";

@Injectable()
export class AccountEffects {

    constructor(
        private actions$: Actions,
        private accountsService: AccountsService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private store: Store<IStore>
    ) {}

    getAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAccountAction>(ActionTypes.GetAccount),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetAccountFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.accountsService.vituPayAccountsGetAccount({ id: action.id }).pipe(
                    switchMap((account) => of(new GetAccountSucceedAction(account, false))),
                    catchError((error) => of(new GetAccountFailAction(error)))
                );
            })
        ),
    );

    getNewAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewAccountAction>(ActionTypes.GetNewAccount),
            switchMap(() => {
                const newAccount: MerchantProcessorAccountDto = {};
                return of(new GetAccountSucceedAction(newAccount, true));
            })
        )
    );

    getAccountSucceed = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAccountSucceedAction>(ActionTypes.GetAccountSucceed),
            filter(action => !action.isCreate),
            switchMap((action) => of(new GetAccountMerchantProcessorsAction(action.account.id)))
        )
    );

    getAccountFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAccountFailAction>(ActionTypes.GetAccountFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateAccount$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateAccountAction>(ActionTypes.UpdateAccount),
        switchMap(({ id, account }) =>
            this.globalSpinner.apply(this.accountsService.vituPayAccountsUpdateAccount({ id, body: account }).pipe(
                switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/accounts"] } })))
            ))
        )
    ));

    deleteAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteAccountAction>(ActionTypes.DeleteAccount),
            switchMap(({ id }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete Account",
                        subtitle: "Are you sure you want to permanently delete this account?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ id, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ id }) =>
                this.globalSpinner.apply(
                    this.accountsService.vituPayAccountsDeleteAccount({
                        id
                    }).pipe(
                        switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/accounts"] } })))
                    )
                )
            )
        )
    );

    createAccount$ = createEffect(() => this.actions$.pipe(
        ofType<CreateAccountAction>(ActionTypes.CreateAccount),
        switchMap(({ account }) =>
            this.globalSpinner.apply(
                this.accountsService.vituPayAccountsCreateAccount({ body: account }).pipe(
                    switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/accounts"] } })))
                )
            )
        )
    ));

    getAccountMerchantProcessors$ = createEffect(() => this.actions$.pipe(
        ofType<GetAccountMerchantProcessorsAction>(ActionTypes.GetAccountMerchantProcessors),
        switchMap(({ id }) =>
            this.accountsService.vituPayAccountsMids({ id }).pipe(
                switchMap(merchantProcessors => of(new GetAccountMerchantProcessorsSucceedAction(merchantProcessors))),
                catchError((error) => of(new GetAccountMerchantProcessorsFailAction(error)))

            )
        )
    ));

}
