import { ReportType } from "@admin_app/shared/download-report-modal/report-type";
import { DownloadReportAction } from "@admin_app/storage/report/report.actions";
import { IStore } from "@admin_app/storage/store";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Report } from "../../shared/download-report-modal/report";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.less"],
})
export class ReportsComponent {

    reports: Array<Report> = [
        {
            type: ReportType.TRANSACTIONS,
            name: `${ReportType.TRANSACTIONS} Report`,
            description: "All data held for each transaction processed within a date range",
        },
        {
            type: ReportType.STATEMENT,
            name: ReportType.STATEMENT,
            description: "Merchant statements",
        },
        {
            type: ReportType.RECONCILIATION,
            name: `${ReportType.RECONCILIATION} Report`,
            description: "Identifies any discrepancies between Vitu and Exchange deposit summaries",
        },
        {
            type: ReportType.FUNDING,
            name: `${ReportType.FUNDING} Report`,
            description: "All funding instructions issued within a date range",
        },
    ];

    constructor(private store: Store<IStore>) {}

    onDownloadReport(reportType: ReportType) {

        this.store.dispatch(new DownloadReportAction(reportType));
    }
}
