import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetConfig = "[Config] GetConfig",
    GetConfigSucceed = "[Config] GetConfigSucceed",
    GetConfigFail = "[Config] GetConfigFail"
}

export class GetConfigAction implements Action {

  constructor() {}

  readonly type = ActionTypes.GetConfig;
}

export class GetConfigSucceedAction implements Action {

    constructor(public record: any) {}

    readonly type = ActionTypes.GetConfigSucceed;
}

export class GetConfigFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetConfigFail;
}
