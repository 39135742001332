<app-tid-details
    [isCreate]="isCreate"
    [merchant]="merchant$ | async"
    [tid]="tid$ | async"
    [tidLoading]="tidLoading$ | async"
    [mids]="mids$ | async"
    (reassignDevice)="onReassignDevice($event)"
    (pairDevice)="onPairDevice($event)"
    (deleteTid)="deleteTid($event)"
    (updateTid)="updateTid($event)"
    (createTid)="createTid($event)"
    (cancel)="onCancel()"
    [midCloverDevices]="midCloverDevices$ | async"
    [midCloverDevicesLoading]="midCloverDevicesLoading$ | async"
    [midCloverDevicesError]="midCloverDevicesError$ | async"
    (getCloverDevices)="onGetCloverDevices($event)"
    [dynamicGooglePayMerchantId]="googlePayMerchantId$ | async"
    [dynamicApplePayMerchantId]="applePayMerchantId$ | async"
    [dynamicApplePayConfig]="applePayConfig$ | async"
    (getGooglePayMerchantId)="onGetGooglePayMerchantId($event)"
    (getApplePayMerchantId)="onGetApplePayMerchantId($event)"
    (syncApplePay)="onSyncApplePay($event)"
    (getDomainValidationFile)="onGetDomainValidationFile($event)">
</app-tid-details>
