import { ConfigDto } from "@admin_app/storage/config/config.state";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { ActionButtonKind, ColumnDef, Pager, TableSorting } from "shared-lib";
import { Filter, Sorting } from "@admin_app/storage/organizations/organizations.state";
import { OrganizationOrderBy } from "@admin_api/models/organization-order-by";

@Component({
    selector: "app-organizations-log",
    templateUrl: "./organizations-log.component.html",
    styleUrls: ["./organizations-log.component.less"],
})
export class OrganizationsLogComponent {

    @Input() config: ConfigDto;

    @Input() organizations: OrganizationDto[];
    @Input() organizationsLoading = false;
    @Input() organizationsError: Error;

    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();

    @Input() filter: Filter;
    @Output() filterChanged = new EventEmitter<Filter>();

    ActionButtonKind = ActionButtonKind;

    readonly columnDefs: ColumnDef[];

    constructor(private router: Router) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            { id: OrganizationOrderBy.Id, title: "Organization Ref", flexWidthBasisInPixels: 120, flexWidthGrow: 1, canSort: true},
            { id: OrganizationOrderBy.Name, title: "Organization Name", flexWidthBasisInPixels: 230, flexWidthGrow: 1, canSort: true},
            { id: "contact", title: "Contact", flexWidthBasisInPixels: 260, flexWidthGrow: 1},
            { id: "address", title: "Address", flexWidthBasisInPixels: 200, flexWidthGrow: 1},
            { id: OrganizationOrderBy.Solution, title: "Solution", flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true},
            { id: "isMerchant", title: "Merchant", flexWidthBasisInPixels: 100, flexWidthGrow: 1},
            { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

    onClickAddNewOrganization() {
        this.router.navigate([`/dashboard/organizations/create`]);
    }

    onRowSelected(id: string) {
        this.router.navigate([`/dashboard/organizations/${id}`]);
    }

}
