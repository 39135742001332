import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { LocalTimePoint } from "shared-lib";

@Component({
    selector: "app-tid-filters",
    templateUrl: "./tid-filters.component.html",
    styleUrls: ["./tid-filters.component.less"]
})
export class TidFiltersComponent {

    @Output() filterChanged = new EventEmitter<any>();
    @Input() filter: any;

    showDeletedList = [
        "No",
        "Yes"
    ];

    filterForm = this.fb.group({
        localShowDeleted: [null]
    });

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: UntypedFormBuilder
    ) {}

    onFilterChanged(filter: any) {
        this.filterChanged.emit(filter);
    }

}
