import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetMerchantIdentityKeysAction, GetMerchantIdentityKeysFailAction, GetMerchantIdentityKeysSucceedAction,
    GetPaymentProcessingCertificatesAction, GetPaymentProcessingCertificatesFailAction,
    GetPaymentProcessingCertificatesSucceedAction } from "./apple-pay-keys.actions";
import { ApplePaymentProcessingCertificateService } from "@admin_api/services/apple-payment-processing-certificate.service";
import { AppleMerchantIdentityCertificateService } from "@admin_api/services/apple-merchant-identity-certificate.service";

@Injectable()
export class ApplePayKeysEffects {

    constructor(
        private actions: Actions,
        private applePayService: ApplePaymentProcessingCertificateService,
        private applePayMerchantIdentityService: AppleMerchantIdentityCertificateService
    ) {}

    getPaymentProcessingCertificates$ = createEffect(() =>
        this.actions.pipe(
            ofType<GetPaymentProcessingCertificatesAction>(ActionTypes.GetPaymentProcessingCertificates),
            switchMap(() =>
                this.applePayService.applePaymentProcessingCertificateGetCertificates().pipe(
                    switchMap((digitalWalletKeyVersionDtos) =>
                        of(new GetPaymentProcessingCertificatesSucceedAction(digitalWalletKeyVersionDtos))),
                    catchError((error) => of(new GetPaymentProcessingCertificatesFailAction(error)))
                )
            )
        ),
    );

    getMerchantIdentityKeys$ = createEffect(() =>
        this.actions.pipe(
            ofType<GetMerchantIdentityKeysAction>(ActionTypes.GetMerchantIdentityKeys),
            switchMap(() =>
                this.applePayMerchantIdentityService.appleMerchantIdentityCertificateGetMerchantIdentityCertificates().pipe(
                    switchMap((digitalWalletKeyVersionDtos) => of(new GetMerchantIdentityKeysSucceedAction(digitalWalletKeyVersionDtos))),
                    catchError((error) => of(new GetMerchantIdentityKeysFailAction(error)))
                )
            )
        ),
    );

}
