/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfPayPalEventDeliveryDto } from '../models/paged-data-dto-of-pay-pal-event-delivery-dto';
import { PayPalEventDeliveryOrderByEnum } from '../models/pay-pal-event-delivery-order-by-enum';
import { PayPalEventDeliveryStatusEnum } from '../models/pay-pal-event-delivery-status-enum';

@Injectable({
  providedIn: 'root',
})
export class PayPalEventDeliveriesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation payPalEventDeliveriesSearch
   */
  static readonly PayPalEventDeliveriesSearchPath = '/v1/admin/paypal-event-deliveries';

  /**
   * Retrieves a list of PayPaly event delieveries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payPalEventDeliveriesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalEventDeliveriesSearch$Response(params?: {
    OrderBy?: Array<PayPalEventDeliveryOrderByEnum>;
    Id?: string;
    EventType?: string;
    Status?: PayPalEventDeliveryStatusEnum;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfPayPalEventDeliveryDto>> {

    const rb = new RequestBuilder(this.rootUrl, PayPalEventDeliveriesService.PayPalEventDeliveriesSearchPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('Id', params.Id, {});
      rb.query('EventType', params.EventType, {});
      rb.query('Status', params.Status, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfPayPalEventDeliveryDto>;
      })
    );
  }

  /**
   * Retrieves a list of PayPaly event delieveries.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payPalEventDeliveriesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalEventDeliveriesSearch(params?: {
    OrderBy?: Array<PayPalEventDeliveryOrderByEnum>;
    Id?: string;
    EventType?: string;
    Status?: PayPalEventDeliveryStatusEnum;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfPayPalEventDeliveryDto> {

    return this.payPalEventDeliveriesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfPayPalEventDeliveryDto>) => r.body as PagedDataDtoOfPayPalEventDeliveryDto)
    );
  }

}
