import { GooglePayKeyState } from "./google-pay-key.state";

export const GooglePayKeyReducer = (
    state: GooglePayKeyState = new GooglePayKeyState(),
    action: any,
): GooglePayKeyState => {
    switch (action.type) {
        default:
            return state;
    }

};
