import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Filter } from "@admin_app/storage/adjustments/adjustments.state";
import { LocalTimePoint } from "shared-lib";
import {OrganizationDto } from "@admin_api/models/organization-dto";

@Component({
    selector: "app-adjustments-log-filters",
    templateUrl: "./adjustments-log-filters.component.html",
    styleUrls: ["./adjustments-log-filters.component.less"]
})
export class AdjustmentsLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    readonly adjustmentDateInfoTip = `Adjustments will be included in the
funding on the adjustment date.`;

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: FormBuilder
    ) {}

    filterForm = this.fb.group({
        localDateInterval: [null],
        localDateAdjustedInterval: [null],
        fundingDepositsRef: [null],
        merchantIds: [null],
        mid: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

    get noMerchants(): boolean {
        return (!this.merchantsLoading) && !(this.merchants?.length);
    }

}
