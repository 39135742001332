import { Action } from "@ngrx/store";
import { WebhookEventTypesDto } from "@admin_api/models/webhook-event-types-dto";
import { CreateSolutionWebhookDto } from "@admin_api/models/create-solution-webhook-dto";
import { UpdateSolutionWebhookDto } from "@admin_api/models/update-solution-webhook-dto";
import { SolutionWebhookDto } from "@admin_api/models/solution-webhook-dto";
import { UpdateWebhookDto } from "@admin_api/models/update-webhook-dto";

export enum ActionTypes {
    GetWebhook = "[Webhook] GetWebhook",
    GetNewWebhook = "[Webhook] GetNewWebhook",
    GetWebhookSucceed = "[Webhook] GetWebhookSucceed",
    GetWebhookFail = "[Webhook] GetWebhookFail",
    UpdateWebhook = "[Webhook] UpdateWebhook",
    DeleteWebhook = "[Webhook] DeleteWebhook",
    CreateWebhook = "[Webhook] CreateWebhook",
    ToggleWebhook = "[Webhook] ToggleWebhook",
    ToggleWebhookSucceed = "[Webhook] ToggleWebhookSucceed",
    ToggleWebhookFail = "[Webhook] ToggleWebhookFail",
    GetWebhookLookups = "[Webhook] GetWebhookLookups",
    GetWebhookLookupsSucceed = "[Webhook] GetWebhookLookupsSucceed"
}

export class GetWebhookAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.GetWebhook;
}

export class GetNewWebhookAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewWebhook;
}

export class GetWebhookSucceedAction implements Action {

    constructor(
        public webhook: SolutionWebhookDto,
        public lookups: WebhookEventTypesDto
    ) {}

    readonly type = ActionTypes.GetWebhookSucceed;
}

export class GetWebhookFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetWebhookFail;
}

export class UpdateWebhookAction implements Action {

    constructor(public id: number, public webhook: UpdateSolutionWebhookDto) {}

    readonly type = ActionTypes.UpdateWebhook;
}

export class DeleteWebhookAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DeleteWebhook;
}

export class CreateWebhookAction implements Action {

    constructor(public webhook: CreateSolutionWebhookDto) {}

    readonly type = ActionTypes.CreateWebhook;
}

export class ToggleWebhookAction implements Action {

    constructor(
        public id: number,
        public webhook: UpdateWebhookDto,
        public undo: () => void
    ) {}

    readonly type = ActionTypes.ToggleWebhook;
}

export class ToggleWebhookSucceedAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.ToggleWebhookSucceed;
}

export class ToggleWebhookFailAction implements Action {

    constructor(
        public id: number,
        public error: any,
        public undo: () => void
    ) {}

    readonly type = ActionTypes.ToggleWebhookFail;
}

export class GetWebhookLookupsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetWebhookLookups;
}

export class GetWebhookLookupsSucceedAction implements Action {

    constructor(public lookups: WebhookEventTypesDto) {}

    readonly type = ActionTypes.GetWebhookLookupsSucceed;
}
