import { ModalActionType } from "@admin_app/shared/modal-action";
import { IStore } from "@admin_app/storage/store";
import { OnDestroy } from "@angular/core";
import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { Store } from "@ngrx/store";
import { Subject, Subscription } from "rxjs";
import { ActionButtonKind } from "shared-lib";
import { RequestSettlementModalAction, RequestSettlementParams } from "./request-settlement-modal-action";

@Component({
    selector: "app-request-settlement-modal",
    templateUrl: "./request-settlement-modal.component.html",
    styleUrls: ["./request-settlement-modal.component.less"]
})
export class RequestSettlementModalComponent implements OnDestroy {

    get formControls() {
        return this.requestSettlementForm && this.requestSettlementForm.controls;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IStore>,
        public dialogRef: MatDialogRef<RequestSettlementModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            id: number;
            errorMessageSubject: Subject<string>;
            modalActionSubject: Subject<RequestSettlementModalAction>;
        }
    ) {}

    private subscriptions = new Subscription();

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ActionButtonKind = ActionButtonKind;

    requestSettlementForm = this.fb.group({
        dateSingleField: [null, [Validators.required]],
        amount: [null, [Validators.required, this.amountValidator.bind(this)]]
    });

    getFormControl(name: string): UntypedFormControl {
        return this.formControls[name] as UntypedFormControl;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onMouseDown() {
        this.data.errorMessageSubject.next("");
    }

    onCancel(event?: any) {
        if (event) {
            event.preventDefault();
        }
        this.data.modalActionSubject.next(new RequestSettlementModalAction(ModalActionType.CANCEL));
    }

    onSubmit() {
        const params: RequestSettlementParams = {};

        params.id = this.data.id;

        const dateSingle = this.getSubmitValueForField("dateSingleField");
        if (dateSingle) {
            params.startDate = dateSingle.from;
            params.endDate = dateSingle.to;
        }

        params.amount = parseFloat(this.getSubmitValueForField("amount"));

        this.data.modalActionSubject.next(new RequestSettlementModalAction(ModalActionType.CONFIRM, params));
    }

    private getSubmitValueForField(fieldName: string): any {
        const fieldValue = this.requestSettlementForm.get(fieldName).value;
        return (fieldValue ? fieldValue : undefined);
    }

    private amountValidator(control: AbstractControl) {

        if (control.value === "0.00") {
            return { amountInvalid: { valid: false } };
        }

        return null;

    }

}
