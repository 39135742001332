import { DigitalWalletKeyVersionDto } from "@admin_api/models/digital-wallet-key-version-dto";

export class ApplePayKeysState {
    error = null;
    record: Array<DigitalWalletKeyVersionDto> = [];
    loading = false;
    merchantIdentityLoading = false;
    merchantIdentityRecord: Array<DigitalWalletKeyVersionDto> = [];
    merchantIdentityError = null;
}
