import { AdjustmentDto } from "@admin_api/models/adjustment-dto";
import { FundingFee } from "@admin_api/models/funding-fee";
import { FundingResultDetails } from "@admin_api/models/funding-result-details";
import { FundingTransaction } from "@admin_api/models/funding-transaction";
import { FundingTransactionOrderBy } from "@admin_api/models/funding-transaction-order-by";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { createFilter, DateRange, FilterBase, getInitialLocalFilterDateRangeLastTwoWeeks, Pager } from "shared-lib";

export class TransactionsSorting {
    orderBy: null | FundingTransactionOrderBy;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class TransactionsFilter extends FilterBase<TransactionsFilter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    localFeesOnly: string = undefined;
}

export class FundingItemState {
    loading = false;
    record: FundingResultDetails = null;
    error = null;
    transactionsPager = new Pager();
    transactions: Array<FundingTransaction> = [];
    transactionsLoading = false;
    transactionsError: Error;
    transactionsSorting = new TransactionsSorting();
    transactionsFilter = createFilter(TransactionsFilter);
    exportLoading = false;
    exportError = null;
    midFees: Array<FundingFee> = [];
    midFeesLoading = false;
    midFeesError: Error = null;
    adjustments: Array<AdjustmentDto> = [];
    adjustmentsLoading = false;
    adjustmentsError: Error = null;
}
