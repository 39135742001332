/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AppleMerchantIdentityCertificateService } from './services/apple-merchant-identity-certificate.service';
import { ApplePaymentProcessingCertificateService } from './services/apple-payment-processing-certificate.service';
import { BinFilesService } from './services/bin-files.service';
import { DfmFilesService } from './services/dfm-files.service';
import { DisputesService } from './services/disputes.service';
import { EventsService } from './services/events.service';
import { GooglePaymentProcessingCertificateService } from './services/google-payment-processing-certificate.service';
import { InfrastructureService } from './services/infrastructure.service';
import { InventoryService } from './services/inventory.service';
import { MerchantsService } from './services/merchants.service';
import { OrganizationRolesService } from './services/organization-roles.service';
import { OrganizationsService } from './services/organizations.service';
import { PaymentMethodsService } from './services/payment-methods.service';
import { PaymentsService } from './services/payments.service';
import { PayPalEventDeliveriesService } from './services/pay-pal-event-deliveries.service';
import { PayPalReportFilesService } from './services/pay-pal-report-files.service';
import { PermissionsService } from './services/permissions.service';
import { RolesService } from './services/roles.service';
import { SolutionsService } from './services/solutions.service';
import { UsersService } from './services/users.service';
import { AccountsService } from './services/accounts.service';
import { WebhooksService } from './services/webhooks.service';
import { BillingService } from './services/billing.service';
import { FundingService } from './services/funding.service';
import { IndustriesService } from './services/industries.service';
import { ReportsService } from './services/reports.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AppleMerchantIdentityCertificateService,
    ApplePaymentProcessingCertificateService,
    BinFilesService,
    DfmFilesService,
    DisputesService,
    EventsService,
    GooglePaymentProcessingCertificateService,
    InfrastructureService,
    InventoryService,
    MerchantsService,
    OrganizationRolesService,
    OrganizationsService,
    PaymentMethodsService,
    PaymentsService,
    PayPalEventDeliveriesService,
    PayPalReportFilesService,
    PermissionsService,
    RolesService,
    SolutionsService,
    UsersService,
    AccountsService,
    WebhooksService,
    BillingService,
    FundingService,
    IndustriesService,
    ReportsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
