<app-adjustments-log
    [adjustments]="adjustments$ | async"
    [adjustmentsLoading]="adjustmentsLoading$ | async"
    [adjustmentsError]="adjustmentsError$ | async"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)"
    [filter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)"
    [merchants]="merchants$ | async"
    [merchantsLoading]="merchantsLoading$ | async">
</app-adjustments-log>
