/* tslint:disable */
/* eslint-disable */

/**
 * Role of the person party played in the business.
 * https://developer.paypal.com/api/limited-release/managed-accounts/v3/#definition-office_bearer_role
 */
export enum OfficeBearerRole {
  CEO = 'CEO',
  CHAIRMAN = 'CHAIRMAN',
  DIRECTOR = 'DIRECTOR',
  SECRETARY = 'SECRETARY',
  TREASURER = 'TREASURER',
  TRUSTEE = 'TRUSTEE'
}
