<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Webhooks' }, { 'label': 'Event History' , 'link': '/dashboard/webhooks/event-history' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="!event then spinner"></ng-container>
        <ng-container *ngIf="event then formFields"></ng-container>

    </div>

</div>

<ng-template #formFields>

    <div class="details-common-container">

        <lib-text-field label="ID">
            {{ event.id }}
        </lib-text-field>

        <lib-text-field id="eventTypeTextField" label="Event Type">
            {{ event.eventType }}
        </lib-text-field>

        <lib-text-field [label]="'Date (' + LocalTimePoint.formatZ() + ')'">
            <lib-date-time-label *ngIf="event.createdUtc" [singleLine]="true" [dateUtc]="event.createdUtc"></lib-date-time-label>
        </lib-text-field>

    </div>

    <div class="label">
        DETAILS
    </div>

    <div class="details-custom-container" *ngIf="this.dataSource">

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <!-- use a disabled button to provide padding for tree leaf -->
              <button mat-icon-button disabled></button>
              {{node.name}}
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                <i [class]="'far ' + (treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right')"></i>
              </button>
              {{node.name}}
            </mat-tree-node>
        </mat-tree>

    </div>

    <div class="label">
        <ng-container *ngIf="eventDeliveriesLoading || eventDeliveries?.length > 0; else deliveriesNotLoaded">
            DELIVERIES
        </ng-container>
        <ng-template #deliveriesNotLoaded>
            <ng-container *ngIf="eventDeliveriesError; else noDeliveries">
                ERROR LOADING DELIVERIES
            </ng-container>
            <ng-template #noDeliveries>
                NO DELIVERIES
            </ng-template>
        </ng-template>
    </div>

    <div *ngIf="eventDeliveriesLoading || eventDeliveries?.length > 0" class="events-container">

        <lib-table-base
            #eventDeliveriesTableComponent
            [columnDefs]="eventDeliveriesColumnDefs"
            [rowsData]="eventDeliveries"
            [rowsDataLoading]="eventDeliveriesLoading"
            [rowsDataShowWhileLoading]="true"
            rowsDataEmptyMessage="No Deliveries"
            [pager]="pager"
            (pageChanged)="onPageChanged($event)"
            [sorting]="sorting"
            (sortingChanged)="onSortingChanged($event)">

            <ng-template let-element="element" let-columnId="columnId">
                <app-event-deliveries-table-cell-content
                    [columnId]="columnId"
                    [element]="element"
                    (resend)="onResend($event)">
                </app-event-deliveries-table-cell-content>
            </ng-template>
        </lib-table-base>

    </div>

    <div class="form-buttons-line">
        <lib-action-button
            [kind]="ActionButtonKind.STROKED"
            largeButton
            label="Back"
            (click)="onCancel()">
        </lib-action-button>
    </div>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>
