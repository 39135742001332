import { ReportType } from "@admin_app/shared/download-report-modal/report-type";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnDef } from "shared-lib";
import { Report } from "../../../shared/download-report-modal/report";

@Component({
    selector: "app-reports-log",
    templateUrl: "./reports-log.component.html",
    styleUrls: ["./reports-log.component.less"],
})
export class ReportsLogComponent {

    @Input() reports: Array<Report> = [];
    @Output() downloadReport = new EventEmitter<ReportType>();

    readonly columnDefs: ColumnDef[];

    constructor() {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: "name", title: "Report Type", flexWidthBasisInPixels: 250, flexWidthGrow: 0, canSort: true },
            {id: "description", title: "Description", flexWidthBasisInPixels: 550, flexWidthGrow: 0, canSort: true },
            {id: "download", title: "Download", flexWidthBasisInPixels: 80, flexWidthGrow: 1},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onDownloadReport(reportType: ReportType) {
        this.downloadReport.emit(reportType);
    }

}
