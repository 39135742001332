<div class="outer-container">
    
    <lib-title-bar
        [crumbs]="[{ 'label': 'Devices' }]">
    </lib-title-bar>
 
    <app-devices-log-filters
        [filter]="filter"
        (filterChanged)="filterChanged.emit($event)"
        [merchants]="merchants"
        [merchantsLoading]="merchantsLoading">
    </app-devices-log-filters>

    <lib-table-base
        class="devices-table"
        [columnDefs]="columnDefs"
        [rowsData]="inventory"
        [rowsDataLoading]="inventoryLoading"
        [rowsDataShowWhileLoading]="true"
        [rowsDataError]="inventoryError"
        rowsDataErrorMessage="Error Loading Devices"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-devices-table-cell-content
                [columnId]="columnId"
                [element]="element"
                [deviceUnderUpdateLoading]="(element.tid === device.tid) ? deviceLoading : null">
            </app-devices-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
