import { Component } from "@angular/core";
import { IStore } from "@admin_app/storage/store";
import { Store } from "@ngrx/store";
import * as WebhookActions from "@admin_app/storage/webhook/webhook.actions";
import { getWebhooks, getWebhooksError, getWebhooksLoading } from "@admin_app/storage/webhooks/webhooks.selectors";
import { getToggleWebhooksLoading } from "@admin_app/storage/webhook/webhook.selectors";
import { getConfig } from "@admin_app/storage/config/config.selectors";
import { WebhookDto } from "@admin_api/models/webhook-dto";

@Component({
    selector: "app-webhooks",
    templateUrl: "./webhooks.component.html",
    styleUrls: [ "./webhooks.component.less" ]
})
export class WebhooksComponent {

    config$ = this.store.select(getConfig);
    webhooks$ = this.store.select(getWebhooks);
    loading$ = this.store.select(getWebhooksLoading);
    error$ = this.store.select(getWebhooksError);
    toggleWebhooksLoading$ = this.store.select(getToggleWebhooksLoading);

    constructor(private store: Store<IStore>) {}

    onIsActiveToggled(payload: {webhook: WebhookDto; undo: () => void}) {
        this.store.dispatch(new WebhookActions.ToggleWebhookAction(payload.webhook.id, payload.webhook, payload.undo));
    }

}
