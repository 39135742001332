/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DigitalWalletKeyVersionDto } from '../models/digital-wallet-key-version-dto';
import { DigitalWalletKeysDto } from '../models/digital-wallet-keys-dto';
import { DigitalWalletPublicKeyDto } from '../models/digital-wallet-public-key-dto';

@Injectable({
  providedIn: 'root',
})
export class GooglePaymentProcessingCertificateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateGet
   */
  static readonly GooglePaymentProcessingCertificateGetPath = '/v1/admin/google-payment-processing-certificate/{token}';

  /**
   * Gets public key with additional info by token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGet$Response(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<StrictHttpResponse<DigitalWalletPublicKeyDto>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletPublicKeyDto>;
      })
    );
  }

  /**
   * Gets public key with additional info by token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGet(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<DigitalWalletPublicKeyDto> {

    return this.googlePaymentProcessingCertificateGet$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletPublicKeyDto>) => r.body as DigitalWalletPublicKeyDto)
    );
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateGetPublicKey
   */
  static readonly GooglePaymentProcessingCertificateGetPublicKeyPath = '/v1/admin/google-payment-processing-certificate/{token}/get-public-key';

  /**
   * Downloads file with public key inside.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateGetPublicKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGetPublicKey$Response(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateGetPublicKeyPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Downloads file with public key inside.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateGetPublicKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGetPublicKey(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<Blob> {

    return this.googlePaymentProcessingCertificateGetPublicKey$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateGetPublicKeyHash
   */
  static readonly GooglePaymentProcessingCertificateGetPublicKeyHashPath = '/v1/admin/google-payment-processing-certificate/{token}/get-public-key-hash';

  /**
   * Gets hash of file with public key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateGetPublicKeyHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGetPublicKeyHash$Response(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateGetPublicKeyHashPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Gets hash of file with public key.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateGetPublicKeyHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGetPublicKeyHash(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<string> {

    return this.googlePaymentProcessingCertificateGetPublicKeyHash$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateCreate
   */
  static readonly GooglePaymentProcessingCertificateCreatePath = '/v1/admin/google-payment-processing-certificate/create';

  /**
   * Creates Google payment processing certificate.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateCreate$Response(params?: {
  }): Observable<StrictHttpResponse<DigitalWalletPublicKeyDto>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateCreatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletPublicKeyDto>;
      })
    );
  }

  /**
   * Creates Google payment processing certificate.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateCreate(params?: {
  }): Observable<DigitalWalletPublicKeyDto> {

    return this.googlePaymentProcessingCertificateCreate$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletPublicKeyDto>) => r.body as DigitalWalletPublicKeyDto)
    );
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateSetAsRegistered
   */
  static readonly GooglePaymentProcessingCertificateSetAsRegisteredPath = '/v1/admin/google-payment-processing-certificate/{token}/set-as-registered';

  /**
   * Set payment processing certificate as registered by token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateSetAsRegistered()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateSetAsRegistered$Response(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<StrictHttpResponse<DigitalWalletKeyVersionDto>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateSetAsRegisteredPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletKeyVersionDto>;
      })
    );
  }

  /**
   * Set payment processing certificate as registered by token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateSetAsRegistered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateSetAsRegistered(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<DigitalWalletKeyVersionDto> {

    return this.googlePaymentProcessingCertificateSetAsRegistered$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletKeyVersionDto>) => r.body as DigitalWalletKeyVersionDto)
    );
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateRevoke
   */
  static readonly GooglePaymentProcessingCertificateRevokePath = '/v1/admin/google-payment-processing-certificate/{token}/revoke';

  /**
   * Revoke google payment processing certificate by token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateRevoke()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateRevoke$Response(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<StrictHttpResponse<DigitalWalletKeyVersionDto>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateRevokePath, 'post');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletKeyVersionDto>;
      })
    );
  }

  /**
   * Revoke google payment processing certificate by token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateRevoke$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateRevoke(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<DigitalWalletKeyVersionDto> {

    return this.googlePaymentProcessingCertificateRevoke$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletKeyVersionDto>) => r.body as DigitalWalletKeyVersionDto)
    );
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateSetOrderAsPrimary
   */
  static readonly GooglePaymentProcessingCertificateSetOrderAsPrimaryPath = '/v1/admin/google-payment-processing-certificate/{token}/set-order-as-primary';

  /**
   * Set payment processing certificate as primary by certificate token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateSetOrderAsPrimary()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateSetOrderAsPrimary$Response(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<StrictHttpResponse<DigitalWalletKeyVersionDto>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateSetOrderAsPrimaryPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletKeyVersionDto>;
      })
    );
  }

  /**
   * Set payment processing certificate as primary by certificate token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateSetOrderAsPrimary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateSetOrderAsPrimary(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<DigitalWalletKeyVersionDto> {

    return this.googlePaymentProcessingCertificateSetOrderAsPrimary$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletKeyVersionDto>) => r.body as DigitalWalletKeyVersionDto)
    );
  }

  /**
   * Path part for operation googlePaymentProcessingCertificateGetCertificates
   */
  static readonly GooglePaymentProcessingCertificateGetCertificatesPath = '/v1/admin/google-payment-processing-certificate/get-certificates';

  /**
   * Gets the list of 3 last google payment processing certificates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googlePaymentProcessingCertificateGetCertificates()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGetCertificates$Response(params?: {
  }): Observable<StrictHttpResponse<DigitalWalletKeysDto>> {

    const rb = new RequestBuilder(this.rootUrl, GooglePaymentProcessingCertificateService.GooglePaymentProcessingCertificateGetCertificatesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletKeysDto>;
      })
    );
  }

  /**
   * Gets the list of 3 last google payment processing certificates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `googlePaymentProcessingCertificateGetCertificates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googlePaymentProcessingCertificateGetCertificates(params?: {
  }): Observable<DigitalWalletKeysDto> {

    return this.googlePaymentProcessingCertificateGetCertificates$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletKeysDto>) => r.body as DigitalWalletKeysDto)
    );
  }

}
