/* tslint:disable */
/* eslint-disable */
export enum PosTerminalOrderBy {
  MerchantName = 'MerchantName',
  MID = 'MID',
  TID = 'TID',
  Location = 'Location',
  DeviceSerial = 'DeviceSerial',
  DeviceName = 'DeviceName',
  DeviceManufacturer = 'DeviceManufacturer',
  DeviceModel = 'DeviceModel',
  Status = 'Status'
}
