<app-adjustment-details
    [isCreate]="isCreate"
    [adjustment]="adjustment$ | async"
    [adjustmentLoading]="adjustmentLoading$ | async"
    [merchants]="merchants$ | async"
    [merchantsLoading]="merchantsLoading$ | async"
    [merchantsError]="merchantsError$ | async"
    [mids]="mids$ | async"
    [midsLoading]="midsLoading$ | async"
    [midsError]="midsError$ | async"
    (createAdjustment)="createAdjustment($event)"
    (deleteAdjustment)="deleteAdjustment($event)"
    (cancel)="onCancel()"
    (merchantChange)="onMerchantChanged($event)">
</app-adjustment-details>
