import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of, Subject } from "rxjs";
import { catchError, map, switchMap, filter as filterOperator, withLatestFrom } from "rxjs/operators";
import { ActionTypes, GetPaymentProcessingCsrAction, GetMerchantIdentityCsrAction, RevokePaymentProcessingCertificateKeyAction,
    RevokeMerchantIdentityKeyAction, UploadPaymentProcessingCertificateAction,
    UploadMerchantIdentityCertificateAction } from "./apple-pay-key.actions";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationModalComponent, ConfirmationModalData, GlobalSpinnerService, VituToastService, VituToastTone } from "shared-lib";
import { GetMerchantIdentityKeysAction, GetPaymentProcessingCertificatesAction } from "../apple-pay-keys/apple-pay-keys.actions";
import { ApplePaymentProcessingCertificateService } from "@admin_api/services/apple-payment-processing-certificate.service";
import { UploadFileModalAction } from "@admin_app/shared/upload-file-modal/upload-file-modal-action";
import { UploadFileModalComponent } from "@admin_app/shared/upload-file-modal/upload-file-modal.component";
import { ModalActionType } from "@admin_app/shared/modal-action";
import { DigitalWalletKeyVersionDto } from "@admin_api/models/digital-wallet-key-version-dto";
import { AppleMerchantIdentityCertificateService } from "@admin_api/services/apple-merchant-identity-certificate.service";
import { getApplePayKeys, getApplePayMerchantIdentityKeys } from "../apple-pay-keys/apple-pay-keys.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { DigitalWalletCertificateStatusEnum } from "@admin_api/models/digital-wallet-certificate-status-enum";
import { CommonUtils } from "../common";

@Injectable()
export class ApplePayKeyEffects {

    constructor(
        private actions: Actions,
        private applePayService: ApplePaymentProcessingCertificateService,
        private applePayMerchantIdentityService: AppleMerchantIdentityCertificateService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private toast: VituToastService,
        private store: Store<IStore>
    ) {}

    revokePaymentProcessingCertificateKey = createEffect(() => this.actions.pipe(
        ofType<RevokePaymentProcessingCertificateKeyAction>(ActionTypes.RevokePaymentProcessingCertificateKey),
        switchMap(({ token }) => this.dialog.open(ConfirmationModalComponent, {
            width: "700px",
            data: {
                title: "Revoke Certificate",
                subtitle: "Are you sure you want to revoke this certificate?",
                confirmButtonText: "Revoke"
            } as ConfirmationModalData
        }).afterClosed().pipe(
            map((confirmed: boolean) => ({ token, confirmed })),
        )),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(({ token }) =>
            this.globalSpinner.apply(
                this.applePayService.applePaymentProcessingCertificateRevoke({ token }).pipe(
                    switchMap(() =>
                        of(new GetPaymentProcessingCertificatesAction()))
                )
            )
        )
    ));

    uploadPaymentProcessingCertificate$ = createEffect((): any => this.actions.pipe(
        ofType<UploadPaymentProcessingCertificateAction>(ActionTypes.UploadPaymentProcessingCertificate),
        withLatestFrom(this.store.select(getApplePayKeys)),
        switchMap(([{}, applePayKeys]) => {
            const numberOfRegisteredKeys =
                applePayKeys.reduce(
                    (current, key) => current += (key.status === DigitalWalletCertificateStatusEnum.Registered) ? 1 : 0, 0);
            if (numberOfRegisteredKeys < 2) {
                return of({ confirmed: true });
            }
            else {
                return this.dialog.open(ConfirmationModalComponent, {
                    width: "700px",
                    data: {
                        title: "Action Unavailable",
                        // eslint-disable-next-line max-len
                        subtitle: "Can only upload a new Payment Processing certificate when there are less than two registered Payment Processing certificates.",
                        subtitle2: "Please revoke an existing Payment Processing certificate and try again.",
                        confirmButtonText: "OK",
                        noCancel: true
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map(() => ({ confirmed: false })),
                );
            }
        }),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(() => {

            const errorMessageSubject = new Subject<string>();
            const modalActionSubject = new Subject<UploadFileModalAction>();

            const dialogRef = this.dialog
                .open(UploadFileModalComponent, {
                    width: "500px",
                    data: {
                        modalActionSubject,
                        errorMessageSubject,
                        dialogTitle: "Upload Certificate",
                        dialogMessage: "Select the Apple Pay Payment Processing certificate file to upload:",
                        fileLabel: "Certificate File"
                    },
                    disableClose: true,
                    autoFocus: false
                });
            return modalActionSubject.pipe(
                switchMap(({action, params}: UploadFileModalAction) => {
                    switch (action) {
                        case ModalActionType.CANCEL:
                        {
                            dialogRef.close();
                            return of();
                        }
                        case ModalActionType.CONFIRM:
                        {
                            return this.globalSpinner.apply(
                                this.applePayService.applePaymentProcessingCertificateUploadCertificate(
                                    {
                                        body: { pkcsFile: params.file }
                                    }
                                )
                                .pipe(
                                    switchMap((response: DigitalWalletKeyVersionDto) => {
                                        dialogRef.close();
                                        this.toast.open(
                                            `Certificate uploaded successfully.`,
                                            VituToastTone.Positive);
                                        return of(new GetPaymentProcessingCertificatesAction());
                                    }),
                                    catchError(error => {
                                        errorMessageSubject.next(error instanceof Error ? error.message : error);
                                        return of();
                                    })
                                )
                            );
                        }
                    }
                })
            );
        })
    ));

    getPaymentProcessingCsr$ = createEffect(() =>
        this.actions.pipe(
            ofType<GetPaymentProcessingCsrAction>(ActionTypes.GetPaymentProcessingCsr),
            withLatestFrom(this.store.select(getApplePayKeys)),
            switchMap(([{}, applePayKeys]) => {
                const numberOfRegisteredKeys =
                    applePayKeys.reduce(
                        (current, key) => current += (key.status === DigitalWalletCertificateStatusEnum.Registered) ? 1 : 0, 0);
                if (numberOfRegisteredKeys < 2) {
                    return of({ confirmed: true });
                }
                else {
                    return this.dialog.open(ConfirmationModalComponent, {
                        width: "700px",
                        data: {
                            title: "Action Unavailable",
                            // eslint-disable-next-line max-len
                            subtitle: "Can only download a new Payment Processing CSR when there are less than two registered Payment Processing certificates.",
                            subtitle2: "Please revoke an existing Payment Processing certificate and try again.",
                            confirmButtonText: "OK",
                            noCancel: true
                        } as ConfirmationModalData
                    }).afterClosed().pipe(
                        map(() => ({ confirmed: false })),
                    );
                }
            }),
            filterOperator(({ confirmed }) => confirmed),
            switchMap(() =>
                this.globalSpinner.apply(this.applePayService.applePaymentProcessingCertificateGetCsr$Response()
                    .pipe(
                        map((response: any) => {
                            CommonUtils.downloadFile(response);
                            return true;
                        })
                    )
                )
            )
        ),
    { dispatch: false });

    revokeMerchantIdentityKey = createEffect(() => this.actions.pipe(
        ofType<RevokeMerchantIdentityKeyAction>(ActionTypes.RevokeMerchantIdentityKey),
        switchMap(({ token }) => this.dialog.open(ConfirmationModalComponent, {
            width: "700px",
            data: {
                title: "Revoke Certificate",
                subtitle: "Are you sure you want to revoke this certificate?",
                confirmButtonText: "Revoke"
            } as ConfirmationModalData
        }).afterClosed().pipe(
            map((confirmed: boolean) => ({ token, confirmed })),
        )),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(({ token }) =>
            this.globalSpinner.apply(
                this.applePayMerchantIdentityService.appleMerchantIdentityCertificateRevokeProcessingCertificate({ token }).pipe(
                    switchMap(() =>
                        of(new GetMerchantIdentityKeysAction()))
                )
            )
        )
    ));

    uploadMerchantIdentityCertificate$ = createEffect((): any => this.actions.pipe(
        ofType<UploadMerchantIdentityCertificateAction>(ActionTypes.UploadMerchantIdentityCertificate),
        withLatestFrom(this.store.select(getApplePayMerchantIdentityKeys)),
        switchMap(([{}, applePayMerchantIdentityKeys]) => {
            const numberOfRegisteredKeys =
                applePayMerchantIdentityKeys.reduce(
                    (current, key) => current += (key.status === DigitalWalletCertificateStatusEnum.Registered) ? 1 : 0, 0);
            if (numberOfRegisteredKeys < 2) {
                return of({ confirmed: true });
            }
            else {
                return this.dialog.open(ConfirmationModalComponent, {
                    width: "700px",
                    data: {
                        title: "Action Unavailable",
                        // eslint-disable-next-line max-len
                        subtitle: "Can only upload a new Merchant Identity certificate when there are less than two registered Merchant Identity certificates.",
                        subtitle2: "Please revoke an existing Merchant Identity certificate and try again.",
                        confirmButtonText: "OK",
                        noCancel: true
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map(() => ({ confirmed: false })),
                );
            }
        }),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(() => {

            const errorMessageSubject = new Subject<string>();
            const modalActionSubject = new Subject<UploadFileModalAction>();

            const dialogRef = this.dialog
                .open(UploadFileModalComponent, {
                    width: "500px",
                    data: {
                        modalActionSubject,
                        errorMessageSubject,
                        dialogTitle: "Upload Certificate",
                        dialogMessage: "Select the Apple Pay Merchant Identity certificate file to upload:",
                        fileLabel: "Certificate File"
                    },
                    disableClose: true,
                    autoFocus: false
                });
            return modalActionSubject.pipe(
                switchMap(({action, params}: UploadFileModalAction) => {
                    switch (action) {
                        case ModalActionType.CANCEL:
                        {
                            dialogRef.close();
                            return of();
                        }
                        case ModalActionType.CONFIRM:
                        {
                            return this.globalSpinner.apply(
                                this.applePayMerchantIdentityService.appleMerchantIdentityCertificateUploadCertificate(
                                    {
                                        body: { pkcsFile: params.file }
                                    }
                                )
                                .pipe(
                                    switchMap((response: DigitalWalletKeyVersionDto) => {
                                        dialogRef.close();
                                        this.toast.open(
                                            `Certificate uploaded successfully.`,
                                            VituToastTone.Positive);
                                        return of(new GetMerchantIdentityKeysAction());
                                    }),
                                    catchError(error => {
                                        errorMessageSubject.next(error instanceof Error ? error.message : error);
                                        return of();
                                    })
                                )
                            );
                        }
                    }
                })
            );
        })
    ));

    getMerchantIdentityCsr$ = createEffect(() =>
        this.actions.pipe(
            ofType<GetMerchantIdentityCsrAction>(ActionTypes.GetMerchantIdentityCsr),
            withLatestFrom(this.store.select(getApplePayMerchantIdentityKeys)),
            switchMap(([{}, applePayMerchantIdentityKeys]) => {
                const numberOfRegisteredKeys =
                    applePayMerchantIdentityKeys.reduce(
                        (current, key) => current += (key.status === DigitalWalletCertificateStatusEnum.Registered) ? 1 : 0, 0);
                if (numberOfRegisteredKeys < 2) {
                    return of({ confirmed: true });
                }
                else {
                    return this.dialog.open(ConfirmationModalComponent, {
                        width: "700px",
                        data: {
                            title: "Action Unavailable",
                            // eslint-disable-next-line max-len
                            subtitle: "Can only download a new Merchant Identity CSR when there are less than two registered Merchant Identity certificates.",
                            subtitle2: "Please revoke an existing Merchant Identity certificate and try again.",
                            confirmButtonText: "OK",
                            noCancel: true
                        } as ConfirmationModalData
                    }).afterClosed().pipe(
                        map(() => ({ confirmed: false })),
                    );
                }
            }),
            filterOperator(({ confirmed }) => confirmed),
            switchMap(() =>
                this.globalSpinner.apply(this.applePayMerchantIdentityService.appleMerchantIdentityCertificateGetCsr$Response()
                    .pipe(
                        map((response: any) => {
                            CommonUtils.downloadFile(response);
                            return true;
                        })
                    )
                )
            )
        ),
    { dispatch: false });

}
