import { DigitalWalletCertificateOrderEnum } from "@admin_api/models/digital-wallet-certificate-order-enum";
import { DigitalWalletCertificateStatusEnum } from "@admin_api/models/digital-wallet-certificate-status-enum";
import { DigitalWalletEnum } from "@admin_api/models/digital-wallet-enum";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionButtonKind } from "shared-lib";

@Component({
    selector: "app-google-pay-certificates-table-cell-content",
    templateUrl: "./google-pay-certificates-table-cell-content.component.html",
    styleUrls: ["./google-pay-certificates-table-cell-content.component.less"],
})
export class GooglePayCertificatesTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;

    @Output() revoke = new EventEmitter<string>();
    @Output() register = new EventEmitter<string>();
    @Output() download = new EventEmitter<string>();
    @Output() getPublicKeyHash = new EventEmitter<string>();
    @Output() makePrimary = new EventEmitter<string>();

    DigitalWalletEnum = DigitalWalletEnum;
    DigitalWalletCertificateStatusEnum = DigitalWalletCertificateStatusEnum;
    DigitalWalletCertificateOrderEnum = DigitalWalletCertificateOrderEnum;
    ActionButtonKind = ActionButtonKind;

    actionButtonKind = ActionButtonKind.SECONDARY;
    actionButtonHasFontImage = false;
    actionButtonIconAfterText = true;

    onClickRevoke() {
        if (this.element) {
            this.revoke.emit(this.element.token);
        }
    }

    onClickRegister() {
        if (this.element) {
            this.register.emit(this.element.token);
        }
    }

    onClickDownload() {
        if (this.element) {
            this.download.emit(this.element.token);
        }
    }

    onClickGetPublicKeyHash() {
        if (this.element) {
            this.getPublicKeyHash.emit(this.element.token);
        }
    }

    onClickMakePrimary() {
        if (this.element) {
            this.makePrimary.emit(this.element.token);
        }
    }

    get sortingKeyForDateExpired(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.dateExpired).getTime();
    }

    get sortingKeyForDateCreated(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.dateCreated).getTime();
    }

    get sortingKeyForActual(): number {
        return this.element.actual ? 1 : 0;
    }

    get sortingKeyForType(): number {
        const digitalWalletTypes = Object.values(DigitalWalletEnum);
        return digitalWalletTypes.findIndex(digitalWalletType => digitalWalletType === this.element.type);
    }

    get sortingKeyForRevoke(): number {
        return (this.element?.status !== DigitalWalletCertificateStatusEnum.Revoked) ? 1 : 0;
    }

    get sortingKeyForPublicKey(): number {
        return (this.element?.status !== DigitalWalletCertificateStatusEnum.Revoked) ? 1 : 0;
    }

    get sortingKeyForRegister(): number {
        return (this.element?.status === DigitalWalletCertificateStatusEnum.New) ? 1 : 0;
    }

    get sortingKeyForMakePrimary(): number {
        return (
            (this.element?.status === DigitalWalletCertificateStatusEnum.New) &&
            (this.element?.order === DigitalWalletCertificateOrderEnum.Secondary)) ? 1 : 0;
    }

    get fontImageForRegister() {
        return (this.actionButtonHasFontImage) ? "fas fa-registered fa-bg" : undefined;
    }

    get fontImageForDownload() {
        return (this.actionButtonHasFontImage) ? "far fa-file-download fa-bg" : undefined;
    }

    get fontImageForGetPublicKeyHash() {
        return (this.actionButtonHasFontImage) ? "far fa-hashtag fa-bg" : undefined;
    }

    get fontImageForRevoke() {
        return (this.actionButtonHasFontImage) ? "far fa-ban fa-bg" : undefined;
    }

}
