<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    suppressInitialRequest
    shortLayout>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-short-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="localShowDeleted"
                        isFilter
                        label="Show Deleted MIDs"
                        [options]="showDeletedList"
                        (handleReset)="filtersBase.resetField('localShowDeleted')">
                    </lib-vitu-form-field-select>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
