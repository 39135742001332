import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef, TableSorting, Pager, LocalTimePoint } from "shared-lib";
import { Sorting } from "@admin_app/storage/fee-plans/fee-plans.state";
import { FeePlanDto } from "@admin_api/models/fee-plan-dto";
import { FeePlanOrderBy } from "@admin_api/models/fee-plan-order-by";

@Component({
    selector: "app-fee-plans-log",
    templateUrl: "./fee-plans-log.component.html",
    styleUrls: ["./fee-plans-log.component.less"],
})
export class FeePlansLogComponent {

    @Input() feePlans: Array<FeePlanDto> = [];
    @Input() feePlansLoading = false;
    @Input() feePlansError: Error;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() uploadPlanCode = new EventEmitter<void>();

    readonly columnDefs: ColumnDef[];

    constructor(private router: Router) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: FeePlanOrderBy.Id, title: "Fee Plan Ref", flexWidthBasisInPixels: 200, flexWidthGrow: 0, canSort: true},
            {id: FeePlanOrderBy.Name, title: "Name", flexWidthBasisInPixels: 400, flexWidthGrow: 0, canSort: true},
            {id: FeePlanOrderBy.Description, title: "Description", flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
/*
            {id: "lastEdited", title: `Last Edited Date (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: "user", title: "User", flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
*/
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

    onClickAddNewFeePlan() {
        this.router.navigate([`/dashboard/fee-plans/create`]);
    }

    onRowSelected(id: string) {
        this.router.navigate([`/dashboard/fee-plans/${id}`]);
    }

}
