/* tslint:disable */
/* eslint-disable */

/**
 * The business
 * https://developer.paypal.com/api/limited-release/managed-accounts/v3/#definition-customer_common_components_overrides-business_name_type
 */
export enum BusinessNameType {
  DOING_BUSINESS_AS = 'DOING_BUSINESS_AS',
  LEGAL = 'LEGAL'
}
