<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'id'">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="element.id ?? 0"></lib-table-column-sort>
    </ng-container>
  
    <ng-container *ngSwitchCase="'name'">
        <lib-text-table-column>
            {{ element.name }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'description'">
        <lib-text-table-column>
            {{ element.description }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
