import { Action } from "@ngrx/store";
import { OrganizationDto } from "@admin_api/models/organization-dto";
import { UpdateOrganizationDto } from "@admin_api/models/update-organization-dto";


export enum ActionTypes {
    ResetOrganization = "[Organization] ResetOrganization",
    GetOrganization = "[Organization] GetOrganization",
    GetNewOrganization = "[Organization] GetNewOrganization",
    GetOrganizationSucceed = "[Organization] GetOrganizationSucceed",
    GetOrganizationFail = "[Organization] GetOrganizationFail",
    UpdateOrganization = "[Organization] UpdateOrganization",
    DeleteOrganization = "[Organization] DeleteOrganization",
    CreateOrganization = "[Organization] CreateOrganization",
    AppendAncestorOrganization = "[Organization] AppendAncestorOrganization",
    AppendAncestorOrganizationSucceed = "[Organization] AppendAncestorOrganizationSucceed",
    AppendAncestorOrganizationFail = "[Organization] AppendAncestorOrganizationFail"
}

export class ResetOrganizationAction implements Action {

    constructor(
    ) {}

    readonly type = ActionTypes.ResetOrganization;
}

export class GetOrganizationAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.GetOrganization;
}

export class GetNewOrganizationAction implements Action {

    constructor(
        public parentId: number
    ) {}

    readonly type = ActionTypes.GetNewOrganization;
}

export class GetOrganizationSucceedAction implements Action {

    constructor(public organization: OrganizationDto, public isCreate = false) {}

    readonly type = ActionTypes.GetOrganizationSucceed;
}

export class GetOrganizationFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetOrganizationFail;
}

export class UpdateOrganizationAction implements Action {

    constructor(public id: number, public organization: UpdateOrganizationDto) {}

    readonly type = ActionTypes.UpdateOrganization;
}

export class DeleteOrganizationAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DeleteOrganization;
}

export class CreateOrganizationAction implements Action {

    constructor(public organization: any) {}

    readonly type = ActionTypes.CreateOrganization;
}

export class AppendAncestorOrganizationAction implements Action {

    constructor(public organization: OrganizationDto) {}

    readonly type = ActionTypes.AppendAncestorOrganization;
}

export class AppendAncestorOrganizationSucceedAction implements Action {

    constructor(public organization: OrganizationDto) {}

    readonly type = ActionTypes.AppendAncestorOrganizationSucceed;
}

export class AppendAncestorOrganizationFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.AppendAncestorOrganization;
}
