import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getTidsState = (store: IStore) => store.tids;

export const getTids = createSelector(
    getTidsState,
    state => state.record,
);

export const getTidsLoading = createSelector(
    getTidsState,
    state => state.loading,
);

export const getTidsError = createSelector(
    getTidsState,
    state => state.error,
);

export const getTidsFilter = createSelector(
    getTidsState,
    state => state.filter
);

export const getAssociatedTids = createSelector(
    getTidsState,
    state => state.associatedRecord,
);

export const getAssociatedTidsLoading = createSelector(
    getTidsState,
    state => state.associatedLoading,
);

export const getAssociatedTidsError = createSelector(
    getTidsState,
    state => state.associatedError,
);
