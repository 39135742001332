/* tslint:disable */
/* eslint-disable */
export enum BusinessSubType {
  ASSOCIATION_TYPE_INCORPORATED = 'ASSOCIATION_TYPE_INCORPORATED',
  ASSOCIATION_TYPE_NON_INCORPORATED = 'ASSOCIATION_TYPE_NON_INCORPORATED',
  GOVT_TYPE_ENTITY = 'GOVT_TYPE_ENTITY',
  GOVT_TYPE_ESTD_UNDER_COMMONWEALTH_LEGISLATION = 'GOVT_TYPE_ESTD_UNDER_COMMONWEALTH_LEGISLATION',
  GOVT_TYPE_ESTD_UNDER_STATE_TERRITORY_LEGISLATION = 'GOVT_TYPE_ESTD_UNDER_STATE_TERRITORY_LEGISLATION',
  GOVT_TYPE_ESTD_UNDER_FOREIGN_COUNTRY_LEGISLATION = 'GOVT_TYPE_ESTD_UNDER_FOREIGN_COUNTRY_LEGISLATION'
}
