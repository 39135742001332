<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    [moreFields]="moreFields"
    suppressInitialRequest>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateInterval"
                        label="Date Created"
                        [clearValue]="filter?._initialFilter?.localDateInterval"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>
                
                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="fundingStatuses"
                        isFilter
                        multiple
                        label="Funding Status"
                        [options]="fundingStatusList"
                        [showReset]="filtersBase.resetFieldVisible('fundingStatuses')"
                        (handleReset)="wipeField('fundingStatuses')"
                        [spaceBetweenCapsOnLabels]="true">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="merchantId"
                        isFilter
                        label="Merchant Name"
                        labelEmpty="No Merchants"
                        [labelEmptyCondition]="noMerchants"
                        [options]="merchants"
                        optionLabelKey="name"
                        optionValueKey="id"
                        [showReset]="filtersBase.resetFieldVisible('merchantId')"
                        (handleReset)="filtersBase.resetField('merchantId')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="mid"
                        label="MID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('mid')"
                        (handleReset)="filtersBase.resetField('mid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="dba"
                        label="DBA"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('dba')"
                        (handleReset)="filtersBase.resetField('dba')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localFundedDateInterval"
                        label="Date Funded"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

            </div>

        </ng-template>

        <ng-template #moreFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="processors"
                        isFilter
                        multiple
                        label="Processor"
                        [options]="processorList"
                        [showReset]="filtersBase.resetFieldVisible('processors')"
                        (handleReset)="filtersBase.resetField('processors')">
                    </lib-vitu-form-field-select>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
