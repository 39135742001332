import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigationAction } from "@ngrx/router-store";
import { filter as filterOperator, switchMap, withLatestFrom, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { IStore } from "../store";
import { Store } from "@ngrx/store";
import {
    LocalTimePoint} from "shared-lib";
import { Filter, Sorting } from "./events.state";
import { getEventsState } from "./events.selectors";
import {
    ActionTypes,
    PageChangedEventsAction,
    FilterEventsAction,
    GetEventsAction,
    GetEventsSucceedAction,
    SortEventsAction,
    GetEventsFailAction

} from "./events.actions";
import { GetWebhookLookupsAction } from "../webhook/webhook.actions";
import { EventsService } from "@admin_api/services/events.service";

@Injectable()
export class EventsEffects {

    constructor(
        private actions: Actions,
        private eventsService: EventsService,
        private store: Store<IStore>
    ) { }

    initEventDeliveriesPage = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigationAction) =>
                new RegExp(/dashboard\/webhooks\/event-history$/).test(action.payload.routerState.url)),
            switchMap(() =>
                [new GetEventsAction(1), new GetWebhookLookupsAction()]
            )
        )
    );

    filterList = createEffect(() =>
        this.actions.pipe(
            ofType<FilterEventsAction>(ActionTypes.FilterEvents),
            switchMap(action => of(new GetEventsAction(1, action.filter)))
        ),
    );

    sortList = createEffect(() =>
        this.actions.pipe(
            ofType<SortEventsAction>(ActionTypes.SortEvents),
            switchMap(action => of(new GetEventsAction(1, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions.pipe(
            ofType<PageChangedEventsAction>(ActionTypes.PageChangedEvents),
            switchMap(action => of(new GetEventsAction(action.page)))
        ),
    );

    getEvents = createEffect(() =>
        this.actions.pipe(
            ofType<GetEventsAction>(ActionTypes.GetEvents),
            withLatestFrom(this.store.select(getEventsState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const params = this.getParams(sorting, filter, state.pager.pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.eventsService.eventsSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetEventsSucceedAction(response, state.pager.pageSize, action.page, stateExtensions))),
                    catchError((error) =>
                        of(new GetEventsFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                EventTypes: filter.eventTypes,
                Mid: filter.mid,
                Tid: filter.tid,
                FundingInstructionRef: filter.fundingInstructionRef,
                TransactionId: filter.transactionId
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
