import { FundingResultsOrderBy } from "@admin_api/models/funding-results-order-by";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { createFilter, DateRange, FilterBase, getInitialLocalFilterDateRangeLastTwoWeeks, Pager } from "shared-lib";
import { FundingResult } from "@admin_api/models/funding-result";
import { FundingStatus } from "@admin_api/models/funding-status";
import { ProcessorEnum } from "@admin_api/models/processor-enum";

export class Sorting {
    orderBy: null | FundingResultsOrderBy;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    startDate: string = undefined;
    endDate: string = undefined;

    localFundedDateInterval: DateRange = undefined;

    merchantId: number = undefined;
    mid: string = undefined;
    dba: string = undefined;
    fundingStatuses: Array<FundingStatus> = Object.values(FundingStatus).filter(fundingStatus => fundingStatus !== FundingStatus.NoFunding);
    processors: Array<ProcessorEnum> = undefined;
}

export class FundingState {
    loading = false;
    error: Error = null;
    record: Array<FundingResult> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
