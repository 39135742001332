import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

const getApplePayKeysState = createSelector(
    (store: IStore) => store.applePayKeys,
    store => store,
);

export const getApplePayKeys = createSelector(
    getApplePayKeysState,
    state => state.record,
);

export const getApplePayKeysLoading = createSelector(
    getApplePayKeysState,
    state => state.loading,
);

export const getApplePayKeysError = createSelector(
    getApplePayKeysState,
    state => state.error,
);

export const getApplePayMerchantIdentityKeys = createSelector(
    getApplePayKeysState,
    state => state.merchantIdentityRecord,
);

export const getApplePayMerchantIdentityKeysLoading = createSelector(
    getApplePayKeysState,
    state => state.merchantIdentityLoading,
);

export const getApplePayMerchantIdentityKeysError = createSelector(
    getApplePayKeysState,
    state => state.merchantIdentityError,
);
