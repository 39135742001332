import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

const getGooglePayKeysState = createSelector(
    (store: IStore) => store.googlePayKeys,
    store => store,
);

export const getGooglePayKeys = createSelector(
    getGooglePayKeysState,
    state => state.record,
);

export const getGooglePayKeysLoading = createSelector(
    getGooglePayKeysState,
    state => state.loading,
);

export const getGooglePayKeysError = createSelector(
    getGooglePayKeysState,
    state => state.error,
);
