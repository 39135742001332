import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { forkJoin, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { NavigateAction, PageLoadFailAction } from "../router/router.actions";
import { ConfirmationModalComponent, ConfirmationModalData, VituCommonErrorEntityIdMustBeNumber } from "shared-lib";
import {
    ActionTypes,
    CreateWebhookAction,
    DeleteWebhookAction,
    GetNewWebhookAction,
    GetWebhookAction,
    GetWebhookFailAction,
    GetWebhookLookupsAction,
    GetWebhookLookupsSucceedAction,
    GetWebhookSucceedAction,
    ToggleWebhookAction,
    ToggleWebhookFailAction,
    ToggleWebhookSucceedAction,
    UpdateWebhookAction
} from "./webhook.actions";
import { GlobalSpinnerService } from "shared-lib";
import { WebhooksService } from "@admin_api/services/webhooks.service";
import { SolutionWebhookDto } from "@admin_api/models/solution-webhook-dto";

@Injectable()
export class WebhookEffects {

    constructor(
        private actions$: Actions,
        private webhooksService: WebhooksService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService
    ) {}

    getWebhook$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetWebhookAction>(ActionTypes.GetWebhook),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetWebhookFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return forkJoin({
                    webhook: this.webhooksService.webhooksGet({ id: action.id }),
                    lookups: this.webhooksService.webhooksLookups()
                }).pipe(
                    mergeMap(({ webhook, lookups }) => of(new GetWebhookSucceedAction(webhook, lookups))),
                    catchError((error) => of(new GetWebhookFailAction(error)))
                );
            }),
        ),
    );

    getNewWebhook$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewWebhookAction>(ActionTypes.GetNewWebhook),
            switchMap(() =>
                this.webhooksService.webhooksLookups().pipe(
                    switchMap((lookups) => {
                        const newWebhook: SolutionWebhookDto = {
                            eventTypes: [],
                            isActive: true,
                            url: "",
                            solution: undefined
                        };
                        return of(new GetWebhookSucceedAction(newWebhook, lookups));
                    }),
                    catchError((error) => of(new GetWebhookFailAction(error)))
                )
            )
        ),
    );

    getWebhookFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetWebhookFailAction>(ActionTypes.GetWebhookFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateWebhook$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateWebhookAction>(ActionTypes.UpdateWebhook),
        switchMap(({ id, webhook }) =>
            this.globalSpinner.apply(
                this.webhooksService.webhooksPut({ id, body: webhook }).pipe(
                    switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/webhooks/configure"] } })))
                )
            )
        )
    ));

    deleteWebhook$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteWebhookAction>(ActionTypes.DeleteWebhook),
            switchMap(({ id }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete Webhook",
                        subtitle: "Are you sure you want to permanently delete this webhook?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ id, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ id }) =>
                this.globalSpinner.apply(
                    this.webhooksService.webhooksDelete({ id }).pipe(
                        switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/webhooks/configure"] } })))
                    )
                )
            )
        )
    );

    createWebhook$ = createEffect(() => this.actions$.pipe(
        ofType<CreateWebhookAction>(ActionTypes.CreateWebhook),
        switchMap(({ webhook }) =>
            this.globalSpinner.apply(
                this.webhooksService.webhooksPost({ body: webhook }).pipe(
                    switchMap(() => of(NavigateAction({ payload: { path: ["/dashboard/webhooks/configure"] } })))
                )
            )
        )
    ));

    toggleWebhook$ = createEffect(() => this.actions$.pipe(
        ofType<ToggleWebhookAction>(ActionTypes.ToggleWebhook),
        switchMap(({ id, webhook, undo }) =>
                this.webhooksService.webhooksPut({ id, body: webhook }).pipe(
                    switchMap(() => of(new ToggleWebhookSucceedAction(id))),
                    catchError((error) => of(new ToggleWebhookFailAction(id, error, undo)))
                )
            )
    ));

    toggleWebhookFail$ = createEffect(() => this.actions$.pipe(
        ofType<ToggleWebhookFailAction>(ActionTypes.ToggleWebhookFail),
        tap(({ undo }) => undo())
    ), { dispatch: false });

    getWebhookLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetWebhookLookupsAction>(ActionTypes.GetWebhookLookups),
            switchMap(() =>
                this.webhooksService.webhooksLookups().pipe(
                    switchMap((lookups) => of(new GetWebhookLookupsSucceedAction(lookups)))
                )
            )
    ));

}
