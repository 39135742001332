/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthenticateTerminalDto } from '../models/authenticate-terminal-dto';
import { CloverOAuthParamsDto } from '../models/clover-o-auth-params-dto';
import { CreateMerchantProcessorDto } from '../models/create-merchant-processor-dto';
import { CreateMerchantTerminalDto } from '../models/create-merchant-terminal-dto';
import { CreateMerchantTerminalResultDto } from '../models/create-merchant-terminal-result-dto';
import { DeletedItemsInclusionEnum } from '../models/deleted-items-inclusion-enum';
import { GatewayEnum } from '../models/gateway-enum';
import { MerchantChangeOrderByEnum } from '../models/merchant-change-order-by-enum';
import { MerchantProcessorCloverDeviceDto } from '../models/merchant-processor-clover-device-dto';
import { MerchantProcessorDetailsDto } from '../models/merchant-processor-details-dto';
import { MerchantProcessorDto } from '../models/merchant-processor-dto';
import { MerchantTerminalDetailsDto } from '../models/merchant-terminal-details-dto';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfMerchantChangeDto } from '../models/paged-data-dto-of-merchant-change-dto';
import { PagedDataDtoOfMerchantTerminalDto } from '../models/paged-data-dto-of-merchant-terminal-dto';
import { ProcessorEnum } from '../models/processor-enum';
import { ReassignPosTerminalDto } from '../models/reassign-pos-terminal-dto';
import { SynchronizedAppleConfigResponse } from '../models/synchronized-apple-config-response';
import { UpdateMerchantProcessorDto } from '../models/update-merchant-processor-dto';
import { UpdateMerchantTerminalDto } from '../models/update-merchant-terminal-dto';

@Injectable({
  providedIn: 'root',
})
export class MerchantsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantChangesSearch
   */
  static readonly MerchantChangesSearchPath = '/v1/admin/merchants/{merchantId}/history';

  /**
   * Get history of merchant changes.
   *
   * Returns information about organization, MIDs and TIDs updates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantChangesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantChangesSearch$Response(params: {
    merchantId: number;
    OrderBy?: Array<MerchantChangeOrderByEnum>;
    MID?: string;
    TID?: string;
    UserEmail?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfMerchantChangeDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantChangesSearchPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('MID', params.MID, {});
      rb.query('TID', params.TID, {});
      rb.query('UserEmail', params.UserEmail, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfMerchantChangeDto>;
      })
    );
  }

  /**
   * Get history of merchant changes.
   *
   * Returns information about organization, MIDs and TIDs updates
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantChangesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantChangesSearch(params: {
    merchantId: number;
    OrderBy?: Array<MerchantChangeOrderByEnum>;
    MID?: string;
    TID?: string;
    UserEmail?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfMerchantChangeDto> {

    return this.merchantChangesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfMerchantChangeDto>) => r.body as PagedDataDtoOfMerchantChangeDto)
    );
  }

  /**
   * Path part for operation merchantProcessorsCloverOAuthGetCloverOAuthUrl
   */
  static readonly MerchantProcessorsCloverOAuthGetCloverOAuthUrlPath = '/v1/admin/merchants/{merchantId}/mids/{id}/clover/oauth/url';

  /**
   * Gets the URL for starting the Clover OAuth authorization process.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantProcessorsCloverOAuthGetCloverOAuthUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsCloverOAuthGetCloverOAuthUrl$Response(params: {

    /**
     * The VituPay merchant ID.
     */
    merchantId: number;

    /**
     * The VituPay merchant processor ID.
     */
    id: number;

    /**
     * This URI will be used to redirect back to our application after authorization.
     */
    redirectUri?: string;
  }): Observable<StrictHttpResponse<CloverOAuthParamsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantProcessorsCloverOAuthGetCloverOAuthUrlPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
      rb.query('redirectUri', params.redirectUri, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CloverOAuthParamsDto>;
      })
    );
  }

  /**
   * Gets the URL for starting the Clover OAuth authorization process.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantProcessorsCloverOAuthGetCloverOAuthUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsCloverOAuthGetCloverOAuthUrl(params: {

    /**
     * The VituPay merchant ID.
     */
    merchantId: number;

    /**
     * The VituPay merchant processor ID.
     */
    id: number;

    /**
     * This URI will be used to redirect back to our application after authorization.
     */
    redirectUri?: string;
  }): Observable<CloverOAuthParamsDto> {

    return this.merchantProcessorsCloverOAuthGetCloverOAuthUrl$Response(params).pipe(
      map((r: StrictHttpResponse<CloverOAuthParamsDto>) => r.body as CloverOAuthParamsDto)
    );
  }

  /**
   * Path part for operation merchantProcessorsSearch
   */
  static readonly MerchantProcessorsSearchPath = '/v1/admin/merchants/{merchantId}/mids';

  /**
   * Get list of MIDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantProcessorsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsSearch$Response(params: {
    merchantId: number;

    /**
     * Filter by IsServiceFee flag
     */
    ServiceFee?: boolean;

    /**
     * Filter MIDs linked to a service fee MID
     */
    ServiceFeeMidId?: number;
    Processor?: ProcessorEnum;
    DeletedItems?: DeletedItemsInclusionEnum;
    DeletedAfterDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<Array<MerchantProcessorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantProcessorsSearchPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.query('ServiceFee', params.ServiceFee, {});
      rb.query('ServiceFeeMidId', params.ServiceFeeMidId, {});
      rb.query('Processor', params.Processor, {});
      rb.query('DeletedItems', params.DeletedItems, {});
      rb.query('DeletedAfterDate', params.DeletedAfterDate, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MerchantProcessorDto>>;
      })
    );
  }

  /**
   * Get list of MIDs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantProcessorsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsSearch(params: {
    merchantId: number;

    /**
     * Filter by IsServiceFee flag
     */
    ServiceFee?: boolean;

    /**
     * Filter MIDs linked to a service fee MID
     */
    ServiceFeeMidId?: number;
    Processor?: ProcessorEnum;
    DeletedItems?: DeletedItemsInclusionEnum;
    DeletedAfterDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<Array<MerchantProcessorDto>> {

    return this.merchantProcessorsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MerchantProcessorDto>>) => r.body as Array<MerchantProcessorDto>)
    );
  }

  /**
   * Path part for operation merchantProcessorsPost
   */
  static readonly MerchantProcessorsPostPath = '/v1/admin/merchants/{merchantId}/mids';

  /**
   * Create MID record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantProcessorsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantProcessorsPost$Response(params: {
    merchantId: number;
    body: CreateMerchantProcessorDto
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantProcessorsPostPath, 'post');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create MID record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantProcessorsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantProcessorsPost(params: {
    merchantId: number;
    body: CreateMerchantProcessorDto
  }): Observable<number> {

    return this.merchantProcessorsPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation merchantProcessorsGet
   */
  static readonly MerchantProcessorsGetPath = '/v1/admin/merchants/{merchantId}/mids/{id}';

  /**
   * Get MID by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantProcessorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsGet$Response(params: {
    merchantId: number;
    id: number;
  }): Observable<StrictHttpResponse<MerchantProcessorDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantProcessorsGetPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MerchantProcessorDetailsDto>;
      })
    );
  }

  /**
   * Get MID by Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantProcessorsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsGet(params: {
    merchantId: number;
    id: number;
  }): Observable<MerchantProcessorDetailsDto> {

    return this.merchantProcessorsGet$Response(params).pipe(
      map((r: StrictHttpResponse<MerchantProcessorDetailsDto>) => r.body as MerchantProcessorDetailsDto)
    );
  }

  /**
   * Path part for operation merchantProcessorsPut
   */
  static readonly MerchantProcessorsPutPath = '/v1/admin/merchants/{merchantId}/mids/{id}';

  /**
   * Update MID record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantProcessorsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantProcessorsPut$Response(params: {
    merchantId: number;
    id: number;
    body: UpdateMerchantProcessorDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantProcessorsPutPath, 'put');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update MID record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantProcessorsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantProcessorsPut(params: {
    merchantId: number;
    id: number;
    body: UpdateMerchantProcessorDto
  }): Observable<void> {

    return this.merchantProcessorsPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantProcessorsDelete
   */
  static readonly MerchantProcessorsDeletePath = '/v1/admin/merchants/{merchantId}/mids/{id}';

  /**
   * Delete MID record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantProcessorsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsDelete$Response(params: {
    merchantId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantProcessorsDeletePath, 'delete');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete MID record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantProcessorsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsDelete(params: {
    merchantId: number;
    id: number;
  }): Observable<void> {

    return this.merchantProcessorsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantProcessorsCloverDevices
   */
  static readonly MerchantProcessorsCloverDevicesPath = '/v1/admin/merchants/{merchantId}/mids/{id}/clover-devices';

  /**
   * Get Clover devices for MID.
   *
   * Request list of devices from Clover API using MID's Clover config
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantProcessorsCloverDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsCloverDevices$Response(params: {
    merchantId: number;
    id: number;
  }): Observable<StrictHttpResponse<Array<MerchantProcessorCloverDeviceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantProcessorsCloverDevicesPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MerchantProcessorCloverDeviceDto>>;
      })
    );
  }

  /**
   * Get Clover devices for MID.
   *
   * Request list of devices from Clover API using MID's Clover config
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantProcessorsCloverDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantProcessorsCloverDevices(params: {
    merchantId: number;
    id: number;
  }): Observable<Array<MerchantProcessorCloverDeviceDto>> {

    return this.merchantProcessorsCloverDevices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MerchantProcessorCloverDeviceDto>>) => r.body as Array<MerchantProcessorCloverDeviceDto>)
    );
  }

  /**
   * Path part for operation merchantTerminalsSearch
   */
  static readonly MerchantTerminalsSearchPath = '/v1/admin/merchants/{merchantId}/tids';

  /**
   * Get list of TIDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsSearch$Response(params: {
    merchantId: number;
    DeletedItems?: DeletedItemsInclusionEnum;
    DeletedAfterDate?: string;
    MerchantProcessorId?: number;
    Gateways?: Array<GatewayEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfMerchantTerminalDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsSearchPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.query('DeletedItems', params.DeletedItems, {});
      rb.query('DeletedAfterDate', params.DeletedAfterDate, {});
      rb.query('MerchantProcessorId', params.MerchantProcessorId, {});
      rb.query('Gateways', params.Gateways, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfMerchantTerminalDto>;
      })
    );
  }

  /**
   * Get list of TIDs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsSearch(params: {
    merchantId: number;
    DeletedItems?: DeletedItemsInclusionEnum;
    DeletedAfterDate?: string;
    MerchantProcessorId?: number;
    Gateways?: Array<GatewayEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfMerchantTerminalDto> {

    return this.merchantTerminalsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfMerchantTerminalDto>) => r.body as PagedDataDtoOfMerchantTerminalDto)
    );
  }

  /**
   * Path part for operation merchantTerminalsPost
   */
  static readonly MerchantTerminalsPostPath = '/v1/admin/merchants/{merchantId}/tids';

  /**
   * Create TID record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsPost$Response(params: {
    merchantId: number;
    body: CreateMerchantTerminalDto
  }): Observable<StrictHttpResponse<CreateMerchantTerminalResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsPostPath, 'post');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateMerchantTerminalResultDto>;
      })
    );
  }

  /**
   * Create TID record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsPost(params: {
    merchantId: number;
    body: CreateMerchantTerminalDto
  }): Observable<CreateMerchantTerminalResultDto> {

    return this.merchantTerminalsPost$Response(params).pipe(
      map((r: StrictHttpResponse<CreateMerchantTerminalResultDto>) => r.body as CreateMerchantTerminalResultDto)
    );
  }

  /**
   * Path part for operation merchantTerminalsGet
   */
  static readonly MerchantTerminalsGetPath = '/v1/admin/merchants/{merchantId}/tids/{id}';

  /**
   * Get TID by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGet$Response(params: {
    merchantId: number;
    id: number;
  }): Observable<StrictHttpResponse<MerchantTerminalDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsGetPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MerchantTerminalDetailsDto>;
      })
    );
  }

  /**
   * Get TID by Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGet(params: {
    merchantId: number;
    id: number;
  }): Observable<MerchantTerminalDetailsDto> {

    return this.merchantTerminalsGet$Response(params).pipe(
      map((r: StrictHttpResponse<MerchantTerminalDetailsDto>) => r.body as MerchantTerminalDetailsDto)
    );
  }

  /**
   * Path part for operation merchantTerminalsPut
   */
  static readonly MerchantTerminalsPutPath = '/v1/admin/merchants/{merchantId}/tids/{id}';

  /**
   * Update TID record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsPut$Response(params: {
    merchantId: number;
    id: number;
    body: UpdateMerchantTerminalDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsPutPath, 'put');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update TID record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsPut(params: {
    merchantId: number;
    id: number;
    body: UpdateMerchantTerminalDto
  }): Observable<void> {

    return this.merchantTerminalsPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantTerminalsDelete
   */
  static readonly MerchantTerminalsDeletePath = '/v1/admin/merchants/{merchantId}/tids/{id}';

  /**
   * Delete TID record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsDelete$Response(params: {
    merchantId: number;
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsDeletePath, 'delete');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete TID record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsDelete(params: {
    merchantId: number;
    id: number;
  }): Observable<void> {

    return this.merchantTerminalsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantTerminalsReassignDevice
   */
  static readonly MerchantTerminalsReassignDevicePath = '/v1/admin/merchants/{merchantId}/tids/{id}/reassign-device';

  /**
   * Reassigns a POS terminal.
   *
   * Use this endpoint to reassign a POS terminal to a different MID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsReassignDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsReassignDevice$Response(params: {
    merchantId: number;
    id: number;
    body: ReassignPosTerminalDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsReassignDevicePath, 'post');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reassigns a POS terminal.
   *
   * Use this endpoint to reassign a POS terminal to a different MID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsReassignDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsReassignDevice(params: {
    merchantId: number;
    id: number;
    body: ReassignPosTerminalDto
  }): Observable<void> {

    return this.merchantTerminalsReassignDevice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantTerminalsAuthenticateTerminal
   */
  static readonly MerchantTerminalsAuthenticateTerminalPath = '/v1/admin/merchants/{merchantId}/tids/{id}/authenticate-terminal';

  /**
   * Authenticates a POS terminal.
   *
   * Use this endpoint to pair a POS terminal.\
   * The POS terminal displays the user code on the screen.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsAuthenticateTerminal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsAuthenticateTerminal$Response(params: {
    merchantId: number;
    id: number;
    body: AuthenticateTerminalDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsAuthenticateTerminalPath, 'post');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Authenticates a POS terminal.
   *
   * Use this endpoint to pair a POS terminal.\
   * The POS terminal displays the user code on the screen.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsAuthenticateTerminal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  merchantTerminalsAuthenticateTerminal(params: {
    merchantId: number;
    id: number;
    body: AuthenticateTerminalDto
  }): Observable<void> {

    return this.merchantTerminalsAuthenticateTerminal$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantTerminalsGenerateAppleMerchantIdentifier
   */
  static readonly MerchantTerminalsGenerateAppleMerchantIdentifierPath = '/v1/admin/merchants/{merchantId}/tids/{id}/generate-apple-merchant-identifier';

  /**
   * Generates apple merchant identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsGenerateAppleMerchantIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGenerateAppleMerchantIdentifier$Response(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsGenerateAppleMerchantIdentifierPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Generates apple merchant identifier.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsGenerateAppleMerchantIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGenerateAppleMerchantIdentifier(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<string> {

    return this.merchantTerminalsGenerateAppleMerchantIdentifier$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation merchantTerminalsGenerateGoogleGatewayMerchantIdentifier
   */
  static readonly MerchantTerminalsGenerateGoogleGatewayMerchantIdentifierPath = '/v1/admin/merchants/{merchantId}/tids/{id}/generate-google-gateway-merchant-identifier';

  /**
   * Generates google gateway merchant identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsGenerateGoogleGatewayMerchantIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGenerateGoogleGatewayMerchantIdentifier$Response(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsGenerateGoogleGatewayMerchantIdentifierPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Generates google gateway merchant identifier.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsGenerateGoogleGatewayMerchantIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGenerateGoogleGatewayMerchantIdentifier(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<string> {

    return this.merchantTerminalsGenerateGoogleGatewayMerchantIdentifier$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation merchantTerminalsSynchronizeAppleConfiguration
   */
  static readonly MerchantTerminalsSynchronizeAppleConfigurationPath = '/v1/admin/merchants/{merchantId}/tids/{id}/synchronize-apple-configuration';

  /**
   * Synchronize customer's domains and apple customer identity in apple service.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsSynchronizeAppleConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsSynchronizeAppleConfiguration$Response(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<StrictHttpResponse<SynchronizedAppleConfigResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsSynchronizeAppleConfigurationPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SynchronizedAppleConfigResponse>;
      })
    );
  }

  /**
   * Synchronize customer's domains and apple customer identity in apple service.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsSynchronizeAppleConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsSynchronizeAppleConfiguration(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<SynchronizedAppleConfigResponse> {

    return this.merchantTerminalsSynchronizeAppleConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<SynchronizedAppleConfigResponse>) => r.body as SynchronizedAppleConfigResponse)
    );
  }

  /**
   * Path part for operation merchantTerminalsGetDomainValidationFile
   */
  static readonly MerchantTerminalsGetDomainValidationFilePath = '/v1/admin/merchants/{merchantId}/tids/{id}/get-domain-validation-file';

  /**
   * Returns domain validation file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTerminalsGetDomainValidationFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGetDomainValidationFile$Response(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantsService.MerchantTerminalsGetDomainValidationFilePath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Returns domain validation file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTerminalsGetDomainValidationFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTerminalsGetDomainValidationFile(params: {

    /**
     * Merchant Id
     */
    merchantId: number;

    /**
     * Terminal ID
     */
    id: number;
  }): Observable<Blob> {

    return this.merchantTerminalsGetDomainValidationFile$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
