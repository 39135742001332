import { DigitalWalletKeyVersionDto } from "@admin_api/models/digital-wallet-key-version-dto";
import { AfterContentChecked, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ColumnDef, LocalTimePoint, TableBaseComponent } from "shared-lib";
import { ActionButtonKind } from "shared-lib";

@Component({
    selector: "app-apple-pay-detail",
    templateUrl: "./apple-pay-detail.component.html",
    styleUrls: ["./apple-pay-detail.component.less"],
})
export class ApplePayDetailComponent implements AfterContentChecked {

    @Input() applePayKeys: Array<DigitalWalletKeyVersionDto>;
    @Input() applePayKeysLoading: boolean;
    @Input() applePayKeysError: Error;

    @Output() uploadPaymentProcessingCertificate = new EventEmitter<void>();
    @Output() getPaymentProcessingCsr = new EventEmitter<void>();
    @Output() revokePaymentProcessingCertificate = new EventEmitter<string>();

    @Input() applePayMerchantIdentityKeys: Array<DigitalWalletKeyVersionDto>;
    @Input() applePayMerchantIdentityKeysLoading: boolean;
    @Input() applePayMerchantIdentityKeysError: Error;

    @Output() uploadMerchantIdentityCertificate = new EventEmitter<void>();
    @Output() getMerchantIdentityCsr = new EventEmitter<void>();
    @Output() revokeMerchantIdentity = new EventEmitter<string>();

    ActionButtonKind = ActionButtonKind;

    @ViewChild("paymentProcessingTableComponent") paymentProcessingTableComponent: TableBaseComponent;
    paymentProcessingContainerMaxHeightInPixels: number;

    @ViewChild("merchantIdentityTableComponent") merchantIdentityTableComponent: TableBaseComponent;
    merchantIdentityContainerMaxHeightInPixels: number;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    publicKeyColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
        { id: "token", title: "Token", flexWidthBasisInPixels: 180, flexWidthGrow: 1, canSort: true },
        { id: "dateCreated", title: `Date Created (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 140, flexWidthGrow: 1, canSort: true },
        { id: "dateExpired", title: `Date Expired (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 140, flexWidthGrow: 1, canSort: true },
        { id: "order", title: "Order", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true },
        { id: "status", title: "Status", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
        { id: "actions", title: "Actions", flexWidthBasisInPixels: 110, flexWidthGrow: 1, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
    ];

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    merchantIdentityColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
        { id: "token", title: "Token", flexWidthBasisInPixels: 180, flexWidthGrow: 1, canSort: true },
        { id: "dateCreated", title: `Date Created (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 140, flexWidthGrow: 1, canSort: true },
        { id: "dateExpired", title: `Date Expired (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 140, flexWidthGrow: 1, canSort: true },
        { id: "status", title: "Status", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
        { id: "actions", title: "Actions", flexWidthBasisInPixels: 110, flexWidthGrow: 1, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
    ];

    ngAfterContentChecked() {
        if (this.paymentProcessingTableComponent) {
            this.paymentProcessingContainerMaxHeightInPixels = this.paymentProcessingTableComponent.minRequiredHeightInPixels;
        }
        if (this.merchantIdentityTableComponent) {
            this.merchantIdentityContainerMaxHeightInPixels = this.merchantIdentityTableComponent.minRequiredHeightInPixels;
        }
    }

}
