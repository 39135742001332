import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetEventDelivery = "[EventDelivery] GetEventDelivery",
    GetEventDeliverySucceed = "[EventDelivery] GetEventDeliverySucceed",
    GetEventDeliveryFail = "[EventDelivery] GetEventDeliveryFail",
    ResendEventDelivery = "[EventDelivery] ResendEventDelivery"
}

export class GetEventDeliveryAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.GetEventDelivery;
}

export class GetEventDeliverySucceedAction implements Action {

    constructor(public response: any) {}

    readonly type = ActionTypes.GetEventDeliverySucceed;
}

export class GetEventDeliveryFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetEventDeliveryFail;
}

export class ResendEventDeliveryAction implements Action {

    constructor(public eventId: number, public eventDeliveryId: number, public isDelivered: boolean) {}

    readonly type = ActionTypes.ResendEventDelivery;
}
