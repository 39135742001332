import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@admin_app/storage/store";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";

import * as OrganizationActions from "@admin_app/storage/organization/organization.actions";
import * as OrganizationSelectors from "@admin_app/storage/organization/organization.selectors";

import * as MidsActions from "@admin_app/storage/mids/mids.actions";
import * as MidsSelectors from "@admin_app/storage/mids/mids.selectors";

import * as TidsActions from "@admin_app/storage/tids/tids.actions";
import * as TidsSelectors from "@admin_app/storage/tids/tids.selectors";

import * as UsersActions from "@admin_app/storage/users/users.actions";
import * as UsersSelectors from "@admin_app/storage/users/users.selectors";

import * as MerchantHistoryActions from "@admin_app/storage/merchant-history/merchant-history.actions";
import * as MerchantHistorySelectors from "@admin_app/storage/merchant-history/merchant-history.selectors";

import * as ConfigSelectors from "@admin_app/storage/config/config.selectors";

import { CreateOrganizationDto } from "@admin_api/models/create-organization-dto";
import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { OrganizationHierarchyModeEnum } from "@admin_api/models/organization-hierarchy-mode-enum";
import { Filter as MidsFilter } from "@admin_app/storage/mids/mids.state";
import { Filter as TidsFilter } from "@admin_app/storage/tids/tids.state";
import { Sorting as MerchantHistorySorting } from "@admin_app/storage/merchant-history/merchant-history.state";
import { Filter as MerchantHistoryFilter } from "@admin_app/storage/merchant-history/merchant-history.state";

@Component({
    selector: "app-organization",
    templateUrl: "./organization.component.html",
    styleUrls: ["./organization.component.less"]
})
export class OrganizationComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    organization$ = this.store.select(OrganizationSelectors.getOrganizationInfo);
    organizationLoading$ = this.store.select(OrganizationSelectors.getOrganizationLoading);
    organizationAncestors$ = this.store.select(OrganizationSelectors.getOrganizationAncestors);
    organizationAncestorsLoading$ = this.store.select(OrganizationSelectors.getOrganizationAncestorsLoading);

    organizations$ = this.store.select(OrganizationsSelectors.getOrganizations);
    organizationsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);
    organizationsError$ = this.store.select(OrganizationsSelectors.getOrganizationsError);

    mids$ = this.store.select(MidsSelectors.getMids);
    midsLoading$ = this.store.select(MidsSelectors.getMidsLoading);
    midsError$ = this.store.select(MidsSelectors.getMidsError);
    midsFilter$ = this.store.select(MidsSelectors.getMidsFilter);

    tids$ = this.store.select(TidsSelectors.getTids);
    tidsLoading$ = this.store.select(TidsSelectors.getTidsLoading);
    tidsError$ = this.store.select(TidsSelectors.getTidsError);
    tidsFilter$ = this.store.select(TidsSelectors.getTidsFilter);

    users$ = this.store.select(UsersSelectors.getUsers);
    usersLoading$ = this.store.select(UsersSelectors.getUsersLoading);
    usersError$ = this.store.select(UsersSelectors.getUsersError);

    merchantHistory$ = this.store.select(MerchantHistorySelectors.getMerchantHistory);
    merchantHistoryLoading$ = this.store.select(MerchantHistorySelectors.getMerchantHistoryLoading);
    merchantHistoryError$ = this.store.select(MerchantHistorySelectors.getMerchantHistoryError);
    merchantHistorySorting$ = this.store.select(MerchantHistorySelectors.getMerchantHistorySorting);
    merchantHistoryFilter$ = this.store.select(MerchantHistorySelectors.getMerchantHistoryFilter);
    merchantHistoryPager$ = this.store.select(MerchantHistorySelectors.getMerchantHistoryPager);

    config$ = this.store.select(ConfigSelectors.getConfig);

    isCreate: boolean;
    parentOrganizationId: number = null;

    ngOnInit() {
        // Can assume that to load this component url length is always > 0
        this.isCreate = (this.route.snapshot.url[this.route.snapshot.url.length - 1].path === "create");

        if (this.route.snapshot.params.organizationId?.length > 0) {
            this.parentOrganizationId = +this.route.snapshot.params.organizationId;
        }

        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, OrganizationHierarchyModeEnum.DirectChildren, false));
        this.store.dispatch(new MidsActions.ResetMidsAction());
        this.store.dispatch(new TidsActions.ResetTidsAction());
        this.store.dispatch(new UsersActions.ResetUsersAction());
        this.store.dispatch(new MerchantHistoryActions.ResetMerchantHistoryAction());

        this.store.dispatch(new OrganizationActions.ResetOrganizationAction());
        if (this.isCreate) {
            this.store.dispatch(new OrganizationActions.GetNewOrganizationAction(this.parentOrganizationId));
        }
        else {
            this.store.dispatch(new OrganizationActions.GetOrganizationAction(+this.route.snapshot.params.organizationId));
        }

    }

    updateOrganization(payload: any) {

        this.store.dispatch(new OrganizationActions.UpdateOrganizationAction(payload.id, payload.dto));
    }

    createOrganization(organization: CreateOrganizationDto) {

        this.store.dispatch(new OrganizationActions.CreateOrganizationAction(organization));
    }

    deleteOrganization(id: number) {

        this.store.dispatch(new OrganizationActions.DeleteOrganizationAction(id));
    }

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

    onMidsFilterChanged(params: {id: number; filter: MidsFilter}) {

        this.store.dispatch(new MidsActions.FilterMidsAction(params.id, params.filter));
    }

    onTidsFilterChanged(params: {id: number; filter: TidsFilter}) {

        this.store.dispatch(new TidsActions.FilterTidsAction(params.id, params.filter));
    }

    onMerchantHistorySortingChanged(params: {id: number; sorting: MerchantHistorySorting}) {
        this.store.dispatch(new MerchantHistoryActions.SortMerchantHistoryAction(params.id, params.sorting));
    }

    onMerchantHistoryPageChanged(params: {id: number; page: number}) {
        this.store.dispatch(new MerchantHistoryActions.PageChangedMerchantHistoryAction(params.id, params.page));
    }

    onMerchantHistoryFilterChanged(params: {id: number; filter: MerchantHistoryFilter}) {
        this.store.dispatch(new MerchantHistoryActions.FilterMerchantHistoryAction(params.id, params.filter));
    }

}
