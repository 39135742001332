<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Organizations' }]">
        <lib-action-button
            label="New Organization"
            [kind]="ActionButtonKind.PRIMARY"
            fontImage="far fa-plus-circle fa-bg"
            (click)="onClickAddNewOrganization()">
        </lib-action-button>
    </lib-title-bar>

    <app-organizations-log-filters
        [config]="config"
        [filter]="filter"
        (filterChanged)="filterChanged.emit($event)">
    </app-organizations-log-filters>

    <lib-table-base
        class="organizations-table"
        [columnDefs]="columnDefs"
        [rowsData]="organizations"
        [rowsDataLoading]="organizationsLoading"
        [rowsDataShowWhileLoading]="true"
        [rowsDataError]="organizationsError"
        rowsDataErrorMessage="Error Loading Organizations"
        selectedRowProperty="id"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-organizations-table-cell-content
                [columnId]="columnId"
                [element]="element"
                [config]="config">
            </app-organizations-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
