<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'SFTP Files' }, { 'label': 'Google Cloud Storage' }]">
    </lib-title-bar>

    <div class="tabs-container">

        <mat-tab-group class="tab-group" [selectedIndex]="0">

            <mat-tab [label]="'DFM Files'">

                <app-dfm-log-filters
                    [filter]="dfmFilesFilter"
                    (filterChanged)="dfmFilesFilterChanged.emit($event)">
                </app-dfm-log-filters>

                <lib-table-base
                    class="files-table"
                    [columnDefs]="dfmFilesColumnDefs"
                    [rowsData]="dfmFiles"
                    [rowsDataLoading]="dfmFilesLoading"
                    [rowsDataError]="dfmFilesError"
                    rowsDataErrorMessage="Error Loading DFM Files"
                    [rowsDataShowWhileLoading]="true"
                    [sorting]="dfmFilesSorting"
                    (sortingChanged)="onDfmFilesSortingChanged($event)"
                    [pager]="dfmFilesPager"
                    (pageChanged)="dfmFilesPageChanged.emit($event)">
            
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-dfm-files-table-cell-content
                            [columnId]="columnId"
                            [element]="element"
                            (reprocess)="reprocessDfmFile.emit($event)"
                            (download)="downloadDfmFile.emit($event)">
                        </app-dfm-files-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="'BIN Files'">

                <app-bin-log-filters
                    [filter]="binFilesFilter"
                    (filterChanged)="binFilesFilterChanged.emit($event)">
                </app-bin-log-filters>

                <lib-table-base
                    class="files-table"
                    [columnDefs]="binFilesColumnDefs"
                    [rowsData]="binFiles"
                    [rowsDataLoading]="binFilesLoading"
                    [rowsDataError]="binFilesError"
                    rowsDataErrorMessage="Error Loading BIN Files"
                    [rowsDataShowWhileLoading]="true"
                    [sorting]="binFilesSorting"
                    (sortingChanged)="onBinFilesSortingChanged($event)"
                    [pager]="binFilesPager"
                    (pageChanged)="binFilesPageChanged.emit($event)">
            
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-bin-files-table-cell-content
                            [columnId]="columnId"
                            [element]="element"
                            (reprocess)="reprocessBinFile.emit($event)"
                            (download)="downloadBinFile.emit($event)">
                        </app-bin-files-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

        </mat-tab-group>

    </div>

</div>
