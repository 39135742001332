import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ActionButtonKind, LocalTimePoint, ColumnDef, Pager, TableSorting } from "shared-lib";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { MerchantEventDetailsDto } from "@admin_api/models/merchant-event-details-dto";
import { EventDeliveryDto } from "@admin_api/models/event-delivery-dto";
import { Sorting } from "@admin_app/storage/event-deliveries/event-deliveries.state";

interface TreeNode {
    name: string;
    children?: TreeNode[];
}

/** Flat node with expandable and level information */
interface FlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: "app-event-details",
    templateUrl: "./event-details.component.html",
    styleUrls: ["./event-details.component.less"],
})
export class EventDetailsComponent implements OnChanges {

    @Input() event: MerchantEventDetailsDto;
    @Input() eventLoading: boolean;

    @Input() eventDeliveries: Array<EventDeliveryDto>;
    @Input() eventDeliveriesLoading: boolean;
    @Input() eventDeliveriesError: Error;

    @Output() cancel = new EventEmitter<void>();
    @Output() resend = new EventEmitter<{eventId: number; eventDeliveryId: number; isDelivered: boolean}>();

    @Input() pager: Pager;
    @Output() pageChanged = new EventEmitter<{eventId: number; page: number}>();

    @Input() sorting: Sorting;
    @Output() sortingChanged = new EventEmitter<{eventId: number; sorting: Sorting}>();

    merchantId: string;

    pageTitle: string;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    eventDeliveriesColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "id", title: "ID", flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true},
        { id: "webhook", title: "Webhook", flexWidthBasisInPixels: 400, flexWidthGrow: 8},
        { id: "solution", title: "Solution", flexWidthBasisInPixels: 160, flexWidthGrow: 1},
        { id: "status", title: "Status", flexWidthBasisInPixels: 175, flexWidthGrow: 1, canSort: true},
        { id: "createdUtc", title: `Date (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 140, flexWidthGrow: 1, canSort: true},
        { id: "resend", title: "Resend", flexWidthBasisInPixels: 40, flexWidthGrow: 1},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    ActionButtonKind = ActionButtonKind;
    LocalTimePoint = LocalTimePoint;

    private _transformer = (node: TreeNode, level: number) => ({
        expandable: !!node.children && node.children.length > 0,
        name: node.name,
        level,
    });

    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: FlatNode) => node.expandable;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {

        this.pageTitle = "View Event";
        if ("event" in changes && this.event) {
            this.dataSource.data = this.createTreeData();
        }
    }

    onResend(payload: {eventDeliveryId: number; isDelivered: boolean}): void {

        this.resend.emit({eventId: this.event.id, eventDeliveryId: payload.eventDeliveryId, isDelivered: payload.isDelivered});
    }

    onPageChanged(page: number): void {

        this.pageChanged.emit({eventId: this.event.id, page});
    }

    onSortingChanged(sorting: TableSorting): void {

        this.sortingChanged.emit({
            eventId: this.event.id,
            sorting: {
                orderBy: sorting.orderBy as any,
                orderDirection: sorting.orderDirection
            }}
        );
    }

    onCancel(): void {

        this.cancel.emit();
    }

    private createTreeData() {

        const tree = JSON.parse(this.event.data);
        const retVal = this.traverse(null, tree);
        return retVal.children;
    }

    private traverse(jsonKey: any, jsonValue: any) {
        let retVal;
        if( jsonValue !== null && typeof jsonValue == "object" ) {
            retVal = {
                name: jsonKey,
                children: []
            };
            Object.entries(jsonValue).forEach(([key, value]) => {
                // key is either an array index or object key
                retVal.children.push(this.traverse(key, value));
            });
        }
        else {
            // jsonValue is a number or string
            retVal = { name: `${jsonKey} : ${jsonValue}` };
        }
        return retVal;
    }

}
