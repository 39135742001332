import { MidsState } from "./mids.state";
import { ActionTypes } from "./mids.actions";

export const MidsReducer = (
    state: MidsState = new MidsState(),
    action: any,
  ): MidsState => {

    switch (action.type) {

        case ActionTypes.ResetMids: {
            return {
                ...state,
                loading: false,
                record: [],
                error: null
            };
        }

        case ActionTypes.GetMids: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetMidsSucceed: {
            return {
                ...state,
                record: action.mids,
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetMidsFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        case ActionTypes.ResetServiceFeeMids: {
            return {
                ...state,
                serviceFeeMidsLoading: false,
                serviceFeeMidsRecord: [],
                serviceFeeMidsError: null
            };
        }

        case ActionTypes.GetServiceFeeMids: {
            return {
                ...state,
                serviceFeeMidsLoading: true
            };
        }

        case ActionTypes.GetServiceFeeMidsSucceed: {
            return {
                ...state,
                serviceFeeMidsRecord: action.mids,
                serviceFeeMidsLoading: false,
                serviceFeeMidsError: null
            };
        }

        case ActionTypes.GetServiceFeeMidsFail: {
            return {
                ...state,
                serviceFeeMidsLoading: false,
                serviceFeeMidsError: action.error,
                serviceFeeMidsRecord: []
            };
        }

        case ActionTypes.ResetAssociatedMids: {
            return {
                ...state,
                associatedMidsLoading: false,
                associatedMidsRecord: [],
                associatedMidsError: null
            };
        }

        case ActionTypes.GetAssociatedMids: {
            return {
                ...state,
                associatedMidsLoading: true
            };
        }

        case ActionTypes.GetAssociatedMidsSucceed: {
            return {
                ...state,
                associatedMidsRecord: action.mids,
                associatedMidsLoading: false,
                associatedMidsError: null
            };
        }

        case ActionTypes.GetAssociatedMidsFail: {
            return {
                ...state,
                associatedMidsLoading: false,
                associatedMidsError: action.error,
                associatedMidsRecord: []
            };
        }

        default: {
            return state;
        }
    }

};
