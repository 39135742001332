import { MerchantChangeDto } from "@admin_api/models/merchant-change-dto";
import { MerchantChangeOrderByEnum } from "@admin_api/models/merchant-change-order-by-enum";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { createFilter, DateRange, FilterBase, Pager } from "shared-lib";

export class Sorting {
    orderBy: null | MerchantChangeOrderByEnum = MerchantChangeOrderByEnum.CreatedUtc;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval: DateRange = undefined;
    mid: string = undefined;
    tid: string = undefined;
    userEmail: string = undefined;
}

export class MerchantHistoryState {
    loading = false;
    error = null;
    record: Array<MerchantChangeDto> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
