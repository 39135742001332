<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="MerchantChangeOrderByEnum.CreatedUtc">
        <lib-date-time-label [dateUtc]="element.createdUtc"></lib-date-time-label>
    </ng-container>

    <ng-container *ngSwitchCase="'userEmail'">
        <lib-text-table-column>
            {{ element.userEmail }}
        </lib-text-table-column>
    </ng-container>
  
    <ng-container *ngSwitchCase="'description'">
        <lib-text-table-column>
            {{ element.description }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





