<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'mid'">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'merchantName'">
        <lib-text-table-column>
            {{ element.merchantName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'feePlanAssociationDate'">
        <lib-date-time-label [dateUtc]="element.associationDateUtc"></lib-date-time-label>
        <lib-table-column-sort [key]="sortingKeyForFeePlanAssociateDate"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
