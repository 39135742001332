import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, withLatestFrom } from "rxjs/operators";
import { ActionTypes, FilterTidsAction, GetAssociatedTidsFailAction, GetAssociatedTidsSucceedAction,
    GetTidsAction, GetTidsFailAction, GetTidsSucceedAction } from "./tids.actions";
import { of } from "rxjs";
import { MerchantsService } from "@admin_api/services/merchants.service";
import { PagerLargeSinglePageSize } from "../common";
import { DeletedItemsInclusionEnum } from "@admin_api/models/deleted-items-inclusion-enum";
import { Filter } from "./tids.state";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { getTidsState } from "./tids.selectors";
import { createFilter } from "shared-lib";

@Injectable()
export class TidsEffects {

    constructor(
        private actions$: Actions,
        private merchantsService: MerchantsService,
        private store: Store<IStore>
    ) {}

    filterTids = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterTidsAction>(ActionTypes.FilterTids),
            switchMap(action => of(new GetTidsAction(action.merchantId, undefined, action.filter)))
        ),
    );

    getTids$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetTidsAction>(ActionTypes.GetTids),
            withLatestFrom(this.store.select(getTidsState)),
            switchMap(([action, state]) => {

                // If no filter passed => clear the filter
                const filter = action.filter ? action.filter : /*state.filter*/createFilter(Filter);
                const params = this.getParams(action.merchantId, action.merchantProcessorId, filter);

                const stateExtensions = {
                    filter
                };

                return this.merchantsService.merchantTerminalsSearch(params)
                    .pipe(
                        switchMap((tids) => of(new GetTidsSucceedAction(tids, stateExtensions))),
                        catchError((error) => of(new GetTidsFailAction(error))
                    )
                );
            })
        )
    );

    getAssociatedTids$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetTidsAction>(ActionTypes.GetAssociatedTids),
            withLatestFrom(this.store.select(getTidsState)),
            switchMap(([action, state]) => {

                // If no filter passed => clear the filter
                const filter = action.filter ? action.filter : /*state.filter*/createFilter(Filter);
                const params = this.getParams(action.merchantId, action.merchantProcessorId, filter);

                const stateExtensions = {
                    filter
                };

                return this.merchantsService.merchantTerminalsSearch(params)
                    .pipe(
                        switchMap((tids) => of(new GetAssociatedTidsSucceedAction(tids, stateExtensions))),
                        catchError((error) => of(new GetAssociatedTidsFailAction(error))
                    )
                );
            })
        )
    );

    private getParams(merchantId: number, merchantProcessorId?: number, filter?: Filter): any {

        const retVal: any = {
            merchantId,
            "Pager.PageSize": PagerLargeSinglePageSize
        };

        if (merchantProcessorId != null) {
            retVal.MerchantProcessorId = merchantProcessorId;
        }

        if (filter?.localShowDeleted === "Yes") {
            retVal.DeletedItems = DeletedItemsInclusionEnum.Exclusive;
        }

        return retVal;
    }

}
