import { OrganizationState } from "./organization.state";
import { ActionTypes } from "./organization.actions";

export const OrganizationReducer = (
    state: OrganizationState = new OrganizationState(),
    action: any,
  ): OrganizationState => {

    switch (action.type) {

        case ActionTypes.ResetOrganization: {
            return {
                ...state,
                loading: false,
                error: null,
                record: null,
                ancestorsLoading: false,
                ancestorsError: null,
                ancestors: []
            };
        }

        case ActionTypes.GetOrganization:
        case ActionTypes.GetNewOrganization: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetOrganizationSucceed: {
            return {
                ...state,
                record: action.organization,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetOrganizationFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        case ActionTypes.AppendAncestorOrganization: {
            return {
                ...state,
//                ancestors: [ action.organization, ...state.ancestors ],
                ancestorsLoading: (action.organization.parentId != null)
            };
        }

        case ActionTypes.AppendAncestorOrganizationSucceed: {
            return {
                ...state,
                ancestors: [ action.organization, ...state.ancestors ],
                ancestorsError: null
            };
        }

        case ActionTypes.AppendAncestorOrganizationFail: {
            return {
                ...state,
                ancestorsLoading: false,
                ancestorsError: action.error
            };
        }

        default: {
            return state;
        }
    }

};
