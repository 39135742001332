import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, withLatestFrom } from "rxjs/operators";
import {
    ActionTypes,
    PageChangedDfmFilesAction,
    GetDfmFilesAction,
    GetDfmFilesSucceedAction,
    SortDfmFilesAction,
    FilterDfmFilesAction,
    GetDfmFilesFailAction
} from "./dfm-files.actions";
import { of } from "rxjs";
import { Filter, Sorting } from "./dfm-files.state";
import { getDfmFilesState } from "./dfm-files.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { DfmFilesService } from "@admin_api/services/dfm-files.service";
import { PagedDataDtoOfDfmFileDto } from "@admin_api/models/paged-data-dto-of-dfm-file-dto";
import { LocalTimePoint } from "shared-lib";

@Injectable()
export class DfmFilesEffects {

    constructor(
        private actions$: Actions,
        private dfmFilesService: DfmFilesService,
        private store: Store<IStore>
    ) {}

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortDfmFilesAction>(ActionTypes.SortDfmFiles),
            switchMap(action => of(new GetDfmFilesAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterDfmFilesAction>(ActionTypes.FilterDfmFiles),
            switchMap(action => of(new GetDfmFilesAction(1, undefined, action.filter, undefined)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedDfmFilesAction>(ActionTypes.PageChangedDfmFiles),
            switchMap(action => of(new GetDfmFilesAction(action.page)))
        ),
    );

    getDfmFiles = createEffect(() =>
        this.actions$.pipe(
            ofType<GetDfmFilesAction>(ActionTypes.GetDfmFiles),
            withLatestFrom(this.store.select(getDfmFilesState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.dfmFilesService.dfmFilesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetDfmFilesSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) => of(new GetDfmFilesFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                Statuses: filter.statuses
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
