<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Accounts' }]">
        <lib-action-button
            label="New Account" fontImage="far fa-plus-circle fa-bg" (click)="onClickCreate()">
        </lib-action-button>
    </lib-title-bar>

    <lib-table-base
        class="accounts-table"
        [columnDefs]="accountColumnDefs"
        [rowsData]="accounts"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Accounts"
        rowsDataEmptyMessage="No Accounts"
        selectedRowProperty="id"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-accounts-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-accounts-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
