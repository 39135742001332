import { AdjustmentState } from "./adjustment.state";
import { ActionTypes } from "./adjustment.actions";

export const AdjustmentReducer = (
    state: AdjustmentState = new AdjustmentState(),
    action: any,
  ): AdjustmentState => {

    switch (action.type) {

        case ActionTypes.ResetAdjustment: {
            return {
                ...state,
                loading: false,
                error: null,
                record: null
            };
        }

        case ActionTypes.GetAdjustment:
        case ActionTypes.GetNewAdjustment: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetAdjustmentSucceed: {
            return {
                ...state,
                record: action.adjustment,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetAdjustmentFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }
    }

};
