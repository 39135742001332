import { AccountState } from "./account.state";
import { ActionTypes } from "./account.actions";

export const AccountReducer = (
    state: AccountState = new AccountState(),
    action: any,
  ): AccountState => {

    switch (action.type) {

        case ActionTypes.GetAccount:
        case ActionTypes.GetNewAccount: {
            return {
                ...state,
                account: null,
                loading: true,
                merchantProcessors: [],
                merchantProcessorsLoading: false,
                merchantProcessorsError: null
            };
        }

        case ActionTypes.GetAccountSucceed: {
            return {
                ...state,
                account: action.account,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetAccountFail: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        case ActionTypes.GetAccountMerchantProcessors: {
            return {
                ...state,
                merchantProcessors: [],
                merchantProcessorsLoading: true
            };
        }

        case ActionTypes.GetAccountMerchantProcessorsSucceed: {
            return {
                ...state,
                merchantProcessors: action.merchantProcessors,
                merchantProcessorsLoading: false,
                merchantProcessorsError: null
            };
        }

        case ActionTypes.GetAccountMerchantProcessorsFail: {
            return {
                ...state,
                merchantProcessors: [],
                merchantProcessorsError: action.error,
                merchantProcessorsLoading: false
            };
        }

        default: {
            return state;
        }
    }

};
