import { Action } from "@ngrx/store";

export enum ActionTypes {
    DownloadBinFile = "[BIN_FILE] DownloadBinFile",
    ReprocessBinFile = "[BIN_FILE] ReprocessBinFile"
}

export class DownloadBinFileAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DownloadBinFile;
}

export class ReprocessBinFileAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.ReprocessBinFile;
}
