import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getAccountsState = (store: IStore) => store.accounts;

export const getAccounts = createSelector(
    getAccountsState,
    accountsState => accountsState.record,
);

export const getAccountsLoading = createSelector(
    getAccountsState,
    accountsState => accountsState.loading,
);

export const getAccountsError = createSelector(
    getAccountsState,
    accountsState => accountsState.error,
);
