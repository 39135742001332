import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter as filterOperator, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ActionTypes, FilterInventoryAction, GetInventoryAction, GetInventoryFailAction,
    GetInventorySucceedAction, PageChangedInventoryAction, SortInventoryAction } from "./inventory.actions";
import { of } from "rxjs";
import { InventoryService } from "@admin_api/services/inventory.service";
import { getInventoryState } from "./inventory.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { Filter, Sorting } from "./inventory.state";

@Injectable()
export class InventoryEffects {

    constructor(
        private actions$: Actions,
        private inventoryService: InventoryService,
        private store: Store<IStore>
    ) {}

    initInventoryPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigatedAction) => action.payload.routerState.url.startsWith("/dashboard/devices")),
            map(() => new GetInventoryAction(1))
        )
    );

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortInventoryAction>(ActionTypes.SortInventory),
            switchMap(action => of(new GetInventoryAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterInventoryAction>(ActionTypes.FilterInventory),
            switchMap(action => of(new GetInventoryAction(1, undefined, action.filter)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedInventoryAction>(ActionTypes.PageChangedInventory),
            switchMap(action => of(new GetInventoryAction(action.page)))
        ),
    );

    getInventory = createEffect(() =>
        this.actions$.pipe(
            ofType<GetInventoryAction>(ActionTypes.GetInventory),
            withLatestFrom(this.store.select(getInventoryState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.inventoryService.inventorySearch(params).pipe(
                    switchMap((response) =>
                        of(new GetInventorySucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) => of(new GetInventoryFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                MerchantId: filter.merchantId,
                MID: filter.mid,
                TID: filter.tid,
                DeviceSerial: filter.deviceSerial,
                DeviceName: filter.deviceName,
                Statuses: filter.statuses
            },
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
