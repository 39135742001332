import { Action } from "@ngrx/store";
import { MerchantProcessorDto } from "@admin_api/models/merchant-processor-dto";
import { Filter } from "./mids.state";

export enum ActionTypes {
    ResetMids = "[MIDS] ResetMids",
    GetMids = "[MIDS] GetMids",
    GetMidsMerge = "[MIDS] GetMidsMerge",
    GetMidsSucceed = "[MIDS] GetMidsSucceed",
    GetMidsFail = "[MIDS] GetMidsFail",
    ResetServiceFeeMids = "[MIDS] ResetServiceFeeMids",
    GetServiceFeeMids = "[MIDS] GetServiceFeeMids",
    GetServiceFeeMidsSucceed = "[MIDS] GetServiceFeeMidsSucceed",
    GetServiceFeeMidsFail = "[MIDS] GetServiceFeeMidsFail",
    ResetAssociatedMids = "[MIDS] ResetAssociatedMids",
    GetAssociatedMids = "[MIDS] GetAssociatedMids",
    GetAssociatedMidsSucceed = "[MIDS] GetAssociatedMidsSucceed",
    GetAssociatedMidsFail = "[MIDS] GetAssociatedMidsFail",
    FilterMids = "[MIDS] FilterMids"
}

export class ResetMidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetMids;
}

export class GetMidsAction implements Action {

    constructor(public merchantId: number, public filter?: Filter) {}

    readonly type = ActionTypes.GetMids;
}

export class GetMidsMergeAction implements Action {

    constructor(public merchantId: number) {}

    readonly type = ActionTypes.GetMidsMerge;
}

export class GetMidsSucceedAction implements Action {

    constructor(public mids: Array<MerchantProcessorDto>, public stateExtensions?: any) {}

    readonly type = ActionTypes.GetMidsSucceed;
}

export class GetMidsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetMidsFail;
}

export class ResetServiceFeeMidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetServiceFeeMids;
}

export class GetServiceFeeMidsAction implements Action {

    constructor(public merchantId: number) {}

    readonly type = ActionTypes.GetServiceFeeMids;
}

export class GetServiceFeeMidsSucceedAction implements Action {

    constructor(public mids: Array<MerchantProcessorDto>) {}

    readonly type = ActionTypes.GetServiceFeeMidsSucceed;
}

export class GetServiceFeeMidsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetServiceFeeMidsFail;
}

export class ResetAssociatedMidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetAssociatedMids;
}

export class GetAssociatedMidsAction implements Action {

    constructor(public merchantId: number, public serviceFeeMidId: number) {}

    readonly type = ActionTypes.GetAssociatedMids;
}

export class GetAssociatedMidsSucceedAction implements Action {

    constructor(public mids: Array<MerchantProcessorDto>) {}

    readonly type = ActionTypes.GetAssociatedMidsSucceed;
}

export class GetAssociatedMidsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetAssociatedMidsFail;
}

export class FilterMidsAction implements Action {

    constructor(public merchantId: number, public filter: Filter) {}

    readonly type = ActionTypes.FilterMids;
}
