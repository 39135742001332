<div class="outer-container">
    <app-transaction-detail
        [transaction]="transaction$ | async"
        [transactionLoading]="transactionLoading$ | async"
        [payment]="payment$ | async"
        [paymentLoading]="paymentLoading$ | async"
        [paymentError]="paymentError$ | async"
        [paymentFees]="(paymentFees$ | async)?.fees"
        [paymentFeesLoading]="paymentFeesLoading$ | async"
        [paymentFeesError]="paymentFeesError$ | async"
        [transactionSiblings]="transactionSiblings$ | async"
        [transactionSiblingsLoading]="transactionSiblingsLoading$ | async"
        (downloadReceipt)="downloadReceipt($event)">
    </app-transaction-detail>
</div>
