<app-organization-details
    [isCreate]="isCreate"
    [parentOrganizationId]="parentOrganizationId"
    [config]="config$ | async"
    [organization]="organization$ | async"
    [organizationLoading]="organizationLoading$ | async"
    [organizationAncestors]="organizationAncestors$ | async"
    [organizationAncestorsLoading]="organizationAncestorsLoading$ | async"
    [organizations]="organizations$ | async"
    [organizationsLoading]="organizationsLoading$ | async"
    [organizationsError]="organizationsError$ | async"
    [mids]="mids$ | async"
    [midsLoading]="midsLoading$ | async"
    [midsError]="midsError$ | async"
    [tids]="tids$ | async"
    [tidsLoading]="tidsLoading$ | async"
    [tidsError]="tidsError$ | async"
    [users]="users$ | async"
    [usersLoading]="usersLoading$ | async"
    [usersError]="usersError$ | async"
    (deleteOrganization)="deleteOrganization($event)"
    (updateOrganization)="updateOrganization($event)"
    (createOrganization)="createOrganization($event)"
    (cancel)="onCancel()"
    [midFilter]="midsFilter$ | async"
    (midFilterChanged)="onMidsFilterChanged($event)"
    [tidFilter]="tidsFilter$ | async"
    (tidFilterChanged)="onTidsFilterChanged($event)"
    [merchantHistory]="merchantHistory$ | async"
    [merchantHistoryLoading]="merchantHistoryLoading$ | async"
    [merchantHistoryError]="merchantHistoryError$ | async"
    [merchantHistorySorting]="merchantHistorySorting$ | async"
    [merchantHistoryFilter]="merchantHistoryFilter$ | async"
    [merchantHistoryPager]="merchantHistoryPager$ | async"
    (merchantHistorySortingChanged)="onMerchantHistorySortingChanged($event)"
    (merchantHistoryPageChanged)="onMerchantHistoryPageChanged($event)"
    (merchantHistoryFilterChanged)="onMerchantHistoryFilterChanged($event)">
</app-organization-details>
