import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { getInitialLocalFilterDateRangeLastTwoWeeks, DateRange, Pager, FilterBase, createFilter } from "shared-lib";
import { MerchantEventDto } from "@admin_api/models/merchant-event-dto";
import { MerchantEventOrderByEnum } from "@admin_api/models/merchant-event-order-by-enum";

export class Sorting {
    orderBy: null | MerchantEventOrderByEnum = MerchantEventOrderByEnum.CreatedUtc;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval?: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    startDate?: string = undefined;
    endDate?: string = undefined;

    eventTypes?: Array<string> = undefined;
    mid?: string = undefined;
    tid?: string = undefined;
    fundingInstructionRef?: string = undefined;
    transactionId?: string = undefined;
}

export class EventsState {
    loading = false;
    error: Error = null;
    rows: Array<MerchantEventDto> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
