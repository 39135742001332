<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Organizations' , 'link': '/dashboard/organizations' }, { 'label': merchant?.name ? merchant.name : '---' , 'link': '/dashboard/organizations/' + organizationId + '/mids' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="!mid then spinner"></ng-container>
        <ng-container *ngIf="mid then formFields"></ng-container>

    </div>

</div>

<ng-template #formFields>

    <form [formGroup]="midForm" class="form" [libVituForm]="midForm"
        (libVituFormSubmit)="onSubmit()" (libVituFormSubmitInvalidFieldNames)="onSubmitInvalidFieldNames($event)" #vituForm=vituForm>

        <div class="field-lines">

            <div *ngIf="midDeleted" id="midDeletedMessage">
                THIS MID WAS DELETED @ {{ DateTimeLabelUtils.ensureTimeZone(mid?.deletedUtc) | date: 'MM/dd/yyyy&nbsp;h:mm:ss&nbsp;a' : LocalTimePoint.formatZ() }} ({{ LocalTimePoint.formatZ() }})
            </div>

            <div id="labelMain" class="label">MAIN</div>

            <lib-vitu-form-field-select
                #processorSelect
                class="field"
                id="processor"
                [formGroup]="midForm"
                formFieldName="processor"
                label="Processor"
                [options]="processors"
                (selectionChanged)="onProcessorChanged(processorSelect.value)"
                [disabled]="!isCreate">
            </lib-vitu-form-field-select>

            <div id="postProcessorGap"></div>

            <div *ngIf="isCreate && processorFiserv" id="isServiceFee" class="service-fee-mid-container">
                <lib-vitu-slide-toggle
                    class="service-fee-slider"
                    [checked]="isServiceFee"
                    (checkedChange)="onServiceFeeToggled($event)"
                    [disabled]="!isCreate">
                </lib-vitu-slide-toggle>
                <span class="service-fee-mid-label">Service Fee MID</span>
            </div>

            <lib-vitu-form-field-input
                class="field"
                id="mid"
                [formGroup]="midForm"
                formFieldName="mid"
                label="MID"
                [disabled]="!isCreate">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                class="field"
                id="feePlan"
                [formGroup]="midForm"
                formFieldName="feePlan"
                label="Fee Plan"
                [options]="feePlans"
                optionLabelKey="name"
                optionValueKey="id">
            </lib-vitu-form-field-select>

            <lib-vitu-form-field-switch
                formControlName="isMonthlyFunding"
                label="Monthly Funding">
            </lib-vitu-form-field-switch>

            <lib-vitu-form-field-switch
                formControlName="automatedAdjustmentsEnabled"
                label="Enable Adjustments">
            </lib-vitu-form-field-switch>

            <lib-vitu-form-field-switch
                formControlName="isNotFunded"
                label="Not Funded">
            </lib-vitu-form-field-switch>

            <lib-vitu-form-field-input
                class="field"
                id="dba"
                [formGroup]="midForm"
                formFieldName="dba"
                label="DBA">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="descriptor"
                [formGroup]="midForm"
                formFieldName="descriptor"
                label="Descriptor">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                *ngIf="showServiceFeeMidField"
                #serviceFeeMidSelect
                class="field"
                id="serviceFeeMid"
                [formGroup]="midForm"
                formFieldName="serviceFeeMid"
                label="Link Service Fee MID"
                [options]="selectableServiceFeeMids"
                optionLabelKey="mid"
                optionValueKey="id"
                labelEmpty="NO SERVICE FEE MIDS"
                [labelEmptyCondition]="selectableServiceFeeMids.length === 0">
            </lib-vitu-form-field-select>

            <lib-vitu-form-field-input
                class="field"
                id="serviceFee"
                [formGroup]="midForm"
                formFieldName="serviceFee"
                label="Service Fee"
                type="number"
                [ngClass]="{'hide-item': !showServiceFeeField}"
                [disabled]="!showServiceFeeField">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="surcharge"
                [formGroup]="midForm"
                formFieldName="surcharge"
                label="Surcharge"
                type="number"
                [ngClass]="{'hide-item': !showSurchargeField}"
                [disabled]="!showSurchargeField">
            </lib-vitu-form-field-input>

            <div id="labelAccount" class="label">ACCOUNT</div>

            <lib-vitu-form-field-select
                *ngIf="isServiceFee"
                #serviceFeeAccountSelect
                class="field"
                id="serviceFeeAccount"
                [formGroup]="midForm"
                formFieldName="serviceFeeAccount"
                label="Account"
                [options]="selectableAccounts"
                optionLabelKey="name"
                optionValueKey="id"
                (selectionChanged)="onServiceFeeAccountChanged(serviceFeeAccountSelect)"
                labelEmpty="NO ACCOUNTS"
                [labelEmptyCondition]="selectableAccounts.length === 0">
            </lib-vitu-form-field-select>

            <lib-vitu-form-field-input
                class="field"
                id="companyName"
                [formGroup]="midForm"
                formFieldName="companyName"
                label="Company Name"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="streetAddress1"
                [formGroup]="midForm"
                formFieldName="streetAddress1"
                label="Street Address 1"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="streetAddress2"
                [formGroup]="midForm"
                formFieldName="streetAddress2"
                label="Street Address 2"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="city"
                [formGroup]="midForm"
                formFieldName="city"
                label="City"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                class="field"
                id="state"
                [formGroup]="midForm"
                formFieldName="state"
                label="State"
                [options]="states"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-select>

            <div *ngIf="isServiceFee"></div>

            <lib-vitu-form-field-input
                class="field"
                id="zipCode"
                [formGroup]="midForm"
                formFieldName="zipCode"
                label="ZIP Code"
                type="zip"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="accountNumber"
                [formGroup]="midForm"
                formFieldName="accountNumber"
                label="Account Number"
                [errorOverrides]="[{'pattern': 'Up to 14 numeric digits only'}]"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="routingNumber"
                [formGroup]="midForm"
                formFieldName="routingNumber"
                label="Routing Number"
                [errorOverrides]="[{'pattern': 'Up to 9 numeric digits only'}]"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                class="field"
                id="accountType"
                [formGroup]="midForm"
                formFieldName="accountType"
                label="Account Type"
                [options]="processorAccountTypes"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-select>

            <lib-vitu-form-field-input
                class="field"
                id="fein"
                [formGroup]="midForm"
                formFieldName="fein"
                label="FEIN"
                [errorOverrides]="[{'pattern': 'Up to 9 numeric digits only'}]"
                [ngClass]="{'hide-item': accountFieldHidden}"
                [disabled]="accountFieldDisabled">
            </lib-vitu-form-field-input>

            <ng-container *ngIf="processorFiserv">

                <div id="labelClover" class="label">CLOVER</div>

                <div *ngIf="cloverUpdated; else cloverNotUpdated" class="clover-update-label-container">
                    <div>Clover configuration has been updated.</div>
                </div>
                <ng-template #cloverNotUpdated>
                    <div *ngIf="showCloverUpdateLabel" class="clover-update-label-container">
                        <div>Clover tokens are out of date. Click 'Refresh Tokens' to update configuration.</div>
                    </div>
                </ng-template>

                <lib-vitu-form-field-input
                    class="field"
                    id="cloverMID"
                    [formGroup]="midForm"
                    formFieldName="cloverMID"
                    label="Clover Merchant ID"
                    [disabled]="false"
                    [errorOverrides]="[{'cloverConfigInvalid': 'Field is required if Public/API Token', 'cloverConfigDeleteInvalid': 'Field is required'}]">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="publicToken"
                    [formGroup]="midForm"
                    formFieldName="publicToken"
                    label="Public Token"
                    [disabled]="false"
                    (changedBlurred)="onCloverTokenChanged()">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="apiToken"
                    [formGroup]="midForm"
                    formFieldName="apiToken"
                    label="API Token"
                    type="obscured"
                    [showObscured]="false"
                    [disabled]="false"
                    (changedBlurred)="onCloverTokenChanged()">
                </lib-vitu-form-field-input>

                <div class="clover-button-container">
                    <lib-action-button
                        *ngIf="editAvailable"
                        id="cloverButton"
                        class="action-button clover-button"
                        [label]="cloverButtonLabel"
                        fontImage="far fa-credit-card fa-bg"
                        [kind]="ActionButtonKind.SECONDARY"
                        (click)="onClickClover(vituForm.dirty)">
                    </lib-action-button>
                </div>

            </ng-container>

            <ng-container *ngIf="processorPayPal">

                <div id="labelPayPal" class="label">PAYPAL</div>

                <div *ngIf="unmatchedPayPalErrors?.length > 0" id="payPalUnmatchedErrors" class="error-details-paypal-unmatched">

                    <div *ngFor="let unmatchedPayPalError of unmatchedPayPalErrors">
                        {{ unmatchedPayPalError }}
                    </div>

                </div>

                <div id="payPalConfigId" class="paypal-config-container" *ngIf="dataSource">

                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>

                            <div *ngIf="!node.item.ignored && !(node.item.ignoredCreate && isCreate)" class="tree-node-mid-container">

                                <div class="tree-node-inner-container">

                                    <button mat-icon-button disabled></button>

                                    <div class="tree-input-container">

                                        <lib-vitu-form-field-input
                                            *ngIf="isInputElement(node.item.schemaKey)"
                                            [formGroup]="midForm"
                                            [formFieldName]="node.item.formControlName"
                                            [label]="node.item.key | spaceBetweenCaps:true"
                                            [infoTip]="node.item.infoTip"
                                            [disabled]="!editAvailable">
                                        </lib-vitu-form-field-input>

                                        <lib-vitu-form-field-input
                                            *ngIf="isNumberInputElement(node.item.schemaKey)"
                                            [formGroup]="midForm"
                                            [formFieldName]="node.item.formControlName"
                                            [label]="node.item.key | spaceBetweenCaps:true"
                                            type="number"
                                            [infoTip]="node.item.infoTip"
                                            [disabled]="!editAvailable">
                                        </lib-vitu-form-field-input>

                                        <lib-vitu-form-field-select
                                            *ngIf="isSelectElement(node.item.schemaKey)"
                                            [formGroup]="midForm"
                                            [formFieldName]="node.item.formControlName"
                                            [label]="node.item.key | spaceBetweenCaps:true"
                                            [options]="getSelectOptionsForKey(node.item.schemaKey)"
                                            optionLabelKey="key"
                                            optionValueKey="value"
                                            [infoTip]="node.item.infoTip"
                                            [disabled]="!editAvailable">
                                        </lib-vitu-form-field-select>

                                        <lib-vitu-form-field-date
                                            *ngIf="isDateElement(node.item.schemaKey)"
                                            [formGroup]="midForm"
                                            [formFieldName]="node.item.formControlName"
                                            [label]="node.item.key | spaceBetweenCaps:true"
                                            [restrictFuture]="!!getDateOptionsForKey(node.item.schemaKey)?.restrictFuture"
                                            [restrictToDateOfBirth]="!!getDateOptionsForKey(node.item.schemaKey)?.restrictToDateOfBirth"
                                            [isUtc]="true"
                                            singleDateMode
                                            readonly
                                            [infoTip]="node.item.infoTip"
                                            [disabled]="!editAvailable">
                                        </lib-vitu-form-field-date>

                                        <lib-vitu-form-field-select
                                            *ngIf="isTimeElement(node.item.schemaKey)"
                                            [formGroup]="midForm"
                                            [formFieldName]="node.item.formControlName"
                                            [label]="getTimeLabel(node.item.key)"
                                            [options]="getTimeOptionsForKey(node.item.schemaKey, node.item.formControlName)"
                                            optionLabelKey="key"
                                            optionValueKey="value"
                                            disabled
                                            [infoTip]="node.item.infoTip">
                                        </lib-vitu-form-field-select>

                                    </div>

                                    <div *ngIf="node.errorDetails" class="error-details-outer-container">
                                        <div class="left-arrow">
                                            &#x2190;
                                        </div>
                                        <div class="error-details-inner-container">
                                            {{ node.errorDetails }}
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </mat-tree-node>
                    
                        <mat-tree-node *matTreeNodeDef="let node; when: hasExpandable" matTreeNodePadding style="padding-bottom: 5px;">

                            <div class="tree-node-inner-container">

                                <button *ngIf="!hasChildren(node)" mat-icon-button disabled></button>

                                <button *ngIf="hasChildren(node)" mat-icon-button matTreeNodeToggle [disableRipple]="true"
                                        [attr.aria-label]="'Toggle ' + node.item.key">
                                    <mat-icon class="mat-icon-rtl-mirror" class="tree-button tree-expand-collapse-button">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>

                                <div class="label" style="line-height: 40px;">
                                    {{ formatKeyForDisplay(node) }}
                                </div>

                                <button *ngIf="hasAddItem(node)" mat-icon-button [disableRipple]="true" (click)="addItem(node)">
                                    <i class="fas fa-plus-circle tree-button tree-add-remove-button"></i>
                                </button>

                                <button *ngIf="hasRemoveItem(node)" mat-icon-button [disableRipple]="true" (click)="removeItem(node)">
                                    <i class="far fa-minus-circle tree-button tree-add-remove-button"></i>
                                </button>

                                <div *ngIf="node.errorDetails" class="error-details-outer-container">
                                    <div class="left-arrow">
                                        &#x2190;
                                    </div>
                                    <div class="error-details-inner-container">
                                        {{ node.errorDetails }}
                                    </div>
                                </div>

                            </div>

                        </mat-tree-node>

                    </mat-tree>

                </div>

            </ng-container>

        </div>

        <div *ngIf="!isCreate" class="actions-line">

            <div class="action-buttons">

                <lib-action-button
                    *ngIf="!midDeleted"
                    id="deleteMidButton"
                    class="action-button"
                    label="Delete MID"
                    fontImage="far fa-trash fa-bg"
                    [kind]="ActionButtonKind.ALERT"
                    [disabled]="midLoading || !mid"
                    (click)="onClickDelete()">
                </lib-action-button>

            </div>

        </div>

        <lib-vitu-form-buttons class="form-buttons-line">

            <lib-vitu-form-abort-button
                (onAbort)="onBack()">
            </lib-vitu-form-abort-button>

            <lib-vitu-form-submit-button
                *ngIf="editAvailable"
                #submitButton
                [label]="submitButtonText"
                [neverDisable]="isCreate">
            </lib-vitu-form-submit-button>

        </lib-vitu-form-buttons>

    </form>

    <mat-tab-group *ngIf="!isCreate" class="associations-tab-group">

        <mat-tab *ngIf="isServiceFee" [label]="getAssociatedMidsTabLabel()">

            <lib-table-base
                class="tab-container"
                [columnDefs]="associatedMidsColumnDefs"
                [rowsData]="associatedMids"
                [rowsDataError]="associatedMidsError"
                [rowsDataLoading]="associatedMidsLoading"
                rowsDataErrorMessage="Error Loading Associated MIDs"
                rowsDataEmptyMessage="No Associated MIDs"
                selectedRowProperty="id"
                (rowSelected)="onAssociatedMidRowSelected($event)"
                [rowsDataShowWhileLoading]="true">

                <ng-template let-element="element" let-columnId="columnId">
                    <app-service-fee-associated-mids-table-cell-content
                        [columnId]="columnId"
                        [element]="element">
                    </app-service-fee-associated-mids-table-cell-content>
                </ng-template>
            </lib-table-base>

        </mat-tab>

        <mat-tab [label]="getAssociatedTidsTabLabel()">

            <lib-table-base
                class="tab-container"
                [columnDefs]="associatedTidsColumnDefs"
                [rowsData]="associatedTids"
                [rowsDataError]="associatedTidsError"
                [rowsDataLoading]="associatedMidsLoading"
                rowsDataErrorMessage="Error Loading Associated TIDs"
                rowsDataEmptyMessage="No Associated TIDs"
                selectedRowProperty="id"
                (rowSelected)="onAssociatedTidRowSelected($event)"
                [rowsDataShowWhileLoading]="true">

                <ng-template let-element="element" let-columnId="columnId">
                    <app-tids-table-cell-content
                        [columnId]="columnId"
                        [element]="element">
                    </app-tids-table-cell-content>
                </ng-template>
            </lib-table-base>

        </mat-tab>

    </mat-tab-group>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>
