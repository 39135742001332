<app-user-details
    [isCreate]="isCreate"
    [organization]="organization$ | async"
    [user]="user$ | async"
    [currentUser]="currentUser$ | async"
    [roleList]="roleList$ | async"
    [loading]="loading$ | async"
    (deleteUser)="deleteUser($event)"
    (updateUser)="updateUser($event)"
    (createUser)="createUser($event)"
    (resetUserPassword)="resetUserPassword($event)"
    (back)="onBack()">
</app-user-details>
