import { FeeTypesState } from "./fee-types.state";
import { ActionTypes } from "./fee-types.actions";

export const FeeTypesReducer = (
    state: FeeTypesState = new FeeTypesState(),
    action: any,
  ): FeeTypesState => {

    switch (action.type) {

        case ActionTypes.GetFeeTypes: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetFeeTypesSucceed: {
            return {
                ...state,
                record: action.feeTypes,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetFeeTypesFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        default: {
            return state;
        }
    }

};
