import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getDfmFilesState = (store: IStore) => store.dfmFiles;

export const getDfmFiles = createSelector(
    getDfmFilesState,
    dfmFilesState => dfmFilesState.record,
);

export const getDfmFilesLoading = createSelector(
    getDfmFilesState,
    dfmFilesState => dfmFilesState.loading,
);

export const getDfmFilesError = createSelector(
    getDfmFilesState,
    dfmFilesState => dfmFilesState.error,
);

export const getDfmFilesSorting = createSelector(
    getDfmFilesState,
    dfmFilesState => dfmFilesState.sorting,
);

export const getDfmFilesFilter = createSelector(
    getDfmFilesState,
    dfmFilesState => dfmFilesState.filter,
);

export const getDfmFilesPager = createSelector(
    getDfmFilesState,
    dfmFilesState => dfmFilesState.pager,
);
