import { UpdateDeviceStatusAction } from "@admin_app/storage/device/device.actions";
import { IStore } from "@admin_app/storage/store";
import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";
import { PosTerminalStatusEnum } from "@admin_api/models/pos-terminal-status-enum";
import { PosTerminalOrderBy } from "@admin_api/models/pos-terminal-order-by";

@Component({
    selector: "app-devices-table-cell-content",
    templateUrl: "./devices-table-cell-content.component.html",
    styleUrls: ["./devices-table-cell-content.component.less"],
})
export class DevicesTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: MerchantTerminalDto;

    @Input() deviceUnderUpdateLoading = false;

    deviceStatuses: Array<any> = [];

    PosTerminalOrderBy = PosTerminalOrderBy;
    PosTerminalStatusEnum = PosTerminalStatusEnum;

    constructor(private store: Store<IStore>) {
        this.deviceStatuses = Object.values(PosTerminalStatusEnum).sort();
    }

    getBusyText(): string {
        return "< BUSY > ";
    }

    onUpdateDeviceStatus(status: PosTerminalStatusEnum): void {
        this.store.dispatch(new UpdateDeviceStatusAction(this.element, status));
    }

    isDeviceBusy(): boolean {
        return this.deviceUnderUpdateLoading;
    }

}
