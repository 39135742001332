import { Action } from "@ngrx/store";
import { Sorting, Filter } from "./event-deliveries.state";
import { EventDeliveriesDto } from "@admin_api/models/event-deliveries-dto";

export enum ActionTypes {
    FilterEventDeliveries = "[EventDeliveries] FilterEventDeliveries",
    SortEventDeliveries = "[EventDeliveries] SortEventDeliveries",
    PageChangedEventDeliveries = "[EventDeliveries] PageChangedEventDeliveries",
    ResetEventDeliveries = "[EventDeliveries] ResetEventDeliveries",
    GetEventDeliveries = "[EventDeliveries] GetEventDeliveries",
    GetEventDeliveriesSucceed = "[EventDeliveries] GetEventDeliveriesSucceed",
    GetEventDeliveriesFail = "[EventDeliveries] GetEventDeliveriesFail"
}

export class FilterEventDeliveriesAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterEventDeliveries;
}

export class SortEventDeliveriesAction implements Action {

    constructor(public eventId, public sorting: Sorting) {}

    readonly type = ActionTypes.SortEventDeliveries;
}

export class PageChangedEventDeliveriesAction implements Action {

    constructor(public eventId, public page: number) {}

    readonly type = ActionTypes.PageChangedEventDeliveries;
}

export class ResetEventDeliveriesAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetEventDeliveries;
}

export class GetEventDeliveriesAction implements Action {

    constructor(public eventId, public page: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetEventDeliveries;
}

export class GetEventDeliveriesSucceedAction implements Action {

    constructor(
        public response: EventDeliveriesDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetEventDeliveriesSucceed;
}

export class GetEventDeliveriesFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetEventDeliveriesFail;
}
