import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";
import { createFilter, FilterBase } from "shared-lib";

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    localShowDeleted = "No";
}

export class TidsState {
    loading = false;
    error = null;
    record: Array<MerchantTerminalDto> = [];
    filter = createFilter(Filter);
    associatedLoading = false;
    associatedError = null;
    associatedRecord: Array<MerchantTerminalDto> = [];
}
