import { getDevice, getDeviceLoading } from "@admin_app/storage/device/device.selectors";
import { getInventory, getInventoryError, getInventoryLoading } from "@admin_app/storage/inventory/inventory.selectors";
import { IStore } from "@admin_app/storage/store";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Filter, Sorting } from "@admin_app/storage/inventory/inventory.state";

import * as InventoryActions from "@admin_app/storage/inventory/inventory.actions";
import * as InventorySelectors from "@admin_app/storage/inventory/inventory.selectors";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";

import { PagerLargeSinglePageSize } from "@admin_app/storage/common";

@Component({
    selector: "app-devices",
    templateUrl: "./devices.component.html",
    styleUrls: ["./devices.component.less"],
})
export class DevicesComponent implements OnInit {

    inventory$ = this.store.select(getInventory);
    inventoryLoading$ = this.store.select(getInventoryLoading);
    inventoryError$ = this.store.select(getInventoryError);

    device$ = this.store.select(getDevice);
    deviceLoading$ = this.store.select(getDeviceLoading);

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    sorting$ = this.store.select(InventorySelectors.getInventorySorting);
    filter$ = this.store.select(InventorySelectors.getInventoryFilter);
    pager$ = this.store.select(InventorySelectors.getInventoryPager);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new InventoryActions.SortInventoryAction(sorting));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new InventoryActions.FilterInventoryAction(filter));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new InventoryActions.PageChangedInventoryAction(page));
    }

}
