import { Component } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { map } from "rxjs/operators";
import { DownloadReceiptAction } from "@admin_app/storage/transaction/transaction.actions";
import { TransactionDetailDto } from "@admin_api/models/transaction-detail-dto";
import { getPaymentState } from "@admin_app/storage/payment/payment.selectors";
import { getTransactionState } from "@admin_app/storage/transaction/transaction.selectors";
import { getTransactionSiblingsState } from "@admin_app/storage/transaction-siblings/transaction-siblings.selectors";

@Component({
    selector: "app-transaction",
    templateUrl: "./transaction.component.html",
    styleUrls: ["./transaction.component.less"]
})
export class TransactionComponent {

    transaction$ = this.store.pipe(
        select(getTransactionState),
        map(i => i.record),
    );

    transactionLoading$ = this.store.pipe(
        select(getTransactionState),
        map(i => i.loading),
    );

    payment$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.record),
    );

    paymentLoading$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.loading),
    );

    paymentError$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.error),
    );

    paymentFees$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.fees),
    );

    paymentFeesLoading$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.feesLoading),
    );

    paymentFeesError$ = this.store.pipe(
        select(getPaymentState),
        map(i => i.feesError),
    );

    transactionSiblings$ = this.store.pipe(
        select(getTransactionSiblingsState),
        map(i => i.record),
    );

    transactionSiblingsLoading$ = this.store.pipe(
        select(getTransactionSiblingsState),
        map(i => i.loading),
    );

    constructor(
        private store: Store<IStore>,
    ) { }

    downloadReceipt(transaction: TransactionDetailDto) {

        this.store.dispatch(DownloadReceiptAction({ transaction }));
    }
}
