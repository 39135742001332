import { Action } from "@ngrx/store";
import { Sorting, Filter } from "./transactions.state";
import { PagedDataDtoOfTransactionDto } from "@admin_api/models/paged-data-dto-of-transaction-dto";
//import { GcpStorageResource } from "@merchant_api/models/gcp-storage-resource";

export enum ActionTypes {
    FilterTransactions = "[Transactions] FilterTransactions",
    SortTransactions = "[Transactions] SortTransactions",
    PageChangedTransactions = "[Transactions] PageChangedTransactions",
    GetTransactions = "[Transactions] GetTransactions",
    GetTransactionsSucceed = "[Transactions] GetTransactionsSucceed",
    GetTransactionsFail = "[Transactions] GetTransactionsFail",
    ExportTransactionsToCsv = "[Transactions] ExportTransactionsToCSV",
    ExportTransactionsToCsvSucceed = "[Transactions] ExportTransactionsToCSVSucceed",
    ExportTransactionsToCsvFail = "[Transactions] ExportTransactionsToCSVFail"
}

export class FilterTransactionsAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterTransactions;
}

export class SortTransactionsAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortTransactions;
}

export class PageChangedTransactionsAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedTransactions;
}

export class GetTransactionsAction implements Action {

    constructor(public page: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetTransactions;
}

export class GetTransactionsSucceedAction implements Action {

    constructor(public response: PagedDataDtoOfTransactionDto, public pageSize: number,
        public page: number, public stateExtensions?: any) {}

    readonly type = ActionTypes.GetTransactionsSucceed;
}

export class GetTransactionsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetTransactionsFail;
}

export class ExportTransactionsToCsvAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ExportTransactionsToCsv;
}
/*
export class ExportTransactionsToCsvSucceedAction implements Action {

    constructor(public resource: GcpStorageResource) {}

    readonly type = ActionTypes.ExportTransactionsToCsvSucceed;
}
*/
export class ExportTransactionsToCsvFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.ExportTransactionsToCsvFail;
}
