<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Fee Plans' }]">
        <div class="title-actions">
            <lib-action-button
                label="Upload Plan Codes"
                fontImage="far fa-file-upload fa-bg"
                (click)="uploadPlanCode.emit()">
            </lib-action-button>
            <lib-action-button
                label="New Fee Plan"
                fontImage="far fa-plus-circle fa-bg"
                (click)="onClickAddNewFeePlan()">
            </lib-action-button>
        </div>
    </lib-title-bar>

    <lib-table-base
        class="fee-plans-table"
        [columnDefs]="columnDefs"
        [rowsData]="feePlans"
        [rowsDataLoading]="feePlansLoading"
        [rowsDataError]="feePlansError"
        rowsDataErrorMessage="Error Loading Fee Plans"
        [rowsDataShowWhileLoading]="true"
        selectedRowProperty="id"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)"
        (rowSelected)="onRowSelected($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-fee-plans-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-fee-plans-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
