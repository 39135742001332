import { IStore } from "@admin_app/storage/store";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Sorting } from "@admin_app/storage/fee-plans/fee-plans.state";

import * as FeePlansActions from "@admin_app/storage/fee-plans/fee-plans.actions";
import * as FeePlansSelectors from "@admin_app/storage/fee-plans/fee-plans.selectors";

@Component({
    selector: "app-fee-plans",
    templateUrl: "./fee-plans.component.html",
    styleUrls: ["./fee-plans.component.less"],
})
export class FeePlansComponent {

    feePlans$ = this.store.select(FeePlansSelectors.getFeePlans);
    feePlansLoading$ = this.store.select(FeePlansSelectors.getFeePlansLoading);
    feePlansError$ = this.store.select(FeePlansSelectors.getFeePlansError);

    pager$ = this.store.select(FeePlansSelectors.getFeePlansPager);
    sorting$ = this.store.select(FeePlansSelectors.getFeePlansSorting);

    constructor(private store: Store<IStore>) {}

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new FeePlansActions.SortFeePlansAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new FeePlansActions.PageChangedFeePlansAction(page));
    }

    onUploadPlanCode() {
        this.store.dispatch(new FeePlansActions.UploadPlanCodesAction());
    }

}
