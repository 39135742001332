/* tslint:disable */
/* eslint-disable */
export enum ReportFileOrderByEnum {
  Id = 'Id',
  FullName = 'FullName',
  Status = 'Status',
  FileType = 'FileType',
  CreatedUtc = 'CreatedUtc',
  UpdatedUtc = 'UpdatedUtc'
}
