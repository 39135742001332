import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@admin_app/storage/store";

import * as EventActions from "@admin_app/storage/event/event.actions";
import * as EventSelectors from "@admin_app/storage/event/event.selectors";

import * as EventDeliveriesSelectors from "@admin_app/storage/event-deliveries/event-deliveries.selectors";

import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { ResendEventDeliveryAction } from "@admin_app/storage/event-delivery/event-delivery.actions";
import { PageChangedEventDeliveriesAction, SortEventDeliveriesAction } from "@admin_app/storage/event-deliveries/event-deliveries.actions";
import { Sorting } from "@admin_app/storage/event-deliveries/event-deliveries.state";

@Component({
    selector: "app-event",
    templateUrl: "./event.component.html",
    styleUrls: ["./event.component.less"]
})
export class EventComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    event$ = this.store.select(EventSelectors.getEvent);
    eventLoading$ = this.store.select(EventSelectors.getEventLoading);

    eventDeliveries$ = this.store.select(EventDeliveriesSelectors.getEventDeliveries);
    eventDeliveriesLoading$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesLoading);
    eventDeliveriesError$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesError);

    pager$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesPager);
    sorting$ = this.store.select(EventDeliveriesSelectors.getEventDeliveriesSorting);

    ngOnInit() {

//        this.store.dispatch(new EventActions.GetEventAction(this.route.snapshot.params.id));
    }

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

    onResend(payload: {eventId: number; eventDeliveryId: number; isDelivered: boolean}): void {

        this.store.dispatch(new ResendEventDeliveryAction(payload.eventId, payload.eventDeliveryId, payload.isDelivered));
    }

    onPageChanged(payload: {eventId: number; page: number}) {
        this.store.dispatch(new PageChangedEventDeliveriesAction(payload.eventId, payload.page));
    }

    onSortingChanged(payload: {eventId: number; sorting: Sorting}) {
        this.store.dispatch(new SortEventDeliveriesAction(payload.eventId, payload.sorting));
    }

}
