<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Roles' , 'link': '/dashboard/roles' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">
        <ng-container *ngIf="!role then spinner"></ng-container>
        <ng-container *ngIf="role then details"></ng-container>
    </div>

</div>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #details>

    <form class="form" [formGroup]="roleForm" [libVituForm]="roleForm" (libVituFormSubmit)="onSubmit()">

        <div class="form-inner-container">

            <div>

                <lib-vitu-form-field-input
                    class="field"
                    id="name"
                    [formGroup]="roleForm"
                    formFieldName="name"
                    label="Name"
                    [errorOverrides]="[{'pattern': 'Contains invalid character(s)'}]">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    class="field"
                    id="solution"
                    [formGroup]="roleForm"
                    formFieldName="solution"
                    label="Solution"
                    [options]="solutions"
                    [optionLabelFn]="getDisplayTextForSolution.bind(this)"
                    [disabled]="!isCreate">
                </lib-vitu-form-field-select>

            </div>

            <lib-permissions-switch-array
                heading="PERMISSIONS"
                [atLeastOneRequired]="false"
                formControlName="permissionIds">
            </lib-permissions-switch-array>
 
            <div class="actions-line" *ngIf="!isCreate">

                <div class="action-buttons">

                    <lib-action-button
                        id="deleteRoleButton"
                        class="action-button"
                        label="Delete Role"
                        fontImage="far fa-trash fa-bg"
                        [kind]="ActionButtonKind.ALERT"
                        [disabled]="loading || !role"
                        (click)="onClickDelete()">
                    </lib-action-button>
        
                </div>

            </div>

        </div>

        <lib-vitu-form-buttons>

            <lib-vitu-form-abort-button
                (onAbort)="onClickBack()">
            </lib-vitu-form-abort-button>

            <lib-vitu-form-submit-button
                [label]="submitButtonText"
                [neverDisable]="isCreate">
            </lib-vitu-form-submit-button>

        </lib-vitu-form-buttons>

    </form>

</ng-template>
