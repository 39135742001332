/* tslint:disable */
/* eslint-disable */
export enum ReportFileStatusEnum {
  Received = 'Received',
  Processing = 'Processing',
  Processed = 'Processed',
  Failed = 'Failed',
  Omitted = 'Omitted',
  Error = 'Error'
}
