import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges,
    OnDestroy,
    OnInit, Output, SimpleChanges
} from "@angular/core";
import {
    AbstractControl, FormControl, UntypedFormBuilder,
    UntypedFormControl, UntypedFormGroup, Validators
} from "@angular/forms";
import {
    ActionButtonKind, ValidatorsVituCustom, VituFormFieldSelectComponent, DateTimeLabelUtils,
    VituToastService, VituToastTone, ZipRegex, ColumnDef, SpaceBetweenCapsPipe, LocalTimePoint
} from "shared-lib";
import { ActivatedRoute, Router } from "@angular/router";
import { MerchantProcessorDetailsDto } from "@admin_api/models/merchant-processor-details-dto";
import { UpdateMerchantProcessorDto } from "@admin_api/models/update-merchant-processor-dto";
import { CreateMerchantProcessorDto } from "@admin_api/models/create-merchant-processor-dto";
import { ProcessorEnum } from "@admin_api/models/processor-enum";
import { StateEnum } from "@admin_api/models/state-enum";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { FeePlanDto } from "@admin_api/models/fee-plan-dto";
import { MerchantProcessorAccountType } from "@admin_api/models/merchant-processor-account-type";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";
import { MerchantProcessorDto } from "@admin_api/models/merchant-processor-dto";
import { GatewayConfigDto } from "@admin_api/models/gateway-config-dto";

import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { BehaviorSubject, Subscription } from "rxjs";

import { payPalSchema, payPalSchemaOptions } from "./paypal-schema";
import { DatePipe, UpperCasePipe } from "@angular/common";
import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";

enum VituTreeNodeSchemaKey {
    OBJECT = "OBJECT",
    ARRAY = "ARRAY"
}

interface VituTreeNodeItem {
    key: string;
    value: any;
    schemaKey?: string;         // attached after buildTree
    formControlName?: string;   // attached after buildTree
    infoTip?: string;           // attached after buildTree
    ignored?: boolean;          // attached after buildTree
    ignoredCreate?: boolean;          // attached after buildTree
}

class VituTreeNode {
    children: VituTreeNode[];
    item: VituTreeNodeItem;
    options?: any;
}

class VituTreeFlatNode {
    item: VituTreeNodeItem;
    level: number;
    expandable: boolean;
    errorDetails?: string;
}

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-mid-details",
    templateUrl: "./mid-details.component.html",
    styleUrls: ["./mid-details.component.less"],
    providers: [
        SpaceBetweenCapsPipe,
        UpperCasePipe,
        DatePipe
    ]
})
export class MidDetailsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() isCreate: boolean;

    @Input() feePlans: Array<FeePlanDto> = [];
    @Input() feePlansLoading = false;

    @Input() merchant: OrganizationDto = {};
    @Input() merchantLoading = false;

    @Input() mid: MerchantProcessorDetailsDto;
    @Input() midLoading = false;

    @Input() accounts: Array<MerchantProcessorAccountDto> = [];
    @Input() accountsLoading = false;

    @Input() serviceFeeMids: Array<MerchantProcessorDto> = [];
    @Input() serviceFeeMidsLoading = false;

    @Input() associatedMids: Array<MerchantProcessorDto> = [];
    @Input() associatedMidsLoading = false;
    @Input() associatedMidsError: Error;

    @Input() associatedTids: Array<MerchantTerminalDto> = [];
    @Input() associatedTidsLoading = false;
    @Input() associatedTidsError: Error;

    @Input() set editMidError(value: any) {
        this._editMidError = value;
        this.decodeAndDisplayErrors(value);
    }
    get editMidError() {
        return this._editMidError;
    }
    _editMidError: Error;

    @Output() deleteMid = new EventEmitter<any>();
    @Output() updateMid = new EventEmitter<any>();
    @Output() createMid = new EventEmitter<any>();
    @Output() back = new EventEmitter<void>();
    @Output() connectClover = new EventEmitter<any>();

    formControlsFiserv = new Map<string, number>();
    formControlsPayPal = new Map<string, number>();

    payPalSchemaOptions = payPalSchemaOptions;
    unmatchedPayPalErrors: Array<string> = [];

    organizationId: string;

    processors: Array<any> = [];
    processorAccountTypes: Array<any> = [];
    states: Array<any> = [];

    uneditedFormConfig: any = {};

    private treeNodeControlPrefix = "treeNodeControl";
    private treeNodeControlCount = 0;

    private dataChangeSubscription: Subscription;

    set isServiceFee(value: boolean) {
        this._isServiceFee = value;
        const accountField = this.midForm?.get("serviceFeeAccount");
        if (this._isServiceFee) {
            accountField?.setValidators([Validators.required]);
        }
        else {
            accountField?.clearValidators();
        }
        accountField?.updateValueAndValidity();
    }
    get isServiceFee() {
        return !!this._isServiceFee;
    }
    _isServiceFee: boolean;

    initialPayPalConfigSHA: any;

    dataChange = new BehaviorSubject<VituTreeNode[]>([]);

    get data(): VituTreeNode[] {
        return this.dataChange.value;
    }

    treeControl: FlatTreeControl<VituTreeFlatNode>;
    dataSource: MatTreeFlatDataSource<VituTreeNode, VituTreeFlatNode>;
    hasExpandable = (_: number, _nodeData: VituTreeFlatNode) => _nodeData.expandable;

    // Map from flat node to nested node.
    private flatNodeMap = new Map<VituTreeFlatNode, VituTreeNode>();
    // Map from nested node to flattened node.
    private nestedNodeMap = new Map<VituTreeNode, VituTreeFlatNode>();
    private treeFlattener: MatTreeFlattener<VituTreeNode, VituTreeFlatNode>;
    private getLevel = (node: VituTreeFlatNode) => node.level;
    private isExpandable = (node: VituTreeFlatNode) => node.expandable;
    private getChildren = (node: VituTreeNode): VituTreeNode[] => node.children;

    // Transformer to convert nested node to flat node. Record the nodes in maps for later use.
    private transformer = (node: VituTreeNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = (existingNode && existingNode.item?.key === node.item?.key)
            ? existingNode
            : new VituTreeFlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    midInitialized = false;

    ActionButtonKind = ActionButtonKind;
    LocalTimePoint = LocalTimePoint;
    DateTimeLabelUtils = DateTimeLabelUtils;

    get accountNames(): Array<string> {
        return this.accounts?.map(account => account.name);
    }

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    associatedMidsColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
        { id: "mid", title: "MID", flexWidthBasisInPixels: 350, flexWidthGrow: 0, canSort: true },
        { id: "dba", title: "DBA", flexWidthBasisInPixels: 250, flexWidthGrow: 0, canSort: true },
        { id: "processor", title: "Processor", flexWidthBasisInPixels: 230, flexWidthGrow: 1, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
    ];

    get associatedMidsLoaded(): boolean {
        return !this.associatedMidsLoading && (this.associatedMids.length > 0);
    }

    get associatedMidsEmpty(): boolean {
        return !this.associatedMidsLoading && !(this.associatedMids.length > 0);
    }

    getAssociatedMidsTabLabel(): string {
        let count = 0;
        if (this.associatedMids?.length && !this.associatedMidsLoading) {
            count = this.associatedMids?.length;
        }
        return this.appendCountIfNecessary("Associated MIDs", count);
    }

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    associatedTidsColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "tid", title: "TID", flexWidthBasisInPixels: 120, flexWidthGrow: 4, canSort: true },
//        { id: "mid", title: "MID", flexWidthBasisInPixels: 120, flexWidthGrow: 4, canSort: true },
//        { id: "processor", title: "Processor", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
        { id: "gateway", title: "Gateway", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
//        { id: "mai", title: "MAI", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
        { id: "deviceSerial", title: "Device Serial", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
        { id: "deviceName", title: "Device Name", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
        { id: "deviceLocation", title: "Device Location", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
        { id: "status", title: "Status", flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    get associatedTidsLoaded(): boolean {
        return !this.associatedTidsLoading && (this.associatedTids.length > 0);
    }

    get associatedTidsEmpty(): boolean {
        return !this.associatedTidsLoading && !(this.associatedTids.length > 0);
    }

    getAssociatedTidsTabLabel(): string {
        let count = 0;
        if (this.associatedTids?.length && !this.associatedTidsLoading) {
            count = this.associatedTids?.length;
        }
        return this.appendCountIfNecessary("Associated TIDs", count);
    }

    private appendCountIfNecessary(title: string, count: number): string {
        return title + ((count > 0) ? ` (${count})` : "");
    }

    constructor(
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private toast: VituToastService,
        private cd: ChangeDetectorRef,
        private router: Router,
        private spaceBetweenCapsPipe: SpaceBetweenCapsPipe,
        private upperCasePipe: UpperCasePipe,
        private datePipe: DatePipe
    ) {
        this.processors = Object.values(ProcessorEnum);
        this.processorAccountTypes = Object.values(MerchantProcessorAccountType);
        this.states = Object.values(StateEnum);

        this.midFormConfig = {
            // Main
            mid: [null, [Validators.required]],
            processor: [null, [Validators.required, ValidatorsVituCustom.selectSingleIntegrity(() => this.processors)]],
            feePlan: [null, [Validators.required,
                ValidatorsVituCustom.selectSingleIntegrity(() => this.feePlans?.map((feePlan) => feePlan.id))]],
            isMonthlyFunding: [null, []],
            automatedAdjustmentsEnabled: [null, []],
            isNotFunded:  [null, []],
            dba: [null, [Validators.required]],
            descriptor: [null, []],
            surcharge: [null, [Validators.required, Validators.min(0), Validators.max(4)]],

            // Merchant's Account Details
            serviceFeeAccount: [null],
            companyName: [null, [Validators.required]],
            streetAddress1: [null, [Validators.required]],
            streetAddress2: [null, []],
            city: [null, [Validators.required]],
            state: [null, [Validators.required, ValidatorsVituCustom.selectSingleIntegrity(() => this.states)]],
            zipCode: [null, [Validators.required, Validators.pattern(ZipRegex)]],
            accountNumber: [null, [Validators.required, Validators.pattern("(\\d{1,14})")]],
            routingNumber: [null, [Validators.required, Validators.pattern("(\\d{1,9})")]],
            accountType: [null, [Validators.required, ValidatorsVituCustom.selectSingleIntegrity(() => this.processorAccountTypes)]],
            fein: [null, [Validators.required, Validators.pattern("(\\d{1,9})")]],

            // Service Fee
            serviceFeeMid: [null],
            serviceFee: [null],

            // Clover gateway config
            cloverMID: [null, [this.cloverConfigValid.bind(this)]],
            publicToken: [null, []],
            apiToken: [null, []],

            // PayPal gateway config
            payPalConfigSHA: [null, []]
        };

        this.midForm = this.fb.group(this.midFormConfig);

        this.formControlsFiserv.set("cloverMID", 1);
        this.formControlsFiserv.set("publicToken", 1);
        this.formControlsFiserv.set("apiToken", 1);

        this.formControlsPayPal.set("payPalConfigSHA", 1);
    }

    private midFormConfig: FormConfig<any>;
    midForm: UntypedFormGroup;
    get formControls(): FormControls<any> { return this.midForm.controls; }

    cloverUpdated: boolean;

    get processorFiserv(): boolean {
        return this.midForm?.get("processor")?.value === ProcessorEnum.Fiserv;
    }

    get processorPayPal(): boolean {
        return this.midForm?.get("processor")?.value === ProcessorEnum.PayPal;
    }

    get cloverButtonLabel(): string {
        const cloverConfig = this.mid.gatewayConfig?.clover;
        return cloverConfig ? "Refresh Tokens" : "Connect to Clover";
    }

    get showCloverUpdateLabel(): boolean {
        return this.mid.gatewayConfig?.clover?.needsRefresh;
    }

    get selectableAccounts() {
        const nullAccount: MerchantProcessorAccountDto = {
            id: null,
            name: ""
        };
        const retVal: Array<MerchantProcessorAccountDto> = [];
        if (!this.isServiceFee) {
            retVal.push(nullAccount);
        }
        retVal.push(...this.accounts);
        return retVal;
    }

    get selectableServiceFeeMids() {
        if (this.serviceFeeMids?.length) {
            const nullMid: MerchantProcessorDto = {
                id: null,
                mid: "—"
            };
            const retVal: Array<MerchantProcessorDto> = [nullMid].concat(this.serviceFeeMids);
            return retVal;
        }
        return [];
    }

    get accountFieldHidden() {
        return this.isServiceFee && !this.hasLinkedAccount;
    }

    get accountFieldDisabled() {
        return this.isServiceFee;
    }

    ngOnInit() {
        this.cloverUpdated = !!(this.route.snapshot.queryParamMap.get("cloverupdated"));
        if (this.cloverUpdated) {
            this.toast.open("Clover configuration update success.", VituToastTone.Positive, null, "top", "OK").subscribe(
                _ => {
                    this.cloverUpdated = false;
                }
            );
        }
    }

    ngOnDestroy() {
        if (this.dataChangeSubscription) {
            this.dataChangeSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.isCreate && changes.isCreate.firstChange) {
            this.organizationId = this.route.snapshot.params.organizationId;
        }

        if (changes.mid && this.mid && !this.midInitialized) {
            this.midInitialized = true; // Safe (but shouldn't really need)

            this.isServiceFee = this.mid.isServiceFee;

            this.uneditedFormConfig = {
                mid: this.mid.mid,
                processor: this.mid.processor,
                feePlan: this.mid.feePlanId,
                isMonthlyFunding: this.mid.isMonthlyFunding,
                automatedAdjustmentsEnabled: this.mid.automatedAdjustmentsEnabled,
                isNotFunded: this.mid.isNotFunded,
                dba: this.mid.dba,
                descriptor: this.mid.descriptor,
                surcharge: this.mid.surchargePercent,
                serviceFeeMid: this.mid.serviceFeeMidId,
                serviceFee: this.mid.serviceFeePercent
            };

            if (this.mid.account) {
                const account = this.mid.account;
                this.uneditedFormConfig.serviceFeeAccount = account.id;
                this.uneditedFormConfig.companyName = account.companyName;
                this.uneditedFormConfig.streetAddress1 = account.address1;
                this.uneditedFormConfig.streetAddress2 = account.address2;
                this.uneditedFormConfig.city = account.city;
                this.uneditedFormConfig.state = account.state;
                this.uneditedFormConfig.zipCode = account.zipCode;
                this.uneditedFormConfig.accountNumber = account.accountNumber;
                this.uneditedFormConfig.routingNumber = account.routingNumber;
                this.uneditedFormConfig.accountType = account.accountType;
                this.uneditedFormConfig.fein = account.fein;
            }

            const cloverConfig = this.mid.gatewayConfig?.clover;
            if (cloverConfig) {
                this.uneditedFormConfig.cloverMID = cloverConfig.cloverMID;
                this.uneditedFormConfig.publicToken = cloverConfig.publicToken;
                this.uneditedFormConfig.apiToken = cloverConfig.apiToken;
            }

            // Ensure PayPal gateway config object exists (necessary to create tree)
            if (!this.mid.gatewayConfig) {
                this.mid.gatewayConfig = {};
            }
            if (!this.mid.gatewayConfig.payPal) {
                this.mid.gatewayConfig.payPal = {};
            }

            const payPalConfig = this.mid.gatewayConfig?.payPal;

            if (payPalConfig) {

                const initialTreeData = this.buildTree(payPalConfig, payPalSchema, 0);
                this.dataChange.next(initialTreeData);

                this.treeFlattener = new MatTreeFlattener(
                    this.transformer,
                    this.getLevel,
                    this.isExpandable,
                    this.getChildren,
                );
                this.treeControl = new FlatTreeControl<VituTreeFlatNode>(this.getLevel, this.isExpandable);
                this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

                if (this.dataChangeSubscription) {
                    this.dataChangeSubscription.unsubscribe();
                }
                this.dataChangeSubscription = this.dataChange.subscribe(data => {
                    this.dataSource.data = data;
                });

                this.augmentTreeUsingSchema();

                this.dataChange.next(this.data);
                this.sortTree();

                this.treeControl.expandAll();

                this.uneditedFormConfig.payPalConfigSHA = Math.random();
                this.initialPayPalConfigSHA = this.uneditedFormConfig.payPalConfigSHA;
            }

            this.midForm.patchValue(this.uneditedFormConfig);
            if (!this.isCreate) {
                this.midForm.markAllAsTouched();
            }

            this.onProcessorChanged(this.uneditedFormConfig.processor);

            if (!this.editAvailable) {
                this.midForm?.disable();
            }
        }

        // Workaround needed for form using ValidatorsVituCustom:
        // ValidatorsVituCustom need to be run by forcing validation if anything they depend on changes,
        // for example as a result of the allowed values being retrieved asynchronously from server (& arriving late).
        // (TODO : Consolidate use of Angular Forms in shareable form base class which implements our common form
        // behaviour like this as default for all of our forms (instead of having to apply it in multiple places))
        this.validateFormControls(this.midForm);
    }

    get pageTitle(): string {
        const serviceFeeOptional = this.isServiceFee ? " Service Fee" : "";
        const editOrView = this.editAvailable ? "Edit" : "View";
        return (this.isCreate ? "New" : editOrView) + serviceFeeOptional + " MID";
    }

    get editAvailable(): boolean {
        return (this.isCreate || this.processorFiserv) && !this.midDeleted;
    }

    get midDeleted(): boolean {
        return (this.mid?.deletedUtc != null);
    }

    get submitButtonText(): string {
        return (this.isCreate ? "Submit" : "Update");
    }

    getFormControl(name: string): UntypedFormControl {
        return this.midForm.controls[name] as UntypedFormControl;
    }

    onSubmitInvalidFieldNames(invalidFieldNames: Array<string>) {
        // Need to expand any collapsed nodes in tree which have invalid fields
        invalidFieldNames?.forEach((invalidFieldName) => {
            this.flatNodeMap.forEach((value: VituTreeNode, key: VituTreeFlatNode) => {
                if (key?.item?.formControlName === invalidFieldName) {
                    this.expandTreeToNode(key);
                }
            });
        });
    }

    onServiceFeeToggled(checked: boolean) {
        this.isServiceFee = checked;
        this.cd.detectChanges();
        if (!this.isServiceFee) {
            this.midForm.get("serviceFeeAccount").reset();
            this.midForm.get("companyName").reset();
            this.midForm.get("streetAddress1").reset();
            this.midForm.get("streetAddress2").reset();
            this.midForm.get("city").reset();
            this.midForm.get("state").reset();
            this.midForm.get("zipCode").reset();
            this.midForm.get("accountNumber").reset();
            this.midForm.get("routingNumber").reset();
            this.midForm.get("accountType").reset();
            this.midForm.get("fein").reset();
        }
    }

    onServiceFeeAccountChanged(serviceFeeAccountSelect: VituFormFieldSelectComponent) {
        const accountId = serviceFeeAccountSelect.value;
        if (accountId === null) {
            this.midForm.get("companyName").reset();
            this.midForm.get("streetAddress1").reset();
            this.midForm.get("streetAddress2").reset();
            this.midForm.get("city").reset();
            this.midForm.get("state").reset();
            this.midForm.get("zipCode").reset();
            this.midForm.get("accountNumber").reset();
            this.midForm.get("routingNumber").reset();
            this.midForm.get("accountType").reset();
            this.midForm.get("fein").reset();
        }
        else {
            const account: MerchantProcessorAccountDto = this.accounts.find(acc => acc.id === accountId);
            this.midForm.get("companyName").setValue(account.companyName);
            this.midForm.get("streetAddress1").setValue(account.address1);
            this.midForm.get("streetAddress2").setValue(account.address2);
            this.midForm.get("city").setValue(account.city);
            this.midForm.get("state").setValue(account.state);
            this.midForm.get("zipCode").setValue(account.zipCode);
            this.midForm.get("accountNumber").setValue(account.accountNumber);
            this.midForm.get("routingNumber").setValue(account.routingNumber);
            this.midForm.get("accountType").setValue(account.accountType);
            this.midForm.get("fein").setValue(account.fein);
        }
        this.cd.detectChanges();
    }

    get hasLinkedAccount(): boolean {
        const serviceFeeAccountValue = this.midForm?.get("serviceFeeAccount").value;
        return (serviceFeeAccountValue != null);
    }

    get showServiceFeeMidField(): boolean {
        return (this.processorFiserv && !this.isServiceFee);
    }

    get showServiceFeeField(): boolean {
        return (this.processorPayPal || (this.processorFiserv && (!this.isServiceFee && this.hasServiceFeeMid)));
    }

    get showSurchargeField(): boolean {
        return (this.processorFiserv && !this.isServiceFee && !this.hasServiceFeeMid);
    }

    onProcessorChanged(processor: ProcessorEnum) {
        const serviceFeeField = this.midForm?.get("serviceFee");
        if (processor === ProcessorEnum.PayPal) {
            serviceFeeField?.setValidators([Validators.min(0)]);
        }
        else {
            serviceFeeField?.setValidators([Validators.required, Validators.min(0)]);
        }
        this.midForm?.updateValueAndValidity();

        let formControlsToDisable: Map<string, number>;
        let formControlsToEnable: Map<string, number>;

        if (processor === ProcessorEnum.Fiserv) {
            formControlsToDisable = this.formControlsPayPal;
            formControlsToEnable = this.formControlsFiserv;
        }
        else if (processor === ProcessorEnum.PayPal) {
            formControlsToDisable = this.formControlsFiserv;
            formControlsToEnable = this.formControlsPayPal;
        }

        formControlsToDisable?.forEach((_: number, key: string) => {
            this.midForm?.get(key)?.disable();
        });
        formControlsToEnable?.forEach((_: number, key: string) => {
            this.midForm?.get(key)?.enable();
        });
        this.cd.detectChanges();
    }

    get hasServiceFeeMid(): boolean {
        const serviceFeeMidValue = this.midForm?.get("serviceFeeMid").value;
        return (serviceFeeMidValue != null);
    }

    onClickDelete() {
        this.deleteMid.emit({
            id: this.mid.id,
            organizationId: this.organizationId
        });
    }

    onCloverTokenChanged() {
        this.midForm.get("cloverMID").updateValueAndValidity();
    }

    onClickClover(formDirty: boolean) {
        this.connectClover.emit({
            id: this.mid.id,
            organizationId: this.organizationId,
            redirectUri: `${window.location.href}?cloverupdated=true`,
            formDirty,
            isCreate: this.isCreate
        });
    }

    onSubmit() {
        const account = this.mid.account || {};
        account.accountNumber = this.midForm.get("accountNumber").value;
        account.accountType = this.midForm.get("accountType").value;
        account.address1 = this.midForm.get("streetAddress1").value;
        account.address2 = this.midForm.get("streetAddress2").value;
        account.city = this.midForm.get("city").value;
        account.companyName = this.midForm.get("companyName").value;
        account.fein = this.midForm.get("fein").value;
        account.routingNumber = this.midForm.get("routingNumber").value;
        account.state = this.midForm.get("state").value;
        account.zipCode = this.midForm.get("zipCode").value;

        const dba = this.midForm.get("dba").value;
        const descriptor = this.midForm.get("descriptor").value;
        const feePlanId = this.midForm.get("feePlan").value;
        let surchargePercent = this.midForm.get("surcharge").value;
        if (typeof surchargePercent === "string") {
            surchargePercent = parseFloat(surchargePercent);
        }

        const isMonthlyFunding = !!(this.midForm.get("isMonthlyFunding").value);
        const automatedAdjustmentsEnabled = !!(this.midForm.get("automatedAdjustmentsEnabled").value);
        const isNotFunded = !!(this.midForm.get("isNotFunded").value);

        const mid = this.midForm.get("mid").value;
        const processor = this.midForm.get("processor").value;

        let gatewayConfig: GatewayConfigDto = null;
        if (processor === ProcessorEnum.Fiserv) {
            const cloverMID = this.midForm.get("cloverMID").value;
            if (cloverMID) {
                gatewayConfig = {};
                gatewayConfig.clover = {
                    cloverMID,
                    publicToken: this.midForm.get("publicToken").value,
                    apiToken: this.midForm.get("apiToken").value
                };
            }

        }
        else if (processor === ProcessorEnum.PayPal) {
            gatewayConfig = {};
            gatewayConfig.payPal = {};
            this.writeTreeNodes(this.data, gatewayConfig.payPal);
        }

        const serviceFeeAccountId = (this.isServiceFee ? this.midForm.get("serviceFeeAccount").value : null);
        const serviceFeeMidId = this.midForm.get("serviceFeeMid").value;
        let serviceFeePercent = this.midForm.get("serviceFee").value;
        if (typeof serviceFeePercent === "string") {
            serviceFeePercent = parseFloat(serviceFeePercent);
        }

        if (this.isCreate) {

            const isServiceFee = this.isServiceFee;
            const createMerchantProcessorDto: CreateMerchantProcessorDto = {
                account,
                dba,
                descriptor,
                feePlanId,
                mid,
                processor,
                gatewayConfig,
                isServiceFee,
                isMonthlyFunding,
                automatedAdjustmentsEnabled,
                isNotFunded
            };

            if (this.processorPayPal) {
                createMerchantProcessorDto.serviceFeePercent = serviceFeePercent;
            }
            else if (this.processorFiserv) {
                if (this.isServiceFee) {
                    createMerchantProcessorDto.serviceFeeAccountId = serviceFeeAccountId;
                }
                else {
                    createMerchantProcessorDto.serviceFeeMidId = serviceFeeMidId;
                    if (this.hasServiceFeeMid) {
                        createMerchantProcessorDto.serviceFeePercent = serviceFeePercent;
                    }
                    else {
                        createMerchantProcessorDto.surchargePercent = surchargePercent;
                    }
                }
            }

            this.createMid.emit({
                organizationId: this.organizationId,
                dto: createMerchantProcessorDto
            });

        }
        else {

            const updateMerchantProcessorDto: UpdateMerchantProcessorDto = {
                account,
                dba,
                descriptor,
                feePlanId,
                gatewayConfig,
                processor,
                isMonthlyFunding,
                automatedAdjustmentsEnabled,
                isNotFunded
            };

            if (this.processorPayPal) {
                updateMerchantProcessorDto.serviceFeePercent = serviceFeePercent;
            }
            else if (this.processorFiserv) {
                if (this.isServiceFee) {
                    updateMerchantProcessorDto.serviceFeeAccountId = serviceFeeAccountId;
                }
                else {
                    updateMerchantProcessorDto.serviceFeeMidId = serviceFeeMidId;
                    if (this.hasServiceFeeMid) {
                        updateMerchantProcessorDto.serviceFeePercent = serviceFeePercent;
                    }
                    else {
                        updateMerchantProcessorDto.surchargePercent = surchargePercent;
                    }
                }
            }

            this.updateMid.emit({
                id: this.mid.id,
                organizationId: this.organizationId,
                dto: updateMerchantProcessorDto
            });

        }

    }

    isInputElement(schemaKey: string) {
        return this.schemaKeyMatches(schemaKey, "i");
    }

    isNumberInputElement(schemaKey: string) {
        return this.schemaKeyMatches(schemaKey, "n");
    }

    isSelectElement(schemaKey: string) {
        return this.schemaKeyMatches(schemaKey, "e") || this.schemaKeyMatches(schemaKey, "b");
    }

    isDateElement(schemaKey: string) {
        return this.schemaKeyMatches(schemaKey, "d");
    }

    isTimeElement(schemaKey: string) {
        return this.schemaKeyMatches(schemaKey, "t");
    }

    getNumberInputOptionsForKey(schemaKey: string) {
        let retVal;
        if (this.isNumberInputElement(schemaKey)) {
            return payPalSchemaOptions[schemaKey];
        }
        return retVal;
    }

    getSelectOptionsForKey(schemaKey: string) {
        const emptyItem = [{key: "", value: null}];
        if (this.schemaKeyMatches(schemaKey, "b")) {
            return emptyItem.concat([{ key: "No", value: false }, { key: "Yes", value: true }]);
        }
        else if (this.schemaKeyMatches(schemaKey, "e")) {
            const options = payPalSchemaOptions[schemaKey] ? (payPalSchemaOptions[schemaKey]).options : [];
            return emptyItem.concat(options.map(option => ({ key: option.replace(/\_/g, " "), value: option })));
        }
        return [];
    }

    getDateOptionsForKey(schemaKey: string) {
        let retVal;
        if (this.isDateElement(schemaKey)) {
            return payPalSchemaOptions[schemaKey];
        }
        return retVal;
    }

    getTimeOptionsForKey(schemaKey: string, controlName: string) {
        const emptyItem = [{key: "", value: undefined}];
        const timeValue = this.midForm?.get(controlName)?.value;
        if (this.schemaKeyMatches(schemaKey, "t")) {
            const timeLabel = this.datePipe.transform(timeValue, "MM/dd/yyyy h:mm:ss a", LocalTimePoint.formatZ());
            return emptyItem.concat([{ key: timeLabel, value: timeValue }]);
        }
        return [];
    }

    getTimeLabel(labelMain: string) {
        if (typeof labelMain === "string") {
            return this.spaceBetweenCapsPipe.transform(labelMain, true) +  ` (${LocalTimePoint.formatZ()})`;
        }
        return "";
    }

    onBack(): void {
        this.back.emit();

        // const current = {};
        // console.log("treeData", this.data);
        // this.writeTreeNodes(this.data, current);
        // console.log("current", current);
    }

    onAssociatedMidRowSelected(mid: number) {
        this.router.navigate([`/dashboard/organizations/${this.organizationId}/mids/${mid}`]);
    }

    onAssociatedTidRowSelected(tid: number) {
        this.router.navigate([`/dashboard/organizations/${this.organizationId}/tids/${tid}`]);
    }

    hasChildren(flatNode: VituTreeFlatNode) {
        const node = this.flatNodeMap.get(flatNode);
        return !!node?.children?.length;
    }

    hasAddItem(flatNode: VituTreeFlatNode) {

        if (!this.editAvailable) {
            return false;
        }

        let retVal = true;
        const isArrayItem = !isNaN(parseInt(flatNode.item.key, 10));
        if (isArrayItem) {
            return false;
        }
        const node = this.flatNodeMap.get(flatNode);
        if (node.item.schemaKey === VituTreeNodeSchemaKey.OBJECT) {
            retVal = !node?.children?.length;
        }

        return retVal;

    }

    hasRemoveItem(flatNode: VituTreeFlatNode) {

        if (!this.editAvailable) {
            return false;
        }

        if (!this.treeControl.isExpanded(flatNode)) {
            return false;
        }
        let retVal = false;
        const isArrayItem = !isNaN(parseInt(flatNode.item.key, 10));
        if (isArrayItem) {
            return true;
        }
        const node = this.flatNodeMap.get(flatNode);
        if (node.item.schemaKey === VituTreeNodeSchemaKey.OBJECT) {
            retVal = !!node?.children?.length;
        }

        return retVal;
    }

    addItem(flatNode: VituTreeFlatNode) {

        const flatNodeSchema = this.getSchemaForFlatNode(flatNode);
        const parent: VituTreeNode = this.flatNodeMap.get(flatNode);
        const isArray: boolean = Array.isArray(flatNodeSchema);
        const itemSchema: object = isArray ? flatNodeSchema[0] : flatNodeSchema;

        if (parent.children) {
            const newKey = `${parent.children.length}`;

            const newChildren: VituTreeNode[] = [];
            Object.entries(itemSchema).forEach(([itemSchemaKey, itemSchemaValue]) => {

                let value: any;
                let children: any;

                const key = itemSchemaKey.split("_")[0];
                let schemaKey = (itemSchemaKey.split("_").length > 0) ? itemSchemaKey.split("_")[1] : null ;

                if (Array.isArray(itemSchemaValue)) {
                    children = [];
                    schemaKey = VituTreeNodeSchemaKey.ARRAY;
                }
                else if ((typeof itemSchemaValue === "object") && (itemSchemaValue !== null)) {
                    children = [];
                    schemaKey = VituTreeNodeSchemaKey.OBJECT;
                }

                const newItem: VituTreeNode = { item: { key, value, schemaKey }, children };
                if ((schemaKey !== VituTreeNodeSchemaKey.ARRAY) && (schemaKey !== VituTreeNodeSchemaKey.OBJECT)) {
                    const formControlName = this.addFormControl(newItem, schemaKey);
                    newItem.item.formControlName = formControlName;
                    newItem.item.infoTip = payPalSchemaOptions[schemaKey]?.infoTip;
                    newItem.item.ignored = payPalSchemaOptions[schemaKey]?.ignored;
                    newItem.item.ignoredCreate = payPalSchemaOptions[schemaKey]?.ignoredCreate;
                }

                newChildren.push(newItem);
            });

            let expandNode: VituTreeNode;
            if (isArray) {
                const newChild = {
                    item: {
                        key: newKey,
                        value: undefined,
                        schemaKey: VituTreeNodeSchemaKey.OBJECT
                    },
                    children: newChildren
                } as VituTreeNode;
                parent.children.push(newChild);
                expandNode = newChild;
            }
            else {
                parent.children = newChildren;
            }

            this.dataChange.next(this.data);

            this.sortTree();

            if (expandNode) {
                this.treeControl.expand(this.nestedNodeMap.get(expandNode));
            }
        }

        this.treeControl.expand(flatNode);

        this.onTreeChanged();
    }

    removeItem(flatNode: VituTreeFlatNode) {

        const node = this.flatNodeMap.get(flatNode);
        const isArrayItem = !isNaN(parseInt(flatNode.item.key, 10));

        if (isArrayItem) {
            const arrayItemIndex = parseInt(flatNode.item.key, 10);
            const parentFlatNode = this.getParentNode(flatNode);
            const parentNode = this.flatNodeMap.get(parentFlatNode);
            const deletedChildren = parentNode.children.splice(arrayItemIndex, 1);
            if (deletedChildren?.length) {
                const deletedChild = deletedChildren[0];
                if (deletedChild.item?.formControlName) {
                    this.removeFormControl(deletedChild.item.formControlName);
                }
            }
            parentNode.children.forEach((child, index) => {
                let childKey = parseInt(child.item.key, 10);
                if (childKey >= arrayItemIndex) {
                    child.item.key = `${--childKey}`;
                }
            });
            this.dataChange.next(this.data);
            if (!parentNode.children.length) {
                this.treeControl.collapse(parentFlatNode);
            }
        }

        if (node.item.schemaKey === VituTreeNodeSchemaKey.OBJECT) {
            if (node?.children) {
                node.children.forEach(nodeChild => {
                    if (nodeChild.item?.formControlName) {
                        this.removeFormControl(nodeChild.item.formControlName);
                    }
                });
                node.children = [];
                this.dataChange.next(this.data);
            }
            this.treeControl.collapse(flatNode);
        }

        this.onTreeChanged();
    }

    formatKeyForDisplay(flatNode: VituTreeFlatNode): string {

        let retVal: string = flatNode.item.key;

        retVal = this.spaceBetweenCapsPipe.transform(retVal, true);
        retVal = this.upperCasePipe.transform(retVal);

        if (this.nodeIsArray(flatNode)) {
            const node = this.flatNodeMap.get(flatNode);
            const count = node.children ? node.children.length : 0;
            retVal = `${retVal} (${count})`;
        }
        else {
            const parsedKeyVal = parseInt(retVal, 10);
            const isArrayItem = !isNaN(parsedKeyVal);
            retVal = isArrayItem ? `${parsedKeyVal + 1}` : retVal;
        }

        return retVal;
    }

    private nodeIsArray(flatNode: VituTreeFlatNode) {
        const node = this.flatNodeMap.get(flatNode);
        return (node?.item?.schemaKey === VituTreeNodeSchemaKey.ARRAY);
    }

    private decodeAndDisplayErrors(errorPayload: any) {

        this.flatNodeMap.forEach((nestedNode: VituTreeNode, flatNode: VituTreeFlatNode) => {
            // important to reset the errorDetails on all the other nodes
            flatNode.errorDetails = undefined;
        });

        this.unmatchedPayPalErrors = [];

        if (!this.processorPayPal) {
            // only include enhanced error handling for PayPal for now
            return;
        }

        let errorCount = 0;

        let errors: any;
        if (errorPayload?.baseError?.error) {
            if (typeof errorPayload?.baseError?.error === "string") {
                const parsedErrors = JSON.parse(errorPayload?.baseError?.error);
                if ((typeof parsedErrors?.errors === "object") && (parsedErrors?.errors != null)) {
                    errors = parsedErrors.errors;
                }
            }
            else {
                errors = errorPayload?.baseError?.error.errors;
            }
        }

        if (errors) {
            if ((typeof errors === "object") && (errors != null)) {
                Object.entries(errors).forEach(([errorKey, errorValue]) => {
                    errorKey = errorKey.replace("GatewayConfig.PayPal.", "");
                    errorKey = errorKey.replace(/\[(\d+)\]/g, "\.$1");
                    const errorKeys = errorKey.split(".");
                    const nodePathToMatch = errorKeys.map(errKey => errKey.charAt(0).toLowerCase() + errKey.slice(1));

                    let maxMatchedPathItemsCount = -1;
                    let minLengthOfMatch = 999;
                    let matchPath: Array<string>;
                    let matchedFlatNode: VituTreeFlatNode;

                    this.flatNodeMap.forEach((nestedNode: VituTreeNode, flatNode: VituTreeFlatNode) => {
                        const nodePath = this.getPathForFlatNode(flatNode, false);
                        const matchResults = this.getMatchedPathItemsCount(nodePath, nodePathToMatch);

                        if ((matchResults.count > 0) && ((matchResults.count > maxMatchedPathItemsCount) ||
                            ((matchResults.count === maxMatchedPathItemsCount) && (matchResults.lengthOfMatch < minLengthOfMatch)))) {
                            matchPath = nodePath;
                            maxMatchedPathItemsCount = matchResults.count;
                            minLengthOfMatch = matchResults.lengthOfMatch;
                            matchedFlatNode = flatNode;
                        }
                    });

                    if (errorValue) {
                        let errorDetails: string = errorValue as any;
                        ++errorCount;

                        if (matchedFlatNode) {
                            // Error matches node => Display next to node
                            if (matchPath?.length < nodePathToMatch?.length) {
                                const extraItems = nodePathToMatch.length - matchPath.length;
                                if (extraItems > 0) {
                                    errorDetails += " (Add item to see errors.)";
                                }
                            }
                            matchedFlatNode.errorDetails = errorDetails;

                            this.expandTreeToNode(matchedFlatNode);
                        }
                        else {
                            // Error doesn't match node => Display in general paypal errors
                            this.unmatchedPayPalErrors.push(`ERROR : ${errorDetails}`);
                        }
                    }
                });
            }
        }

        this.dataChange.next(this.data);

        if (errorCount > 0) {
            const errorMessage = (errorCount === 1)
                ? `There is 1 error in the PayPal configuration. Please fix it and submit form again.`
                : `There are ${errorCount} errors in the PayPal configuration. Please fix these and submit form again.`;
            this.toast.open(errorMessage, VituToastTone.Negative);
        }

        setTimeout(() => {
            let errorSelector: string;
            // Show any general (unmatched) errors before specific (matched) errors
            if (this.unmatchedPayPalErrors.length > 0) {
                errorSelector = ".error-details-paypal-unmatched";
            }
            else {
                errorSelector = ".error-details-outer-container";
            }
            const errorElementToShow = document.querySelector(errorSelector);
            if (errorElementToShow) {
                errorElementToShow.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }, 0);
    }

    private expandTreeToNode(flatNode: VituTreeFlatNode) {
        let flatNodeParent = this.getParentNode(flatNode);
        while (flatNodeParent !== null) {
            this.treeControl.expand(flatNodeParent);
            flatNodeParent = this.getParentNode(flatNodeParent);
        }
    }

    private getMatchedPathItemsCount(nodePath: Array<string>, nodePathToMatch: Array<string>) {
        let count = 0;
        nodePathToMatch.forEach((item, index) => {
            if (item === nodePath[index]) {
                ++count;
            }
        });
        return { count, lengthOfMatch: nodePath.length };
    }

    private getSchemaForFlatNode(flatNode: VituTreeFlatNode): any {

        const schemaPath = this.getPathForFlatNode(flatNode, true);

        let flatNodeSchema = payPalSchema;
        schemaPath.forEach(schemaPathItem => {
            flatNodeSchema = flatNodeSchema[schemaPathItem];
        });

        return flatNodeSchema;
    }

    private getPathForFlatNode(flatNode: VituTreeFlatNode, isSchema = false): Array<string> {

        let currentFlatNode = flatNode;
        const schemaPath = [];

        do {
            let schemaPathItem = currentFlatNode.item.key;
            if (isSchema) {
                schemaPathItem = this.forceArrayIndexZero(schemaPathItem);
            }
            schemaPath.unshift(schemaPathItem);
            currentFlatNode = this.getParentNode(currentFlatNode);
        } while (currentFlatNode !== null);

        return schemaPath;
    }

    private getParentNode(node: VituTreeFlatNode): VituTreeFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    private findMatchInSchema(name: string, schemaPath: Array<string>) {

        let flatNodeSchema = payPalSchema;
        schemaPath.forEach(schemaPathItem => {
            flatNodeSchema = flatNodeSchema[schemaPathItem];
        });
        let retVal;

        if (!Array.isArray(flatNodeSchema) && (flatNodeSchema != null) && (typeof flatNodeSchema === "object")) {
            Object.entries(flatNodeSchema).forEach(([key, value]) => {
                const keyParts = key.split("_");
                if (keyParts.length > 1) {
                    if (!retVal && (keyParts[0] === name)) {
                        retVal = key;
                    }
                }
            });
        }

        return retVal;
    }

    private sortTree() {
        const newData = this.sortTreeComparator(this.data);
        this.sortTreeNodeChildren(newData);
        this.dataChange.next(newData);
    }

    private sortTreeNodeChildren(data: VituTreeNode[]) {
        for (const elmt of data) {
            if (elmt.children !== undefined) {
                elmt.children = this.sortTreeComparator(elmt.children);
                this.sortTreeNodeChildren(elmt.children);
            }
        }
    }

    private sortTreeComparator(tree) {
        return tree.sort((elem1, elem2) => {
            if (elem1?.item?.key < elem2?.item?.key) {
                return -1;
            } else if (elem1?.item?.key > elem2?.item?.key) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    private forceArrayIndexZero(key: string): string {
        const numVal = parseInt(key, 10);
        if (!isNaN(numVal)) {
            key = "0";
        }
        return key;
    }

    private writeTreeNodes(nodes: VituTreeNode[], targetObj: any) {
        nodes?.forEach((treeDataItem) => {
            this.writeTreeNode(treeDataItem, targetObj);
        });
    }

    private writeTreeNode(node: VituTreeNode, targetObj: any) {

        if (node.item.schemaKey === VituTreeNodeSchemaKey.ARRAY) {
            const newArray = (node.children.length > 0) ? [] : null;
            node.children.forEach((child) => {
                this.writeTreeNode(child, newArray);
            });
            targetObj[node.item.key] = newArray;
        }
        else if (node.item.schemaKey === VituTreeNodeSchemaKey.OBJECT) {
            const newObj = (node.children.length > 0) ? {} : null;
            node.children.forEach((child) => {
                this.writeTreeNode(child, newObj);
            });
            targetObj[node.item.key] = newObj;
        }
        else {
            let value = this.midForm?.get(node.item.formControlName)?.value;
            if (value === "") {
                // Convert all empty string fields to null to help PayPal validation
                value = null;
            }
            else if (this.isDateElement(node.item.schemaKey)) {
                // Would be better if vitu form field date handled conversion IN & OUT of these single date only
                // format strings internally (i.e. it requires 'to' & 'from' format at the moment)
                value = value?.from;
                if (value && (typeof value === "string")) {
                    const timeIndex = value.toUpperCase().indexOf("T");
                    const datePart = value.substring(0, timeIndex);
                    value = datePart;
                }
            }
            else if (this.isNumberInputElement(node.item.schemaKey)) {
                if (value != null) {
                    value = parseFloat(value);
                }
            }
            targetObj[node.item.key] = value;
        }

    }

    private augmentTreeUsingSchema() {
        this.flatNodeMap.forEach((value: VituTreeNode, key: VituTreeFlatNode) => {
            const nodePath = this.getPathForFlatNode(key, false);
            const schemaPath = this.getPathForFlatNode(key, true);
            const matchName = this.findMatchInSchema(nodePath[nodePath.length - 1], schemaPath.slice(0, -1));
            if (matchName) {
                // Attach the schema key to the tree node
                const schemaKey = (matchName.split("_").length > 0) ? matchName.split("_")[1] : null;
                value.item.schemaKey = schemaKey;
                key.item.schemaKey = schemaKey;
                const formControlName = this.addFormControl(value, schemaKey);
                value.item.formControlName = formControlName;
                key.item.formControlName = formControlName;
                value.item.infoTip = payPalSchemaOptions[schemaKey]?.infoTip;
                key.item.infoTip = payPalSchemaOptions[schemaKey]?.infoTip;
                value.item.ignored = payPalSchemaOptions[schemaKey]?.ignored;
                key.item.ignored = payPalSchemaOptions[schemaKey]?.ignored;
                value.item.ignoredCreate = payPalSchemaOptions[schemaKey]?.ignoredCreate;
                key.item.ignoredCreate = payPalSchemaOptions[schemaKey]?.ignoredCreate;
            }
        });
    }

    private addFormControl(node: VituTreeNode, schemaKey: any) {
        const formControlName = `${this.treeNodeControlPrefix}${++this.treeNodeControlCount}`;
        let controlValue = node.item.value;
        // Would be better if vitu form field date handled conversion IN & OUT of these single date only
        // format strings internally (i.e. it requires 'to' & 'from' format at the moment)
        if (this.isDateElement(schemaKey) && node.item?.value) {
            const utcSpecifiedDate = new Date(node.item.value).toISOString();
            controlValue = {
                from: utcSpecifiedDate,
                to: utcSpecifiedDate
            };
        }
        this.midForm.addControl(
            formControlName,
            new FormControl(controlValue, (payPalSchemaOptions[schemaKey]).clientValidators)
        );
        this.formControlsPayPal.set(formControlName, 1);
        return formControlName;
    }

    private removeFormControl(formControlName: string) {
        this.midForm.removeControl(formControlName);
        this.formControlsPayPal.delete(formControlName);
    }

    private onTreeChanged() {
        const newValue = Math.random();
        const shaControl = this.midForm.get("payPalConfigSHA");
        shaControl.setValue(newValue);
        shaControl.updateValueAndValidity();
        if (newValue !== this.initialPayPalConfigSHA) {
            // manually update this (as programmatic update)
            this.midForm.markAsDirty();
        }
    }

    private schemaKeyMatches(schemaKey: string, match: string) {
        return (schemaKey && schemaKey.startsWith(match));
    }

    private validateFormControls(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup?.controls).forEach(field => {
            formGroup?.get(field)?.updateValueAndValidity();
        });
    }

    private objectKeys(obj: any, schema: any, level: number): Array<any> {
        // Ensure we init tree with objects which have complete properties
        let retVal = [];
        const objKeys = Object.keys(obj);
        if ((level === 0) || objKeys?.length) {
            let schemaKeys = Object.keys(schema);
            schemaKeys = schemaKeys.map(schemaKey => schemaKey.split("_")[0]);
            retVal = [...new Set([...schemaKeys, ...objKeys])];
        }
        return retVal;
    }

    // Build tree (list of VituTreeNode) from JSON structure
    private buildTree(obj: { [key: string]: any }, schema: any, level: number): VituTreeNode[] {
        return this.objectKeys(obj, schema, level).reduce<VituTreeNode[]>((accumulator, key) => {
            let value = obj[key];
            const node = new VituTreeNode();
            node.item = { key, value: undefined, schemaKey: undefined };

            if (value != null) {
                if (typeof value === "object") {
                    if (Array.isArray(value)) {
                        node.item.schemaKey = VituTreeNodeSchemaKey.ARRAY;
                    }
                    else {
                        node.item.schemaKey = VituTreeNodeSchemaKey.OBJECT;
                    }
                    node.children = this.buildTree(value, schema[this.forceArrayIndexZero(key)], level + 1);
                }
                else {
                    node.item = { key, value };
                }
            }
            else {
                const schemaValue = schema[key];
                if ((typeof schemaValue === "object")) {
                    if (Array.isArray(schemaValue)) {
                        node.item.schemaKey = VituTreeNodeSchemaKey.ARRAY;
                        value = [];
                    }
                    else {
                        node.item.schemaKey = VituTreeNodeSchemaKey.OBJECT;
                        value = {};
                    }
                    node.children = this.buildTree(value, schema[this.forceArrayIndexZero(key)], level + 1);
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    private cloverConfigValid(c: UntypedFormControl) {
        const cloverMID = this.midForm?.get("cloverMID")?.value;
        const publicToken = this.midForm?.get("publicToken")?.value;
        const apiToken = this.midForm?.get("apiToken")?.value;
        if (!this.isCreate) {
            const invalidDelete = (this.uneditedFormConfig.cloverMID && !(cloverMID?.length));
            if (invalidDelete) {
                return { cloverConfigDeleteInvalid: { valid: false } };
            }
        }
        const invalidEdit = (!(cloverMID?.length > 0) && ((publicToken?.length > 0) || (apiToken?.length > 0)));
        if (invalidEdit) {
            return { cloverConfigInvalid: { valid: false } };
        }
        return null;
    };

}
