import { MerchantProcessorCloverDeviceDto } from "@admin_api/models/merchant-processor-clover-device-dto";
import { MerchantProcessorDetailsDto } from "@admin_api/models/merchant-processor-details-dto";

export class MidState {
    loading = false;
    error = null;
    record: MerchantProcessorDetailsDto = null;
    cloverDevices: Array<MerchantProcessorCloverDeviceDto> = [];
    cloverDevicesError: Error = null;
    cloverDevicesLoading = false;
    reassignDeviceLoading = false;
    reassignDeviceError = null;
    reassignDeviceRecord: MerchantProcessorDetailsDto = null;
    editMidError = null;
}
