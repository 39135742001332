import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActionButtonKind, ColumnDef, EmailRegex, TelephoneRegex, ZipRegex,
    ValidatorsVituCustom, LocalTimePoint, Pager, TableSorting } from "shared-lib";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, UrlSegment } from "@angular/router";
import { Location } from "@angular/common";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { SolutionEnum } from "@admin_api/models/solution-enum";
import { CreateOrganizationDto } from "@admin_api/models/create-organization-dto";
import { MerchantProcessorDto } from "@admin_api/models/merchant-processor-dto";
import { StateEnum } from "@admin_api/models/state-enum";
import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { ShowInformationModalAction } from "@admin_app/storage/app/app.actions";
import { UserDto } from "@admin_api/models/user-dto";
import { ConfigDto } from "@admin_app/storage/config/config.state";
import { UpdateOrganizationDto } from "@admin_api/models/update-organization-dto";
import { OrganizationOrderBy } from "@admin_api/models/organization-order-by";
import { Filter as MidsFilter } from "@admin_app/storage/mids/mids.state";
import { Filter as TidsFilter } from "@admin_app/storage/tids/tids.state";
import { Sorting as MerchantHistorySorting } from "@admin_app/storage/merchant-history/merchant-history.state";
import { Filter as MerchantHistoryFilter } from "@admin_app/storage/merchant-history/merchant-history.state";
import { MerchantChangeDto } from "@admin_api/models/merchant-change-dto";
import { MerchantChangeOrderByEnum } from "@admin_api/models/merchant-change-order-by-enum";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-organization-details",
    templateUrl: "./organization-details.component.html",
    styleUrls: ["./organization-details.component.less"],
})
export class OrganizationDetailsComponent implements OnChanges {

    @Input() isCreate: boolean;
    @Input() parentOrganizationId: number;

    @Input() organization: OrganizationDto;
    @Input() organizationLoading = false;
    @Input() organizationAncestors: Array<OrganizationDto> = [];
    @Input() organizationAncestorsLoading = false;

    @Input() organizations: Array<OrganizationDto> = [];
    @Input() organizationsLoading = false;
    @Input() organizationsError: Error;

    @Input() mids: Array<MerchantProcessorDto> = [];
    @Input() midsLoading = false;
    @Input() midsError: Error;

    @Input() tids: Array<MerchantTerminalDto> = [];
    @Input() tidsLoading = false;
    @Input() tidsError: Error;

    @Input() users: Array<UserDto> = [];
    @Input() usersLoading = false;
    @Input() usersError: Error;

    @Input() merchantHistory: Array<MerchantChangeDto> = [];
    @Input() merchantHistoryLoading = false;
    @Input() merchantHistoryError: Error;
    @Input() merchantHistorySorting: any = {
        orderBy: null,
        orderDirection: null
    };
    @Output() merchantHistorySortingChanged = new EventEmitter<{id: number; sorting: MerchantHistorySorting}>();
    @Input() merchantHistoryFilter: MerchantHistoryFilter;
    @Output() merchantHistoryFilterChanged = new EventEmitter<{id: number; filter: MerchantHistoryFilter}>();
    @Input() merchantHistoryPager: Pager;
    @Output() merchantHistoryPageChanged = new EventEmitter<{id: number; page: number}>();

    @Input() config: ConfigDto;

    @Input() midFilter: any;
    @Output() midFilterChanged = new EventEmitter<{id: number; filter: any}>();

    @Input() tidFilter: any;
    @Output() tidFilterChanged = new EventEmitter<{id: number; filter: any}>();

    @Output() deleteOrganization = new EventEmitter<number>();
    @Output() updateOrganization = new EventEmitter<any>();
    @Output() createOrganization = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<void>();

    LocalTimePoint = LocalTimePoint;

    organizationId: number;
    loadContext = 0;

    solutions: Array<any> = [];
    states: Array<any> = [];

    uneditedFormConfig: any = {};

    merchantAlreadyLive = false;

    private get baseUrl(): string {
        return "/dashboard/organizations/" + (this.isCreate ? "create" : `${this.organizationId}`);
    }

    pageTitle: string;
    submitButtonText: string;
    showingDeletedMids = false;
    showingDeletedTids = false;

    ActionButtonKind = ActionButtonKind;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    organizationColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: OrganizationOrderBy.Id, title: "Organization Ref", flexWidthBasisInPixels: 120, flexWidthGrow: 1, canSort: true},
        { id: OrganizationOrderBy.Name, title: "Organization Name", flexWidthBasisInPixels: 230, flexWidthGrow: 1, canSort: true},
        { id: "contact", title: "Contact", flexWidthBasisInPixels: 260, flexWidthGrow: 1},
        { id: "address", title: "Address", flexWidthBasisInPixels: 200, flexWidthGrow: 1},
        { id: OrganizationOrderBy.Solution, title: "Solution", flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true},
        { id: "isMerchant", title: "Merchant", flexWidthBasisInPixels: 100, flexWidthGrow: 1},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    get midColumnDefs(): ColumnDef[] {
        return [
            { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            { id: "mid", title: "MID", flexWidthBasisInPixels: 200, flexWidthGrow: 1, canSort: true },
            { id: "dba", title: "DBA", flexWidthBasisInPixels: 200, flexWidthGrow: 1, canSort: true },
            { id: "processor", title: "Processor", flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true },
            { id: "isServiceFee", title: "Service Fee", flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true },
            { id: "deletedUtc", title: this.showingDeletedMids ? `Date Deleted (${LocalTimePoint.formatZ()})` : "",
                flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
            { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    get tidColumnDefs(): ColumnDef[] {
        return [
            { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            { id: "tid", title: "TID", flexWidthBasisInPixels: 120, flexWidthGrow: 4, canSort: true },
            { id: "mid", title: "MID", flexWidthBasisInPixels: 120, flexWidthGrow: 4, canSort: true },
            { id: "processor", title: "Processor", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
            { id: "gateway", title: "Gateway", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
            { id: "mai", title: "MAI", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
            { id: "deviceSerial", title: "Device Serial", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
            { id: "deviceName", title: "Device Name", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
            { id: "deviceLocation", title: "Device Location", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
            { id: "status", title: "Status", flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true },
            { id: "deletedUtc", title: this.showingDeletedTids ? `Date Deleted (${LocalTimePoint.formatZ()})` : "",
                flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
            { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    userColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "firstName", title: "First Name", flexWidthBasisInPixels: 250, flexWidthGrow: 0, canSort: true },
        { id: "lastName", title: "Last Name", flexWidthBasisInPixels: 250, flexWidthGrow: 0, canSort: true },
        { id: "email", title: "Email", flexWidthBasisInPixels: 450, flexWidthGrow: 0, canSort: true },
        { id: "lockedStatus", title: "Locked Status", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    merchantHistoryColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: MerchantChangeOrderByEnum.CreatedUtc, title: `Date (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 140, flexWidthGrow: 0, canSort: true},
        { id: "userEmail", title: "User Email", flexWidthBasisInPixels: 220, flexWidthGrow: 0, canSort: false },
        { id: "description", title: "Description", flexWidthBasisInPixels: 250, flexWidthGrow: 1, canSort: false },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    get isCreateTidDisabled(): boolean {
        return this.organizationLoading || !this.mids?.length;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private fb: UntypedFormBuilder,
        private store: Store<IStore>
    ) {
        this.solutions = Object.values(SolutionEnum);
        this.states = Object.values(StateEnum);
    }

    getDisplayTextForSolution(solutionVal: string): string {
        let text = "";
        if (solutionVal && this.config?.solutionLookup) {
            const found = this.config.solutionLookup.filter(item => (item.id === solutionVal));
            if (found?.length) {
                text = found[0].name;
            }
        }
        return text;
    }

    get crumbs() {
        const retVal: any = [ { label: "Organizations" , link: "/dashboard/organizations" } ];
        if (this.organizationAncestorsLoading) {
            retVal.push({ label: "---" });
        }
        this.organizationAncestors.forEach(
            (ancestor: OrganizationDto)=> {
                retVal.push({ label: ancestor.name, link: `/dashboard/organizations/${ancestor.id}` });
            }
        );
        retVal.push({ label: this.pageTitle });
        return retVal;
    }

    private organizationFormConfig: FormConfig<any> = {
        organizationRef: [null, [Validators.required]],
        organizationName: [null, [Validators.required]],
        solution: [null, [Validators.required, ValidatorsVituCustom.selectSingleIntegrity(() => this.solutions)]],
        isMerchant: [null, []],
        isMerchantLive: [null, []],
        merchantLiveDate: [null, []],
        contactName: [null, [Validators.required]],
        contactNumber: [null, [Validators.required, Validators.pattern(TelephoneRegex)]],
        contactEmail: [null, [Validators.required, Validators.pattern(EmailRegex)]],
        streetAddress1: [null, [Validators.required]],
        streetAddress2: [null, []],
        city: [null, [Validators.required]],
        state: [null, [Validators.required, ValidatorsVituCustom.selectSingleIntegrity(() => this.states)]],
        zipCode: [null, [Validators.required, Validators.pattern(ZipRegex)]]
    };

    organizationForm = this.fb.group(this.organizationFormConfig);

    get formControls(): FormControls<any> { return this.organizationForm?.controls; }

    getFormControl(name: string): UntypedFormControl {
        return this.organizationForm?.controls[name] as UntypedFormControl;
    }

    get organizationIsMerchant(): boolean {
        return !!this.getFormControl("isMerchant")?.value;
    }

    get merchantLiveDate(): string {
        return this.getFormControl("merchantLiveDate")?.value;
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.isCreate && changes.isCreate.firstChange) {
            this.isCreate = changes.isCreate.currentValue;
            // if (!this.isCreate) {
            //     this.seedTab();
            // }
            this.pageTitle = (this.isCreate ? "New" : "Edit") + " Organization";
            this.submitButtonText = (this.isCreate ? "Submit" : "Update");
        }

        if (changes.organization && this.organization) {

            if (!this.isCreate) {
                this.seedTab();
            }

            this.uneditedFormConfig = {
                organizationRef: this.organization.id,
                organizationName: this.organization.name,
                solution: this.organization.solution,
                isMerchant: this.organization.isMerchant,
                isMerchantLive: !!this.organization.merchantLive,
                merchantLiveDate: this.organization.merchantLive,
                streetAddress1: this.organization.address?.address1,
                streetAddress2: this.organization.address?.address2,
                city: this.organization.address?.city,
                state: this.organization.address?.state,
                zipCode: this.organization.address?.postalCode
            };

            this.merchantAlreadyLive = this.uneditedFormConfig.isMerchantLive;

            if (this.organization.contacts?.length) {
                const firstContact = this.organization.contacts[0];
                this.uneditedFormConfig.contactName = firstContact.name;
                this.uneditedFormConfig.contactNumber = firstContact.phone;
                this.uneditedFormConfig.contactEmail = firstContact.email;
            }

            this.organizationForm.patchValue(this.uneditedFormConfig);
            if (!this.isCreate) {
                this.organizationForm.markAllAsTouched();
            }
        }

        // Workaround needed for form using ValidatorsVituCustom:
        // ValidatorsVituCustom need to be run by forcing validation if anything they depend on changes,
        // for example as a result of the allowed values being retrieved asynchronously from server (& arriving late).
        // (TODO : Consolidate use of Angular Forms in shareable form base class which implements our common form
        // behaviour like this as default for all of our forms (instead of having to apply it in multiple places))
        this.validateFormControls(this.organizationForm);
    }

    onClickDelete() {
        this.deleteOrganization.emit(this.organization.id);
    }

    onClickCreateOrganization() {
        this.location.replaceState(`${this.baseUrl}/organizations`);        // set up BACK url
        this.router.navigate([`${this.baseUrl}/organizations/create`]);
    }

    onClickCreateMid() {
        this.location.replaceState(`${this.baseUrl}`);      // set up BACK url
        this.router.navigate([`${this.baseUrl}/mids/create`]);
    }

    onClickCreateTid() {
        if (this.isCreateTidDisabled) {
            const title = "Not Available";
            const message = "TID cannot be created without an available MID.";
            const buttonText = "Continue";
            this.store.dispatch(new ShowInformationModalAction(title, message, buttonText));
        }
        else {
            this.location.replaceState(`${this.baseUrl}/tids`);     // set up BACK url
            this.router.navigate([`${this.baseUrl}/tids/create`]);
        }
    }

    onClickCreateUser() {
        this.location.replaceState(`${this.baseUrl}/users`);                // set up BACK url
        this.router.navigate([`${this.baseUrl}/users/create`]);
    }

    onOrganizationRowSelected(organizationId: number) {
        this.location.replaceState(`${this.baseUrl}/organizations`);        // set up BACK url
        this.router.navigate([`/dashboard/organizations/${organizationId}`]);
    }

    onMidRowSelected(mid: number) {
        this.location.replaceState(`${this.baseUrl}/mids`);                 // set up BACK url
        this.router.navigate([`${this.baseUrl}/mids/${mid}`]);
    }

    onTidRowSelected(tid: string) {
        this.location.replaceState(`${this.baseUrl}/tids`);                 // set up BACK url
        this.router.navigate([`${this.baseUrl}/tids/${tid}`]);
    }

    onUserRowSelected(userId: number) {
        this.location.replaceState(`${this.baseUrl}/users`);                // set up BACK url
        this.router.navigate([`${this.baseUrl}/users/${userId}`]);
    }

    get hasSolution(): boolean {
        return (this.parentOrganizationId === null);
    }

    get isMerchant(): boolean {
        return this.organization?.isMerchant;
    }

    onSubmit() {
        const address = this.organization.address || {};
        address.address1 = this.organizationForm.get("streetAddress1").value;
        address.address2 = this.organizationForm.get("streetAddress2").value;
        address.city = this.organizationForm.get("city").value;
        address.state = this.organizationForm.get("state").value;
        address.postalCode = this.organizationForm.get("zipCode").value;

        const contactEmail = this.organizationForm.get("contactEmail").value;
        const contactName = this.organizationForm.get("contactName").value;
        const contactPhone = this.organizationForm.get("contactNumber").value;
        const name = this.organizationForm.get("organizationName").value;
        const solution = this.organizationForm.get("solution").value;
        const isMerchant = this.organizationForm.get("isMerchant").value;
        const parentOrganizationId = this.parentOrganizationId;

        let makeMerchantLive = false;
        if (isMerchant) {
            const isMerchantLive = this.organizationForm.get("isMerchantLive").value;
            makeMerchantLive = !this.merchantAlreadyLive && isMerchantLive;
        }

        if (!address.address2) {
            delete address.address2;
        }

        if (this.isCreate) {
            const createOrganizationDto: CreateOrganizationDto = {
                address,
                contactEmail,
                contactName,
                contactPhone,
                name,
                isMerchant,
                parentOrganizationId
            };

            if (this.hasSolution) {
                createOrganizationDto.solution = solution;
            }

            if (makeMerchantLive) {
                createOrganizationDto.isMerchantLive = true;
            }

            this.createOrganization.emit(createOrganizationDto);
        }
        else {
            const updateOrganizationDto: UpdateOrganizationDto = {
                address,
                contactEmail,
                contactName,
                contactPhone,
                name
            };

            if (makeMerchantLive) {
                updateOrganizationDto.isMerchantLive = true;
            }

            this.updateOrganization.emit({
                id: this.organization.id,
                dto: updateOrganizationDto
            });
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

    onMidFilterChanged(filter: MidsFilter): void {
        this.showingDeletedMids = (filter.localShowDeleted === "Yes");
        this.midFilterChanged.emit({
            id: this.organization.id,
            filter
        });
    }

    onTidFilterChanged(filter: TidsFilter): void {
        this.showingDeletedTids = (filter.localShowDeleted === "Yes");
        this.tidFilterChanged.emit({
            id: this.organization.id,
            filter
        });
    }

    onMerchantHistorySortingChanged(sorting: TableSorting) {
        this.merchantHistorySortingChanged.emit({
            id: this.organization.id,
            sorting: {
                orderBy: sorting.orderBy as any,
                orderDirection: sorting.orderDirection
            }
        });
    }

    onMerchantHistoryFilterChanged(filter: MerchantHistoryFilter) {
        this.merchantHistoryFilterChanged.emit({
            id: this.organization.id,
            filter
        });
    }

    onMerchantHistoryPageChanged(page: number) {
        this.merchantHistoryPageChanged.emit({
            id: this.organization.id,
            page
        });
    }

    getOrganizationsTabLabel(): string {
        let count = 0;
        if (this.organizations?.length) {
             count = this.organizations?.length;
        }
        return this.appendCountIfNecessary("Organizations", count);
    }

    getMidsTabLabel(): string {
        let count = 0;
        if (this.mids?.length && !this.midsLoading) {
            count = this.mids?.length;
        }
        return this.appendCountIfNecessary("MIDs", count, this.showingDeletedMids);
    }

    getTidsTabLabel(): string {
        let count = 0;
        if (this.tids?.length) {
            count = this.tids?.length;
        }
        return this.appendCountIfNecessary("TIDs", count, this.showingDeletedTids);
    }

    getUsersTabLabel(): string {
        let count = 0;
        if (this.users?.length) {
             count = this.users?.length;
        }
        return this.appendCountIfNecessary("Users", count);
    }

    getMerchantHistoryTabLabel(): string {
        return "History";
    }

    onTabChanged($event) {
        const tabIndex = $event.index;
        let context;

        if (this.isMerchant) {
            switch (tabIndex) {
                case 0: {
                    context = "/mids";
                    break;
                }
                case 1: {
                    context = "/tids";
                    break;
                }
                case 2: {
                    context = "/users";
                    break;
                }
                case 3: {
                    context = "/history";
                    break;
                }
            }
        }
        else {
            switch (tabIndex) {
                case 0: {
                    context = "/organizations";
                    break;
                }
                case 1: {
                    context = "/users";
                    break;
                }
            }
        }

        if (context) {
            this.location.replaceState(`/dashboard/organizations/${this.organizationId}${context}`);
        }
    }

    private appendCountIfNecessary(title: string, count: number, deleted?: boolean): string {
        const deletedPart = deleted ? " deleted" : "";
        return title + ((count > 0) ? ` (${count}${deletedPart})` : "");
    }

    private seedTab(): void {

        const routeSnapshot: ActivatedRouteSnapshot = this.route.snapshot;
        const url: Array<UrlSegment> = routeSnapshot.url;
        const lastPartOfUrl: UrlSegment = url[url.length - 1];
        this.organizationId = +routeSnapshot.params.organizationId;

        if (this.isMerchant) {
            switch (lastPartOfUrl.path) {
                case "mids": {
                    this.loadContext = 0;
                    break;
                }
                case "tids": {
                    this.loadContext = 1;
                    break;
                }
                case "users": {
                    this.loadContext = 2;
                    break;
                }
                case "history": {
                    this.loadContext = 3;
                    break;
                }
                default: {
                    break;
                }
            }
        }
        else {
            switch (lastPartOfUrl.path) {
                case "organizations": {
                    this.loadContext = 0;
                    break;
                }
                case "users": {
                    this.loadContext = 1;
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    private validateFormControls(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup?.controls).forEach(field => {
            formGroup?.get(field)?.updateValueAndValidity();
        });
    }

}
