import { Action } from "@ngrx/store";

export enum ActionTypes {
    DownloadPayPalSftpFile = "[PAYPAL_SFTP_FILE] DownloadPayPalSftpFile",
    ReprocessPayPalSftpFile = "[PAYPAL_SFTP_FILE] ReprocessPayPalSftpFile"
}

export class DownloadPayPalSftpFileAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DownloadPayPalSftpFile;
}

export class ReprocessPayPalSftpFileAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.ReprocessPayPalSftpFile;
}
