import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@admin_app/storage/store";

import * as FeePlansActions from "@admin_app/storage/fee-plans/fee-plans.actions";
import * as FeePlansSelectors from "@admin_app/storage/fee-plans/fee-plans.selectors";

import * as OrganizationActions from "@admin_app/storage/organization/organization.actions";
import * as OrganizationSelectors from "@admin_app/storage/organization/organization.selectors";

import * as AccountsActions from "@admin_app/storage/accounts/accounts.actions";
import * as AccountsSelectors from "@admin_app/storage/accounts/accounts.selectors";

import * as MidsActions from "@admin_app/storage/mids/mids.actions";
import * as MidsSelectors from "@admin_app/storage/mids/mids.selectors";

import * as TidsActions from "@admin_app/storage/tids/tids.actions";
import * as TidsSelectors from "@admin_app/storage/tids/tids.selectors";

import * as MidActions from "@admin_app/storage/mid/mid.actions";
import * as MidSelectors from "@admin_app/storage/mid/mid.selectors";
import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { ShowInformationModalAction } from "@admin_app/storage/app/app.actions";

@Component({
    selector: "app-mid",
    templateUrl: "./mid.component.html",
    styleUrls: ["./mid.component.less"]
})
export class MidComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    feePlans$ = this.store.select(FeePlansSelectors.getFeePlans);
    feePlansLoading$ = this.store.select(FeePlansSelectors.getFeePlansLoading);

    merchant$ = this.store.select(OrganizationSelectors.getOrganizationInfo);
    merchantLoading$ = this.store.select(OrganizationSelectors.getOrganizationLoading);

    accounts$ = this.store.select(AccountsSelectors.getAccounts);
    accountsLoading$ = this.store.select(AccountsSelectors.getAccountsLoading);

    mid$ = this.store.select(MidSelectors.getMidInfo);
    midLoading$ = this.store.select(MidSelectors.getMidLoading);

    editMidError$ = this.store.select(MidSelectors.getEditMidError);

    serviceFeeMids$ = this.store.select(MidsSelectors.getServiceFeeMids);
    serviceFeeMidsLoading$ = this.store.select(MidsSelectors.getServiceFeeMidsLoading);

    associatedMids$ = this.store.select(MidsSelectors.getAssociatedMids);
    associatedMidsLoading$ = this.store.select(MidsSelectors.getAssociatedMidsLoading);
    associatedMidsError$ = this.store.select(MidsSelectors.getAssociatedMidsError);

    associatedTids$ = this.store.select(TidsSelectors.getAssociatedTids);
    associatedTidsLoading$ = this.store.select(TidsSelectors.getAssociatedTidsLoading);
    associatedTidsError$ = this.store.select(TidsSelectors.getAssociatedTidsError);

    isCreate: boolean;

    ngOnInit() {

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new FeePlansActions.GetFeePlansAction(1, 100));
        this.store.dispatch(new OrganizationActions.GetOrganizationAction(+this.route.snapshot.params.organizationId));

        this.store.dispatch(new AccountsActions.GetAccountsAction());

        this.store.dispatch(new MidsActions.ResetServiceFeeMidsAction());
        this.store.dispatch(new MidsActions.GetServiceFeeMidsAction(+this.route.snapshot.params.organizationId));

        this.store.dispatch(new MidActions.ResetEditMidErrorsAction());

        this.store.dispatch(new TidsActions.ResetAssociatedTidsAction());

        this.isCreate = !this.route.snapshot.params.mid;

        if (this.isCreate) {
            this.store.dispatch(new MidActions.GetNewMidAction());
        }
        else {
            this.store.dispatch(new MidActions.GetMidAction(this.route.snapshot.params.mid, this.route.snapshot.params.organizationId));
            this.store.dispatch(
                new TidsActions.GetAssociatedTidsAction(+this.route.snapshot.params.organizationId, +this.route.snapshot.params.mid)
            );
        }
    }

    updateMid(payload: any) {

        this.store.dispatch(new MidActions.UpdateMidAction(payload.id, payload.organizationId, payload.dto));
    }

    createMid(payload: any) {

        this.store.dispatch(new MidActions.CreateMidAction(payload.organizationId, payload.dto));
    }

    deleteMid(payload: any) {

        this.store.dispatch(new MidActions.DeleteMidAction(payload.id, payload.organizationId));
    }

    connectClover(payload: any) {

        if (payload.isCreate) {
            const title = "Action Unavailable";
            const message = "Please submit New MID first and then try again.";
            const buttonText = "OK";
            this.store.dispatch(new ShowInformationModalAction(title, message, buttonText));
        }
        else if (payload.formDirty) {
            const title = "Action Unavailable";
            const message = "Please submit or cancel form changes first and then try again.";
            const buttonText = "OK";
            this.store.dispatch(new ShowInformationModalAction(title, message, buttonText));
        }
        else {
            this.store.dispatch(new MidActions.ConnectCloverAction(payload.id, payload.organizationId, payload.redirectUri));
        }
    }

    onBack(): void {
        this.store.dispatch(GoBackAction());
    }

}
