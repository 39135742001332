import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "shared-lib";
import jwt_decode from "jwt-decode";
import { from } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate() {

        const token = this.authService.getAccessToken();
        const { role } = jwt_decode(token) as { role: string };

        return from(this.authService.validateToken()).pipe(
            map(() => {
                const admin = role == "backofficeadmin";

                if (!admin) {
                    this.router.navigateByUrl("/forbidden");
                }

                return admin;
            })
        );
    }
}
