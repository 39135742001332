import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getMidsState = (store: IStore) => store.mids;

export const getMids = createSelector(
    getMidsState,
    state => state.record
);

export const getMidsLoading = createSelector(
    getMidsState,
    state => state.loading
);

export const getMidsError = createSelector(
    getMidsState,
    state => state.error
);

export const getServiceFeeMids = createSelector(
    getMidsState,
    state => state.serviceFeeMidsRecord
);

export const getServiceFeeMidsLoading = createSelector(
    getMidsState,
    state => state.serviceFeeMidsLoading
);

export const getServiceFeeMidsError = createSelector(
    getMidsState,
    state => state.serviceFeeMidsError
);

export const getAssociatedMids = createSelector(
    getMidsState,
    state => state.associatedMidsRecord
);

export const getAssociatedMidsLoading = createSelector(
    getMidsState,
    state => state.associatedMidsLoading
);

export const getAssociatedMidsError = createSelector(
    getMidsState,
    state => state.associatedMidsError
);

export const getMidsFilter = createSelector(
    getMidsState,
    state => state.filter
);
