<div class="outer-container" (mousedown)="onMouseDown()">

    <div mat-dialog-title>
        <div>{{ data.dialogTitle }}</div>
        <i class="fal fa-times global-dialog-close-button" (mousedown)="onCancel($event)"></i>
    </div>

    <div mat-dialog-content class="content">

        <input
            type="file"
            class="hidden-file-input"
            (change)="onFileSelected($event)"
            [accept]="data.onlyFilesOfType"
            #fileUpload>

        <div class="select-message">
            <div>
                {{ data.dialogMessage }}
            </div>
            <lib-vitu-tooltip
                *ngIf="!!data.infoTip?.length"
                class="info-tip"
                [text]="data.infoTip">
                    <i class="far fa-info-circle"></i>            
            </lib-vitu-tooltip>
        </div>

        <form class="form" [formGroup]="form" [libVituForm]="form" (libVituFormSubmit)="onSubmit()">

            <div class="form-fields-container">

                <div #fileInputContainer class="file-input-container">

                    <lib-vitu-form-field-input
                        class="form-input"
                        id="fileField"
                        [formGroup]="form"
                        formFieldName="fileField"
                        [label]="data.fileLabel"
                        readonly>
                    </lib-vitu-form-field-input>

                    <div class="prevent-focus-on-input-field">
                    </div>

                    <div class="click-on-input-field"
                        (click)="onChooseFile(fileUpload)">
                    </div>

                    <button mat-icon-button matSuffix color="primary"
                        class="upload-button"
                        (click)="onChooseFile(fileUpload)">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>

                </div>

            </div>

            <lib-modal-error-message *ngIf="data?.errorMessageSubject | async as errorMessage" [message]="errorMessage">
            </lib-modal-error-message>
        
            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()"
                    [isModal]="true">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Upload"
                    [neverDisable]="true">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </form>
    </div>

</div>
