import { ActionTypes } from "./device.actions";
import { DeviceState } from "./device.state";

export const DeviceReducer = (
    state: DeviceState = new DeviceState(),
    action: any,
): DeviceState => {

    switch (action.type) {

        case ActionTypes.UpdateDeviceStatus: {

            const newState = {
                ...state,
                record: {...action.device},
                loading: true
            };

            return newState;
        }

        case ActionTypes.UpdateDeviceStatusSucceed: {

            const newState = {
                ...state,
                loading: false,
                error: null
            };

            if (newState.record.posConfig) {
                newState.record.posConfig.status = action.status;
            }

            return newState;
        }

        case ActionTypes.UpdateDeviceStatusFail: {

            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default:
            return state;
    }
};
