/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountMerchantProcessorDto } from '../models/account-merchant-processor-dto';
import { MerchantProcessorAccountData } from '../models/merchant-processor-account-data';
import { MerchantProcessorAccountDto } from '../models/merchant-processor-account-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation vituPayAccountsGetAccounts
   */
  static readonly VituPayAccountsGetAccountsPath = '/v1/admin/accounts/vitu-pay';

  /**
   * Get list of VituPay accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vituPayAccountsGetAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsGetAccounts$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MerchantProcessorAccountDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.VituPayAccountsGetAccountsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MerchantProcessorAccountDto>>;
      })
    );
  }

  /**
   * Get list of VituPay accounts.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vituPayAccountsGetAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsGetAccounts(params?: {
  }): Observable<Array<MerchantProcessorAccountDto>> {

    return this.vituPayAccountsGetAccounts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MerchantProcessorAccountDto>>) => r.body as Array<MerchantProcessorAccountDto>)
    );
  }

  /**
   * Path part for operation vituPayAccountsCreateAccount
   */
  static readonly VituPayAccountsCreateAccountPath = '/v1/admin/accounts/vitu-pay';

  /**
   * Create VituPay account.
   *
   * VituPay accounts can be used for service fee MIDs and not related to any merchant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vituPayAccountsCreateAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vituPayAccountsCreateAccount$Response(params: {
    body: MerchantProcessorAccountData
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.VituPayAccountsCreateAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create VituPay account.
   *
   * VituPay accounts can be used for service fee MIDs and not related to any merchant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vituPayAccountsCreateAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vituPayAccountsCreateAccount(params: {
    body: MerchantProcessorAccountData
  }): Observable<number> {

    return this.vituPayAccountsCreateAccount$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation vituPayAccountsGetAccount
   */
  static readonly VituPayAccountsGetAccountPath = '/v1/admin/accounts/vitu-pay/{id}';

  /**
   * Get VituPay account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vituPayAccountsGetAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsGetAccount$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<MerchantProcessorAccountDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.VituPayAccountsGetAccountPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MerchantProcessorAccountDto>;
      })
    );
  }

  /**
   * Get VituPay account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vituPayAccountsGetAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsGetAccount(params: {
    id: number;
  }): Observable<MerchantProcessorAccountDto> {

    return this.vituPayAccountsGetAccount$Response(params).pipe(
      map((r: StrictHttpResponse<MerchantProcessorAccountDto>) => r.body as MerchantProcessorAccountDto)
    );
  }

  /**
   * Path part for operation vituPayAccountsUpdateAccount
   */
  static readonly VituPayAccountsUpdateAccountPath = '/v1/admin/accounts/vitu-pay/{id}';

  /**
   * Update VituPay account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vituPayAccountsUpdateAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vituPayAccountsUpdateAccount$Response(params: {
    id: number;
    body: MerchantProcessorAccountData
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.VituPayAccountsUpdateAccountPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update VituPay account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vituPayAccountsUpdateAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vituPayAccountsUpdateAccount(params: {
    id: number;
    body: MerchantProcessorAccountData
  }): Observable<void> {

    return this.vituPayAccountsUpdateAccount$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation vituPayAccountsDeleteAccount
   */
  static readonly VituPayAccountsDeleteAccountPath = '/v1/admin/accounts/vitu-pay/{id}';

  /**
   * Delete VituPay account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vituPayAccountsDeleteAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsDeleteAccount$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.VituPayAccountsDeleteAccountPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete VituPay account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vituPayAccountsDeleteAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsDeleteAccount(params: {
    id: number;
  }): Observable<void> {

    return this.vituPayAccountsDeleteAccount$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation vituPayAccountsMids
   */
  static readonly VituPayAccountsMidsPath = '/v1/admin/accounts/vitu-pay/{id}/mids';

  /**
   * Get all MIDs associated to a VituPay account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vituPayAccountsMids()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsMids$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<AccountMerchantProcessorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.VituPayAccountsMidsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountMerchantProcessorDto>>;
      })
    );
  }

  /**
   * Get all MIDs associated to a VituPay account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `vituPayAccountsMids$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vituPayAccountsMids(params: {
    id: number;
  }): Observable<Array<AccountMerchantProcessorDto>> {

    return this.vituPayAccountsMids$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccountMerchantProcessorDto>>) => r.body as Array<AccountMerchantProcessorDto>)
    );
  }

}
