import { Action } from "@ngrx/store";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";

export enum ActionTypes {
    GetAccounts = "[ACCOUNTS] GetAccounts",
    GetAccountsSucceed = "[ACCOUNTS] GetAccountsSucceed",
    GetAccountsFail = "[ACCOUNTS] GetAccountsFail"
}

export class GetAccountsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetAccounts;
}

export class GetAccountsSucceedAction implements Action {

    constructor(public accounts: Array<MerchantProcessorAccountDto>) {}

    readonly type = ActionTypes.GetAccountsSucceed;
}

export class GetAccountsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetAccountsFail;
}
