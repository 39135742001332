import { Action } from "@ngrx/store";
import { Filter, Sorting } from "./organizations.state";
import { PagedDataDtoOfOrganizationDto } from "@admin_api/models/paged-data-dto-of-organization-dto";
import { OrganizationHierarchyModeEnum } from "@admin_api/models/organization-hierarchy-mode-enum";

export enum ActionTypes {
    ResetOrganizations = "[Organizations] ResetOrganizations",
    GetOrganizations = "[Organizations] GetOrganizations",
    GetOrganizationsSucceed = "[Organizations] GetOrganizationsSucceed",
    GetOrganizationsFail = "[Organizations] GetOrganizationsFail",
    SortOrganizations = "[Organizations] SortOrganizations",
    PageChangedOrganizations = "[Organizations] PageChangedOrganizations",
    FilterOrganizations = "[Organizations] FilterOrganizations"
}

export class ResetOrganizationsAction implements Action {

    constructor(
        public hierarchyOrganizationId: number,
        public hierarchyMode: OrganizationHierarchyModeEnum,
        public merchantsOnly: boolean) {}

    readonly type = ActionTypes.ResetOrganizations;
}

export class GetOrganizationsAction implements Action {

    constructor(
        public page: number,
        public pageSize?: number,
        public filter?: Filter,
        public sorting?: Sorting) {}

    readonly type = ActionTypes.GetOrganizations;
}

export class GetOrganizationsSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfOrganizationDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetOrganizationsSucceed;
}

export class GetOrganizationsFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetOrganizationsFail;
}

export class SortOrganizationsAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortOrganizations;
}

export class PageChangedOrganizationsAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedOrganizations;
}

export class FilterOrganizationsAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterOrganizations;
}
