import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { LocalTimePoint } from "shared-lib";
import { Filter } from "@admin_app/storage/dfm-files/dfm-files.state";
import { DfmFileStatusEnum } from "@admin_api/models/dfm-file-status-enum";

@Component({
    selector: "app-dfm-log-filters",
    templateUrl: "./dfm-log-filters.component.html",
    styleUrls: ["./dfm-log-filters.component.less"]
})
export class DfmLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;

    LocalTimePoint = LocalTimePoint;

    dfmFileStatusList: Array<DfmFileStatusEnum>;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.dfmFileStatusList = Object.values(DfmFileStatusEnum);
    }

    filterForm = this.fb.group({
        localDateInterval: [null],
        statuses: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

}
