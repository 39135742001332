import { Action } from "@ngrx/store";
import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";
import { Filter, Sorting } from "./inventory.state";
import { PagedDataDtoOfMerchantTerminalDto } from "@admin_api/models/paged-data-dto-of-merchant-terminal-dto";

export enum ActionTypes {
    GetInventory = "[Inventory] GetInventory",
    GetInventorySucceed = "[Inventory] GetInventorySucceed",
    GetInventoryFail = "[Inventory] GetInventoryFail",
    SortInventory = "[Inventory] SortInventory",
    FilterInventory = "[Inventory] FilterInventory",
    PageChangedInventory = "[Inventory] PageChangedInventory",
    UpdateInventoryDevice = "[Inventory] UpdateInventoryDevice"
}

export class GetInventoryAction implements Action {

    constructor(public page: number, public pageSize?: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetInventory;
}

export class GetInventorySucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfMerchantTerminalDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetInventorySucceed;
}

export class GetInventoryFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetInventoryFail;
}

export class SortInventoryAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortInventory;
}

export class FilterInventoryAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterInventory;
}

export class PageChangedInventoryAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedInventory;
}

export class UpdateInventoryDeviceAction implements Action {

    constructor(
      public device: MerchantTerminalDto
    ) { }

    readonly type = ActionTypes.UpdateInventoryDevice;
}
