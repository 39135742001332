import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { ActionTypes, GetAccountsAction, GetAccountsFailAction, GetAccountsSucceedAction } from "./accounts.actions";
import { of } from "rxjs";
import { AccountsService } from "@admin_api/services/accounts.service";

@Injectable()
export class AccountsEffects {

    constructor(
        private actions$: Actions,
        private accountsService: AccountsService
    ) {}

    initAccountsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => action.payload.routerState.url.startsWith("/dashboard/accounts")),
            map(() => new GetAccountsAction())
        )
    );

    getAccounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAccountsAction>(ActionTypes.GetAccounts),
            switchMap(() =>
                this.accountsService.vituPayAccountsGetAccounts().pipe(
                    switchMap((accounts) => of(new GetAccountsSucceedAction(accounts))),
                    catchError((error) => of(new GetAccountsFailAction(error)))
                )
            )
        ),
    );

}
