<div class="outer-container">

    <lib-title-bar
        [crumbs]="crumbs">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="true then formFields"></ng-container>
        <ng-container *ngIf="organizationLoading then spinner"></ng-container>

    </div>

    <div class="tabs-container">

        <mat-tab-group *ngIf="!isCreate && !organizationLoading && !isMerchant" class="tab-group" [selectedIndex]="loadContext" (selectedTabChange)="onTabChanged($event)">

            <mat-tab [label]="getOrganizationsTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="organizationColumnDefs"
                    [rowsData]="organizations"
                    [rowsDataLoading]="organizationsLoading"
                    [rowsDataError]="organizationsError"
                    rowsDataErrorMessage="Error Loading Organizations"
                    rowsDataEmptyMessage="No Organizations"
                    selectedRowProperty="id"
                    (rowSelected)="onOrganizationRowSelected($event)">
            
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-organizations-table-cell-content
                            [columnId]="columnId"
                            [element]="element"
                            [config]="config">
                        </app-organizations-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getUsersTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="userColumnDefs"
                    [rowsData]="users"
                    [rowsDataLoading]="organizationLoading || usersLoading"
                    [rowsDataError]="usersError"
                    rowsDataErrorMessage="Error Loading Users"
                    rowsDataEmptyMessage="No Users"
                    selectedRowProperty="id"
                    (rowSelected)="onUserRowSelected($event)">
        
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-users-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-users-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

        </mat-tab-group>

        <mat-tab-group *ngIf="!isCreate && !organizationLoading && isMerchant" class="tab-group" [selectedIndex]="loadContext" (selectedTabChange)="onTabChanged($event)">

            <mat-tab [label]="getMidsTabLabel()">

                <div class="table-filters">
                    <app-mid-filters
                        [filter]="midFilter"
                        (filterChanged)="onMidFilterChanged($event)">
                    </app-mid-filters>
                </div>

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="midColumnDefs"
                    [rowsData]="mids"
                    [rowsDataLoading]="organizationLoading || midsLoading"
                    [rowsDataError]="midsError"
                    rowsDataErrorMessage="Error Loading MIDs"
                    rowsDataEmptyMessage="No MIDs"
                    selectedRowProperty="id"
                    (rowSelected)="onMidRowSelected($event)"
                    [rowsDataShowWhileLoading]="true">
        
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-mids-table-cell-content
                            [columnId]="columnId"
                            [element]="element"
                            [showingDeletedMids]="showingDeletedMids">
                        </app-mids-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getTidsTabLabel()">

                <div class="table-filters">
                    <app-tid-filters
                        [filter]="tidFilter"
                        (filterChanged)="onTidFilterChanged($event)">
                    </app-tid-filters>
                </div>

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="tidColumnDefs"
                    [rowsData]="tids"
                    [rowsDataLoading]="organizationLoading || tidsLoading"
                    [rowsDataError]="tidsError"
                    rowsDataErrorMessage="Error Loading TIDs"
                    rowsDataEmptyMessage="No TIDs"
                    selectedRowProperty="id"
                    (rowSelected)="onTidRowSelected($event)"
                    [rowsDataShowWhileLoading]="true">
        
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-tids-table-cell-content
                            [columnId]="columnId"
                            [element]="element"
                            [showingDeletedTids]="showingDeletedTids">
                        </app-tids-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getUsersTabLabel()">

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="userColumnDefs"
                    [rowsData]="users"
                    [rowsDataLoading]="organizationLoading || usersLoading"
                    [rowsDataError]="usersError"
                    rowsDataErrorMessage="Error Loading Users"
                    rowsDataEmptyMessage="No Users"
                    selectedRowProperty="id"
                    (rowSelected)="onUserRowSelected($event)"
                    [rowsDataShowWhileLoading]="true">
        
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-users-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-users-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

            <mat-tab [label]="getMerchantHistoryTabLabel()">

                <div class="table-filters">
                    <app-merchant-history-filters
                        [filter]="merchantHistoryFilter"
                        (filterChanged)="onMerchantHistoryFilterChanged($event)">
                    </app-merchant-history-filters>
                </div>

                <lib-table-base
                    class="tab-container"
                    [columnDefs]="merchantHistoryColumnDefs"
                    [rowsData]="merchantHistory"
                    [rowsDataLoading]="organizationLoading || merchantHistoryLoading"
                    [rowsDataError]="merchantHistoryError"
                    rowsDataErrorMessage="Error Loading History"
                    rowsDataEmptyMessage="No History"
                    selectedRowProperty="id"
                    [sorting]="merchantHistorySorting"
                    (sortingChanged)="onMerchantHistorySortingChanged($event)"
                    [pager]="merchantHistoryPager"
                    (pageChanged)="onMerchantHistoryPageChanged($event)"
                    [rowsDataShowWhileLoading]="true">
        
                    <ng-template let-element="element" let-columnId="columnId">
                        <app-merchant-history-table-cell-content
                            [columnId]="columnId"
                            [element]="element">
                        </app-merchant-history-table-cell-content>
                    </ng-template>
                </lib-table-base>

            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<ng-template #formFields>

    <form [formGroup]="organizationForm" class="form" [libVituForm]="organizationForm" (libVituFormSubmit)="onSubmit()">

        <div class="field-lines">

            <lib-vitu-form-field-input
                [ngClass]="{'vitu-hide-form-field': isCreate}"
                class="field"
                id="organizationRef"
                [formGroup]="organizationForm"
                formFieldName="organizationRef"
                label="Organization Ref"
                [disabled]="true">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="organizationName"
                [formGroup]="organizationForm"
                formFieldName="organizationName"
                label="Organization Name">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                class="field"
                [ngClass]="{'hide-item': !hasSolution}"
                id="solution"
                [formGroup]="organizationForm"
                formFieldName="solution"
                label="Solution"
                [options]="solutions"
                [optionLabelFn]="getDisplayTextForSolution.bind(this)"
                [disabled]="!isCreate || !hasSolution">
            </lib-vitu-form-field-select>

            <lib-vitu-form-field-switch
                formControlName="isMerchant"
                label="Is Merchant"
                [disableControl]="!isCreate">
            </lib-vitu-form-field-switch>

            <lib-vitu-form-field-switch
                *ngIf="!organizationLoading && organizationIsMerchant && !merchantAlreadyLive"
                formControlName="isMerchantLive"
                label="Is Merchant Live"
                [disableControl]="merchantAlreadyLive">
            </lib-vitu-form-field-switch>

            <lib-vitu-input
                *ngIf="!organizationLoading && organizationIsMerchant && merchantAlreadyLive"
                [label]="'Merchant Live Date (' + LocalTimePoint.formatZ() + ')'"
                [value]="merchantLiveDate | date: 'MM/dd/yyyy&nbsp;h:mm:ss&nbsp;a' : LocalTimePoint.formatZ()"
                [readonly]="true">
            </lib-vitu-input>

            <lib-vitu-form-field-input
                class="field"
                id="streetAddress1"
                [formGroup]="organizationForm"
                formFieldName="streetAddress1"
                label="Street Address 1">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="streetAddress2"
                [formGroup]="organizationForm"
                formFieldName="streetAddress2"
                label="Street Address 2">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="city"
                [formGroup]="organizationForm"
                formFieldName="city"
                label="City">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                class="field"
                id="state"
                [formGroup]="organizationForm"
                formFieldName="state"
                label="State"
                [options]="states">
            </lib-vitu-form-field-select>

            <lib-vitu-form-field-input
                class="field"
                id="zipCode"
                [formGroup]="organizationForm"
                formFieldName="zipCode"
                label="ZIP Code"
                type="zip">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="contactName"
                [formGroup]="organizationForm"
                formFieldName="contactName"
                label="Contact Name">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="contactNumber"
                [formGroup]="organizationForm"
                formFieldName="contactNumber"
                label="Contact Number"
                type="telephone">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                class="field"
                id="contactEmail"
                [formGroup]="organizationForm"
                formFieldName="contactEmail"
                label="Contact Email"
                type="email">
            </lib-vitu-form-field-input>

        </div>

        <div *ngIf="!isCreate" class="actions-line">

            <div class="action-buttons">

                <lib-action-button
                    id="deleteOrganizationButton"
                    class="action-button"
                    label="Delete Organization"
                    fontImage="far fa-trash fa-bg"
                    [kind]="ActionButtonKind.ALERT"
                    [disabled]="organizationLoading || !organization"
                    (click)="onClickDelete()">
                </lib-action-button>

                <lib-action-button
                    *ngIf="!organizationLoading && !isMerchant"
                    id="createOrganizationButton"
                    class="action-button"
                    label="Create Organization"
                    [kind]="ActionButtonKind.SECONDARY"
                    fontImage="far fa-plus-circle fa-bg"
                    (click)="onClickCreateOrganization()">
                </lib-action-button>

                <lib-action-button
                    *ngIf="!organizationLoading && isMerchant"
                    id="createMidButton"
                    class="action-button"
                    label="Create MID"
                    [kind]="ActionButtonKind.SECONDARY"
                    fontImage="far fa-plus-circle fa-bg"
                    (click)="onClickCreateMid()">
                </lib-action-button>
    
                <lib-action-button
                    *ngIf="!organizationLoading && isMerchant"
                    id="createTidButton"
                    class="action-button"
                    label="Create TID"
                    [kind]="ActionButtonKind.SECONDARY"
                    fontImage="far fa-plus-circle fa-bg"
                    (click)="onClickCreateTid()">
                </lib-action-button>

                <!--
                    AC_todo :
                      'Create User not currently available for organizations.
                      (When it is we can remove the conditional inclusion).
                      See VPAY-3222 (& VPAY-2204).
                -->
                <lib-action-button
                    *ngIf="isMerchant"
                    id="createUserButton"
                    class="action-button"
                    label="Create User"
                    [kind]="ActionButtonKind.SECONDARY"
                    fontImage="far fa-plus-circle fa-bg"
                    (click)="onClickCreateUser()">
                </lib-action-button>

            </div>

        </div>

        <lib-vitu-form-buttons class="form-buttons-line">

            <lib-vitu-form-abort-button
                (onAbort)="onCancel()">
            </lib-vitu-form-abort-button>

            <lib-vitu-form-submit-button
                [label]="submitButtonText"
                [neverDisable]="isCreate">
            </lib-vitu-form-submit-button>

        </lib-vitu-form-buttons>

    </form>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>
