import { Component, OnInit } from "@angular/core";
import { IStore } from "@admin_app/storage/store";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Pager } from "shared-lib";

import {
    PageChangedPaymentsAction,
    ExportPaymentsToCsvAction,
    FilterPaymentsAction,
    SortPaymentsAction,
    BulkUpdateAction
} from "@admin_app/storage/payments/payments.actions";

import { Sorting, Filter } from "@admin_app/storage/payments/payments.state";
import { PaymentDto } from "@admin_api/models/payment-dto";
import { getPaymentsState } from "@admin_app/storage/payments/payments.selectors";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";

import { PagerLargeSinglePageSize } from "@admin_app/storage/common";

type RowsData = PaymentDto[];

@Component({
    selector: "app-payments",
    templateUrl: "./payments.component.html",
    styleUrls: ["./payments.component.less"],
})
export class PaymentsComponent implements OnInit {

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    get rowsData$(): Observable<RowsData> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.rows));
    }

    get sorting$(): Observable<Sorting> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.sorting));
    }

    get pager$(): Observable<Pager> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.pager));
    }

    get loading$(): Observable<boolean> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.loading));
    }

    get error$(): Observable<Error> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.error));
    }

    get filter$(): Observable<Filter> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.filter));
    }

    get exportLoading$(): Observable<boolean> {
        return this.store.pipe(select(getPaymentsState), map((i) => i.exportLoading));
    }

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FilterPaymentsAction(filter));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new SortPaymentsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new PageChangedPaymentsAction(page));
    }

    exportToCsv() {
        this.store.dispatch(new ExportPaymentsToCsvAction());
    }

    onBulkUpdate() {
        this.store.dispatch(new BulkUpdateAction());
    }
}
