import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionButtonKind, LocalTimePoint } from "shared-lib";
import { DisputeDetailsDto } from "@admin_api/models/dispute-details-dto";

@Component({
    selector: "app-dispute-details",
    templateUrl: "./dispute-details.component.html",
    styleUrls: ["./dispute-details.component.less"],
})
export class DisputeDetailsComponent {

    @Input() dispute: DisputeDetailsDto;
    @Input() disputeLoading: boolean;

    @Output() cancel = new EventEmitter<void>();

    pageTitle = "View Dispute";

    ActionButtonKind = ActionButtonKind;
    LocalTimePoint = LocalTimePoint;

    onCancel(): void {
        this.cancel.emit();
    }

}
