import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { GoBackAction, PageLoadFailAction } from "../router/router.actions";

import { ConfirmationModalComponent, ConfirmationModalData,
    GlobalSpinnerService, VituCommonErrorEntityIdMustBeNumber, VituToastService, VituToastTone } from "shared-lib";
import { BillingService } from "@admin_api/services/billing.service";

import {
    ActionTypes,
    CreateFeePlanAction,
    DeleteFeePlanAction,
    GetFeePlanAction,
    GetFeePlanFailAction,
    GetFeePlanMerchantProcessorsAction,
    GetFeePlanMerchantProcessorsFailAction,
    GetFeePlanMerchantProcessorsSucceedAction,
    GetFeePlanSucceedAction,
    GetNewFeePlanAction,
    UpdateFeePlanAction
} from "./fee-plan.actions";
import { GenericNoOpAction } from "../generic.actions";

@Injectable()
export class FeePlanEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private toast: VituToastService
    ) {}

    getFeePlan$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetFeePlanAction>(ActionTypes.GetFeePlan),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetFeePlanFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.billingService.feePlansGet({ id: action.id }).pipe(
                    switchMap(feePlan => of(new GetFeePlanSucceedAction(feePlan))),
                    catchError((error) => of(new GetFeePlanFailAction(error)))
                );
            })
        ),
    );

    getNewFeePlan$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetNewFeePlanAction>(ActionTypes.GetNewFeePlan),
            switchMap(() => {
                const newFeePlan: any = {};
                return of(new GetFeePlanSucceedAction(newFeePlan, true));
            })
        ),
    );

    getFeePlanFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFeePlanFailAction>(ActionTypes.GetFeePlanFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateFeePlan$ = createEffect(() => this.actions$.pipe(
            ofType<UpdateFeePlanAction>(ActionTypes.UpdateFeePlan),
            switchMap(({ id, feePlan }) =>
                this.globalSpinner.apply(
                    this.billingService.feePlansPut({ id, body: feePlan }).pipe(
                    switchMap(() => of(GoBackAction()))
                ))
            )
        ));

    deleteFeePlan$ = createEffect(() => this.actions$.pipe(
            ofType<DeleteFeePlanAction>(ActionTypes.DeleteFeePlan),
            switchMap(({ id }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete Fee Plan",
                        subtitle: "Are you sure you want to permanently delete this fee plan?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ id, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ id }) =>
                this.globalSpinner.apply(
                    this.billingService.feePlansDelete({ id }).pipe(
                        switchMap(() => {
                            this.toast.open(`Fee Plan ${id} deleted.`, VituToastTone.Positive);
                            return of(GoBackAction());
                        }),
                        catchError((error) => {
                            this.toast.open(
                                `Fee Plan ${id} deletion failed. ${error instanceof Error ? error.message : error}`,
                                VituToastTone.Negative);
                            return of(new GenericNoOpAction());
                        })
                ))
            )
        ));

    createFeePlan$ = createEffect(() => this.actions$.pipe(
            ofType<CreateFeePlanAction>(ActionTypes.CreateFeePlan),
            switchMap(({ feePlan, feeTypeValues }) =>
                this.globalSpinner.apply(
                    this.billingService.feePlansPost({ body: feePlan }).pipe(
                        switchMap((feePlanId: number) => this.billingService.feePlansBindFeeTypes(
                            {
                                id: feePlanId,
                                body: { feeTypes: feeTypeValues }
                            })
                            .pipe(
                                switchMap(() => of(GoBackAction())
                            ))
                        )
                    )
                )
            )
        ));

    getFeePlanMerchantProcessors$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetFeePlanMerchantProcessorsAction>(ActionTypes.GetFeePlanMerchantProcessors),
            switchMap(action =>
                this.billingService.feePlansMerchantProcessors({ id: action.id }).pipe(
                    switchMap(merchantProcessors => of(new GetFeePlanMerchantProcessorsSucceedAction(merchantProcessors))),
                    catchError((error) => of(new GetFeePlanMerchantProcessorsFailAction(error)))
                )
            )
        ),
    );

}
