import { Action } from "@ngrx/store";

export enum ActionTypes {
    DownloadDfmFile = "[DFM_FILE] DownloadDfmFile",
    ReprocessDfmFile = "[DFM_FILE] ReprocessDfmFile"
}

export class DownloadDfmFileAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DownloadDfmFile;
}

export class ReprocessDfmFileAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.ReprocessDfmFile;
}
