import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, filter as filterOperator, withLatestFrom } from "rxjs/operators";
import { GooglePaymentProcessingCertificateService } from "@admin_api/services/google-payment-processing-certificate.service";
import { ActionTypes, CreateNewKeyAction, GetPublicKeyHashAction,
    GetPublicKeyFileAction,
    MakeKeyPrimaryAction,
    RegisterKeyAction,
    RevokePaymentProcessingCertificateKeyAction } from "./google-pay-key.actions";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationModalComponent, ConfirmationModalData, GlobalSpinnerService } from "shared-lib";
import { GetPaymentProcessingCertificatesAction } from "../google-pay-keys/google-pay-keys.actions";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { getGooglePayKeys } from "../google-pay-keys/google-pay-keys.selectors";
import { DigitalWalletCertificateStatusEnum } from "@admin_api/models/digital-wallet-certificate-status-enum";
import { CommonUtils } from "../common";

@Injectable()
export class GooglePayKeyEffects {

    constructor(
        private actions: Actions,
        private googlePayService: GooglePaymentProcessingCertificateService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private store: Store<IStore>
    ) {}

    getPublicKeyFile = createEffect(() =>
        this.actions.pipe(
            ofType<GetPublicKeyFileAction>(ActionTypes.GetPublicKeyFile),
            switchMap(({ token }) =>
                this.globalSpinner.apply(this.googlePayService.googlePaymentProcessingCertificateGetPublicKey$Response({ token })
                    .pipe(
                        map((response: any) => {
                            CommonUtils.downloadFile(response);
                            return true;
                        })
                    )
                )
            )
        ),
    { dispatch: false });

    getPublicKeyHash = createEffect(() => this.actions.pipe(
        ofType<GetPublicKeyHashAction>(ActionTypes.GetPublicKeyHash),
        switchMap(({ token }) =>
            this.globalSpinner.apply(
                this.googlePayService.googlePaymentProcessingCertificateGetPublicKeyHash({ token })
            ).pipe(
                switchMap((hash) =>
                    this.dialog.open(ConfirmationModalComponent, {
                        width: "700px",
                        data: {
                            title: "Public Key File Hash",
                            subtitle: hash,
                            confirmButtonText: "OK",
                            noCancel: true,
                            copySubtitle: true
                        } as ConfirmationModalData
                    }).afterClosed()
                )
            )
        )
    ));

    revokePaymentProcessingCertificateKey = createEffect(() => this.actions.pipe(
        ofType<RevokePaymentProcessingCertificateKeyAction>(ActionTypes.RevokePaymentProcessingCertificateKey),
        switchMap(({ token }) => this.dialog.open(ConfirmationModalComponent, {
            width: "700px",
            data: {
                title: "Revoke Certificate",
                subtitle: "Are you sure you want to revoke this certificate?",
                confirmButtonText: "Revoke"
            } as ConfirmationModalData
        }).afterClosed().pipe(
            map((confirmed: boolean) => ({ token, confirmed })),
        )),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(({ token }) =>
            this.globalSpinner.apply(
                this.googlePayService.googlePaymentProcessingCertificateRevoke({ token }).pipe(
                    switchMap(() =>
                        of(new GetPaymentProcessingCertificatesAction()))
                )
            )
        )
    ));

    registerKey = createEffect(() => this.actions.pipe(
        ofType<RegisterKeyAction>(ActionTypes.RegisterKey),
        withLatestFrom(this.store.select(getGooglePayKeys)),
        switchMap(([{ token }, googlePayKeys]) => {
            const numberOfRegisteredKeys =
                googlePayKeys.versions.reduce(
                    (current, key) => current += (key.status === DigitalWalletCertificateStatusEnum.Registered) ? 1 : 0, 0);
            if (numberOfRegisteredKeys < 2) {
                return this.dialog.open(ConfirmationModalComponent, {
                    width: "700px",
                    data: {
                        title: "Register Certificate",
                        subtitle: "Are you sure you want to register this certificate?",
                        confirmButtonText: "Register"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((confirmed: boolean) => ({ token, confirmed })),
                );
            }
            else {
                return this.dialog.open(ConfirmationModalComponent, {
                    width: "700px",
                    data: {
                        title: "Registration Error",
                        subtitle: "Two registered certificates already exist.",
                        subtitle2: "Please revoke one of these certificates to continue.",
                        confirmButtonText: "OK",
                        noCancel: true
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((confirmed: boolean) => ({ token, confirmed: false })),
                );
            }
        }),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(({ token }) =>
            this.globalSpinner.apply(
                this.googlePayService.googlePaymentProcessingCertificateSetAsRegistered({ token }).pipe(
                    switchMap(response =>
                        of(new GetPaymentProcessingCertificatesAction()))
                )
            )
        )
    ));

    makeKeyPrimary = createEffect(() => this.actions.pipe(
        ofType<MakeKeyPrimaryAction>(ActionTypes.MakeKeyPrimary),
        switchMap(({ token }) => this.dialog.open(ConfirmationModalComponent, {
            width: "700px",
            data: {
                title: "Make Certificate Primary",
                subtitle: "Are you sure you want to make this the primary certificate?",
                confirmButtonText: "OK"
            } as ConfirmationModalData
        }).afterClosed().pipe(
            map((confirmed: boolean) => ({ token, confirmed })),
        )),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(({ token }) =>
            this.globalSpinner.apply(
                this.googlePayService.googlePaymentProcessingCertificateSetOrderAsPrimary({ token }).pipe(
                    switchMap(() =>
                        of(new GetPaymentProcessingCertificatesAction()))
                )
            )
        )
    ));

    createNewKey$ = createEffect(() => this.actions.pipe(
        ofType<CreateNewKeyAction>(ActionTypes.CreateNewKey),
        withLatestFrom(this.store.select(getGooglePayKeys)),
        switchMap(([{}, googlePayKeys]) => {
            const numberOfRegisteredKeys =
                googlePayKeys.versions.reduce(
                    (current, key) => current +=
                        ((key.status === DigitalWalletCertificateStatusEnum.Registered) ||
                         (key.status === DigitalWalletCertificateStatusEnum.New)) ? 1 : 0, 0);
            if (numberOfRegisteredKeys < 2) {
                return of({ confirmed: true });
            }
            else {
                return this.dialog.open(ConfirmationModalComponent, {
                    width: "700px",
                    data: {
                        title: "Action Unavailable",
                        // eslint-disable-next-line max-len
                        subtitle: "Can only create a new Payment Processing certificate when there are less than two registered or new Payment Processing certificates.",
                        subtitle2: "Please revoke an existing Payment Processing certificate and try again.",
                        confirmButtonText: "OK",
                        noCancel: true
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map(() => ({ confirmed: false })),
                );
            }
        }),
        filterOperator(({ confirmed }) => confirmed),
        switchMap(() =>
            this.globalSpinner.apply(
                this.googlePayService.googlePaymentProcessingCertificateCreate().pipe(
                    switchMap(() => of(new GetPaymentProcessingCertificatesAction()))
                )
            )
        )
    ));

}
