import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { ValueFormatEnum } from "@admin_api/models/value-format-enum";

@Component({
    selector: "app-fee-plan-fee-types-table-cell-content",
    templateUrl: "./fee-plan-fee-types-table-cell-content.component.html",
    styleUrls: ["./fee-plan-fee-types-table-cell-content.component.less"],
})
export class FeePlanFeeTypesTableCellContentComponent implements OnInit, OnChanges {

    @Input() columnId: string;
    @Input() element: any;
    @Input() isCreate: boolean;
    @Input() customFeeTypeValueMap = {};
    @Input() validateCustomFeeTypeInput: (touched: boolean, element: any, customFeeTypeValueMap: any) => boolean;
    @Input() tableRowIndex: number;

    inputTouched = false;

    ValueFormatEnum = ValueFormatEnum;

    ngOnInit() {
        if (this.isCreate) {
            this.element.isAbsorbed = false;
        }
    }

    ngOnChanges() {
        // Keep index up to date (eg. if table sorting changes)
        this.element.tableRowIndex = this.tableRowIndex;
    }

    isCustomValueValid(): boolean {
        return this.validateCustomFeeTypeInput(this.inputTouched, this.element, this.customFeeTypeValueMap);
    }

    get sortingKeyForIsAbsorbed(): number {
        return this.element.isAbsorbed ? 1 : 0;
    }

    get sortingKeyForValue(): number {
        // Weight items accordingly to define where they appear in sorting...

        const valueNum = this.element.valueNum ?? 0;

        if (this.isCreate && this.element.isEditable) {
            // Just ensure any input elements are first (although not ordered by value within themselves as user can change them)
            return 0;
        }
        else if ((this.element.valueFormat === ValueFormatEnum.Percentage) ||
            ((this.element.valueFormat === ValueFormatEnum.NA) && (!valueNum))) {
            return (valueNum + 1);
        }
        else if (this.element.valueFormat === ValueFormatEnum.Dollar) {
            return (valueNum + 10000);
        }
        else if (this.element.valueFormat === ValueFormatEnum.NA) {
            return (valueNum + 100000000);
        }
        return 0;
    }

}
