import { DigitalWalletKeyVersionDto } from "@admin_api/models/digital-wallet-key-version-dto";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetPaymentProcessingCertificates = "[APPLE_PAY_KEYS] GetPaymentProcessingCertificates",
    GetPaymentProcessingCertificatesSucceed = "[APPLE_PAY_KEYS] GetPaymentProcessingCertificatesSucceed",
    GetPaymentProcessingCertificatesFail = "[APPLE_PAY_KEYS] GetPaymentProcessingCertificatesFail",
    GetMerchantIdentityKeys = "[APPLE_PAY_KEYS] GetMerchantIdentityKeys",
    GetMerchantIdentityKeysSucceed = "[APPLE_PAY_KEYS] GetMerchantIdentityKeysSucceed",
    GetMerchantIdentityKeysFail = "[APPLE_PAY_KEYS] GetMerchantIdentityKeysFail"
}

export class GetPaymentProcessingCertificatesAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetPaymentProcessingCertificates;
  }

export class GetPaymentProcessingCertificatesSucceedAction implements Action {

    constructor(public digitalWalletKeyVersionDtos: Array<DigitalWalletKeyVersionDto>) {}

    readonly type = ActionTypes.GetPaymentProcessingCertificatesSucceed;
}

export class GetPaymentProcessingCertificatesFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetPaymentProcessingCertificatesFail;
}

export class GetMerchantIdentityKeysAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetMerchantIdentityKeys;
  }

export class GetMerchantIdentityKeysSucceedAction implements Action {

    constructor(public digitalWalletKeyVersionDtos: Array<DigitalWalletKeyVersionDto>) {}

    readonly type = ActionTypes.GetMerchantIdentityKeysSucceed;
}

export class GetMerchantIdentityKeysFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetMerchantIdentityKeysFail;
}
