import { Action } from "@ngrx/store";
import { PagedDataDtoOfMerchantChangeDto } from "@admin_api/models/paged-data-dto-of-merchant-change-dto";
import { Filter, Sorting } from "./merchant-history.state";

export enum ActionTypes {
    ResetMerchantHistory = "[MERCHANT_HISTORY] ResetMerchantHistory",
    GetMerchantHistory = "[MERCHANT_HISTORY] GetMerchantHistory",
    GetMerchantHistorySucceed = "[MERCHANT_HISTORY] GetMerchantHistorySucceed",
    GetMerchantHistoryFail = "[MERCHANT_HISTORY] GetMerchantHistoryFail",
    SortMerchantHistory = "[MERCHANT_HISTORY] SortMerchantHistory",
    FilterMerchantHistory = "[MERCHANT_HISTORY] FilterMerchantHistory",
    PageChangedMerchantHistory = "[MERCHANT_HISTORY] PageChangedMerchantHistory"
}

export class ResetMerchantHistoryAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetMerchantHistory;
}

export class GetMerchantHistoryAction implements Action {

    constructor(
        public id: number,
        public page = 1,
        public pageSize?: number,
        public filter?: Filter,
        public sorting?: Sorting) {}

    readonly type = ActionTypes.GetMerchantHistory;
}

export class GetMerchantHistorySucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfMerchantChangeDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetMerchantHistorySucceed;
}

export class GetMerchantHistoryFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetMerchantHistoryFail;
}

export class SortMerchantHistoryAction implements Action {

    constructor(public id: number, public sorting: Sorting) {}

    readonly type = ActionTypes.SortMerchantHistory;
}

export class FilterMerchantHistoryAction implements Action {

    constructor(public id: number, public filter: Filter) {}

    readonly type = ActionTypes.FilterMerchantHistory;
}

export class PageChangedMerchantHistoryAction implements Action {

    constructor(public id: number, public page: number) {}

    readonly type = ActionTypes.PageChangedMerchantHistory;
}
