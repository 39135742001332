import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DisputeTypeEnum } from "@admin_api/models/dispute-type-enum";
import { PaymentOrderBy } from "@admin_api/models/payment-order-by";
import { DateTimeFormatUtils } from "shared-lib";
import { DigitalWalletEnum } from "@admin_api/models/digital-wallet-enum";

@Component({
    selector: "app-group-transactions-table-cell-content",
    templateUrl: "./group-transactions-table-cell-content.component.html",
    styleUrls: ["./group-transactions-table-cell-content.component.less"],
})
export class GroupTransactionsTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;

    PaymentOrderBy = PaymentOrderBy;
    DateTimeFormatUtils = DateTimeFormatUtils;
    DisputeTypeEnum = DisputeTypeEnum;
    DigitalWalletEnum = DigitalWalletEnum;

    constructor(private router: Router) {}

    getDisplayTextForPayerName() {
        let displayText;
        if (this.element.payer?.fullName) {
            displayText = `${this.element.payer.fullName}`.toUpperCase();
        }
        return displayText;
    }

    getDisplayTextForCardNumber() {
        let displayText;
        if ((this.element.card?.first6) && (this.element.card?.last4)) {
            displayText = `${this.element.card.first6}XXX${this.element.card?.last4}`;
        }
        return displayText;
    }

    onMouseDownDisputeType(event: MouseEvent, disputeType: DisputeTypeEnum) {
        let suffix;
        if (disputeType === DisputeTypeEnum.Retrieval) {
            suffix = "retrievals";
        }
        if (disputeType === DisputeTypeEnum.Chargeback) {
            suffix = "chargebacks";
        }
        if (suffix) {
            event.stopPropagation();
            const url = `/dashboard/transactions/${this.element.recentTransaction}/${suffix}`;
            this.router.navigate([url]);
        }
    }

}
