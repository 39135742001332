<app-apple-pay-detail
    [applePayKeys]="applePayKeys$ | async"
    [applePayKeysLoading]="applePayKeysLoading$ | async"
    [applePayKeysError]="applePayKeysError$ | async"
    (uploadPaymentProcessingCertificate)="onUploadPaymentProcessingCertificate()"
    (getPaymentProcessingCsr)="onGetPaymentProcessingCsr()"
    (revokePaymentProcessingCertificate)="onRevokePaymentProcessingCertificate($event)"
    [applePayMerchantIdentityKeys]="applePayMerchantIdentityKeys$ | async"
    [applePayMerchantIdentityKeysLoading]="applePayMerchantIdentityKeysLoading$ | async"
    [applePayMerchantIdentityKeysError]="applePayMerchantIdentityKeysError$ | async"
    (uploadMerchantIdentityCertificate)="onUploadMerchantIdentityCertificate()"
    (getMerchantIdentityCsr)="onGetMerchantIdentityCsr()"
    (revokeMerchantIdentity)="onRevokeMerchantIdentity($event)">
</app-apple-pay-detail>
