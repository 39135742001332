<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'mid'">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'dba'">
        <lib-text-table-column>
            {{ element.dba }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'processor'">
        <lib-text-table-column>
            {{ element.processor }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'isServiceFee'">
        <lib-binary-status-icon
            [value]="element?.isServiceFee"
            [showPositiveOnly]="true">
        </lib-binary-status-icon>
        <lib-table-column-sort [key]="element.isServiceFee ? 1 : 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'deletedUtc'">
        <ng-container *ngIf="showingDeletedMids">
            <lib-date-time-label [dateUtc]="element?.deletedUtc">
            </lib-date-time-label>
            <lib-table-column-sort [key]="sortingKeyForDateDeleted"></lib-table-column-sort>
        </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
