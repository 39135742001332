<div class="outer-container">

    <lib-title-bar
        [crumbs]="crumbs">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="true then formFields"></ng-container>
        <ng-container *ngIf="adjustmentLoading then spinner"></ng-container>

    </div>

</div>

<ng-template #formFields>

    <form [formGroup]="adjustmentForm" class="form" [libVituForm]="adjustmentForm" (libVituFormSubmit)="onSubmit()">

        <div *ngIf="isCreate" class="create">

            <div class="create-inner-container">

                <div id="merchant">
                    <lib-vitu-form-field-select
                        [formGroup]="adjustmentForm"
                        formFieldName="merchant"
                        label="Merchant"
                        [options]="merchants"
                        optionLabelKey="name"
                        optionValueKey="id"
                        (selectionChanged)="onMerchantChanged()">
                    </lib-vitu-form-field-select>
                </div>

                <div id="mid">
                    <lib-vitu-form-field-select
                        [formGroup]="adjustmentForm"
                        formFieldName="mid"
                        label="MID"
                        [labelEmpty]="emptyMidLabel"
                        [labelEmptyCondition]="noMids"
                        [options]="mids"
                        optionLabelKey="mid"
                        optionValueKey="id">
                    </lib-vitu-form-field-select>
                </div>

                <div id="adjustmentDate">
                    <lib-vitu-form-field-date
                        [formGroup]="adjustmentForm"
                        formFieldName="adjustmentDate"
                        label="Adjustment Date"
                        singleDateMode
                        restrictPast
                        readonly
                        [infoTip]="createAdjustmentDateInfoTip">
                    </lib-vitu-form-field-date>
                </div>

                <div id="amount">
                    <lib-vitu-form-field-input
                        [formGroup]="adjustmentForm"
                        formFieldName="amount"
                        label="Amount"
                        type="twoDigitDecimalAllowNegative"
                        prefix="$">
                    </lib-vitu-form-field-input>
                </div>

                <div id="reason">
                    <lib-vitu-form-field-select
                        [formGroup]="adjustmentForm"
                        formFieldName="reason"
                        label="Reason"
                        [options]="reasons"
                        [spaceBetweenCapsOnLabels]="true">
                    </lib-vitu-form-field-select>
                </div>

                <div id="description">
                    <lib-vitu-form-field-input
                        [formGroup]="adjustmentForm"
                        formFieldName="description"
                        label="Description"
                        [errorOverrides]="[{'maxlength': 'Up to 45 chars only'}]">
                    </lib-vitu-form-field-input>
                </div>

            </div>

            <lib-vitu-form-buttons class="form-buttons-line">

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Submit"
                    [neverDisable]="isCreate">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </div>

        <div *ngIf="!isCreate" class="view">

            <div class="view-inner-container">
    
                <lib-text-field label="Adjustment Ref">
                    {{ adjustment?.token }}
                </lib-text-field>

                <lib-text-field [label]="'Date Created (' + LocalTimePoint.formatZ() + ')'">
                    <lib-date-time-label
                        *ngIf="adjustment?.createdUtc"
                        [singleLine]="true"
                        [dateUtc]="adjustment?.createdUtc">
                    </lib-date-time-label>
                </lib-text-field>

                <lib-text-field [label]="'Adjustment Date (' + LocalTimePoint.formatZ() + ')'"
                    [infoTip]="adjustmentDateInfoTip">
                    <lib-date-time-label 
                        *ngIf="adjustment?.adjustmentDate"
                        [singleLine]="true"
                        [dateUtc]="adjustment?.adjustmentDate"
                        [rawDateWithNoTime]="true">
                    </lib-date-time-label>
                </lib-text-field>

                <lib-text-field label="Amount">
                    {{ adjustment?.amount | vituCurrencyUsd }}
                </lib-text-field>

                <lib-text-field label="Reason">
                    {{ adjustment?.reason | spaceBetweenCaps }}
                </lib-text-field>

                <lib-text-field label="MID">
                    {{ adjustment?.mid }}
                </lib-text-field>

                <lib-text-field label="Funding Deposit Ref">
                    {{ adjustment?.fundingDepositsRef }}
                </lib-text-field>

                <lib-text-field label="Type">
                    {{ adjustment?.type }}
                </lib-text-field>

                <lib-text-field label="Description">
                    {{ adjustment?.description }}
                </lib-text-field>

            </div>

            <div class="actions-line">

                <div class="action-buttons">
    
                    <lib-action-button
                        id="deleteAdjustmentButton"
                        class="action-button"
                        label="Delete Adjustment"
                        fontImage="far fa-trash fa-bg"
                        [kind]="ActionButtonKind.ALERT"
                        [disabled]="adjustmentLoading || !adjustment"
                        (click)="onClickDelete()">
                    </lib-action-button>
    
                </div>
    
            </div>

            <lib-vitu-form-buttons class="form-buttons-line">

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()">
                </lib-vitu-form-abort-button>
    
            </lib-vitu-form-buttons>

        </div>

    </form>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>
