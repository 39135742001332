<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Webhooks' }, { 'label': 'Configure' , 'link': '/dashboard/webhooks/configure' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">
        <ng-container *ngIf="!webhook then spinner"></ng-container>
        <ng-container *ngIf="webhook then details"></ng-container>
    </div>

</div>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #details>

    <form class="form" [formGroup]="webhookForm" [libVituForm]="webhookForm" (libVituFormSubmit)="onSubmit()">

        <div class="form-inner-container">

            <div>

                <lib-vitu-form-field-input
                    class="field"
                    id="url"
                    [formGroup]="webhookForm"
                    formFieldName="url"
                    label="URL">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    class="field"
                    id="solution"
                    [formGroup]="webhookForm"
                    formFieldName="solution"
                    label="Solution"
                    [options]="solutions"
                    [optionLabelFn]="getDisplayTextForSolution.bind(this)">
                </lib-vitu-form-field-select>

            </div>

            <lib-grouped-switch-array
                heading="EVENT TYPES"
                errorMessageAtLeastOneRequired="At least one event type is required"
                formControlName="eventTypes">
            </lib-grouped-switch-array>

            <div class="secret-fields-container" *ngIf="!isCreate">

                <lib-vitu-form-field-input
                    class="field"
                    id="secret"
                    [formGroup]="webhookForm"
                    formFieldName="secret"
                    label="Secret"
                    type="obscured"
                    [(showObscured)]="showSecret"
                    readonly>
                </lib-vitu-form-field-input>

                <lib-action-button
                    class="selection-button align-buttons-y"
                    label="Generate Secret"
                    [kind]="ActionButtonKind.SECONDARY"
                    [disabled]="loading || generatedSecretLoading"
                    (click)="onClickGenerateSecret()">
                </lib-action-button>

            </div>

            <div class="actions-line" *ngIf="!isCreate">

                <lib-vitu-form-field-switch
                    formControlName="isActive"
                    label="Active">
                </lib-vitu-form-field-switch>

                <div class="action-buttons">

                    <lib-action-button
                        id="deleteWebhookButton"
                        class="action-button"
                        label="Delete Webhook"
                        fontImage="far fa-trash fa-bg"
                        [kind]="ActionButtonKind.ALERT"
                        [disabled]="loading || !webhook"
                        (click)="onClickDelete()">
                    </lib-action-button>
        
                </div>

            </div>

        </div>

        <lib-vitu-form-buttons>

            <lib-vitu-form-abort-button
                (onAbort)="onClickBack()">
            </lib-vitu-form-abort-button>

            <lib-vitu-form-submit-button
                [label]="submitButtonText"
                [neverDisable]="isCreate">
            </lib-vitu-form-submit-button>

        </lib-vitu-form-buttons>

    </form>

</ng-template>
