import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ColumnDef } from "shared-lib";
import { getCurrentUserPermissions } from "@admin_app/storage/current-user/current-user.selectors";
import { IStore } from "@admin_app/storage/store";
import { ConfigDto } from "@admin_app/storage/config/config.state";
import { SolutionWebhookDto } from "@admin_api/models/solution-webhook-dto";
import { WebhookDto } from "@admin_api/models/webhook-dto";

@Component({
    selector: "app-webhook-log",
    templateUrl: "./webhook-log.component.html",
    styleUrls: [ "./webhook-log.component.less" ]
})
export class WebhookLogComponent {

    @Input() config: ConfigDto;
    @Input() webhooks: SolutionWebhookDto[];
    @Input() loading = false;
    @Input() error: Error;
    @Input() toggleWebhooksLoading: Array<number> = [];
    @Output() activeToggled = new EventEmitter<{webhook: WebhookDto; undo: () => void}>();

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    webhookColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "id", title: "ID", flexWidthBasisInPixels: 150, flexWidthGrow: 0, canSort: true },
        { id: "url", title: "URL", flexWidthBasisInPixels: 580, flexWidthGrow: 0, canSort: true },
        { id: "solution", title: "Solution", flexWidthBasisInPixels: 220, flexWidthGrow: 0, canSort: true },
        { id: "active", title: "Active", flexWidthBasisInPixels: 60, flexWidthGrow: 0 },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 1},
    ];

    currentUserPermissions$ = this.store.pipe(
        select(getCurrentUserPermissions)
    );

    onIsActiveToggled(payload: {webhook: WebhookDto; undo: () => void}) {
        this.activeToggled.emit(payload);
    }

    onClickCreate() {
        this.router.navigate([`/dashboard/webhooks/configure/create`]);
    }

    onRowSelected(id: string) {
        this.router.navigate([`/dashboard/webhooks/configure/${id}`]);
    }

    constructor(private router: Router, private store: Store<IStore>) {}

    hasCreatePermission(currentPermissions: string[]): boolean {

        return true;
    }

}
