import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of, Subject } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { GoBackAction, NavigateAction, PageLoadFailAction, ReloadPageAction } from "../router/router.actions";

import {
    ActionTypes,
    CreateTidAction,
    DeleteTidAction,
    GetTidAction,
    GetTidSucceedAction,
    GetNewTidAction,
    UpdateTidAction,
    ReassignDeviceAction,
    GetTidFailAction,
    PairDeviceAction,
    GetGooglePayMerchantIdAction,
    GetGooglePayMerchantIdSucceedAction,
    GetGooglePayMerchantIdFailAction,
    GetApplePayMerchantIdAction,
    GetApplePayMerchantIdSucceedAction,
    GetApplePayMerchantIdFailAction,
    SyncApplePayAction,
    SyncApplePaySucceedAction,
    SyncApplePayFailAction,
    GetDomainValidationFileAction
} from "./tid.actions";
import { ConfirmationModalComponent, ConfirmationModalData,
    GlobalSpinnerService, VituCommonErrorEntityIdMustBeNumber, VituToastService, VituToastTone } from "shared-lib";
import { MerchantsService } from "@admin_api/services/merchants.service";
import { MerchantTerminalDetailsDto } from "@admin_api/models/merchant-terminal-details-dto";
import { ReassignDeviceModalAction } from "@admin_app/shared/reassign-device-modal/reassign-device-modal-action";
import { ReassignDeviceModalComponent } from "@admin_app/shared/reassign-device-modal/reassign-device-modal.component";
import { ModalActionType } from "@admin_app/shared/modal-action";
import { ReassignPosTerminalDto } from "@admin_api/models/reassign-pos-terminal-dto";
import { PairDeviceModalComponent } from "@admin_app/shared/pair-device-modal/pair-device-modal.component";
import { PairDeviceModalAction } from "@admin_app/shared/pair-device-modal/pair-device-modal-action";
import { AuthenticateTerminalDto } from "@admin_api/models/authenticate-terminal-dto";
import { CommonUtils } from "../common";
import { ApplePayConfigResponse } from "@admin_api/models/apple-pay-config-response";
import { RcConfigDto } from "@admin_api/models/rc-config-dto";

@Injectable()
export class TidEffects {

    constructor(
        private actions$: Actions,
        private merchantsService: MerchantsService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private toast: VituToastService
    ) {}

    getTid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetTidAction>(ActionTypes.GetTid),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetTidFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.merchantsService.merchantTerminalsGet({ id: action.id, merchantId: action.merchantId }).pipe(
                    switchMap(tid => of(new GetTidSucceedAction(tid))),
                    catchError((error) => of(new GetTidFailAction(error)))
                );
            })
        )
    );

    getNewTid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewTidAction>(ActionTypes.GetNewTid),
            switchMap(() => {
                const newTid: MerchantTerminalDetailsDto = {};
                return of(new GetTidSucceedAction(newTid));
            })
        ),
    );

    getTidFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetTidFailAction>(ActionTypes.GetTidFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateTid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateTidAction>(ActionTypes.UpdateTid),
            map((action) => {
                const rcConfig = action?.tid?.config as RcConfigDto;
                const requestRegenerateDatawireId = rcConfig?.datawireId === null;
                return { ...action, requestRegenerateDatawireId };
            }),
            switchMap(({ id, merchantId, tid, requestRegenerateDatawireId }) => {
                if (requestRegenerateDatawireId) {
                    return this.dialog.open(ConfirmationModalComponent, {
                        data: {
                            title: "Datawire ID",
                            subtitle: "Are you sure you would like to request a new Datawire ID?",
                            confirmButtonText: "Yes"
                        } as ConfirmationModalData
                    }).afterClosed().pipe(
                        map((confirmed: boolean) => ({ id, merchantId, tid, confirmed })),
                        filter(({ confirmed }) => confirmed)
                    );
                }
                return of({ id, merchantId, tid});
            }),
            switchMap(({ id, merchantId, tid }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantTerminalsPut({ id, merchantId, body: tid }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        )
    );

    deleteTid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteTidAction>(ActionTypes.DeleteTid),
            switchMap(({ id, merchantId }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete TID",
                        subtitle: "Are you sure you want to permanently delete this TID record?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ id, merchantId, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ id, merchantId }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantTerminalsDelete({ id, merchantId }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        )
    );

    createTid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<CreateTidAction>(ActionTypes.CreateTid),
            switchMap(({ merchantId, tid }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantTerminalsPost({ merchantId, body: tid }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        )
    );

    reassignDevice = createEffect((): any => this.actions$.pipe(
            ofType<ReassignDeviceAction>(ActionTypes.ReassignDevice),
            switchMap(reassignDeviceAction => {

                const errorMessageSubject = new Subject<string>();
                const modalActionSubject = new Subject<ReassignDeviceModalAction>();

                const dialogRef = this.dialog
                    .open(ReassignDeviceModalComponent, {
                        width: "700px",
                        data: {
                            sourceMerchantId: reassignDeviceAction.merchantId,
                            sourceMerchantProcessorId: reassignDeviceAction.merchantProcessorId,
                            sourceMerchantTerminalId: reassignDeviceAction.merchantTerminalId,
                            modalActionSubject,
                            errorMessageSubject
                        },
                        disableClose: true,
                        autoFocus: false
                    });
                return modalActionSubject.pipe(
                    switchMap(({action, params}: ReassignDeviceModalAction) => {
                        switch (action) {
                            case ModalActionType.CANCEL:
                            {
                                dialogRef.close();
                                return of();
                            }
                            case ModalActionType.CONFIRM:
                            {
                                const body = {
                                    cloverMID: params.cloverMerchantId,
                                    dba: params.dba,
                                    deviceLocation: params.deviceLocation,
                                    deviceName: params.deviceName,
                                    mai: params.mai,
                                    merchantId: params.merchantId,
                                    merchantProcessorId: params.merchantProcessorId,
                                    status: params.status
                                } as ReassignPosTerminalDto;

                                return this.globalSpinner.apply(this.merchantsService.merchantTerminalsReassignDevice(
                                    {
                                        merchantId: params.sourceMerchantId,
                                        id: params.merchantTerminalId,
                                        body
                                    })
                                    .pipe(
                                        switchMap(() => {
                                            dialogRef.close();
                                            this.toast.open("Device reassigned successfully.", VituToastTone.Positive);
                                            return of(NavigateAction({
                                                payload: { path: [`/dashboard/organizations/${params.merchantId}/tids`] }
                                            }));
                                        }),
                                        catchError(error => {
                                            errorMessageSubject.next(error instanceof Error ? error.message : error);
                                            return of();
                                        })
                                    )
                                );
                            }
                        }
                    })
                );
            })
        ));

        pairDevice = createEffect((): any => this.actions$.pipe(
            ofType<PairDeviceAction>(ActionTypes.PairDevice),
            switchMap(pairDeviceAction => {

                const errorMessageSubject = new Subject<string>();
                const modalActionSubject = new Subject<PairDeviceModalAction>();

                const dialogRef = this.dialog
                    .open(PairDeviceModalComponent, {
                        width: "400px",
                        data: {
                            sourceMerchantId: pairDeviceAction.merchantId,
                            sourceMerchantProcessorId: pairDeviceAction.merchantProcessorId,
                            sourceMerchantTerminalId: pairDeviceAction.merchantTerminalId,
                            modalActionSubject,
                            errorMessageSubject
                        },
                        disableClose: true,
                        autoFocus: false
                    });
                return modalActionSubject.pipe(
                    switchMap(({action, params}: PairDeviceModalAction) => {
                        switch (action) {
                            case ModalActionType.CANCEL:
                            {
                                dialogRef.close();
                                return of();
                            }
                            case ModalActionType.CONFIRM:
                            {
                                const body = {
                                    userCode: params.userCode
                                } as AuthenticateTerminalDto;

                                return this.globalSpinner.apply(this.merchantsService.merchantTerminalsAuthenticateTerminal(
                                    {
                                        merchantId: params.sourceMerchantId,
                                        id: params.merchantTerminalId,
                                        body
                                    })
                                    .pipe(
                                        switchMap(() => {
                                            dialogRef.close();
                                            this.toast.open("Device paired successfully.", VituToastTone.Positive);
                                            return of();
                                        }),
                                        catchError(error => {
                                            errorMessageSubject.next(error instanceof Error ? error.message : error);
                                            return of();
                                        })
                                    )
                                );
                            }
                        }
                    })
                );
            })
        ));

    getGooglePayMerchantId$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetGooglePayMerchantIdAction>(ActionTypes.GetGooglePayMerchantId),
            switchMap(({ id, merchantId }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantTerminalsGenerateGoogleGatewayMerchantIdentifier({ id, merchantId }).pipe(
                        switchMap((response) => of(new GetGooglePayMerchantIdSucceedAction(response))),
                        catchError((error) => of(new GetGooglePayMerchantIdFailAction(error)))
                    )
                )
            )
        )
    );

    getApplePayMerchantId$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetApplePayMerchantIdAction>(ActionTypes.GetApplePayMerchantId),
            switchMap(({ id, merchantId }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantTerminalsGenerateAppleMerchantIdentifier({ id, merchantId }).pipe(
                        switchMap((response) => of(new GetApplePayMerchantIdSucceedAction(response))),
                        catchError((error) => of(new GetApplePayMerchantIdFailAction(error)))
                    )
                )
            )
        )
    );

    syncApplePay$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SyncApplePayAction>(ActionTypes.SyncApplePay),
            switchMap(({ id, merchantId }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantTerminalsSynchronizeAppleConfiguration({ id, merchantId }).pipe(
                        switchMap((response: ApplePayConfigResponse) => of(new SyncApplePaySucceedAction(response))),
                        catchError((error) => of(new SyncApplePayFailAction(error)))
                    )
                )
            )
        )
    );

    syncApplePaySucceed = createEffect(() =>
        this.actions$.pipe(
            ofType<SyncApplePaySucceedAction>(ActionTypes.SyncApplePaySucceed),
            switchMap(({ applePayConfigResponse }) => {
                const responseErrors = (applePayConfigResponse as any)?.synchronizationResult?.errorMessages;
                const toastLines = [];
                if (responseErrors?.length > 0) {
                    toastLines.push("Apple Pay configuration synchronization result:");
                    responseErrors.forEach(responseError => {
                        const reason = (responseError instanceof Error) ? responseError.message : responseError;
                        if (typeof reason === "string") {
                            toastLines.push(reason);
                        }
                    });
                    this.toast.open(toastLines, VituToastTone.Neutral);
                }
                else {
                    toastLines.push("Apple Pay configuration synchronization success.");
                    this.toast.open(toastLines, VituToastTone.Positive);
                }
                return of();
            })
        ),
    { dispatch: false });

    syncApplePayFail = createEffect(() =>
        this.actions$.pipe(
            ofType<SyncApplePayFailAction>(ActionTypes.SyncApplePayFail),
            switchMap(() => {
                this.toast.open("Apple Pay configuration synchronization failed.", VituToastTone.Negative);
                return of();
            })
        ),
    { dispatch: false });

    getDomainValidationFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetDomainValidationFileAction>(ActionTypes.GetDomainValidationFile),
            switchMap(({ id, merchantId }) =>
                this.globalSpinner.apply(this.merchantsService.merchantTerminalsGetDomainValidationFile$Response({ id, merchantId })
                    .pipe(
                        map((response: any) => {
                            CommonUtils.downloadFile(response);
                            return true;
                        })
                    )
                )
            )
        ),
    { dispatch: false });

}
