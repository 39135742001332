import { Component, Input } from "@angular/core";

@Component({
    selector: "app-users-table-cell-content",
    templateUrl: "./users-table-cell-content.component.html",
    styleUrls: ["./users-table-cell-content.component.less"],
})
export class UsersTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;

    get sortingKeyForLockedStatus(): number {
        return this.element.isLockedOut ? 0 : 1;
    }
}
