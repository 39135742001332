import { Action } from "@ngrx/store";
import { FeeTypeModel } from "@admin_api/models/fee-type-model";

export enum ActionTypes {
    GetFeeTypes = "[FEE_TYPES] GetFeeTypes",
    GetFeeTypesSucceed = "[FEE_TYPES] GetFeeTypesSucceed",
    GetFeeTypesFail = "[FEE_TYPES] GetFeeTypesFail"
}

export class GetFeeTypesAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetFeeTypes;
}

export class GetFeeTypesSucceedAction implements Action {

    constructor(public feeTypes: Array<FeeTypeModel>) {}

    readonly type = ActionTypes.GetFeeTypesSucceed;
}

export class GetFeeTypesFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetFeeTypesFail;
}
