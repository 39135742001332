import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ColumnDef } from "shared-lib";
import { getCurrentUserPermissions } from "@admin_app/storage/current-user/current-user.selectors";
import { IStore } from "@admin_app/storage/store";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";

@Component({
    selector: "app-account-log",
    templateUrl: "./account-log.component.html",
    styleUrls: [ "./account-log.component.less" ]
})
export class AccountLogComponent {

    @Input() accounts: Array<MerchantProcessorAccountDto>;
    @Input() loading = false;
    @Input() error: Error;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    accountColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "id", title: "Account Ref", flexWidthBasisInPixels: 150, flexWidthGrow: 0, canSort: true},
        { id: "name", title: "Name", flexWidthBasisInPixels: 300, flexWidthGrow: 0, canSort: true},
        { id: "description", title: "Description", flexWidthBasisInPixels: 400, flexWidthGrow: 1, canSort: true},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    currentUserPermissions$ = this.store.pipe(
        select(getCurrentUserPermissions)
    );

    onClickCreate() {
        this.router.navigate([`/dashboard/accounts/create`]);
    }

    onRowSelected(id: number) {
        this.router.navigate([`/dashboard/accounts/${id}`]);
    }

    constructor(private router: Router, private store: Store<IStore>) {}

    hasCreateAccountPermission(currentPermissions: string[]): boolean {

        return (currentPermissions.indexOf("core.accounts.create") !== -1);
    }

}
