/* tslint:disable */
/* eslint-disable */

/**
 * The BusinessType.
 * https://developer.paypal.com/api/limited-release/managed-accounts/v3/#definition-customer_common_components_overrides-business_type
 */
export enum BusinessType {
  ASSOCIATION = 'ASSOCIATION',
  CORPORATION = 'CORPORATION',
  GOVERNMENT = 'GOVERNMENT',
  INDIVIDUAL = 'INDIVIDUAL',
  NONPROFIT = 'NONPROFIT',
  OTHER_CORPORATE_BODY = 'OTHER_CORPORATE_BODY',
  PARTNERSHIP = 'PARTNERSHIP',
  PROPRIETORSHIP = 'PROPRIETORSHIP',
  PRIVATE_CORPORATION = 'PRIVATE_CORPORATION',
  PROPRIETORY_COMPANY = 'PROPRIETORY_COMPANY',
  PUBLIC_COMPANY = 'PUBLIC_COMPANY',
  REGISTERED_COOPERATIVE = 'REGISTERED_COOPERATIVE'
}
