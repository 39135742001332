/* eslint-disable max-len */
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { EffectsConfiguration } from "./storage/effects-configuration";
import { ReducerConfiguration, ReducerMap } from "./storage/reducer-configuration";

import { AppComponent } from "./app.component";
import { DevicesComponent } from "./pages/devices/devices.component";
import { DevicesLogComponent } from "./pages/devices/devices-log/devices-log.component";
import {
    DevicesTableCellContentComponent
} from "./pages/devices/devices-log/devices-list-table-cell-content/devices-table-cell-content.component";
import { SharedModule } from "./shared/shared.module";

import { AuthService } from "shared-lib";
import { AuthConfig, OAuthModule } from "angular-oauth2-oidc";
import { environment } from "@admin_environments/environment";
import { ApiModule as CoreApiModule } from "@Portal/CoreApi/api.module";
import { ApiModule as CoreAdminApiModule } from "@admin_api/api.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { MidComponent } from "./pages/mid/mid.component";
import { MidDetailsComponent } from "./pages/mid/mid-details/mid-details.component";
import { TidComponent } from "./pages/tid/tid.component";
import { TidDetailsComponent } from "./pages/tid/tid-details/tid-details.component";
import { UserComponent } from "./pages/user/user.component";
import { UserDetailsComponent } from "./pages/user/user-details/user-details.component";
import {
    FeePlansTableCellContentComponent
} from "./pages/fee-plans/fee-plans-log/fee-plans-list-table-cell-content/fee-plans-table-cell-content.component";
import { FeePlansLogComponent } from "./pages/fee-plans/fee-plans-log/fee-plans-log.component";
import { FeePlansComponent } from "./pages/fee-plans/fee-plans.component";
import { FeePlanComponent } from "./pages/fee-plan/fee-plan.component";
import { FeePlanDetailsComponent } from "./pages/fee-plan/fee-plan/fee-plan-details.component";
import {
    FeePlanFeeTypesTableCellContentComponent
} from "./pages/fee-plan/fee-plan/fee-plan-fee-types-table-cell-content/fee-plan-fee-types-table-cell-content.component";
import {
    FeePlanMidsTableCellContentComponent
} from "./pages/fee-plan/fee-plan/fee-plan-mids-table-cell-content/fee-plan-mids-table-cell-content.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { ReportsLogComponent } from "./pages/reports/reports-log/reports-log.component";
import {
    ReportsTableCellContentComponent
} from "./pages/reports/reports-log/reports-table-cell-content/reports-table-cell-content.component";
import { DownloadReportModalComponent } from "./shared/download-report-modal/download-report-modal.component";
import { ReassignDeviceModalComponent } from "./shared/reassign-device-modal/reassign-device-modal.component";
import { PairDeviceModalComponent } from "./shared/pair-device-modal/pair-device-modal.component";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { PageUnavailableComponent } from "./pages/page-unavailable/page-unavailable.component";
import { WebhooksComponent } from "./pages/webhooks/webhooks.component";
import { WebhookLogComponent } from "./pages/webhooks/webhook-log/webhook-log.component";
import {
    WebhooksTableCellContentComponent
} from "./pages/webhooks/webhook-log/webhooks-table-cell-content/webhooks-table-cell-content.component";
import { WebhookComponent } from "./pages/webhook/webhook.component";
import { WebhookDetailsComponent } from "./pages/webhook/webhook-details/webhook-details.component";
import { EventHistoryLogComponent } from "./pages/event-history/event-history-log/event-history-log.component";
import {
    EventHistoryTableCellContentComponent
} from "./pages/event-history/event-history-log/event-history-table-cell-content/event-history-table-cell-content.component";
import { EventHistoryComponent } from "./pages/event-history/event-history.component";
import { EventComponent } from "./pages/event/event.component";
import { EventDetailsComponent } from "./pages/event/event-details/event-details.component";
import {
    FundingTableCellContentComponent
} from "./pages/funding/funding-log/funding-table-cell-content/funding-table-cell-content.component";
import { FundingComponent } from "./pages/funding/funding.component";
import { FundingLogComponent } from "./pages/funding/funding-log/funding-log.component";
import { FundingItemComponent } from "./pages/funding-item/funding-item.component";
import { FundingItemDetailsComponent } from "./pages/funding-item/funding-item-details/funding-item-details.component";
import {
    FundingItemSettlementTableCellContentComponent } from
"./pages/funding-item/funding-item-details/funding-item-settlement-table-cell-content/funding-item-settlement-table-cell-content.component";
import { FundingLogFiltersComponent } from "./pages/funding/funding-log/funding-log-filters/funding-log-filters.component";
import {
    DfmFilesTableCellContentComponent
} from "./pages/google-cloud-storage/google-cloud-storage-log/dfm-files-table-cell-content/dfm-files-table-cell-content.component";
import {
    BinFilesTableCellContentComponent
} from "./pages/google-cloud-storage/google-cloud-storage-log/bin-files-table-cell-content/bin-files-table-cell-content.component";
import { GoogleCloudStorageComponent } from "./pages/google-cloud-storage/google-cloud-storage.component";
import { GoogleCloudStorageLogComponent } from "./pages/google-cloud-storage/google-cloud-storage-log/google-cloud-storage-log.component";
import { authConfig } from "./auth-config";
import { DashboardModule } from "./pages/dashboard/dashboard.module";
import {
    EventHistoryLogFiltersComponent
} from "./pages/event-history/event-history-log/event-history-log-filters/event-history-log-filters.component";
import {
    EventDeliveriesTableCellContentComponent
} from "./pages/event/event-details/event-deliveries-table-cell-content/event-deliveries-table-cell-content.component";
import {
    FundingItemTransactionFiltersComponent
} from "./pages/funding-item/funding-item-details/funding-item-transaction-filters/funding-item-transaction-filters.component";
import {
    FundingItemTransactionsTableCellContentComponent
} from "./pages/funding-item/funding-item-details/funding-item-transactions-table-cell-content/funding-item-transactions-table-cell-content.component";
import {
    FundingItemMidFeesTableCellContentComponent
} from "./pages/funding-item/funding-item-details/funding-item-mid-fees-table-cell-content/funding-item-mid-fees-table-cell-content.component";
import { RequestSettlementModalComponent } from "./shared/request-settlement-modal/request-settlement-modal.component";
import { GooglePayComponent } from "./pages/google-pay/google-pay.component";
import { GooglePayDetailComponent } from "./pages/google-pay/google-pay-detail/google-pay-detail.component";
import {
    GooglePayCertificatesTableCellContentComponent
} from "./pages/google-pay/google-pay-detail/google-pay-certificates-table-cell-content/google-pay-certificates-table-cell-content.component";
import { BinLogFiltersComponent } from "./pages/google-cloud-storage/google-cloud-storage-log/bin-log-filters/bin-log-filters.component";
import { DfmLogFiltersComponent } from "./pages/google-cloud-storage/google-cloud-storage-log/dfm-log-filters/dfm-log-filters.component";
import { HelpDetailsComponent } from "./pages/help/help-details/help-details.component";
import { HelpComponent } from "./pages/help/help.component";
import { DevicesLogFiltersComponent } from "./pages/devices/devices-log/devices-log-filters/devices-log-filters.component";
import { TransactionsComponent } from "./pages/transactions/transactions.component";
import { TransactionLogComponent } from "./pages/transactions/transaction-log/transaction-log.component";
import { TransactionLogFiltersComponent } from "./pages/transactions/transaction-log/transaction-log-filters/transaction-log-filters.component";
import { TransactionComponent } from "./pages/transaction/transaction.component";
import { TransactionDetailComponent } from "./pages/transaction/transaction-detail/transaction-detail.component";
import { DisputesTableCellContentComponent } from "./pages/transaction/transaction-detail/disputes-table-cell-content/disputes-table-cell-content.component";
import { FeesTableCellContentComponent } from "./pages/transaction/transaction-detail/fees-table-cell-content/fees-table-cell-content.component";
import { RolesComponent } from "./pages/roles/roles.component";
import { RoleLogComponent } from "./pages/roles/role-log/role-log.component";
import { RolesTableCellContentComponent } from "./pages/roles/role-log/roles-table-cell-content/roles-table-cell-content.component";
import { RoleDetailsComponent } from "./pages/role/role-details/role-details.component";
import { RoleComponent } from "./pages/role/role.component";
import { AccountsTableCellContentComponent } from "./pages/accounts/account-log/accounts-table-cell-content/accounts-table-cell-content.component";
import { AccountLogComponent } from "./pages/accounts/account-log/account-log.component";
import { AccountsComponent } from "./pages/accounts/accounts.component";
import { AccountComponent } from "./pages/account/account.component";
import { AccountDetailsComponent } from "./pages/account/account-details/account-details.component";
import { ServiceFeeAssociatedMidsTableCellContentComponent } from "./pages/mid/mid-details/service-fee-associated-mids-table-cell-content/service-fee-associated-mids-table-cell-content.component";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./custom-route-reuse-strategy";
import { OrganizationsLogComponent } from "./pages/organizations/organizations-log/organizations-log.component";
import { OrganizationsTableCellContentComponent } from "./pages/organizations/organizations-log/organizations-table-cell-content/organizations-table-cell-content.component";
import { OrganizationsComponent } from "./pages/organizations/organizations.component";
import { OrganizationsLogFiltersComponent } from "./pages/organizations/organizations-log/organizations-log-filters/organizations-log-filters.component";
import { OrganizationDetailsComponent } from "./pages/organization/organization-details/organization-details.component";
import { OrganizationComponent } from "./pages/organization/organization.component";
import {
    MidsTableCellContentComponent
} from "./pages/organization/organization-details/mids-table-cell-content/mids-table-cell-content.component";
import {
    TidsTableCellContentComponent
} from "./pages/organization/organization-details/tids-table-cell-content/tids-table-cell-content.component";
import {
    UsersTableCellContentComponent
} from "./pages/organization/organization-details/users-table-cell-content/users-table-cell-content.component";
import {
    PayPalEventsComponent
} from "./pages/paypal-events/paypal-events.component";
import {
    PayPalEventsLogComponent
} from "./pages/paypal-events/paypal-events-log/paypal-events-log.component";
import {
    PayPalEventsTableCellContentComponent
} from "./pages/paypal-events/paypal-events-log/paypal-events-table-cell-content/paypal-events-table-cell-content.component";
import {
    PayPalEventsLogFiltersComponent
} from "./pages/paypal-events/paypal-events-log/paypal-events-log-filters/paypal-events-log-filters.component";
import {
    PayPalSftpComponent
} from "./pages/paypal-sftp/paypal-sftp.component";
import {
    PayPalSftpLogComponent
} from "./pages/paypal-sftp/paypal-sftp-log/paypal-sftp-log.component";
import {
    PayPalSftpTableCellContentComponent
} from "./pages/paypal-sftp/paypal-sftp-log/paypal-sftp-table-cell-content/paypal-sftp-table-cell-content.component";
import {
    PayPalSftpLogFiltersComponent
} from "./pages/paypal-sftp/paypal-sftp-log/paypal-sftp-log-filters/paypal-sftp-log-filters.component";
import {
    FundingItemAdjustmentsTableCellContentComponent
} from "./pages/funding-item/funding-item-details/funding-item-adjustments-table-cell-content/funding-item-adjustments-table-cell-content.component";
import {
    AdjustmentsLogFiltersComponent
} from "./pages/adjustments/adjustments-log/adjustments-log-filters/adjustments-log-filters.component";
import {
    AdjustmentsLogComponent
} from "./pages/adjustments/adjustments-log/adjustments-log.component";
import {
    AdjustmentsTableCellContentComponent
} from "./pages/adjustments/adjustments-log/adjustments-table-cell-content/adjustments-table-cell-content.component";
import {
    AdjustmentsComponent
} from "./pages/adjustments/adjustments.component";
import { AdjustmentDetailsComponent } from "./pages/adjustment/adjustment-details/adjustment-details.component";
import { AdjustmentComponent } from "./pages/adjustment/adjustment.component";
import {
    ApplePayPaymentProcessingCertificatesTableCellContentComponent
} from "./pages/apple-pay/apple-pay-detail/apple-pay-payment-processing-certificates-table-cell-content/apple-pay-payment-processing-certificates-table-cell-content.component";
import {
    ApplePayDetailComponent
} from "./pages/apple-pay/apple-pay-detail/apple-pay-detail.component";
import {
    ApplePayComponent
} from "./pages/apple-pay/apple-pay.component";
import { UploadFileModalComponent } from "./shared/upload-file-modal/upload-file-modal.component";
import {
    ApplePayMerchantIdentityCertificatesTableCellContentComponent
} from "./pages/apple-pay/apple-pay-detail/apple-pay-merchant-identity-certificates-table-cell-content/apple-pay-merchant-identity-certificates-table-cell-content.component";
import {
    DisputesLogComponent
} from "./pages/disputes/disputes-log/disputes-log.component";
import {
    DisputesComponent
} from "./pages/disputes/disputes.component";
import { DisputesLogFiltersComponent } from "./pages/disputes/disputes-log/disputes-log-filters/disputes-log-filters.component";
import { DisputeDetailsComponent } from "./pages/dispute/dispute-details/dispute-details.component";
import { DisputeComponent } from "./pages/dispute/dispute.component";
import { PaymentLogComponent } from "./pages/payments/payment-log/payment-log.component";
import { PaymentsComponent } from "./pages/payments/payments.component";
import { GroupTransactionsTableCellContentComponent } from "./pages/payments/payment-log/group-transactions-table-cell-content/group-transactions-table-cell-content.component";
import { GroupTransactionLogFiltersComponent } from "./pages/payments/payment-log/group-transaction-log-filters/group-transaction-log-filters.component";
import { AccountMidsTableCellContentComponent } from "./pages/account/account-details/account-mids-table-cell-content/account-mids-table-cell-content.component";
import { MidFiltersComponent } from "./pages/organization/organization-details/mid-filters/mid-filters.component";
import { TidFiltersComponent } from "./pages/organization/organization-details/tid-filters/tid-filters.component";
import { MerchantHistoryTableCellContentComponent } from "./pages/organization/organization-details/merchant-history-table-cell-content/merchant-history-table-cell-content.component";
import { MerchantHistoryFiltersComponent } from "./pages/organization/organization-details/merchant-history-filters/merchant-history-filters.component";

@NgModule({
    declarations: [
        AppComponent,
        MidComponent,
        MidDetailsComponent,
        ServiceFeeAssociatedMidsTableCellContentComponent,
        TidComponent,
        TidDetailsComponent,
        UserComponent,
        UserDetailsComponent,
        DevicesComponent,
        DevicesLogComponent,
        DevicesTableCellContentComponent,
        DevicesLogFiltersComponent,
        FeePlansComponent,
        FeePlansLogComponent,
        FeePlansTableCellContentComponent,
        FeePlanComponent,
        FeePlanDetailsComponent,
        FeePlanFeeTypesTableCellContentComponent,
        FeePlanMidsTableCellContentComponent,
        EventComponent,
        EventDetailsComponent,
        EventDeliveriesTableCellContentComponent,
        EventHistoryComponent,
        EventHistoryLogComponent,
        EventHistoryTableCellContentComponent,
        EventHistoryLogFiltersComponent,
        PayPalEventsComponent,
        PayPalEventsLogComponent,
        PayPalEventsTableCellContentComponent,
        PayPalEventsLogFiltersComponent,
        WebhooksComponent,
        WebhookLogComponent,
        WebhooksTableCellContentComponent,
        WebhookComponent,
        WebhookDetailsComponent,
        ReportsComponent,
        ReportsLogComponent,
        ReportsTableCellContentComponent,
        DownloadReportModalComponent,
        ReassignDeviceModalComponent,
        PairDeviceModalComponent,
        RequestSettlementModalComponent,
        UploadFileModalComponent,
        ForbiddenComponent,
        PageUnavailableComponent,
        FundingComponent,
        FundingLogComponent,
        FundingTableCellContentComponent,
        FundingItemComponent,
        FundingItemDetailsComponent,
        FundingItemTransactionsTableCellContentComponent,
        FundingItemMidFeesTableCellContentComponent,
        FundingItemSettlementTableCellContentComponent,
        FundingItemAdjustmentsTableCellContentComponent,
        FundingItemTransactionFiltersComponent,
        FundingLogFiltersComponent,
        AdjustmentsComponent,
        AdjustmentsLogComponent,
        AdjustmentsTableCellContentComponent,
        AdjustmentsLogFiltersComponent,
        AdjustmentComponent,
        AdjustmentDetailsComponent,
        GoogleCloudStorageComponent,
        GoogleCloudStorageLogComponent,
        DfmFilesTableCellContentComponent,
        DfmLogFiltersComponent,
        BinFilesTableCellContentComponent,
        BinLogFiltersComponent,
        PayPalSftpComponent,
        PayPalSftpLogComponent,
        PayPalSftpTableCellContentComponent,
        PayPalSftpLogFiltersComponent,
        GooglePayComponent,
        GooglePayDetailComponent,
        GooglePayCertificatesTableCellContentComponent,
        ApplePayComponent,
        ApplePayDetailComponent,
        ApplePayPaymentProcessingCertificatesTableCellContentComponent,
        ApplePayMerchantIdentityCertificatesTableCellContentComponent,
        HelpComponent,
        HelpDetailsComponent,
        PaymentsComponent,
        PaymentLogComponent,
        GroupTransactionsTableCellContentComponent,
        GroupTransactionLogFiltersComponent,
        TransactionsComponent,
        TransactionLogComponent,
        TransactionLogFiltersComponent,
        TransactionComponent,
        TransactionDetailComponent,
        DisputesTableCellContentComponent,
        FeesTableCellContentComponent,
        RoleComponent,
        RoleDetailsComponent,
        RolesComponent,
        RoleLogComponent,
        RolesTableCellContentComponent,
        AccountMidsTableCellContentComponent,
        AccountComponent,
        AccountDetailsComponent,
        AccountsComponent,
        AccountLogComponent,
        AccountsTableCellContentComponent,
        MidFiltersComponent,
        TidFiltersComponent,
        MerchantHistoryFiltersComponent,
        OrganizationsLogFiltersComponent,
        OrganizationsTableCellContentComponent,
        OrganizationsLogComponent,
        OrganizationsComponent,
        OrganizationDetailsComponent,
        OrganizationComponent,
        MidsTableCellContentComponent,
        TidsTableCellContentComponent,
        UsersTableCellContentComponent,
        MerchantHistoryTableCellContentComponent,
        DisputesLogFiltersComponent,
        DisputesLogComponent,
        DisputesComponent,
        DisputeDetailsComponent,
        DisputeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StoreModule.forRoot(ReducerMap, ReducerConfiguration),
        EffectsModule.forRoot(EffectsConfiguration),
        SharedModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !environment.isDevelopment,
        }),
        CoreApiModule.forRoot({rootUrl: environment.coreApiRootUrl}),
        CoreAdminApiModule.forRoot({rootUrl: environment.coreApiRootUrl}),
        StoreRouterConnectingModule.forRoot(),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.coreApiRootUrl, environment.portalApiRootUrl],
                sendAccessToken: true,
            },
        }),
        DashboardModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: AuthService) => async () => {
                await authService.configure();
                return authService.validateToken().then((result) => {
                    if (result) {
                        // Only enable token refreshing if we have valid token
                        authService.initTokenRefreshListener();
                        return null;
                    }

                    return new Promise(() => {}); // return unresolvable promise to prevent applcation bootstrap
                });
            },
            deps: [AuthService],
            multi: true,
        },
        { provide: AuthConfig, useValue: authConfig },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
