import { TidState } from "./tid.state";
import { ActionTypes } from "./tid.actions";
import { sample } from "rxjs";

export const TidReducer = (
    state: TidState = new TidState(),
    action: any,
  ): TidState => {

    switch (action.type) {

        case ActionTypes.GetTid:
        case ActionTypes.GetNewTid: {
            return {
                ...state,
                record: null,
                loading: true,
                googlePayMerchantIdRecord: null,
                googlePayMerchantIdLoading: false,
                googlePayMerchantIdError: null,
                applePayMerchantIdRecord: null,
                applePayMerchantIdLoading: false,
                applePayMerchantIdError: null,
                applePaySyncRecord: null,
                applePaySyncLoading: false,
                applePaySyncError: null
            };
        }

        case ActionTypes.GetTidSucceed: {
            return {
                ...state,
                record: action.tid,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetTidFail: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        case ActionTypes.GetGooglePayMerchantId: {
            return {
                ...state,
                googlePayMerchantIdRecord: null,
                googlePayMerchantIdLoading: true
            };
        }

        case ActionTypes.GetGooglePayMerchantIdSucceed: {
            return {
                ...state,
                googlePayMerchantIdRecord: action.googlePayMerchantId,
                googlePayMerchantIdLoading: false,
                googlePayMerchantIdError: null
            };
        }

        case ActionTypes.GetGooglePayMerchantIdFail: {
            return {
                ...state,
                googlePayMerchantIdLoading: false,
                googlePayMerchantIdError: action.error
            };
        }

        case ActionTypes.GetApplePayMerchantId: {
            return {
                ...state,
                applePayMerchantIdRecord: null,
                applePayMerchantIdLoading: true
            };
        }

        case ActionTypes.GetApplePayMerchantIdSucceed: {
            return {
                ...state,
                applePayMerchantIdRecord: action.applePayMerchantId,
                applePayMerchantIdLoading: false,
                applePayMerchantIdError: null
            };
        }

        case ActionTypes.GetApplePayMerchantIdFail: {
            return {
                ...state,
                applePayMerchantIdLoading: false,
                applePayMerchantIdError: action.error
            };
        }

        case ActionTypes.SyncApplePay: {
            return {
                ...state,
                applePaySyncRecord: null,
                applePaySyncLoading: true
            };
        }

        case ActionTypes.SyncApplePaySucceed: {

            const newState = {
                ...state,
                applePaySyncRecord: {...action.applePayConfigResponse},
                applePaySyncLoading: false,
                applePaySyncError: null
            };

            return newState;
        }

        case ActionTypes.SyncApplePayFail: {
            return {
                ...state,
                applePaySyncLoading: false,
                applePaySyncError: action.error
            };
        }

        default: {
            return state;
        }
    }

};
