import { Component } from "@angular/core";
import { IStore } from "@admin_app/storage/store";
import { Store } from "@ngrx/store";
import { getAccounts, getAccountsError, getAccountsLoading } from "@admin_app/storage/accounts/accounts.selectors";

@Component({
    selector: "app-accounts",
    templateUrl: "./accounts.component.html",
    styleUrls: [ "./accounts.component.less" ]
})
export class AccountsComponent {

    accounts$ = this.store.select(getAccounts);

    loading$ = this.store.select(getAccountsLoading);

    error$ = this.store.select(getAccountsError);

    constructor(private store: Store<IStore>) {}

}
