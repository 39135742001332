<app-disputes-log
    [disputes]="disputes$ | async"
    [disputesLoading]="disputesLoading$ | async"
    [disputesError]="disputesError$ | async"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)"
    [filter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)"
    [exportLoading]="exportLoading$ | async"
    (exportToCsv)="exportToCsv()"
    [merchants]="merchants$ | async"
    [merchantsLoading]="merchantsLoading$ | async">
</app-disputes-log>
