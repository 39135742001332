import { UsersState } from "./users.state";
import { ActionTypes } from "./users.actions";

export const UsersReducer = (
    state: UsersState = new UsersState(),
    action: any,
  ): UsersState => {

    switch (action.type) {

        case ActionTypes.ResetUsers: {
            return {
                ...state,
                loading: false,
                record: [],
                error: null
            };
        }

        case ActionTypes.GetUsers: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetUsersSucceed: {
            return {
                ...state,
                record: action.users,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetUsersFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: []
            };
        }

        default: {
            return state;
        }
    }

};
