<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="OrganizationOrderBy.Id">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="OrganizationOrderBy.Name">
        <lib-text-table-column>
            {{ element.name }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'contact'">
        <lib-text-table-column>
            {{ getDisplayTextForContactEmail() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'address'">
        <lib-text-table-column>
            {{ getDisplayTextForAddress() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="OrganizationOrderBy.Solution">
        <lib-text-table-column>
            {{ getDisplayTextForSolution() }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'isMerchant'">
        <lib-binary-status-icon
            [value]="element?.isMerchant"
            [showPositiveOnly]="true">
        </lib-binary-status-icon>
        <lib-table-column-sort [key]="element?.isMerchant ? 1 : 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





