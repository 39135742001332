<app-fee-plans-log
    [feePlans]="feePlans$ | async"
    [feePlansLoading]="feePlansLoading$ | async"
    [feePlansError]="feePlansError$ | async"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)"
    (uploadPlanCode)="onUploadPlanCode()">
</app-fee-plans-log>
