import { Action } from "@ngrx/store";
import { PagedDataDtoOfMerchantTerminalDto } from "@admin_api/models/paged-data-dto-of-merchant-terminal-dto";
import { Filter } from "./tids.state";

export enum ActionTypes {
    ResetTids = "[TIDS] ResetTids",
    GetTids = "[TIDS] GetTids",
    GetTidsSucceed = "[TIDS] GetTidsSucceed",
    GetTidsFail = "[TIDS] GetTidsFail",
    FilterTids = "[TIDS] FilterTids",
    ResetAssociatedTids = "[TIDS] ResetAssociatedTids",
    GetAssociatedTids = "[TIDS] GetAssociatedTids",
    GetAssociatedTidsSucceed = "[TIDS] GetAssociatedTidsSucceed",
    GetAssociatedTidsFail = "[TIDS] GetAssociatedTidsFail"
}

export class ResetTidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetTids;
}

export class GetTidsAction implements Action {

    constructor(public merchantId: number, public merchantProcessorId?: number, public filter?: Filter) {}

    readonly type = ActionTypes.GetTids;
}

export class GetTidsSucceedAction implements Action {

    constructor(public response: PagedDataDtoOfMerchantTerminalDto, public stateExtensions?: any) {}

    readonly type = ActionTypes.GetTidsSucceed;
}

export class GetTidsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetTidsFail;
}

export class FilterTidsAction implements Action {

    constructor(public merchantId: number, public filter: Filter) {}

    readonly type = ActionTypes.FilterTids;
}

export class ResetAssociatedTidsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetAssociatedTids;
}

export class GetAssociatedTidsAction implements Action {

    constructor(public merchantId: number, public merchantProcessorId?: number, public filter?: Filter) {}

    readonly type = ActionTypes.GetAssociatedTids;
}

export class GetAssociatedTidsSucceedAction implements Action {

    constructor(public response: PagedDataDtoOfMerchantTerminalDto, public stateExtensions?: any) {}

    readonly type = ActionTypes.GetAssociatedTidsSucceed;
}

export class GetAssociatedTidsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetAssociatedTidsFail;
}
