import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetFeeTypesAction, GetFeeTypesFailAction, GetFeeTypesSucceedAction } from "./fee-types.actions";
import { throwError, of } from "rxjs";
import { BillingService } from "@admin_api/services/billing.service";

@Injectable()
export class FeeTypesEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService
    ) {}

    getFeeTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFeeTypesAction>(ActionTypes.GetFeeTypes),
            switchMap(() => this.billingService.feeTypesGetAll().pipe(
                    switchMap((feeTypes) => of(new GetFeeTypesSucceedAction(feeTypes))),
                    catchError((error) => of(new GetFeeTypesFailAction(error)))
                ))
        )
    );

}
