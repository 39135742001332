import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { LocalTimePoint } from "shared-lib";
import { OrganizationDto } from "@admin_api/models/organization-dto";
import { Filter } from "@admin_app/storage/inventory/inventory.state";
import { PosTerminalStatusEnum } from "@admin_api/models/pos-terminal-status-enum";

@Component({
    selector: "app-devices-log-filters",
    templateUrl: "./devices-log-filters.component.html",
    styleUrls: ["./devices-log-filters.component.less"]
})
export class DevicesLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    LocalTimePoint = LocalTimePoint;

    deviceStatusList: Array<PosTerminalStatusEnum>;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.deviceStatusList = Object.values(PosTerminalStatusEnum);
    }

    filterForm = this.fb.group({
        merchantId: [null],
        mid: [null],
        tid: [null],
        deviceSerial: [null],
        deviceName: [null],
        statuses: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

    get noMerchants(): boolean {
        return (!this.merchantsLoading) && !(this.merchants?.length);
    }

}
