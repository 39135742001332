import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { EmailRegex, LocalTimePoint } from "shared-lib";

@Component({
    selector: "app-merchant-history-filters",
    templateUrl: "./merchant-history-filters.component.html",
    styleUrls: ["./merchant-history-filters.component.less"]
})
export class MerchantHistoryFiltersComponent {

    @Output() filterChanged = new EventEmitter<any>();
    @Input() filter: any;

    filterForm = this.fb.group({
        localDateInterval: [null],
        tid: [null],
        mid: [null],
        userEmail: [null, [Validators.pattern(EmailRegex)]]
    });

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: UntypedFormBuilder
    ) {}

    onFilterChanged(filter: any) {
        this.filterChanged.emit(filter);
    }

}
