<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'token'">
        <lib-text-table-column>
            {{ element?.token }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'dateExpired'">
        <lib-date-time-label [dateUtc]="element?.dateExpired">
        </lib-date-time-label>
        <lib-table-column-sort [key]="sortingKeyForDateExpired"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'dateCreated'">
        <lib-date-time-label [dateUtc]="element?.dateCreated">
        </lib-date-time-label>
        <lib-table-column-sort [key]="sortingKeyForDateCreated"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'order'">
        <lib-text-table-column *ngIf="(element?.status === DigitalWalletCertificateStatusEnum.New) || (element?.status === DigitalWalletCertificateStatusEnum.Registered); else noOrder">
            {{ element?.order }}
        </lib-text-table-column>
        <ng-template #noOrder>
            <lib-text-table-column>
            </lib-text-table-column>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <lib-status-chip
            class="certificate-statuses"
            [status]="element?.status">
        </lib-status-chip>
    </ng-container>

    <ng-container *ngSwitchCase="'actions'">
        <div class="action-buttons-container">
            <lib-action-button
                *ngIf="element?.status !== DigitalWalletCertificateStatusEnum.Revoked"
                [kind]="actionButtonKind"
                label="Revoke"
                [fontImage]="fontImageForRevoke"
                [iconAfterText]="actionButtonIconAfterText"
                (click)="onClickRevoke()">
            </lib-action-button>
        </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





