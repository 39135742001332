import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnDef, LocalTimePoint, TableSorting, Pager } from "shared-lib";
import { Router } from "@angular/router";
import { PayPalEventDeliveryOrderByEnum } from "@admin_api/models/pay-pal-event-delivery-order-by-enum";
import { Filter, Sorting } from "@admin_app/storage/paypal-events/paypal-events.state";
import { PayPalEventDeliveryDto } from "@admin_api/models/pay-pal-event-delivery-dto";

@Component({
    selector: "app-paypal-events-log",
    templateUrl: "./paypal-events-log.component.html",
    styleUrls: [ "./paypal-events-log.component.less" ]
})
export class PayPalEventsLogComponent {

    @Input() events: Array<PayPalEventDeliveryDto>;
    @Input() loading = false;
    @Input() error: Error;

    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;
    @Input() filter: Filter;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() filterChanged = new EventEmitter<Filter>();

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    eventsColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: PayPalEventDeliveryOrderByEnum.Id, title: "ID", flexWidthBasisInPixels: 345, flexWidthGrow: 1, canSort: true},
        { id: PayPalEventDeliveryOrderByEnum.EventType, title: "Event Type", flexWidthBasisInPixels: 380, flexWidthGrow: 1, canSort: true},
        { id: PayPalEventDeliveryOrderByEnum.CreateTime, title:`Date (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
        { id: "summary", title: "Summary", flexWidthBasisInPixels: 150, flexWidthGrow: 4},
        { id: "status", title: "Status", flexWidthBasisInPixels: 110, flexWidthGrow: 0},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    constructor(private router: Router) {}

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as PayPalEventDeliveryOrderByEnum,
            orderDirection: sorting.orderDirection
        });
    }

    onRowSelected(id: number) {
//        this.router.navigate([`/dashboard/webhooks/paypal-events/${id}`]);
    }

}
