/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdjustmentDto } from '../models/adjustment-dto';
import { AdjustmentOrderBy } from '../models/adjustment-order-by';
import { BoundMerchantProcessorsResult } from '../models/bound-merchant-processors-result';
import { CategoryCodeDfmEnum } from '../models/category-code-dfm-enum';
import { CategoryCodeEnum } from '../models/category-code-enum';
import { CreateManualAdjustmentDto } from '../models/create-manual-adjustment-dto';
import { FeePlanData } from '../models/fee-plan-data';
import { FeePlanDetailModel } from '../models/fee-plan-detail-model';
import { FeePlanFeeTypes } from '../models/fee-plan-fee-types';
import { FeePlanOrderBy } from '../models/fee-plan-order-by';
import { FeeType } from '../models/fee-type';
import { FeeTypeModel } from '../models/fee-type-model';
import { FundingFee } from '../models/funding-fee';
import { FundingResultDetails } from '../models/funding-result-details';
import { FundingResultsOrderBy } from '../models/funding-results-order-by';
import { FundingStatus } from '../models/funding-status';
import { FundingTransactionOrderBy } from '../models/funding-transaction-order-by';
import { Lookup } from '../models/lookup';
import { ManualFundingSettlementResult } from '../models/manual-funding-settlement-result';
import { Month } from '../models/month';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfAdjustmentDto } from '../models/paged-data-dto-of-adjustment-dto';
import { PagedDataDtoOfFeePlanDto } from '../models/paged-data-dto-of-fee-plan-dto';
import { PagedDataDtoOfFundingResult } from '../models/paged-data-dto-of-funding-result';
import { PagedDataDtoOfFundingTransaction } from '../models/paged-data-dto-of-funding-transaction';
import { PlanCode } from '../models/plan-code';
import { PlanCodeModel } from '../models/plan-code-model';
import { ProcessorEnum } from '../models/processor-enum';
import { ResendFundingRequest } from '../models/resend-funding-request';
import { ResentFundingInstruction } from '../models/resent-funding-instruction';
import { Revision } from '../models/revision';
import { RevisionModel } from '../models/revision-model';
import { SettlementSummaryStatus } from '../models/settlement-summary-status';
import { StatementReportItem } from '../models/statement-report-item';
import { Tier } from '../models/tier';
import { TierModel } from '../models/tier-model';
import { TriggerEnum } from '../models/trigger-enum';
import { ValueFormatEnum } from '../models/value-format-enum';


/**
 * Billing API.
 */
@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adjustmentsSearch
   */
  static readonly AdjustmentsSearchPath = '/v1/admin/billing/adjustments';

  /**
   * Search manual adjustments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adjustmentsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustmentsSearch$Response(params?: {
    MerchantIds?: Array<number>;
    MID?: string;
    FundingDepositRef?: string;
    OrderBy?: AdjustmentOrderBy;
    AdjustmentStartDate?: string;
    AdjustmentEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfAdjustmentDto>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.AdjustmentsSearchPath, 'get');
    if (params) {
      rb.query('MerchantIds', params.MerchantIds, {"style":"form","explode":true});
      rb.query('MID', params.MID, {});
      rb.query('FundingDepositRef', params.FundingDepositRef, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('AdjustmentStartDate', params.AdjustmentStartDate, {});
      rb.query('AdjustmentEndDate', params.AdjustmentEndDate, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfAdjustmentDto>;
      })
    );
  }

  /**
   * Search manual adjustments.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adjustmentsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustmentsSearch(params?: {
    MerchantIds?: Array<number>;
    MID?: string;
    FundingDepositRef?: string;
    OrderBy?: AdjustmentOrderBy;
    AdjustmentStartDate?: string;
    AdjustmentEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfAdjustmentDto> {

    return this.adjustmentsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfAdjustmentDto>) => r.body as PagedDataDtoOfAdjustmentDto)
    );
  }

  /**
   * Path part for operation adjustmentsPost
   */
  static readonly AdjustmentsPostPath = '/v1/admin/billing/adjustments';

  /**
   * Create manual adjustment.
   *
   * Manual adjustments are used in funding and will be funded on AdjustmentDate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adjustmentsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adjustmentsPost$Response(params: {
    body: CreateManualAdjustmentDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.AdjustmentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create manual adjustment.
   *
   * Manual adjustments are used in funding and will be funded on AdjustmentDate
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adjustmentsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adjustmentsPost(params: {
    body: CreateManualAdjustmentDto
  }): Observable<string> {

    return this.adjustmentsPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation adjustmentsGet
   */
  static readonly AdjustmentsGetPath = '/v1/admin/billing/adjustments/{token}';

  /**
   * Get manual adjustments by token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adjustmentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustmentsGet$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<AdjustmentDto>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.AdjustmentsGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdjustmentDto>;
      })
    );
  }

  /**
   * Get manual adjustments by token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adjustmentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustmentsGet(params: {
    token: string;
  }): Observable<AdjustmentDto> {

    return this.adjustmentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<AdjustmentDto>) => r.body as AdjustmentDto)
    );
  }

  /**
   * Path part for operation adjustmentsDelete
   */
  static readonly AdjustmentsDeletePath = '/v1/admin/billing/adjustments/{token}';

  /**
   * Delete manual not funded adjustment.
   *
   * Only not funded adjustments can be deleted
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adjustmentsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustmentsDelete$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.AdjustmentsDeletePath, 'delete');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete manual not funded adjustment.
   *
   * Only not funded adjustments can be deleted
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adjustmentsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustmentsDelete(params: {
    token: string;
  }): Observable<void> {

    return this.adjustmentsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation feeCategoriesGet
   */
  static readonly FeeCategoriesGetPath = '/v1/admin/billing/fee-categories';

  /**
   * Provides an information about fee categories.
   *
   * Use this endpoint in order to get the information about all available fee categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeCategoriesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeCategoriesGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Lookup>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeCategoriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lookup>>;
      })
    );
  }

  /**
   * Provides an information about fee categories.
   *
   * Use this endpoint in order to get the information about all available fee categories
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeCategoriesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeCategoriesGet(params?: {
  }): Observable<Array<Lookup>> {

    return this.feeCategoriesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Lookup>>) => r.body as Array<Lookup>)
    );
  }

  /**
   * Path part for operation feeDfmCategoriesGet
   */
  static readonly FeeDfmCategoriesGetPath = '/v1/admin/billing/fee-dfm-categories';

  /**
   * Provides an information about DFM fee categories.
   *
   * Use this endpoint in order to get information about all available DFM fee categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeDfmCategoriesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeDfmCategoriesGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Lookup>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeDfmCategoriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lookup>>;
      })
    );
  }

  /**
   * Provides an information about DFM fee categories.
   *
   * Use this endpoint in order to get information about all available DFM fee categories
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeDfmCategoriesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeDfmCategoriesGet(params?: {
  }): Observable<Array<Lookup>> {

    return this.feeDfmCategoriesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Lookup>>) => r.body as Array<Lookup>)
    );
  }

  /**
   * Path part for operation feePlansSearch
   */
  static readonly FeePlansSearchPath = '/v1/admin/billing/fee-plans';

  /**
   * Lists paged and filtered fee plans by query.
   *
   * Use this endpoint to search fee plans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feePlansSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansSearch$Response(params?: {
    OrderBy?: FeePlanOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfFeePlanDto>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeePlansSearchPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfFeePlanDto>;
      })
    );
  }

  /**
   * Lists paged and filtered fee plans by query.
   *
   * Use this endpoint to search fee plans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feePlansSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansSearch(params?: {
    OrderBy?: FeePlanOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfFeePlanDto> {

    return this.feePlansSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfFeePlanDto>) => r.body as PagedDataDtoOfFeePlanDto)
    );
  }

  /**
   * Path part for operation feePlansPost
   */
  static readonly FeePlansPostPath = '/v1/admin/billing/fee-plans';

  /**
   * Creates a new fee plan.
   *
   * Use this endpoint if you want create a new fee plan. You need to specify a name and optionally a description
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feePlansPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feePlansPost$Response(params: {

    /**
     * Fee plan model contains name and optional description
     */
    body: FeePlanData
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeePlansPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Creates a new fee plan.
   *
   * Use this endpoint if you want create a new fee plan. You need to specify a name and optionally a description
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feePlansPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feePlansPost(params: {

    /**
     * Fee plan model contains name and optional description
     */
    body: FeePlanData
  }): Observable<number> {

    return this.feePlansPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation feePlansGet
   */
  static readonly FeePlansGetPath = '/v1/admin/billing/fee-plans/{id}';

  /**
   * Gets detailed information about the fee plan by id.
   *
   * Use this endpoint to get the detailed information about the fee plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feePlansGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansGet$Response(params: {

    /**
     * Fee plan id
     */
    id: number;
  }): Observable<StrictHttpResponse<FeePlanDetailModel>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeePlansGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FeePlanDetailModel>;
      })
    );
  }

  /**
   * Gets detailed information about the fee plan by id.
   *
   * Use this endpoint to get the detailed information about the fee plan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feePlansGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansGet(params: {

    /**
     * Fee plan id
     */
    id: number;
  }): Observable<FeePlanDetailModel> {

    return this.feePlansGet$Response(params).pipe(
      map((r: StrictHttpResponse<FeePlanDetailModel>) => r.body as FeePlanDetailModel)
    );
  }

  /**
   * Path part for operation feePlansPut
   */
  static readonly FeePlansPutPath = '/v1/admin/billing/fee-plans/{id}';

  /**
   * Updates fee plan data information.
   *
   * Use this endpoint in order to change fee plan name or description
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feePlansPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feePlansPut$Response(params: {

    /**
     * Fee plan id
     */
    id: number;

    /**
     * Updated fee plan model
     */
    body: FeePlanData
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeePlansPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates fee plan data information.
   *
   * Use this endpoint in order to change fee plan name or description
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feePlansPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feePlansPut(params: {

    /**
     * Fee plan id
     */
    id: number;

    /**
     * Updated fee plan model
     */
    body: FeePlanData
  }): Observable<void> {

    return this.feePlansPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation feePlansDelete
   */
  static readonly FeePlansDeletePath = '/v1/admin/billing/fee-plans/{id}';

  /**
   * Deletes the fee plan by id.
   *
   * Use this endpoint to delete a fee plan by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feePlansDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansDelete$Response(params: {

    /**
     * Fee plan id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeePlansDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes the fee plan by id.
   *
   * Use this endpoint to delete a fee plan by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feePlansDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansDelete(params: {

    /**
     * Fee plan id
     */
    id: number;
  }): Observable<void> {

    return this.feePlansDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation feePlansBindFeeTypes
   */
  static readonly FeePlansBindFeeTypesPath = '/v1/admin/billing/fee-plans/{id}/bind-fee-types';

  /**
   * Binds fee type to the fee plan.
   *
   * This endpoint provides the ability to update fee values in the fee plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feePlansBindFeeTypes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feePlansBindFeeTypes$Response(params: {

    /**
     * Fee plan id
     */
    id: number;

    /**
     * Fee value model to update
     */
    body: FeePlanFeeTypes
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeePlansBindFeeTypesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Binds fee type to the fee plan.
   *
   * This endpoint provides the ability to update fee values in the fee plan
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feePlansBindFeeTypes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feePlansBindFeeTypes(params: {

    /**
     * Fee plan id
     */
    id: number;

    /**
     * Fee value model to update
     */
    body: FeePlanFeeTypes
  }): Observable<void> {

    return this.feePlansBindFeeTypes$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation feePlansMerchantProcessors
   */
  static readonly FeePlansMerchantProcessorsPath = '/v1/admin/billing/fee-plans/{id}/merchant-processors';

  /**
   * Gets merchants by the fee plan id.
   *
   * This endpoint provides the list of connected merchants by fee plan id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feePlansMerchantProcessors()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansMerchantProcessors$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<BoundMerchantProcessorsResult>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeePlansMerchantProcessorsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoundMerchantProcessorsResult>>;
      })
    );
  }

  /**
   * Gets merchants by the fee plan id.
   *
   * This endpoint provides the list of connected merchants by fee plan id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feePlansMerchantProcessors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feePlansMerchantProcessors(params: {
    id: number;
  }): Observable<Array<BoundMerchantProcessorsResult>> {

    return this.feePlansMerchantProcessors$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BoundMerchantProcessorsResult>>) => r.body as Array<BoundMerchantProcessorsResult>)
    );
  }

  /**
   * Path part for operation feeTriggersGet
   */
  static readonly FeeTriggersGetPath = '/v1/admin/billing/fee-triggers';

  /**
   * Provides an information about fee triggers.
   *
   * Use this endpoint in order to get information about all available fee triggers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeTriggersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTriggersGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Lookup>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeTriggersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lookup>>;
      })
    );
  }

  /**
   * Provides an information about fee triggers.
   *
   * Use this endpoint in order to get information about all available fee triggers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeTriggersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTriggersGet(params?: {
  }): Observable<Array<Lookup>> {

    return this.feeTriggersGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Lookup>>) => r.body as Array<Lookup>)
    );
  }

  /**
   * Path part for operation feeTypesGetAll
   */
  static readonly FeeTypesGetAllPath = '/v1/admin/billing/fee-types';

  /**
   * Lists filtered fee types by the query.
   *
   * Use this endpoint to search fee types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeTypesGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTypesGetAll$Response(params?: {
    Name?: string;
    ValueNum?: number;
    Processor?: ProcessorEnum;
    Category?: CategoryCodeEnum;
    CategoryDfm?: CategoryCodeDfmEnum;
    Trigger?: TriggerEnum;
    IsEnabled?: boolean;
    CanAbsorb?: boolean;
    IsEditable?: boolean;
    ValueFormat?: ValueFormatEnum;
    OrderBy?: Array<string>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<Array<FeeTypeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeTypesGetAllPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {});
      rb.query('ValueNum', params.ValueNum, {});
      rb.query('Processor', params.Processor, {});
      rb.query('Category', params.Category, {});
      rb.query('CategoryDfm', params.CategoryDfm, {});
      rb.query('Trigger', params.Trigger, {});
      rb.query('IsEnabled', params.IsEnabled, {});
      rb.query('CanAbsorb', params.CanAbsorb, {});
      rb.query('IsEditable', params.IsEditable, {});
      rb.query('ValueFormat', params.ValueFormat, {});
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FeeTypeModel>>;
      })
    );
  }

  /**
   * Lists filtered fee types by the query.
   *
   * Use this endpoint to search fee types
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeTypesGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTypesGetAll(params?: {
    Name?: string;
    ValueNum?: number;
    Processor?: ProcessorEnum;
    Category?: CategoryCodeEnum;
    CategoryDfm?: CategoryCodeDfmEnum;
    Trigger?: TriggerEnum;
    IsEnabled?: boolean;
    CanAbsorb?: boolean;
    IsEditable?: boolean;
    ValueFormat?: ValueFormatEnum;
    OrderBy?: Array<string>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<Array<FeeTypeModel>> {

    return this.feeTypesGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FeeTypeModel>>) => r.body as Array<FeeTypeModel>)
    );
  }

  /**
   * Path part for operation feeTypesPost
   */
  static readonly FeeTypesPostPath = '/v1/admin/billing/fee-types';

  /**
   * Creates new fee type.
   *
   * Use this endpoint if you want create a new fee type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeTypesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feeTypesPost$Response(params: {

    /**
     * Fee type model
     */
    body: FeeType
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeTypesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creates new fee type.
   *
   * Use this endpoint if you want create a new fee type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeTypesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feeTypesPost(params: {

    /**
     * Fee type model
     */
    body: FeeType
  }): Observable<void> {

    return this.feeTypesPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation feeTypesGet
   */
  static readonly FeeTypesGetPath = '/v1/admin/billing/fee-types/{id}';

  /**
   * Gets detailed information about the fee type by id.
   *
   * Use this endpoint to get the detailed information about the fee type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTypesGet$Response(params: {

    /**
     * Fee plan id
     */
    id: number;
  }): Observable<StrictHttpResponse<FeeTypeModel>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeTypesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FeeTypeModel>;
      })
    );
  }

  /**
   * Gets detailed information about the fee type by id.
   *
   * Use this endpoint to get the detailed information about the fee type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTypesGet(params: {

    /**
     * Fee plan id
     */
    id: number;
  }): Observable<FeeTypeModel> {

    return this.feeTypesGet$Response(params).pipe(
      map((r: StrictHttpResponse<FeeTypeModel>) => r.body as FeeTypeModel)
    );
  }

  /**
   * Path part for operation feeTypesPut
   */
  static readonly FeeTypesPutPath = '/v1/admin/billing/fee-types/{id}';

  /**
   * Updates fee type data information.
   *
   * Use this endpoint in order to change the fee type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeTypesPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feeTypesPut$Response(params: {

    /**
     * Fee type id
     */
    id: number;

    /**
     * Fee type model
     */
    body: FeeType
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeTypesPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates fee type data information.
   *
   * Use this endpoint in order to change the fee type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeTypesPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  feeTypesPut(params: {

    /**
     * Fee type id
     */
    id: number;

    /**
     * Fee type model
     */
    body: FeeType
  }): Observable<void> {

    return this.feeTypesPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation feeTypesDelete
   */
  static readonly FeeTypesDeletePath = '/v1/admin/billing/fee-types/{id}';

  /**
   * Deletes the fee type by id.
   *
   * Use this endpoint to delete fee type by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `feeTypesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTypesDelete$Response(params: {

    /**
     * Fee type id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FeeTypesDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes the fee type by id.
   *
   * Use this endpoint to delete fee type by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `feeTypesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  feeTypesDelete(params: {

    /**
     * Fee type id
     */
    id: number;
  }): Observable<void> {

    return this.feeTypesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fundingSearchFundingResults
   */
  static readonly FundingSearchFundingResultsPath = '/v1/admin/billing/funding';

  /**
   * Search funding instructions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingSearchFundingResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingResults$Response(params?: {
    MerchantId?: number;
    MerchantProcessorId?: number;
    MID?: string;
    DBA?: string;
    FundingStatuses?: Array<FundingStatus>;
    SettlementStatuses?: Array<SettlementSummaryStatus>;
    OrderBy?: FundingResultsOrderBy;
    FundedStartDate?: string;
    FundedEndDate?: string;
    Processors?: Array<ProcessorEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfFundingResult>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingSearchFundingResultsPath, 'get');
    if (params) {
      rb.query('MerchantId', params.MerchantId, {});
      rb.query('MerchantProcessorId', params.MerchantProcessorId, {});
      rb.query('MID', params.MID, {});
      rb.query('DBA', params.DBA, {});
      rb.query('FundingStatuses', params.FundingStatuses, {"style":"form","explode":true});
      rb.query('SettlementStatuses', params.SettlementStatuses, {"style":"form","explode":true});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('FundedStartDate', params.FundedStartDate, {});
      rb.query('FundedEndDate', params.FundedEndDate, {});
      rb.query('Processors', params.Processors, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfFundingResult>;
      })
    );
  }

  /**
   * Search funding instructions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingSearchFundingResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingResults(params?: {
    MerchantId?: number;
    MerchantProcessorId?: number;
    MID?: string;
    DBA?: string;
    FundingStatuses?: Array<FundingStatus>;
    SettlementStatuses?: Array<SettlementSummaryStatus>;
    OrderBy?: FundingResultsOrderBy;
    FundedStartDate?: string;
    FundedEndDate?: string;
    Processors?: Array<ProcessorEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfFundingResult> {

    return this.fundingSearchFundingResults$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfFundingResult>) => r.body as PagedDataDtoOfFundingResult)
    );
  }

  /**
   * Path part for operation fundingManualFundingAll
   */
  static readonly FundingManualFundingAllPath = '/v1/admin/billing/funding';

  /**
   * Manually resend funding instructions using the same MID and Date properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingManualFundingAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fundingManualFundingAll$Response(params: {

    /**
     * List of existing instructions
     */
    body: ResendFundingRequest
  }): Observable<StrictHttpResponse<Array<ResentFundingInstruction>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingManualFundingAllPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResentFundingInstruction>>;
      })
    );
  }

  /**
   * Manually resend funding instructions using the same MID and Date properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingManualFundingAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fundingManualFundingAll(params: {

    /**
     * List of existing instructions
     */
    body: ResendFundingRequest
  }): Observable<Array<ResentFundingInstruction>> {

    return this.fundingManualFundingAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResentFundingInstruction>>) => r.body as Array<ResentFundingInstruction>)
    );
  }

  /**
   * Path part for operation fundingGetFundingResult
   */
  static readonly FundingGetFundingResultPath = '/v1/admin/billing/funding/{id}';

  /**
   * Get funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingGetFundingResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetFundingResult$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<FundingResultDetails>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingGetFundingResultPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FundingResultDetails>;
      })
    );
  }

  /**
   * Get funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingGetFundingResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetFundingResult(params: {
    id: number;
  }): Observable<FundingResultDetails> {

    return this.fundingGetFundingResult$Response(params).pipe(
      map((r: StrictHttpResponse<FundingResultDetails>) => r.body as FundingResultDetails)
    );
  }

  /**
   * Path part for operation fundingSearchFundingTransactions
   */
  static readonly FundingSearchFundingTransactionsPath = '/v1/admin/billing/funding/{id}/transactions';

  /**
   * Search transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingSearchFundingTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingTransactions$Response(params: {
    id: number;
    FeesOnly?: boolean;
    OrderBy?: FundingTransactionOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfFundingTransaction>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingSearchFundingTransactionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('FeesOnly', params.FeesOnly, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfFundingTransaction>;
      })
    );
  }

  /**
   * Search transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingSearchFundingTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingTransactions(params: {
    id: number;
    FeesOnly?: boolean;
    OrderBy?: FundingTransactionOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfFundingTransaction> {

    return this.fundingSearchFundingTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfFundingTransaction>) => r.body as PagedDataDtoOfFundingTransaction)
    );
  }

  /**
   * Path part for operation fundingExportFundingTransactions
   */
  static readonly FundingExportFundingTransactionsPath = '/v1/admin/billing/funding/{id}/transactions/export';

  /**
   * Export transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingExportFundingTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingExportFundingTransactions$Response(params: {
    id: number;
    feesOnly?: boolean;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingExportFundingTransactionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('feesOnly', params.feesOnly, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingExportFundingTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingExportFundingTransactions(params: {
    id: number;
    feesOnly?: boolean;
  }): Observable<Blob> {

    return this.fundingExportFundingTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation fundingGetMidFees
   */
  static readonly FundingGetMidFeesPath = '/v1/admin/billing/funding/{id}/mid-fees';

  /**
   * Get MID fees associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingGetMidFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetMidFees$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<FundingFee>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingGetMidFeesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FundingFee>>;
      })
    );
  }

  /**
   * Get MID fees associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingGetMidFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetMidFees(params: {
    id: number;
  }): Observable<Array<FundingFee>> {

    return this.fundingGetMidFees$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FundingFee>>) => r.body as Array<FundingFee>)
    );
  }

  /**
   * Path part for operation fundingGetAdjustments
   */
  static readonly FundingGetAdjustmentsPath = '/v1/admin/billing/funding/{id}/adjustments';

  /**
   * Get adjustments associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingGetAdjustments()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetAdjustments$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<AdjustmentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingGetAdjustmentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdjustmentDto>>;
      })
    );
  }

  /**
   * Get adjustments associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingGetAdjustments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetAdjustments(params: {
    id: number;
  }): Observable<Array<AdjustmentDto>> {

    return this.fundingGetAdjustments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentDto>>) => r.body as Array<AdjustmentDto>)
    );
  }

  /**
   * Path part for operation fundingManualFunding
   */
  static readonly FundingManualFundingPath = '/v1/admin/billing/funding/{originalId}';

  /**
   * Manually resend funding instruction using the same MID and Date properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingManualFunding()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingManualFunding$Response(params: {

    /**
     * Id of the existing instruction
     */
    originalId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingManualFundingPath, 'post');
    if (params) {
      rb.path('originalId', params.originalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Manually resend funding instruction using the same MID and Date properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingManualFunding$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingManualFunding(params: {

    /**
     * Id of the existing instruction
     */
    originalId: number;
  }): Observable<void> {

    return this.fundingManualFunding$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fundingUploadTestSettlementData
   */
  static readonly FundingUploadTestSettlementDataPath = '/v1/admin/billing/funding/upload-test-settlement-data';

  /**
   * Upload test settlement data.
   *
   * Upload file with test settlement data that replaces the data received from Exchange in sandbox
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingUploadTestSettlementData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  fundingUploadTestSettlementData$Response(params?: {
    body?: {
'uploadedFile'?: Blob | null;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingUploadTestSettlementDataPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload test settlement data.
   *
   * Upload file with test settlement data that replaces the data received from Exchange in sandbox
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingUploadTestSettlementData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  fundingUploadTestSettlementData(params?: {
    body?: {
'uploadedFile'?: Blob | null;
}
  }): Observable<void> {

    return this.fundingUploadTestSettlementData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fundingRequestSettlement
   */
  static readonly FundingRequestSettlementPath = '/v1/admin/billing/funding/{id}/request-settlement';

  /**
   * Manually request funding settlement.
   *
   * Request Exchange funding settlement and process it if found
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingRequestSettlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingRequestSettlement$Response(params: {
    id: number;
    SettlementDate?: string;
    Amount?: number;
  }): Observable<StrictHttpResponse<ManualFundingSettlementResult>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.FundingRequestSettlementPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('SettlementDate', params.SettlementDate, {});
      rb.query('Amount', params.Amount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualFundingSettlementResult>;
      })
    );
  }

  /**
   * Manually request funding settlement.
   *
   * Request Exchange funding settlement and process it if found
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingRequestSettlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingRequestSettlement(params: {
    id: number;
    SettlementDate?: string;
    Amount?: number;
  }): Observable<ManualFundingSettlementResult> {

    return this.fundingRequestSettlement$Response(params).pipe(
      map((r: StrictHttpResponse<ManualFundingSettlementResult>) => r.body as ManualFundingSettlementResult)
    );
  }

  /**
   * Path part for operation planCodesGetAll
   */
  static readonly PlanCodesGetAllPath = '/v1/admin/billing/plan-codes';

  /**
   * Lists filtered plan codes by query.
   *
   * Use this endpoint to search plan codes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planCodesGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  planCodesGetAll$Response(params?: {
    Code?: string;
    NetworkCodes?: Array<string>;
    TierCode?: string;
    IndustryCode?: string;
    Rate?: number;
    PerItem?: number;
    Description?: string;
    RevisionCode?: string;
    OrderBy?: Array<string>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<Array<PlanCodeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.PlanCodesGetAllPath, 'get');
    if (params) {
      rb.query('Code', params.Code, {});
      rb.query('NetworkCodes', params.NetworkCodes, {"style":"form","explode":true});
      rb.query('TierCode', params.TierCode, {});
      rb.query('IndustryCode', params.IndustryCode, {});
      rb.query('Rate', params.Rate, {});
      rb.query('PerItem', params.PerItem, {});
      rb.query('Description', params.Description, {});
      rb.query('RevisionCode', params.RevisionCode, {});
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlanCodeModel>>;
      })
    );
  }

  /**
   * Lists filtered plan codes by query.
   *
   * Use this endpoint to search plan codes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planCodesGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planCodesGetAll(params?: {
    Code?: string;
    NetworkCodes?: Array<string>;
    TierCode?: string;
    IndustryCode?: string;
    Rate?: number;
    PerItem?: number;
    Description?: string;
    RevisionCode?: string;
    OrderBy?: Array<string>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<Array<PlanCodeModel>> {

    return this.planCodesGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanCodeModel>>) => r.body as Array<PlanCodeModel>)
    );
  }

  /**
   * Path part for operation planCodesPost
   */
  static readonly PlanCodesPostPath = '/v1/admin/billing/plan-codes';

  /**
   * Creates new plan code.
   *
   * Use this endpoint if you want create a new plan code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planCodesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planCodesPost$Response(params: {

    /**
     * Plan code model
     */
    body: PlanCode
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.PlanCodesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Creates new plan code.
   *
   * Use this endpoint if you want create a new plan code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planCodesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planCodesPost(params: {

    /**
     * Plan code model
     */
    body: PlanCode
  }): Observable<number> {

    return this.planCodesPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation planCodesGet
   */
  static readonly PlanCodesGetPath = '/v1/admin/billing/plan-codes/{id}';

  /**
   * Gets detailed information about the plan code by id.
   *
   * Use this endpoint to get the detailed information about the plan code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planCodesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  planCodesGet$Response(params: {

    /**
     * Plan code id
     */
    id: number;
  }): Observable<StrictHttpResponse<PlanCodeModel>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.PlanCodesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanCodeModel>;
      })
    );
  }

  /**
   * Gets detailed information about the plan code by id.
   *
   * Use this endpoint to get the detailed information about the plan code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planCodesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planCodesGet(params: {

    /**
     * Plan code id
     */
    id: number;
  }): Observable<PlanCodeModel> {

    return this.planCodesGet$Response(params).pipe(
      map((r: StrictHttpResponse<PlanCodeModel>) => r.body as PlanCodeModel)
    );
  }

  /**
   * Path part for operation planCodesPut
   */
  static readonly PlanCodesPutPath = '/v1/admin/billing/plan-codes/{id}';

  /**
   * Updates plan code data information.
   *
   * Use this endpoint in order to change plan code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planCodesPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planCodesPut$Response(params: {

    /**
     * Plan code id
     */
    id: number;

    /**
     * Plan code model
     */
    body: PlanCode
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.PlanCodesPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates plan code data information.
   *
   * Use this endpoint in order to change plan code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planCodesPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planCodesPut(params: {

    /**
     * Plan code id
     */
    id: number;

    /**
     * Plan code model
     */
    body: PlanCode
  }): Observable<void> {

    return this.planCodesPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation planCodesDelete
   */
  static readonly PlanCodesDeletePath = '/v1/admin/billing/plan-codes/{id}';

  /**
   * Deletes the plan code by id.
   *
   * Use this endpoint to delete plan code by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planCodesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  planCodesDelete$Response(params: {

    /**
     * Plan code id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.PlanCodesDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes the plan code by id.
   *
   * Use this endpoint to delete plan code by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planCodesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planCodesDelete(params: {

    /**
     * Plan code id
     */
    id: number;
  }): Observable<void> {

    return this.planCodesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation planCodesUpload
   */
  static readonly PlanCodesUploadPath = '/v1/admin/billing/plan-codes/upload';

  /**
   * Uploads prepared plan codes .csv file.
   *
   * Use this endpoint in order to upload plan code list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planCodesUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  planCodesUpload$Response(params?: {
    body?: {
'file'?: Blob | null;
}
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.PlanCodesUploadPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Uploads prepared plan codes .csv file.
   *
   * Use this endpoint in order to upload plan code list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planCodesUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  planCodesUpload(params?: {
    body?: {
'file'?: Blob | null;
}
  }): Observable<number> {

    return this.planCodesUpload$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation reportTransactionReport
   */
  static readonly ReportTransactionReportPath = '/v1/admin/billing/report/transaction-report';

  /**
   * Get transaction report.
   *
   * Returns list of transactions with fees data grouped by category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportTransactionReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportTransactionReport$Response(params?: {
    MerchantIds?: Array<number>;
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.ReportTransactionReportPath, 'get');
    if (params) {
      rb.query('MerchantIds', params.MerchantIds, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('MerchantProcessorIds', params.MerchantProcessorIds, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Get transaction report.
   *
   * Returns list of transactions with fees data grouped by category
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportTransactionReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportTransactionReport(params?: {
    MerchantIds?: Array<number>;
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<Blob> {

    return this.reportTransactionReport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation reportFundingReport
   */
  static readonly ReportFundingReportPath = '/v1/admin/billing/report/funding-report';

  /**
   * Get funding report.
   *
   * Returns list of funding instructions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportFundingReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFundingReport$Response(params?: {
    MerchantIds?: Array<number>;
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.ReportFundingReportPath, 'get');
    if (params) {
      rb.query('MerchantIds', params.MerchantIds, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('MerchantProcessorIds', params.MerchantProcessorIds, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Get funding report.
   *
   * Returns list of funding instructions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportFundingReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFundingReport(params?: {
    MerchantIds?: Array<number>;
    StartDate?: string;
    EndDate?: string;
    MerchantProcessorIds?: Array<number>;
  }): Observable<Blob> {

    return this.reportFundingReport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation reportStatementReports
   */
  static readonly ReportStatementReportsPath = '/v1/admin/billing/report/by-merchant/{merchantId}/statement-reports/{id}';

  /**
   * Get statement report by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportStatementReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReports$Response(params: {
    merchantId: number;
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.ReportStatementReportsPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Get statement report by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportStatementReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReports(params: {
    merchantId: number;
    id: string;
  }): Observable<Blob> {

    return this.reportStatementReports$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation reportStatementReportsAll
   */
  static readonly ReportStatementReportsAllPath = '/v1/admin/billing/report/by-merchant/{merchantId}/statement-reports/{year}/{month}';

  /**
   * Get statement report by date.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportStatementReportsAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReportsAll$Response(params: {
    merchantId: number;
    year: number;
    month: Month;
  }): Observable<StrictHttpResponse<Array<StatementReportItem>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.ReportStatementReportsAllPath, 'get');
    if (params) {
      rb.path('merchantId', params.merchantId, {});
      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatementReportItem>>;
      })
    );
  }

  /**
   * Get statement report by date.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportStatementReportsAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportStatementReportsAll(params: {
    merchantId: number;
    year: number;
    month: Month;
  }): Observable<Array<StatementReportItem>> {

    return this.reportStatementReportsAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatementReportItem>>) => r.body as Array<StatementReportItem>)
    );
  }

  /**
   * Path part for operation reportFundingReconciliation
   */
  static readonly ReportFundingReconciliationPath = '/v1/admin/billing/report/funding-reconciliation';

  /**
   * Get funding reconciliation report.
   *
   * Returns funding reconciliation for the MID and date. Contains the list of amounts with difference between Exchange
   * and VituPay data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportFundingReconciliation()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFundingReconciliation$Response(params?: {
    merchantId?: number;
    MerchantProcessorId?: number;
    CreatedDate?: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.ReportFundingReconciliationPath, 'get');
    if (params) {
      rb.query('merchantId', params.merchantId, {});
      rb.query('MerchantProcessorId', params.MerchantProcessorId, {});
      rb.query('CreatedDate', params.CreatedDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Get funding reconciliation report.
   *
   * Returns funding reconciliation for the MID and date. Contains the list of amounts with difference between Exchange
   * and VituPay data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportFundingReconciliation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportFundingReconciliation(params?: {
    merchantId?: number;
    MerchantProcessorId?: number;
    CreatedDate?: string;
  }): Observable<Blob> {

    return this.reportFundingReconciliation$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation reportDetailedFeeReconciliationReport
   */
  static readonly ReportDetailedFeeReconciliationReportPath = '/v1/admin/billing/report/detailed-fee-reconciliation-report';

  /**
   * Provides detailed fee reconciliation report.
   *
   * Use endpoint for debugging any problems with fees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportDetailedFeeReconciliationReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportDetailedFeeReconciliationReport$Response(params?: {

    /**
     * Merchant id
     */
    merchantId?: number;
    Year?: number;
    Month?: number;
    Mid?: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.ReportDetailedFeeReconciliationReportPath, 'get');
    if (params) {
      rb.query('merchantId', params.merchantId, {});
      rb.query('Year', params.Year, {});
      rb.query('Month', params.Month, {});
      rb.query('Mid', params.Mid, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Provides detailed fee reconciliation report.
   *
   * Use endpoint for debugging any problems with fees
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportDetailedFeeReconciliationReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportDetailedFeeReconciliationReport(params?: {

    /**
     * Merchant id
     */
    merchantId?: number;
    Year?: number;
    Month?: number;
    Mid?: string;
  }): Observable<Blob> {

    return this.reportDetailedFeeReconciliationReport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation revisionsGetAll
   */
  static readonly RevisionsGetAllPath = '/v1/admin/billing/revisions';

  /**
   * Gets revisions by query.
   *
   * Use this endpoint to search revisions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revisionsGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  revisionsGetAll$Response(params?: {
    Name?: string;
    StartUtc?: string;
    EndUtc?: string;
    OrderBy?: Array<string>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<Array<RevisionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.RevisionsGetAllPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {});
      rb.query('StartUtc', params.StartUtc, {});
      rb.query('EndUtc', params.EndUtc, {});
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RevisionModel>>;
      })
    );
  }

  /**
   * Gets revisions by query.
   *
   * Use this endpoint to search revisions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revisionsGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revisionsGetAll(params?: {
    Name?: string;
    StartUtc?: string;
    EndUtc?: string;
    OrderBy?: Array<string>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<Array<RevisionModel>> {

    return this.revisionsGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RevisionModel>>) => r.body as Array<RevisionModel>)
    );
  }

  /**
   * Path part for operation revisionsPost
   */
  static readonly RevisionsPostPath = '/v1/admin/billing/revisions';

  /**
   * Creates new revision.
   *
   * Use this endpoint if you want create a new revision
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revisionsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revisionsPost$Response(params: {

    /**
     * Revision model
     */
    body: Revision
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.RevisionsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Creates new revision.
   *
   * Use this endpoint if you want create a new revision
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revisionsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revisionsPost(params: {

    /**
     * Revision model
     */
    body: Revision
  }): Observable<number> {

    return this.revisionsPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation revisionsGet
   */
  static readonly RevisionsGetPath = '/v1/admin/billing/revisions/{id}';

  /**
   * Gets detailed information about the revision by id.
   *
   * Use this endpoint to get the detailed information about the revision
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revisionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  revisionsGet$Response(params: {

    /**
     * Revision id
     */
    id: number;
  }): Observable<StrictHttpResponse<RevisionModel>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.RevisionsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RevisionModel>;
      })
    );
  }

  /**
   * Gets detailed information about the revision by id.
   *
   * Use this endpoint to get the detailed information about the revision
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revisionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revisionsGet(params: {

    /**
     * Revision id
     */
    id: number;
  }): Observable<RevisionModel> {

    return this.revisionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<RevisionModel>) => r.body as RevisionModel)
    );
  }

  /**
   * Path part for operation revisionsPut
   */
  static readonly RevisionsPutPath = '/v1/admin/billing/revisions/{id}';

  /**
   * Updates revision data information.
   *
   * Use this endpoint in order to change revision name, start and end effective dates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revisionsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revisionsPut$Response(params: {

    /**
     * Revision id
     */
    id: number;

    /**
     * Revision model
     */
    body: Revision
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.RevisionsPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates revision data information.
   *
   * Use this endpoint in order to change revision name, start and end effective dates
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revisionsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revisionsPut(params: {

    /**
     * Revision id
     */
    id: number;

    /**
     * Revision model
     */
    body: Revision
  }): Observable<void> {

    return this.revisionsPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation revisionsDelete
   */
  static readonly RevisionsDeletePath = '/v1/admin/billing/revisions/{id}';

  /**
   * Deletes the revision by id.
   *
   * Use this endpoint to delete revision by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revisionsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  revisionsDelete$Response(params: {

    /**
     * Revision id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.RevisionsDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes the revision by id.
   *
   * Use this endpoint to delete revision by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revisionsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revisionsDelete(params: {

    /**
     * Revision id
     */
    id: number;
  }): Observable<void> {

    return this.revisionsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation tiersGetAll
   */
  static readonly TiersGetAllPath = '/v1/admin/billing/tiers';

  /**
   * Gets all available tiers for plan codes.
   *
   * This endpoint returns all available tiers that can be used in plan codes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tiersGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  tiersGetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<TierModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.TiersGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TierModel>>;
      })
    );
  }

  /**
   * Gets all available tiers for plan codes.
   *
   * This endpoint returns all available tiers that can be used in plan codes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tiersGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tiersGetAll(params?: {
  }): Observable<Array<TierModel>> {

    return this.tiersGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TierModel>>) => r.body as Array<TierModel>)
    );
  }

  /**
   * Path part for operation tiersPost
   */
  static readonly TiersPostPath = '/v1/admin/billing/tiers';

  /**
   * Creates new tier.
   *
   * Use this endpoint if you want create a new tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tiersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tiersPost$Response(params: {

    /**
     * Tier model
     */
    body: Tier
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.TiersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Creates new tier.
   *
   * Use this endpoint if you want create a new tier
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tiersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tiersPost(params: {

    /**
     * Tier model
     */
    body: Tier
  }): Observable<number> {

    return this.tiersPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation tiersGet
   */
  static readonly TiersGetPath = '/v1/admin/billing/tiers/{id}';

  /**
   * Gets detailed information about the tier by id.
   *
   * Use this endpoint to get the detailed information about the tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tiersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tiersGet$Response(params: {

    /**
     * Tier id
     */
    id: number;
  }): Observable<StrictHttpResponse<TierModel>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.TiersGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TierModel>;
      })
    );
  }

  /**
   * Gets detailed information about the tier by id.
   *
   * Use this endpoint to get the detailed information about the tier
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tiersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tiersGet(params: {

    /**
     * Tier id
     */
    id: number;
  }): Observable<TierModel> {

    return this.tiersGet$Response(params).pipe(
      map((r: StrictHttpResponse<TierModel>) => r.body as TierModel)
    );
  }

  /**
   * Path part for operation tiersPut
   */
  static readonly TiersPutPath = '/v1/admin/billing/tiers/{id}';

  /**
   * Updates tier data information.
   *
   * Use this endpoint in order to change the tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tiersPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tiersPut$Response(params: {

    /**
     * Tier id
     */
    id: number;

    /**
     * Tier model
     */
    body: Tier
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.TiersPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates tier data information.
   *
   * Use this endpoint in order to change the tier
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tiersPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tiersPut(params: {

    /**
     * Tier id
     */
    id: number;

    /**
     * Tier model
     */
    body: Tier
  }): Observable<void> {

    return this.tiersPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation tiersDelete
   */
  static readonly TiersDeletePath = '/v1/admin/billing/tiers/{id}';

  /**
   * Deletes the tier by id.
   *
   * Use this endpoint to delete the tier by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tiersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  tiersDelete$Response(params: {

    /**
     * Tier id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.TiersDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes the tier by id.
   *
   * Use this endpoint to delete the tier by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tiersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tiersDelete(params: {

    /**
     * Tier id
     */
    id: number;
  }): Observable<void> {

    return this.tiersDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation valueFormatsGet
   */
  static readonly ValueFormatsGetPath = '/v1/admin/billing/value-formats';

  /**
   * Provides an information about available value formats for fees.
   *
   * Use this endpoint in order to get the information about all available value formats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `valueFormatsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueFormatsGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Lookup>>> {

    const rb = new RequestBuilder(this.rootUrl, BillingService.ValueFormatsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lookup>>;
      })
    );
  }

  /**
   * Provides an information about available value formats for fees.
   *
   * Use this endpoint in order to get the information about all available value formats
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `valueFormatsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  valueFormatsGet(params?: {
  }): Observable<Array<Lookup>> {

    return this.valueFormatsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Lookup>>) => r.body as Array<Lookup>)
    );
  }

}
