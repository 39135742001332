import { OrganizationHierarchyModeEnum } from "@admin_api/models/organization-hierarchy-mode-enum";
import { createFilter, Pager } from "shared-lib";
import { ActionTypes } from "./organizations.actions";
import { Filter, OrganizationsState, Sorting } from "./organizations.state";

export const OrganizationsReducer = (
    state: OrganizationsState = new OrganizationsState(),
    action: any,
  ): OrganizationsState => {

    switch (action.type) {

        case ActionTypes.ResetOrganizations: {
            const newState = {
                ...state,
                loading: false,
                error: null,
                record: [],
                sorting: new Sorting(),
                pager: new Pager(),
                filter: createFilter(Filter)
            };
            // Bit klunky hacking the initial filter here ...
            newState.filter.hierarchyMode = action.hierarchyMode;
            newState.filter.hierarchyOrganizationId = action.hierarchyOrganizationId;
            newState.filter.merchantsOnly = action.merchantsOnly;
            newState.filter._initialFilter.hierarchyMode = action.hierarchyMode;
            newState.filter._initialFilter.hierarchyOrganizationId = action.hierarchyOrganizationId;
            newState.filter._initialFilter.merchantsOnly = action.merchantsOnly;
            return newState;
        }

        case ActionTypes.GetOrganizations: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetOrganizationsSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            return {
                ...state,
                record: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetOrganizationsFail: {
            return {
                ...state,
                record: [],
                loading: false,
                error: action.error
            };
        }

        case ActionTypes.SortOrganizations: {
            return {
                ...state,
                sorting: action.sorting
            };
        }

        case ActionTypes.FilterOrganizations: {
            return {
                ...state,
                filter: action.filter
            };
        }

        default: {
            return state;
        }
    }

};
