import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getAccountState = (store: IStore) => store.account;

export const getAccountInfo = createSelector(
    getAccountState,
    accountState => accountState.account
);

export const getAccountLoading = createSelector(
    getAccountState,
    accountState => accountState.loading
);

export const getAccountMerchantProcessors = createSelector(
    getAccountState,
    accountState => accountState.merchantProcessors
);

export const getAccountMerchantProcessorsLoading = createSelector(
    getAccountState,
    accountState => accountState.merchantProcessorsLoading
);

export const getAccountMerchantProcessorsError = createSelector(
    getAccountState,
    accountState => accountState.merchantProcessorsError
);
