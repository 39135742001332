<app-funding-item-details
    [fundingItem]="fundingItem$ | async"
    [fundingItemLoading]="fundingItemLoading$ | async"
    [fundingItemError]="fundingItemError$ | async"
    [fundingItemTransactions]="fundingItemTransactions$ | async"
    [fundingItemTransactionsLoading]="fundingItemTransactionsLoading$ | async"
    [fundingItemTransactionsError]="fundingItemTransactionsError$ | async"
    [fundingItemExportLoading]="fundingItemExportLoading$ | async"
    [transactionsPager]="fundingItemTransactionsPager$ | async"
    (transactionsPageChanged)="onFundingItemTransactionsPageChanged($event)"
    [transactionsSorting]="fundingItemTransactionsSorting$ | async"
    (transactionsSortingChanged)="onFundingItemTransactionsSortingChanged($event)"
    [transactionsFilter]="fundingItemTransactionsFilter$ | async"
    (transactionsFilterChanged)="onFundingItemTransactionsFilterChanged($event)"
    (exportTransactionsToCsv)="onExportTransactionsToCsv($event)"
    [fundingItemMidFees]="fundingItemMidFees$ | async"
    [fundingItemMidFeesLoading]="fundingItemMidFeesLoading$ | async"
    [fundingItemMidFeesError]="fundingItemMidFeesError$ | async"
    [fundingItemAdjustments]="fundingItemAdjustments$ | async"
    [fundingItemAdjustmentsLoading]="fundingItemAdjustmentsLoading$ | async"
    [fundingItemAdjustmentsError]="fundingItemAdjustmentsError$ | async"
    (cancel)="onCancel()"
    (resend)="onResend($event)"
    (requestSettlement)="onRequestSettlement($event)">
</app-funding-item-details>
