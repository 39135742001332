import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { ActivatedRoute } from "@angular/router";
import * as AccountActions from "@admin_app/storage/account/account.actions";
import * as AccountSelectors from "@admin_app/storage/account/account.selectors";
import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";
import { MerchantProcessorAccountData } from "@admin_api/models/merchant-processor-account-data";

@Component({
    selector: "app-account",
    templateUrl: "./account.component.html",
    styleUrls: ["./account.component.less"]
})
export class AccountComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    account$ = this.store.select(AccountSelectors.getAccountInfo);
    loading$ = this.store.select(AccountSelectors.getAccountLoading);

    accountMerchantProcessors$ = this.store.select(AccountSelectors.getAccountMerchantProcessors);
    accountMerchantProcessorsLoading$ = this.store.select(AccountSelectors.getAccountMerchantProcessorsLoading);
    accountMerchantProcessorsError$ = this.store.select(AccountSelectors.getAccountMerchantProcessorsError);

    isCreate: boolean;

    ngOnInit() {

        this.isCreate = !this.route.snapshot.params.id;

        if (this.isCreate) {
            this.store.dispatch(new AccountActions.GetNewAccountAction());
        }
        else {
            this.store.dispatch(new AccountActions.GetAccountAction(this.route.snapshot.params.id));
        }
    }

    updateAccount(payload: {id: number; account: MerchantProcessorAccountData}) {

        this.store.dispatch(new AccountActions.UpdateAccountAction(payload.id, payload.account));
    }

    createAccount(account: MerchantProcessorAccountData) {

        this.store.dispatch(new AccountActions.CreateAccountAction(account));
    }

    deleteAccount(account: MerchantProcessorAccountDto) {

        this.store.dispatch(new AccountActions.DeleteAccountAction(account.id));
    }

    onBack(): void {

        this.store.dispatch(GoBackAction());
    }

}
