/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangePosTerminalStatusDto } from '../models/change-pos-terminal-status-dto';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfMerchantTerminalDto } from '../models/paged-data-dto-of-merchant-terminal-dto';
import { PosTerminalOrderBy } from '../models/pos-terminal-order-by';
import { PosTerminalStatusEnum } from '../models/pos-terminal-status-enum';


/**
 * API to manage POS devices.
 */
@Injectable({
  providedIn: 'root',
})
export class InventoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inventorySearch
   */
  static readonly InventorySearchPath = '/v1/admin/inventory';

  /**
   * Returns a list of Clover POS devices and their configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventorySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventorySearch$Response(params?: {
    MerchantId?: number;
    MerchantName?: string;
    MID?: string;
    TID?: string;
    DeviceSerial?: string;
    DeviceName?: string;
    Statuses?: Array<PosTerminalStatusEnum>;
    OrderBy?: PosTerminalOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfMerchantTerminalDto>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.InventorySearchPath, 'get');
    if (params) {
      rb.query('MerchantId', params.MerchantId, {});
      rb.query('MerchantName', params.MerchantName, {});
      rb.query('MID', params.MID, {});
      rb.query('TID', params.TID, {});
      rb.query('DeviceSerial', params.DeviceSerial, {});
      rb.query('DeviceName', params.DeviceName, {});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfMerchantTerminalDto>;
      })
    );
  }

  /**
   * Returns a list of Clover POS devices and their configuration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inventorySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventorySearch(params?: {
    MerchantId?: number;
    MerchantName?: string;
    MID?: string;
    TID?: string;
    DeviceSerial?: string;
    DeviceName?: string;
    Statuses?: Array<PosTerminalStatusEnum>;
    OrderBy?: PosTerminalOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfMerchantTerminalDto> {

    return this.inventorySearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfMerchantTerminalDto>) => r.body as PagedDataDtoOfMerchantTerminalDto)
    );
  }

  /**
   * Path part for operation inventoryChangeStatus
   */
  static readonly InventoryChangeStatusPath = '/v1/admin/inventory/{terminalId}/change-status';

  /**
   * Changes Clover POS status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryChangeStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryChangeStatus$Response(params: {

    /**
     * Terminal identifier
     */
    terminalId: number;
    body: ChangePosTerminalStatusDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryService.InventoryChangeStatusPath, 'put');
    if (params) {
      rb.path('terminalId', params.terminalId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changes Clover POS status.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inventoryChangeStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryChangeStatus(params: {

    /**
     * Terminal identifier
     */
    terminalId: number;
    body: ChangePosTerminalStatusDto
  }): Observable<void> {

    return this.inventoryChangeStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
