import { FundingResultsOrderBy } from "@admin_api/models/funding-results-order-by";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-funding-table-cell-content",
    templateUrl: "./funding-table-cell-content.component.html",
    styleUrls: ["./funding-table-cell-content.component.less"],
})
export class FundingTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

    FundingResultsOrderBy = FundingResultsOrderBy;

}
