import { createAction, props } from "@ngrx/store";
import { TransactionDetailDto } from "@admin_api/models/transaction-detail-dto";

export const GetTransactionDetailAction = createAction(
    "[Transaction] Get Transaction Detail",
    props<{
        token: string;
        getRelations: boolean;
    }>(),
);

export const GetTransactionDetailSucceedAction = createAction(
    "[Transaction] Get Transaction Detail Succeed",
    props<{
        record: TransactionDetailDto;
        getRelations: boolean;
    }>(),
);

export const GetTransactionDetailFailAction = createAction(
    "[Transaction] Get Transaction Detail Fail",
    props<{
        error: any;
    }>(),
);

export const UpdateTransactionDetailAction = createAction(
    "[Transaction] Update Transaction Detail",
    props<{ record: TransactionDetailDto }>(),
);

export const DownloadReceiptAction = createAction(
    "[Transaction] Download Receipt",
    props<{ transaction: TransactionDetailDto }>(),
);
