import { ConfigDto } from "@admin_app/storage/config/config.state";
import { Component, Input } from "@angular/core";
import { OrganizationOrderBy } from "@admin_api/models/organization-order-by";

@Component({
    selector: "app-organizations-table-cell-content",
    templateUrl: "./organizations-table-cell-content.component.html",
    styleUrls: ["./organizations-table-cell-content.component.less"],
})
export class OrganizationsTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;
    @Input() config: ConfigDto;

    OrganizationOrderBy = OrganizationOrderBy;

    constructor() {}

    getDisplayTextForContactEmail(): string {
        let text = "";
        if (Array.isArray(this.element?.contacts) && this.element.contacts.length) {
            text = this.element.contacts[0].email;
        }
        return text;
    }

    getDisplayTextForAddress(): string {
        let text = "";
        if (this.element?.address) {
            text += this.addAddressFieldAppendage(this.element.address.city);
            text += this.addAddressFieldAppendage(this.element.address.state);
            text = this.removeTrailingCommaSpace(text);
        }
        return text;
    }

    getDisplayTextForSolution(): string {
        let text = "";
        if (this.element?.solution && this.config?.solutionLookup) {
            const found = this.config.solutionLookup.filter(item => (item.id === this.element.solution));
            if (found?.length) {
                text = found[0].name;
            }
        }
        return text;
    }

    private addAddressFieldAppendage(appendage: string): string {
        let retVal = "";
        if (appendage) {
            retVal += `${appendage}, `;
        }
        return retVal;
    }

    private removeTrailingCommaSpace(base: string): string {
        let retVal = base;
        if (base?.length >= 2) {
            if (base.lastIndexOf(", ") === (base.length - 2)) {
                retVal = base.substring(0, base.length - 2);
            }
        }
        return retVal;
    }

}
