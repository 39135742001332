import { PayPalEventsState } from "./paypal-events.state";
import {
    ActionTypes,
} from "./paypal-events.actions";

export const PayPalEventsReducer = (state: PayPalEventsState = new PayPalEventsState(), action: any): PayPalEventsState => {

    switch (action.type) {

        case ActionTypes.FilterPayPalEvents:
        case ActionTypes.SortPayPalEvents:
        case ActionTypes.PageChangedPayPalEvents:
        case ActionTypes.GetPayPalEvents: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetPayPalEventsSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            return {
                ...state,
                rows: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetPayPalEventsFail: {
            return {
                ...state,
                rows: [],
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }

    }

};
