import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getBinFilesState = (store: IStore) => store.binFiles;

export const getBinFiles = createSelector(
    getBinFilesState,
    binFilesState => binFilesState.record,
);

export const getBinFilesLoading = createSelector(
    getBinFilesState,
    binFilesState => binFilesState.loading,
);

export const getBinFilesError = createSelector(
    getBinFilesState,
    binFilesState => binFilesState.error,
);

export const getBinFilesSorting = createSelector(
    getBinFilesState,
    binFilesState => binFilesState.sorting,
);

export const getBinFilesFilter = createSelector(
    getBinFilesState,
    binFilesState => binFilesState.filter,
);

export const getBinFilesPager = createSelector(
    getBinFilesState,
    binFilesState => binFilesState.pager,
);
