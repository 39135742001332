import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { ActionTypes, FilterMidsAction, GetAssociatedMidsAction, GetAssociatedMidsFailAction, GetAssociatedMidsSucceedAction,
    GetMidsAction, GetMidsFailAction, GetMidsMergeAction, GetMidsSucceedAction, GetServiceFeeMidsAction, GetServiceFeeMidsFailAction,
    GetServiceFeeMidsSucceedAction } from "./mids.actions";
import { of } from "rxjs";
import { MerchantsService } from "@admin_api/services/merchants.service";
import { PagerLargeSinglePageSize } from "../common";
import { Filter } from "./mids.state";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { getMidsState } from "./mids.selectors";
import { DeletedItemsInclusionEnum } from "@admin_api/models/deleted-items-inclusion-enum";
import { createFilter } from "shared-lib";

@Injectable()
export class MidsEffects {

    constructor(
        private actions$: Actions,
        private merchantsService: MerchantsService,
        private store: Store<IStore>
    ) {}

    filterMids = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterMidsAction>(ActionTypes.FilterMids),
            switchMap(action => of(new GetMidsAction(action.merchantId, action.filter)))
        ),
    );

    getMids$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMidsAction>(ActionTypes.GetMids),
            withLatestFrom(this.store.select(getMidsState)),
            switchMap(([action, state]) => {

                // If no filter passed => clear the filter
                const filter = action.filter ? action.filter : /*state.filter*/createFilter(Filter);
                const params = this.getParams(action.merchantId, filter);

                const stateExtensions = {
                    filter
                };

                return this.merchantsService.merchantProcessorsSearch(params)
                    .pipe(
                        switchMap((mids) => of(new GetMidsSucceedAction(mids, stateExtensions))),
                        catchError((error) => of(new GetMidsFailAction(error))
                    )
                );
            })
        )
    );

    // Allows multiple requests to go out (without cancelling previous one)
    getMidsMerge$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMidsMergeAction>(ActionTypes.GetMidsMerge),
            mergeMap((action) =>
                this.merchantsService.merchantProcessorsSearch({
                    merchantId: action.merchantId,
                    "Pager.PageSize": PagerLargeSinglePageSize }).pipe(
                        switchMap((mids) => of(new GetMidsSucceedAction(mids))),
                        catchError((error) => of(new GetMidsFailAction(error)))
                ))
        )
    );

    getServiceFeeMids$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetServiceFeeMidsAction>(ActionTypes.GetServiceFeeMids),
            switchMap((action) =>
                this.merchantsService.merchantProcessorsSearch({
                    merchantId: action.merchantId,
                    ServiceFee: true,
                    "Pager.PageSize": PagerLargeSinglePageSize }).pipe(
                        switchMap((mids) => of(new GetServiceFeeMidsSucceedAction(mids))),
                        catchError((error) => of(new GetServiceFeeMidsFailAction(error)))
                ))
        )
    );

    getAssociatedMids$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAssociatedMidsAction>(ActionTypes.GetAssociatedMids),
            switchMap((action) =>
                this.merchantsService.merchantProcessorsSearch({
                    merchantId: action.merchantId,
                    ServiceFeeMidId: action.serviceFeeMidId,
                    "Pager.PageSize": PagerLargeSinglePageSize }).pipe(
                        switchMap((mids) => of(new GetAssociatedMidsSucceedAction(mids))),
                        catchError((error) => of(new GetAssociatedMidsFailAction(error)))
                ))
        )
    );

    private getParams(merchantId: number, filter?: Filter): any {

        const retVal: any = {
            merchantId,
            "Pager.PageSize": PagerLargeSinglePageSize
        };

        if (filter?.localShowDeleted === "Yes") {
            retVal.DeletedItems = DeletedItemsInclusionEnum.Exclusive;
        }

        return retVal;
    }

}
