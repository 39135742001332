<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    suppressInitialRequest>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="merchantId"
                        isFilter
                        label="Merchant Name"
                        labelEmpty="No Merchants"
                        [labelEmptyCondition]="noMerchants"
                        [options]="merchants"
                        optionLabelKey="name"
                        optionValueKey="id"
                        [showReset]="filtersBase.resetFieldVisible('merchantId')"
                        (handleReset)="filtersBase.resetField('merchantId')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="mid"
                        label="MID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('mid')"
                        (handleReset)="filtersBase.resetField('mid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="tid"
                        label="TID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('tid')"
                        (handleReset)="filtersBase.resetField('tid')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="deviceSerial"
                        label="Device Serial"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('deviceSerial')"
                        (handleReset)="filtersBase.resetField('deviceSerial')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="deviceName"
                        label="Device Name"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('deviceName')"
                        (handleReset)="filtersBase.resetField('deviceName')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="statuses"
                        isFilter
                        multiple
                        label="Device Status"
                        [options]="deviceStatusList"
                        [showReset]="filtersBase.resetFieldVisible('statuses')"
                        (handleReset)="filtersBase.resetField('statuses')"
                        [spaceBetweenCapsOnLabels]="true">
                    </lib-vitu-form-field-select>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
