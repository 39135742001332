import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigationAction } from "@ngrx/router-store";
import { catchError, filter as filterOperator, map, switchMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import {
    ActionTypes,
    FilterFundingAction,
    GetFundingAction,
    GetFundingSucceedAction,
    GetFundingFailAction,
    PageChangedFundingAction,
    SortFundingAction
} from "./funding.actions";
import { BillingService } from "@admin_api/services/billing.service";
import { getFundingState } from "./funding.selectors";
import { Filter, Sorting } from "./funding.state";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { LocalTimePoint } from "shared-lib";

@Injectable()
export class FundingEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService,
        private store: Store<IStore>
    ) {}

    initFundingPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigationAction) =>
                new RegExp(/dashboard\/funding\/funding-history$/).test(action.payload.routerState.url)),
            map(() => new GetFundingAction(1))
        )
    );

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortFundingAction>(ActionTypes.SortFunding),
            switchMap(action => of(new GetFundingAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterFundingAction>(ActionTypes.FilterFunding),
            switchMap(action => of(new GetFundingAction(1, undefined, action.filter)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedFundingAction>(ActionTypes.PageChangedFunding),
            switchMap(action => of(new GetFundingAction(action.page)))
        ),
    );

    getFunding = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingAction>(ActionTypes.GetFunding),
            withLatestFrom(this.store.select(getFundingState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.billingService.fundingSearchFundingResults(params).pipe(
                    switchMap((response) =>
                        of(new GetFundingSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) =>
                        of(new GetFundingFailAction(error))
                    )
                );
            })
        )
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                MerchantId: filter.merchantId,
                MID: filter.mid,
                DBA: filter.dba,
                FundingStatuses: filter.fundingStatuses,
                Processors: filter.processors
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            FundedStartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localFundedDateInterval?.from),
            FundedEndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localFundedDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }


}
