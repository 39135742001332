import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getMerchantHistoryState = (store: IStore) => store.merchantHistory;

export const getMerchantHistory = createSelector(
    getMerchantHistoryState,
    state => state.record
);

export const getMerchantHistoryLoading = createSelector(
    getMerchantHistoryState,
    state => state.loading
);

export const getMerchantHistoryError = createSelector(
    getMerchantHistoryState,
    state => state.error
);

export const getMerchantHistorySorting = createSelector(
    getMerchantHistoryState,
    state => state.sorting
);

export const getMerchantHistoryFilter = createSelector(
    getMerchantHistoryState,
    state => state.filter
);

export const getMerchantHistoryPager = createSelector(
    getMerchantHistoryState,
    state => state.pager
);
