import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigationAction } from "@ngrx/router-store";
import { filter as filterOperator, switchMap, withLatestFrom, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { IStore } from "../store";
import { Store } from "@ngrx/store";
import {
    LocalTimePoint} from "shared-lib";
import { Filter, Sorting } from "./paypal-events.state";
import { getPayPalEventsState } from "./paypal-events.selectors";
import {
    ActionTypes,
    PageChangedPayPalEventsAction,
    FilterPayPalEventsAction,
    GetPayPalEventsAction,
    GetPayPalEventsSucceedAction,
    SortPayPalEventsAction,
    GetPayPalEventsFailAction

} from "./paypal-events.actions";
import { PayPalEventDeliveriesService } from "@admin_api/services/pay-pal-event-deliveries.service";

@Injectable()
export class PayPalEventsEffects {

    constructor(
        private actions: Actions,
        private payPalEventsService: PayPalEventDeliveriesService,
        private store: Store<IStore>
    ) { }

    initEventDeliveriesPage = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigationAction) =>
                new RegExp(/dashboard\/webhooks\/paypal-events$/).test(action.payload.routerState.url)),
            switchMap(() =>
                [new GetPayPalEventsAction(1)]
            )
        )
    );

    filterList = createEffect(() =>
        this.actions.pipe(
            ofType<FilterPayPalEventsAction>(ActionTypes.FilterPayPalEvents),
            switchMap(action => of(new GetPayPalEventsAction(1, action.filter)))
        ),
    );

    sortList = createEffect(() =>
        this.actions.pipe(
            ofType<SortPayPalEventsAction>(ActionTypes.SortPayPalEvents),
            switchMap(action => of(new GetPayPalEventsAction(1, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions.pipe(
            ofType<PageChangedPayPalEventsAction>(ActionTypes.PageChangedPayPalEvents),
            switchMap(action => of(new GetPayPalEventsAction(action.page)))
        ),
    );

    getPayPalEvents = createEffect(() =>
        this.actions.pipe(
            ofType<GetPayPalEventsAction>(ActionTypes.GetPayPalEvents),
            withLatestFrom(this.store.select(getPayPalEventsState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const params = this.getParams(sorting, filter, state.pager.pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.payPalEventsService.payPalEventDeliveriesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetPayPalEventsSucceedAction(response, state.pager.pageSize, action.page, stateExtensions))),
                    catchError((error) =>
                        of(new GetPayPalEventsFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                EventType: filter.eventType,
                Id: filter.id,
                Status: filter.status
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
