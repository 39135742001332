import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@admin_app/storage/store";

import * as OrganizationActions from "@admin_app/storage/organization/organization.actions";
import * as OrganizationSelectors from "@admin_app/storage/organization/organization.selectors";

import * as TidActions from "@admin_app/storage/tid/tid.actions";
import * as TidSelectors from "@admin_app/storage/tid/tid.selectors";

import * as MidsActions from "@admin_app/storage/mids/mids.actions";
import * as MidsSelectors from "@admin_app/storage/mids/mids.selectors";

import * as MidActions from "@admin_app/storage/mid/mid.actions";
import * as MidSelectors from "@admin_app/storage/mid/mid.selectors";

import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { ShowInformationModalAction } from "@admin_app/storage/app/app.actions";

@Component({
    selector: "app-tid",
    templateUrl: "./tid.component.html",
    styleUrls: ["./tid.component.less"]
})
export class TidComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute
    ) {}

    merchant$ = this.store.select(OrganizationSelectors.getOrganizationInfo);
    merchantLoading$ = this.store.select(OrganizationSelectors.getOrganizationLoading);

    tid$ = this.store.select(TidSelectors.getTidInfo);
    tidLoading$ = this.store.select(TidSelectors.getTidLoading);

    googlePayMerchantId$ = this.store.select(TidSelectors.getGooglePayMerchantId);
    applePayMerchantId$ = this.store.select(TidSelectors.getApplePayMerchantId);

    applePayConfig$ = this.store.select(TidSelectors.getApplePayConfig);

    mids$ = this.store.select(MidsSelectors.getMids);
    midsLoading$ = this.store.select(MidsSelectors.getMidsLoading);

    midCloverDevices$ = this.store.select(MidSelectors.getMidCloverDevices);
    midCloverDevicesLoading$ = this.store.select(MidSelectors.getMidCloverDevicesLoading);
    midCloverDevicesError$ = this.store.select(MidSelectors.getMidCloverDevicesError);

    isCreate: boolean;

    ngOnInit() {
        this.store.dispatch(new OrganizationActions.GetOrganizationAction(+this.route.snapshot.params.organizationId));
        this.store.dispatch(new MidsActions.GetMidsAction(+this.route.snapshot.params.organizationId));
        this.store.dispatch(new MidActions.ResetCloverDevicesAction());

        this.isCreate = !this.route.snapshot.params.tid;

        if (this.isCreate) {
            this.store.dispatch(new TidActions.GetNewTidAction());
        }
        else {
            this.store.dispatch(new TidActions.GetTidAction(this.route.snapshot.params.tid, this.route.snapshot.params.organizationId));
        }
    }

    updateTid(payload: any) {

        this.store.dispatch(new TidActions.UpdateTidAction(payload.id, payload.organizationId, payload.dto));
    }

    createTid(payload: any) {

        this.store.dispatch(new TidActions.CreateTidAction(payload.organizationId, payload.dto));
    }

    deleteTid(payload: any) {

        this.store.dispatch(new TidActions.DeleteTidAction(payload.id, payload.organizationId));
    }

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

    onReassignDevice(payload: any): void {

        this.store.dispatch(
            new TidActions.ReassignDeviceAction(payload.organizationId, payload.merchantProcessorId, payload.merchantTerminalId));
    }

    onPairDevice(payload: any): void {

        this.store.dispatch(
            new TidActions.PairDeviceAction(payload.organizationId, payload.merchantProcessorId, payload.merchantTerminalId));
    }

    onGetCloverDevices(payload: any): void {

        this.store.dispatch(new MidActions.GetCloverDevicesAction(payload.merchantProcessorId, payload.organizationId));
    }

    onGetGooglePayMerchantId(payload: any): void {

        this.store.dispatch(
            new TidActions.GetGooglePayMerchantIdAction(payload.id, payload.organizationId));
    }

    onGetApplePayMerchantId(payload: any): void {

        this.store.dispatch(
            new TidActions.GetApplePayMerchantIdAction(payload.id, payload.organizationId));
    }

    onSyncApplePay(payload: any): void {

        if (payload.formDirty) {
            const title = "Action Unavailable";
            const message = "Please submit or cancel form changes first and then try again.";
            const buttonText = "OK";
            this.store.dispatch(new ShowInformationModalAction(title, message, buttonText));
        }
        else {
            this.store.dispatch(
                new TidActions.SyncApplePayAction(payload.id, payload.organizationId));
        }
    }

    onGetDomainValidationFile(payload: any): void {

        this.store.dispatch(
            new TidActions.GetDomainValidationFileAction(payload.id, payload.organizationId));
    }

}
