/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateSolutionWebhookDto } from '../models/create-solution-webhook-dto';
import { EntityDto } from '../models/entity-dto';
import { ItemsDtoOfSolutionWebhookDto } from '../models/items-dto-of-solution-webhook-dto';
import { SolutionWebhookDto } from '../models/solution-webhook-dto';
import { UpdateSolutionWebhookDto } from '../models/update-solution-webhook-dto';
import { WebhookEventTypesDto } from '../models/webhook-event-types-dto';

@Injectable({
  providedIn: 'root',
})
export class WebhooksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation webhooksSearch
   */
  static readonly WebhooksSearchPath = '/v1/admin/webhooks';

  /**
   * Retrieves a list of solution webhooks registered in the system.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksSearch$Response(params?: {
  }): Observable<StrictHttpResponse<ItemsDtoOfSolutionWebhookDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksSearchPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemsDtoOfSolutionWebhookDto>;
      })
    );
  }

  /**
   * Retrieves a list of solution webhooks registered in the system.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksSearch(params?: {
  }): Observable<ItemsDtoOfSolutionWebhookDto> {

    return this.webhooksSearch$Response(params).pipe(
      map((r: StrictHttpResponse<ItemsDtoOfSolutionWebhookDto>) => r.body as ItemsDtoOfSolutionWebhookDto)
    );
  }

  /**
   * Path part for operation webhooksPost
   */
  static readonly WebhooksPostPath = '/v1/admin/webhooks';

  /**
   * Adds a new solution webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPost$Response(params: {

    /**
     * The solution webhook properties.
     */
    body: CreateSolutionWebhookDto
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * Adds a new solution webhook.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPost(params: {

    /**
     * The solution webhook properties.
     */
    body: CreateSolutionWebhookDto
  }): Observable<EntityDto> {

    return this.webhooksPost$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * Path part for operation webhooksGet
   */
  static readonly WebhooksGetPath = '/v1/admin/webhooks/{id}';

  /**
   * Retrieves info about a solution webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGet$Response(params: {

    /**
     * The solution webhook id.
     */
    id: number;
  }): Observable<StrictHttpResponse<SolutionWebhookDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SolutionWebhookDto>;
      })
    );
  }

  /**
   * Retrieves info about a solution webhook.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGet(params: {

    /**
     * The solution webhook id.
     */
    id: number;
  }): Observable<SolutionWebhookDto> {

    return this.webhooksGet$Response(params).pipe(
      map((r: StrictHttpResponse<SolutionWebhookDto>) => r.body as SolutionWebhookDto)
    );
  }

  /**
   * Path part for operation webhooksPut
   */
  static readonly WebhooksPutPath = '/v1/admin/webhooks/{id}';

  /**
   * Changes a solution webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPut$Response(params: {

    /**
     * The solution webhook id.
     */
    id: number;

    /**
     * The solution webhook properties.
     */
    body: UpdateSolutionWebhookDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changes a solution webhook.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhooksPut(params: {

    /**
     * The solution webhook id.
     */
    id: number;

    /**
     * The solution webhook properties.
     */
    body: UpdateSolutionWebhookDto
  }): Observable<void> {

    return this.webhooksPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation webhooksDelete
   */
  static readonly WebhooksDeletePath = '/v1/admin/webhooks/{id}';

  /**
   * Deletes a solution webhook.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksDelete$Response(params: {

    /**
     * The solution webhook id.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a solution webhook.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksDelete(params: {

    /**
     * The solution webhook id.
     */
    id: number;
  }): Observable<void> {

    return this.webhooksDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation webhooksGenerateSecret
   */
  static readonly WebhooksGenerateSecretPath = '/v1/admin/webhooks/generate-secret';

  /**
   * Generates a new webhook secret.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksGenerateSecret()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGenerateSecret$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksGenerateSecretPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Generates a new webhook secret.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksGenerateSecret$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksGenerateSecret(params?: {
  }): Observable<string> {

    return this.webhooksGenerateSecret$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation webhooksLookups
   */
  static readonly WebhooksLookupsPath = '/v1/admin/webhooks/lookups';

  /**
   * Retrives a list of all available event names.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhooksLookups()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksLookups$Response(params?: {
  }): Observable<StrictHttpResponse<WebhookEventTypesDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhooksLookupsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebhookEventTypesDto>;
      })
    );
  }

  /**
   * Retrives a list of all available event names.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhooksLookups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhooksLookups(params?: {
  }): Observable<WebhookEventTypesDto> {

    return this.webhooksLookups$Response(params).pipe(
      map((r: StrictHttpResponse<WebhookEventTypesDto>) => r.body as WebhookEventTypesDto)
    );
  }

}
