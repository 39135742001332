import { InventoryState } from "./inventory.state";
import { ActionTypes } from "./inventory.actions";

export const InventoryReducer = (
    state: InventoryState = new InventoryState(),
    action: any,
  ): InventoryState => {

    switch (action.type) {

        case ActionTypes.FilterInventory:
        case ActionTypes.SortInventory:
        case ActionTypes.PageChangedInventory:
        case ActionTypes.GetInventory: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetInventorySucceed: {
            const morePagesMode = (action.response.totalPages == null);
            return {
                ...state,
                record: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
        }

        case ActionTypes.GetInventoryFail: {
            return {
                ...state,
                record: [],
                loading: false,
                error: action.error
            };
        }

        case ActionTypes.UpdateInventoryDevice: {

            const newState = state;
            if (newState.record) {
                const foundIndex = newState.record.findIndex(device => device.tid === action.device.tid);
                if (foundIndex >= 0) {
                    newState.record[foundIndex] = {...action.device};
                }
            }

            return newState;
        }

        default: {
            return state;
        }
    }

};
