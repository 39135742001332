import { CurrentUserEffects } from "./current-user/current-user.effects";
import { AppEffects } from "./app/app.effects";
import { InventoryEffects } from "./inventory/inventory.effects";
import { RouterEffects } from "./router/router.effects";
import { ConfigEffects } from "./config/config.effects";
import { DeviceEffects } from "./device/device.effects";
import { MidsEffects } from "./mids/mids.effects";
import { TidsEffects } from "./tids/tids.effects";
import { MidEffects } from "./mid/mid.effects";
import { FeePlansEffects } from "./fee-plans/fee-plans.effects";
import { FeePlanEffects } from "./fee-plan/fee-plan.effects";
import { FeeTypesEffects } from "./fee-types/fee-types.effects";
import { TidEffects } from "./tid/tid.effects";
import { ReportEffects } from "./report/report.effects";
import { StatementsEffects } from "./statements/statements.effects";
import { UsersEffects } from "./users/users.effects";
import { UserEffects } from "./user/user.effects";
import { WebhooksEffects } from "./webhooks/webhooks.effects";
import { WebhookEffects } from "./webhook/webhook.effects";
import { EventDeliveriesEffects } from "./event-deliveries/event-deliveries.effects";
import { EventDeliveryEffects } from "./event-delivery/event-delivery.effects";
import { FundingEffects } from "./funding/funding.effects";
import { FundingItemEffects } from "./funding-item/funding-item.effects";
import { DfmFilesEffects } from "./dfm-files/dfm-files.effects";
import { BinFilesEffects } from "./bin-files/bin-files.effects";
import { DfmFileEffects } from "./dfm-file/dfm-file.effects";
import { BinFileEffects } from "./bin-file/bin-file.effects";
import { EventsEffects } from "./events/events.effects";
import { EventEffects } from "./event/event.effects";
import { GooglePayKeyEffects } from "./google-pay-key/google-pay-key.effects";
import { GooglePayKeysEffects } from "./google-pay-keys/google-pay-keys.effects";
import { PaymentsEffects } from "./payments/payments.effects";
import { TransactionsEffects } from "./transactions/transactions.effects";
import { PaymentEffects } from "./payment/payment.effects";
import { TransactionEffects } from "./transaction/transaction.effects";
import { TransactionSiblingsEffects } from "./transaction-siblings/transaction-siblings.effects";
import { RoleEffects } from "./role/role.effects";
import { RolesEffects } from "./roles/roles.effects";
import { AccountsEffects } from "./accounts/accounts.effects";
import { AccountEffects } from "./account/account.effects";
import { OrganizationsEffects } from "./organizations/organizations.effects";
import { OrganizationEffects } from "./organization/organization.effects";
import { PayPalEventsEffects } from "./paypal-events/paypal-events.effects";
import { PayPalSftpFileEffects } from "./paypal-sftp-file/paypal-sftp-file.effects";
import { PayPalSftpFilesEffects } from "./paypal-sftp-files/paypal-sftp-files.effects";
import { AdjustmentsEffects } from "./adjustments/adjustments.effects";
import { AdjustmentEffects } from "./adjustment/adjustment.effects";
import { ApplePayKeysEffects } from "./apple-pay-keys/apple-pay-keys.effects";
import { ApplePayKeyEffects } from "./apple-pay-key/apple-pay-key.effects";
import { DisputesEffects } from "./disputes/disputes.effects";
import { DisputeEffects } from "./dispute/dispute.effects";
import { MerchantHistoryEffects } from "./merchant-history/merchant-history.effects";

export const EffectsConfiguration = [
    AppEffects,
    ConfigEffects,
    RouterEffects,
    CurrentUserEffects,
    InventoryEffects,
    DeviceEffects,
    MidsEffects,
    MidEffects,
    TidsEffects,
    TidEffects,
    FeePlansEffects,
    FeePlanEffects,
    FeeTypesEffects,
    ReportEffects,
    StatementsEffects,
    UsersEffects,
    UserEffects,
    WebhookEffects,
    WebhooksEffects,
    EventDeliveriesEffects,
    EventDeliveryEffects,
    FundingEffects,
    FundingItemEffects,
    DfmFilesEffects,
    DfmFileEffects,
    BinFilesEffects,
    BinFileEffects,
    PayPalSftpFilesEffects,
    PayPalSftpFileEffects,
    EventsEffects,
    EventEffects,
    PayPalEventsEffects,
    GooglePayKeyEffects,
    GooglePayKeysEffects,
    ApplePayKeyEffects,
    ApplePayKeysEffects,
    PaymentsEffects,
    TransactionsEffects,
    PaymentEffects,
    TransactionEffects,
    TransactionSiblingsEffects,
    RoleEffects,
    RolesEffects,
    AccountsEffects,
    AccountEffects,
    OrganizationsEffects,
    OrganizationEffects,
    AdjustmentsEffects,
    AdjustmentEffects,
    DisputesEffects,
    DisputeEffects,
    MerchantHistoryEffects
];
