import { FundingResult } from "@admin_api/models/funding-result";
import { FundingResultsOrderBy } from "@admin_api/models/funding-results-order-by";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { Filter, Sorting } from "@admin_app/storage/funding/funding.state";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef, LocalTimePoint, Pager, TableSorting } from "shared-lib";

@Component({
    selector: "app-funding-log",
    templateUrl: "./funding-log.component.html",
    styleUrls: ["./funding-log.component.less"],
})
export class FundingLogComponent {

    @Input() funding: Array<FundingResult>;
    @Input() fundingLoading = false;
    @Input() fundingError: Error;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();

    @Input() filter: Filter;
    @Output() filterChanged = new EventEmitter<Filter>();

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    readonly columnDefs: ColumnDef[];

    constructor(private router: Router) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: FundingResultsOrderBy.CreatedUtc, title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true},
            {id: "merchantName", title: "Merchant Name", flexWidthBasisInPixels: 230, flexWidthGrow: 1, canSort: false},
            {id: FundingResultsOrderBy.MID, title: "MID", flexWidthBasisInPixels: 170, flexWidthGrow: 1, canSort: true},
            {id: "vituPayDepositAmount", title: "VituPay Deposit Amount", flexWidthBasisInPixels: 120, flexWidthGrow: 1, canSort: false},
            {id: "merchantDepositAmount", title: "Merchant Deposit Amount", flexWidthBasisInPixels: 120, flexWidthGrow: 1, canSort: false},
            {id: FundingResultsOrderBy.FundingStatus, title: "Funding Status",
                flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
            {id: FundingResultsOrderBy.FundedDateUtc, title: `Date Funded (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

    onRowSelected(id: string) {
        this.router.navigate([`/dashboard/funding/funding-history/${id}`]);
    }

}
