import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter as filterOperator, map, switchMap, withLatestFrom } from "rxjs/operators";
import {
    ActionTypes,
    PageChangedFeePlansAction,
    GetFeePlansAction,
    GetFeePlansSucceedAction,
    SortFeePlansAction,
    GetFeePlansFailAction,
    UploadPlanCodesAction
} from "./fee-plans.actions";
import { of, Subject } from "rxjs";
import { BillingService } from "@admin_api/services/billing.service";
import { Filter, Sorting } from "./fee-plans.state";
import { getFeePlansState } from "./fee-plans.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { ConfirmationModalComponent, ConfirmationModalData, GlobalSpinnerService, VituToastService, VituToastTone } from "shared-lib";
import { MatDialog } from "@angular/material/dialog";
import { UploadFileModalAction } from "@admin_app/shared/upload-file-modal/upload-file-modal-action";
import { ModalActionType } from "@admin_app/shared/modal-action";
import { UploadFileModalComponent } from "@admin_app/shared/upload-file-modal/upload-file-modal.component";

@Injectable()
export class FeePlansEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService,
        private store: Store<IStore>,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private toast: VituToastService
    ) {}

    initFeePlansPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigatedAction) => /\/dashboard\/fee-plans/g.test(action.payload.routerState.url) &&
                !(/\/dashboard\/fee-plans\//g.test(action.payload.routerState.url))),
            map(() => new GetFeePlansAction(1))
        )
    );

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortFeePlansAction>(ActionTypes.SortFeePlans),
            switchMap(action => of(new GetFeePlansAction(1, undefined, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedFeePlansAction>(ActionTypes.PageChangedFeePlans),
            switchMap(action => of(new GetFeePlansAction(action.page)))
        ),
    );

    getFeePlans = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFeePlansAction>(ActionTypes.GetFeePlans),
            withLatestFrom(this.store.select(getFeePlansState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.billingService.feePlansSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetFeePlansSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) =>
                        of(new GetFeePlansFailAction(error))
                    )
                );
            })
        ),
    );

    uploadPaymentProcessingCertificate$ = createEffect((): any =>
        this.actions$.pipe(
            ofType<UploadPlanCodesAction>(ActionTypes.UploadPlanCodes),
            switchMap(() => {

                const errorMessageSubject = new Subject<string>();
                const modalActionSubject = new Subject<UploadFileModalAction>();

                const dialogRef = this.dialog
                    .open(UploadFileModalComponent, {
                        width: "500px",
                        data: {
                            modalActionSubject,
                            errorMessageSubject,
                            dialogTitle: "Upload Plan Codes",
                            dialogMessage: "Select the plan codes file to upload:",
                            fileLabel: "Plan Codes File",
                            onlyFilesOfType: ".csv",
                            infoTip:
`File should be a .csv file containing row data in the expected format for plan code records,
under the following headers (the headers themselves should not be included):

Network,Industry,Tier,Code,Description,Rate,PerItem.

Network: required. should be one of supported card brands (Visa,Mastercard,Discover,Amex).
Industry: optional.
Tier: optional.
Code: required. max 4 chars length.
Description: optional. max 100 chars length.
Rate: required. should be >= 0. should include % suffix.
PerItem: required. should be >= 0. Should include $ prefix.

Record example:
Visa,,,N01,US Regulated (DB),0.05%,$0.22
`
                        },
                        disableClose: true,
                        autoFocus: false
                    });
                return modalActionSubject.pipe(
                    switchMap(({action, params}: UploadFileModalAction) => {
                        switch (action) {
                            case ModalActionType.CANCEL:
                            {
                                dialogRef.close();
                                return of();
                            }
                            case ModalActionType.CONFIRM:
                            {
                                return this.globalSpinner.apply(
                                    this.billingService.planCodesUpload(
                                        {
                                            body: { file: params.file }
                                        }
                                    )
                                    .pipe(
                                        switchMap((response: number) => {
                                            dialogRef.close();
                                            this.toast.open(
                                                [
                                                    `Plan Codes file uploaded successfully.`,
                                                    `Number of plan codes added: ${response}`
                                                ],
                                                VituToastTone.Positive);
                                            return of();
                                        }),
                                        catchError(error => {
                                            errorMessageSubject.next(error instanceof Error ? error.message : error);
                                            return of();
                                        })
                                    )
                                );
                            }
                        }
                    })
                );
            })
        ));

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
// Enable when there's a UI for filter
//            ...{ filter params },
//            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
//            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
