<div class="outer-container" (mousedown)="onMouseDown()">

    <div mat-dialog-title>
        <div>Pair Device</div>
        <i class="fal fa-times global-dialog-close-button" (mousedown)="onCancel($event)"></i>
    </div>

    <div mat-dialog-content class="content">

        <form class="form" [formGroup]="pairDeviceForm" [libVituForm]="pairDeviceForm" (libVituFormSubmit)="onSubmit()">

            <div class="form-fields-container">

                <lib-vitu-form-field-input
                    id="userCodeField"
                    [formGroup]="pairDeviceForm"
                    formFieldName="userCodeField"
                    label="User Code">
                </lib-vitu-form-field-input>

            </div>

            <lib-modal-error-message *ngIf="data?.errorMessageSubject | async as errorMessage" [message]="errorMessage">
            </lib-modal-error-message>
        
            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()"
                    [isModal]="true">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Pair"
                    [neverDisable]="true">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </form>
    </div>

</div>
