import { FeePlanDto } from "@admin_api/models/fee-plan-dto";
import { FeePlanOrderBy } from "@admin_api/models/fee-plan-order-by";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { DateRange, getInitialLocalFilterDateRangeLastTwoWeeks, Pager } from "shared-lib";

export class Sorting {
    orderBy: null | FeePlanOrderBy;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter {
    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval?: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    startDate?: string;
    endDate?: string;
}

export class FeePlansState {
    loading = false;
    error: Error = null;
    record: Array<FeePlanDto> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = new Filter();
}
