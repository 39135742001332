import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import {
    ActionTypes,
    GenerateWebhooksSecretFailAction,
    GenerateWebhooksSecretSucceedAction,
    GetWebhooksAction,
    GetWebhooksFailAction,
    GetWebhooksSucceedAction
} from "./webhooks.actions";
import { WebhooksService } from "@admin_api/services/webhooks.service";
import { VituToastService, VituToastTone } from "shared-lib";

@Injectable()
export class WebhooksEffects {

    constructor(
        private actions$: Actions,
        private webhooksService: WebhooksService,
        private toast: VituToastService
    ) {}

    initWebhooksPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => action.payload.routerState.url.startsWith("/dashboard/webhooks/configure")),
            map(() => new GetWebhooksAction())
        )
    );

    getWebhooks$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetWebhooksAction>(ActionTypes.GetWebhooks),
            switchMap(() =>
                this.webhooksService.webhooksSearch().pipe(
                    switchMap((webhooks) => of(new GetWebhooksSucceedAction(webhooks.items))),
                    catchError((error) => of(new GetWebhooksFailAction(error)))
                )
            )
        )
    );

    generateWebhooksSecret$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetWebhooksAction>(ActionTypes.GenerateWebhooksSecret),
            switchMap(() =>
                this.webhooksService.webhooksGenerateSecret().pipe(
                    switchMap((secret) => {
                        this.toast.open("Generate secret success.", VituToastTone.Positive);
                        return of(new GenerateWebhooksSecretSucceedAction(secret));
                    }),
                    catchError((error) => {
                        this.toast.open("Generate secret failed.", VituToastTone.Negative);
                        return of(new GenerateWebhooksSecretFailAction(error));
                    })
                )
            )
        ),
    );

}
