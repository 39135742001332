import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getTidState = (store: IStore) => store.tid;

export const getTidInfo = createSelector(
    getTidState,
    tidState => tidState.record,
);

export const getTidLoading = createSelector(
    getTidState,
    tidState => tidState.loading,
);

export const getGooglePayMerchantId = createSelector(
    getTidState,
    tidState => tidState.googlePayMerchantIdRecord,
);

export const getApplePayMerchantId = createSelector(
    getTidState,
    tidState => tidState.applePayMerchantIdRecord,
);

export const getApplePayConfig = createSelector(
    getTidState,
    tidState => tidState.applePaySyncRecord,
);
