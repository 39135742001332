import { Action } from "@ngrx/store";
import { Filter, Sorting } from "./dfm-files.state";
import { PagedDataDtoOfDfmFileDto } from "@admin_api/models/paged-data-dto-of-dfm-file-dto";

export enum ActionTypes {
    GetDfmFiles = "[DFM_FILES] GetDfmFiles",
    GetDfmFilesSucceed = "[DFM_FILES] GetDfmFilesSucceed",
    GetDfmFilesFail = "[DFM_FILES] GetDfmFilesFail",
    SortDfmFiles = "[DFM_FILES] SortDfmFiles",
    FilterDfmFiles = "[DFM_FILES] FilterDfmFiles",
    PageChangedDfmFiles = "[DFM_FILES] PageChangedDfmFiles"
}

export class GetDfmFilesAction implements Action {

    constructor(public page: number, public pageSize?: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetDfmFiles;
}

export class GetDfmFilesSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfDfmFileDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetDfmFilesSucceed;
}

export class GetDfmFilesFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetDfmFilesFail;
}

export class SortDfmFilesAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortDfmFiles;
}

export class FilterDfmFilesAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterDfmFiles;
}

export class PageChangedDfmFilesAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedDfmFiles;
}
