import { Component } from "@angular/core";
import { IStore } from "@admin_app/storage/store";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { getWebhookLookups } from "@admin_app/storage/webhook/webhook.selectors";
import {
    getPayPalEvents, getPayPalEventsError, getPayPalEventsLoading, getPayPalEventsState
} from "@admin_app/storage/paypal-events/paypal-events.selectors";
import {
    FilterPayPalEventsAction, PageChangedPayPalEventsAction, SortPayPalEventsAction
} from "@admin_app/storage/paypal-events/paypal-events.actions";
import { Filter, Sorting } from "@admin_app/storage/paypal-events/paypal-events.state";

@Component({
    selector: "app-paypal-events",
    templateUrl: "./paypal-events.component.html",
    styleUrls: [ "./paypal-events.component.less" ]
})
export class PayPalEventsComponent {

    events$ = this.store.select(getPayPalEvents);
    loading$ = this.store.select(getPayPalEventsLoading);
    error$ = this.store.select(getPayPalEventsError);
    webhookLookups$ = this.store.select(getWebhookLookups);

    pager$ = this.store.pipe(select(getPayPalEventsState), map((i) => i.pager));
    sorting$ = this.store.pipe(select(getPayPalEventsState), map((i) => i.sorting));
    filter$ = this.store.pipe(select(getPayPalEventsState), map((i) => i.filter));

    constructor(private store: Store<IStore>) {}

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new SortPayPalEventsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new PageChangedPayPalEventsAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FilterPayPalEventsAction(filter));
    }

}
