<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Transactions' }]">
        <lib-action-button
            label="Bulk Update"
            [kind]="ActionButtonKind.PRIMARY"
            fontImage="far fa-file-upload fa-bg"
            (click)="onClickBulkUpdate()">
        </lib-action-button>
    </lib-title-bar>

    <app-transaction-log-filters
        [filter]="filter"
        (filterChanged)="filterChanged.emit($event)"
        [merchants]="merchants"
        [merchantsLoading]="merchantsLoading">
    </app-transaction-log-filters>

<!--
    <div class="table-actions-bar">
        <lib-action-button label="Export" (click)="this.exportToCsv.emit()" [disabled]="exportButtonDisabled()"
            fontImage="far fa-file-export fa-lg" [kind]="ActionButtonKind.SECONDARY">
        </lib-action-button>
    </div>
-->

    <div class="table-container">
        <ng-container *ngIf="true then table"></ng-container>
        <ng-container *ngIf="exportLoading then spinner"></ng-container>
    </div>

</div>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #table>

    <app-transactions-table
        [rowsData]="rowsData"
        [rowsDataLoading]="loading"
        [rowsDataError]="error"
        rowsDataErrorMessage="Error Loading Transactions"
        rowsDataEmptyMessage="No Transactions Found"
        [rowsDataShowWhileLoading]="true"
        selectedRowProperty="token"
        [sorting]="sorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="pager"
        (pageChanged)="pageChanged.emit($event)"
        (rowSelected)="onRowSelected($event)">
    </app-transactions-table>

</ng-template>
