import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, filter, map } from "rxjs/operators";
import { of } from "rxjs";
import { EventsService } from "@admin_api/services/events.service";
import {
    ActionTypes,
    GetEventAction,
    GetEventFailAction,
    GetEventSucceedAction
} from "./event.actions";
import { GetEventDeliveriesAction, ResetEventDeliveriesAction } from "../event-deliveries/event-deliveries.actions";
import { RouterNavigationAction, ROUTER_NAVIGATED } from "@ngrx/router-store";
import { PageLoadFailAction } from "../router/router.actions";
import { VituCommonErrorEntityIdMustBeNumber } from "shared-lib";

@Injectable()
export class EventEffects {

    constructor(
        private actions: Actions,
        private eventsService: EventsService
    ) { }

    initEventPage = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigationAction) =>
                new RegExp(/dashboard\/webhooks\/event-history\/.+$/).test(action.payload.routerState.url)),
            switchMap((action: any) => {
                const rS = action.payload.routerState;
                const token = rS.root.firstChild.firstChild.params.id;
                return [new ResetEventDeliveriesAction(), new GetEventAction(token)];
            })
        )
    );

    getEvent = createEffect(() =>
        this.actions.pipe(
            ofType<GetEventAction>(ActionTypes.GetEvent),
            switchMap((action) => {
                if (isNaN(action.id)) {
                    return of(new GetEventFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.eventsService.eventsGet({ id: action.id }).pipe(
                    switchMap((response) => of(new GetEventSucceedAction(response))),
                    catchError((error) => of(new GetEventFailAction(error)))
                );
            })
        ),
    );

    getEventSucceed = createEffect(() => this.actions.pipe(
        ofType<GetEventSucceedAction>(ActionTypes.GetEventSucceed),
        switchMap(action => [
            new GetEventDeliveriesAction(action.response.id, 1)
        ])
    ));

    getEventFail = createEffect(() =>
        this.actions.pipe(
            ofType<GetEventFailAction>(ActionTypes.GetEventFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

}
