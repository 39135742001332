import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { ActivatedRoute } from "@angular/router";
import * as RoleActions from "@admin_app/storage/role/role.actions";
import * as RoleSelectors from "@admin_app/storage/role/role.selectors";
import { GoBackAction } from "@admin_app/storage/router/router.actions";
import * as ConfigSelectors from "@admin_app/storage/config/config.selectors";
import { CreateGlobalRoleDto } from "@admin_api/models/create-global-role-dto";
import { UpdateGlobalRoleDto } from "@admin_api/models/update-global-role-dto";
import { GlobalRoleDetailsDto } from "@admin_api/models/global-role-details-dto";

@Component({
    selector: "app-role",
    templateUrl: "./role.component.html",
    styleUrls: ["./role.component.less"]
})
export class RoleComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    config$ = this.store.select(ConfigSelectors.getConfig);
    role$ = this.store.select(RoleSelectors.getRoleInfo);
    permissionList$ = this.store.select(RoleSelectors.getRolePermissionList);
    loading$ = this.store.select(RoleSelectors.getRoleLoading);

    isCreate: boolean;

    ngOnInit() {

        this.isCreate = !this.route.snapshot.params.id;
        const solution = this.route.snapshot.queryParams?.solution;

        if (this.isCreate) {
            this.store.dispatch(new RoleActions.GetNewRoleAction());
        }
        else {
            this.store.dispatch(new RoleActions.GetRoleAction(this.route.snapshot.params.id, solution ?? null));
        }
    }

    updateRole(payload: {id: number; updateGlobalRoleDto: UpdateGlobalRoleDto}) {

        this.store.dispatch(new RoleActions.UpdateRoleAction(payload.id, payload.updateGlobalRoleDto));
    }

    createRole(createGlobalRoleDto: CreateGlobalRoleDto) {

        this.store.dispatch(new RoleActions.CreateRoleAction(createGlobalRoleDto));
    }

    deleteRole(role: GlobalRoleDetailsDto) {

        this.store.dispatch(new RoleActions.DeleteRoleAction(role));
    }

    onBack(): void {

        this.store.dispatch(GoBackAction());
    }

}
