import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getConfigState = createSelector(
    (store: IStore) => store.config,
    store => store,
);

export const getConfig = createSelector(
    getConfigState,
    configState => configState.record,
);
