import { ModalAction, ModalActionType } from "@admin_app/shared/modal-action";

export interface RequestSettlementParams {
    id?: number;
    startDate?: string;
    endDate?: string;
    amount?: number;
}

export class RequestSettlementModalAction extends ModalAction {
    constructor(public action: ModalActionType, public params?: RequestSettlementParams) {
        super(action, params);
    }
}
