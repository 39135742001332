import { AccountMerchantProcessorDto } from "@admin_api/models/account-merchant-processor-dto";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";

export class AccountState {
    account: MerchantProcessorAccountDto = null;
    loading = false;
    error = null;
    merchantProcessors: Array<AccountMerchantProcessorDto> = [];
    merchantProcessorsLoading = false;
    merchantProcessorsError = null;
}
