import { WebhooksState } from "./webhooks.state";
import { ActionTypes } from "./webhooks.actions";

export const WebhooksReducer = (
    state: WebhooksState = new WebhooksState(),
    action: any,
  ): WebhooksState => {

    switch (action.type) {

        case ActionTypes.GetWebhooks: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetWebhooksSucceed: {
            return {
                ...state,
                record: action.webhooks,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetWebhooksFail: {
            return {
                ...state,
                record: [],
                loading: false,
                error: action.error
            };
        }

        case ActionTypes.GenerateWebhooksSecret: {
            return {
                ...state,
                secretLoading: true
            };
        }

        case ActionTypes.GenerateWebhooksSecretSucceed: {
            return {
                ...state,
                secret: action.secret,
                secretLoading: false,
                secretError: null
            };
        }

        case ActionTypes.GenerateWebhooksSecretFail: {
            return {
                ...state,
                secretLoading: false,
                secretError: action.error
            };
        }

        case ActionTypes.ResetWebhooksSecret: {
            return {
                ...state,
                secret: null,
                secretLoading: false,
                secretError: null
            };
        }

        default: {
            return state;
        }
    }

};
