import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, withLatestFrom, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { IStore } from "../store";
import { Store } from "@ngrx/store";
import { Filter, Sorting } from "./event-deliveries.state";
import { getEventDeliveriesState } from "./event-deliveries.selectors";
import {
    ActionTypes,
    PageChangedEventDeliveriesAction,
    GetEventDeliveriesAction,
    GetEventDeliveriesSucceedAction,
    SortEventDeliveriesAction,
    GetEventDeliveriesFailAction

} from "./event-deliveries.actions";
import { EventsService } from "@admin_api/services/events.service";
import { getEvent } from "../event/event.selectors";

@Injectable()
export class EventDeliveriesEffects {

    constructor(
        private actions: Actions,
        private eventsService: EventsService,
        private store: Store<IStore>
    ) { }

    sortList = createEffect(() =>
        this.actions.pipe(
            ofType<SortEventDeliveriesAction>(ActionTypes.SortEventDeliveries),
            switchMap(action => of(new GetEventDeliveriesAction(action.eventId, 1, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions.pipe(
            ofType<PageChangedEventDeliveriesAction>(ActionTypes.PageChangedEventDeliveries),
            switchMap(action => of(new GetEventDeliveriesAction(action.eventId, action.page)))
        ),
    );

    getEventDeliveries = createEffect(() =>
        this.actions.pipe(
            ofType<GetEventDeliveriesAction>(ActionTypes.GetEventDeliveries),
            withLatestFrom(
                this.store.select(getEventDeliveriesState),
                this.store.select(getEvent),
            ),
            switchMap(([action, state, event]) => {

                const filter = {
                    ...(action.filter ?? state.filter),
                    localDateInterval: {
                        from: event.createdUtc,
                        to: null,
                    }
                };

                const sorting = action.sorting ? action.sorting : state.sorting;
                const params = this.getParams(action.eventId, sorting, filter, state.pager.pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.eventsService.eventDeliveriesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetEventDeliveriesSucceedAction(response, state.pager.pageSize, action.page, stateExtensions))),
                    catchError((error) =>
                        of(new GetEventDeliveriesFailAction(error)))
                );
            })
        ),
    );

    private getParams(eventId: number, sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            eventId,
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
//            ...{ filter params },
            StartDate: filter.localDateInterval?.from,
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
