import { MerchantChangeOrderByEnum } from "@admin_api/models/merchant-change-order-by-enum";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-merchant-history-table-cell-content",
    templateUrl: "./merchant-history-table-cell-content.component.html",
    styleUrls: ["./merchant-history-table-cell-content.component.less"],
})
export class MerchantHistoryTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;

    MerchantChangeOrderByEnum = MerchantChangeOrderByEnum;
}
