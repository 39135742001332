import { Action } from "@ngrx/store";
import { PermissionDto } from "@admin_api/models/permission-dto";
import { CreateGlobalRoleDto } from "@admin_api/models/create-global-role-dto";
import { UpdateGlobalRoleDto } from "@admin_api/models/update-global-role-dto";
import { GlobalRoleDetailsDto } from "@admin_api/models/global-role-details-dto";
import { SolutionEnum } from "@admin_api/models/solution-enum";


export enum ActionTypes {
    GetRole = "[Role] GetRole",
    GetNewRole = "[Role] GetNewRole",
    GetRoleSucceed = "[Role] GetRoleSucceed",
    GetRoleFail = "[Role] GetRoleFail",
    UpdateRole = "[Role] UpdateRole",
    DeleteRole = "[Role] DeleteRole",
    CreateRole = "[Role] CreateRole"
}

export class GetRoleAction implements Action {

    constructor(
        public id: number,
        public solution: SolutionEnum
    ) {}

    readonly type = ActionTypes.GetRole;
}

export class GetNewRoleAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewRole;
}

export class GetRoleSucceedAction implements Action {

    constructor(
        public role: GlobalRoleDetailsDto,
        public permissionList: PermissionDto[]
    ) {}

    readonly type = ActionTypes.GetRoleSucceed;
}

export class GetRoleFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetRoleFail;
}

export class UpdateRoleAction implements Action {

    constructor(public id: number, public updateGlobalRoleDto: UpdateGlobalRoleDto) {}

    readonly type = ActionTypes.UpdateRole;
}

export class DeleteRoleAction implements Action {

    constructor(public role: GlobalRoleDetailsDto) {}

    readonly type = ActionTypes.DeleteRole;
}

export class CreateRoleAction implements Action {

    constructor(public createGlobalRoleDto: CreateGlobalRoleDto) {}

    readonly type = ActionTypes.CreateRole;
}
