import { ModalAction, ModalActionType } from "@admin_app/shared/modal-action";
import { PosTerminalStatusEnum } from "@admin_api/models/pos-terminal-status-enum";

export interface ReassignDeviceParams {
    sourceMerchantId: number;
    merchantTerminalId: number;
    merchantId: number;
    merchantProcessorId: number;
    mai: string;
    dba: string;
    deviceName: string;
    deviceLocation: string;
    cloverMerchantId: string;
    status: PosTerminalStatusEnum;
}

export class ReassignDeviceModalAction extends ModalAction {
    constructor(public action: ModalActionType, public params?: ReassignDeviceParams) {
        super(action, params);
    }
}
