import { PaymentDetailsDto } from "@admin_api/models/payment-details-dto";
import { TransactionFeesResult } from "@admin_api/models/transaction-fees-result";

export class PaymentState {
  loading = false;
  error = null;
  record: PaymentDetailsDto = {};
  feesLoading = false;
  feesError = null;
  fees: TransactionFeesResult = {};
}
