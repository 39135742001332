import { IStore } from "@admin_app/storage/store";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import * as DisputesActions from "@admin_app/storage/disputes/disputes.actions";
import * as DisputesSelectors from "@admin_app/storage/disputes/disputes.selectors";
import { Filter, Sorting } from "@admin_app/storage/disputes/disputes.state";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";
import { PagerLargeSinglePageSize } from "@admin_app/storage/common";
import { ExportDisputesToCsvAction } from "@admin_app/storage/disputes/disputes.actions";

@Component({
    selector: "app-disputes",
    templateUrl: "./disputes.component.html",
    styleUrls: ["./disputes.component.less"],
})
export class DisputesComponent implements OnInit {

    disputes$ = this.store.select(DisputesSelectors.getDisputes);
    disputesLoading$ = this.store.select(DisputesSelectors.getDisputesLoading);
    disputesError$ = this.store.select(DisputesSelectors.getDisputesError);

    pager$ = this.store.select(DisputesSelectors.getDisputesPager);
    sorting$ = this.store.select(DisputesSelectors.getDisputesSorting);
    filter$ = this.store.select(DisputesSelectors.getDisputesFilter);

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    exportLoading$ = this.store.select(DisputesSelectors.getDisputesExport);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new DisputesActions.SortDisputesAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new DisputesActions.PageChangedDisputesAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new DisputesActions.FilterDisputesAction(filter));
    }

    exportToCsv() {
        this.store.dispatch(new ExportDisputesToCsvAction());
    }

}
