import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { GoBackAction, NavigateAction, PageLoadFailAction } from "../router/router.actions";

import {
    ActionTypes,
    ConnectCloverAction,
    CreateMidAction,
    CreateMidFailAction,
    CreateMidSucceedAction,
    DeleteMidAction,
    GetCloverDevicesAction,
    GetCloverDevicesFailAction,
    GetCloverDevicesSucceedAction,
    GetMidAction,
    GetMidFailAction,
    GetMidSucceedAction,
    GetNewMidAction,
    GetReassignDeviceMidAction,
    GetReassignDeviceMidFailAction,
    GetReassignDeviceMidSucceedAction,
    UpdateMidAction,
    UpdateMidFailAction,
    UpdateMidSucceedAction
} from "./mid.actions";
import { ConfirmationModalComponent, ConfirmationModalData,
    GlobalSpinnerService, VituCommonErrorEntityIdMustBeNumber } from "shared-lib";
import { MerchantsService } from "@admin_api/services/merchants.service";
import { MerchantProcessorDetailsDto } from "@admin_api/models/merchant-processor-details-dto";
import { GetAssociatedMidsAction, ResetAssociatedMidsAction } from "../mids/mids.actions";
import { PayPalConfigurationStatusEnum } from "@admin_api/models/pay-pal-configuration-status-enum";
import { AccountAgreementType } from "@admin_api/models/account-agreement-type";

@Injectable()
export class MidEffects {

    constructor(
        private actions$: Actions,
        private merchantsService: MerchantsService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService
    ) {}

    getMid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMidAction>(ActionTypes.GetMid),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetMidFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.merchantsService.merchantProcessorsGet({ id: action.id, merchantId: action.merchantId }).pipe(
                    switchMap(mid => of(new GetMidSucceedAction(mid))),
                    catchError((error) => of(new GetMidFailAction(error)))
                );
            })
        )
    );

    getNewMid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewMidAction>(ActionTypes.GetNewMid),
            switchMap(() => {
                const newMid: MerchantProcessorDetailsDto = {};
                return of(new GetMidSucceedAction(newMid));
            })
        ),
    );

    getMidSucceed = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMidSucceedAction>(ActionTypes.GetMidSucceed),
            filter(({mid}) => mid.isServiceFee),
            switchMap(({mid}) => [
                new ResetAssociatedMidsAction(),
                new GetAssociatedMidsAction(mid.merchantId, mid.id)
            ])
        )
    );

    getMidFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMidFailAction>(ActionTypes.GetMidFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateMid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateMidAction>(ActionTypes.UpdateMid),
            switchMap(({ id, merchantId, mid }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantProcessorsPut({ id, merchantId, body: mid }).pipe(
                        switchMap(() => of(new UpdateMidSucceedAction())),
                        catchError((error) => of(new UpdateMidFailAction(error)))
                    )
                )
            )
        )
    );

    updateMidSucceed = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateMidSucceedAction>(ActionTypes.UpdateMidSucceed),
            switchMap(() => of(GoBackAction()))
        )
    );

    deleteMid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteMidAction>(ActionTypes.DeleteMid),
            switchMap(({ id, merchantId }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete MID",
                        subtitle: "Are you sure you want to permanently delete this MID record?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ id, merchantId, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ id, merchantId }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantProcessorsDelete({ id, merchantId }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        )
    );

    createMid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<CreateMidAction>(ActionTypes.CreateMid),
            switchMap(({ merchantId, mid }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantProcessorsPost({ merchantId, body: mid }).pipe(
                        switchMap(() => of(new CreateMidSucceedAction())),
                        catchError((error) => of(new CreateMidFailAction(error)))
                    )
                )
            )
        )
    );

    createMidSucceed = createEffect(() =>
        this.actions$.pipe(
            ofType<CreateMidSucceedAction>(ActionTypes.CreateMidSucceed),
            switchMap(() => of(GoBackAction()))
        )
    );

    connectClover$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConnectCloverAction>(ActionTypes.ConnectClover),
            switchMap(({ id, merchantId, redirectUri }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Connect to Clover",
                        subtitle: "This will navigate to Clover which will update the Clover settings for this MID.",
                        subtitle2: "You will be redirected back to this page when complete.",
                        confirmButtonText: "Connect"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((connectConfirmed: boolean) => ({ id, merchantId, redirectUri, connectConfirmed })),
                )),
            filter(({ connectConfirmed }) => connectConfirmed),
            switchMap(({ id, merchantId, redirectUri }) =>
                this.globalSpinner.apply(
                    this.merchantsService.merchantProcessorsCloverOAuthGetCloverOAuthUrl({ id, merchantId, redirectUri }).pipe(
                        tap(({ redirectUrl }) => window.location.href = redirectUrl)
                    )
                )
            )
        ), {dispatch: false}
    );

    getDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetCloverDevicesAction>(ActionTypes.GetCloverDevices),
            switchMap(action =>
                this.merchantsService.merchantProcessorsCloverDevices({ id: action.id, merchantId: action.merchantId }).pipe(
                    switchMap(devices => of(new GetCloverDevicesSucceedAction(devices))),
                    catchError((error) => of(new GetCloverDevicesFailAction(error)))
                )
            )
        ),
    );

    getReassignDeviceMid$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetReassignDeviceMidAction>(ActionTypes.GetReassignDeviceMid),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetReassignDeviceMidFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.merchantsService.merchantProcessorsGet({ id: action.id, merchantId: action.merchantId }).pipe(
                    switchMap(mid => of(new GetReassignDeviceMidSucceedAction(mid))),
                    catchError((error) => of(new GetReassignDeviceMidFailAction(error)))
                );
            })
        )
    );

}
