/* tslint:disable */
/* eslint-disable */
export enum AdjustmentOrderBy {
  Id = 'Id',
  AdjustmentDate = 'AdjustmentDate',
  Amount = 'Amount',
  FundingDeposit = 'FundingDeposit',
  MID = 'MID',
  Reason = 'Reason',
  CreatedDate = 'CreatedDate'
}
