import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { GoBackAction, PageLoadFailAction } from "../router/router.actions";

import {
    ActionTypes,
    CreateOrganizationAction,
    DeleteOrganizationAction,
    GetOrganizationAction,
    GetOrganizationFailAction,
    GetOrganizationSucceedAction,
    GetNewOrganizationAction,
    UpdateOrganizationAction,
    AppendAncestorOrganizationAction,
    AppendAncestorOrganizationSucceedAction,
    AppendAncestorOrganizationFailAction
} from "./organization.actions";
import { ConfirmationModalComponent, ConfirmationModalData,
    GlobalSpinnerService, VituCommonErrorEntityIdMustBeNumber } from "shared-lib";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { GetMidsAction } from "../mids/mids.actions";
import { GetTidsAction } from "../tids/tids.actions";
import { OrganizationsService } from "@admin_api/services/organizations.service";
import { GetUsersAction } from "../users/users.actions";
import { GetOrganizationsAction, ResetOrganizationsAction } from "../organizations/organizations.actions";
import { OrganizationHierarchyModeEnum } from "@admin_api/models/organization-hierarchy-mode-enum";
import { PagerLargeSinglePageSize } from "../common";
import { GetMerchantHistoryAction } from "../merchant-history/merchant-history.actions";

@Injectable()
export class OrganizationEffects {

    constructor(
        private actions$: Actions,
        private organizationsService: OrganizationsService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService
    ) {}

    getOrganization$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetOrganizationAction>(ActionTypes.GetOrganization),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetOrganizationFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.organizationsService.organizationsGet({ id: action.id }).pipe(
                    switchMap(organization => of(new GetOrganizationSucceedAction(organization))),
                    catchError((error) => of(new GetOrganizationFailAction(error)))
                );
            })
        ),
    );

    getNewOrganization$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetNewOrganizationAction>(ActionTypes.GetNewOrganization),
            switchMap((action) => {
                const newOrganization: OrganizationDto = {};
                newOrganization.parentId = action.parentId;
                return of(new GetOrganizationSucceedAction(newOrganization, true));
            })
        ),
    );

    getOrganizationSucceed$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetOrganizationSucceedAction>(ActionTypes.GetOrganizationSucceed),
            switchMap(action =>
                [
                    new AppendAncestorOrganizationAction(action.organization),
                    ...((!action.isCreate && !action.organization.isMerchant)
                        ? [ new ResetOrganizationsAction(action.organization.id, OrganizationHierarchyModeEnum.DirectChildren, false),
                            new GetOrganizationsAction(1, PagerLargeSinglePageSize) ]
                        : []),
                    ...(!action.isCreate
                        ? [
                            new GetMidsAction(action.organization.id),
                            new GetTidsAction(action.organization.id),
                            new GetUsersAction(action.organization.id),
                            new GetMerchantHistoryAction(action.organization.id) ]
                        : [])
                ]
            )
        ),
    );

    getOrganizationFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetOrganizationFailAction>(ActionTypes.GetOrganizationFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateOrganization$ = createEffect(() => this.actions$.pipe(
            ofType<UpdateOrganizationAction>(ActionTypes.UpdateOrganization),
            switchMap(({ id, organization }) =>
                this.globalSpinner.apply(
                    this.organizationsService.organizationsPut({ id, body: organization }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        ));

    deleteOrganization$ = createEffect(() => this.actions$.pipe(
            ofType<DeleteOrganizationAction>(ActionTypes.DeleteOrganization),
            switchMap(({ id }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete Organization",
                        subtitle: "Are you sure you want to permanently delete this organization record?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ id, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ id }) =>
                this.globalSpinner.apply(
                    this.organizationsService.organizationsDelete({ id }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        ));

    createOrganization$ = createEffect(() => this.actions$.pipe(
            ofType<CreateOrganizationAction>(ActionTypes.CreateOrganization),
            switchMap(({ organization }) =>
                this.globalSpinner.apply(
                    this.organizationsService.organizationsPost({ body: organization }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        ));

    appendAncestorOrganization$ = createEffect(() =>

        this.actions$.pipe(
            ofType<AppendAncestorOrganizationAction>(ActionTypes.AppendAncestorOrganization),
            filter(action => (action.organization.parentId != null)),
            switchMap(action => {
                if (isNaN(action.organization.parentId)) {
                    return of(new AppendAncestorOrganizationFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.organizationsService.organizationsGet({ id: action.organization.parentId }).pipe(
                    switchMap(organization => of(new AppendAncestorOrganizationSucceedAction(organization))),
                    catchError((error) => of(new AppendAncestorOrganizationFailAction(error)))
                );
            })
        ),
    );

    appendAncestorOrganizationSucceed$ = createEffect(() =>

        this.actions$.pipe(
            ofType<AppendAncestorOrganizationSucceedAction>(ActionTypes.AppendAncestorOrganizationSucceed),
            map(action => new AppendAncestorOrganizationAction(action.organization))
        ),
    );

}
