import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getAdjustmentState = (store: IStore) => store.adjustment;

export const getAdjustmentInfo = createSelector(
    getAdjustmentState,
    adjustmentState => adjustmentState.record
);

export const getAdjustmentLoading = createSelector(
    getAdjustmentState,
    adjustmentState => adjustmentState.loading
);
