import { ActionTypes } from "./google-pay-keys.actions";
import { GooglePayKeysState } from "./google-pay-keys.state";

export const GooglePayKeysReducer = (
    state: GooglePayKeysState = new GooglePayKeysState(),
    action: any,
): GooglePayKeysState => {

    switch (action.type) {

        case ActionTypes.GetPaymentProcessingCertificates: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetPaymentProcessingCertificatesSucceed: {
            return {
                ...state,
                record: action.digitalWalletKeysDto,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetPaymentProcessingCertificatesFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: null
            };
        }

        default:
            return state;
    }
};
