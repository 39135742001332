import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";
import { ColumnDef, Pager, TableSorting } from "shared-lib";
import { Filter, Sorting } from "@admin_app/storage/inventory/inventory.state";
import { PosTerminalOrderBy } from "@admin_api/models/pos-terminal-order-by";
import { OrganizationDto } from "@admin_api/models/organization-dto";

@Component({
    selector: "app-devices-log",
    templateUrl: "./devices-log.component.html",
    styleUrls: ["./devices-log.component.less"],
})
export class DevicesLogComponent {

    @Input() inventory: Array<MerchantTerminalDto> = [];
    @Input() inventoryLoading = false;
    @Input() inventoryError: Error;

    @Input() device: any = {};
    @Input() deviceLoading = false;

    @Input() sorting: any = {
        orderBy: null,
        orderDirection: null
    };
    @Output() sortingChanged = new EventEmitter<Sorting>();

    @Input() filter: Filter;
    @Output() filterChanged = new EventEmitter<Filter>();

    @Input() pager: Pager;
    @Output() pageChanged = new EventEmitter<number>();

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    readonly columnDefs: ColumnDef[];

    constructor() {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: PosTerminalOrderBy.MerchantName, title: "Merchant Name", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
            {id: PosTerminalOrderBy.MID, title: "MID", flexWidthBasisInPixels: 130, flexWidthGrow: 2, canSort: true},
            {id: PosTerminalOrderBy.TID, title: "TID", flexWidthBasisInPixels: 130, flexWidthGrow: 2, canSort: true},
            {id: PosTerminalOrderBy.Location, title: "Location", flexWidthBasisInPixels: 90, flexWidthGrow: 4, canSort: true},
            {id: PosTerminalOrderBy.DeviceSerial, title: "Device Serial", flexWidthBasisInPixels: 120, flexWidthGrow: 2, canSort: true},
            {id: PosTerminalOrderBy.DeviceName, title: "Device Name", flexWidthBasisInPixels: 125, flexWidthGrow: 4, canSort: true},
            {id: PosTerminalOrderBy.DeviceManufacturer, title: "Device Manufacturer",
                flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
            {id: PosTerminalOrderBy.DeviceModel, title: "Device Model", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
//            {id: "gateway", title: "Gateway", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
//            {id: "processor", title: "Processor", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
            {id: PosTerminalOrderBy.Status, title: "Status", flexWidthBasisInPixels: 145, flexWidthGrow: 1, canSort: true},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy,
            orderDirection: sorting.orderDirection
        });
    }

}
