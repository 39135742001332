<div class="outer-container" (mousedown)="onMouseDown()">

    <div mat-dialog-title>
        <div>Download Report</div>
        <i class="fal fa-times global-dialog-close-button" (mousedown)="onCancel($event)"></i>
    </div>

    <div mat-dialog-content class="content">

        <div class="first-row">

            <div>
                <div class="label">Report Type</div>
                <div class="variable-text">{{ reportType }}</div>
            </div>

        </div>

        <form class="form" [formGroup]="reportForm" [libVituForm]="reportForm" (libVituFormSubmit)="onSubmit()">

            <div class="form-fields-container">

                <ng-container [ngSwitch]="data.reportType">

                    <ng-container *ngSwitchCase="ReportType.STATEMENT">

                        <lib-vitu-form-field-select
                            class="form-input"
                            id="merchantField"
                            [formGroup]="reportForm"
                            formFieldName="merchantField"
                            label="Merchant"
                            [options]="merchants"
                            optionLabelKey="name"
                            optionValueKey="id"
                            (selectionChanged)="onMerchantChanged()">
                        </lib-vitu-form-field-select>

                        <div class="form-double-input">

                            <lib-vitu-form-field-select
                                class="form-input"
                                id="yearField"
                                [formGroup]="reportForm"
                                formFieldName="yearField"
                                label="Year"
                                [options]="years"
                                (selectionChanged)="onYearChanged()">
                            </lib-vitu-form-field-select>

                            <lib-vitu-form-field-select
                                class="form-input"
                                id="monthField"
                                [formGroup]="reportForm"
                                formFieldName="monthField"
                                label="Month"
                                [options]="optionsForMonths"
                                optionLabelKey="label"
                                optionValueKey="value"
                                (selectionChanged)="onMonthChanged()">
                            </lib-vitu-form-field-select>

                        </div>

                        <lib-vitu-form-field-select
                            class="form-input"
                            id="statementField"
                            [formGroup]="reportForm"
                            formFieldName="statementField"
                            label="Statement"
                            labelEmpty="No Statements"
                            [labelEmptyCondition]="noStatements"
                            [options]="statements"
                            optionLabelKey="description"
                            optionValueKey="id">
                        </lib-vitu-form-field-select>

                    </ng-container>

                    <ng-container *ngSwitchCase="ReportType.RECONCILIATION">

                        <lib-vitu-form-field-date
                            class="form-input"
                            id="dateSingleField"
                            [formGroup]="reportForm"
                            formFieldName="dateSingleField"
                            label="Date"
                            restrictFuture
                            singleDateMode
                            readonly>
                        </lib-vitu-form-field-date>

                        <lib-vitu-form-field-select
                            class="form-input"
                            id="merchantField"
                            [formGroup]="reportForm"
                            formFieldName="merchantField"
                            label="Merchant"
                            [options]="merchants"
                            optionLabelKey="name"
                            optionValueKey="id"
                            (selectionChanged)="onMerchantChanged()">
                        </lib-vitu-form-field-select>

                        <lib-vitu-form-field-select
                            class="form-input show-mids-select"
                            id="midField"
                            [formGroup]="reportForm"
                            formFieldName="midField"
                            label="MID"
                            labelEmpty="Selected merchant contains no MIDs"
                            [labelEmptyCondition]="noMids"
                            [options]="mids"
                            optionLabelKey="mid"
                            optionValueKey="id">
                        </lib-vitu-form-field-select>

                    </ng-container>

                    <ng-container *ngSwitchCase="ReportType.FUNDING">

                        <lib-vitu-form-field-date
                            class="form-input"
                            id="dateRangeField"
                            [formGroup]="reportForm"
                            formFieldName="dateRangeField"
                            label="Date Range"
                            restrictFuture
                            readonly>
                        </lib-vitu-form-field-date>

                        <lib-vitu-form-field-select
                            class="form-input"
                            id="merchantField"
                            [formGroup]="reportForm"
                            formFieldName="merchantField"
                            multiple
                            label="Merchant(s)"
                            labelEmpty="No Merchants"
                            [options]="merchants"
                            optionLabelKey="name"
                            optionValueKey="id"
                            (selectionChanged)="onMerchantsChanged()">
                        </lib-vitu-form-field-select>

                        <div class="all-mids-container">
                            <span class="all-mids-label">All MIDs for selected merchant(s)</span>
                            <div class="checkbox-container">
                                <mat-checkbox #cb
                                    class="custom-checkbox"
                                    formControlName="allMidsField"
                                    [formControl]="getFormControl('allMidsField')"
                                    (change)="onAllMidsToggled(cb.checked)">
                                </mat-checkbox>
                            </div>    
                        </div>

                        <lib-vitu-form-field-select
                            class="form-input"
                            [ngClass]="{'show-mids-select': showOnlyMids}"
                            id="midField"
                            [formGroup]="reportForm"
                            formFieldName="midField"
                            multiple
                            label="Only include these MID(s)"
                            labelEmpty="Selected merchants contain no MIDs"
                            [labelEmptyCondition]="noMids"
                            [options]="mids"
                            optionLabelKey="mid"
                            optionValueKey="id"
                            [groups]="midGroups">
                        </lib-vitu-form-field-select>

                    </ng-container>

                    <ng-container *ngSwitchCase="ReportType.TRANSACTIONS">

                        <lib-vitu-form-field-date
                            class="form-input"
                            id="dateRangeField"
                            [formGroup]="reportForm"
                            formFieldName="dateRangeField"
                            label="Date Range"
                            restrictFuture
                            readonly>
                        </lib-vitu-form-field-date>

                        <lib-vitu-form-field-select
                            class="form-input"
                            id="merchantField"
                            [formGroup]="reportForm"
                            formFieldName="merchantField"
                            multiple
                            label="Merchant(s)"
                            labelEmpty="No Merchants"
                            [options]="merchants"
                            optionLabelKey="name"
                            optionValueKey="id"
                            (selectionChanged)="onMerchantsChanged()">
                        </lib-vitu-form-field-select>

                        <div class="all-mids-container">
                            <span class="all-mids-label">All MIDs for selected merchant(s)</span>
                            <div class="checkbox-container">
                                <mat-checkbox #cb
                                    class="custom-checkbox"
                                    formControlName="allMidsField"
                                    [formControl]="getFormControl('allMidsField')"
                                    (change)="onAllMidsToggled(cb.checked)">
                                </mat-checkbox>
                            </div>    
                        </div>

                        <lib-vitu-form-field-select
                            class="form-input"
                            [ngClass]="{'show-mids-select': showOnlyMids}"
                            id="midField"
                            [formGroup]="reportForm"
                            formFieldName="midField"
                            multiple
                            label="Only include these MID(s)"
                            labelEmpty="Selected merchants contain no MIDs"
                            [labelEmptyCondition]="noMids"
                            [options]="mids"
                            optionLabelKey="mid"
                            optionValueKey="id"
                            [groups]="midGroups">
                        </lib-vitu-form-field-select>

                    </ng-container>

                    <ng-container *ngSwitchDefault>

                        <lib-vitu-form-field-date
                            class="form-input"
                            id="dateRangeField"
                            [formGroup]="reportForm"
                            formFieldName="dateRangeField"
                            label="Date Range"
                            restrictFuture
                            readonly>
                        </lib-vitu-form-field-date>

                        <lib-vitu-form-field-select
                            class="form-input"
                            id="merchantField"
                            [formGroup]="reportForm"
                            formFieldName="merchantField"
                            label="Merchant"
                            [options]="merchants"
                            optionLabelKey="name"
                            optionValueKey="id"
                            (selectionChanged)="onMerchantChanged()">
                        </lib-vitu-form-field-select>

                        <lib-vitu-form-field-select
                            class="form-input"
                            id="midField"
                            [formGroup]="reportForm"
                            formFieldName="midField"
                            multiple
                            label="MID(s)"
                            labelEmpty="No MIDs"
                            [labelEmptyCondition]="noMids"
                            [options]="mids"
                            optionLabelKey="mid"
                            optionValueKey="id">
                        </lib-vitu-form-field-select>

                    </ng-container>

                </ng-container>

            </div>

            <lib-modal-error-message *ngIf="data?.errorMessageSubject | async as errorMessage" [message]="errorMessage">
            </lib-modal-error-message>

            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()"
                    [isModal]="true">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Download"
                    [neverDisable]="true">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </form>
    </div>

</div>
