import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap } from "rxjs/operators";
import { UsersService } from "@admin_api/services/users.service";
import { ActionTypes, GetUsersAction, GetUsersFailAction, GetUsersSucceedAction } from "./users.actions";
import { of } from "rxjs";
import { PagerLargeSinglePageSize } from "../common";

@Injectable()
export class UsersEffects {

    constructor(
        private actions$: Actions,
        private usersService: UsersService
    ) {}

    getUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetUsersAction>(ActionTypes.GetUsers),
            switchMap((action) =>
                this.usersService.usersSearch({ organizationId: action.merchantId, "Pager.PageSize": PagerLargeSinglePageSize }).pipe(
                    switchMap((users) => of(new GetUsersSucceedAction(users))),
                    catchError((error) => of(new GetUsersFailAction(error)))
                )
            )
        ),
    );

}
