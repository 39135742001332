import { Action } from "@ngrx/store";
import { Filter, Sorting } from "./bin-files.state";
import { PagedDataDtoOfBinFileDto } from "@admin_api/models/paged-data-dto-of-bin-file-dto";

export enum ActionTypes {
    GetBinFiles = "[BIN_FILES] GetBinFiles",
    GetBinFilesSucceed = "[BIN_FILES] GetBinFilesSucceed",
    GetBinFilesFail = "[BIN_FILES] GetBinFilesFail",
    SortBinFiles = "[BIN_FILES] SortBinFiles",
    FilterBinFiles = "[BIN_FILES] FilterBinFiles",
    PageChangedBinFiles = "[BIN_FILES] PageChangedBinFiles"
}

export class GetBinFilesAction implements Action {

    constructor(public page: number, public pageSize?: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetBinFiles;
}

export class GetBinFilesSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfBinFileDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetBinFilesSucceed;
}

export class GetBinFilesFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetBinFilesFail;
}

export class SortBinFilesAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortBinFiles;
}

export class FilterBinFilesAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterBinFiles;
}

export class PageChangedBinFilesAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedBinFiles;
}
