import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getOrganizationsState = (store: IStore) => store.organizations;

export const getOrganizations = createSelector(
    getOrganizationsState,
    organizationsState => organizationsState.record,
);

export const getOrganizationsLoading = createSelector(
    getOrganizationsState,
    organizationsState => organizationsState.loading,
);

export const getOrganizationsError = createSelector(
    getOrganizationsState,
    organizationsState => organizationsState.error,
);

export const getOrganizationsSorting = createSelector(
    getOrganizationsState,
    organizationsState => organizationsState.sorting,
);

export const getOrganizationsPager = createSelector(
    getOrganizationsState,
    organizationsState => organizationsState.pager,
);

export const getOrganizationsFilter = createSelector(
    getOrganizationsState,
    organizationsState => organizationsState.filter,
);
