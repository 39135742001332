import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { TableSorting, Pager, ActionButtonKind } from "shared-lib";

import { Sorting, Filter } from "@admin_app/storage/transactions/transactions.state";
import { TransactionDto } from "@admin_api/models/transaction-dto";

import { OrganizationDto } from "@admin_api/models/organization-dto";;

type RowsData = TransactionDto[];

@Component({
    selector: "app-transaction-log",
    templateUrl: "./transaction-log.component.html",
    styleUrls: ["./transaction-log.component.less"],
})
export class TransactionLogComponent {

    @Input() loading = false;
    @Input() error: Error;
    @Input() exportLoading = false;
    @Input() rowsData: RowsData;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;
    @Input() filter: Filter;

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    @Output() filterChanged = new EventEmitter<Filter>();
    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() exportToCsv = new EventEmitter<void>();
    @Output() bulkUpdate = new EventEmitter<void>();

    onClickBulkUpdate() {
        this.bulkUpdate.emit();
    }

    ActionButtonKind = ActionButtonKind;

    constructor(private router: Router) {}

    onSortingChanged(sort: Sorting|TableSorting) {
        const sorting = sort as Sorting;
        this.sortingChanged.emit(sorting);
    }

    onRowSelected(rowData: string): void {
        const token = rowData as string;
        this.router.navigate([`/dashboard/transactions/${token}`]);
    }

    exportButtonDisabled() {
        return this.exportLoading
            || !this.filter?.localDateInterval?.from
            || !this.filter?.localDateInterval?.to;
    }

}
