import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, catchError, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import {
    GetPaymentDetailAction,
    ActionTypes,
    GetPaymentDetailSucceedAction,
    GetPaymentFeesAction,
    GetPaymentFeesSucceedAction,
    GetPaymentDetailFailAction,
    GetPaymentFeesFailAction
} from "./payment.actions";
import { PaymentsService } from "@admin_api/services/payments.service";
import { GetTransactionSiblingsDetailAction } from "../transaction-siblings/transaction-siblings.actions";
import { GenericNoOpAction } from "../generic.actions";

@Injectable()
export class PaymentEffects {

    constructor(
        private actions: Actions,
        private paymentsService: PaymentsService
    ) { }

    getPayment = createEffect(() => this.actions.pipe(
            ofType<GetPaymentDetailAction>(ActionTypes.GetPaymentDetail),
            switchMap(action => this.paymentsService.paymentsGet({ token: action.token })
                    .pipe(
                        mergeMap(result => of(new GetPaymentDetailSucceedAction(result, action.getRelations))),
                        catchError((error) => of(new GetPaymentDetailFailAction(error)))
                    ))
        ));

    getPaymentSucceed = createEffect(() => this.actions.pipe(
            ofType<GetPaymentDetailSucceedAction>(ActionTypes.GetPaymentDetailSucceed),
            switchMap(action => [
                (action.getRelations)
                    ? new GetTransactionSiblingsDetailAction(action.record.transactions.map(transaction => transaction.token))
                    : new GenericNoOpAction(),
                new GetPaymentFeesAction(action.record.token)
            ])
        ));

    getPaymentFees = createEffect(() => this.actions.pipe(
            ofType<GetPaymentFeesAction>(ActionTypes.GetPaymentFees),
            switchMap(action => this.paymentsService.paymentsGetFees({ token: action.token })
                    .pipe(
                        mergeMap(result => of(new GetPaymentFeesSucceedAction(result))),
                        catchError((error) => of(new GetPaymentFeesFailAction(error)))
                    ))
        ));

}
