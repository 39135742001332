import { Action } from "@ngrx/store";

export enum ActionTypes {
    RevokePaymentProcessingCertificateKey = "[APPLE_PAY_KEY] RevokePaymentProcessingCertificateKey",
    GetPaymentProcessingCsr = "[APPLE_PAY_KEY] GetPaymentProcessingCsr",
    UploadPaymentProcessingCertificate = "[APPLE_PAY_KEY] UploadPaymentProcessingCertificate",
    RevokeMerchantIdentityKey = "[APPLE_PAY_KEY] RevokeMerchantIdentityKey",
    GetMerchantIdentityCsr = "[APPLE_PAY_KEY] GetMerchantIdentityCsr",
    UploadMerchantIdentityCertificate = "[APPLE_PAY_KEY] UploadMerchantIdentityCertificate"
}

export class RevokePaymentProcessingCertificateKeyAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.RevokePaymentProcessingCertificateKey;
}

export class GetPaymentProcessingCsrAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetPaymentProcessingCsr;
}

export class UploadPaymentProcessingCertificateAction implements Action {

    constructor() {}

    readonly type = ActionTypes.UploadPaymentProcessingCertificate;
}

export class RevokeMerchantIdentityKeyAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.RevokeMerchantIdentityKey;
}

export class GetMerchantIdentityCsrAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetMerchantIdentityCsr;
}

export class UploadMerchantIdentityCertificateAction implements Action {

    constructor() {}

    readonly type = ActionTypes.UploadMerchantIdentityCertificate;
}
