<app-fee-plan-details
    [isCreate]="isCreate"
    [feePlan]="feePlan$ | async"
    [feePlanLoading]="feePlanLoading$ | async"
    [feePlanError]="feePlanError$ | async"
    [feePlanMerchantProcessors]="feePlanMerchantProcessors$ | async"
    [feePlanMerchantProcessorsLoading]="feePlanMerchantProcessorsLoading$ | async"
    [feePlanMerchantProcessorsError]="feePlanMerchantProcessorsError$ | async"
    [feeTypes]="feeTypes$ | async"
    [feeTypesLoading]="feeTypesLoading$ | async"
    [feeTypesError]="feeTypesError$ | async"
    (deleteFeePlan)="deleteFeePlan($event)"
    (updateFeePlan)="updateFeePlan($event)"
    (createFeePlan)="createFeePlan($event)"
    (cancel)="onCancel()">
</app-fee-plan-details>
