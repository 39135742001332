import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SolutionEnum } from "@admin_api/models/solution-enum";
import { Filter } from "@admin_app/storage/organizations/organizations.state";
import { ConfigDto } from "@admin_app/storage/config/config.state";
;

@Component({
    selector: "app-organizations-log-filters",
    templateUrl: "./organizations-log-filters.component.html",
    styleUrls: ["./organizations-log-filters.component.less"]
})
export class OrganizationsLogFiltersComponent implements OnInit {

    @Input() config: ConfigDto;
    @Input() filter: Filter;
    @Output() filterChanged = new EventEmitter<Filter>();

    solutions: Array<SolutionEnum>;

    filterForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.solutions = Object.values(SolutionEnum);
    }

    ngOnInit() {
        this.filterForm = this.fb.group({
            solution: [null],
            mid: [null],
            hierarchyMode: [this.filter.hierarchyMode],
            hierarchyOrganizationId: [this.filter.hierarchyOrganizationId],
            merchantsOnly: [this.filter.merchantsOnly]
        });
    }

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

    getDisplayTextForSolution(solutionVal: string): string {
        let text = "";
        if (solutionVal && this.config?.solutionLookup) {
            const found = this.config.solutionLookup.filter(item => (item.id === solutionVal));
            if (found?.length) {
                text = found[0].name;
            }
        }
        return text;
    }

}
