import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@admin_app/storage/store";

import * as FundingItemActions from "@admin_app/storage/funding-item/funding-item.actions";
import * as FundingItemSelectors from "@admin_app/storage/funding-item/funding-item.selectors";

import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { FundingResultDetails } from "@admin_api/models/funding-result-details";
import {
    ExportTransactionsToCsvAction,
    FilterFundingItemTransactionsAction,
    PageChangedFundingItemTransactionsAction,
    RequestSettlementAction,
    ResendFundingAction,
    SortFundingItemTransactionsAction
} from "@admin_app/storage/funding-item/funding-item.actions";
import { TransactionsFilter, TransactionsSorting } from "@admin_app/storage/funding-item/funding-item.state";

@Component({
    selector: "app-funding-item",
    templateUrl: "./funding-item.component.html",
    styleUrls: ["./funding-item.component.less"]
})
export class FundingItemComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    fundingItem$ = this.store.select(FundingItemSelectors.getFundingItem);
    fundingItemLoading$ = this.store.select(FundingItemSelectors.getFundingItemLoading);
    fundingItemError$ = this.store.select(FundingItemSelectors.getFundingItemError);
    fundingItemTransactions$ = this.store.select(FundingItemSelectors.getFundingItemTransactions);
    fundingItemTransactionsLoading$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsLoading);
    fundingItemTransactionsError$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsError);
    fundingItemTransactionsPager$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsPager);
    fundingItemTransactionsSorting$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsSorting);
    fundingItemTransactionsFilter$ = this.store.select(FundingItemSelectors.getFundingItemTransactionsFilter);
    fundingItemExportLoading$ = this.store.select(FundingItemSelectors.getFundingItemExportLoading);
    fundingItemMidFees$ = this.store.select(FundingItemSelectors.getFundingItemMidFees);
    fundingItemMidFeesLoading$ = this.store.select(FundingItemSelectors.getFundingItemMidFeesLoading);
    fundingItemMidFeesError$ = this.store.select(FundingItemSelectors.getFundingItemMidFeesError);
    fundingItemAdjustments$ = this.store.select(FundingItemSelectors.getFundingItemAdjustments);
    fundingItemAdjustmentsLoading$ = this.store.select(FundingItemSelectors.getFundingItemAdjustmentsLoading);
    fundingItemAdjustmentsError$ = this.store.select(FundingItemSelectors.getFundingItemAdjustmentsError);

    ngOnInit() {

        this.store.dispatch(new FundingItemActions.ResetFundingItemAction());
        this.store.dispatch(new FundingItemActions.GetFundingItemAction(+this.route.snapshot.params.id));
    }

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

    onResend(event: FundingResultDetails): void {

        this.store.dispatch(new ResendFundingAction(event));
    }

    onRequestSettlement(id: number): void {

        this.store.dispatch(new RequestSettlementAction(id));
    }

    onFundingItemTransactionsPageChanged(payload: {id: number; page: number}) {

        this.store.dispatch(new PageChangedFundingItemTransactionsAction(payload.id, payload.page));
    }

    onFundingItemTransactionsSortingChanged(payload: {id: number; sorting: TransactionsSorting}) {

        this.store.dispatch(new SortFundingItemTransactionsAction(payload.id, payload.sorting));
    }

    onFundingItemTransactionsFilterChanged(payload: {id: number; filter: TransactionsFilter}) {

        this.store.dispatch(new FilterFundingItemTransactionsAction(payload.id, payload.filter));
    }

    onExportTransactionsToCsv(id: number) {

        this.store.dispatch(new ExportTransactionsToCsvAction(id));
    }

}
