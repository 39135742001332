import { ModalActionType } from "@admin_app/shared/modal-action";
import { IStore } from "@admin_app/storage/store";
import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { ActionButtonKind } from "shared-lib";
import { PairDeviceModalAction, PairDeviceParams } from "./pair-device-modal-action";

@Component({
    selector: "app-pair-device-modal",
    templateUrl: "./pair-device-modal.component.html",
    styleUrls: ["./pair-device-modal.component.less"]
})
export class PairDeviceModalComponent {

    get formControls() {
        return this.pairDeviceForm && this.pairDeviceForm.controls;
    }

    pairDeviceForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IStore>,
        public dialogRef: MatDialogRef<PairDeviceModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            sourceMerchantId: number;
            sourceMerchantProcessorId: number;
            sourceMerchantTerminalId: number;
            errorMessageSubject: Subject<string>;
            modalActionSubject: Subject<PairDeviceModalAction>;
        }
    ) {
        this.pairDeviceForm = this.fb.group({
            userCodeField: [null, [Validators.required]]
        });
    }

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ActionButtonKind = ActionButtonKind;

    getFormControl(name: string): UntypedFormControl {
        return this.formControls[name] as UntypedFormControl;
    }

    onMouseDown() {
        this.data.errorMessageSubject.next("");
    }

    onCancel(event?: any) {
        if (event) {
            event.preventDefault();
        }
        this.data.modalActionSubject.next(new PairDeviceModalAction(ModalActionType.CANCEL));
    }

    onSubmit() {
        const params: PairDeviceParams = {
            merchantTerminalId: this.data.sourceMerchantTerminalId,
            sourceMerchantId: this.data.sourceMerchantId,
            userCode: this.getSubmitValueForField("userCodeField")
        };

        this.data.modalActionSubject.next(new PairDeviceModalAction(ModalActionType.CONFIRM, params));
    }

    private getSubmitValueForField(fieldName: string): any {
        const fieldValue = this.pairDeviceForm.get(fieldName).value;
        return (fieldValue ? fieldValue : undefined);
    }

}
