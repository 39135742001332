import { ModalActionType } from "@admin_app/shared/modal-action";
import { GetMidsAction, ResetMidsAction } from "@admin_app/storage/mids/mids.actions";
import { getMids, getMidsLoading } from "@admin_app/storage/mids/mids.selectors";
import { IStore } from "@admin_app/storage/store";
import { OnDestroy } from "@angular/core";
import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { Store } from "@ngrx/store";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { MerchantProcessorDto } from "@admin_api/models/merchant-processor-dto";
import { PosTerminalStatusEnum } from "@admin_api/models/pos-terminal-status-enum";
import { Subject, Subscription } from "rxjs";
import { ActionButtonKind } from "shared-lib";
import { ReassignDeviceModalAction, ReassignDeviceParams } from "./reassign-device-modal-action";
import { GetReassignDeviceMidAction, ResetReassignDeviceMidAction } from "@admin_app/storage/mid/mid.actions";
import { MerchantProcessorDetailsDto } from "@admin_api/models/merchant-processor-details-dto";
import { getReassignDeviceMid, getReassignDeviceMidLoading } from "@admin_app/storage/mid/mid.selectors";
import { PagerLargeSinglePageSize } from "@admin_app/storage/common";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";

@Component({
    selector: "app-reassign-device-modal",
    templateUrl: "./reassign-device-modal.component.html",
    styleUrls: ["./reassign-device-modal.component.less"]
})
export class ReassignDeviceModalComponent implements OnDestroy {

    PosTerminalStatusEnum = PosTerminalStatusEnum;

    get formControls() {
        return this.reassignDeviceForm && this.reassignDeviceForm.controls;
    }

    get deviceStatuses() {
        return this.statuses.filter(status => status !== PosTerminalStatusEnum.Reassigned);
    }

    reassignDeviceForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IStore>,
        public dialogRef: MatDialogRef<ReassignDeviceModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            sourceMerchantId: number;
            sourceMerchantProcessorId: number;
            sourceMerchantTerminalId: number;
            errorMessageSubject: Subject<string>;
            modalActionSubject: Subject<ReassignDeviceModalAction>;
        }
    ) {
        this.statuses = Object.values(PosTerminalStatusEnum).sort();

        this.subscriptions.add(this.merchants$.subscribe((merchants: OrganizationDto[]) => {
            this.merchants = merchants;
        }));
        this.subscriptions.add(this.merchantsLoading$.subscribe((merchantsLoading: boolean) => {
            this.merchantsLoading = merchantsLoading;
        }));
        this.subscriptions.add(this.mids$.subscribe((mids: MerchantProcessorDto[]) => {
            this.mids = mids;
            this.mids.forEach((mid: any) => {
                const dbaLabel = mid.dba ?? `—`;
                mid.label = `${mid.mid} (${dbaLabel})`;
            });
        }));
        this.subscriptions.add(this.midsLoading$.subscribe((midsLoading: boolean) => {
            this.midsLoading = midsLoading;
        }));

        this.subscriptions.add(this.reassignDeviceMid$.subscribe((mid: MerchantProcessorDetailsDto) => {
            this.reassignDeviceMid = mid;
            let newCloverMerchantId = "";
            if (mid?.gatewayConfig?.clover) {
                newCloverMerchantId = mid.gatewayConfig.clover.cloverMID;
            }
            this.reassignDeviceForm?.get("cloverMerchantIdField")?.setValue(newCloverMerchantId);
            this.reassignDeviceForm?.get("midField")?.updateValueAndValidity();
        }));
        this.subscriptions.add(this.reassignDeviceMidLoading$.subscribe((loading: boolean) => {
            this.reassignDeviceMidLoading = loading;
        }));

        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));

        this.store.dispatch(new ResetMidsAction());
        this.store.dispatch(new ResetReassignDeviceMidAction());

        this.reassignDeviceForm = this.fb.group({
            merchantField: [null, [Validators.required]],
            midField: [null, [Validators.required, this.midHasCloverMerchantId.bind(this)]],
            maiField: [null, [Validators.required]],
            dbaField: [null, [Validators.required]],
            deviceNameField: [null, [Validators.required]],
            deviceLocationField: [null, [Validators.required]],
            cloverMerchantIdField: [null, [Validators.required]],
            statusField: [null, [Validators.required]]
        });
    }

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    merchants: OrganizationDto[] = [];
    merchantsLoading = false;

    mids$ = this.store.select(getMids);
    midsLoading$ = this.store.select(getMidsLoading);

    mids: MerchantProcessorDto[] = [];
    midsLoading = false;

    reassignDeviceMid$ = this.store.select(getReassignDeviceMid);
    reassignDeviceMidLoading$ = this.store.select(getReassignDeviceMidLoading);

    reassignDeviceMid: MerchantProcessorDetailsDto;
    reassignDeviceMidLoading = false;

    statuses: Array<any>;

    private subscriptions = new Subscription();

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ActionButtonKind = ActionButtonKind;

    midHasCloverMerchantId = (c: UntypedFormControl) => {
        const mid = this.reassignDeviceForm?.get("midField")?.value;
        const cloverMerchantId = this.reassignDeviceForm?.get("cloverMerchantIdField")?.value;
        const valid = ((mid == null) || (cloverMerchantId?.length > 0));
        return valid ? null : {
            customError1: {
                valid: false
            }
        };
    };

    getFormControl(name: string): UntypedFormControl {
        return this.formControls[name] as UntypedFormControl;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onMerchantChanged() {
        const merchantId = this.reassignDeviceForm.get("merchantField").value;
        this.resetMidField();
        this.store.dispatch(new ResetReassignDeviceMidAction());
        this.store.dispatch(new GetMidsAction(merchantId));
    }

    onMidChanged() {
        const merchantId = this.reassignDeviceForm.get("merchantField").value;
        const merchantProcessorId = this.reassignDeviceForm.get("midField").value;
        this.store.dispatch(new GetReassignDeviceMidAction(merchantProcessorId, merchantId));
    }

    onMouseDown() {
        this.data.errorMessageSubject.next("");
    }

    onCancel(event?: any) {
        if (event) {
            event.preventDefault();
        }
        this.data.modalActionSubject.next(new ReassignDeviceModalAction(ModalActionType.CANCEL));
    }

    onSubmit() {
        if (this.reassignDeviceMidLoading) {
            return;
        }

        const params: ReassignDeviceParams = {
            merchantTerminalId: this.data.sourceMerchantTerminalId,
            sourceMerchantId: this.data.sourceMerchantId,
            merchantId: this.getSubmitValueForField("merchantField"),
            merchantProcessorId: this.getSubmitValueForField("midField"),
            mai: this.getSubmitValueForField("maiField"),
            dba: this.getSubmitValueForField("dbaField"),
            deviceName: this.getSubmitValueForField("deviceNameField"),
            deviceLocation: this.getSubmitValueForField("deviceLocationField"),
            cloverMerchantId: this.getSubmitValueForField("cloverMerchantIdField"),
            status: this.getSubmitValueForField("statusField")
        };

        this.data.modalActionSubject.next(new ReassignDeviceModalAction(ModalActionType.CONFIRM, params));
    }

    get noMids(): boolean {
        return (!this.midsLoading) && !(this.mids?.length);
    }

    private getSubmitValueForField(fieldName: string): any {
        const fieldValue = this.reassignDeviceForm.get(fieldName).value;
        return (fieldValue ? fieldValue : undefined);
    }

    private resetMidField(): void {
        this.reassignDeviceForm.get("midField").setValue(null);
        this.store.dispatch(new ResetMidsAction());
    }

}
