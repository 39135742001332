import { TransactionDto } from "@admin_api/models/transaction-dto";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { TransactionOrderBy } from "@admin_api/models/transaction-order-by";
import { CardBrandEnum } from "@admin_api/models/card-brand-enum";
import { TransactionStatus } from "@admin_api/models/transaction-status";
import { DateRange, Pager, FilterBase, createFilter, getInitialLocalFilterDateRangeLastTwoWeeks } from "shared-lib";
import { GatewayEnum } from "@admin_api/models/gateway-enum";
import { DigitalWalletEnum } from "@admin_api/models/digital-wallet-enum";
import { TransactionType } from "@admin_api/models/transaction-type";

export class Sorting {
    orderBy: null | TransactionOrderBy = TransactionOrderBy.DateAndTime;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    localDateSettledInterval: DateRange = undefined;
    localDateFundedInterval: DateRange = undefined;
    transactionId: string = undefined;
    amount: number = undefined;
    authCode: string = undefined;
    first6: string = undefined;
    last4: string = undefined;
    payerName: string = undefined;
    cardBrands: Array<CardBrandEnum> = undefined;
    types: Array<TransactionType> = undefined;
    statuses: Array<TransactionStatus> = undefined;
    gateways: Array<GatewayEnum> = undefined;
    tid: string = undefined;
    mid: string = undefined;
    merchantId: number = undefined;
    dba: string = undefined;
    isServiceFee: boolean = undefined;
    digitalWallets: Array<DigitalWalletEnum> = undefined;
    token: string = undefined;
}

export class TransactionsState {
    loading = false;
    error = null;
    exportLoading = false;
    exportError = null;
    rows: TransactionDto[];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
