import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import {
    ActionTypes,
    FilterPaymentsAction,
    SortPaymentsAction,
    PageChangedPaymentsAction,
    ExportPaymentsToCsvAction,
    GetPaymentsAction,
    GetPaymentsSucceedAction,
    ExportPaymentsToCsvFailAction,
    GetPaymentsFailAction,
    BulkUpdateAction,
} from "./payments.actions";
import { switchMap, withLatestFrom, tap, catchError, filter as filterOperator } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { PaymentsService } from "@admin_api/services/payments.service";
import { IStore } from "../store";
import { Store } from "@ngrx/store";
import { GlobalSpinnerService, LocalTimePoint, VituToastService, VituToastTone } from "shared-lib";
import { Filter, Sorting } from "./payments.state";
import { getPaymentsState } from "./payments.selectors";
import { UploadFileModalAction } from "@admin_app/shared/upload-file-modal/upload-file-modal-action";
import { MatDialog } from "@angular/material/dialog";
import { UploadFileModalComponent } from "@admin_app/shared/upload-file-modal/upload-file-modal.component";
import { ModalActionType } from "@admin_app/shared/modal-action";
import { GetTransactionsAction } from "../transactions/transactions.actions";
import { RouterNavigationAction, ROUTER_NAVIGATED } from "@ngrx/router-store";

@Injectable()
export class PaymentsEffects {

    constructor(
        private actions: Actions,
        private paymentsService: PaymentsService,
        private store: Store<IStore>,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private toast: VituToastService
    ) { }

    initPaymentsPage = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigationAction) => new RegExp(/dashboard\/payments$/).test(action.payload.routerState.url)),
            switchMap(() =>
                [ new GetPaymentsAction(1) ]
            )
        )
    );

    filterList = createEffect(() =>
        this.actions.pipe(
            ofType<FilterPaymentsAction>(ActionTypes.FilterPayments),
            switchMap(action => of(new GetPaymentsAction(1, action.filter)))
        ),
    );

    sortList = createEffect(() =>
        this.actions.pipe(
            ofType<SortPaymentsAction>(ActionTypes.SortPayments),
            switchMap(action => of(new GetPaymentsAction(1, undefined, action.sorting)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions.pipe(
            ofType<PageChangedPaymentsAction>(ActionTypes.PageChangedPayments),
            switchMap(action => of(new GetPaymentsAction(action.page)))
        ),
    );

    bulkUpdate$ = createEffect((): any => this.actions.pipe(
        ofType<BulkUpdateAction>(ActionTypes.BulkUpdate),
        switchMap(() => {

            const errorMessageSubject = new Subject<string>();
            const modalActionSubject = new Subject<UploadFileModalAction>();

            const dialogRef = this.dialog
                .open(UploadFileModalComponent, {
                    width: "500px",
                    data: {
                        modalActionSubject,
                        errorMessageSubject,
                        dialogTitle: "Upload File",
                        dialogMessage: "Select the bulk update file to upload",
                        fileLabel: "File",
                        onlyFilesOfType: ".csv",
                        infoTip:
`File should be a .csv file containing row data in the expected format under the following headers:

Token,Status,SettlementDate,SettlementStatus,FundingDate,FundingDepositsId

For example, here a single row is supplied which has one skipped data field (FundingDate):

Token,Status,SettlementDate,SettlementStatus,FundingDate,FundingDepositsId
tr_fBiIvvJg0gcm1KSnJgcFPnF4hFXf,Success,08/25/2022 6:30:37 AM,Settled,,4556
`
                    },
                    disableClose: true,
                    autoFocus: false
                });
            return modalActionSubject.pipe(
                switchMap(({action, params}: UploadFileModalAction) => {
                    switch (action) {
                        case ModalActionType.CANCEL:
                        {
                            dialogRef.close();
                            return of();
                        }
                        case ModalActionType.CONFIRM:
                        {
                            return this.globalSpinner.apply(
                                this.paymentsService.transactionsUpdateTransactionInformation(
                                    {
                                        body: { uploadedFile: params.file }
                                    }
                                )
                                .pipe(
                                    switchMap((response: {[key: string]: string}) => {
                                        dialogRef.close();
                                        this.toast.open(
                                            `Updated successfully.`,
                                            VituToastTone.Positive);
                                        return of(new GetTransactionsAction(1));
                                    }),
                                    catchError(error => {
                                        errorMessageSubject.next(error instanceof Error ? error.message : error);
                                        return of();
                                    })
                                )
                            );
                        }
                    }
                })
            );
        })
    ));

    getPayments = createEffect(() =>
        this.actions.pipe(
            ofType<GetPaymentsAction>(ActionTypes.GetPayments),
            withLatestFrom(this.store.select(getPaymentsState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const params = this.getParams(sorting, filter, state.pager.pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.paymentsService.paymentsSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetPaymentsSucceedAction(response, state.pager.pageSize, action.page, stateExtensions))),
                    catchError((error) => of(new GetPaymentsFailAction(error)))
                );
            })
        ),
    );
/*
    exportToCsv = createEffect(() =>
        this.actions.pipe(
            ofType<ExportPaymentsToCsvAction>(ActionTypes.ExportPaymentsToCsv),
            withLatestFrom(this.store.select(getPaymentsState)),
            switchMap(([_, state]) => {

                const params = this.getParams(state.sorting, state.filter, state.pager.pageSize, state.pager.page);

                return this.paymentsService.transactionGroupsExport(params).pipe(
                    tap(response => window.location.href = response.url),
                    switchMap((response) =>
                        of(new ExportPaymentsToCsvSucceedAction(response))),
                    catchError((error) =>
                        of(new ExportPaymentsToCsvFailAction(error)))
                );
            })
        ),
    );
*/
    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                LocalDateInterval: filter.localDateInterval,
                LocalDateSettledInterval: filter.localDateSettledInterval,
                LocalDateFundedInterval: filter.localDateFundedInterval,
                Amount: filter.amount,
                First6: filter.first6,
                Last4: filter.last4,
                PayerName: filter.payerName,
                CardBrands: filter.cardBrands,
                DisputeTypes: filter.disputeTypes,
                Token: filter.token,
                Statuses: filter.statuses,
                Gateways: filter.gateways,
                TID: filter.tid,
                MID: filter.mid,
                MerchantId: filter.merchantId,
                DBA: filter.dba,
                DigitalWallets: filter.digitalWallets
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            SettlementStartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateSettledInterval?.from),
            SettlementEndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateSettledInterval?.to),
            FundingStartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateFundedInterval?.from),
            FundingEndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateFundedInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
