import { getCurrentUserState } from "@admin_app/storage/current-user/current-user.selectors";
import { IStore } from "@admin_app/storage/store";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { SidebarItem, AuthService } from "shared-lib";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.less"],
})
export class DashboardComponent {

    sidebarOpen = (window.innerWidth > 1440);

    sidebarItems: SidebarItem[] = [
        {
            name: "Organizations",
            primaryRouteToActivate: "/dashboard/organizations",
            hasSeparatorBefore: true,
            faIcon: "fas fa-sitemap"
        },
        {
            name: "Payments",
            primaryRouteToActivate: "/dashboard/payments",
            faIcon: "far fa-money-check-edit-alt"
        },
        {
            name: "Transactions",
            primaryRouteToActivate: "/dashboard/transactions",
            faIcon: "far fa-file-invoice-dollar"
        },
        {
            name: "Disputes",
            primaryRouteToActivate: "/dashboard/disputes",
            faIcon: "far fa-boxing-glove"
        },
        {
            name: "Accounts",
            primaryRouteToActivate: "/dashboard/accounts",
            faIcon: "far fa-file-user"
        },
        {
            name: "Devices",
            primaryRouteToActivate: "/dashboard/devices",
            faIcon: "far fa-phone-laptop"
        },
        {
            name: "Fee Plans",
            primaryRouteToActivate: "/dashboard/fee-plans",
            faIcon: "fas fa-search-dollar"
        },
        {
            name: "Webhooks",
            faIcon: "far fa-arrow-from-left",
            secondaryRouteToCapture: "/dashboard/webhooks",
            subItems: [
                {
                    name: "Configure",
                    primaryRouteToActivate: "/dashboard/webhooks/configure",
                    faIcon: "far fa-cog"
                },
                {
                    name: "Event History",
                    primaryRouteToActivate: "/dashboard/webhooks/event-history",
                    faIcon: "fas fa-history"
                },
                {
                    name: "PayPal Events",
                    primaryRouteToActivate: "/dashboard/webhooks/paypal-events",
                    faIcon: "fab fa-paypal"
                }
            ]
        },
        {
            name: "Roles",
            primaryRouteToActivate: "/dashboard/roles",
            faIcon: "far fa-user-tag"
        },
        {
            name: "Reports",
            primaryRouteToActivate: "/dashboard/reports",
            faIcon: "far fa-file-chart-pie"
        },
        {
            name: "Funding",
            faIcon: "far fa-coins",
            secondaryRouteToCapture: "/dashboard/funding",
            subItems: [
                {
                    name: "Funding History",
                    primaryRouteToActivate: "/dashboard/funding/funding-history",
                    faIcon: "far fa-history"
                },
                {
                    name: "Adjustments",
                    primaryRouteToActivate: "/dashboard/funding/adjustments",
                    faIcon: "far fa-adjust"
                }
            ]
        },
        {
            name: "SFTP Files",
            faIcon: "far fa-file-spreadsheet",
            secondaryRouteToCapture: "/dashboard/sftp-files",
            subItems: [
                {
                    name: "Google Cloud Storage",
                    primaryRouteToActivate: "/dashboard/sftp-files/google-cloud-storage",
                    faIcon: "far fa-cloud"
                },
                {
                    name: "PayPal SFTP",
                    primaryRouteToActivate: "/dashboard/sftp-files/paypal-sftp",
                    faIcon: "fab fa-paypal"
                }
            ]
        },
        {
            name: "Digital Wallets",
            faIcon: "fas fa-wallet",
            secondaryRouteToCapture: "/dashboard/digital-wallets",
            subItems: [
                {
                    name: "Google Pay",
                    primaryRouteToActivate: "/dashboard/digital-wallets/google-pay",
                    faIcon: "fab fa-google"
                },
                {
                    name: "Apple Pay",
                    primaryRouteToActivate: "/dashboard/digital-wallets/apple-pay",
                    faIcon: "fab fa-apple"
                }
            ]
        }
    ];

    currentUserName$ = this.store.pipe(
        select(getCurrentUserState),
        map(i => i.record?.name)
    );

    currentUserPermissions = [];

    constructor(private store: Store<IStore>, private authService: AuthService, public router: Router) {}

    onLogout() {
        this.authService.logout();
    }

}
