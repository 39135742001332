import { PagedDataDtoOfAdjustmentDto } from "@admin_api/models/paged-data-dto-of-adjustment-dto";
import { Action } from "@ngrx/store";
import { Filter, Sorting } from "./adjustments.state";

export enum ActionTypes {
    GetAdjustments = "[ADJUSTMENTS] GetAdjustments",
    GetAdjustmentsSucceed = "[ADJUSTMENTS] GetAdjustmentsSucceed",
    GetAdjustmentsFail = "[ADJUSTMENTS] GetAdjustmentsFail",
    SortAdjustments = "[ADJUSTMENTS] SortAdjustments",
    PageChangedAdjustments = "[ADJUSTMENTS] PageChangedAdjustments",
    FilterAdjustments = "[ADJUSTMENTS] FilterAdjustments",
}

export class GetAdjustmentsAction implements Action {

    constructor(public page: number, public pageSize?: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetAdjustments;
}

export class GetAdjustmentsSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfAdjustmentDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetAdjustmentsSucceed;
}

export class GetAdjustmentsFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetAdjustmentsFail;
}

export class SortAdjustmentsAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortAdjustments;
}

export class PageChangedAdjustmentsAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedAdjustments;
}

export class FilterAdjustmentsAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterAdjustments;
}
