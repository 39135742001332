import { Component, OnDestroy, OnInit } from "@angular/core";
import { IStore } from "@admin_app/storage/store";
import { Store, select } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Pager } from "shared-lib";

import {
    FilterTransactionsAction,
    SortTransactionsAction,
    PageChangedTransactionsAction,
    ExportTransactionsToCsvAction
} from "@admin_app/storage/transactions/transactions.actions";
import { Sorting, Filter } from "@admin_app/storage/transactions/transactions.state";
import { TransactionDto } from "@admin_api/models/transaction-dto";

import { getTransactionsState } from "@admin_app/storage/transactions/transactions.selectors";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";

import { PagerLargeSinglePageSize } from "@admin_app/storage/common";
import { BulkUpdateAction } from "@admin_app/storage/payments/payments.actions";

type RowsData = TransactionDto[];

@Component({
    selector: "app-transactions",
    templateUrl: "./transactions.component.html",
    styleUrls: ["./transactions.component.less"],
})
export class TransactionsComponent implements OnInit, OnDestroy {

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    get rowsData$(): Observable<RowsData> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.rows));
    }

    get sorting$(): Observable<Sorting> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.sorting));
    }

    get pager$(): Observable<Pager> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.pager));
    }

    get loading$(): Observable<boolean> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.loading));
    }

    get error$(): Observable<Error> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.error));
    }

    get filter$(): Observable<Filter> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.filter));
    }

    get exportLoading$(): Observable<boolean> {
        return this.store.pipe(select(getTransactionsState), map((i) => i.exportLoading));
    }

    private subscriptions = new Subscription();

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FilterTransactionsAction(filter));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new SortTransactionsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new PageChangedTransactionsAction(page));
    }

    exportToCsv() {
        this.store.dispatch(new ExportTransactionsToCsvAction());
    }

    onBulkUpdate() {
        this.store.dispatch(new BulkUpdateAction());
    }
}
