import { ApplePayKeyState } from "./apple-pay-key.state";

export const ApplePayKeyReducer = (
    state: ApplePayKeyState = new ApplePayKeyState(),
    action: any,
): ApplePayKeyState => {
    switch (action.type) {
        default:
            return state;
    }

};
