import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnDef, LocalTimePoint, TableSorting, Pager } from "shared-lib";
import { Router } from "@angular/router";
import { WebhookEventTypesDto } from "@admin_api/models/webhook-event-types-dto";
import { MerchantEventOrderByEnum } from "@admin_api/models/merchant-event-order-by-enum";
import { Filter, Sorting } from "@admin_app/storage/events/events.state";
import { MerchantEventDto } from "@admin_api/models/merchant-event-dto";

@Component({
    selector: "app-event-history-log",
    templateUrl: "./event-history-log.component.html",
    styleUrls: [ "./event-history-log.component.less" ]
})
export class EventHistoryLogComponent {

    @Input() events: Array<MerchantEventDto>;
    @Input() loading = false;
    @Input() error: Error;
    @Input() webhookLookups: WebhookEventTypesDto;

    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;
    @Input() filter: Filter;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() filterChanged = new EventEmitter<Filter>();

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    eventsColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: MerchantEventOrderByEnum.Id, title: "ID", flexWidthBasisInPixels: 150, flexWidthGrow: 0, canSort: true},
        { id: MerchantEventOrderByEnum.EventType, title: "Event Type", flexWidthBasisInPixels: 560, flexWidthGrow: 0, canSort: true},
        { id: MerchantEventOrderByEnum.CreatedUtc, title:`Date (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 140, flexWidthGrow: 0, canSort: true},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    constructor(private router: Router) {}

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as MerchantEventOrderByEnum,
            orderDirection: sorting.orderDirection
        });
    }

    onRowSelected(id: number) {
        this.router.navigate([`/dashboard/webhooks/event-history/${id}`]);
    }

}
