/* tslint:disable */
/* eslint-disable */
export enum AgreementType {
  FATCA_REPORTING_NOT_SUBJECT_TO_US_TAX_CERTIFICATION = 'FATCA_REPORTING_NOT_SUBJECT_TO_US_TAX_CERTIFICATION',
  FATCA_REPORTING_SUBJECT_TO_US_TAX_CERTIFICATION = 'FATCA_REPORTING_SUBJECT_TO_US_TAX_CERTIFICATION',
  PERSONAL_DATA_SHARING_CONSENT_FOR_VERIFICATION = 'PERSONAL_DATA_SHARING_CONSENT_FOR_VERIFICATION',
  US_IRS_CERTIFICATION_OF_CAPACITY = 'US_IRS_CERTIFICATION_OF_CAPACITY',
  US_IRS_DATA_TRANSFER_CERTIFICATION = 'US_IRS_DATA_TRANSFER_CERTIFICATION',
  US_IRS_NOT_SUBJECT_TO_US_TAX_CERTIFICATION = 'US_IRS_NOT_SUBJECT_TO_US_TAX_CERTIFICATION',
  US_IRS_SUBJECT_TO_US_TAX_CERTIFICATION = 'US_IRS_SUBJECT_TO_US_TAX_CERTIFICATION'
}
