/* tslint:disable */
/* eslint-disable */
export enum OrganizationOrderBy {
  Id = 'Id',
  Name = 'Name',
  Solution = 'Solution',
  Contact = 'Contact',
  Address = 'Address',
  IsMerchant = 'IsMerchant'
}
