import { ReportFileDto } from "@admin_api/models/report-file-dto";
import { ReportFileStatusEnum } from "@admin_api/models/report-file-status-enum";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { createFilter, DateRange, FilterBase, Pager } from "shared-lib";
import { ReportFileTypeEnum } from "@admin_api/models/report-file-type-enum";

export class Sorting {
    orderBy: null | string;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval: DateRange = undefined;
    startDate: string = undefined;
    endDate: string = undefined;

    types: Array<ReportFileTypeEnum> = undefined;
    statuses: Array<ReportFileStatusEnum> = undefined;
}

export class PayPalSftpFilesState {
    loading = false;
    record: Array<ReportFileDto> = [];
    error: Error = null;
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
