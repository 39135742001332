import { Environment } from "shared-lib";

export const environment: Environment = {
    isDevelopment: false,
    descriptor: "test",
    portalApiRootUrl: "https://test.vitupay.com",
    coreApiRootUrl: "https://api.test.vitupay.com",
    authRootUrl: "https://auth.test.vitupay.com",
    stackDriverErrorReporting: {
        projectId: "vitupay-oos",
        key: "AIzaSyB1SP6bwD9LGmLiBWjRyYWZIqE3S95DzkQ",
        appName: "admin"
    }
};
