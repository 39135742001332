import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getAdjustmentsState = (store: IStore) => store.adjustments;

export const getAdjustments = createSelector(
    getAdjustmentsState,
    adjustmentsState => adjustmentsState.record
);

export const getAdjustmentsLoading = createSelector(
    getAdjustmentsState,
    adjustmentsState => adjustmentsState.loading
);

export const getAdjustmentsError = createSelector(
    getAdjustmentsState,
    adjustmentsState => adjustmentsState.error
);

export const getAdjustmentsSorting = createSelector(
    getAdjustmentsState,
    adjustmentsState => adjustmentsState.sorting
);

export const getAdjustmentsPager = createSelector(
    getAdjustmentsState,
    adjustmentsState => adjustmentsState.pager
);

export const getAdjustmentsFilter = createSelector(
    getAdjustmentsState,
    adjustmentsState => adjustmentsState.filter
);
