import { ModalAction, ModalActionType } from "@admin_app/shared/modal-action";

export interface UploadFileParams {
    file?: File;
}

export class UploadFileModalAction extends ModalAction {
    constructor(public action: ModalActionType, public params?: UploadFileParams) {
        super(action, params);
    }
}
