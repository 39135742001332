import { DigitalWalletCertificateOrderEnum } from "@admin_api/models/digital-wallet-certificate-order-enum";
import { DigitalWalletCertificateStatusEnum } from "@admin_api/models/digital-wallet-certificate-status-enum";
import { DigitalWalletEnum } from "@admin_api/models/digital-wallet-enum";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionButtonKind } from "shared-lib";

@Component({
    selector: "app-apple-pay-payment-processing-certificates-table-cell-content",
    templateUrl: "./apple-pay-payment-processing-certificates-table-cell-content.component.html",
    styleUrls: ["./apple-pay-payment-processing-certificates-table-cell-content.component.less"],
})
export class ApplePayPaymentProcessingCertificatesTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;

    @Output() revoke = new EventEmitter<string>();

    DigitalWalletEnum = DigitalWalletEnum;
    DigitalWalletCertificateStatusEnum = DigitalWalletCertificateStatusEnum;
    DigitalWalletCertificateOrderEnum = DigitalWalletCertificateOrderEnum;
    ActionButtonKind = ActionButtonKind;

    actionButtonKind = ActionButtonKind.SECONDARY;
    actionButtonHasFontImage = false;
    actionButtonIconAfterText = true;

    onClickRevoke() {
        if (this.element) {
            this.revoke.emit(this.element.token);
        }
    }

    get sortingKeyForDateExpired(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.dateExpired).getTime();
    }

    get sortingKeyForDateCreated(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.dateCreated).getTime();
    }

    get sortingKeyForActual(): number {
        return this.element.actual ? 1 : 0;
    }

    get sortingKeyForType(): number {
        const digitalWalletTypes = Object.values(DigitalWalletEnum);
        return digitalWalletTypes.findIndex(digitalWalletType => digitalWalletType === this.element.type);
    }

    get sortingKeyForRevoke(): number {
        return (this.element?.status !== DigitalWalletCertificateStatusEnum.Revoked) ? 1 : 0;
    }

    get sortingKeyForPublicKey(): number {
        return (this.element?.status !== DigitalWalletCertificateStatusEnum.Revoked) ? 1 : 0;
    }

    get fontImageForRevoke() {
        return (this.actionButtonHasFontImage) ? "far fa-ban fa-bg" : undefined;
    }

}
