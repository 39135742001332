import { Action } from "@ngrx/store";
import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";
import { PosTerminalStatusEnum } from "@admin_api/models/pos-terminal-status-enum";

export enum ActionTypes {
    UpdateDeviceStatus = "[Device] UpdateDeviceStatus",
    UpdateDeviceStatusSucceed = "[Device] UpdateDeviceStatusSucceed",
    UpdateDeviceStatusFail = "[Device] UpdateDeviceStatusFail"
}

export class UpdateDeviceStatusAction implements Action {

    constructor(public device: MerchantTerminalDto, public status: PosTerminalStatusEnum) {}

    readonly type = ActionTypes.UpdateDeviceStatus;
}

export class UpdateDeviceStatusSucceedAction implements Action {

    constructor(public device: MerchantTerminalDto, public status: PosTerminalStatusEnum) {}

    readonly type = ActionTypes.UpdateDeviceStatusSucceed;
}

export class UpdateDeviceStatusFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.UpdateDeviceStatusFail;
}
