import { MerchantTerminalDto } from "@admin_api/models/merchant-terminal-dto";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { PosTerminalStatusEnum } from "@admin_api/models/pos-terminal-status-enum";
import { createFilter, FilterBase, Pager } from "shared-lib";
import { PagerLargeSinglePageSize } from "../common";

export class Sorting {
    orderBy: null | string;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    merchantId: number = undefined;
    mid: string = undefined;
    tid: string = undefined;
    deviceSerial: string = undefined;
    deviceName: string = undefined;
    statuses: Array<PosTerminalStatusEnum> = undefined;
}

export class InventoryState {
    loading = false;
    error: Error = null;
    record: Array<MerchantTerminalDto> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
