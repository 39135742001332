import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getPayPalEventsState = (store: IStore) => store.payPalEvents;

export const getPayPalEvents = createSelector(
    getPayPalEventsState,
    eventsState => eventsState.rows,
);

export const getPayPalEventsLoading = createSelector(
    getPayPalEventsState,
    eventsState => eventsState.loading,
);

export const getPayPalEventsError = createSelector(
    getPayPalEventsState,
    eventsState => eventsState.error,
);
