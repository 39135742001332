<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'tid'">
        <lib-text-table-column>
            {{ element.tid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'mid'">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'processor'">
        <lib-text-table-column>
            {{ element.processor }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'gateway'">
        <lib-text-table-column>
            {{ element.gateway }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'mai'">
        <lib-text-table-column>
            {{ element.mai }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceSerial'">
        <lib-text-table-column>
            {{ element.posConfig?.deviceSerial }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceName'">
        <lib-text-table-column>
            {{ element.posConfig?.deviceName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceLocation'">
        <lib-text-table-column>
            {{ element.posConfig?.deviceLocation }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <lib-status-chip
            *ngIf="element.posConfig?.status; else noPosTerminalStatus"
            class="pos-terminal-statuses"
            [status]="element.posConfig?.status">
        </lib-status-chip>
        <ng-template #noPosTerminalStatus>
            <lib-text-table-column>
            </lib-text-table-column>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'deletedUtc'">
        <ng-container *ngIf="showingDeletedTids">
            <lib-date-time-label [dateUtc]="element?.deletedUtc">
            </lib-date-time-label>
            <lib-table-column-sort [key]="sortingKeyForDateDeleted"></lib-table-column-sort>
        </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





