import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getPayPalSftpFilesState = (store: IStore) => store.payPalSftpFiles;

export const getPayPalSftpFiles = createSelector(
    getPayPalSftpFilesState,
    filesState => filesState.record,
);

export const getPayPalSftpFilesLoading = createSelector(
    getPayPalSftpFilesState,
    filesState => filesState.loading,
);

export const getPayPalSftpFilesError = createSelector(
    getPayPalSftpFilesState,
    filesState => filesState.error,
);

export const getPayPalSftpFilesSorting = createSelector(
    getPayPalSftpFilesState,
    filesState => filesState.sorting,
);

export const getPayPalSftpFilesFilter = createSelector(
    getPayPalSftpFilesState,
    filesState => filesState.filter,
);

export const getPayPalSftpFilesPager = createSelector(
    getPayPalSftpFilesState,
    filesState => filesState.pager,
);
