export class CurrentUserDto {
    id = "";
    name = "";
    permissions = [];
    defaultRoute = "";
}

export class CurrentUserState {
    loading = false;
    error = null;
    record: CurrentUserDto = null;
}
