/* tslint:disable */
/* eslint-disable */
export enum PosTerminalStatusEnum {
  Suspended = 'Suspended',
  Backup = 'Backup',
  Active = 'Active',
  Defective = 'Defective',
  Validation = 'Validation',
  Reassigned = 'Reassigned',
  NotBoarded = 'NotBoarded'
}
