import { PayPalSftpFileState } from "./paypal-sftp-file.state";
import { ActionTypes } from "./paypal-sftp-file.actions";

export const PayPalSftpFileReducer = (
    state: PayPalSftpFileState = new PayPalSftpFileState(),
    action: any,
): PayPalSftpFileState => {

    switch (action.type) {
        default:
            return state;
    }
};
