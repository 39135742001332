import { IStore } from "@admin_app/storage/store";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Sorting, Filter } from "@admin_app/storage/paypal-sftp-files/paypal-sftp-files.state";

import * as PayPalSftpFilesActions from "@admin_app/storage/paypal-sftp-files/paypal-sftp-files.actions";
import * as PayPalSftpFilesSelectors from "@admin_app/storage/paypal-sftp-files/paypal-sftp-files.selectors";

import * as PayPalSftpFileActions from "@admin_app/storage/paypal-sftp-file/paypal-sftp-file.actions";

@Component({
    selector: "app-paypal-sftp",
    templateUrl: "./paypal-sftp.component.html",
    styleUrls: ["./paypal-sftp.component.less"]
})
export class PayPalSftpComponent {

    files$ = this.store.select(PayPalSftpFilesSelectors.getPayPalSftpFiles);
    filesLoading$ = this.store.select(PayPalSftpFilesSelectors.getPayPalSftpFilesLoading);
    filesError$ = this.store.select(PayPalSftpFilesSelectors.getPayPalSftpFilesError);

    filesPager$ = this.store.select(PayPalSftpFilesSelectors.getPayPalSftpFilesPager);
    filesSorting$ = this.store.select(PayPalSftpFilesSelectors.getPayPalSftpFilesSorting);
    filesFilter$ = this.store.select(PayPalSftpFilesSelectors.getPayPalSftpFilesFilter);

    constructor(private store: Store<IStore>) {
        this.store.dispatch(new PayPalSftpFilesActions.GetPayPalSftpFilesAction(1));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new PayPalSftpFilesActions.SortPayPalSftpFilesAction(sorting));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new PayPalSftpFilesActions.FilterPayPalSftpFilesAction(filter));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new PayPalSftpFilesActions.PageChangedPayPalSftpFilesAction(page));
    }

    onReprocessFile(id: number) {
        this.store.dispatch(new PayPalSftpFileActions.ReprocessPayPalSftpFileAction(id));
    }

    onDownloadFile(id: number) {
        this.store.dispatch(new PayPalSftpFileActions.DownloadPayPalSftpFileAction(id));
    }

}
