import { SolutionWebhookDto } from "@admin_api/models/solution-webhook-dto";
import { WebhookEventTypesDto } from "@admin_api/models/webhook-event-types-dto";

export class WebhookState {
    loading = false;
    error = null;
    webhook: SolutionWebhookDto = null;
    lookups: WebhookEventTypesDto = null;
    toggleWebhooksLoading: Array<number> = [];
    toggleWebhookError = null;
}
