import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@admin_app/storage/store";

import * as AdjustmentActions from "@admin_app/storage/adjustment/adjustment.actions";
import * as AdjustmentSelectors from "@admin_app/storage/adjustment/adjustment.selectors";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";

import * as MidsActions from "@admin_app/storage/mids/mids.actions";
import * as MidsSelectors from "@admin_app/storage/mids/mids.selectors";

import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { CreateManualAdjustmentDto } from "@admin_api/models/create-manual-adjustment-dto";
import { PagerLargeSinglePageSize } from "@admin_app/storage/common";

@Component({
    selector: "app-adjustment",
    templateUrl: "./adjustment.component.html",
    styleUrls: ["./adjustment.component.less"]
})
export class AdjustmentComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    adjustment$ = this.store.select(AdjustmentSelectors.getAdjustmentInfo);
    adjustmentLoading$ = this.store.select(AdjustmentSelectors.getAdjustmentLoading);

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);
    merchantsError$ = this.store.select(OrganizationsSelectors.getOrganizationsError);

    mids$ = this.store.select(MidsSelectors.getMids);
    midsLoading$ = this.store.select(MidsSelectors.getMidsLoading);
    midsError$ = this.store.select(MidsSelectors.getMidsError);

    isCreate: boolean;

    ngOnInit() {
        // Can assume that to load this component url length is always > 0
        this.isCreate = (this.route.snapshot.url[this.route.snapshot.url.length - 1].path === "create");

        this.store.dispatch(new AdjustmentActions.ResetAdjustmentAction());
        if (this.isCreate) {
            this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));
            // AC_todo : Using a single large page (up to 100 items here)
            // because we don't have pageable select dropdown widget.
            // => better solution would be to create a pageable select dropdown widget
            // (eg. with 'More...' item) and then use paging of default page size.
            this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));

            this.store.dispatch(new MidsActions.ResetMidsAction());

            this.store.dispatch(new AdjustmentActions.GetNewAdjustmentAction());
        }
        else {
            this.store.dispatch(new AdjustmentActions.GetAdjustmentAction(this.route.snapshot.params.token));
        }

    }

    onMerchantChanged(merchantId: number) {
        this.store.dispatch(new MidsActions.GetMidsAction(merchantId));
    }

    createAdjustment(adjustment: CreateManualAdjustmentDto) {

        this.store.dispatch(new AdjustmentActions.CreateAdjustmentAction(adjustment));
    }

    deleteAdjustment(token: string) {

        this.store.dispatch(new AdjustmentActions.DeleteAdjustmentAction(token));
    }

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

}
