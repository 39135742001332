import { DigitalWalletKeysDto } from "@admin_api/models/digital-wallet-keys-dto";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetPaymentProcessingCertificates = "[GOOGLE_PAY_KEYS] GetPaymentProcessingCertificates",
    GetPaymentProcessingCertificatesSucceed = "[GOOGLE_PAY_KEYS] GetPaymentProcessingCertificatesSucceed",
    GetPaymentProcessingCertificatesFail = "[GOOGLE_PAY_KEYS] GetPaymentProcessingCertificatesFail"
}

export class GetPaymentProcessingCertificatesAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetPaymentProcessingCertificates;
  }

export class GetPaymentProcessingCertificatesSucceedAction implements Action {

    constructor(public digitalWalletKeysDto: DigitalWalletKeysDto) {}

    readonly type = ActionTypes.GetPaymentProcessingCertificatesSucceed;
}

export class GetPaymentProcessingCertificatesFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetPaymentProcessingCertificatesFail;
}
