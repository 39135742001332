import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { Location } from "@angular/common";
import { TransactionDetailDto } from "@admin_api/models/transaction-detail-dto";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { UpdateTransactionDetailAction } from "@admin_app/storage/transaction/transaction.actions";
import { TransactionStatus } from "@admin_api/models/transaction-status";
import { ColumnDef } from "shared-lib";
import { PaymentDetailsDto } from "@admin_api/models/payment-details-dto";
import { ActionButtonKind, LocalTimePoint } from "shared-lib";
import { TransactionFeeModel } from "@admin_api/models/transaction-fee-model";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, UrlSegment } from "@angular/router";
import { DigitalWalletEnum } from "@admin_api/models/digital-wallet-enum";
import { AdditionalAmountType } from "@admin_api/models/additional-amount-type";
import { DisputeOrderBy } from "@admin_api/models/dispute-order-by";
import { DisputeTypeEnum } from "@admin_api/models/dispute-type-enum";
import { TransactionType } from "@admin_api/models/transaction-type";

enum LoadContext {
    History,
    Chargebacks,
    Retrievals,
    Fees,
    AdditionalInformation
}

interface FeePart {
    category: string;
    value: number;
}

@Component({
    selector: "app-transaction-detail",
    templateUrl: "./transaction-detail.component.html",
    styleUrls: ["./transaction-detail.component.less"]
})
export class TransactionDetailComponent implements OnChanges {

    @Input() transactionLoading = false;
    @Input() transaction: TransactionDetailDto = {};

    @Input() paymentLoading = false;
    @Input() paymentError: Error;

    @Input() set payment(value: PaymentDetailsDto) {
        this._payment = value;
        this.calculateServiceFeeInfo();
        this.calculateServiceFeeRefundInfo();
    }
    get payment() {
        return this._payment;
    }
    _payment: PaymentDetailsDto = {};

    @Input() paymentFeesLoading = false;
    @Input() paymentFeesError: Error;

    @Input() set paymentFees(value: Array<TransactionFeeModel>) {
        this._paymentFees = value || [];

        this.feeParts = [];
        this.feeTotal = 0;

        const feePartsMap = {};

        this._paymentFees.forEach(fee => {

            if (isNaN(feePartsMap[fee.feeCategory])) {
                feePartsMap[fee.feeCategory] = 0;
            }

            feePartsMap[fee.feeCategory] += fee.feeValue;
            this.feeTotal += fee.feeValue;
        });

        const feePartCategories = Object.keys(feePartsMap);
        feePartCategories.forEach(feePartCategory => {
            this.feeParts.push({
                category: feePartCategory,
                value: feePartsMap[feePartCategory]
            });
        });
    }
    get paymentFees() {
        return this._paymentFees;
    }
    private _paymentFees: Array<TransactionFeeModel> = [];

    serviceFeeAmount = 0;
    serviceFeePercent = 0;

    serviceFeeRefundAmount = 0;

    feeParts: Array<FeePart> = [];
    feeTotal = 0;
    initComplete = false;

    @Input() transactionSiblingsLoading = false;
    @Input() transactionSiblings: Array<TransactionDetailDto> = [];

    get relatedPaymentLoading() {
        return this.transactionLoading || this.paymentLoading || (this.payment.token !== this.transaction.paymentToken);
    }

    @Output() downloadReceipt = new EventEmitter<TransactionDetailDto>();

    loadContext = LoadContext.History;

    LocalTimePoint = LocalTimePoint;
    ActionButtonKind = ActionButtonKind;
    DigitalWalletEnum = DigitalWalletEnum;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    disputeColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: DisputeOrderBy.RaiseDateUtc, title: `Date Created (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 130, flexWidthGrow: 0, canSort: false},
        { id: DisputeOrderBy.CaseNumber, title: "Case", flexWidthBasisInPixels: 210, flexWidthGrow: 0, canSort: false},
        { id: DisputeOrderBy.CaseReason, title: "Reason Type", flexWidthBasisInPixels: 90, flexWidthGrow: 6, canSort: false},
        { id: "amount", title: "Amount", flexWidthBasisInPixels: 100, flexWidthGrow: 1},
        { id: "statusDescription", title: "Status", flexWidthBasisInPixels: 60, flexWidthGrow: 3, canSort: false},
        { id: "statusDateUtc", title: `Date Updated (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 130, flexWidthGrow: 0},
        { id: "disputeOutcome", title: "Outcome", flexWidthBasisInPixels: 60, flexWidthGrow: 1},
        { id: "dueDateUtc", title: `Date Due (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 110, flexWidthGrow: 0},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0}
    ];

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    feeColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "feeCategory", title: "Fee Category", flexWidthBasisInPixels: 250, flexWidthGrow: 0, canSort: true},
        { id: "feeType", title: "Fee Type", flexWidthBasisInPixels: 420, flexWidthGrow: 0, canSort: true},
        { id: "feeValue", title: "Fee Amount", flexWidthBasisInPixels: 180, flexWidthGrow: 0, canSort: true},
        { id: "perc", title: "% of Transaction", flexWidthBasisInPixels: 180, flexWidthGrow: 1, canSort: true},
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    constructor(
        private store: Store<IStore>,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router) {}

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initComplete) {
            this.seedTab();
            this.initComplete = true;
        }
        if (changes.hasOwnProperty("transaction") && !this.transactionLoading) {
            this.location.replaceState(`/dashboard/transactions/${changes.transaction.currentValue.token}`);
        }
    }

    onTransactionSelected(token: string): void {
        const found = this.transactionSiblings.find(element => element.token === token);
        if (found) {
            this.store.dispatch(UpdateTransactionDetailAction({ record: found }));
        }
    }

    transactionSuccessful(): boolean {
        return (this.transaction?.status === TransactionStatus.Success);
    }

    getHistoryTabLabel(): string {
        let count = 0;
        if (!this.relatedPaymentLoading && this.payment.transactions?.length) {
            count = this.payment.transactions?.length;
        }
        return this.appendCountIfNecessary("History", count);
    }

    get chargebacks() {
        return this.payment?.disputes?.filter(dispute => dispute.disputeType === DisputeTypeEnum.Chargeback);
    }

    getChargebacksTabLabel(): string {
        let count = 0;
        if (!this.relatedPaymentLoading && this.payment.disputes?.length) {
            count = this.payment.disputes.filter(dispute => dispute.disputeType === DisputeTypeEnum.Chargeback).length;
        }
        return this.appendCountIfNecessary("Chargebacks", count);
    }

    get retrievals() {
        return this.payment?.disputes?.filter(dispute => dispute.disputeType === DisputeTypeEnum.Retrieval);
    }

    getRetrievalsTabLabel(): string {
        let count = 0;
        if (!this.relatedPaymentLoading && this.payment.disputes?.length) {
            count = this.payment.disputes.filter(dispute => dispute.disputeType === DisputeTypeEnum.Retrieval).length;
        }
        return this.appendCountIfNecessary("Retrievals", count);
    }

    getFeesTabLabel(): string {
        let count = 0;
        if (this.paymentFees?.length) {
            count = this.paymentFees?.length;
        }
        return this.appendCountIfNecessary("Fees", count);
    }

    getCardNumber(): string {

        if (this.transaction.card?.first6 && this.transaction.card?.last4) {

            return `${this.transaction.card?.first6}XXX${this.transaction.card?.last4}`;
        }

        return "—";
    }

    getAdditionalInformationTabLabel(): string {
        return this.appendCountIfNecessary("Additional Information", this.getCountOfAdditionalInformation());
    }

    getCountOfAdditionalInformation(): number {
        let count = 0;
        if (this.transaction?.meta) {
            count = Object.keys(this.transaction.meta).length;
        }
        return count;
    }

    getAvsDetailFieldValue(result: null|string, resultDescription: null|string) {
        let retVal = "";

        if (result) {
            retVal += result;
        }
        if (result && resultDescription) {
            retVal += " - ";
        }
        if (resultDescription) {
            retVal += resultDescription;
        }

        return retVal;
    }

    getCvvDetailFieldValue(result: null|string, resultDescription: null|string) {
        let retVal = "";

        if (resultDescription) {
            retVal = resultDescription;
        }
        else if (result) {
            retVal = result;
        }

        return retVal;
    }

    get displayTextForTransactionType() {
        let suffix = "";
        if (this.transaction.isServiceFee) {
            suffix = " (SF)";
        }
        return `${this.transaction.type}${suffix}`;
    }

    onChargebackRowSelected(token: string) {
        if (this.transaction?.token) {
            this.location.replaceState(`/dashboard/transactions/${this.transaction.token}/chargebacks`);    // set up BACK url
            this.router.navigate([`/dashboard/disputes/${token}`]);
        }
    }

    onRetrievalRowSelected(token: string) {
        if (this.transaction?.token) {
            this.location.replaceState(`/dashboard/transactions/${this.transaction.token}/retrievals`);    // set up BACK url
            this.router.navigate([`/dashboard/disputes/${token}`]);
        }
    }

    private appendCountIfNecessary(title: string, count: number): string {
        return title + ((count > 0) ? ` (${count})` : "");
    }

    private seedTab(): void {

        const routeSnapshot: ActivatedRouteSnapshot = this.route.snapshot;
        const url: Array<UrlSegment> = routeSnapshot.url;
        const lastPartOfUrl: UrlSegment = url[url.length - 1];

        switch (lastPartOfUrl.path) {
            case "history": {
                this.loadContext = LoadContext.History;
                break;
            }
            case "chargebacks": {
                this.loadContext = LoadContext.Chargebacks;
                break;
            }
            case "retrievals": {
                this.loadContext = LoadContext.Retrievals;
                break;
            }
            case "fees": {
                this.loadContext = LoadContext.Fees;
                break;
            }
            case "additional-information": {
                this.loadContext = LoadContext.AdditionalInformation;
                break;
            }
            default: {
                break;
            }
        }
    }

    private calculateServiceFeeInfo() {
        const serviceFeeItems = this.payment?.transactions?.filter(transaction => transaction.type !== TransactionType.Refund)
            .flatMap(transaction => transaction.additionalAmounts?.items)
            .filter(transactionItem => transactionItem?.type === AdditionalAmountType.ServiceFee);

        this.serviceFeeAmount = serviceFeeItems?.reduce((sum, t) => sum + t.amount, 0) ?? 0;
        this.serviceFeePercent = serviceFeeItems?.find(platformFeeItem => platformFeeItem.percent > 0)?.percent ?? 0;
    }

    private calculateServiceFeeRefundInfo() {
        const serviceFeeItems = this.payment?.transactions?.filter(transaction => transaction.type === TransactionType.Refund
                && transaction.status === TransactionStatus.Success)
            .flatMap(transaction => transaction.additionalAmounts?.items)
            .filter(transactionItem => transactionItem?.type === AdditionalAmountType.ServiceFee);

        this.serviceFeeRefundAmount = serviceFeeItems?.reduce((sum, t) => sum + t.amount, 0) ?? 0;
    }

}
