<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    suppressInitialRequest>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-regular-layout">

                <div class="form-field-container">
                    <lib-vitu-form-field-date
                        [formGroup]="filterForm"
                        formFieldName="localDateInterval"
                        label="Date"
                        [clearValue]="filter?._initialFilter?.localDateInterval"
                        restrictFuture
                        isFilter
                        readonly>
                    </lib-vitu-form-field-date>
                </div>

                <div class="form-field-container" id="idFilterField">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="id"
                        isFilter
                        label="ID"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('id')"
                        (handleReset)="filtersBase.resetField('id')">
                    </lib-vitu-form-field-input>
                </div>

                <div class="form-field-container" id="eventTypeFilterField">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="eventType"
                        isFilter
                        label="Event Type"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('eventType')"
                        (handleReset)="filtersBase.resetField('eventType')">
                    </lib-vitu-form-field-input>
                </div>

                <lib-vitu-form-field-select
                    [formGroup]="filterForm"
                    formFieldName="status"
                    label="Status"
                    [options]="statuses"
                    [spaceBetweenCapsOnLabels]="true"
                    [showReset]="filtersBase.resetFieldVisible('status')"
                    (handleReset)="filtersBase.resetField('status')">
                </lib-vitu-form-field-select>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
