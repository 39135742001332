/* tslint:disable */
/* eslint-disable */
export enum BinFileOrderByEnum {
  Id = 'Id',
  FileName = 'FileName',
  Date = 'Date',
  Status = 'Status',
  CreatedUtc = 'CreatedUtc',
  UpdatedUtc = 'UpdatedUtc'
}
