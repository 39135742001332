<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="DisputeOrderBy.CaseNumber">
        <lib-text-table-column>
            {{ element.caseNumber }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="DisputeOrderBy.Id">
        <lib-text-table-column>
            {{ element.token }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="DisputeOrderBy.RaiseDateUtc">
        <lib-date-time-label [dateUtc]="element.raiseDateUtc"></lib-date-time-label>
        <lib-table-column-sort [key]="sortingKeyForRaiseDate"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'dueDateUtc'">
        <lib-date-time-label [dateUtc]="element.dueDateUtc"></lib-date-time-label>
        <lib-table-column-sort [key]="sortingKeyForDueDate"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="DisputeOrderBy.CaseReason">
        <lib-text-table-column>
            {{ element.reasonCodeDescription }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'amount'">
        <lib-text-table-column>
            {{ element.disputeAmount | vituCurrencyUsd }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="element.disputeAmount ?? 0"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'statusDescription'">
        <lib-text-table-column>
            {{ element.statusDescription }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'statusDateUtc'">
        <lib-date-time-label [dateUtc]="element.statusDateUtc"></lib-date-time-label>
        <lib-table-column-sort [key]="sortingKeyForStatusDate"></lib-table-column-sort>
    </ng-container>

    <ng-container *ngSwitchCase="'disputeOutcome'">
        <lib-text-table-column>
            {{ element.disputeOutcome }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'mid'">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'processor'">
        <lib-text-table-column>
            {{ element.processor }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'disputeType'">
        <lib-text-table-column>
            {{ element.disputeType }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'paymentToken'">
        <lib-text-table-column>
            {{ element.paymentToken }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'publicTransactionId'">
        <lib-text-table-column>
            {{ element.publicTransactionId }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'merchantName'">
        <lib-text-table-column>
            {{ element.organizationName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





