import { DfmFileState } from "./dfm-file.state";
import { ActionTypes } from "./dfm-file.actions";

export const DfmFileReducer = (
    state: DfmFileState = new DfmFileState(),
    action: any,
): DfmFileState => {

    switch (action.type) {
        default:
            return state;
    }
};
