<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Digital Wallets' }, { 'label': 'Google Pay' }]">
    </lib-title-bar>

    <div class="certificate-title-container">
        <div id="labelMain" class="label">PAYMENT PROCESSING CERTIFICATES</div>
        <lib-action-button
            label="New Google Pay Certificate"
            fontImage="far fa-plus-circle fa-bg"
            (click)="addNew.emit()">
        </lib-action-button>
    </div>

    <lib-table-base
        class="public-keys-table"
        [columnDefs]="publicKeyColumnDefs"
        [rowsData]="googlePayKeys?.versions"
        [rowsDataLoading]="googlePayKeysLoading"
        [rowsDataError]="googlePayKeysError"
        rowsDataErrorMessage="Error Loading Certificates"
        rowsDataEmptyMessage="No Payment Processing Certificates">

        <ng-template let-element="element" let-columnId="columnId">
            <app-google-pay-certificates-table-cell-content
                [columnId]="columnId"
                [element]="element"
                (revoke)="revoke.emit($event)"
                (register)="register.emit($event)"
                (download)="download.emit($event)"
                (getPublicKeyHash)="getPublicKeyHash.emit($event)"
                (makePrimary)="makePrimary.emit($event)">
            </app-google-pay-certificates-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
