import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CreateUserDto } from "@admin_api/models/create-user-dto";
import { RoleDto } from "@admin_api/models/role-dto";
import { UserDetailsDto } from "@admin_api/models/user-details-dto";
import { ActionButtonKind, EmailRegex, ValidatorsVituCustom } from "shared-lib";
import { CurrentUserDto } from "@admin_app/storage/current-user/current-user.state";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { ActivatedRoute } from "@angular/router";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-user-details",
    templateUrl: "./user-details.component.html",
    styleUrls: ["./user-details.component.less"],
})
export class UserDetailsComponent implements OnChanges {

    @Input() isCreate: boolean;
    @Input() organization: OrganizationDto = {};
    @Input() user: UserDetailsDto;
    @Input() currentUser: CurrentUserDto;
    @Input() roleList: RoleDto[];
    @Input() loading: boolean;

    @Output() deleteUser = new EventEmitter<{organizationId: number; userId: string}>();
    @Output() updateUser = new EventEmitter<{organizationId: number; user: UserDetailsDto}>();
    @Output() createUser = new EventEmitter<{organizationId: number; user: CreateUserDto}>();
    @Output() resetUserPassword = new EventEmitter<{organizationId: number; userId: string}>();
    @Output() back = new EventEmitter<void>();

    organizationId: string;

    pageTitle: string;
    submitButtonText: string;

    ActionButtonKind = ActionButtonKind;

    constructor(
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
    ) {}

    private userFormConfig: FormConfig<UserDetailsDto> = {
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.pattern(EmailRegex)]],
        roleIds: [null, [Validators.required, ValidatorsVituCustom.selectMultiIntegrity(() => this.roleList?.map((role) => role.id))]],
        isLockedOut: [false],
    };

    userForm = this.fb.group(this.userFormConfig);
    get formControls(): FormControls<UserDetailsDto> { return this.userForm.controls; }

    private static compareRole(a: RoleDto, b: RoleDto): number {
        const aVal = a.name?.toLowerCase();
        const bVal = b.name?.toLowerCase();
        if (aVal > bVal) {
            return 1;
        }
        else if (aVal < bVal) {
            return -1;
        }
        return 0;
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.isCreate && changes.isCreate.firstChange) {
            this.organizationId = this.route.snapshot.params.organizationId;
            this.pageTitle = (this.isCreate ? "New" : "Edit") + " User";
            this.submitButtonText = (this.isCreate ? "Submit" : "Update");
        }

        if ("user" in changes && this.user) {

            this.userForm.patchValue(this.user);

            if (!this.isCreate) {
                this.userForm.markAllAsTouched();
            }

        }

        // Workaround needed for form using ValidatorsVituCustom:
        // ValidatorsVituCustom need to be run by forcing validation if anything they depend on changes,
        // for example as a result of the allowed values being retrieved asynchronously from server (& arriving late).
        // (TODO : Consolidate use of Angular Forms in shareable form base class which implements our common form
        // behaviour like this as default for all of our forms (instead of having to apply it in multiple places))
        this.validateFormControls(this.userForm);
    }

    onClickDelete() {
        this.deleteUser.emit({
            organizationId: +this.organizationId,
            userId: this.user.id
        });
    }

    onClickResetPassword() {
        this.resetUserPassword.emit({
            organizationId: +this.organizationId,
            userId: this.user.id
        });
    }

    onClickBack() {
        this.back.emit();
    }

    onSubmit() {
        if (this.isCreate) {
            this.createUser.emit({
                organizationId: +this.organizationId,
                user: {
                    ...this.user,
                    ...this.userForm.value,
                    resetPasswordReturnUrl: window.location.origin
                }
            });
        }
        else {
            this.updateUser.emit({
                organizationId: +this.organizationId,
                user: {
                    ...this.user,
                    ...this.userForm.value
                }
            });
        }
    }

    get globalRoleList() {
        let retVal = [];
        if (this.roleList?.length) {
            retVal = retVal.concat(this.roleList.filter(role => !role.isCustom));
        }
        return retVal.sort(UserDetailsComponent.compareRole);
    }

    get customRoleList() {
        let retVal = [];
        if (this.roleList?.length) {
            retVal = retVal.concat(this.roleList.filter(role => role.isCustom));
        }
        return retVal.sort(UserDetailsComponent.compareRole);
    }

    get groupedRoleList() {
        return this.globalRoleList.concat(this.customRoleList);
    }

    get roleGroups() {

        const groups = [];
        let currentMinIdx = 0;

        if (this.globalRoleList?.length) {
            groups.push({name: "", minIdx: 0, maxIdx: this.globalRoleList.length - 1});
            currentMinIdx = this.globalRoleList.length;
        }
        if (this.customRoleList?.length) {
            groups.push({name: "CUSTOM", minIdx: currentMinIdx, maxIdx: currentMinIdx + this.customRoleList.length - 1});
        }

        return JSON.stringify(groups);
    }

    private validateFormControls(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup?.controls).forEach(field => {
            formGroup?.get(field)?.updateValueAndValidity();
        });
    }

}
