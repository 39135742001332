import { IStore } from "@admin_app/storage/store";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import * as ApplePayKeysActions from "@admin_app/storage/apple-pay-keys/apple-pay-keys.actions";
import * as ApplePayKeysSelectors from "@admin_app/storage/apple-pay-keys/apple-pay-keys.selectors";

import * as ApplePayKeyActions from "@admin_app/storage/apple-pay-key/apple-pay-key.actions";

@Component({
    selector: "app-apple-pay",
    templateUrl: "./apple-pay.component.html",
    styleUrls: ["./apple-pay.component.less"],
})
export class ApplePayComponent implements OnInit {

    applePayKeys$ = this.store.select(ApplePayKeysSelectors.getApplePayKeys);
    applePayKeysLoading$ = this.store.select(ApplePayKeysSelectors.getApplePayKeysLoading);
    applePayKeysError$ = this.store.select(ApplePayKeysSelectors.getApplePayKeysError);

    applePayMerchantIdentityKeys$ = this.store.select(ApplePayKeysSelectors.getApplePayMerchantIdentityKeys);
    applePayMerchantIdentityKeysLoading$ = this.store.select(ApplePayKeysSelectors.getApplePayMerchantIdentityKeysLoading);
    applePayMerchantIdentityKeysError$ = this.store.select(ApplePayKeysSelectors.getApplePayMerchantIdentityKeysError);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new ApplePayKeysActions.GetPaymentProcessingCertificatesAction());
        this.store.dispatch(new ApplePayKeysActions.GetMerchantIdentityKeysAction());
    }

    onUploadPaymentProcessingCertificate() {
        this.store.dispatch(new ApplePayKeyActions.UploadPaymentProcessingCertificateAction());
    }

    onGetPaymentProcessingCsr() {
        this.store.dispatch(new ApplePayKeyActions.GetPaymentProcessingCsrAction());
    }

    onRevokePaymentProcessingCertificate(token: string) {
        this.store.dispatch(new ApplePayKeyActions.RevokePaymentProcessingCertificateKeyAction(token));
    }

    onUploadMerchantIdentityCertificate() {
        this.store.dispatch(new ApplePayKeyActions.UploadMerchantIdentityCertificateAction());
    }

    onGetMerchantIdentityCsr() {
        this.store.dispatch(new ApplePayKeyActions.GetMerchantIdentityCsrAction());
    }

    onRevokeMerchantIdentity(token: string) {
        this.store.dispatch(new ApplePayKeyActions.RevokeMerchantIdentityKeyAction(token));
    }

}
