import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { UsersService } from "@admin_api/services/users.service";
import { OrganizationRolesService } from "@admin_api/services/organization-roles.service";
import { GoBackAction, PageLoadFailAction } from "../router/router.actions";
import { GetCurrentUserAction, GetCurrentUserSucceedAction } from "../current-user/current-user.actions";
import { ConfirmationModalComponent, ConfirmationModalData } from "shared-lib";
import { ActionTypes, CreateUserAction, DeleteUserAction, GetNewUserAction, GetUserAction, GetUserFailAction, GetUserSucceedAction,
    UpdateUserAction } from "./user.actions";
import { ActionTypes as CurrentUserActionTypes } from "../current-user/current-user.actions";
import { UserDetailsDto } from "@admin_api/models/user-details-dto";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { GlobalSpinnerService } from "shared-lib";
import { CreateRoleDto } from "@admin_api/models/create-role-dto";
import { PermissionsService } from "@admin_api/services/permissions.service";
import { PermissionDto } from "@admin_api/models/permission-dto";

@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private usersService: UsersService,
        private organizationRolesService: OrganizationRolesService,
        private permissionsService: PermissionsService,
        private dialog: MatDialog,
        private globalSpinner: GlobalSpinnerService,
        private store: Store<IStore>
    ) {}

    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetUserAction>(ActionTypes.GetUser),
            switchMap(action =>
                forkJoin({
                    user: this.usersService.usersGet({ organizationId: action.merchantId, id: action.id }),
                    roleList: this.organizationRolesService.organizationRolesSearch({ organizationId: action.merchantId }),
                }).pipe(
                    mergeMap(({ user, roleList }) => of(new GetUserSucceedAction(user, roleList))),
                    catchError((error) => of(new GetUserFailAction(error)))
                )
            )
        ),
    );

    getNewUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewUserAction>(ActionTypes.GetNewUser),
            switchMap((action) =>
                this.organizationRolesService.organizationRolesSearch({ organizationId: action.merchantId }).pipe(
                    this.createAdminRoleIfNoRolesExist.bind(this, action),
                    switchMap((roleList) => {
                        const newUser: UserDetailsDto = {
                            email: "",
                            firstName: "",
                            id: "",
                            isLockedOut: false,
                            lastName: "",
                            roleIds: []
                        };
                        return of(new GetUserSucceedAction(newUser, roleList));
                    }),
                    catchError((error) => of(new GetUserFailAction(error)))
                )
            ),
        ),
    );

    getUserFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetUserFailAction>(ActionTypes.GetUserFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    updateUser$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateUserAction>(ActionTypes.UpdateUser),
        switchMap(({ merchantId, user }) =>
            this.globalSpinner.apply(this.usersService.usersPut({ organizationId: merchantId, id: user.id, body: user }).pipe(
                this.waitOnGetCurrentUser.bind(this),
                switchMap(() => of(GoBackAction()))
            ))
        )
    ));

    deleteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteUserAction>(ActionTypes.DeleteUser),
            switchMap(({ merchantId, userId }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete User",
                        subtitle: "Are you sure you want to permanently delete this user record?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ merchantId, userId, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ merchantId, userId }) =>
                this.globalSpinner.apply(
                    this.usersService.usersDelete({ organizationId: merchantId, id: userId }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        )
    );
/*
    resetUserPassword$ = createEffect(() => this.actions$.pipe(
            ofType<ResetUserPasswordAction>(ActionTypes.ResetUserPassword),
            switchMap(({ userId }) => this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Reset Password",
                        subtitle: "Are you sure you want to reset this user password?",
                        confirmButtonText: "Reset"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((resetConfirmed: boolean) => ({ userId, resetConfirmed })),
                )),
            filter(({ resetConfirmed }) => resetConfirmed),
            switchMap(({ userId }) =>
                this.globalSpinner.apply(
                    this.usersService.usersResetPassword({ mid: merchantId, id: userId, body: { returnUrl: window.location.origin } }).pipe(
                        map(() => {
                            this.toast.open("We have sent an email with password reset instructions to user email", VituToastTone.Positive);
                        })
                    )
                )
            )
        ), { dispatch: false });
*/
    createUser$ = createEffect(() => this.actions$.pipe(
            ofType<CreateUserAction>(ActionTypes.CreateUser),
            switchMap(({ merchantId, user }) =>
                this.globalSpinner.apply(
                    this.usersService.usersPost({ organizationId: merchantId, body: user }).pipe(
                        switchMap(() => of(GoBackAction()))
                    )
                )
            )
        ));

    private waitOnGetCurrentUser<T>(source: Observable<T>) {
        // AC_todo : Need to improve this:
        // Inefficient => Only needs called if role being altered is for current user
        return source.pipe(
            map(() => {
                this.store.dispatch(new GetCurrentUserAction());
                return 1;
            }),
            switchMap(() => this.actions$.pipe(
                ofType<GetCurrentUserSucceedAction>(CurrentUserActionTypes.GetCurrentUserSucceed),
                take(1))
            )
        );
    }

    private createAdminRoleIfNoRolesExist<T>(action: any, source: Observable<T>) {
        return source.pipe(
            switchMap((roleList: any) => {
                if (!roleList?.length) {
                    return this.permissionsService.permissionsGetPermissions().pipe(
                        switchMap((permissions: Array<PermissionDto>) => {
                            const role: CreateRoleDto = {
                                name: "Admin",
                                permissionIds: permissions?.map(permission => permission.id)
                            };
                            return this.organizationRolesService.organizationRolesPost({ organizationId: action.merchantId, body: role })
                                .pipe(
                                    switchMap(
                                        () => this.organizationRolesService.organizationRolesSearch({ organizationId: action.merchantId })
                                )
                            );
                        })
                    );
                }
                return of(roleList);
            })
        );
    }

}
