import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnDef, TableSorting, Pager, LocalTimePoint } from "shared-lib";
import { Filter as DfmFilesFilter, Sorting as DfmFilesSorting } from "@admin_app/storage/dfm-files/dfm-files.state";
import { Filter as BinFilesFilter, Sorting as BinFilesSorting } from "@admin_app/storage/bin-files/bin-files.state";
import { DfmFileDto } from "@admin_api/models/dfm-file-dto";
import { BinFileDto } from "@admin_api/models/bin-file-dto";
import { DfmFileOrderByEnum } from "@admin_api/models/dfm-file-order-by-enum";
import { BinFileOrderByEnum } from "@admin_api/models/bin-file-order-by-enum";

@Component({
    selector: "app-google-cloud-storage-log",
    templateUrl: "./google-cloud-storage-log.component.html",
    styleUrls: ["./google-cloud-storage-log.component.less"]
})
export class GoogleCloudStorageLogComponent {

    @Input() dfmFiles: Array<DfmFileDto> = [];
    @Input() dfmFilesLoading = false;
    @Input() dfmFilesError: Error;
    @Input() dfmFilesSorting: DfmFilesSorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() dfmFilesFilter: DfmFilesFilter;
    @Input() dfmFilesPager: Pager;

    @Output() dfmFilesSortingChanged = new EventEmitter<DfmFilesSorting>();
    @Output() dfmFilesFilterChanged = new EventEmitter<DfmFilesFilter>();
    @Output() dfmFilesPageChanged = new EventEmitter<number>();
    @Output() reprocessDfmFile = new EventEmitter<number>();
    @Output() downloadDfmFile = new EventEmitter<number>();

    @Input() binFiles: Array<BinFileDto> = [];
    @Input() binFilesLoading = false;
    @Input() binFilesError: Error;
    @Input() binFilesSorting: BinFilesSorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() binFilesFilter: BinFilesFilter;
    @Input() binFilesPager: Pager;

    @Output() binFilesSortingChanged = new EventEmitter<BinFilesSorting>();
    @Output() binFilesFilterChanged = new EventEmitter<BinFilesFilter>();
    @Output() binFilesPageChanged = new EventEmitter<number>();
    @Output() reprocessBinFile = new EventEmitter<number>();
    @Output() downloadBinFile = new EventEmitter<number>();

    readonly dfmFilesColumnDefs: ColumnDef[];
    readonly binFilesColumnDefs: ColumnDef[];

    constructor() {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.dfmFilesColumnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: DfmFileOrderByEnum.Id, title: "File Ref", flexWidthBasisInPixels: 110, flexWidthGrow: 0, canSort: true},
            {id: DfmFileOrderByEnum.FullName, title: "Name", flexWidthBasisInPixels: 480, flexWidthGrow: 3, canSort: true},
            {id: DfmFileOrderByEnum.Owner, title: "Owner", flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: DfmFileOrderByEnum.CreatedUtc, title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: DfmFileOrderByEnum.UpdatedUtc, title: `Date Updated (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: DfmFileOrderByEnum.Status, title: "Status", flexWidthBasisInPixels: 110, flexWidthGrow: 1, canSort: true},
            {id: "reprocess", title: "Reprocess", flexWidthBasisInPixels: 40, flexWidthGrow: 1},
            {id: "download", title: "Download", flexWidthBasisInPixels: 40, flexWidthGrow: 1},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];

        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.binFilesColumnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            {id: BinFileOrderByEnum.Id, title: "File Ref", flexWidthBasisInPixels: 110, flexWidthGrow: 0, canSort: true},
            {id: BinFileOrderByEnum.FileName, title: "Name", flexWidthBasisInPixels: 480, flexWidthGrow: 3, canSort: true},
            {id: BinFileOrderByEnum.CreatedUtc, title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: BinFileOrderByEnum.UpdatedUtc, title: `Date Updated (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true},
            {id: BinFileOrderByEnum.Status, title: "Status", flexWidthBasisInPixels: 110, flexWidthGrow: 1, canSort: true},
            {id: "reprocess", title: "Reprocess", flexWidthBasisInPixels: 40, flexWidthGrow: 1},
            {id: "download", title: "Download", flexWidthBasisInPixels: 40, flexWidthGrow: 1},
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        ];
    }

    onDfmFilesSortingChanged(sorting: TableSorting) {
        this.dfmFilesSortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

    onBinFilesSortingChanged(sorting: TableSorting) {
        this.binFilesSortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

}
