import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getDisputesState = (store: IStore) => store.disputes;

export const getDisputes = createSelector(
    getDisputesState,
    disputesState => disputesState.record
);

export const getDisputesLoading = createSelector(
    getDisputesState,
    disputesState => disputesState.loading
);

export const getDisputesError = createSelector(
    getDisputesState,
    disputesState => disputesState.error
);

export const getDisputesPager = createSelector(
    getDisputesState,
    disputesState => disputesState.pager
);

export const getDisputesSorting = createSelector(
    getDisputesState,
    disputesState => disputesState.sorting
);

export const getDisputesFilter = createSelector(
    getDisputesState,
    disputesState => disputesState.filter
);

export const getDisputesExport = createSelector(
    getDisputesState,
    disputesState => disputesState.exportLoading
);
