import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetPaymentProcessingCertificatesAction,
    GetPaymentProcessingCertificatesFailAction, GetPaymentProcessingCertificatesSucceedAction } from "./google-pay-keys.actions";
import { GooglePaymentProcessingCertificateService } from "@admin_api/services/google-payment-processing-certificate.service";

@Injectable()
export class GooglePayKeysEffects {

    constructor(
        private actions: Actions,
        private googlePayService: GooglePaymentProcessingCertificateService
    ) {}

    getPaymentProcessingCertificates$ = createEffect(() =>
        this.actions.pipe(
            ofType<GetPaymentProcessingCertificatesAction>(ActionTypes.GetPaymentProcessingCertificates),
            switchMap(() =>
                this.googlePayService.googlePaymentProcessingCertificateGetCertificates().pipe(
                    switchMap((digitalWalletKeysDto) => of(new GetPaymentProcessingCertificatesSucceedAction(digitalWalletKeysDto))),
                    catchError((error) => of(new GetPaymentProcessingCertificatesFailAction(error)))
                )
            )
        ),
    );

}
