import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Filter } from "@admin_app/storage/transactions/transactions.state";
import { CardBrandEnum } from "@admin_api/models/card-brand-enum";
import { TransactionStatus } from "@admin_api/models/transaction-status";
import { LocalTimePoint } from "shared-lib";
import { GatewayEnum } from "@admin_api/models/gateway-enum";
import { OrganizationDto } from "@admin_api/models/organization-dto";
import { DigitalWalletEnum } from "@admin_api/models/digital-wallet-enum";
import { TransactionType } from "@admin_api/models/transaction-type";

@Component({
    selector: "app-transaction-log-filters",
    templateUrl: "./transaction-log-filters.component.html",
    styleUrls: ["./transaction-log-filters.component.less"],
})
export class TransactionLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();

    @Input() filter: Filter;

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    gatewayList: Array<GatewayEnum>;
    typeList: Array<TransactionType>;
    digitalWalletList: Array<DigitalWalletEnum>;
    serviceFeeList: Array<string>;

    // eslint-disable-next-line max-len
    readonly moreFields = ["statuses", "types", "isServiceFee", "first6", "last4", "amount", "payerName", "cardBrands", "transactionId", "authCode", "localDateSettledInterval", "localDateFundedInterval", "digitalWallets", "token"];

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.gatewayList = Object.values(GatewayEnum);
        this.typeList = [...Object.values(TransactionType)].sort();
        this.digitalWalletList = Object.values(DigitalWalletEnum);
        this.serviceFeeList = [
            "No",
            "Yes"
        ];
    }

    amountMask = {
        mask: Number,
        scale: 2,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        radix: ".",
        mapToRadix: [","]
    };

    filterForm = this.fb.group({
        localDateInterval: [null],
        localDateSettledInterval: [null],
        transactionId: [null],
        amount: [null],
        authCode: [null],
        first6: [null, [Validators.pattern(/^\d{6}$/)]],
        last4: [null, [Validators.pattern(/^\d{4}$/)]],
        payerName: [null, [Validators.pattern(/^[a-zA-Z0-9\s]+$/)]],
        cardBrands: [null],
        types: [null],
        statuses: [null],
        gateways: [null],
        tid: [null],
        mid: [null],
        localDateFundedInterval: [null],
        merchantId: [null],
        dba: [null],
        digitalWallets: [null],
        isServiceFee: [null],
        token: [null]
    });

    cardList = [
        CardBrandEnum.Amex,
        CardBrandEnum.Discover,
        CardBrandEnum.MasterCard,
        CardBrandEnum.Visa,
    ];

    statusList = Object.values(TransactionStatus);

    onFilterChanged(filter: any) {

        this.filterChanged.emit(filter);
    }

    get noMerchants(): boolean {
        return (!this.merchantsLoading) && !(this.merchants?.length);
    }

}
