/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DfmFileOrderByEnum } from '../models/dfm-file-order-by-enum';
import { DfmFileStatusEnum } from '../models/dfm-file-status-enum';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfDfmFileDto } from '../models/paged-data-dto-of-dfm-file-dto';

@Injectable({
  providedIn: 'root',
})
export class DfmFilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dfmFilesRescan
   */
  static readonly DfmFilesRescanPath = '/v1/admin/dfm-files/rescan';

  /**
   * Starts the scan process for missed DFM files.
   *
   * Use this endpoint to find missed DFM files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dfmFilesRescan()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesRescan$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DfmFilesService.DfmFilesRescanPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Starts the scan process for missed DFM files.
   *
   * Use this endpoint to find missed DFM files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dfmFilesRescan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesRescan(params?: {
  }): Observable<void> {

    return this.dfmFilesRescan$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation dfmFilesSearch
   */
  static readonly DfmFilesSearchPath = '/v1/admin/dfm-files';

  /**
   * Lists DFM files by the query.
   *
   * Use this endpoint in order to get the DFM files list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dfmFilesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesSearch$Response(params?: {
    OrderBy?: Array<DfmFileOrderByEnum>;
    Statuses?: Array<DfmFileStatusEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfDfmFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, DfmFilesService.DfmFilesSearchPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfDfmFileDto>;
      })
    );
  }

  /**
   * Lists DFM files by the query.
   *
   * Use this endpoint in order to get the DFM files list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dfmFilesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesSearch(params?: {
    OrderBy?: Array<DfmFileOrderByEnum>;
    Statuses?: Array<DfmFileStatusEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfDfmFileDto> {

    return this.dfmFilesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfDfmFileDto>) => r.body as PagedDataDtoOfDfmFileDto)
    );
  }

  /**
   * Path part for operation dfmFilesReprocess
   */
  static readonly DfmFilesReprocessPath = '/v1/admin/dfm-files/{id}/reprocess';

  /**
   * Starts DFM file processing for already processed file.
   *
   * Use this endpoint carefully in order to reprocess the DFM file. It doesn't work for files in Processing state
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dfmFilesReprocess()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesReprocess$Response(params: {

    /**
     * DFM file id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DfmFilesService.DfmFilesReprocessPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Starts DFM file processing for already processed file.
   *
   * Use this endpoint carefully in order to reprocess the DFM file. It doesn't work for files in Processing state
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dfmFilesReprocess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesReprocess(params: {

    /**
     * DFM file id
     */
    id: number;
  }): Observable<void> {

    return this.dfmFilesReprocess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation dfmFilesDownload
   */
  static readonly DfmFilesDownloadPath = '/v1/admin/dfm-files/{id}/download';

  /**
   * Gets the archived and parsed DFM file by id.
   *
   * Use this endpoint in order to get the zip-archived file that contains original and parsed DFM files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dfmFilesDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesDownload$Response(params: {

    /**
     * DFM file id
     */
    id: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DfmFilesService.DfmFilesDownloadPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Gets the archived and parsed DFM file by id.
   *
   * Use this endpoint in order to get the zip-archived file that contains original and parsed DFM files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dfmFilesDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dfmFilesDownload(params: {

    /**
     * DFM file id
     */
    id: number;
  }): Observable<Blob> {

    return this.dfmFilesDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
