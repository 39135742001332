import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigationAction } from "@ngrx/router-store";
import { map, filter, switchMap, catchError, mergeMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import * as TransactionActions from "./transaction.actions";
import { PaymentsService } from "@admin_api/services/payments.service";
import { GetPaymentDetailAction } from "../payment/payment.actions";
//import { GcpStorageResource } from "@admin_api/models/gcp-storage-resource";
import { GenericNoOpAction } from "../generic.actions";
import { PageLoadFailAction } from "../router/router.actions";
import { GcpStorageResource } from "@admin_api/models/gcp-storage-resource";

@Injectable()
export class TransactionEffects {

    constructor(
        private actions: Actions,
        private paymentsService: PaymentsService
    ) {}

    initTransactionDetailPage = createEffect(() => this.actions.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigationAction) => new RegExp(/dashboard\/transactions\/.*/).test(action.payload.routerState.url)),
            map((action: any) => {
                const rS = action.payload.routerState;
                const token = rS.root.firstChild.firstChild.params.id;
                return TransactionActions.GetTransactionDetailAction({ token, getRelations: true });
            }),
        ));

    getTransaction = createEffect(() => this.actions.pipe(
            ofType(TransactionActions.GetTransactionDetailAction),
            switchMap(({ token, getRelations }) =>
                this.paymentsService.transactionsGetTransaction({ token })
                    .pipe(
                        mergeMap(record => of(TransactionActions.GetTransactionDetailSucceedAction({ record, getRelations }))),
                        catchError((error) => of(TransactionActions.GetTransactionDetailFailAction(error)))
                    )
            )
        ));

    getTransactionSucceed = createEffect(() => this.actions.pipe(
            ofType(TransactionActions.GetTransactionDetailSucceedAction),
            switchMap(action => (action.getRelations
                    ? of(new GetPaymentDetailAction(action.record.paymentToken, action.getRelations))
                    : of(new GenericNoOpAction()))),
        ));

    getTransactionFail = createEffect(() => this.actions.pipe(
        ofType(TransactionActions.GetTransactionDetailFailAction),
        switchMap(() => of(PageLoadFailAction()))
    ));

    downloadReceipt = createEffect(() => this.actions.pipe(
            ofType(TransactionActions.DownloadReceiptAction),
            switchMap(({ transaction }) => this.paymentsService.transactionsGetReceipt({ token: transaction.token }).pipe(
                    catchError(() => of(null as GcpStorageResource)),
                )),
            map(response => response?.url),
            filter(url => !!url),
            tap(url => { window.open(url, "_self"); }),
        ), { dispatch: false });

}
