import { Action } from "@ngrx/store";
import { UserDto } from "@admin_api/models/user-dto";

export enum ActionTypes {
    ResetUsers = "[Users] ResetUsers",
    GetUsers = "[Users] GetUsers",
    GetUsersSucceed = "[Users] GetUsersSucceed",
    GetUsersFail = "[Users] GetUsersFail"
}

export class ResetUsersAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetUsers;
}

export class GetUsersAction implements Action {

    constructor(public merchantId: number) {}

    readonly type = ActionTypes.GetUsers;
}

export class GetUsersSucceedAction implements Action {

    constructor(public users: Array<UserDto>) {}

    readonly type = ActionTypes.GetUsersSucceed;
}

export class GetUsersFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetUsersFail;
}
