import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Filter } from "@admin_app/storage/funding/funding.state";
import { LocalTimePoint } from "shared-lib";
import { FundingStatus } from "@admin_api/models/funding-status";
import { OrganizationDto } from "@admin_api/models/organization-dto";
import { ProcessorEnum } from "@admin_api/models/processor-enum";
;

@Component({
    selector: "app-funding-log-filters",
    templateUrl: "./funding-log-filters.component.html",
    styleUrls: ["./funding-log-filters.component.less"]
})
export class FundingLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    LocalTimePoint = LocalTimePoint;

    fundingStatusList: Array<FundingStatus>;
    processorList: Array<ProcessorEnum>;

    readonly moreFields = ["processors"];

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.fundingStatusList = Object.values(FundingStatus);

        // Remove 'Processing' item from current position
        const processingIndex = this.fundingStatusList.findIndex((element) => element === FundingStatus.Processing);
        this.fundingStatusList.splice(processingIndex, 1);

        this.processorList = Object.values(ProcessorEnum);
    }

    filterForm = this.fb.group({
        localDateInterval: [null],
        fundingStatuses: [null],
        merchantId: [null],
        mid: [null],
        dba: [null],
        localFundedDateInterval: [null],
        processors: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

    get noMerchants(): boolean {
        return (!this.merchantsLoading) && !(this.merchants?.length);
    }

    wipeField(fieldName: string) {
        this.filterForm.get(fieldName).setValue(undefined);
        this.filterForm.updateValueAndValidity();
    }

}
