import { ModalAction, ModalActionType } from "@admin_app/shared/modal-action";

export interface PairDeviceParams {
    sourceMerchantId: number;
    merchantTerminalId: number;
    userCode: string;
}

export class PairDeviceModalAction extends ModalAction {
    constructor(public action: ModalActionType, public params?: PairDeviceParams) {
        super(action, params);
    }
}
