import { OrganizationDto } from "@admin_api/models/organization-dto";;

export class OrganizationState {
    loading = false;
    error = null;
    record: OrganizationDto = null;
    ancestorsLoading = false;
    ancestorsError = null;
    ancestors: Array<OrganizationDto> = [];
}
