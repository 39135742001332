import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getOrganizationState = (store: IStore) => store.organization;

export const getOrganizationInfo = createSelector(
    getOrganizationState,
    organizationState => organizationState.record
);

export const getOrganizationLoading = createSelector(
    getOrganizationState,
    organizationState => organizationState.loading
);

export const getOrganizationAncestors = createSelector(
    getOrganizationState,
    organizationState => organizationState.ancestors
);

export const getOrganizationAncestorsLoading = createSelector(
    getOrganizationState,
    organizationState => organizationState.ancestorsLoading
);
