import { Component, Input } from "@angular/core";

@Component({
    selector: "app-fee-plan-mids-table-cell-content",
    templateUrl: "./fee-plan-mids-table-cell-content.component.html",
    styleUrls: ["./fee-plan-mids-table-cell-content.component.less"],
})
export class FeePlanMidsTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

    get sortingKeyForFeePlanAssociateDate(): number {
        // Timezone independent (as just for comparision with like values)
        return new Date(this.element.associationDateUtc).getTime();
    }

}
