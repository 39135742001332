import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, filter as filterOperator, map, withLatestFrom, tap } from "rxjs/operators";
import { of, Subject } from "rxjs";

import { BillingService } from "@admin_api/services/billing.service";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import {
    ActionTypes,
    ExportTransactionsToCsvAction,
    ExportTransactionsToCsvFailAction,
    ExportTransactionsToCsvSucceedAction,
    FilterFundingItemTransactionsAction,
    GetFundingItemAction,
    GetFundingItemAdjustmentsAction,
    GetFundingItemAdjustmentsFailAction,
    GetFundingItemAdjustmentsSucceedAction,
    GetFundingItemFailAction,
    GetFundingItemMidFeesAction,
    GetFundingItemMidFeesFailAction,
    GetFundingItemMidFeesSucceedAction,
    GetFundingItemSucceedAction,
    GetFundingItemTransactionsAction,
    GetFundingItemTransactionsFailAction,
    GetFundingItemTransactionsSucceedAction,
    PageChangedFundingItemTransactionsAction,
    RequestSettlementAction,
    ResendFundingAction,
    SortFundingItemTransactionsAction
} from "./funding-item.actions";
import { ConfirmationModalComponent, ConfirmationModalData, ErrorUi,
    ErrorUiService, GlobalSpinnerService, LocalTimePoint, VituCommonErrorEntityIdMustBeNumber,
    VituToastService, VituToastTone } from "shared-lib";
import { MatDialog } from "@angular/material/dialog";
import { GenericNoOpAction } from "../generic.actions";
import { GoBackAction, PageLoadFailAction } from "../router/router.actions";
import { getFundingItemState } from "./funding-item.selectors";
import { PagedDataDtoOfFundingTransaction } from "@admin_api/models/paged-data-dto-of-funding-transaction";
import { TransactionsFilter, TransactionsSorting } from "./funding-item.state";
import { StrictHttpResponse } from "@admin_api/strict-http-response";
import { RequestSettlementModalAction } from "@admin_app/shared/request-settlement-modal/request-settlement-modal-action";
import { RequestSettlementModalComponent } from "@admin_app/shared/request-settlement-modal/request-settlement-modal.component";
import { ModalActionType } from "@admin_app/shared/modal-action";
import { ManualFundingSettlementResult } from "@admin_api/models/manual-funding-settlement-result";

@Injectable()
export class FundingItemEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService,
        private store: Store<IStore>,
        private dialog: MatDialog,
        private toast: VituToastService,
        private globalSpinner: GlobalSpinnerService,
        private errorUi: ErrorUiService,
    ) {}

    getFundingItem$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetFundingItemAction>(ActionTypes.GetFundingItem),
            switchMap(action => {
                if (isNaN(action.id)) {
                    return of(new GetFundingItemFailAction(new VituCommonErrorEntityIdMustBeNumber()));
                }
                return this.billingService.fundingGetFundingResult({ id: action.id }).pipe(
                    switchMap(fundingItem => of(new GetFundingItemSucceedAction(fundingItem))),
                    catchError((error) => of(new GetFundingItemFailAction(error)))
                );
            })
        ),
    );

    getFundingItemSucceed$ = createEffect(() =>

        this.actions$.pipe(
            ofType<GetFundingItemSucceedAction>(ActionTypes.GetFundingItemSucceed),
            switchMap(action =>
                [
                    new GetFundingItemTransactionsAction(action.fundingItem.id, 1),
                    new GetFundingItemMidFeesAction(action.fundingItem.id),
                    new GetFundingItemAdjustmentsAction(action.fundingItem.id)
                ]
            )
        ),
    );

    getFundingItemFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemFailAction>(ActionTypes.GetFundingItemFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    resend = createEffect(() =>

        this.actions$.pipe(
            ofType<ResendFundingAction>(ActionTypes.ResendFunding),
            switchMap(({ event }) => this.dialog.open(ConfirmationModalComponent, {
                    width: "700px",
                    data: {
                        title: "Resend Funding",
                        subtitle: "Are you sure you want to resend this funding?",
                        confirmButtonText: "Resend"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((confirmed: boolean) => ({ event, confirmed })),
                )),
            filterOperator(({ confirmed }) => confirmed),
            switchMap(({ event }) =>
                this.errorUi.disable(
                    this.globalSpinner.apply(
                        this.billingService.fundingManualFunding({ originalId: event.id }).pipe(
                            switchMap(() => {
                                this.toast.open(`Funding redelivery triggered.`, VituToastTone.Positive);
                                return of(GoBackAction());
                            }),
                            catchError(() => {
                                this.toast.open(`Funding redelivery failed.`, VituToastTone.Negative);
                                return of(new GenericNoOpAction());
                            })
                        )
                    )
                )
            )
        ),
    );

    requestSettlement = createEffect((): any => this.actions$.pipe(
        ofType<RequestSettlementAction>(ActionTypes.RequestSettlement),
        switchMap(requestSettlementAction => {

            const errorMessageSubject = new Subject<string>();
            const modalActionSubject = new Subject<RequestSettlementModalAction>();

            const dialogRef = this.dialog
                .open(RequestSettlementModalComponent, {
                    width: "400px",
                    data: {
                        id: requestSettlementAction.id,
                        modalActionSubject,
                        errorMessageSubject
                    },
                    disableClose: true,
                    autoFocus: false
                });
            return modalActionSubject.pipe(
                switchMap(({action, params}: RequestSettlementModalAction) => {
                    switch (action) {
                        case ModalActionType.CANCEL:
                        {
                            dialogRef.close();
                            return of();
                        }
                        case ModalActionType.CONFIRM:
                        {
                            return this.globalSpinner.apply(this.billingService.fundingRequestSettlement(
                                {
                                    id: params.id,
                                    SettlementDate: LocalTimePoint.convertLocalValueToUtcValue(params.startDate),
                                    Amount: params.amount
                                })
                                .pipe(
                                    switchMap((response: ManualFundingSettlementResult) => {
                                        dialogRef.close();
                                        const settlementRef = response.settlementSummaryRef;
                                        this.toast.open(
                                            `Settlement ${settlementRef} added to funding instruction ${params.id}.`,
                                            VituToastTone.Positive);
                                        return of(new GetFundingItemAction(params.id));
                                    }),
                                    catchError(error => {
                                        errorMessageSubject.next(error instanceof Error ? error.message : error);
                                        return of();
                                    })
                                )
                            );
                        }
                    }
                })
            );
        })
    ));

    getFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemTransactionsAction>(ActionTypes.GetFundingItemTransactions),
            withLatestFrom(this.store.select(getFundingItemState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.transactionsFilter;
                const sorting = action.sorting ? action.sorting : state.transactionsSorting;
                const pageSize = action.pageSize ? action.pageSize : state.transactionsPager.pageSize;
                const params = this.getParams(action.id, sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.billingService.fundingSearchFundingTransactions(params).pipe(
                    switchMap((response) =>
                        of(new GetFundingItemTransactionsSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) => of(new GetFundingItemTransactionsFailAction(error)))
                );
            })
        )
    );

    pageChangedFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedFundingItemTransactionsAction>(ActionTypes.PageChangedFundingItemTransactions),
            switchMap(action => of(new GetFundingItemTransactionsAction(action.id, action.page)))
        ),
    );

    sortFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<SortFundingItemTransactionsAction>(ActionTypes.SortFundingItemTransactions),
            switchMap(action => of(new GetFundingItemTransactionsAction(action.id, 1, undefined, action.sorting)))
        ),
    );

    filterFundingItemTransactions = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterFundingItemTransactionsAction>(ActionTypes.FilterFundingItemTransactions),
            switchMap(action => of(new GetFundingItemTransactionsAction(action.id, 1, undefined, undefined, action.filter)))
        ),
    );

    exportTransactionsToCsv = createEffect(() =>
        this.actions$.pipe(
            ofType<ExportTransactionsToCsvAction>(ActionTypes.ExportTransactionsToCsv),
            withLatestFrom(this.store.select(getFundingItemState)),
            switchMap(([action, state]) => {

                const params = this.getExportParams(
                    action.id,
                    state.transactionsSorting,
                    state.transactionsFilter,
                    state.transactionsPager.pageSize,
                    state.transactionsPager.page);

                return this.billingService.fundingExportFundingTransactions$Response(params).pipe(
                    switchMap((response) =>
                        of(new ExportTransactionsToCsvSucceedAction(response))),
                    catchError((error) =>
                        of(new ExportTransactionsToCsvFailAction(error)))
                );
            })
        )
    );

    exportTransactionsToCsvSucceed = createEffect(() =>
        this.actions$.pipe(
            ofType<ExportTransactionsToCsvSucceedAction>(ActionTypes.ExportTransactionsToCsvSucceed),
            tap((action) => {
                try {
                    const fileName = this.getDownloadFileName(action.response);
                    this.openFile(fileName, action.response);
                }
                catch (error) {
                    this.toast.open("Unable to export file.", VituToastTone.Negative);
                }
            })
        ),{ dispatch: false }
    );

    getFundingItemMidFees = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemMidFeesAction>(ActionTypes.GetFundingItemMidFees),
            switchMap((action) =>
                this.billingService.fundingGetMidFees({id: action.id}).pipe(
                    switchMap((response) =>
                        of(new GetFundingItemMidFeesSucceedAction(response))),
                    catchError((error) =>
                        of(new GetFundingItemMidFeesFailAction(error)))
                ))
        )
    );

    getFundingItemAdjustments = createEffect(() =>
        this.actions$.pipe(
            ofType<GetFundingItemAdjustmentsAction>(ActionTypes.GetFundingItemAdjustments),
            switchMap((action) =>
                this.billingService.fundingGetAdjustments({id: action.id}).pipe(
                    switchMap((response) =>
                        of(new GetFundingItemAdjustmentsSucceedAction(response))),
                    catchError((error) =>
                        of(new GetFundingItemAdjustmentsFailAction(error)))
                ))
        )
    );

    private getExportParams(id: number, sorting: TransactionsSorting, filter: TransactionsFilter, pageSize: number, page: number): any {

        const retVal: any = {
            id
        };

        if (typeof filter.localFeesOnly === "string") {
            retVal.feesOnly = filter.localFeesOnly === "Yes";
        }

        return retVal;
    }

    private getParams(id: number, sorting: TransactionsSorting, filter: TransactionsFilter, pageSize: number, page: number): any {

        const retVal: any = {
            id,
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            // StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            // EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };

        if (typeof filter.localFeesOnly === "string") {
            retVal.FeesOnly = filter.localFeesOnly === "Yes";
        }

        return retVal;
    }

    private getDownloadFileName(response: StrictHttpResponse<Blob>): string {
        let fileName = null;

        try {
            const contentDisposition = response.headers.get("content-disposition");
            const regEx = /filename=[\"]?([^\"]+?)[\"]?;/;
            const fileNameMatches = contentDisposition.match(regEx);
            if (Array.isArray(fileNameMatches) && (fileNameMatches.length === 2)) {
                fileName = fileNameMatches[1];
            }
            if (!fileName) {
                throw new Error();
            }
        }
        catch (error) {
            throw new Error("Unable to download file.");
        }

        return fileName;
    }

    private openFile(fileName: string, response: StrictHttpResponse<Blob>): void {

        try {
            const blob = new Blob([response.body], { type: "application/octet-stream" });
            const fileUrl = URL.createObjectURL(blob);
            const tempAnchorElement: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
            tempAnchorElement.href = fileUrl;
            tempAnchorElement.download = fileName;
            document.body.appendChild(tempAnchorElement);
            tempAnchorElement.click();
            document.body.removeChild(tempAnchorElement);
            URL.revokeObjectURL(fileUrl);
        }
        catch (error) {
            throw new Error("Unable to open file.");
        }

    }

}
