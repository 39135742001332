<app-google-pay-detail
    [googlePayKeys]="googlePayKeys$ | async"
    [googlePayKeysLoading]="googlePayKeysLoading$ | async"
    [googlePayKeysError]="googlePayKeysError$ | async"
    (addNew)="onAddNewKey()"
    (revoke)="onRevoke($event)"
    (register)="onRegister($event)"
    (download)="onDownload($event)"
    (getPublicKeyHash)="onGetPublicKeyHash($event)"
    (makePrimary)="onMakePrimary($event)">
</app-google-pay-detail>
