import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActionButtonKind, ValidatorsVituCustom } from "shared-lib";
import { PermissionDto } from "@admin_api/models/permission-dto";
import { SolutionEnum } from "@admin_api/models/solution-enum";
import { ConfigDto } from "@admin_app/storage/config/config.state";
import { CreateGlobalRoleDto } from "@admin_api/models/create-global-role-dto";
import { UpdateGlobalRoleDto } from "@admin_api/models/update-global-role-dto";
import { GlobalRoleDetailsDto } from "@admin_api/models/global-role-details-dto";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-role-details",
    templateUrl: "./role-details.component.html",
    styleUrls: ["./role-details.component.less"],
})
export class RoleDetailsComponent implements OnInit, OnChanges {

    @Input() isCreate: boolean;
    @Input() config: ConfigDto;
    @Input() role: GlobalRoleDetailsDto;
    @Input() permissionList: PermissionDto[];
    @Input() loading: boolean;

    @Output() deleteRole = new EventEmitter<GlobalRoleDetailsDto>();
    @Output() updateRole = new EventEmitter<{id: number; updateGlobalRoleDto: UpdateGlobalRoleDto}>();
    @Output() createRole = new EventEmitter<CreateGlobalRoleDto>();
    @Output() back = new EventEmitter<void>();

    private readonly globalSolution = "GLOBAL";
    private uneditedSolutionValue: string;

    pageTitle: string;
    submitButtonText: string;

    solutions: Array<any> = [];

    ActionButtonKind = ActionButtonKind;

    constructor(
        private fb: UntypedFormBuilder,
    ) {
        this.solutions = [this.globalSolution].concat(Object.values(SolutionEnum));
    }

    roleForm = this.fb.group({
        name: [null, [Validators.required, Validators.pattern(/^[\w][\w- &()]*$/)]],
        solution: [null, [ValidatorsVituCustom.selectSingleIntegrity(() => this.solutions)]],
        permissionIds: [null as Array<{id: number; name: string; checked: boolean}>]
    });

    getDisplayTextForSolution(solutionVal: string): string {
        if (solutionVal === this.globalSolution) {
            return solutionVal;
        }
        let text = "";
        if (solutionVal && this.config?.solutionLookup) {
            const found = this.config.solutionLookup.filter(item => (item.id === solutionVal));
            if (found?.length) {
                text = found[0].name;
            }
        }
        return text;
    }

    get formControls(): FormControls<GlobalRoleDetailsDto> { return this.roleForm.controls; }

    ngOnInit() {

        this.pageTitle = (this.isCreate ? "New" : "Edit") + " Role";
        this.submitButtonText = (this.isCreate ? "Submit" : "Update");
    }

    ngOnChanges(changes: SimpleChanges) {

        if ("role" in changes && this.role) {
            this.roleForm.controls.name.patchValue(this.role.name);
            this.uneditedSolutionValue = (this.role.solution != null) ? this.role.solution : this.globalSolution;
            this.roleForm.controls.solution.patchValue(this.uneditedSolutionValue);
        }

        if ("permissionList" in changes && this.permissionList) {
            this.formControls?.permissionIds.patchValue(this.getFormArray());
        }

        if (!this.isCreate) {
            this.roleForm.markAllAsTouched();
        }
    }

    private getFormArray(): Array<any> {
        return this.permissionList?.map(i => ({
            ...i,
            checked: this.isChecked(i, this.role)
        }));
    }

    private isChecked(permission, role: GlobalRoleDetailsDto) {

        return role?.permissionIds
            ? role.permissionIds?.indexOf(permission.id) >= 0
            : false;
    }

    onClickDelete() {

        this.deleteRole.emit(this.role);
    }

    onClickBack() {

        this.back.emit();
    }

    onSubmit() {

        const roleForm = this.roleForm.value;

        const name = roleForm.name;
        const solutionValue = this.isCreate ? roleForm.solution : this.uneditedSolutionValue;
        const solution = (solutionValue === this.globalSolution) ? null : solutionValue;
        const permissionIds = roleForm.permissionIds.filter(i => i.checked).map(i => i.id);

        if (this.isCreate) {
            const createGlobalRoleDto: CreateGlobalRoleDto = {
                name,
                solution,
                permissionIds
            };
            this.createRole.emit(createGlobalRoleDto);
        }
        else {
            const updateGlobalRoleDto: UpdateGlobalRoleDto = {
                concurrencyToken: this.role?.concurrencyToken,
                name,
                solution,
                permissionIds
            };
            this.updateRole.emit({id: this.role.id, updateGlobalRoleDto});
        }

    }

}
