import { ActionButtonKind, AuthService } from "shared-lib";
import { Component } from "@angular/core";

@Component({
    selector: "app-forbidden",
    templateUrl: "./forbidden.component.html",
    styleUrls: ["./forbidden.component.less"]
})
export class ForbiddenComponent {

    ActionButtonKind = ActionButtonKind;

    constructor(
        private authService: AuthService,
    ) {}

    logOut() {

        this.authService.logout();
    }
}
