import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { IStore } from "@admin_app/storage/store";

import * as FeeTypesActions from "@admin_app/storage/fee-types/fee-types.actions";
import * as FeeTypesSelectors from "@admin_app/storage/fee-types/fee-types.selectors";

import * as FeePlanActions from "@admin_app/storage/fee-plan/fee-plan.actions";
import * as FeePlanSelectors from "@admin_app/storage/fee-plan/fee-plan.selectors";
import { GoBackAction } from "@admin_app/storage/router/router.actions";

@Component({
    selector: "app-fee-plan",
    templateUrl: "./fee-plan.component.html",
    styleUrls: ["./fee-plan.component.less"]
})
export class FeePlanComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    feePlan$ = this.store.select(FeePlanSelectors.getFeePlanInfo);
    feePlanLoading$ = this.store.select(FeePlanSelectors.getFeePlanLoading);
    feePlanError$ = this.store.select(FeePlanSelectors.getFeePlanError);

    feePlanMerchantProcessors$ = this.store.select(FeePlanSelectors.getFeePlanMerchantProcessors);
    feePlanMerchantProcessorsLoading$ = this.store.select(FeePlanSelectors.getFeePlanMerchantProcessorsLoading);
    feePlanMerchantProcessorsError$ = this.store.select(FeePlanSelectors.getFeePlanMerchantProcessorsError);

    feeTypes$ = this.store.select(FeeTypesSelectors.getFeeTypes);
    feeTypesLoading$ = this.store.select(FeeTypesSelectors.getFeeTypesLoading);
    feeTypesError$ = this.store.select(FeeTypesSelectors.getFeeTypesError);

    isCreate: boolean;

    ngOnInit() {
        this.isCreate = !this.route.snapshot.params.feePlanId;

        this.store.dispatch(new FeePlanActions.ResetFeePlanMerchantProcessorsAction());

        if (this.isCreate) {
            this.store.dispatch(new FeeTypesActions.GetFeeTypesAction());
            this.store.dispatch(new FeePlanActions.GetNewFeePlanAction());
        }
        else {
            const feePlanId = +this.route.snapshot.params.feePlanId;
            this.store.dispatch(new FeePlanActions.GetFeePlanAction(feePlanId));
            this.store.dispatch(new FeePlanActions.GetFeePlanMerchantProcessorsAction(feePlanId));
        }

    }

    updateFeePlan(payload: any) {

        this.store.dispatch(new FeePlanActions.UpdateFeePlanAction(payload.id, payload.dto));
    }

    createFeePlan(payload: any) {

        this.store.dispatch(new FeePlanActions.CreateFeePlanAction(payload.feePlan, payload.feeTypeValues));
    }

    deleteFeePlan(id: number) {

        this.store.dispatch(new FeePlanActions.DeleteFeePlanAction(id));
    }

    onCancel(): void {

        this.store.dispatch(GoBackAction());
    }

}
