/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfReportFileDto } from '../models/paged-data-dto-of-report-file-dto';
import { ReportFileOrderByEnum } from '../models/report-file-order-by-enum';
import { ReportFileStatusEnum } from '../models/report-file-status-enum';
import { ReportFileTypeEnum } from '../models/report-file-type-enum';

@Injectable({
  providedIn: 'root',
})
export class PayPalReportFilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation payPalReportFilesSearch
   */
  static readonly PayPalReportFilesSearchPath = '/v1/admin/paypal-report-files';

  /**
   * Get list of Paypal report files.
   *
   * Use this endpoint in order to get PayPal report files list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payPalReportFilesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesSearch$Response(params?: {
    OrderBy?: Array<ReportFileOrderByEnum>;
    Statuses?: Array<ReportFileStatusEnum>;
    Types?: Array<ReportFileTypeEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfReportFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, PayPalReportFilesService.PayPalReportFilesSearchPath, 'get');
    if (params) {
      rb.query('OrderBy', params.OrderBy, {"style":"form","explode":true});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('Types', params.Types, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfReportFileDto>;
      })
    );
  }

  /**
   * Get list of Paypal report files.
   *
   * Use this endpoint in order to get PayPal report files list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payPalReportFilesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesSearch(params?: {
    OrderBy?: Array<ReportFileOrderByEnum>;
    Statuses?: Array<ReportFileStatusEnum>;
    Types?: Array<ReportFileTypeEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfReportFileDto> {

    return this.payPalReportFilesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfReportFileDto>) => r.body as PagedDataDtoOfReportFileDto)
    );
  }

  /**
   * Path part for operation payPalReportFilesReprocess
   */
  static readonly PayPalReportFilesReprocessPath = '/v1/admin/paypal-report-files/{id}/reprocess';

  /**
   * Starts PayPal report file processing for already processed file.
   *
   * Use this endpoint carefully in order to reprocess PayPal report file. It doesn't work for files in Processing
   * state
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payPalReportFilesReprocess()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesReprocess$Response(params: {

    /**
     * PayPal report file id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PayPalReportFilesService.PayPalReportFilesReprocessPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Starts PayPal report file processing for already processed file.
   *
   * Use this endpoint carefully in order to reprocess PayPal report file. It doesn't work for files in Processing
   * state
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payPalReportFilesReprocess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesReprocess(params: {

    /**
     * PayPal report file id
     */
    id: number;
  }): Observable<void> {

    return this.payPalReportFilesReprocess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation payPalReportFilesRescan
   */
  static readonly PayPalReportFilesRescanPath = '/v1/admin/paypal-report-files/{id}/rescan';

  /**
   * Starts a scan process for PayPal report file.
   *
   * Use this endpoint to find missed PayPal report files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payPalReportFilesRescan()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesRescan$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PayPalReportFilesService.PayPalReportFilesRescanPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Starts a scan process for PayPal report file.
   *
   * Use this endpoint to find missed PayPal report files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payPalReportFilesRescan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesRescan(params: {
    id: number;
  }): Observable<void> {

    return this.payPalReportFilesRescan$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation payPalReportFilesDownload
   */
  static readonly PayPalReportFilesDownloadPath = '/v1/admin/paypal-report-files/{id}/download';

  /**
   * Gets the processed PayPal report file.
   *
   * Use this endpoint to download processed PayPal report file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payPalReportFilesDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesDownload$Response(params: {

    /**
     * PayPal file id
     */
    id: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PayPalReportFilesService.PayPalReportFilesDownloadPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Gets the processed PayPal report file.
   *
   * Use this endpoint to download processed PayPal report file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payPalReportFilesDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  payPalReportFilesDownload(params: {

    /**
     * PayPal file id
     */
    id: number;
  }): Observable<Blob> {

    return this.payPalReportFilesDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
