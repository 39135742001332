<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="FeePlanOrderBy.Id">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FeePlanOrderBy.Name">
        <lib-text-table-column>
            {{ element.name }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="FeePlanOrderBy.Description">
        <lib-text-table-column>
            {{ element.description }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
