import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "@admin_environments/environment";
import { GetCurrentUrl } from "shared-lib";

export const authConfig: AuthConfig = {
    issuer: environment.authRootUrl,
    redirectUri: GetCurrentUrl(),
    clientId: "backofficejs",
    responseType: "code",
    showDebugInformation: environment.isDevelopment,
    scope: "profile offline_access core-api.backoffice",
};
