/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrganizationDto } from '../models/create-organization-dto';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { OrganizationDto } from '../models/organization-dto';
import { OrganizationHierarchyModeEnum } from '../models/organization-hierarchy-mode-enum';
import { OrganizationOrderBy } from '../models/organization-order-by';
import { PagedDataDtoOfOrganizationDto } from '../models/paged-data-dto-of-organization-dto';
import { SolutionEnum } from '../models/solution-enum';
import { UpdateOrganizationDto } from '../models/update-organization-dto';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationsGet
   */
  static readonly OrganizationsGetPath = '/v1/admin/organizations/{id}';

  /**
   * Get organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<OrganizationDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationDto>;
      })
    );
  }

  /**
   * Get organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsGet(params: {
    id: number;
  }): Observable<OrganizationDto> {

    return this.organizationsGet$Response(params).pipe(
      map((r: StrictHttpResponse<OrganizationDto>) => r.body as OrganizationDto)
    );
  }

  /**
   * Path part for operation organizationsDelete
   */
  static readonly OrganizationsDeletePath = '/v1/admin/organizations/{id}';

  /**
   * Delete organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsDelete(params: {
    id: number;
  }): Observable<void> {

    return this.organizationsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation organizationsSearch
   */
  static readonly OrganizationsSearchPath = '/v1/admin/organizations/search';

  /**
   * Get list of organizations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsSearch$Response(params?: {
    Solution?: SolutionEnum;
    OrderBy?: OrganizationOrderBy;
    MerchantsOnly?: boolean;

    /**
     * If HierarchyMode is set, HierarchyOrganizationId must be used to specify the organization.
     * DirectChildren - return children of organization specified by HierarchyOrganizationId
     * FullHierarchy - return all organizations that have relation with HierarchyOrganizationId
     */
    HierarchyMode?: OrganizationHierarchyModeEnum;
    HierarchyOrganizationId?: number;

    /**
     * Search using contains. Minimum 3 characters
     */
    Name?: string;
    Mid?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfOrganizationDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsSearchPath, 'get');
    if (params) {
      rb.query('Solution', params.Solution, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('MerchantsOnly', params.MerchantsOnly, {});
      rb.query('HierarchyMode', params.HierarchyMode, {});
      rb.query('HierarchyOrganizationId', params.HierarchyOrganizationId, {});
      rb.query('Name', params.Name, {});
      rb.query('Mid', params.Mid, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfOrganizationDto>;
      })
    );
  }

  /**
   * Get list of organizations.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsSearch(params?: {
    Solution?: SolutionEnum;
    OrderBy?: OrganizationOrderBy;
    MerchantsOnly?: boolean;

    /**
     * If HierarchyMode is set, HierarchyOrganizationId must be used to specify the organization.
     * DirectChildren - return children of organization specified by HierarchyOrganizationId
     * FullHierarchy - return all organizations that have relation with HierarchyOrganizationId
     */
    HierarchyMode?: OrganizationHierarchyModeEnum;
    HierarchyOrganizationId?: number;

    /**
     * Search using contains. Minimum 3 characters
     */
    Name?: string;
    Mid?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfOrganizationDto> {

    return this.organizationsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfOrganizationDto>) => r.body as PagedDataDtoOfOrganizationDto)
    );
  }

  /**
   * Path part for operation organizationsPut
   */
  static readonly OrganizationsPutPath = '/v1/admin/organizations';

  /**
   * Update organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsPut$Response(params: {
    id?: number;
    body: UpdateOrganizationDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsPut(params: {
    id?: number;
    body: UpdateOrganizationDto
  }): Observable<void> {

    return this.organizationsPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation organizationsPost
   */
  static readonly OrganizationsPostPath = '/v1/admin/organizations';

  /**
   * Create organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsPost$Response(params: {
    body: CreateOrganizationDto
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsPost(params: {
    body: CreateOrganizationDto
  }): Observable<number> {

    return this.organizationsPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation organizationsRelink
   */
  static readonly OrganizationsRelinkPath = '/v1/admin/organizations/{id}/relink/{newParentId}';

  /**
   * Relink organization.
   *
   * Change the parent of the organization or merchant. Organization can't be reassigned to a different solution
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsRelink()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsRelink$Response(params: {
    id: number;
    newParentId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsRelinkPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('newParentId', params.newParentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Relink organization.
   *
   * Change the parent of the organization or merchant. Organization can't be reassigned to a different solution
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsRelink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsRelink(params: {
    id: number;
    newParentId: number;
  }): Observable<void> {

    return this.organizationsRelink$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
