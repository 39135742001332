<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'SFTP Files' }, { 'label': 'PayPal SFTP' }]">
    </lib-title-bar>

    <app-paypal-sftp-log-filters
        [filter]="filesFilter"
        (filterChanged)="filesFilterChanged.emit($event)">
    </app-paypal-sftp-log-filters>

    <lib-table-base
        class="files-table"
        [columnDefs]="filesColumnDefs"
        [rowsData]="files"
        [rowsDataLoading]="filesLoading"
        [rowsDataError]="filesError"
        rowsDataErrorMessage="Error Loading PayPal SFTP Files"
        [rowsDataShowWhileLoading]="true"
        [sorting]="filesSorting"
        (sortingChanged)="onSortingChanged($event)"
        [pager]="filesPager"
        (pageChanged)="filesPageChanged.emit($event)">

        <ng-template let-element="element" let-columnId="columnId">
            <app-paypal-sftp-table-cell-content
                [columnId]="columnId"
                [element]="element"
                (reprocess)="reprocessFile.emit($event)"
                (download)="downloadFile.emit($event)">
            </app-paypal-sftp-table-cell-content>
        </ng-template>
    </lib-table-base>

</div>
