import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { ActivatedRoute } from "@angular/router";
import * as WebhookActions from "@admin_app/storage/webhook/webhook.actions";
import * as WebhookSelectors from "@admin_app/storage/webhook/webhook.selectors";
import * as CurrentUserSelectors from "@admin_app/storage/current-user/current-user.selectors";
import * as WebhooksSelectors from "@admin_app/storage/webhooks/webhooks.selectors";
import * as WebhooksActions from "@admin_app/storage/webhooks/webhooks.actions";
import { GoBackAction } from "@admin_app/storage/router/router.actions";
import { CreateSolutionWebhookDto } from "@admin_api/models/create-solution-webhook-dto";
import { UpdateSolutionWebhookDto } from "@admin_api/models/update-solution-webhook-dto";
import * as ConfigSelectors from "@admin_app/storage/config/config.selectors";

@Component({
    selector: "app-webhook",
    templateUrl: "./webhook.component.html",
    styleUrls: ["./webhook.component.less"]
})
export class WebhookComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    webhook$ = this.store.select(WebhookSelectors.getWebhookInfo);
    config$ = this.store.select(ConfigSelectors.getConfig);
    lookups$ = this.store.select(WebhookSelectors.getWebhookLookups);
    loading$ = this.store.select(WebhookSelectors.getWebhookLoading);
    currentUser$ = this.store.select(CurrentUserSelectors.getCurrentUser);
    generatedSecret$ = this.store.select(WebhooksSelectors.getWebhooksSecret);
    generatedSecretLoading$ = this.store.select(WebhooksSelectors.getWebhooksSecretLoading);

    isCreate: boolean;

    ngOnInit() {

        this.store.dispatch(new WebhooksActions.ResetWebhooksSecretAction());

        this.isCreate = !this.route.snapshot.params.id;

        if (this.isCreate) {
            this.store.dispatch(new WebhookActions.GetNewWebhookAction());
        }
        else {
            this.store.dispatch(new WebhookActions.GetWebhookAction(this.route.snapshot.params.id));
        }
    }

    generateSecret() {

        this.store.dispatch(new WebhooksActions.GenerateWebhooksSecretAction());
    }

    updateWebhook(payload: {id: number; webhook: UpdateSolutionWebhookDto}) {

        this.store.dispatch(new WebhookActions.UpdateWebhookAction(payload.id, payload.webhook));
    }

    createWebhook(webhook: CreateSolutionWebhookDto) {

        this.store.dispatch(new WebhookActions.CreateWebhookAction(webhook));
    }

    deleteWebhook(webhookId: number) {

        this.store.dispatch(new WebhookActions.DeleteWebhookAction(webhookId));
    }

    onBack(): void {

        this.store.dispatch(GoBackAction());
    }

}
