<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'mid'">
        <lib-text-table-column>
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'organizationName'">
        <lib-text-table-column>
            {{ element.organizationName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'dba'">
        <lib-text-table-column>
            {{ element.dba }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
