import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ActionTypes, GetConfigAction, GetConfigFailAction, GetConfigSucceedAction } from "./config.actions";
import { SolutionsService } from "@admin_api/services/solutions.service";
import { ConfigDto } from "./config.state";

@Injectable()
export class ConfigEffects {

    constructor(
        private actions: Actions,
        private solutionsService: SolutionsService
    ) {}

    getConfig = createEffect(() => this.actions.pipe(
            ofType<GetConfigAction>(ActionTypes.GetConfig),
            switchMap(() => from(this.solutionsService.solutionsLookup())
                    .pipe(
                        switchMap(lookup => {
                            const configDto: ConfigDto = {
                                solutionLookup: lookup
                            };
                            return of(new GetConfigSucceedAction(configDto));
                        }),
                        catchError((error) =>
                            of(new GetConfigFailAction(error)))
                ))
        ));

}
