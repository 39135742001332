import { AdjustmentsState } from "./adjustments.state";
import { ActionTypes } from "./adjustments.actions";

export const AdjustmentsReducer = (
    state: AdjustmentsState = new AdjustmentsState(),
    action: any,
  ): AdjustmentsState => {

    switch (action.type) {

        case ActionTypes.GetAdjustments: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetAdjustmentsSucceed: {
            const morePagesMode = (action.response.totalPages == null);
            const retVal = {
                ...state,
                record: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
            return retVal;
        }

        case ActionTypes.GetAdjustmentsFail: {
            return {
                ...state,
                record: [],
                loading: false,
                error: action.error
            };
        }

        case ActionTypes.SortAdjustments: {
            return {
                ...state,
                loading: true,
                sorting: action.sorting
            };
        }

        case ActionTypes.FilterAdjustments: {
            return {
                ...state,
                loading: true,
                filter: action.filter
            };
        }

        default: {
            return state;
        }
    }

};
