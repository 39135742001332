import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, switchMap, withLatestFrom } from "rxjs/operators";
import {
    ActionTypes,
    PageChangedPayPalSftpFilesAction,
    GetPayPalSftpFilesAction,
    GetPayPalSftpFilesSucceedAction,
    SortPayPalSftpFilesAction,
    FilterPayPalSftpFilesAction,
    GetPayPalSftpFilesFailAction
} from "./paypal-sftp-files.actions";
import { of } from "rxjs";
import { Filter, Sorting } from "./paypal-sftp-files.state";
import { getPayPalSftpFilesState } from "./paypal-sftp-files.selectors";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { PayPalReportFilesService } from "@admin_api/services/pay-pal-report-files.service";
import { LocalTimePoint } from "shared-lib";

@Injectable()
export class PayPalSftpFilesEffects {

    constructor(
        private actions$: Actions,
        private payPalSftpFilesService: PayPalReportFilesService,
        private store: Store<IStore>
    ) {}

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortPayPalSftpFilesAction>(ActionTypes.SortPayPalSftpFiles),
            switchMap(action => of(new GetPayPalSftpFilesAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterPayPalSftpFilesAction>(ActionTypes.FilterPayPalSftpFiles),
            switchMap(action => of(new GetPayPalSftpFilesAction(1, undefined, action.filter, undefined)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedPayPalSftpFilesAction>(ActionTypes.PageChangedPayPalSftpFiles),
            switchMap(action => of(new GetPayPalSftpFilesAction(action.page)))
        ),
    );

    getPayPalSftpFiles = createEffect(() =>
        this.actions$.pipe(
            ofType<GetPayPalSftpFilesAction>(ActionTypes.GetPayPalSftpFiles),
            withLatestFrom(this.store.select(getPayPalSftpFilesState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.payPalSftpFilesService.payPalReportFilesSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetPayPalSftpFilesSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) => of(new GetPayPalSftpFilesFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                Types: filter.types,
                Statuses: filter.statuses
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
