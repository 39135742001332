/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CardBrandEnum } from '../models/card-brand-enum';
import { DigitalWalletEnum } from '../models/digital-wallet-enum';
import { DisputeTypeEnum } from '../models/dispute-type-enum';
import { GcpStorageResource } from '../models/gcp-storage-resource';
import { GatewayEnum } from '../models/gateway-enum';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfPaymentDto } from '../models/paged-data-dto-of-payment-dto';
import { PagedDataDtoOfTransactionDto } from '../models/paged-data-dto-of-transaction-dto';
import { PaymentDetailsDto } from '../models/payment-details-dto';
import { PaymentMethodType } from '../models/payment-method-type';
import { PaymentOrderBy } from '../models/payment-order-by';
import { PaymentStatus } from '../models/payment-status';
import { TransactionDetailDto } from '../models/transaction-detail-dto';
import { TransactionFeesResult } from '../models/transaction-fees-result';
import { TransactionOrderBy } from '../models/transaction-order-by';
import { TransactionStatus } from '../models/transaction-status';
import { TransactionType } from '../models/transaction-type';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation paymentsSearch
   */
  static readonly PaymentsSearchPath = '/v1/admin/payments';

  /**
   * Search payments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsSearch$Response(params?: {
    MerchantId?: number;
    FundingStartDate?: string;
    FundingEndDate?: string;
    DBA?: string;
    OrderBy?: PaymentOrderBy;
    Amount?: number;
    Token?: string;
    TID?: string;
    MID?: string;
    Description?: string;
    CustomerName?: string;
    DisputeTypes?: Array<DisputeTypeEnum>;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;
    Statuses?: Array<PaymentStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfPaymentDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsSearchPath, 'get');
    if (params) {
      rb.query('MerchantId', params.MerchantId, {});
      rb.query('FundingStartDate', params.FundingStartDate, {});
      rb.query('FundingEndDate', params.FundingEndDate, {});
      rb.query('DBA', params.DBA, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Amount', params.Amount, {});
      rb.query('Token', params.Token, {});
      rb.query('TID', params.TID, {});
      rb.query('MID', params.MID, {});
      rb.query('Description', params.Description, {});
      rb.query('CustomerName', params.CustomerName, {});
      rb.query('DisputeTypes', params.DisputeTypes, {"style":"form","explode":true});
      rb.query('PayerName', params.PayerName, {});
      rb.query('Cardholder', params.Cardholder, {});
      rb.query('Last4', params.Last4, {});
      rb.query('First6', params.First6, {});
      rb.query('CardBrands', params.CardBrands, {"style":"form","explode":true});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('Gateways', params.Gateways, {"style":"form","explode":true});
      rb.query('DigitalWallets', params.DigitalWallets, {"style":"form","explode":true});
      rb.query('SettlementStartDate', params.SettlementStartDate, {});
      rb.query('SettlementEndDate', params.SettlementEndDate, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfPaymentDto>;
      })
    );
  }

  /**
   * Search payments.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsSearch(params?: {
    MerchantId?: number;
    FundingStartDate?: string;
    FundingEndDate?: string;
    DBA?: string;
    OrderBy?: PaymentOrderBy;
    Amount?: number;
    Token?: string;
    TID?: string;
    MID?: string;
    Description?: string;
    CustomerName?: string;
    DisputeTypes?: Array<DisputeTypeEnum>;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;
    Statuses?: Array<PaymentStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfPaymentDto> {

    return this.paymentsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfPaymentDto>) => r.body as PagedDataDtoOfPaymentDto)
    );
  }

  /**
   * Path part for operation paymentsGet
   */
  static readonly PaymentsGetPath = '/v1/admin/payments/{token}';

  /**
   * Get payment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGet$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<PaymentDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDetailsDto>;
      })
    );
  }

  /**
   * Get payment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGet(params: {
    token: string;
  }): Observable<PaymentDetailsDto> {

    return this.paymentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentDetailsDto>) => r.body as PaymentDetailsDto)
    );
  }

  /**
   * Path part for operation paymentsGetFees
   */
  static readonly PaymentsGetFeesPath = '/v1/admin/payments/{token}/fees';

  /**
   * Get payment's fees.
   *
   * Returns fees for all payment's transactions grouped by categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGetFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetFees$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<TransactionFeesResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGetFeesPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionFeesResult>;
      })
    );
  }

  /**
   * Get payment's fees.
   *
   * Returns fees for all payment's transactions grouped by categories
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGetFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetFees(params: {
    token: string;
  }): Observable<TransactionFeesResult> {

    return this.paymentsGetFees$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionFeesResult>) => r.body as TransactionFeesResult)
    );
  }

  /**
   * Path part for operation transactionsSearchTransactions
   */
  static readonly TransactionsSearchTransactionsPath = '/v1/admin/payments/transactions';

  /**
   * Search transactions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsSearchTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsSearchTransactions$Response(params?: {
    MerchantId?: number;
    FundingStartDate?: string;
    FundingEndDate?: string;
    DBA?: string;
    PaymentMethod?: PaymentMethodType;
    Amount?: number;
    OrderBy?: TransactionOrderBy;
    Token?: string;
    Types?: Array<TransactionType>;
    Description?: string;
    Customer?: string;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    AuthCode?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;

    /**
     * Public 14-digits transaction id
     */
    TransactionId?: string;
    TID?: string;
    MID?: string;
    Statuses?: Array<TransactionStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    IsServiceFee?: boolean;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    meta?: {
[key: string]: string;
};
  }): Observable<StrictHttpResponse<PagedDataDtoOfTransactionDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsSearchTransactionsPath, 'get');
    if (params) {
      rb.query('MerchantId', params.MerchantId, {});
      rb.query('FundingStartDate', params.FundingStartDate, {});
      rb.query('FundingEndDate', params.FundingEndDate, {});
      rb.query('DBA', params.DBA, {});
      rb.query('PaymentMethod', params.PaymentMethod, {});
      rb.query('Amount', params.Amount, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('Token', params.Token, {});
      rb.query('Types', params.Types, {"style":"form","explode":true});
      rb.query('Description', params.Description, {});
      rb.query('Customer', params.Customer, {});
      rb.query('PayerName', params.PayerName, {});
      rb.query('Cardholder', params.Cardholder, {});
      rb.query('AuthCode', params.AuthCode, {});
      rb.query('Last4', params.Last4, {});
      rb.query('First6', params.First6, {});
      rb.query('CardBrands', params.CardBrands, {"style":"form","explode":true});
      rb.query('TransactionId', params.TransactionId, {});
      rb.query('TID', params.TID, {});
      rb.query('MID', params.MID, {});
      rb.query('Statuses', params.Statuses, {"style":"form","explode":true});
      rb.query('Gateways', params.Gateways, {"style":"form","explode":true});
      rb.query('DigitalWallets', params.DigitalWallets, {"style":"form","explode":true});
      rb.query('SettlementStartDate', params.SettlementStartDate, {});
      rb.query('SettlementEndDate', params.SettlementEndDate, {});
      rb.query('IsServiceFee', params.IsServiceFee, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
      rb.query('meta', params.meta, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfTransactionDto>;
      })
    );
  }

  /**
   * Search transactions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsSearchTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsSearchTransactions(params?: {
    MerchantId?: number;
    FundingStartDate?: string;
    FundingEndDate?: string;
    DBA?: string;
    PaymentMethod?: PaymentMethodType;
    Amount?: number;
    OrderBy?: TransactionOrderBy;
    Token?: string;
    Types?: Array<TransactionType>;
    Description?: string;
    Customer?: string;

    /**
     * Cardholder name, Payer name, etc.
     */
    PayerName?: string;
    Cardholder?: string;
    AuthCode?: string;
    Last4?: string;
    First6?: string;
    CardBrands?: Array<CardBrandEnum>;

    /**
     * Public 14-digits transaction id
     */
    TransactionId?: string;
    TID?: string;
    MID?: string;
    Statuses?: Array<TransactionStatus>;
    Gateways?: Array<GatewayEnum>;
    DigitalWallets?: Array<DigitalWalletEnum>;
    SettlementStartDate?: string;
    SettlementEndDate?: string;
    IsServiceFee?: boolean;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    meta?: {
[key: string]: string;
};
  }): Observable<PagedDataDtoOfTransactionDto> {

    return this.transactionsSearchTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfTransactionDto>) => r.body as PagedDataDtoOfTransactionDto)
    );
  }

  /**
   * Path part for operation transactionsGetTransaction
   */
  static readonly TransactionsGetTransactionPath = '/v1/admin/payments/transactions/{token}';

  /**
   * Get transaction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsGetTransaction()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetTransaction$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<TransactionDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsGetTransactionPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionDetailDto>;
      })
    );
  }

  /**
   * Get transaction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsGetTransaction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetTransaction(params: {
    token: string;
  }): Observable<TransactionDetailDto> {

    return this.transactionsGetTransaction$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionDetailDto>) => r.body as TransactionDetailDto)
    );
  }

  /**
   * Path part for operation transactionsGetTransactionFees
   */
  static readonly TransactionsGetTransactionFeesPath = '/v1/admin/payments/transactions/{token}/fees';

  /**
   * Get transaction's fees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsGetTransactionFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetTransactionFees$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<TransactionFeesResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsGetTransactionFeesPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TransactionFeesResult>;
      })
    );
  }

  /**
   * Get transaction's fees.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsGetTransactionFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetTransactionFees(params: {
    token: string;
  }): Observable<TransactionFeesResult> {

    return this.transactionsGetTransactionFees$Response(params).pipe(
      map((r: StrictHttpResponse<TransactionFeesResult>) => r.body as TransactionFeesResult)
    );
  }

  /**
   * Path part for operation transactionsUpdateTransactionInformation
   */
  static readonly TransactionsUpdateTransactionInformationPath = '/v1/admin/payments/transactions/update-data';

  /**
   * Update transactions' data.
   *
   * This method allows to upload a file to update transaction's data such as statuses, funding and settlement info.\
   * It also emits appropriate events. Allowed for production as well.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsUpdateTransactionInformation()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  transactionsUpdateTransactionInformation$Response(params?: {
    body?: {
'uploadedFile'?: Blob | null;
}
  }): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsUpdateTransactionInformationPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * Update transactions' data.
   *
   * This method allows to upload a file to update transaction's data such as statuses, funding and settlement info.\
   * It also emits appropriate events. Allowed for production as well.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsUpdateTransactionInformation$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  transactionsUpdateTransactionInformation(params?: {
    body?: {
'uploadedFile'?: Blob | null;
}
  }): Observable<{
[key: string]: string;
}> {

    return this.transactionsUpdateTransactionInformation$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation transactionsGetReceipt
   */
  static readonly TransactionsGetReceiptPath = '/v1/admin/payments/transactions/{token}/receipt';

  /**
   * Returns a transaction receipt.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionsGetReceipt()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetReceipt$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<GcpStorageResource>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentsService.TransactionsGetReceiptPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GcpStorageResource>;
      })
    );
  }

  /**
   * Returns a transaction receipt.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transactionsGetReceipt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionsGetReceipt(params: {
    token: string;
  }): Observable<GcpStorageResource> {

    return this.transactionsGetReceipt$Response(params).pipe(
      map((r: StrictHttpResponse<GcpStorageResource>) => r.body as GcpStorageResource)
    );
  }

}
