<app-devices-log
    [inventory]="inventory$ | async"
    [inventoryLoading]="inventoryLoading$ | async"
    [inventoryError]="inventoryError$ | async"
    [device]="device$ | async"
    [deviceLoading]="deviceLoading$ | async"
    [merchants]="merchants$ | async"
    [merchantsLoading]="merchantsLoading$ | async"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)"
    [filter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)">
</app-devices-log>
