<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="'id'">
        <lib-text-table-column>
            {{ element.id }}
        </lib-text-table-column>
        <lib-table-column-sort [key]="element.id ?? 0"></lib-table-column-sort>
    </ng-container>
  
    <ng-container *ngSwitchCase="'url'">
        <lib-text-table-column>
            {{ element.url }}
        </lib-text-table-column>
    </ng-container>
  
    <ng-container *ngSwitchCase="'active'">
        <span (mousedown)="stopPropagation($event)" (mouseup)="stopPropagation($event)">
            <lib-vitu-slide-toggle
                [checked]="element.isActive"
                (checkedChange)="isActiveToggled($event)"
                [disabled]="toggleWebhookLoading">
            </lib-vitu-slide-toggle>
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'solution'">
        {{ getDisplayTextForSolution() }}
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>





