import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigationAction } from "@ngrx/router-store";
import { catchError, filter as filterOperator, map, switchMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import {
    ActionTypes,
    FilterAdjustmentsAction,
    GetAdjustmentsAction,
    GetAdjustmentsSucceedAction,
    GetAdjustmentsFailAction,
    PageChangedAdjustmentsAction,
    SortAdjustmentsAction
} from "./adjustments.actions";
import { BillingService } from "@admin_api/services/billing.service";
import { getAdjustmentsState } from "./adjustments.selectors";
import { Filter, Sorting } from "./adjustments.state";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { LocalTimePoint } from "shared-lib";

@Injectable()
export class AdjustmentsEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService,
        private store: Store<IStore>
    ) {}

    initAdjustmentsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigationAction) =>
                new RegExp(/dashboard\/funding\/adjustments$/).test(action.payload.routerState.url)),
            map(() => new GetAdjustmentsAction(1))
        )
    );

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortAdjustmentsAction>(ActionTypes.SortAdjustments),
            switchMap(action => of(new GetAdjustmentsAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterAdjustmentsAction>(ActionTypes.FilterAdjustments),
            switchMap(action => of(new GetAdjustmentsAction(1, undefined, action.filter)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedAdjustmentsAction>(ActionTypes.PageChangedAdjustments),
            switchMap(action => of(new GetAdjustmentsAction(action.page)))
        ),
    );

    getAdjustments = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAdjustmentsAction>(ActionTypes.GetAdjustments),
            withLatestFrom(this.store.select(getAdjustmentsState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.billingService.adjustmentsSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetAdjustmentsSucceedAction(
                            response,
                            pageSize,
                            action.page,
                            stateExtensions)
                        )
                    ),
                    catchError((error) =>
                        of(new GetAdjustmentsFailAction(error))
                    )
                );
            })
        )
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                FundingDepositRef: filter.fundingDepositsRef,
                MerchantIds: filter.merchantIds,
                MID: filter.mid
            },
            StartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.from),
            EndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateInterval?.to),
            AdjustmentStartDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateAdjustedInterval?.from),
            AdjustmentEndDate: LocalTimePoint.convertLocalValueToUtcValue(filter.localDateAdjustedInterval?.to),
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }


}
