<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Fee Plans' , 'link': '/dashboard/fee-plans' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="true then formFields"></ng-container>

    </div>

</div>

<ng-template #formFields>

    <form [formGroup]="feePlanForm" class="form">

        <div class="field-lines">

            <div id="labelMain" class="label">MAIN</div>

            <lib-vitu-form-field-input
                [ngClass]="{'vitu-hide-form-field': isCreate}"
                id="feePlanRef"
                [formGroup]="feePlanForm"
                formFieldName="feePlanRef"
                label="Fee Plan Ref"
                [disabled]="true">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                id="feePlanName"
                [formGroup]="feePlanForm"
                formFieldName="feePlanName"
                label="Fee Plan Name"
                [disabled]="!isCreate">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-input
                id="feePlanDescription"
                [formGroup]="feePlanForm"
                formFieldName="feePlanDescription"
                label="Fee Plan Description"
                [disabled]="!isCreate">
            </lib-vitu-form-field-input>

            <lib-vitu-form-field-select
                *ngIf="isCreate"
                id="processor"
                [formGroup]="feePlanForm"
                formFieldName="processor"
                label="Processor"
                [options]="processors"
                (selectionChanged)="onProcessorChanged()"
                [infoTip]="processorInfoTip">
            </lib-vitu-form-field-select>

        </div>

        <div class="fees-body-container">

            <ng-container *ngIf="feeTypesErr; else noFeeTypesError">
                <div id="typesLabel" class="label">{{ 'ERROR LOADING FEE TYPES' }}</div>
            </ng-container>
            <ng-template #noFeeTypesError>
                <div id="typesLabel" class="label">{{ feeTypesEmpty() ? 'NO FEE TYPES' : 'FEE TYPES' }}</div>
                <div *ngIf="!feeTypesEmpty()" class="fee-types-container" [ngStyle]="{'max-height': feeTypesContainerMaxHeightInPixels + 'px'}">
                    <ng-container *ngIf="feeTypesAreLoading() then spinner"></ng-container>
                    <ng-container *ngIf="feeTypesLoaded() then feeTypesTable"></ng-container>
                </div>
            </ng-template>

            <ng-container *ngIf="!isCreate && !feeTypesAreLoading()">
                <ng-container *ngIf="feePlanMerchantProcessorsError; else noMidsError">
                    <div id="midsLabel" class="label">{{ 'ERROR LOADING ASSOCIATED MIDS' }}</div>
                </ng-container>
                <ng-template #noMidsError>
                    <div id="midsLabel" class="label">{{ feePlanMidsEmpty() ? 'NO ASSOCIATED MIDS' : 'ASSOCIATED MIDS' }}</div>
                    <div *ngIf="!feePlanMidsEmpty()" class="fee-plan-mids-container" [ngStyle]="{'max-height': feePlanMidsContainerMaxHeightInPixels + 'px'}">
                        <ng-container *ngIf="feePlanMidsLoading() then spinner"></ng-container>
                        <ng-container *ngIf="feePlanMidsLoaded() then feePlanMidsTable"></ng-container>
                    </div>
                </ng-template>
            </ng-container>

        </div>

        <div *ngIf="!isCreate" class="actions-line">

            <div class="action-buttons">

                <lib-action-button
                    id="deleteFeePlanButton"
                    class="action-button"
                    label="Delete Fee Plan"
                    fontImage="far fa-trash fa-bg"
                    [kind]="ActionButtonKind.ALERT"
                    [disabled]="feePlanLoading || !feePlan"
                    (click)="onClickDelete()">
                </lib-action-button>

            </div>

        </div>

        <div class="page-complete-line">

            <lib-action-button
                [kind]="ActionButtonKind.STROKED"
                largeButton
                label="Back"
                (click)="onCancel()">
            </lib-action-button>
    
            <lib-action-button
                *ngIf="isCreate"
                largeButton
                [label]="submitButtonText"
                (click)="onSubmit()">
            </lib-action-button>
    
        </div>

    </form>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #feeTypesTable>
    <lib-table-base
        #feeTypesTableComponent
        [columnDefs]="feeTypesColumnDefs"
        [rowsData]="isCreate ? filteredFeeTypes : feePlan.feeTypes">

        <ng-template let-element="element" let-columnId="columnId" let-pageRowIndex="pageRowIndex">
            <app-fee-plan-fee-types-table-cell-content
                [columnId]="columnId"
                [element]="element"
                [isCreate]="isCreate"
                [customFeeTypeValueMap]="customFeeTypeValueMap"
                [validateCustomFeeTypeInput]="validateCustomFeeTypeInput"
                [tableRowIndex]="pageRowIndex">
            </app-fee-plan-fee-types-table-cell-content>
        </ng-template>
    </lib-table-base>
</ng-template>

<ng-template #feePlanMidsTable>
    <lib-table-base
        #feePlanMidsTableComponent
        [columnDefs]="feePlanMidsColumnDefs"
        [rowsData]="feePlanMerchantProcessors"
        [rowsDataError]="feePlanMerchantProcessorsError"
        rowsDataErrorMessage="Error Loading Associated MIDs">

        <ng-template let-element="element" let-columnId="columnId">
            <app-fee-plan-mids-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-fee-plan-mids-table-cell-content>
        </ng-template>
    </lib-table-base>
</ng-template>
