<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Accounts' , 'link': '/dashboard/accounts' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">
        <ng-container *ngIf="!account then spinner"></ng-container>
        <ng-container *ngIf="account then details"></ng-container>
    </div>

</div>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>

<ng-template #details>

    <form class="form" [formGroup]="accountForm" [libVituForm]="accountForm" (libVituFormSubmit)="onSubmit()">

        <div class="form-inner-container">

            <div class="field-lines">

                <lib-vitu-form-field-input
                    [ngClass]="{'vitu-hide-form-field': isCreate}"
                    class="field"
                    id="accountRef"
                    [formGroup]="accountForm"
                    formFieldName="accountRef"
                    label="Account Ref"
                    [disabled]="true">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="name"
                    [formGroup]="accountForm"
                    formFieldName="name"
                    label="Name">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="description"
                    [formGroup]="accountForm"
                    formFieldName="description"
                    label="Description">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="companyName"
                    [formGroup]="accountForm"
                    formFieldName="companyName"
                    label="Company Name">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="streetAddress1"
                    [formGroup]="accountForm"
                    formFieldName="streetAddress1"
                    label="Street Address 1">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="streetAddress2"
                    [formGroup]="accountForm"
                    formFieldName="streetAddress2"
                    label="Street Address 2">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="city"
                    [formGroup]="accountForm"
                    formFieldName="city"
                    label="City">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    class="field"
                    id="state"
                    [formGroup]="accountForm"
                    formFieldName="state"
                    label="State"
                    [options]="states">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-input
                    class="field"
                    id="zipCode"
                    [formGroup]="accountForm"
                    formFieldName="zipCode"
                    label="ZIP Code"
                    type="zip">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="accountNumber"
                    [formGroup]="accountForm"
                    formFieldName="accountNumber"
                    label="Account Number"
                    [errorOverrides]="[{'pattern': 'Up to 14 numeric digits only'}]">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="routingNumber"
                    [formGroup]="accountForm"
                    formFieldName="routingNumber"
                    label="Routing Number"
                    [errorOverrides]="[{'pattern': 'Up to 9 numeric digits only'}]">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    class="field"
                    id="fein"
                    [formGroup]="accountForm"
                    formFieldName="fein"
                    label="FEIN"
                    [errorOverrides]="[{'pattern': 'Up to 9 numeric digits only'}]">
                </lib-vitu-form-field-input>

            </div>

            <ng-container *ngIf="!isCreate">
                <div class="account-mids-outer-container">
                    <ng-container *ngIf="accountMerchantProcessorsError; else noMidsError">
                        <div id="accountMidsLabel" class="label">{{ 'ERROR LOADING ASSOCIATED MIDS' }}</div>
                    </ng-container>
                    <ng-template #noMidsError>
                        <div id="accountMidsLabel" class="label">{{ accountMidsEmpty() ? 'NO ASSOCIATED MIDS' : 'ASSOCIATED MIDS' }}</div>
                        <div *ngIf="!accountMidsEmpty()" class="account-mids-container">
                            <ng-container *ngIf="accountMidsLoading() then spinner"></ng-container>
                            <ng-container *ngIf="accountMidsLoaded() then accountMidsTable"></ng-container>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
 
            <div class="actions-line" *ngIf="!isCreate">

                <div class="action-buttons">

                    <lib-action-button
                        id="deleteAccountButton"
                        class="action-button"
                        label="Delete Account"
                        fontImage="far fa-trash fa-bg"
                        [kind]="ActionButtonKind.ALERT"
                        [disabled]="loading || !account"
                        (click)="onClickDelete()">
                    </lib-action-button>
        
                </div>

            </div>

        </div>

        <lib-vitu-form-buttons>

            <lib-vitu-form-abort-button
                (onAbort)="onClickBack()">
            </lib-vitu-form-abort-button>

            <lib-vitu-form-submit-button
                [label]="submitButtonText"
                [neverDisable]="isCreate">
            </lib-vitu-form-submit-button>

        </lib-vitu-form-buttons>

    </form>

</ng-template>

<ng-template #accountMidsTable>
    <lib-table-base
        [columnDefs]="accountMidsColumnDefs"
        [rowsData]="accountMerchantProcessors"
        [rowsDataError]="accountMerchantProcessorsError"
        rowsDataErrorMessage="Error Loading Associated MIDs">

        <ng-template let-element="element" let-columnId="columnId">
            <app-account-mids-table-cell-content
                [columnId]="columnId"
                [element]="element">
            </app-account-mids-table-cell-content>
        </ng-template>
    </lib-table-base>
</ng-template>
