import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActionButtonKind, ValidatorsVituCustom } from "shared-lib";
import { CurrentUserDto } from "@admin_app/storage/current-user/current-user.state";
import { WebhookEventTypesDto } from "@admin_api/models/webhook-event-types-dto";
import { LookupDto } from "@admin_api/models/lookup-dto";
import { SolutionEnum } from "@admin_api/models/solution-enum";
import { CreateSolutionWebhookDto } from "@admin_api/models/create-solution-webhook-dto";
import { UpdateSolutionWebhookDto } from "@admin_api/models/update-solution-webhook-dto";
import { SolutionWebhookDto } from "@admin_api/models/solution-webhook-dto";
import { ConfigDto } from "@admin_app/storage/config/config.state";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-webhook-details",
    templateUrl: "./webhook-details.component.html",
    styleUrls: ["./webhook-details.component.less"],
})
export class WebhookDetailsComponent implements OnInit, OnChanges {

    @Input() isCreate: boolean;
    @Input() webhook: SolutionWebhookDto;
    @Input() lookups: WebhookEventTypesDto;
    @Input() currentUser: CurrentUserDto;
    @Input() loading: boolean;
    @Input() generatedSecret: string;
    @Input() generatedSecretLoading: boolean;
    @Input() config: ConfigDto;

    @Output() generateSecret = new EventEmitter<void>();
    @Output() deleteWebhook = new EventEmitter<number>();
    @Output() updateWebhook = new EventEmitter<{id: number; webhook: UpdateSolutionWebhookDto}>();
    @Output() createWebhook = new EventEmitter<CreateSolutionWebhookDto>();
    @Output() back = new EventEmitter<void>();

    pageTitle: string;
    submitButtonText: string;
    showSecret = false;
    solutions: Array<any> = [];

    ActionButtonKind = ActionButtonKind;

    constructor(
        private fb: UntypedFormBuilder,
    ) {
        this.solutions = Object.values(SolutionEnum);
    }

    webhookForm = this.fb.group({
        url: [null, [Validators.required, ValidatorsVituCustom.validHttpsUrl]],
        solution: [null, [Validators.required, ValidatorsVituCustom.selectSingleIntegrity(() => this.solutions)]],
        eventTypes: [null as Array<{id: string; name: string; checked: boolean}>],
        isActive: [true],
        secret: [null, [Validators.required]]
    });

    getDisplayTextForSolution(solutionVal: string): string {
        let text = "";
        if (solutionVal && this.config?.solutionLookup) {
            const found = this.config.solutionLookup.filter(item => (item.id === solutionVal));
            if (found?.length) {
                text = found[0].name;
            }
        }
        return text;
    }

    get formControls(): FormControls<any> { return this.webhookForm.controls; }

    onClickGenerateSecret() {
        this.generateSecret.emit();
    }

    onClickToggleShowSecret() {
        this.showSecret = !this.showSecret;
    }

    ngOnInit() {

        this.pageTitle = (this.isCreate ? "New" : "Edit") + " Webhook";
        this.submitButtonText = (this.isCreate ? "Submit" : "Update");

        if (this.isCreate) {
            this.formControls?.secret.disable();
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if ("webhook" in changes && this.webhook) {

            this.webhookForm.patchValue(this.webhook);

            if (!this.isCreate) {
                this.webhookForm.markAllAsTouched();
            }
        }

        if ("lookups" in changes && this.lookups) {

            this.formControls?.eventTypes.patchValue(this.getFormArray());
        }

        if ("generatedSecret" in changes && this.generatedSecret) {

            this.webhookForm.markAsDirty();    // because we're dirtying it manually here (ie. programmatic)
            this.formControls?.secret.patchValue(this.generatedSecret);
        }

        // Workaround needed for form using ValidatorsVituCustom:
        // ValidatorsVituCustom need to be run by forcing validation if anything they depend on changes,
        // for example as a result of the allowed values being retrieved asynchronously from server (& arriving late).
        // (TODO : Consolidate use of Angular Forms in shareable form base class which implements our common form
        // behaviour like this as default for all of our forms (instead of having to apply it in multiple places))
        this.validateFormControls(this.webhookForm);
    }

    private getFormArray(): Array<{id: string; name: string; checked: boolean}> {

        return this.lookups?.eventTypes?.map(i => ({
            id: i.id,
            name: i.name,
            checked: this.isChecked(i, this.webhook)
        }));
    }

    private isChecked(eventType: LookupDto, webhook: SolutionWebhookDto) {

        return webhook?.eventTypes
            ? webhook.eventTypes?.indexOf(eventType.id) >= 0
            : false;
    }

    onClickDelete() {

        this.deleteWebhook.emit(this.webhook.id);
    }

    onClickBack() {

        this.back.emit();
    }

    onSubmit() {

        const webhookForm = this.webhookForm.value;

        const url = webhookForm.url;
        const eventTypes = webhookForm.eventTypes.filter(i => i.checked).map(i => i.id);
        const solution = webhookForm.solution;

        if (this.isCreate) {
            const createWebhookDto: CreateSolutionWebhookDto = {
                url,
                eventTypes,
                solution
            };
            this.createWebhook.emit(createWebhookDto);
        }
        else {
            const secret = webhookForm.secret;
            const isActive = webhookForm.isActive;
            const updateWebhookDto: UpdateSolutionWebhookDto = {
                url,
                eventTypes,
                secret,
                isActive,
                solution
            };
            this.updateWebhook.emit({
                id: this.webhook.id,
                webhook: updateWebhookDto
            });
        }

    }

    private validateFormControls(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup?.controls).forEach(field => {
            formGroup?.get(field)?.updateValueAndValidity();
        });
    }

}
