import { getConfig } from "@admin_app/storage/config/config.selectors";
import { IStore } from "@admin_app/storage/store";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";
import { Filter, Sorting } from "@admin_app/storage/organizations/organizations.state";

@Component({
    selector: "app-organizations",
    templateUrl: "./organizations.component.html",
    styleUrls: ["./organizations.component.less"],
})
export class OrganizationsComponent {

    organizations$ = this.store.select(OrganizationsSelectors.getOrganizations);
    organizationsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);
    organizationsError$ = this.store.select(OrganizationsSelectors.getOrganizationsError);

    pager$ = this.store.select(OrganizationsSelectors.getOrganizationsPager);
    sorting$ = this.store.select(OrganizationsSelectors.getOrganizationsSorting);
    filter$ = this.store.select(OrganizationsSelectors.getOrganizationsFilter);

    config$ = this.store.select(getConfig);

    constructor(private store: Store<IStore>) {}

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new OrganizationsActions.SortOrganizationsAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new OrganizationsActions.PageChangedOrganizationsAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new OrganizationsActions.FilterOrganizationsAction(filter));
    }

}
