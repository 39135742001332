/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DisputeDetailsDto } from '../models/dispute-details-dto';
import { DisputeOrderBy } from '../models/dispute-order-by';
import { DisputeTypeEnum } from '../models/dispute-type-enum';
import { GcpStorageResource } from '../models/gcp-storage-resource';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfDisputeDetailsDto } from '../models/paged-data-dto-of-dispute-details-dto';
import { ProcessorEnum } from '../models/processor-enum';

@Injectable({
  providedIn: 'root',
})
export class DisputesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation disputesSearch
   */
  static readonly DisputesSearchPath = '/v1/admin/disputes';

  /**
   * Lists filtered and paged disputes by query.
   *
   * Use this endpoint to search disputes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disputesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  disputesSearch$Response(params?: {
    MerchantId?: number;
    OrderBy?: DisputeOrderBy;
    CaseNumber?: string;
    Mid?: string;
    Processor?: ProcessorEnum;
    DisputeType?: DisputeTypeEnum;
    Tokens?: Array<string>;
    PaymentToken?: string;
    PublicTransactionId?: string;
    CurrentStatus?: string;
    DisputeAmount?: number;
    StatusDateStartUtc?: string;
    StatusDateEndUtc?: string;
    RaiseDateStartUtc?: string;
    RaiseDateEndUtc?: string;
    DueDateStartUtc?: string;
    DueDateEndUtc?: string;
    DisputeOutcome?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfDisputeDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, DisputesService.DisputesSearchPath, 'get');
    if (params) {
      rb.query('MerchantId', params.MerchantId, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('CaseNumber', params.CaseNumber, {});
      rb.query('Mid', params.Mid, {});
      rb.query('Processor', params.Processor, {});
      rb.query('DisputeType', params.DisputeType, {});
      rb.query('Tokens', params.Tokens, {"style":"form","explode":true});
      rb.query('PaymentToken', params.PaymentToken, {});
      rb.query('PublicTransactionId', params.PublicTransactionId, {});
      rb.query('CurrentStatus', params.CurrentStatus, {});
      rb.query('DisputeAmount', params.DisputeAmount, {});
      rb.query('StatusDateStartUtc', params.StatusDateStartUtc, {});
      rb.query('StatusDateEndUtc', params.StatusDateEndUtc, {});
      rb.query('RaiseDateStartUtc', params.RaiseDateStartUtc, {});
      rb.query('RaiseDateEndUtc', params.RaiseDateEndUtc, {});
      rb.query('DueDateStartUtc', params.DueDateStartUtc, {});
      rb.query('DueDateEndUtc', params.DueDateEndUtc, {});
      rb.query('DisputeOutcome', params.DisputeOutcome, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfDisputeDetailsDto>;
      })
    );
  }

  /**
   * Lists filtered and paged disputes by query.
   *
   * Use this endpoint to search disputes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `disputesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disputesSearch(params?: {
    MerchantId?: number;
    OrderBy?: DisputeOrderBy;
    CaseNumber?: string;
    Mid?: string;
    Processor?: ProcessorEnum;
    DisputeType?: DisputeTypeEnum;
    Tokens?: Array<string>;
    PaymentToken?: string;
    PublicTransactionId?: string;
    CurrentStatus?: string;
    DisputeAmount?: number;
    StatusDateStartUtc?: string;
    StatusDateEndUtc?: string;
    RaiseDateStartUtc?: string;
    RaiseDateEndUtc?: string;
    DueDateStartUtc?: string;
    DueDateEndUtc?: string;
    DisputeOutcome?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfDisputeDetailsDto> {

    return this.disputesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfDisputeDetailsDto>) => r.body as PagedDataDtoOfDisputeDetailsDto)
    );
  }

  /**
   * Path part for operation disputesGet
   */
  static readonly DisputesGetPath = '/v1/admin/disputes/{token}';

  /**
   * Gets detailed information about the dispute by token.
   *
   * Use this endpoint to get the detailed information about the dispute by public token that looks like di_*
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disputesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  disputesGet$Response(params: {

    /**
     * Dispute token
     */
    token: string;
  }): Observable<StrictHttpResponse<DisputeDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, DisputesService.DisputesGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DisputeDetailsDto>;
      })
    );
  }

  /**
   * Gets detailed information about the dispute by token.
   *
   * Use this endpoint to get the detailed information about the dispute by public token that looks like di_*
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `disputesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disputesGet(params: {

    /**
     * Dispute token
     */
    token: string;
  }): Observable<DisputeDetailsDto> {

    return this.disputesGet$Response(params).pipe(
      map((r: StrictHttpResponse<DisputeDetailsDto>) => r.body as DisputeDetailsDto)
    );
  }

  /**
   * Path part for operation disputesReportCsv
   */
  static readonly DisputesReportCsvPath = '/v1/admin/disputes/report-csv';

  /**
   * Get disputes report.
   *
   * Returns disputes report in CSV format. The report contains information about disputes, transactions, merchants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disputesReportCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  disputesReportCsv$Response(params?: {
    MerchantId?: number;
    OrderBy?: DisputeOrderBy;
    CaseNumber?: string;
    Mid?: string;
    Processor?: ProcessorEnum;
    DisputeType?: DisputeTypeEnum;
    Tokens?: Array<string>;
    PaymentToken?: string;
    PublicTransactionId?: string;
    CurrentStatus?: string;
    DisputeAmount?: number;
    StatusDateStartUtc?: string;
    StatusDateEndUtc?: string;
    RaiseDateStartUtc?: string;
    RaiseDateEndUtc?: string;
    DueDateStartUtc?: string;
    DueDateEndUtc?: string;
    DisputeOutcome?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<GcpStorageResource>> {

    const rb = new RequestBuilder(this.rootUrl, DisputesService.DisputesReportCsvPath, 'get');
    if (params) {
      rb.query('MerchantId', params.MerchantId, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('CaseNumber', params.CaseNumber, {});
      rb.query('Mid', params.Mid, {});
      rb.query('Processor', params.Processor, {});
      rb.query('DisputeType', params.DisputeType, {});
      rb.query('Tokens', params.Tokens, {"style":"form","explode":true});
      rb.query('PaymentToken', params.PaymentToken, {});
      rb.query('PublicTransactionId', params.PublicTransactionId, {});
      rb.query('CurrentStatus', params.CurrentStatus, {});
      rb.query('DisputeAmount', params.DisputeAmount, {});
      rb.query('StatusDateStartUtc', params.StatusDateStartUtc, {});
      rb.query('StatusDateEndUtc', params.StatusDateEndUtc, {});
      rb.query('RaiseDateStartUtc', params.RaiseDateStartUtc, {});
      rb.query('RaiseDateEndUtc', params.RaiseDateEndUtc, {});
      rb.query('DueDateStartUtc', params.DueDateStartUtc, {});
      rb.query('DueDateEndUtc', params.DueDateEndUtc, {});
      rb.query('DisputeOutcome', params.DisputeOutcome, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GcpStorageResource>;
      })
    );
  }

  /**
   * Get disputes report.
   *
   * Returns disputes report in CSV format. The report contains information about disputes, transactions, merchants
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `disputesReportCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disputesReportCsv(params?: {
    MerchantId?: number;
    OrderBy?: DisputeOrderBy;
    CaseNumber?: string;
    Mid?: string;
    Processor?: ProcessorEnum;
    DisputeType?: DisputeTypeEnum;
    Tokens?: Array<string>;
    PaymentToken?: string;
    PublicTransactionId?: string;
    CurrentStatus?: string;
    DisputeAmount?: number;
    StatusDateStartUtc?: string;
    StatusDateEndUtc?: string;
    RaiseDateStartUtc?: string;
    RaiseDateEndUtc?: string;
    DueDateStartUtc?: string;
    DueDateEndUtc?: string;
    DisputeOutcome?: string;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<GcpStorageResource> {

    return this.disputesReportCsv$Response(params).pipe(
      map((r: StrictHttpResponse<GcpStorageResource>) => r.body as GcpStorageResource)
    );
  }

}
