import { GetMidsAction, GetMidsMergeAction, ResetMidsAction } from "@admin_app/storage/mids/mids.actions";
import { getMids, getMidsLoading } from "@admin_app/storage/mids/mids.selectors";
import { GetStatementsAction, ResetStatementsAction } from "@admin_app/storage/statements/statements.actions";
import { getStatements, getStatementsLoading } from "@admin_app/storage/statements/statements.selectors";
import { IStore } from "@admin_app/storage/store";
import { ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { MerchantProcessorDto } from "@admin_api/models/merchant-processor-dto";
import { Month } from "@admin_api/models/month";
import { StatementReportItem } from "@admin_api/models/statement-report-item";
import { Subject, Subscription } from "rxjs";
import { ActionButtonKind, LocalTimePoint } from "shared-lib";
import { ModalActionType } from "../modal-action";
import { DownloadReportModalAction, DownloadReportParams } from "./download-report-modal-action";
import { ReportType } from "./report-type";
import { PagerLargeSinglePageSize } from "@admin_app/storage/common";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";

@Component({
    selector: "app-download-report-modal",
    templateUrl: "./download-report-modal.component.html",
    styleUrls: ["./download-report-modal.component.less"]
})
export class DownloadReportModalComponent implements OnInit, OnDestroy {

    get formControls() {
        return this.reportForm && this.reportForm.controls;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<IStore>,
        public dialogRef: MatDialogRef<DownloadReportModalComponent>,
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: {
            reportType: ReportType;
            errorMessageSubject: Subject<string>;
            modalActionSubject: Subject<DownloadReportModalAction>;
        }
    ) {
        this.calculateMaxMonthAndYear();
    }

    ReportType = ReportType;
    LocalTimePoint = LocalTimePoint;

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    merchants: OrganizationDto[] = [];
    merchantsLoading = false;

    mids$ = this.store.select(getMids);
    midsLoading$ = this.store.select(getMidsLoading);

    mids: MerchantProcessorDto[] = [];
    midsLoading = false;

    statements$ = this.store.select(getStatements);
    statementsLoading$ = this.store.select(getStatementsLoading);

    statements: Array<StatementReportItem> = [];
    statementsLoading = false;

    showOnlyMids = false;

    private subscriptions = new Subscription();

    private readonly localTimePoint = new LocalTimePoint();
    private maxMonth: number = null;
    private maxYear: number = null;

    get months(): Array<string> {
        let retVal = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        if (this.hasYear) {
            const year = this.reportForm.get("yearField").value;
            if (year === this.maxYear) {
                retVal = retVal.slice(0, this.maxMonth);
            }
        }
        return retVal;
    }

    get years(): Array<number> {
        let retVal = [];
        const minYear = 2021;
        let yearToAdd = this.maxYear;

        do {
            retVal.push(yearToAdd);
        } while (yearToAdd-- > minYear);

        if (this.hasMonth) {
            const month = this.reportForm.get("monthField").value;
            if (month > this.maxMonth) {
                retVal = retVal.slice(1);
            }
        }
        return retVal;
    }

    ActionButtonKind = ActionButtonKind;

    reportForm = this.fb.group({
        idField: [{value:null, disabled: true}, [Validators.required]],
        dateSingleField: [{value:null, disabled: true}, [Validators.required]],
        dateRangeField: [{value:null, disabled: true}, [Validators.required]],
        merchantField: [{value:null, disabled: true}, [Validators.required]],
        allMidsField: [{value:true, disabled: true}],
        midField: [{value:null, disabled: true}, this.data.reportType === ReportType.RECONCILIATION ? [Validators.required] : []],
        statementField: [{value:null, disabled: true}, [Validators.required]],
        monthField: [{value:null, disabled: true}, [Validators.required]],
        yearField: [{value:null, disabled: true}, [Validators.required]]
    });

    onAllMidsToggled(allMids: boolean) {
        const midField = this.reportForm.get("midField");
        this.reportForm.get("midField").setValue(null);
        if (allMids) {
            midField.disable();
            this.showOnlyMids = false;
            this.reportForm.get("midField").clearValidators();
        }
        else {
            midField.enable();
            this.showOnlyMids = true;
            this.reportForm.get("midField").addValidators(Validators.required);
        }
        this.cd.detectChanges();
    }

    get reportType(): string {
        return (this.data.reportType === ReportType.STATEMENT ? this.data.reportType : this.data.reportType + " Report");
    }

    getFormControl(name: string): UntypedFormControl {
        return this.formControls[name] as UntypedFormControl;
    }

    get optionsForMonths() {
        return this.months.map((month, index) => ({label: month, value: index + 1}));
    }

    get midGroups() {
        // Assumes mids are already in order of merchants
        const groups = [];
        let currentMerc = null;
        let minIdx = null;
        let maxIdx = null;
        let name;

        this.mids.forEach((mid, index) => {
            if (currentMerc === null) {
                // start new group
                currentMerc = mid.merchantId;
                minIdx = index;
            }
            else if (currentMerc !== mid.merchantId) {
                // non-final group => commit group & start new group
                maxIdx = index - 1;
                name = this.getNameForMerchant(currentMerc);
                groups.push({name, minIdx, maxIdx});
                currentMerc = mid.merchantId;
                minIdx = index;
            }
        });

        // final group => commit group
        if (minIdx !== null) {
            maxIdx = this.mids.length - 1;
            name = this.getNameForMerchant(currentMerc);
            groups.push({name, minIdx, maxIdx});
        }

        return JSON.stringify(groups);
    }

    private getNameForMerchant(merchantId: number) {
        const matchedMerchants = this.merchants.filter(merchant => merchant.id === merchantId);
        return (matchedMerchants?.length > 0) ? matchedMerchants[0].name : "";
    }

    ngOnInit() {
        this.enableFormFieldsForReportType();

        this.subscriptions.add(this.merchants$.subscribe((merchants: OrganizationDto[]) => {
            this.merchants = merchants;
        }));
        this.subscriptions.add(this.merchantsLoading$.subscribe((merchantsLoading: boolean) => {
            this.merchantsLoading = merchantsLoading;
        }));

        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));

        switch (this.data.reportType) {
            case ReportType.TRANSACTIONS:
            case ReportType.RECONCILIATION:
            case ReportType.FUNDING: {
                this.subscriptions.add(this.mids$.subscribe((mids: MerchantProcessorDto[]) => {
                    this.mids = this.mids.concat(mids);
                    this.ensureMidsInOrderOfMerchants();
                }));
                this.subscriptions.add(this.midsLoading$.subscribe((midsLoading: boolean) => {
                    this.midsLoading = midsLoading;
                }));
                this.resetMidField();
                break;
            }
            case ReportType.STATEMENT: {
                this.subscriptions.add(this.statements$.subscribe((statements: Array<StatementReportItem>) => {
                    this.statements = statements;
                }));
                this.subscriptions.add(this.statementsLoading$.subscribe((statementsLoading: boolean) => {
                    this.statementsLoading = statementsLoading;
                }));
                this.resetStatementField();
                break;
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onMonthChanged() {
        switch (this.data.reportType) {
            case ReportType.STATEMENT: {
                this.getStatementsIfRequired();
                break;
            }
        }
    }

    onYearChanged() {
        switch (this.data.reportType) {
            case ReportType.STATEMENT: {
                this.getStatementsIfRequired();
                break;
            }
        }
    }

    onMerchantChanged() {
        switch (this.data.reportType) {
            case ReportType.STATEMENT: {
                this.getStatementsIfRequired();
                break;
            }
            default: {
                const merchantId = this.reportForm.get("merchantField").value;
                this.resetMidField();
                this.store.dispatch(new GetMidsAction(merchantId));
                break;
            }
        }
    }

    onMerchantsChanged() {
        const selectedMerchantIds = this.reportForm.get("merchantField").value;

        // Calculate merchants which don't yet have mids loaded
        const merchantsWithoutMids = [];
        selectedMerchantIds.forEach(selectedMerchantId => {
            const matchingMids = this.mids.filter(mid => (mid.merchantId === selectedMerchantId));
            if (!(matchingMids?.length)) {
                merchantsWithoutMids.push(selectedMerchantId);
            }
        });

        // Discard any mids which aren't owned by the selected merchants
        const newMids = [];
        this.mids.forEach(mid => {
            const matchingMerchants = selectedMerchantIds.filter(mercId => mercId === mid.merchantId);
            if (matchingMerchants?.length) {
                newMids.push(mid);
            }
        });
        this.mids = newMids;

        // Ensure the selected MIDs doesn't contain any mids which aren't part of the selected merchants
        const selectedMids = this.reportForm.get("midField").value;
        if (Array.isArray(selectedMids)) {
            const newSelectedMids = [];
            selectedMids.forEach(selectedMid => {
                if (this.mids.filter(mid => mid.id === selectedMid)?.length > 0) {
                    newSelectedMids.push(selectedMid);
                }
            });
            this.reportForm.get("midField").setValue(newSelectedMids);
        }

        // Ensure the mdis are in the correct order
        this.ensureMidsInOrderOfMerchants();

        // Request required mids which aren't already loaded
        merchantsWithoutMids.forEach(merchantWithoutMid => {
            this.store.dispatch(new GetMidsMergeAction(merchantWithoutMid));
        });
    }

    private ensureMidsInOrderOfMerchants(): void {
        let newMids = [];
        if (Array.isArray(this.merchants)) {
            this.merchants.forEach(merchant => {
                const midsForMerchant = this.mids.filter(mid => mid.merchantId === merchant.id);
                newMids = newMids.concat(midsForMerchant);
            });
        }
        this.mids = newMids;
    }

    get hasMerchant(): boolean {
        const merchantField = this.reportForm.get("merchantField");
        return (merchantField && (merchantField.value !== null));
    }

    get hasMonth(): boolean {
        const monthField = this.reportForm.get("monthField");
        return (monthField && (monthField.value !== null));
    }

    get hasYear(): boolean {
        const yearField = this.reportForm.get("yearField");
        return (yearField && (yearField.value !== null));
    }

    get noStatements(): boolean {
        return (!this.statementsLoading) && !(this.statements?.length);
    }

    get noMids(): boolean {
        return (!this.midsLoading) && !(this.mids?.length);
    }

    onMouseDown() {
        this.data.errorMessageSubject.next("");
    }

    onCancel(event?: any) {
        if (event) {
            event.preventDefault();
        }
        this.data.modalActionSubject.next(new DownloadReportModalAction(ModalActionType.CANCEL));
    }

    onSubmit() {

        const params: DownloadReportParams = {};

        switch (this.data.reportType) {
            case ReportType.TRANSACTIONS:
            case ReportType.FUNDING: {
                params.merchantId = this.getSubmitValueForField("merchantField");
                params.merchantProcessorIds = this.getSubmitValueForField("midField");
                const dateRange = this.getSubmitValueForField("dateRangeField");
                if (dateRange) {
                    params.startDate = dateRange.from;
                    params.endDate = dateRange.to;
                }
                params.requiresMids = this.noMids;
                break;
            }
            case ReportType.STATEMENT: {
                params.merchantId = this.getSubmitValueForField("merchantField");
                params.statementId = this.getSubmitValueForField("statementField");
                break;
            }
            case ReportType.RECONCILIATION: {
                params.merchantId = this.getSubmitValueForField("merchantField");
                params.merchantProcessorIds = this.getSubmitValueForField("midField");
                const dateSingle = this.getSubmitValueForField("dateSingleField");
                if (dateSingle) {
                    params.startDate = dateSingle.from;
                    params.endDate = dateSingle.to;
                }
                params.requiresMids = this.noMids;
                break;
            }
        }

        this.data.modalActionSubject.next(new DownloadReportModalAction(ModalActionType.CONFIRM, params));
    }

    private getSubmitValueForField(fieldName: string): any {
        const fieldValue = this.reportForm.get(fieldName).value;
        return (fieldValue ? fieldValue : undefined);
    }

    private enableFormFieldsForReportType() {
        switch (this.data.reportType) {
            case ReportType.TRANSACTIONS: {
                this.reportForm.get("dateRangeField").enable();
                this.reportForm.get("merchantField").enable();
                this.reportForm.get("allMidsField").enable();
                break;
            }
            case ReportType.FUNDING: {
                this.reportForm.get("dateRangeField").enable();
                this.reportForm.get("merchantField").enable();
                this.reportForm.get("allMidsField").enable();
                break;
            }
            case ReportType.STATEMENT: {
                this.reportForm.get("merchantField").enable();
                this.reportForm.get("monthField").enable();
                this.reportForm.get("yearField").enable();
                this.reportForm.get("statementField").enable();
                break;
            }
            case ReportType.RECONCILIATION: {
                this.reportForm.get("dateSingleField").enable();
                this.reportForm.get("merchantField").enable();
                this.reportForm.get("midField").enable();
                break;
            }
        }
    }

    private resetStatementField(): void {
        this.reportForm.get("statementField").setValue(null);   // Workaround Angular issue
        this.store.dispatch(new ResetStatementsAction());
    }

    private resetMidField(): void {
        this.mids = [];
        this.reportForm.get("midField").setValue(null);         // Workaround Angular issue
        this.store.dispatch(new ResetMidsAction());
    }

    private calculateMaxMonthAndYear(): void {
        const date = this.localTimePoint.asNgbDate;
        let month = date.month;
        let year = date.year;
        // manual calculation of previous month => accurate
        month -= 1;
        if (month <= 0) {
            month = 12;
            year -= 1;
        }
        this.maxMonth = month;
        this.maxYear = year;
    }

    private getStatementsIfRequired(): void {
        if (this.hasMerchant && this.hasMonth && this.hasYear) {
            this.resetStatementField();
            const merchantId = this.reportForm.get("merchantField").value;
            const month = this.getMonthForMonthVal(this.reportForm.get("monthField").value);
            const year = this.reportForm.get("yearField").value;
            this.store.dispatch(new GetStatementsAction(merchantId, month as Month, year));
        }
    }

    private getMonthForMonthVal(monthVal: number): string {
        const months = Array.from({length: 12}, (_item, i) => new Date(0, i).toLocaleString("en-US", {month: "long"}));
        return months[monthVal - 1];
    }

}
