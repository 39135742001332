<lib-filters-base
    #filtersBase
    [filterForm]="filterForm"
    (filterChanged)="onFilterChanged($event)"
    [filter]="filter"
    suppressInitialRequest
    shortLayout>

    <div [formGroup]="filterForm">  <!-- required -->

        <ng-template #basicFilters>

            <div class="global-form-line-short-layout">
                
                <div class="form-field-container">
                    <lib-vitu-form-field-select
                        [formGroup]="filterForm"
                        formFieldName="solution"
                        isFilter
                        label="Solution"
                        [options]="solutions"
                        [optionLabelFn]="getDisplayTextForSolution.bind(this)"
                        [showReset]="filtersBase.resetFieldVisible('solution')"
                        (handleReset)="filtersBase.resetField('solution')">
                    </lib-vitu-form-field-select>
                </div>

                <div class="form-field-container">
                    <lib-vitu-form-field-input
                        [formGroup]="filterForm"
                        formFieldName="mid"
                        label="MID"
                        isFilter
                        autocomplete="off"
                        [maxlength]="100"
                        [showReset]="filtersBase.resetFieldVisible('mid')"
                        (handleReset)="filtersBase.resetField('mid')">
                    </lib-vitu-form-field-input>
                </div>

            </div>

        </ng-template>

    </div>

</lib-filters-base>
