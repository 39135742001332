import { AdjustmentDto } from "@admin_api/models/adjustment-dto";
import { AdjustmentOrderBy } from "@admin_api/models/adjustment-order-by";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { createFilter, DateRange, FilterBase, getInitialLocalFilterDateRangeLastTwoWeeks,
    getInitialLocalFilterDateRangeToEndOfNextMonth, Pager } from "shared-lib";

export class Sorting {
    orderBy: null | AdjustmentOrderBy;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
//    localDateInterval: DateRange = undefined;
//    localDateAdjustedInterval: DateRange = getInitialLocalFilterDateRangeToEndOfNextMonth();
    localDateInterval: DateRange = getInitialLocalFilterDateRangeLastTwoWeeks();
    localDateAdjustedInterval: DateRange = undefined;

    fundingDepositsRef: string = undefined;
    merchantIds: Array<number> = undefined;
    mid: string = undefined;
}

export class AdjustmentsState {
    loading = false;
    error: Error = null;
    record: Array<AdjustmentDto> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
