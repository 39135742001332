import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges,
    Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActionButtonKind, ColumnDef, TableBaseComponent, LocalTimePoint } from "shared-lib";
import { FeePlanDetailModel } from "@admin_api/models/fee-plan-detail-model";
import { ProcessorEnum } from "@admin_api/models/processor-enum";
import { FeeTypeModel } from "@admin_api/models/fee-type-model";
import { BoundMerchantProcessorsResult } from "@admin_api/models/bound-merchant-processors-result";
import { FeeTypeValue } from "@admin_api/models/fee-type-value";
import { EditableFeeTypeModel } from "./editable-fee-plan-model";
import {
    FeePlanFeeTypesTableCellContentComponent
} from "./fee-plan-fee-types-table-cell-content/fee-plan-fee-types-table-cell-content.component";
import { FeePlanData } from "@admin_api/models/fee-plan-data";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-fee-plan-details",
    templateUrl: "./fee-plan-details.component.html",
    styleUrls: ["./fee-plan-details.component.less"],
})
export class FeePlanDetailsComponent implements OnChanges, AfterContentChecked {

    @Input() isCreate: boolean;

    @Input() feePlan: FeePlanDetailModel;
    @Input() feePlanLoading = false;
    @Input() feePlanError: Error;

    @Input() feePlanMerchantProcessors: Array<BoundMerchantProcessorsResult>;
    @Input() feePlanMerchantProcessorsLoading = false;
    @Input() feePlanMerchantProcessorsError: Error;

    private readonly allProcessors = "ALL";

    @ViewChildren(FeePlanFeeTypesTableCellContentComponent) feeTypeTableCells: QueryList<FeePlanFeeTypesTableCellContentComponent>;

    @Input() set feeTypes(value: Array<FeeTypeModel>) {
        this.editableFeeTypes = [];
        value.forEach(feeType => {
            if (feeType.isEnabled) {
                this.editableFeeTypes.push(
                    {
                        ...feeType,
                        isAbsorbed: false,
                        tableRowIndex: undefined
                    }
                );
                this.customFeeTypeValueMap[feeType.id] = feeType.valueNum;
            }
        });
        this.recalcFilterFeeTypes();
    }

    editableFeeTypes: Array<EditableFeeTypeModel> = [];
    filteredFeeTypes: Array<EditableFeeTypeModel> = [];

    recalcFilterFeeTypes() {
        let feeTypes = this.editableFeeTypes;
        const processor = this.feePlanForm?.get("processor")?.value;
        if (processor && processor !== this.allProcessors) {
            feeTypes = feeTypes?.filter(feeType => feeType.processor === processor);
        }
        this.filteredFeeTypes = feeTypes;
    }

    onProcessorChanged() {
        this.recalcFilterFeeTypes();
    }

    get processorInfoTip() {
        return `Only allow inclusion of Fee Types which use these processors`;
    }

    @Input() feeTypesLoading = false;
    @Input() feeTypesError: Error;

    @Output() deleteFeePlan = new EventEmitter<number>();
    @Output() updateFeePlan = new EventEmitter<any>();
    @Output() createFeePlan = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<void>();

    customFeeTypeValueMap = {};

    replacedUrl: string;

    uneditedFormConfig: any = {};

    processors: Array<any> = [];

    pageTitle: string;
    submitButtonText: string;

    ActionButtonKind = ActionButtonKind;

    feeTypesColumnDefs: ColumnDef[];

    rowIndexOfFirstInvalidCustomFeeType: number;

    feeTypesContainerMaxHeightInPixels: number;
    feePlanMidsContainerMaxHeightInPixels: number;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    feePlanMidsColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "mid", title: "MID", flexWidthBasisInPixels: 80, flexWidthGrow: 1, canSort: true },
        { id: "merchantName", title: "Merchant Name", flexWidthBasisInPixels: 80, flexWidthGrow: 1, canSort: true},
        { id: "feePlanAssociationDate",
            title: `Association Date (${LocalTimePoint.formatZ()})`, flexWidthBasisInPixels: 140, flexWidthGrow: 1, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    @ViewChild("feeTypesTableComponent") feeTypesTableComponent: TableBaseComponent;
    @ViewChild("feePlanMidsTableComponent") feePlanMidsTableComponent: TableBaseComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef
    ) {
        this.processors = [this.allProcessors].concat(Object.values(ProcessorEnum));
    }

    private feePlanFormConfig: FormConfig<any> = {
        feePlanRef: [null, [Validators.required]],
        feePlanName: [null, [Validators.required]],
        feePlanDescription: [null, []],
        processor: [null, []]
    };

    feePlanForm = this.fb.group(this.feePlanFormConfig);
    get formControls(): FormControls<any> { return this.feePlanForm.controls; }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.isCreate && changes.isCreate.firstChange) {
            this.pageTitle = (this.isCreate ? "New" : "View") + " Fee Plan";
            this.submitButtonText = (this.isCreate ? "Submit" : "Update");

            // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
            this.feeTypesColumnDefs = [
                { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
                { id: "name", title: "Fee Type", flexWidthBasisInPixels: 240, flexWidthGrow: 2, canSort: true },
                { id: "valueNum", title: "Value", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true },
                { id: "category", title: "Category", flexWidthBasisInPixels: 100, flexWidthGrow: 4, canSort: true},
                { id: "trigger", title: "Trigger", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
                { id: "processor", title: "Processor", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
                { id: "feeCollector", title: "Collected By", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: true},
                { id: "isAbsorbed", title: "Absorbed By Vitu", flexWidthBasisInPixels: 80, flexWidthGrow: 4, canSort: !this.isCreate},
                { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            ];

            if (this.isCreate) {
                const isEnabledCol = { id: "isEnabled", title: "Enabled", flexWidthBasisInPixels: 80, flexWidthGrow: 1};
                this.feeTypesColumnDefs.splice(8, 0, isEnabledCol);
            }
        }

        if ("feePlan" in changes && this.feePlan) {

            this.uneditedFormConfig = {
                feePlanRef: this.feePlan.id,
                feePlanName: this.feePlan.name,
                feePlanDescription: this.feePlan.description,
                processor: this.allProcessors
            };

            this.feePlanForm.patchValue(this.uneditedFormConfig);
            if (!this.isCreate) {
                this.feePlanForm.markAllAsTouched();
            }
        }

        this.cd.detectChanges();
    }

    ngAfterContentChecked() {
        if (this.feeTypesTableComponent) {
            this.feeTypesContainerMaxHeightInPixels = this.feeTypesTableComponent.minRequiredHeightInPixels;
        }
        if (this.feePlanMidsTableComponent) {
            this.feePlanMidsContainerMaxHeightInPixels = this.feePlanMidsTableComponent.minRequiredHeightInPixels;
        }
    }

    get customFeeTypeValuesValid(): boolean {
        let valid = true;

        for (const feeType of this.filteredFeeTypes) {
            if (feeType.isEnabled) {
                if (feeType.isEditable && (this.customFeeTypeValueMap[feeType.id] === null)) {
                    this.rowIndexOfFirstInvalidCustomFeeType = feeType.tableRowIndex;
                    valid = false;
                    break;
                }
            }
        }

        return valid;
    }

    onClickDelete() {
        this.deleteFeePlan.emit(this.feePlan.id);
    }

    isSubmitInvalid(): boolean {
        const formInvalid = this.feePlanForm.invalid;
        const feeTypesInvalid = this.isCreate && !this.customFeeTypeValuesValid;
        return (formInvalid || feeTypesInvalid);
    }

    onSubmit() {

        this.rowIndexOfFirstInvalidCustomFeeType = -1;
        if (this.isSubmitInvalid()) {
            this.showInvalidFieldsOnForm();
            return;
        }

        const name = this.feePlanForm.get("feePlanName").value;
        const description = this.feePlanForm.get("feePlanDescription").value;
        const feeTypeValues: Array<FeeTypeValue> = [];

        for (const feeType of this.filteredFeeTypes) {
            if (feeType.isEnabled) {
                feeTypeValues.push(
                    {
                        id: feeType.id,
                        value: this.customFeeTypeValueMap[feeType.id],
                        isAbsorbed: feeType.isAbsorbed
                    });
            }
        }

        const feePlan: FeePlanData = {
            name,
            description
        };

        this.createFeePlan.emit({ feePlan, feeTypeValues });

    }

    onCancel(): void {
        this.cancel.emit();
    }

    feeTypesAreLoading() {
        if (this.isCreate) {
            return this.feeTypesLoading;
        }
        else {
            return this.feePlanLoading;
        }
    }

    feeTypesEmpty() {
        if (this.isCreate) {
            return !this.feeTypesLoading && !this.filteredFeeTypes?.length;
        }
        else {
            return this.feePlan && !this.feePlan.feeTypes?.length;
        }
    }

    feeTypesLoaded() {
        if (this.isCreate) {
            return !this.feeTypesLoading && this.filteredFeeTypes?.length;
        }
        else {
            return this.feePlan && this.feePlan.feeTypes?.length;
        }
    }

    get feeTypesErr() {
        if (this.isCreate) {
            return this.feeTypesError;
        }
        else {
            return this.feePlanError;
        }
    }

    feePlanMidsLoading() {
        return this.feePlanMerchantProcessorsLoading;
    }

    feePlanMidsEmpty() {
        return !this.feePlanMerchantProcessorsLoading && !this.feePlanMerchantProcessors.length;
    }

    feePlanMidsLoaded() {
        return !this.feePlanMerchantProcessorsLoading && this.feePlanMerchantProcessors.length;
    }

    validateCustomFeeTypeInput(touched: boolean, element: any, customFeeTypeValueMap): boolean {
        return (!touched || !element.isEnabled || !(element.isEditable && (customFeeTypeValueMap[element.id] === null)));
    }

    private showInvalidFieldsOnForm() {
        this.feeTypeTableCells.forEach(feeTypeTableCell => {
            feeTypeTableCell.inputTouched = true;
        });
        this.feePlanForm.markAllAsTouched();
        this.feePlanForm.updateValueAndValidity();

        if (this.rowIndexOfFirstInvalidCustomFeeType >= 0) {
            if (this.feeTypesTableComponent) {
                this.feeTypesTableComponent.scrollRowIntoViewIfNecessary(this.rowIndexOfFirstInvalidCustomFeeType);
            }
        }
    }

}
