import { IStore } from "@admin_app/storage/store";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Filter, Sorting } from "@admin_app/storage/funding/funding.state";

import * as FundingActions from "@admin_app/storage/funding/funding.actions";
import * as FundingSelectors from "@admin_app/storage/funding/funding.selectors";

import * as OrganizationsActions from "@admin_app/storage/organizations/organizations.actions";
import * as OrganizationsSelectors from "@admin_app/storage/organizations/organizations.selectors";
import { PagerLargeSinglePageSize } from "@admin_app/storage/common";

@Component({
    selector: "app-funding",
    templateUrl: "./funding.component.html",
    styleUrls: ["./funding.component.less"],
})
export class FundingComponent implements OnInit {

    funding$ = this.store.select(FundingSelectors.getFunding);
    fundingLoading$ = this.store.select(FundingSelectors.getFundingLoading);
    fundingError$ = this.store.select(FundingSelectors.getFundingError);

    pager$ = this.store.select(FundingSelectors.getFundingPager);
    sorting$ = this.store.select(FundingSelectors.getFundingSorting);
    filter$ = this.store.select(FundingSelectors.getFundingFilter);

    merchants$ = this.store.select(OrganizationsSelectors.getOrganizations);
    merchantsLoading$ = this.store.select(OrganizationsSelectors.getOrganizationsLoading);

    constructor(private store: Store<IStore>) {}

    ngOnInit() {
        this.store.dispatch(new OrganizationsActions.ResetOrganizationsAction(null, null, true));

        // AC_todo : Using a single large page (up to 100 items here)
        // because we don't have pageable select dropdown widget.
        // => better solution would be to create a pageable select dropdown widget
        // (eg. with 'More...' item) and then use paging of default page size.
        this.store.dispatch(new OrganizationsActions.GetOrganizationsAction(1, PagerLargeSinglePageSize));
    }

    onSortingChanged(sorting: Sorting) {
        this.store.dispatch(new FundingActions.SortFundingAction(sorting));
    }

    onPageChanged(page: number) {
        this.store.dispatch(new FundingActions.PageChangedFundingAction(page));
    }

    onFilterChanged(filter: Filter) {
        this.store.dispatch(new FundingActions.FilterFundingAction(filter));
    }

}
