import { ActionTypes } from "./config.actions";
import { ConfigState } from "./config.state";

export const ConfigReducer = (
    state: ConfigState = new ConfigState(),
    action: any,
): ConfigState => {

    switch (action.type) {

        case ActionTypes.GetConfig: {

            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetConfigSucceed: {

            return {
                ...state,
                loading: false,
                record: action.record,
                error: null
            };
        }

        case ActionTypes.GetConfigFail: {

            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        default:
            return state;
    }
};
