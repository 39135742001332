import { OrganizationDto } from "@admin_api/models/organization-dto";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { createFilter, FilterBase, Pager } from "shared-lib";
import { OrganizationOrderBy } from "@admin_api/models/organization-order-by";
import { SolutionEnum } from "@admin_api/models/solution-enum";
import { OrganizationHierarchyModeEnum } from "@admin_api/models/organization-hierarchy-mode-enum";

export class Sorting {
    orderBy: null | OrganizationOrderBy;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)
    solution?: SolutionEnum = undefined;
    mid?: string = undefined;
    hierarchyMode?: OrganizationHierarchyModeEnum = undefined;
    hierarchyOrganizationId?: number = undefined;
    merchantsOnly?: boolean = undefined;
}

export class OrganizationsState {
    loading = false;
    error: Error = null;
    record: Array<OrganizationDto> = [];
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
