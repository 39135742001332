import { AdjustmentDto } from "@admin_api/models/adjustment-dto";
import { FundingFee } from "@admin_api/models/funding-fee";
import { FundingResultDetails } from "@admin_api/models/funding-result-details";
import { PagedDataDtoOfFundingTransaction } from "@admin_api/models/paged-data-dto-of-funding-transaction";
import { StrictHttpResponse } from "@admin_api/strict-http-response";
import { Action } from "@ngrx/store";
import { TransactionsFilter, TransactionsSorting } from "./funding-item.state";

export enum ActionTypes {
    ResetFundingItem = "[FUNDING_ITEM] ResetFundingItem",
    GetFundingItem = "[FUNDING_ITEM] GetFundingItem",
    GetFundingItemSucceed = "[FUNDING_ITEM] GetFundingItemSucceed",
    GetFundingItemFail = "[FUNDING_ITEM] GetFundingItemFail",
    ResendFunding = "[FUNDING_ITEM] ResendFunding",
    RequestSettlement = "[FUNDING_ITEM] RequestSettlement",
    GetFundingItemTransactions = "[FUNDING_ITEM] GetFundingItemTransactions",
    GetFundingItemTransactionsSucceed = "[FUNDING_ITEM] GetFundingItemTransactionsSucceed",
    GetFundingItemTransactionsFail = "[FUNDING_ITEM] GetFundingItemTransactionsFail",
    PageChangedFundingItemTransactions="[FUNDING_ITEM] PageChangedFundingItemTransactions",
    SortFundingItemTransactions="[FUNDING_ITEM] SortFundingItemTransactions",
    FilterFundingItemTransactions="[FUNDING_ITEM] FilterFundingItemTransactions",
    ExportTransactionsToCsv="[FUNDING_ITEM] ExportTransactionsToCsv",
    ExportTransactionsToCsvSucceed="[FUNDING_ITEM] ExportTransactionsToCsvSucceed",
    ExportTransactionsToCsvFail="[FUNDING_ITEM] ExportTransactionsToCsvFail",
    GetFundingItemMidFees = "[FUNDING_ITEM] GetFundingItemMidFees",
    GetFundingItemMidFeesSucceed = "[FUNDING_ITEM] GetFundingItemMidFeesSucceed",
    GetFundingItemMidFeesFail = "[FUNDING_ITEM] GetFundingItemMidFeesFail",
    GetFundingItemAdjustments = "[FUNDING_ITEM] GetFundingItemAdjustments",
    GetFundingItemAdjustmentsSucceed = "[FUNDING_ITEM] GetFundingItemAdjustmentsSucceed",
    GetFundingItemAdjustmentsFail = "[FUNDING_ITEM] GetFundingItemAdjustmentsFail",
}

export class ResetFundingItemAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetFundingItem;
}

export class GetFundingItemAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.GetFundingItem;
}

export class GetFundingItemSucceedAction implements Action {

    constructor(
        public fundingItem: FundingResultDetails) {}

    readonly type = ActionTypes.GetFundingItemSucceed;
}

export class GetFundingItemFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetFundingItemFail;
}

export class ResendFundingAction implements Action {

    constructor(public event: FundingResultDetails) {}

    readonly type = ActionTypes.ResendFunding;
}

export class RequestSettlementAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.RequestSettlement;

}

export class PageChangedFundingItemTransactionsAction implements Action {

    constructor(public id: number, public page: number) {}

    readonly type = ActionTypes.PageChangedFundingItemTransactions;
}

export class SortFundingItemTransactionsAction implements Action {

    constructor(public id: number, public sorting: TransactionsSorting) {}

    readonly type = ActionTypes.SortFundingItemTransactions;
}

export class FilterFundingItemTransactionsAction implements Action {

    constructor(public id: number, public filter: TransactionsFilter) {}

    readonly type = ActionTypes.FilterFundingItemTransactions;
}

export class GetFundingItemTransactionsAction implements Action {

    constructor(
        public id: number,
        public page: number,
        public pageSize?: number,
        public sorting?: TransactionsSorting,
        public filter?: TransactionsFilter,
        ) {}

    readonly type = ActionTypes.GetFundingItemTransactions;
}

export class GetFundingItemTransactionsSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfFundingTransaction,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetFundingItemTransactionsSucceed;
}

export class GetFundingItemTransactionsFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetFundingItemTransactionsFail;
}

export class ExportTransactionsToCsvAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.ExportTransactionsToCsv;
}

export class ExportTransactionsToCsvSucceedAction implements Action {

    constructor(public response: StrictHttpResponse<Blob>) {}

    readonly type = ActionTypes.ExportTransactionsToCsvSucceed;
}

export class ExportTransactionsToCsvFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.ExportTransactionsToCsvFail;
}

export class GetFundingItemMidFeesAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.GetFundingItemMidFees;
}

export class GetFundingItemMidFeesSucceedAction implements Action {

    constructor(public response: Array<FundingFee>) {}

    readonly type = ActionTypes.GetFundingItemMidFeesSucceed;
}

export class GetFundingItemMidFeesFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetFundingItemMidFeesFail;
}

export class GetFundingItemAdjustmentsAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.GetFundingItemAdjustments;
}

export class GetFundingItemAdjustmentsSucceedAction implements Action {

    constructor(public response: Array<AdjustmentDto>) {}

    readonly type = ActionTypes.GetFundingItemAdjustmentsSucceed;
}

export class GetFundingItemAdjustmentsFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetFundingItemAdjustmentsFail;
}
