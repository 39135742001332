<app-paypal-events-log
    [events]="events$ | async"
    [loading]="loading$ | async"
    [error]="error$ | async"
    [sorting]="sorting$ | async"
    (sortingChanged)="onSortingChanged($event)"
    [pager]="pager$ | async"
    (pageChanged)="onPageChanged($event)"
    [filter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)">
</app-paypal-events-log>
