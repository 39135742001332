import { PagedDataDtoOfPayPalEventDeliveryDto } from "@admin_api/models/paged-data-dto-of-pay-pal-event-delivery-dto";
import { Action } from "@ngrx/store";
import { Sorting, Filter } from "./paypal-events.state";

export enum ActionTypes {
    FilterPayPalEvents = "[PayPalEvents] FilterPayPalEvents",
    SortPayPalEvents = "[PayPalEvents] SortPayPalEvents",
    PageChangedPayPalEvents = "[PayPalEvents] PageChangedPayPalEvents",
    GetPayPalEvents = "[PayPalEvents] GetPayPalEvents",
    GetPayPalEventsSucceed = "[PayPalEvents] GetPayPalEventsSucceed",
    GetPayPalEventsFail = "[PayPalEvents] GetPayPalEventsFail"
}

export class FilterPayPalEventsAction implements Action {

    constructor(public filter: Filter) {}

    readonly type = ActionTypes.FilterPayPalEvents;
}

export class SortPayPalEventsAction implements Action {

    constructor(public sorting: Sorting) {}

    readonly type = ActionTypes.SortPayPalEvents;
}

export class PageChangedPayPalEventsAction implements Action {

    constructor(public page: number) {}

    readonly type = ActionTypes.PageChangedPayPalEvents;
}

export class GetPayPalEventsAction implements Action {

    constructor(public page: number, public filter?: Filter, public sorting?: Sorting) {}

    readonly type = ActionTypes.GetPayPalEvents;
}

export class GetPayPalEventsSucceedAction implements Action {

    constructor(
        public response: PagedDataDtoOfPayPalEventDeliveryDto,
        public pageSize: number,
        public page: number,
        public stateExtensions?: any) {}

    readonly type = ActionTypes.GetPayPalEventsSucceed;
}

export class GetPayPalEventsFailAction implements Action {

    constructor(public error: Error) {}

    readonly type = ActionTypes.GetPayPalEventsFail;
}
