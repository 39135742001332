<app-paypal-sftp-log
    [files]="files$ | async"
    [filesLoading]="filesLoading$ | async"
    [filesError]="filesError$ | async"
    [filesSorting]="filesSorting$ | async"
    (filesSortingChanged)="onSortingChanged($event)"
    [filesFilter]="filesFilter$ | async"
    (filesFilterChanged)="onFilterChanged($event)"
    [filesPager]="filesPager$ | async"
    (filesPageChanged)="onPageChanged($event)"
    (reprocessFile)="onReprocessFile($event)"
    (downloadFile)="onDownloadFile($event)">
</app-paypal-sftp-log>
