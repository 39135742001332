import { DigitalWalletCertificateStatusEnum } from "@admin_api/models/digital-wallet-certificate-status-enum";
import { DigitalWalletKeysDto } from "@admin_api/models/digital-wallet-keys-dto";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnDef, LocalTimePoint } from "shared-lib";
import { ActionButtonKind } from "shared-lib";

@Component({
    selector: "app-google-pay-detail",
    templateUrl: "./google-pay-detail.component.html",
    styleUrls: ["./google-pay-detail.component.less"],
})
export class GooglePayDetailComponent {

    @Input() googlePayKeys: DigitalWalletKeysDto;
    @Input() googlePayKeysLoading: boolean;
    @Input() googlePayKeysError: Error;

    @Output() addNew = new EventEmitter<string>();
    @Output() revoke = new EventEmitter<string>();
    @Output() register = new EventEmitter<string>();
    @Output() download = new EventEmitter<string>();
    @Output() getPublicKeyHash = new EventEmitter<string>();
    @Output() makePrimary = new EventEmitter<string>();

    ActionButtonKind = ActionButtonKind;

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    publicKeyColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
        { id: "token", title: "Token", flexWidthBasisInPixels: 120, flexWidthGrow: 1, canSort: true },
        { id: "dateCreated", title: `Date Created (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true },
        { id: "dateExpired", title: `Date Expired (${LocalTimePoint.formatZ()})`,
            flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true },
        { id: "status", title: "Status", flexWidthBasisInPixels: 110, flexWidthGrow: 1, canSort: true},
        { id: "order", title: "Order", flexWidthBasisInPixels: 80, flexWidthGrow: 1, canSort: true },
        { id: "actions", title: "Actions", flexWidthBasisInPixels: 450, flexWidthGrow: 1 },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0 },
    ];

}
