<ng-container [ngSwitch]="columnId">

    <ng-container *ngSwitchCase="PosTerminalOrderBy.DeviceSerial">
        <lib-text-table-column>
            {{ element.posConfig?.deviceSerial }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PosTerminalOrderBy.DeviceName">
        <lib-text-table-column>
            {{ element.posConfig?.deviceName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PosTerminalOrderBy.DeviceManufacturer">
        <lib-text-table-column>
            {{ element.posConfig?.deviceManufacturer }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PosTerminalOrderBy.DeviceModel">
        <lib-text-table-column>
            {{ element.posConfig?.deviceModel }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PosTerminalOrderBy.TID">
        <lib-text-table-column [link]="'/dashboard/organizations/' + element.merchantId + '/tids/' + element.id">
            {{ element.tid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PosTerminalOrderBy.MID">
        <lib-text-table-column [link]="'/dashboard/organizations/' + element.merchantId + '/mids/' + element.merchantProcessorId">
            {{ element.mid }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'gateway'">
        <lib-text-table-column>
            {{ element.gateway }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="'processor'">
        <lib-text-table-column>
            {{ element.processor }}
        </lib-text-table-column>
    </ng-container>
    
    <ng-container *ngSwitchCase="PosTerminalOrderBy.MerchantName">
        <lib-text-table-column [link]="'/dashboard/organizations/' + element.merchantId">
            {{ element.merchantName }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PosTerminalOrderBy.Location">
        <lib-text-table-column>
            {{ element.posConfig?.deviceLocation }}
        </lib-text-table-column>
    </ng-container>

    <ng-container *ngSwitchCase="PosTerminalOrderBy.Status">
        <button mat-stroked-button
                class="status-button"
                [matMenuTriggerFor]="statusMenu"
                [disabled]="isDeviceBusy()">
            <div class="button-inner">
                <div>
                    <span *ngIf="isDeviceBusy(); else notBusy">{{ getBusyText() }}</span>
                    <ng-template #notBusy>
                        <lib-status-chip
                            class="device-statuses"
                            [status]="element.posConfig?.status">
                        </lib-status-chip>
                    </ng-template>
                </div>
                <div class="button-icon"><i [class]="'fas fa-caret-down fa-lg'"></i></div>
            </div>
        </button>
        <mat-menu #statusMenu="matMenu" xPosition="before">
            <ng-container *ngFor="let deviceStatus of deviceStatuses">
                <button mat-menu-item (click)="onUpdateDeviceStatus(deviceStatus)"
                    [ngClass]="{'hide-menu-option': (deviceStatus === element.posConfig?.status) || (deviceStatus === PosTerminalStatusEnum.Reassigned) }">
                    <lib-status-chip
                        class="device-statuses"
                        [status]="deviceStatus">
                    </lib-status-chip>
                </button>
            </ng-container>
        </mat-menu>
    </ng-container>

    <ng-container *ngSwitchDefault>
    </ng-container>

</ng-container>
