/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DigitalWalletKeyVersionDto } from '../models/digital-wallet-key-version-dto';

@Injectable({
  providedIn: 'root',
})
export class AppleMerchantIdentityCertificateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation appleMerchantIdentityCertificateGetCsr
   */
  static readonly AppleMerchantIdentityCertificateGetCsrPath = '/v1/admin/apple-merchant-identity-certificate/get-csr';

  /**
   * Returns generated CSR for apple developer panel.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleMerchantIdentityCertificateGetCsr()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleMerchantIdentityCertificateGetCsr$Response(params?: {
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, AppleMerchantIdentityCertificateService.AppleMerchantIdentityCertificateGetCsrPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Returns generated CSR for apple developer panel.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appleMerchantIdentityCertificateGetCsr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleMerchantIdentityCertificateGetCsr(params?: {
  }): Observable<Blob> {

    return this.appleMerchantIdentityCertificateGetCsr$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation appleMerchantIdentityCertificateUploadCertificate
   */
  static readonly AppleMerchantIdentityCertificateUploadCertificatePath = '/v1/admin/apple-merchant-identity-certificate/upload-certificate';

  /**
   * Uploads merchant identity certificate generated by Apple on CSR.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleMerchantIdentityCertificateUploadCertificate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appleMerchantIdentityCertificateUploadCertificate$Response(params?: {
    body?: {
'pkcsFile'?: Blob | null;
}
  }): Observable<StrictHttpResponse<DigitalWalletKeyVersionDto>> {

    const rb = new RequestBuilder(this.rootUrl, AppleMerchantIdentityCertificateService.AppleMerchantIdentityCertificateUploadCertificatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletKeyVersionDto>;
      })
    );
  }

  /**
   * Uploads merchant identity certificate generated by Apple on CSR.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appleMerchantIdentityCertificateUploadCertificate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  appleMerchantIdentityCertificateUploadCertificate(params?: {
    body?: {
'pkcsFile'?: Blob | null;
}
  }): Observable<DigitalWalletKeyVersionDto> {

    return this.appleMerchantIdentityCertificateUploadCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletKeyVersionDto>) => r.body as DigitalWalletKeyVersionDto)
    );
  }

  /**
   * Path part for operation appleMerchantIdentityCertificateRevokeProcessingCertificate
   */
  static readonly AppleMerchantIdentityCertificateRevokeProcessingCertificatePath = '/v1/admin/apple-merchant-identity-certificate/revoke-processing-certificate/{token}';

  /**
   * Revokes merchant identity certificate by token identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleMerchantIdentityCertificateRevokeProcessingCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleMerchantIdentityCertificateRevokeProcessingCertificate$Response(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<StrictHttpResponse<DigitalWalletKeyVersionDto>> {

    const rb = new RequestBuilder(this.rootUrl, AppleMerchantIdentityCertificateService.AppleMerchantIdentityCertificateRevokeProcessingCertificatePath, 'post');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DigitalWalletKeyVersionDto>;
      })
    );
  }

  /**
   * Revokes merchant identity certificate by token identifier.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appleMerchantIdentityCertificateRevokeProcessingCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleMerchantIdentityCertificateRevokeProcessingCertificate(params: {

    /**
     * Certificate&#x27;s token identifier
     */
    token: string;
  }): Observable<DigitalWalletKeyVersionDto> {

    return this.appleMerchantIdentityCertificateRevokeProcessingCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<DigitalWalletKeyVersionDto>) => r.body as DigitalWalletKeyVersionDto)
    );
  }

  /**
   * Path part for operation appleMerchantIdentityCertificateGetMerchantIdentityCertificates
   */
  static readonly AppleMerchantIdentityCertificateGetMerchantIdentityCertificatesPath = '/v1/admin/apple-merchant-identity-certificate/get-merchant-identity-certificates';

  /**
   * Returns list of active merchant identity certificates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleMerchantIdentityCertificateGetMerchantIdentityCertificates()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleMerchantIdentityCertificateGetMerchantIdentityCertificates$Response(params?: {
  }): Observable<StrictHttpResponse<Array<DigitalWalletKeyVersionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AppleMerchantIdentityCertificateService.AppleMerchantIdentityCertificateGetMerchantIdentityCertificatesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DigitalWalletKeyVersionDto>>;
      })
    );
  }

  /**
   * Returns list of active merchant identity certificates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appleMerchantIdentityCertificateGetMerchantIdentityCertificates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleMerchantIdentityCertificateGetMerchantIdentityCertificates(params?: {
  }): Observable<Array<DigitalWalletKeyVersionDto>> {

    return this.appleMerchantIdentityCertificateGetMerchantIdentityCertificates$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DigitalWalletKeyVersionDto>>) => r.body as Array<DigitalWalletKeyVersionDto>)
    );
  }

}
