import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { LocalTimePoint } from "shared-lib";
import { Filter } from "@admin_app/storage/paypal-events/paypal-events.state";
import { PayPalEventDeliveryStatusEnum } from "@admin_api/models/pay-pal-event-delivery-status-enum";

@Component({
    selector: "app-paypal-events-log-filters",
    templateUrl: "./paypal-events-log-filters.component.html",
    styleUrls: ["./paypal-events-log-filters.component.less"]
})
export class PayPalEventsLogFiltersComponent {

    @Output() filterChanged = new EventEmitter<Filter>();
    @Input() filter: Filter;

    statuses: Array<PayPalEventDeliveryStatusEnum>;

    LocalTimePoint = LocalTimePoint;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.statuses = Object.values(PayPalEventDeliveryStatusEnum);
    }

    filterForm = this.fb.group({
        localDateInterval: [null],
        eventType: [null],
        id: [null],
        status: [null]
    });

    onFilterChanged(filter: Filter) {
        this.filterChanged.emit(filter);
    }

}
