import { AccountMerchantProcessorDto } from "@admin_api/models/account-merchant-processor-dto";
import { MerchantProcessorAccountData } from "@admin_api/models/merchant-processor-account-data";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    GetAccount = "[ACCOUNT] GetAccount",
    GetNewAccount = "[ACCOUNT] GetNewAccount",
    GetAccountSucceed = "[ACCOUNT] GetAccountSucceed",
    GetAccountFail = "[ACCOUNT] GetAccountFail",
    UpdateAccount = "[ACCOUNT] UpdateAccount",
    DeleteAccount = "[ACCOUNT] DeleteAccount",
    CreateAccount = "[ACCOUNT] CreateAccount",
    GetAccountMerchantProcessors = "[ACCOUNT] GetAccountMerchantProcessors",
    GetAccountMerchantProcessorsSucceed = "[ACCOUNT] GetAccountMerchantProcessorsSucceed",
    GetAccountMerchantProcessorsFail = "[ACCOUNT] GetAccountMerchantProcessorsFail"
}

export class GetAccountAction implements Action {

    constructor(
        public id: number
    ) {}

    readonly type = ActionTypes.GetAccount;
}

export class GetNewAccountAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewAccount;
}

export class GetAccountSucceedAction implements Action {

    constructor(
        public account: MerchantProcessorAccountDto,
        public isCreate: boolean
    ) {}

    readonly type = ActionTypes.GetAccountSucceed;
}

export class GetAccountFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetAccountFail;
}

export class UpdateAccountAction implements Action {

    constructor(public id: number, public account: MerchantProcessorAccountData) {}

    readonly type = ActionTypes.UpdateAccount;
}

export class DeleteAccountAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.DeleteAccount;
}

export class CreateAccountAction implements Action {

    constructor(public account: MerchantProcessorAccountData) {}

    readonly type = ActionTypes.CreateAccount;
}

export class GetAccountMerchantProcessorsAction implements Action {

    constructor(public id: number) {}

    readonly type = ActionTypes.GetAccountMerchantProcessors;
}

export class GetAccountMerchantProcessorsSucceedAction implements Action {

    constructor(public merchantProcessors: Array<AccountMerchantProcessorDto>) {}

    readonly type = ActionTypes.GetAccountMerchantProcessorsSucceed;
}

export class GetAccountMerchantProcessorsFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetAccountMerchantProcessorsFail;
}

