import { Action } from "@ngrx/store";
import { CreateMerchantProcessorDto } from "@admin_api/models/create-merchant-processor-dto";
import { MerchantProcessorDetailsDto } from "@admin_api/models/merchant-processor-details-dto";
import { UpdateMerchantProcessorDto } from "@admin_api/models/update-merchant-processor-dto";
import { MerchantProcessorCloverDeviceDto } from "@admin_api/models/merchant-processor-clover-device-dto";

export enum ActionTypes {
    GetMid = "[MID] GetMid",
    GetNewMid = "[MID] GetNewMid",
    GetMidSucceed = "[MID] GetMidSucceed",
    GetMidFail = "[MID] GetMidFail",
    UpdateMid = "[MID] UpdateMid",
    DeleteMid = "[MID] DeleteMid",
    CreateMid = "[MID] CreateMid",
    ConnectClover = "[MID] ConnectClover",
    ResetCloverDevices = "[MID] ResetCloverDevices",
    GetCloverDevices = "[MID] GetCloverDevices",
    GetCloverDevicesSucceed = "[MID] GetCloverDevicesSucceed",
    GetCloverDevicesFail = "[MID] GetCloverDevicesFail",
    ResetReassignDeviceMid = "[MID] ResetReassignDeviceMid",
    GetReassignDeviceMid = "[MID] GetReassignDeviceMid",
    GetReassignDeviceMidSucceed = "[MID] GetReassignDeviceMidSucceed",
    GetReassignDeviceMidFail = "[MID] GetReassignDeviceMidFail",
    ResetEditMidErrors = "[MID] ResetEditMidErrors",
    CreateMidSucceed = "[MID] CreateMidSucceed",
    CreateMidFail = "[MID] CreateMidFail",
    UpdateMidSucceed = "[MID] UpdateMidSucceed",
    UpdateMidFail = "[MID] UpdateMidFail"
}

export class GetMidAction implements Action {

    constructor(
        public id: number, public merchantId: number
    ) {}

    readonly type = ActionTypes.GetMid;
}

export class GetNewMidAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewMid;
}

export class GetMidSucceedAction implements Action {

    constructor(
        public mid: MerchantProcessorDetailsDto
    ) {}

    readonly type = ActionTypes.GetMidSucceed;
}

export class GetMidFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetMidFail;
}

export class UpdateMidAction implements Action {

    constructor(public id: number, public merchantId: number, public mid: UpdateMerchantProcessorDto) {}

    readonly type = ActionTypes.UpdateMid;
}

export class DeleteMidAction implements Action {

    constructor(public id: number, public merchantId: number) {}

    readonly type = ActionTypes.DeleteMid;
}

export class CreateMidAction implements Action {

    constructor(public merchantId: number, public mid: CreateMerchantProcessorDto) {}

    readonly type = ActionTypes.CreateMid;
}

export class ConnectCloverAction implements Action {

    constructor(public id: number, public merchantId: number, public redirectUri: string) {}

    readonly type = ActionTypes.ConnectClover;
}

export class ResetCloverDevicesAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetCloverDevices;
}

export class GetCloverDevicesAction implements Action {

    constructor(public id: number, public merchantId: number) {}

    readonly type = ActionTypes.GetCloverDevices;
}

export class GetCloverDevicesSucceedAction implements Action {

    constructor(public response: MerchantProcessorCloverDeviceDto[]) {}

    readonly type = ActionTypes.GetCloverDevicesSucceed;
}

export class GetCloverDevicesFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetCloverDevicesFail;
}

export class ResetReassignDeviceMidAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetReassignDeviceMid;
}

export class GetReassignDeviceMidAction implements Action {

    constructor(
        public id: number, public merchantId: number
    ) {}

    readonly type = ActionTypes.GetReassignDeviceMid;
}

export class GetReassignDeviceMidSucceedAction implements Action {

    constructor(
        public mid: MerchantProcessorDetailsDto
    ) {}

    readonly type = ActionTypes.GetReassignDeviceMidSucceed;
}

export class GetReassignDeviceMidFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetReassignDeviceMidFail;
}

export class ResetEditMidErrorsAction implements Action {

    constructor() {}

    readonly type = ActionTypes.ResetEditMidErrors;
}

export class CreateMidSucceedAction implements Action {

    constructor() {}

    readonly type = ActionTypes.CreateMidSucceed;
}

export class CreateMidFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.CreateMidFail;
}

export class UpdateMidSucceedAction implements Action {

    constructor() {}

    readonly type = ActionTypes.UpdateMidSucceed;
}

export class UpdateMidFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.UpdateMidFail;
}
