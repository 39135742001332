import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const getInventoryState = (store: IStore) => store.inventory;

export const getInventory = createSelector(
    getInventoryState,
    inventoryState => inventoryState.record,
);

export const getInventoryLoading = createSelector(
    getInventoryState,
    inventoryState => inventoryState.loading,
);

export const getInventoryError = createSelector(
    getInventoryState,
    inventoryState => inventoryState.error,
);

export const getInventorySorting = createSelector(
    getInventoryState,
    inventoryState => inventoryState.sorting,
);

export const getInventoryFilter = createSelector(
    getInventoryState,
    inventoryState => inventoryState.filter,
);

export const getInventoryPager = createSelector(
    getInventoryState,
    inventoryState => inventoryState.pager,
);
