import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { catchError, filter as filterOperator, map, switchMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { Sorting, Filter } from "./organizations.state";
import { Store } from "@ngrx/store";
import { IStore } from "../store";
import { getOrganizationsState } from "./organizations.selectors";
import {
    ActionTypes,
    FilterOrganizationsAction,
    GetOrganizationsAction,
    GetOrganizationsFailAction,
    GetOrganizationsSucceedAction,
    PageChangedOrganizationsAction,
    ResetOrganizationsAction,
    SortOrganizationsAction
} from "./organizations.actions";
import { OrganizationsService } from "@admin_api/services/organizations.service";
import { OrganizationHierarchyModeEnum } from "@admin_api/models/organization-hierarchy-mode-enum";

@Injectable()
export class OrganizationsEffects {

    constructor(
        private actions$: Actions,
        private organizationsService: OrganizationsService,
        private store: Store<IStore>
    ) {}

    initOrganizationsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filterOperator((action: RouterNavigatedAction) => /\/dashboard\/organizations/g.test(action.payload.routerState.url) &&
                !(/\/dashboard\/organizations\//g.test(action.payload.routerState.url))),
            switchMap(() => [
                new ResetOrganizationsAction(null, OrganizationHierarchyModeEnum.DirectChildren, false),
                new GetOrganizationsAction(1, undefined, undefined, undefined)
            ])
        )
    );

    sort = createEffect(() =>
        this.actions$.pipe(
            ofType<SortOrganizationsAction>(ActionTypes.SortOrganizations),
            switchMap(action => of(new GetOrganizationsAction(1, undefined, undefined, action.sorting)))
        ),
    );

    filter = createEffect(() =>
        this.actions$.pipe(
            ofType<FilterOrganizationsAction>(ActionTypes.FilterOrganizations),
            switchMap(action => of(new GetOrganizationsAction(1, undefined, action.filter)))
        ),
    );

    pageChanged = createEffect(() =>
        this.actions$.pipe(
            ofType<PageChangedOrganizationsAction>(ActionTypes.PageChangedOrganizations),
            switchMap(action => of(new GetOrganizationsAction(action.page)))
        ),
    );

    getOrganizations$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetOrganizationsAction>(ActionTypes.GetOrganizations),
            withLatestFrom(this.store.select(getOrganizationsState)),
            switchMap(([action, state]) => {

                const filter = action.filter ? action.filter : state.filter;
                const sorting = action.sorting ? action.sorting : state.sorting;
                const pageSize = action.pageSize ? action.pageSize : state.pager.pageSize;
                const params = this.getParams(sorting, filter, pageSize, action.page);

                const stateExtensions = {
                    filter,
                    sorting
                };

                return this.organizationsService.organizationsSearch(params).pipe(
                    switchMap((response) =>
                        of(new GetOrganizationsSucceedAction(response, pageSize, action.page, stateExtensions))),
                    catchError((error) =>
                        of(new GetOrganizationsFailAction(error)))
                );
            })
        ),
    );

    private getParams(sorting: Sorting, filter: Filter, pageSize: number, page: number): any {

        return {
            ...(sorting.orderDirection ? {OrderBy: sorting.orderBy, OrderDirection: sorting.orderDirection} : {}),
            ...{
                Solution: filter.solution,
                Mid: filter.mid,
                HierarchyMode: filter.hierarchyMode,
                HierarchyOrganizationId: filter.hierarchyOrganizationId,
                MerchantsOnly: filter.merchantsOnly ? true : undefined
            },
            "Pager.PageSize": pageSize,
            "Pager.PageIndex": page
        };
    }

}
