/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateUserDto } from '../models/create-user-dto';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { UpdateUserDto } from '../models/update-user-dto';
import { UpdateUserPasswordDto } from '../models/update-user-password-dto';
import { UserDetailsDto } from '../models/user-details-dto';
import { UserDto } from '../models/user-dto';
import { UsersOrderBy } from '../models/users-order-by';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation usersSearch
   */
  static readonly UsersSearchPath = '/v1/admin/organizations/{organizationId}/users';

  /**
   * Get organization's users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearch$Response(params: {
    organizationId: number;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    OrderDirection?: OrderDirectionEnum;
    OrderBy?: UsersOrderBy;
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersSearchPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('OrderBy', params.OrderBy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * Get organization's users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearch(params: {
    organizationId: number;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    OrderDirection?: OrderDirectionEnum;
    OrderBy?: UsersOrderBy;
  }): Observable<Array<UserDto>> {

    return this.usersSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation usersPost
   */
  static readonly UsersPostPath = '/v1/admin/organizations/{organizationId}/users';

  /**
   * Create user for organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost$Response(params: {
    organizationId: number;
    body: CreateUserDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPostPath, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create user for organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost(params: {
    organizationId: number;
    body: CreateUserDto
  }): Observable<string> {

    return this.usersPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation usersSearch2
   */
  static readonly UsersSearch2Path = '/v1/merchants/{organizationId}/users';

  /**
   * Get organization's users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSearch2()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearch2$Response(params: {
    organizationId: number;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    OrderDirection?: OrderDirectionEnum;
    OrderBy?: UsersOrderBy;
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersSearch2Path, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('OrderBy', params.OrderBy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * Get organization's users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersSearch2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersSearch2(params: {
    organizationId: number;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
    OrderDirection?: OrderDirectionEnum;
    OrderBy?: UsersOrderBy;
  }): Observable<Array<UserDto>> {

    return this.usersSearch2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation usersPost2
   */
  static readonly UsersPost2Path = '/v1/merchants/{organizationId}/users';

  /**
   * Create user for organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPost2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost2$Response(params: {
    organizationId: number;
    body: CreateUserDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPost2Path, 'post');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create user for organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersPost2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost2(params: {
    organizationId: number;
    body: CreateUserDto
  }): Observable<string> {

    return this.usersPost2$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation usersGet
   */
  static readonly UsersGetPath = '/v1/admin/organizations/{organizationId}/users/{id}';

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet$Response(params: {
    organizationId: number;
    id: string;
  }): Observable<StrictHttpResponse<UserDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailsDto>;
      })
    );
  }

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet(params: {
    organizationId: number;
    id: string;
  }): Observable<UserDetailsDto> {

    return this.usersGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetailsDto>) => r.body as UserDetailsDto)
    );
  }

  /**
   * Path part for operation usersPut
   */
  static readonly UsersPutPath = '/v1/admin/organizations/{organizationId}/users/{id}';

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPut$Response(params: {
    organizationId: number;
    id: string;
    body: UpdateUserDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPutPath, 'put');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPut(params: {
    organizationId: number;
    id: string;
    body: UpdateUserDto
  }): Observable<void> {

    return this.usersPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersDelete
   */
  static readonly UsersDeletePath = '/v1/admin/organizations/{organizationId}/users/{id}';

  /**
   * Delete organization's user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete$Response(params: {
    organizationId: number;
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersDeletePath, 'delete');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete organization's user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete(params: {
    organizationId: number;
    id: string;
  }): Observable<void> {

    return this.usersDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersGet2
   */
  static readonly UsersGet2Path = '/v1/merchants/{organizationId}/users/{id}';

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGet2()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet2$Response(params: {
    organizationId: number;
    id: string;
  }): Observable<StrictHttpResponse<UserDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGet2Path, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailsDto>;
      })
    );
  }

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGet2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet2(params: {
    organizationId: number;
    id: string;
  }): Observable<UserDetailsDto> {

    return this.usersGet2$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetailsDto>) => r.body as UserDetailsDto)
    );
  }

  /**
   * Path part for operation usersPut2
   */
  static readonly UsersPut2Path = '/v1/merchants/{organizationId}/users/{id}';

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPut2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPut2$Response(params: {
    organizationId: number;
    id: string;
    body: UpdateUserDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPut2Path, 'put');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get organization's user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersPut2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPut2(params: {
    organizationId: number;
    id: string;
    body: UpdateUserDto
  }): Observable<void> {

    return this.usersPut2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersDelete2
   */
  static readonly UsersDelete2Path = '/v1/merchants/{organizationId}/users/{id}';

  /**
   * Delete organization's user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersDelete2()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete2$Response(params: {
    organizationId: number;
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersDelete2Path, 'delete');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete organization's user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersDelete2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete2(params: {
    organizationId: number;
    id: string;
  }): Observable<void> {

    return this.usersDelete2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersUpdatePassword
   */
  static readonly UsersUpdatePasswordPath = '/v1/admin/organizations/{organizationId}/users/{id}/update-password';

  /**
   * Update user's password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdatePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdatePassword$Response(params: {
    organizationId: number;
    id: string;
    body: UpdateUserPasswordDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdatePasswordPath, 'put');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update user's password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersUpdatePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdatePassword(params: {
    organizationId: number;
    id: string;
    body: UpdateUserPasswordDto
  }): Observable<void> {

    return this.usersUpdatePassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation usersUpdatePassword2
   */
  static readonly UsersUpdatePassword2Path = '/v1/merchants/{organizationId}/users/{id}/update-password';

  /**
   * Update user's password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdatePassword2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdatePassword2$Response(params: {
    organizationId: number;
    id: string;
    body: UpdateUserPasswordDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdatePassword2Path, 'put');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update user's password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersUpdatePassword2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdatePassword2(params: {
    organizationId: number;
    id: string;
    body: UpdateUserPasswordDto
  }): Observable<void> {

    return this.usersUpdatePassword2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
