import { Action } from "@ngrx/store";
import { PaymentDetailsDto } from "@admin_api/models/payment-details-dto";
import { TransactionFeesResult } from "@admin_api/models/transaction-fees-result";

export enum ActionTypes {
    GetPaymentDetail = "[PAYMENT] GetPaymentDetail",
    GetPaymentDetailSucceed = "[PAYMENT] GetPaymentDetailSucceed",
    GetPaymentDetailFail = "[PAYMENT] GetPaymentDetailFail",
    GetPaymentFees = "[PAYMENT] GetPaymentFees",
    GetPaymentFeesSucceed = "[PAYMENT] GetPaymentFeesSucceed",
    GetPaymentFeesFail = "[PAYMENT] GetPaymentFeesFail"
}

export class GetPaymentDetailAction implements Action {

  constructor(
    public token: string,
    public getRelations = false
  ) {}

  readonly type = ActionTypes.GetPaymentDetail;
}

export class GetPaymentDetailSucceedAction implements Action {

  constructor(
    public record: PaymentDetailsDto,
    public getRelations = false
  ) {}

  readonly type = ActionTypes.GetPaymentDetailSucceed;
}

export class GetPaymentDetailFailAction implements Action {

    constructor(
      public error: any
    ) {}

    readonly type = ActionTypes.GetPaymentDetailFail;
}

export class GetPaymentFeesAction implements Action {

    constructor(
      public token: string
    ) {}

    readonly type = ActionTypes.GetPaymentFees;
}

export class GetPaymentFeesSucceedAction implements Action {

    constructor(
        public fees: TransactionFeesResult
    ) {}

    readonly type = ActionTypes.GetPaymentFeesSucceed;
}

export class GetPaymentFeesFailAction implements Action {

    constructor(
        public error: any
    ) {}

    readonly type = ActionTypes.GetPaymentFeesFail;
}
