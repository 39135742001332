/* tslint:disable */
/* eslint-disable */
export enum BusinessDocumentType {
  BUSINESS_REGISTRATION_NUMBER = 'BUSINESS_REGISTRATION_NUMBER',
  EMPLOYER_IDENTIFICATION_NUMBER = 'EMPLOYER_IDENTIFICATION_NUMBER',
  VALUE_ADDED_TAX_ID = 'VALUE_ADDED_TAX_ID',
  BUSINESS_NUMBER = 'BUSINESS_NUMBER',
  COMPANY_NUMBER = 'COMPANY_NUMBER',
  CHARITY_NUMBER = 'CHARITY_NUMBER',
  INCORPORATION_NUMBER = 'INCORPORATION_NUMBER',
  REGISTERED_COOPERATIVE_NUMBER = 'REGISTERED_COOPERATIVE_NUMBER'
}
