import { ConfigDto } from "@admin_app/storage/config/config.state";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-roles-table-cell-content",
    templateUrl: "./roles-table-cell-content.component.html",
    styleUrls: ["./roles-table-cell-content.component.less"],
})
export class RolesTableCellContentComponent {
    @Input() columnId: string;
    @Input() element: any;
    @Input() config: ConfigDto;

    getDisplayTextForSolution(): string {
        if (this.element?.solution ==  null) {
            return "GLOBAL";
        }
        let text = "";
        if (this.element?.solution && this.config?.solutionLookup) {
            const found = this.config.solutionLookup.filter(item => (item.id === this.element.solution));
            if (found?.length) {
                text = found[0].name;
            }
        }
        return text;
    }

}
