<app-google-cloud-storage-log
    [dfmFiles]="dfmFiles$ | async"
    [dfmFilesLoading]="dfmFilesLoading$ | async"
    [dfmFilesError]="dfmFilesError$ | async"
    [dfmFilesSorting]="dfmFilesSorting$ | async"
    (dfmFilesSortingChanged)="onDfmFilesSortingChanged($event)"
    [dfmFilesFilter]="dfmFilesFilter$ | async"
    (dfmFilesFilterChanged)="onDfmFilesFilterChanged($event)"
    [dfmFilesPager]="dfmFilesPager$ | async"
    (dfmFilesPageChanged)="onDfmFilesPageChanged($event)"
    (reprocessDfmFile)="onReprocessDfmFile($event)"
    (downloadDfmFile)="onDownloadDfmFile($event)"
    [binFiles]="binFiles$ | async"
    [binFilesLoading]="binFilesLoading$ | async"
    [binFilesError]="binFilesError$ | async"
    [binFilesSorting]="binFilesSorting$ | async"
    (binFilesSortingChanged)="onBinFilesSortingChanged($event)"
    [binFilesFilter]="binFilesFilter$ | async"
    (binFilesFilterChanged)="onBinFilesFilterChanged($event)"
    [binFilesPager]="binFilesPager$ | async"
    (binFilesPageChanged)="onBinFilesPageChanged($event)"
    (reprocessBinFile)="onReprocessBinFile($event)"
    (downloadBinFile)="onDownloadBinFile($event)">
</app-google-cloud-storage-log>
