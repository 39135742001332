import { AdjustmentDto } from "@admin_api/models/adjustment-dto";
import { CreateManualAdjustmentDto } from "@admin_api/models/create-manual-adjustment-dto";
import { Action } from "@ngrx/store";

export enum ActionTypes {
    ResetAdjustment = "[Adjustment] ResetAdjustment",
    GetAdjustment = "[Adjustment] GetAdjustment",
    GetNewAdjustment = "[Adjustment] GetNewAdjustment",
    GetAdjustmentSucceed = "[Adjustment] GetAdjustmentSucceed",
    GetAdjustmentFail = "[Adjustment] GetAdjustmentFail",
    CreateAdjustment = "[Adjustment] CreateAdjustment",
    DeleteAdjustment = "[Adjustment] DeleteAdjustment"
}

export class ResetAdjustmentAction implements Action {

    constructor(
    ) {}

    readonly type = ActionTypes.ResetAdjustment;
}

export class GetAdjustmentAction implements Action {

    constructor(
        public token: string
    ) {}

    readonly type = ActionTypes.GetAdjustment;
}

export class GetNewAdjustmentAction implements Action {

    constructor() {}

    readonly type = ActionTypes.GetNewAdjustment;
}

export class GetAdjustmentSucceedAction implements Action {

    constructor(public adjustment: AdjustmentDto, public isCreate = false) {}

    readonly type = ActionTypes.GetAdjustmentSucceed;
}

export class GetAdjustmentFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetAdjustmentFail;
}

export class CreateAdjustmentAction implements Action {

    constructor(public adjustment: CreateManualAdjustmentDto) {}

    readonly type = ActionTypes.CreateAdjustment;
}

export class DeleteAdjustmentAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.DeleteAdjustment;
}
