import { DfmFileDto } from "@admin_api/models/dfm-file-dto";
import { DfmFileStatusEnum } from "@admin_api/models/dfm-file-status-enum";
import { OrderDirectionEnum } from "@admin_api/models/order-direction-enum";
import { createFilter, DateRange, FilterBase, Pager } from "shared-lib";

export class Sorting {
    orderBy: null | string;
    orderDirection: null | OrderDirectionEnum = OrderDirectionEnum.Desc;
}

export class Filter extends FilterBase<Filter> {
    // NOTE: Must set all params to null or undefined or a specific value (required for undo)

    // localDateInterval is relative to the local time zone (eg. see LocalTimePoint.timeZone)
    // (API endpoints require UTC time so this value will be shifted at send)
    localDateInterval: DateRange = undefined;
    startDate: string = undefined;
    endDate: string = undefined;

    statuses: Array<DfmFileStatusEnum> = undefined;
}

export class DfmFilesState {
    loading = false;
    record: Array<DfmFileDto> = [];
    error: Error = null;
    sorting = new Sorting();
    pager = new Pager();
    filter = createFilter(Filter);
}
