import { ModalActionType } from "@admin_app/shared/modal-action";
import { OnDestroy } from "@angular/core";
import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { Subject, Subscription } from "rxjs";
import { ActionButtonKind } from "shared-lib";
import { UploadFileModalAction, UploadFileParams } from "./upload-file-modal-action";

@Component({
    selector: "app-upload-file-modal",
    templateUrl: "./upload-file-modal.component.html",
    styleUrls: ["./upload-file-modal.component.less"]
})
export class UploadFileModalComponent implements OnDestroy {

    get formControls() {
        return this.form && this.form.controls;
    }

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<UploadFileModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            errorMessageSubject: Subject<string>;
            modalActionSubject: Subject<UploadFileModalAction>;
            dialogTitle: string;
            dialogMessage: string;
            fileLabel: string;
            infoTip: string;
            onlyFilesOfType: string;
        }
    ) {}

    private subscriptions = new Subscription();

    private chosenFile: File;

    @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

    ActionButtonKind = ActionButtonKind;

    form = this.fb.group({
        fileField: [null, [Validators.required]]
    });

    getFormControl(name: string): UntypedFormControl {
        return this.formControls[name] as UntypedFormControl;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onMouseDown() {
        this.data.errorMessageSubject.next("");
    }

    onCancel(event?: any) {
        if (event) {
            event.preventDefault();
        }
        this.data.modalActionSubject.next(new UploadFileModalAction(ModalActionType.CANCEL));
    }

    onSubmit() {
        const params: UploadFileParams = {};
        params.file = this.chosenFile;
        this.data.modalActionSubject.next(new UploadFileModalAction(ModalActionType.CONFIRM, params));
    }

    onChooseFile(fileUpload: any) {
        fileUpload.click();
    }

    onFileSelected(event: any) {
        this.chosenFile = event.target.files[0];
        this.getFormControl("fileField").setValue(this.chosenFile.name);
        this.form.updateValueAndValidity();
    }

}
