import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActionButtonKind, ColumnDef, ValidatorsVituCustom, ZipRegex } from "shared-lib";
import { StateEnum } from "@admin_api/models/state-enum";
import { MerchantProcessorAccountDto } from "@admin_api/models/merchant-processor-account-dto";
import { MerchantProcessorAccountData } from "@admin_api/models/merchant-processor-account-data";
import { MerchantProcessorAccountType } from "@admin_api/models/merchant-processor-account-type";
import { AccountMerchantProcessorDto } from "@admin_api/models/account-merchant-processor-dto";

type FormConfig<T> = { [P in keyof T]: any[] };
type FormControls<T> = { [P in keyof T]: AbstractControl };

@Component({
    selector: "app-account-details",
    templateUrl: "./account-details.component.html",
    styleUrls: ["./account-details.component.less"],
})
export class AccountDetailsComponent implements OnChanges {

    @Input() isCreate: boolean;
    @Input() account: MerchantProcessorAccountDto;
    @Input() loading: boolean;

    @Input() accountMerchantProcessors: Array<AccountMerchantProcessorDto>;
    @Input() accountMerchantProcessorsLoading = false;
    @Input() accountMerchantProcessorsError: Error;

    @Output() deleteAccount = new EventEmitter<MerchantProcessorAccountDto>();
    @Output() updateAccount = new EventEmitter<{id: number; account: MerchantProcessorAccountData}>();
    @Output() createAccount = new EventEmitter<MerchantProcessorAccountData>();
    @Output() back = new EventEmitter<void>();

    pageTitle: string;
    submitButtonText: string;

    processorAccountTypes: Array<any> = [];
    states: Array<any> = [];

    ActionButtonKind = ActionButtonKind;

    constructor(
        private fb: UntypedFormBuilder,
    ) {
        this.processorAccountTypes = Object.values(MerchantProcessorAccountType);
        this.states = Object.values(StateEnum);
    }

    private accountFormConfig: FormConfig<any> = {
        accountRef: [null, [Validators.required]],
        name: [null, [Validators.required]],
        description: [null, []],
        companyName: [null, [Validators.required]],
        streetAddress1: [null, [Validators.required]],
        streetAddress2: [null, []],
        city: [null, [Validators.required]],
        state: [null, [Validators.required, ValidatorsVituCustom.selectSingleIntegrity(() => this.states)]],
        zipCode: [null, [Validators.required, Validators.pattern(ZipRegex)]],
        accountNumber: [null, [Validators.required, Validators.pattern("(\\d{1,14})")]],
        routingNumber: [null, [Validators.required, Validators.pattern("(\\d{1,9})")]],
        fein: [null, [Validators.required, Validators.pattern("(\\d{1,9})")]]
    };

    accountForm = this.fb.group(this.accountFormConfig);
    get formControls(): FormControls<any> { return this.accountForm.controls; }

    // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
    accountMidsColumnDefs: ColumnDef[] = [
        { id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
        { id: "mid", title: "MID", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true },
        { id: "organizationName", title: "Organization Name", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true},
        { id: "dba", title: "DBA", flexWidthBasisInPixels: 150, flexWidthGrow: 1, canSort: true },
        { id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
    ];

    ngOnChanges(changes: SimpleChanges) {

        if (changes.isCreate && changes.isCreate.firstChange) {
            this.pageTitle = (this.isCreate ? "New" : "Edit") + " Account";
            this.submitButtonText = (this.isCreate ? "Submit" : "Update");
        }

        if ("account" in changes && this.account) {
            const formConfig: any = {};

            formConfig.accountRef = this.account.id;
            formConfig.name = this.account.name;
            formConfig.description = this.account.description;
            formConfig.companyName = this.account.companyName;
            formConfig.streetAddress1 = this.account.address1;
            formConfig.streetAddress2 = this.account.address2;
            formConfig.city = this.account.city;
            formConfig.state = this.account.state;
            formConfig.zipCode = this.account.zipCode;
            formConfig.accountNumber = this.account.accountNumber;
            formConfig.routingNumber = this.account.routingNumber;
            formConfig.fein = this.account.fein;

            this.accountForm.patchValue(formConfig);
            if (!this.isCreate) {
                this.accountForm.markAllAsTouched();
            }
        }

        // Workaround needed for form using ValidatorsVituCustom:
        // ValidatorsVituCustom need to be run by forcing validation if anything they depend on changes,
        // for example as a result of the allowed values being retrieved asynchronously from server (& arriving late).
        // (TODO : Consolidate use of Angular Forms in shareable form base class which implements our common form
        // behaviour like this as default for all of our forms (instead of having to apply it in multiple places))
        this.validateFormControls(this.accountForm);
    }

    accountMidsLoading() {
        return this.accountMerchantProcessorsLoading;
    }

    accountMidsEmpty() {
        return !this.accountMerchantProcessorsLoading && !this.accountMerchantProcessors.length;
    }

    accountMidsLoaded() {
        return !this.accountMerchantProcessorsLoading && this.accountMerchantProcessors.length;
    }

    onClickDelete() {

        this.deleteAccount.emit(this.account);
    }

    onClickBack() {

        this.back.emit();
    }

    onSubmit() {

        const account: MerchantProcessorAccountData = {};
        account.name = this.accountForm.get("name").value;
        account.description = this.accountForm.get("description").value;
        account.accountNumber = this.accountForm.get("accountNumber").value;
        account.address1 = this.accountForm.get("streetAddress1").value;
        account.address2 = this.accountForm.get("streetAddress2").value;
        account.city = this.accountForm.get("city").value;
        account.companyName = this.accountForm.get("companyName").value;
        account.fein = this.accountForm.get("fein").value;
        account.routingNumber = this.accountForm.get("routingNumber").value;
        account.state = this.accountForm.get("state").value;
        account.zipCode = this.accountForm.get("zipCode").value;

        if (this.isCreate) {
            this.createAccount.emit(account);
        }
        else {
            this.updateAccount.emit({id: this.account.id, account});
        }

    }

    private validateFormControls(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup?.controls).forEach(field => {
            formGroup?.get(field)?.updateValueAndValidity();
        });
    }

}
