import { FeePlanState } from "./fee-plan.state";
import { ActionTypes } from "./fee-plan.actions";

export const FeePlanReducer = (
    state: FeePlanState = new FeePlanState(),
    action: any,
  ): FeePlanState => {

    switch (action.type) {

        case ActionTypes.GetFeePlan:
        case ActionTypes.GetNewFeePlan: {
            return {
                ...state,
                record: null,
                loading: true
            };
        }

        case ActionTypes.GetFeePlanSucceed: {
            return {
                ...state,
                record: action.feePlan,
                error: null,
                loading: false
            };
        }

        case ActionTypes.GetFeePlanFail: {
            return {
                ...state,
                error: action.error,
                loading: false,
                record: null
            };
        }

        case ActionTypes.ResetFeePlanMerchantProcessors: {
            return {
                ...state,
                merchantProcessorsLoading: false,
                merchantProcessors: [],
                merchantProcessorsError: null
            };
        }

        case ActionTypes.GetFeePlanMerchantProcessors: {
            return {
                ...state,
                merchantProcessors: [],
                merchantProcessorsLoading: true
            };
        }

        case ActionTypes.GetFeePlanMerchantProcessorsSucceed: {
            return {
                ...state,
                merchantProcessors: action.merchantProcessors,
                merchantProcessorsLoading: false,
                merchantProcessorsError: null
            };
        }

        case ActionTypes.GetFeePlanMerchantProcessorsFail: {
            return {
                ...state,
                merchantProcessors: [],
                merchantProcessorsError: action.error,
                merchantProcessorsLoading: false
            };
        }

        default: {
            return state;
        }
    }

};
