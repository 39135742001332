/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateGlobalRoleDto } from '../models/create-global-role-dto';
import { GlobalRoleDetailsDto } from '../models/global-role-details-dto';
import { GlobalRoleDto } from '../models/global-role-dto';
import { SolutionEnum } from '../models/solution-enum';
import { UpdateGlobalRoleDto } from '../models/update-global-role-dto';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation rolesSearch
   */
  static readonly RolesSearchPath = '/v1/admin/roles';

  /**
   * Get global roles.
   *
   * Use this method to get existing roles. If solutions are passed returns global roles and solution roles, otherwise
   * global roles only
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  rolesSearch$Response(params?: {
    solutions?: Array<SolutionEnum>;
  }): Observable<StrictHttpResponse<Array<GlobalRoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.RolesSearchPath, 'get');
    if (params) {
      rb.query('solutions', params.solutions, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GlobalRoleDto>>;
      })
    );
  }

  /**
   * Get global roles.
   *
   * Use this method to get existing roles. If solutions are passed returns global roles and solution roles, otherwise
   * global roles only
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rolesSearch(params?: {
    solutions?: Array<SolutionEnum>;
  }): Observable<Array<GlobalRoleDto>> {

    return this.rolesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GlobalRoleDto>>) => r.body as Array<GlobalRoleDto>)
    );
  }

  /**
   * Path part for operation rolesPost
   */
  static readonly RolesPostPath = '/v1/admin/roles';

  /**
   * Create global role.
   *
   * Create global or solution role. Global roles are available for any solutions, solution roles only to a single one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rolesPost$Response(params: {
    body: CreateGlobalRoleDto
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.RolesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create global role.
   *
   * Create global or solution role. Global roles are available for any solutions, solution roles only to a single one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rolesPost(params: {
    body: CreateGlobalRoleDto
  }): Observable<number> {

    return this.rolesPost$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation rolesGet
   */
  static readonly RolesGetPath = '/v1/admin/roles/{id}';

  /**
   * Get global role.
   *
   * If requested role is a solution role, solution must be passed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  rolesGet$Response(params: {
    id: number;
    solution?: SolutionEnum;
  }): Observable<StrictHttpResponse<GlobalRoleDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.RolesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('solution', params.solution, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GlobalRoleDetailsDto>;
      })
    );
  }

  /**
   * Get global role.
   *
   * If requested role is a solution role, solution must be passed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rolesGet(params: {
    id: number;
    solution?: SolutionEnum;
  }): Observable<GlobalRoleDetailsDto> {

    return this.rolesGet$Response(params).pipe(
      map((r: StrictHttpResponse<GlobalRoleDetailsDto>) => r.body as GlobalRoleDetailsDto)
    );
  }

  /**
   * Path part for operation rolesPut
   */
  static readonly RolesPutPath = '/v1/admin/roles/{id}';

  /**
   * Update global role.
   *
   * If updated role is a solution role, solution must be passed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolesPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rolesPut$Response(params: {
    id: number;
    body: UpdateGlobalRoleDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.RolesPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update global role.
   *
   * If updated role is a solution role, solution must be passed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolesPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rolesPut(params: {
    id: number;
    body: UpdateGlobalRoleDto
  }): Observable<void> {

    return this.rolesPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation rolesDelete
   */
  static readonly RolesDeletePath = '/v1/admin/roles/{id}';

  /**
   * Delete role.
   *
   * If deleted role is a solution role, solution must be passed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rolesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  rolesDelete$Response(params: {
    id: number;
    solution?: SolutionEnum;
    concurrencyToken?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RolesService.RolesDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('solution', params.solution, {});
      rb.query('concurrencyToken', params.concurrencyToken, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete role.
   *
   * If deleted role is a solution role, solution must be passed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rolesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rolesDelete(params: {
    id: number;
    solution?: SolutionEnum;
    concurrencyToken?: number;
  }): Observable<void> {

    return this.rolesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
