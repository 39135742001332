import { AdjustmentDto } from "@admin_api/models/adjustment-dto";
import { AdjustmentOrderBy } from "@admin_api/models/adjustment-order-by";
import { OrganizationDto } from "@admin_api/models/organization-dto";;
import { Filter, Sorting } from "@admin_app/storage/adjustments/adjustments.state";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnDef, LocalTimePoint, Pager, TableSorting } from "shared-lib";

@Component({
    selector: "app-adjustments-log",
    templateUrl: "./adjustments-log.component.html",
    styleUrls: ["./adjustments-log.component.less"],
})
export class AdjustmentsLogComponent {

    @Input() adjustments: Array<AdjustmentDto>;
    @Input() adjustmentsLoading = false;
    @Input() adjustmentsError: Error;
    @Input() sorting: Sorting = {
        orderBy: null,
        orderDirection: null
    };
    @Input() pager: Pager;

    @Output() sortingChanged = new EventEmitter<Sorting>();
    @Output() pageChanged = new EventEmitter<number>();

    @Input() filter: Filter;
    @Output() filterChanged = new EventEmitter<Filter>();

    @Input() merchants: OrganizationDto[] = [];
    @Input() merchantsLoading = false;

    readonly columnDefs: ColumnDef[];

    readonly adjustmentDateInfoTip = `Adjustments will be included in the
funding on the adjustment date.`;

    constructor(private router: Router) {
        // NOTE: Optimise table widths against minimum table width (or table can have bottom horizontal scrollbar)
        this.columnDefs = [
            {id: "leftGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0},
            // {id: "token", title: "Adjustment Ref", flexWidthBasisInPixels: 240, flexWidthGrow: 1 },
            {id: AdjustmentOrderBy.CreatedDate, title: `Date Created (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true },
            {id: AdjustmentOrderBy.MID, title: "MID", flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true },
            {id: AdjustmentOrderBy.Reason, title: "Reason", flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true },
            {id: "description", title: "Description", flexWidthBasisInPixels: 130, flexWidthGrow: 3 },
            {id: AdjustmentOrderBy.Amount, title: "Amount", flexWidthBasisInPixels: 100, flexWidthGrow: 1, canSort: true },
            // {id: AdjustmentOrderBy.FundingDeposit, title: "Funding Deposit Ref",
            //     flexWidthBasisInPixels: 130, flexWidthGrow: 1, canSort: true },
            {id: "type", title: "Type", flexWidthBasisInPixels: 90, flexWidthGrow: 1},
            {id: AdjustmentOrderBy.AdjustmentDate, title: `Adjustment Date (${LocalTimePoint.formatZ()})`,
                flexWidthBasisInPixels: 130, flexWidthGrow: 1, infoTip: this.adjustmentDateInfoTip, canSort: true },
            {id: "rightGutter", title: "", flexWidthBasisInPixels: 20, flexWidthGrow: 0}
        ];
    }

    onSortingChanged(sorting: TableSorting) {
        this.sortingChanged.emit({
            orderBy: sorting.orderBy as any,
            orderDirection: sorting.orderDirection
        });
    }

    onRowSelected(token: string) {
        this.router.navigate([`/dashboard/funding/adjustments/${token}`]);
    }

    onClickCreateAdjustment() {
        this.router.navigate([`/dashboard/funding/adjustments/create`]);
    }

}
