/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdjustmentDto } from '../models/adjustment-dto';
import { FundingFee } from '../models/funding-fee';
import { FundingResultDetails } from '../models/funding-result-details';
import { FundingResultsOrderBy } from '../models/funding-results-order-by';
import { FundingStatus } from '../models/funding-status';
import { FundingTransactionOrderBy } from '../models/funding-transaction-order-by';
import { ManualFundingSettlementResult } from '../models/manual-funding-settlement-result';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfFundingResult } from '../models/paged-data-dto-of-funding-result';
import { PagedDataDtoOfFundingTransaction } from '../models/paged-data-dto-of-funding-transaction';
import { ProcessorEnum } from '../models/processor-enum';
import { ResendFundingRequest } from '../models/resend-funding-request';
import { ResentFundingInstruction } from '../models/resent-funding-instruction';
import { SettlementSummaryStatus } from '../models/settlement-summary-status';


/**
 * Funding API.
 */
@Injectable({
  providedIn: 'root',
})
export class FundingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fundingSearchFundingResults
   */
  static readonly FundingSearchFundingResultsPath = '/v1/admin/billing/funding';

  /**
   * Search funding instructions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingSearchFundingResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingResults$Response(params?: {
    MerchantId?: number;
    MerchantProcessorId?: number;
    MID?: string;
    DBA?: string;
    FundingStatuses?: Array<FundingStatus>;
    SettlementStatuses?: Array<SettlementSummaryStatus>;
    OrderBy?: FundingResultsOrderBy;
    FundedStartDate?: string;
    FundedEndDate?: string;
    Processors?: Array<ProcessorEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfFundingResult>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingSearchFundingResultsPath, 'get');
    if (params) {
      rb.query('MerchantId', params.MerchantId, {});
      rb.query('MerchantProcessorId', params.MerchantProcessorId, {});
      rb.query('MID', params.MID, {});
      rb.query('DBA', params.DBA, {});
      rb.query('FundingStatuses', params.FundingStatuses, {"style":"form","explode":true});
      rb.query('SettlementStatuses', params.SettlementStatuses, {"style":"form","explode":true});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('FundedStartDate', params.FundedStartDate, {});
      rb.query('FundedEndDate', params.FundedEndDate, {});
      rb.query('Processors', params.Processors, {"style":"form","explode":true});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfFundingResult>;
      })
    );
  }

  /**
   * Search funding instructions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingSearchFundingResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingResults(params?: {
    MerchantId?: number;
    MerchantProcessorId?: number;
    MID?: string;
    DBA?: string;
    FundingStatuses?: Array<FundingStatus>;
    SettlementStatuses?: Array<SettlementSummaryStatus>;
    OrderBy?: FundingResultsOrderBy;
    FundedStartDate?: string;
    FundedEndDate?: string;
    Processors?: Array<ProcessorEnum>;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfFundingResult> {

    return this.fundingSearchFundingResults$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfFundingResult>) => r.body as PagedDataDtoOfFundingResult)
    );
  }

  /**
   * Path part for operation fundingManualFundingAll
   */
  static readonly FundingManualFundingAllPath = '/v1/admin/billing/funding';

  /**
   * Manually resend funding instructions using the same MID and Date properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingManualFundingAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fundingManualFundingAll$Response(params: {

    /**
     * List of existing instructions
     */
    body: ResendFundingRequest
  }): Observable<StrictHttpResponse<Array<ResentFundingInstruction>>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingManualFundingAllPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResentFundingInstruction>>;
      })
    );
  }

  /**
   * Manually resend funding instructions using the same MID and Date properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingManualFundingAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fundingManualFundingAll(params: {

    /**
     * List of existing instructions
     */
    body: ResendFundingRequest
  }): Observable<Array<ResentFundingInstruction>> {

    return this.fundingManualFundingAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResentFundingInstruction>>) => r.body as Array<ResentFundingInstruction>)
    );
  }

  /**
   * Path part for operation fundingGetFundingResult
   */
  static readonly FundingGetFundingResultPath = '/v1/admin/billing/funding/{id}';

  /**
   * Get funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingGetFundingResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetFundingResult$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<FundingResultDetails>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingGetFundingResultPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FundingResultDetails>;
      })
    );
  }

  /**
   * Get funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingGetFundingResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetFundingResult(params: {
    id: number;
  }): Observable<FundingResultDetails> {

    return this.fundingGetFundingResult$Response(params).pipe(
      map((r: StrictHttpResponse<FundingResultDetails>) => r.body as FundingResultDetails)
    );
  }

  /**
   * Path part for operation fundingSearchFundingTransactions
   */
  static readonly FundingSearchFundingTransactionsPath = '/v1/admin/billing/funding/{id}/transactions';

  /**
   * Search transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingSearchFundingTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingTransactions$Response(params: {
    id: number;
    FeesOnly?: boolean;
    OrderBy?: FundingTransactionOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfFundingTransaction>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingSearchFundingTransactionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('FeesOnly', params.FeesOnly, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfFundingTransaction>;
      })
    );
  }

  /**
   * Search transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingSearchFundingTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingSearchFundingTransactions(params: {
    id: number;
    FeesOnly?: boolean;
    OrderBy?: FundingTransactionOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfFundingTransaction> {

    return this.fundingSearchFundingTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfFundingTransaction>) => r.body as PagedDataDtoOfFundingTransaction)
    );
  }

  /**
   * Path part for operation fundingExportFundingTransactions
   */
  static readonly FundingExportFundingTransactionsPath = '/v1/admin/billing/funding/{id}/transactions/export';

  /**
   * Export transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingExportFundingTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingExportFundingTransactions$Response(params: {
    id: number;
    feesOnly?: boolean;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingExportFundingTransactionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('feesOnly', params.feesOnly, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export transactions associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingExportFundingTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingExportFundingTransactions(params: {
    id: number;
    feesOnly?: boolean;
  }): Observable<Blob> {

    return this.fundingExportFundingTransactions$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation fundingGetMidFees
   */
  static readonly FundingGetMidFeesPath = '/v1/admin/billing/funding/{id}/mid-fees';

  /**
   * Get MID fees associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingGetMidFees()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetMidFees$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<FundingFee>>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingGetMidFeesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FundingFee>>;
      })
    );
  }

  /**
   * Get MID fees associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingGetMidFees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetMidFees(params: {
    id: number;
  }): Observable<Array<FundingFee>> {

    return this.fundingGetMidFees$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FundingFee>>) => r.body as Array<FundingFee>)
    );
  }

  /**
   * Path part for operation fundingGetAdjustments
   */
  static readonly FundingGetAdjustmentsPath = '/v1/admin/billing/funding/{id}/adjustments';

  /**
   * Get adjustments associated with funding instruction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingGetAdjustments()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetAdjustments$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<AdjustmentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingGetAdjustmentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdjustmentDto>>;
      })
    );
  }

  /**
   * Get adjustments associated with funding instruction.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingGetAdjustments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingGetAdjustments(params: {
    id: number;
  }): Observable<Array<AdjustmentDto>> {

    return this.fundingGetAdjustments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentDto>>) => r.body as Array<AdjustmentDto>)
    );
  }

  /**
   * Path part for operation fundingManualFunding
   */
  static readonly FundingManualFundingPath = '/v1/admin/billing/funding/{originalId}';

  /**
   * Manually resend funding instruction using the same MID and Date properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingManualFunding()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingManualFunding$Response(params: {

    /**
     * Id of the existing instruction
     */
    originalId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingManualFundingPath, 'post');
    if (params) {
      rb.path('originalId', params.originalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Manually resend funding instruction using the same MID and Date properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingManualFunding$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingManualFunding(params: {

    /**
     * Id of the existing instruction
     */
    originalId: number;
  }): Observable<void> {

    return this.fundingManualFunding$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fundingUploadTestSettlementData
   */
  static readonly FundingUploadTestSettlementDataPath = '/v1/admin/billing/funding/upload-test-settlement-data';

  /**
   * Upload test settlement data.
   *
   * Upload file with test settlement data that replaces the data received from Exchange in sandbox
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingUploadTestSettlementData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  fundingUploadTestSettlementData$Response(params?: {
    body?: {
'uploadedFile'?: Blob | null;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingUploadTestSettlementDataPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload test settlement data.
   *
   * Upload file with test settlement data that replaces the data received from Exchange in sandbox
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingUploadTestSettlementData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  fundingUploadTestSettlementData(params?: {
    body?: {
'uploadedFile'?: Blob | null;
}
  }): Observable<void> {

    return this.fundingUploadTestSettlementData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fundingRequestSettlement
   */
  static readonly FundingRequestSettlementPath = '/v1/admin/billing/funding/{id}/request-settlement';

  /**
   * Manually request funding settlement.
   *
   * Request Exchange funding settlement and process it if found
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fundingRequestSettlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingRequestSettlement$Response(params: {
    id: number;
    SettlementDate?: string;
    Amount?: number;
  }): Observable<StrictHttpResponse<ManualFundingSettlementResult>> {

    const rb = new RequestBuilder(this.rootUrl, FundingService.FundingRequestSettlementPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('SettlementDate', params.SettlementDate, {});
      rb.query('Amount', params.Amount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualFundingSettlementResult>;
      })
    );
  }

  /**
   * Manually request funding settlement.
   *
   * Request Exchange funding settlement and process it if found
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fundingRequestSettlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fundingRequestSettlement(params: {
    id: number;
    SettlementDate?: string;
    Amount?: number;
  }): Observable<ManualFundingSettlementResult> {

    return this.fundingRequestSettlement$Response(params).pipe(
      map((r: StrictHttpResponse<ManualFundingSettlementResult>) => r.body as ManualFundingSettlementResult)
    );
  }

}
