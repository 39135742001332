<div class="outer-container">

    <lib-title-bar [crumbs]="[{ 'label': 'Digital Wallets' }, { 'label': 'Apple Pay' }]">
    </lib-title-bar>

    <div class="certificate-title-container">
        <div id="labelMain" class="label">PAYMENT PROCESSING CERTIFICATES</div>
        <div class="title-actions">
            <lib-action-button
                label="Download CSR"
                fontImage="far fa-file-download fa-bg"
                (click)="getPaymentProcessingCsr.emit()">
            </lib-action-button>
            <lib-action-button
                label="Upload Certificate"
                fontImage="far fa-file-upload fa-bg"
                (click)="uploadPaymentProcessingCertificate.emit()">
            </lib-action-button>
        </div>
    </div>

    <div class="payment-processing-certificates-table-container"
        [ngStyle]="{'max-height': paymentProcessingContainerMaxHeightInPixels + 'px'}">

        <lib-table-base
            #paymentProcessingTableComponent
            class="payment-processing-certificates-table"
            [columnDefs]="publicKeyColumnDefs"
            [rowsData]="applePayKeys"
            [rowsDataLoading]="applePayKeysLoading"
            [rowsDataError]="applePayKeysError"
            rowsDataErrorMessage="Error Loading Certificates"
            rowsDataEmptyMessage="No Payment Processing Certificates">

            <ng-template let-element="element" let-columnId="columnId">
                <app-apple-pay-payment-processing-certificates-table-cell-content
                    [columnId]="columnId"
                    [element]="element"
                    (revoke)="revokePaymentProcessingCertificate.emit($event)">
                </app-apple-pay-payment-processing-certificates-table-cell-content>
            </ng-template>
        </lib-table-base>

    </div>

    <div class="certificate-title-container merchant-identity">
        <div id="labelMain" class="label">MERCHANT IDENTITY CERTIFICATES</div>
        <div class="title-actions">
            <lib-action-button
                label="Download CSR"
                fontImage="far fa-file-download fa-bg"
                (click)="getMerchantIdentityCsr.emit()">
            </lib-action-button>
            <lib-action-button
                label="Upload Certificate"
                fontImage="far fa-file-upload fa-bg"
                (click)="uploadMerchantIdentityCertificate.emit()">
            </lib-action-button>
        </div>
    </div>

    <div class="merchant-identity-certificates-table-container"
        [ngStyle]="{'max-height': merchantIdentityContainerMaxHeightInPixels + 'px'}">

        <lib-table-base
            #merchantIdentityTableComponent
            class="merchant-identity-certificates-table"
            [columnDefs]="merchantIdentityColumnDefs"
            [rowsData]="applePayMerchantIdentityKeys"
            [rowsDataLoading]="applePayMerchantIdentityKeysLoading"
            [rowsDataError]="applePayMerchantIdentityKeysError"
            rowsDataErrorMessage="Error Loading Certificates"
            rowsDataEmptyMessage="No Merchant Identity Certificates">

            <ng-template let-element="element" let-columnId="columnId">
                <app-apple-pay-merchant-identity-certificates-table-cell-content
                    [columnId]="columnId"
                    [element]="element"
                    (revoke)="revokeMerchantIdentity.emit($event)">
                </app-apple-pay-merchant-identity-certificates-table-cell-content>
            </ng-template>
        </lib-table-base>
    </div>

</div>
