import { Filter, MerchantHistoryState, Sorting } from "./merchant-history.state";
import { ActionTypes } from "./merchant-history.actions";
import { createFilter, Pager } from "shared-lib";

export const MerchantHistoryReducer = (
    state: MerchantHistoryState = new MerchantHistoryState(),
    action: any,
  ): MerchantHistoryState => {

    switch (action.type) {

        case ActionTypes.ResetMerchantHistory: {
            return {
                ...state,
                loading: false,
                error: null,
                record: [],
                sorting: new Sorting(),
                pager: new Pager(),
                filter: createFilter(Filter)
            };
        }

        case ActionTypes.GetMerchantHistory: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetMerchantHistorySucceed: {
            const morePagesMode = (action.response.totalPages == null);
            const retVal = {
                ...state,
                record: action.response.items,
                pager: {
                    pageSize: action.pageSize,
                    page: action.page,
                    pageCount: morePagesMode ? action.response.morePages : action.response.totalPages,
                    morePagesMode
                },
                loading: false,
                error: null,
                ...action.stateExtensions
            };
            return retVal;
        }

        case ActionTypes.GetMerchantHistoryFail: {
            return {
                ...state,
                record: [],
                loading: false,
                error: action.error
            };
        }

        default: {
            return state;
        }
    }

};
