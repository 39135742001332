import { IStore } from "@admin_app/storage/store";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Sorting as BinFilesSorting, Filter as BinFilesFilter } from "@admin_app/storage/bin-files/bin-files.state";
import { Sorting as DfmFilesSorting, Filter as DfmFilesFilter } from "@admin_app/storage/dfm-files/dfm-files.state";

import * as DfmFilesActions from "@admin_app/storage/dfm-files/dfm-files.actions";
import * as DfmFilesSelectors from "@admin_app/storage/dfm-files/dfm-files.selectors";

import * as BinFilesActions from "@admin_app/storage/bin-files/bin-files.actions";
import * as BinFilesSelectors from "@admin_app/storage/bin-files/bin-files.selectors";

import * as DfmFileActions from "@admin_app/storage/dfm-file/dfm-file.actions";
import * as BinFileActions from "@admin_app/storage/bin-file/bin-file.actions";

@Component({
    selector: "app-google-cloud-storage",
    templateUrl: "./google-cloud-storage.component.html",
    styleUrls: ["./google-cloud-storage.component.less"]
})
export class GoogleCloudStorageComponent {

    dfmFiles$ = this.store.select(DfmFilesSelectors.getDfmFiles);
    dfmFilesLoading$ = this.store.select(DfmFilesSelectors.getDfmFilesLoading);
    dfmFilesError$ = this.store.select(DfmFilesSelectors.getDfmFilesError);

    dfmFilesPager$ = this.store.select(DfmFilesSelectors.getDfmFilesPager);
    dfmFilesSorting$ = this.store.select(DfmFilesSelectors.getDfmFilesSorting);
    dfmFilesFilter$ = this.store.select(DfmFilesSelectors.getDfmFilesFilter);

    binFiles$ = this.store.select(BinFilesSelectors.getBinFiles);
    binFilesLoading$ = this.store.select(BinFilesSelectors.getBinFilesLoading);
    binFilesError$ = this.store.select(BinFilesSelectors.getBinFilesError);

    binFilesPager$ = this.store.select(BinFilesSelectors.getBinFilesPager);
    binFilesSorting$ = this.store.select(BinFilesSelectors.getBinFilesSorting);
    binFilesFilter$ = this.store.select(BinFilesSelectors.getBinFilesFilter);

    constructor(private store: Store<IStore>) {
        this.store.dispatch(new DfmFilesActions.GetDfmFilesAction(1));
        this.store.dispatch(new BinFilesActions.GetBinFilesAction(1));
    }

    onDfmFilesSortingChanged(sorting: DfmFilesSorting) {
        this.store.dispatch(new DfmFilesActions.SortDfmFilesAction(sorting));
    }

    onDfmFilesFilterChanged(filter: DfmFilesFilter) {
        this.store.dispatch(new DfmFilesActions.FilterDfmFilesAction(filter));
    }

    onDfmFilesPageChanged(page: number) {
        this.store.dispatch(new DfmFilesActions.PageChangedDfmFilesAction(page));
    }

    onBinFilesSortingChanged(sorting: BinFilesSorting) {
        this.store.dispatch(new BinFilesActions.SortBinFilesAction(sorting));
    }

    onBinFilesFilterChanged(filter: BinFilesFilter) {
        this.store.dispatch(new BinFilesActions.FilterBinFilesAction(filter));
    }

    onBinFilesPageChanged(page: number) {
        this.store.dispatch(new BinFilesActions.PageChangedBinFilesAction(page));
    }

    onReprocessDfmFile(id: number) {
        this.store.dispatch(new DfmFileActions.ReprocessDfmFileAction(id));
    }

    onDownloadDfmFile(id: number) {
        this.store.dispatch(new DfmFileActions.DownloadDfmFileAction(id));
    }

    onReprocessBinFile(id: number) {
        this.store.dispatch(new BinFileActions.ReprocessBinFileAction(id));
    }

    onDownloadBinFile(id: number) {
        this.store.dispatch(new BinFileActions.DownloadBinFileAction(id));
    }

}
