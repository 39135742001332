import { FeePlanOrderBy } from "@admin_api/models/fee-plan-order-by";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-fee-plans-table-cell-content",
    templateUrl: "./fee-plans-table-cell-content.component.html",
    styleUrls: ["./fee-plans-table-cell-content.component.less"],
})
export class FeePlansTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

    FeePlanOrderBy = FeePlanOrderBy;

    constructor() {}

}
