import { AdjustmentOrderBy } from "@admin_api/models/adjustment-order-by";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-adjustments-table-cell-content",
    templateUrl: "./adjustments-table-cell-content.component.html",
    styleUrls: ["./adjustments-table-cell-content.component.less"],
})
export class AdjustmentsTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;

    AdjustmentOrderBy = AdjustmentOrderBy;

}
