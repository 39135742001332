import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IStore } from "@admin_app/storage/store";
import { ActivatedRoute } from "@angular/router";
import * as UserActions from "@admin_app/storage/user/user.actions";
import * as UserSelectors from "@admin_app/storage/user/user.selectors";
import * as CurrentUserSelectors from "@admin_app/storage/current-user/current-user.selectors";
import * as OrganizationSelectors from "@admin_app/storage/organization/organization.selectors";
import { UserDetailsDto } from "@admin_api/models/user-details-dto";
import { CreateUserDto } from "@admin_api/models/create-user-dto";
import { GoBackAction } from "@admin_app/storage/router/router.actions";

@Component({
    selector: "app-user",
    templateUrl: "./user.component.html",
    styleUrls: ["./user.component.less"]
})
export class UserComponent implements OnInit {

    constructor(
        private store: Store<IStore>,
        private route: ActivatedRoute,
    ) {}

    organization$ = this.store.select(OrganizationSelectors.getOrganizationInfo);
    organizationLoading$ = this.store.select(OrganizationSelectors.getOrganizationLoading);

    user$ = this.store.select(UserSelectors.getUserInfo);
    roleList$ = this.store.select(UserSelectors.getUserRoleList);
    loading$ = this.store.select(UserSelectors.getUserLoading);
    currentUser$ = this.store.select(CurrentUserSelectors.getCurrentUser);

    isCreate: boolean;

    ngOnInit() {

        this.isCreate = !this.route.snapshot.params.id;
        const organizationId = +this.route.snapshot.params.organizationId;

        if (this.isCreate) {
            this.store.dispatch(new UserActions.GetNewUserAction(organizationId));
        }
        else {
            this.store.dispatch(
                new UserActions.GetUserAction(organizationId, this.route.snapshot.params.id)
            );
        }

    }

    updateUser(payload: {organizationId: number; user: UserDetailsDto}) {

        this.store.dispatch(new UserActions.UpdateUserAction(payload.organizationId, payload.user));
    }

    createUser(payload: {organizationId: number; user: CreateUserDto}) {

        this.store.dispatch(new UserActions.CreateUserAction(payload.organizationId, payload.user));
    }

    deleteUser(payload: {organizationId: number; userId: string}) {

        this.store.dispatch(new UserActions.DeleteUserAction(payload.organizationId, payload.userId));
    }

    resetUserPassword(payload: {organizationId: number; userId: string}) {

        this.store.dispatch(new UserActions.ResetUserPasswordAction(payload.organizationId, payload.userId));
    }

    onBack(): void {

        this.store.dispatch(GoBackAction());
    }

}
