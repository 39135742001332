<lib-dashboard-common
    brandIconPath="assets/img/title-icon.svg"
    [brandIconOffsetInPixelsY]="6"
    [userName]="currentUserName$ | async"
    [currentUserPermissions]="currentUserPermissions"
    [sidebarOpen]="sidebarOpen"
    [sidebarItems]="sidebarItems"
    (logout)="onLogout()">
        <router-outlet></router-outlet>
</lib-dashboard-common>
