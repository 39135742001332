import { Action } from "@ngrx/store";

export enum ActionTypes {
    RevokePaymentProcessingCertificateKey = "[GOOGLE_PAY_KEY] RevokePaymentProcessingCertificateKey",
    RegisterKey = "[GOOGLE_PAY_KEY] RegisterKey",
    MakeKeyPrimary = "[GOOGLE_PAY_KEY] MakeKeyPrimary",
    CreateNewKey = "[GOOGLE_PAY_KEY] CreateNewKey",
    GetPublicKeyFile = "[GOOGLE_PAY_KEY] GetPublicKeyFile",
    GetPublicKeyHash = "[GOOGLE_PAY_KEY] GetPublicKeyHash"
}

export class RevokePaymentProcessingCertificateKeyAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.RevokePaymentProcessingCertificateKey;
}

export class RegisterKeyAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.RegisterKey;
}

export class MakeKeyPrimaryAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.MakeKeyPrimary;
}

export class CreateNewKeyAction implements Action {

    constructor() {}

    readonly type = ActionTypes.CreateNewKey;
}

export class GetPublicKeyFileAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.GetPublicKeyFile;
}

export class GetPublicKeyHashAction implements Action {

    constructor(public token: string) {}

    readonly type = ActionTypes.GetPublicKeyHash;
}
