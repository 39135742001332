<div class="outer-container" (mousedown)="onMouseDown()">

    <div mat-dialog-title>
        <div>Reassign Device</div>
        <i class="fal fa-times global-dialog-close-button" (mousedown)="onCancel($event)"></i>
    </div>

    <div mat-dialog-content class="content">

        <form class="form" [formGroup]="reassignDeviceForm" [libVituForm]="reassignDeviceForm" (libVituFormSubmit)="onSubmit()">

            <div class="form-fields-container">

                <lib-vitu-form-field-select
                    id="merchantField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="merchantField"
                    label="Merchant"
                    [options]="merchants"
                    optionLabelKey="name"
                    optionValueKey="id"
                    (selectionChanged)="onMerchantChanged()">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-select
                    id="midField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="midField"
                    label="MID (DBA)"
                    labelEmpty="MID (Choose Merchant with MIDs)"
                    [labelEmptyCondition]="noMids"
                    [options]="mids"
                    optionLabelKey="label"
                    optionValueKey="id"
                    (selectionChanged)="onMidChanged()"
                    [errorOverrides]="[{'customError1': 'Choose MID which has Clover Merchant ID'}]">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-input
                    id="cloverMerchantIdField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="cloverMerchantIdField"
                    label="Clover Merchant ID"
                    [disabled]="true">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    id="maiField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="maiField"
                    label="MAI">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    id="dbaField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="dbaField"
                    label="DBA">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    id="deviceNameField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="deviceNameField"
                    label="Device Name">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    id="deviceLocationField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="deviceLocationField"
                    label="Device Location">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    id="statusField"
                    [formGroup]="reassignDeviceForm"
                    formFieldName="statusField"
                    label="Status"
                    [options]="deviceStatuses"
                    [limitedVerticalSpace]="true"
                    [spaceBetweenCapsOnLabels]="true">
                </lib-vitu-form-field-select>

            </div>

            <lib-modal-error-message *ngIf="data?.errorMessageSubject | async as errorMessage" [message]="errorMessage">
            </lib-modal-error-message>
        
            <lib-vitu-form-buttons>

                <lib-vitu-form-abort-button
                    (onAbort)="onCancel()"
                    [isModal]="true">
                </lib-vitu-form-abort-button>
    
                <lib-vitu-form-submit-button
                    label="Reassign"
                    [neverDisable]="true">
                </lib-vitu-form-submit-button>
    
            </lib-vitu-form-buttons>

        </form>
    </div>

</div>
