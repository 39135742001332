import { ApplePayConfigResponse } from "@admin_api/models/apple-pay-config-response";
import { MerchantTerminalDetailsDto } from "@admin_api/models/merchant-terminal-details-dto";

export class TidState {
    loading = false;
    error = null;
    record: MerchantTerminalDetailsDto = null;
    googlePayMerchantIdLoading = false;
    googlePayMerchantIdError = null;
    googlePayMerchantIdRecord: string = null;
    applePayMerchantIdLoading = false;
    applePayMerchantIdError = null;
    applePayMerchantIdRecord: string = null;
    applePaySyncLoading = false;
    applePaySyncError = null;
    applePaySyncRecord: ApplePayConfigResponse = null;
}
