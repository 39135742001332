import { ActionTypes } from "./apple-pay-keys.actions";
import { ApplePayKeysState } from "./apple-pay-keys.state";

export const ApplePayKeysReducer = (
    state: ApplePayKeysState = new ApplePayKeysState(),
    action: any,
): ApplePayKeysState => {

    switch (action.type) {

        case ActionTypes.GetPaymentProcessingCertificates: {
            return {
                ...state,
                loading: true
            };
        }

        case ActionTypes.GetPaymentProcessingCertificatesSucceed: {
            return {
                ...state,
                record: action.digitalWalletKeyVersionDtos,
                loading: false,
                error: null
            };
        }

        case ActionTypes.GetPaymentProcessingCertificatesFail: {
            return {
                ...state,
                loading: false,
                error: action.error,
                record: null
            };
        }

        case ActionTypes.GetMerchantIdentityKeys: {
            return {
                ...state,
                merchantIdentityLoading: true
            };
        }

        case ActionTypes.GetMerchantIdentityKeysSucceed: {
            return {
                ...state,
                merchantIdentityRecord: action.digitalWalletKeyVersionDtos,
                merchantIdentityLoading: false,
                merchantIdentityError: null
            };
        }

        case ActionTypes.GetMerchantIdentityKeysFail: {
            return {
                ...state,
                merchantIdentityLoading: false,
                merchantIdentityError: action.error,
                merchantIdentityRecord: null
            };
        }

        default:
            return state;
    }
};
