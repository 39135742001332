/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CardBrandEnum } from '../models/card-brand-enum';
import { OrderDirectionEnum } from '../models/order-direction-enum';
import { PagedDataDtoOfPaymentMethod } from '../models/paged-data-dto-of-payment-method';
import { PaymentMethod } from '../models/payment-method';
import { PaymentMethodOrderBy } from '../models/payment-method-order-by';
import { PaymentMethodPayment } from '../models/payment-method-payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation paymentMethodsSearch
   */
  static readonly PaymentMethodsSearchPath = '/v1/admin/payment-methods';

  /**
   * Search merchants' payment methods.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsSearch$Response(params?: {
    TID?: string;
    MID?: string;
    Token?: string;
    Last4?: number;
    First6?: number;
    CardBrands?: Array<CardBrandEnum>;
    IsExpired?: boolean;
    IsDisabled?: boolean;
    PayerName?: string;
    OrderBy?: PaymentMethodOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<StrictHttpResponse<PagedDataDtoOfPaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsSearchPath, 'get');
    if (params) {
      rb.query('TID', params.TID, {});
      rb.query('MID', params.MID, {});
      rb.query('Token', params.Token, {});
      rb.query('Last4', params.Last4, {});
      rb.query('First6', params.First6, {});
      rb.query('CardBrands', params.CardBrands, {"style":"form","explode":true});
      rb.query('IsExpired', params.IsExpired, {});
      rb.query('IsDisabled', params.IsDisabled, {});
      rb.query('PayerName', params.PayerName, {});
      rb.query('OrderBy', params.OrderBy, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('OrderDirection', params.OrderDirection, {});
      rb.query('Pager.PageSize', params['Pager.PageSize'], {});
      rb.query('Pager.PageIndex', params['Pager.PageIndex'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedDataDtoOfPaymentMethod>;
      })
    );
  }

  /**
   * Search merchants' payment methods.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsSearch(params?: {
    TID?: string;
    MID?: string;
    Token?: string;
    Last4?: number;
    First6?: number;
    CardBrands?: Array<CardBrandEnum>;
    IsExpired?: boolean;
    IsDisabled?: boolean;
    PayerName?: string;
    OrderBy?: PaymentMethodOrderBy;
    StartDate?: string;
    EndDate?: string;
    OrderDirection?: OrderDirectionEnum;
    'Pager.PageSize'?: number;
    'Pager.PageIndex'?: number;
  }): Observable<PagedDataDtoOfPaymentMethod> {

    return this.paymentMethodsSearch$Response(params).pipe(
      map((r: StrictHttpResponse<PagedDataDtoOfPaymentMethod>) => r.body as PagedDataDtoOfPaymentMethod)
    );
  }

  /**
   * Path part for operation paymentMethodsGet
   */
  static readonly PaymentMethodsGetPath = '/v1/admin/payment-methods/{token}';

  /**
   * Get merchants' payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsGet$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsGetPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Get merchants' payment method.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsGet(params: {
    token: string;
  }): Observable<PaymentMethod> {

    return this.paymentMethodsGet$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Path part for operation paymentMethodsGetHistory
   */
  static readonly PaymentMethodsGetHistoryPath = '/v1/admin/payment-methods/{token}/history';

  /**
   * Returns the list of payments create with the payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsGetHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsGetHistory$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<Array<PaymentMethodPayment>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsGetHistoryPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentMethodPayment>>;
      })
    );
  }

  /**
   * Returns the list of payments create with the payment method.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsGetHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsGetHistory(params: {
    token: string;
  }): Observable<Array<PaymentMethodPayment>> {

    return this.paymentMethodsGetHistory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodPayment>>) => r.body as Array<PaymentMethodPayment>)
    );
  }

}
