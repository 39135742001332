<div class="outer-container">

    <lib-title-bar
        [crumbs]="[{ 'label': 'Organizations' , 'link': '/dashboard/organizations' }, { 'label': merchant?.name ? merchant.name : '---' , 'link': '/dashboard/organizations/' + organizationId + '/tids' }, { 'label': pageTitle }]">
    </lib-title-bar>

    <div class="details-container">

        <ng-container *ngIf="!tid then spinner"></ng-container>
        <ng-container *ngIf="tid then formFields"></ng-container>

    </div>

</div>

<ng-template #formFields>

    <form [formGroup]="tidForm" class="form" #vituForm=vituForm
        [libVituForm]="tidForm" (libVituFormSubmit)="onSubmit()">

        <div class="field-lines">

            <div *ngIf="tidDeleted" id="tidDeletedMessage">
                THIS TID WAS DELETED @ {{ DateTimeLabelUtils.ensureTimeZone(tid?.deletedUtc) | date: 'MM/dd/yyyy&nbsp;h:mm:ss&nbsp;a' : LocalTimePoint.formatZ() }} ({{ LocalTimePoint.formatZ() }})
            </div>

            <div class="label">MAIN</div>

            <lib-vitu-form-field-select
                [formGroup]="tidForm"
                formFieldName="COMMON_MID"
                label="MID (DBA)"
                [options]="mids"
                optionLabelKey="label"
                optionValueKey="mid"
                [disabled]="isFieldDisabled('COMMON_MID')"
                (selectionChanged)="onMidChanged()"
                [errorOverrides]="[{'midInvalidForGateway': 'MID invalid for Gateway'}]">
            </lib-vitu-form-field-select>

            <lib-vitu-form-field-select
                [formGroup]="tidForm"
                formFieldName="COMMON_GATEWAY"
                label="Gateway"
                [options]="gateways"
                [disabled]="isFieldDisabled('COMMON_GATEWAY')"
                (selectionChanged)="onGatewayChanged()"
                [errorOverrides]="[{'midInvalidForGateway': 'Gateway invalid for MID'}]">
            </lib-vitu-form-field-select>

            <ng-container *ngIf="gateway === GatewayEnum.CloverEcomm">

                <lib-vitu-input
                    id="fieldCloverEcommCloverMerchantId"
                    label="Clover Merchant ID"
                    [value]="cloverMID"
                    [readonly]="true">
                </lib-vitu-input>

                <lib-vitu-form-field-input
                    id="fieldCloverEcommTid"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_ECOMM_TID"
                    label="TID"
                    [disabled]="isFieldDisabled('CLOVER_ECOMM_TID')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_ECOMM_MAI"
                    label="MAI"
                    [disabled]="isFieldDisabled('CLOVER_ECOMM_MAI')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    *ngIf="!merchantProcessorIsServiceFee"
                    id="fieldCloverEcommTermsAndConditionsUrl"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_ECOMM_TERMS_AND_CONDITIONS_URL"
                    label="Terms and Conditions URL"
                    [disabled]="isFieldDisabled('CLOVER_ECOMM_TERMS_AND_CONDITIONS_URL')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    *ngIf="!merchantProcessorIsServiceFee"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_ECOMM_SERVICE_CONTACT_NUMBER"
                    label="Service Contact Number"
                    [disabled]="isFieldDisabled('CLOVER_ECOMM_SERVICE_CONTACT_NUMBER')"
                    type="telephone">
                </lib-vitu-form-field-input>

            </ng-container>

            <ng-container *ngIf="gateway === GatewayEnum.CloverPos">

                <lib-vitu-input
                    label="Clover Merchant ID"
                    [value]="cloverMID"
                    [readonly]="true">
                </lib-vitu-input>

                <div></div>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_SERIAL"
                    label="Device Serial"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_SERIAL')"
                    [maxlength]="14"
                    [errorOverrides]="[{'pattern': 'Requires 14 alphanumeric chars'}]">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    *ngIf="isCreate"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_MANUFACTURER"
                    label="Device Manufacturer"
                    [options]="deviceManufacturers"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_MANUFACTURER')">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-input
                    *ngIf="!isCreate"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_MANUFACTURER"
                    label="Device Manufacturer"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_MANUFACTURER')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    *ngIf="isCreate"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_MODEL"
                    label="Device Model"
                    [options]="deviceModels"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_MODEL')">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-input
                    *ngIf="!isCreate"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_MODEL"
                    label="Device Model"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_MODEL')">
                </lib-vitu-form-field-input>

                <div></div>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_NAME"
                    label="Device Name"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_NAME')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_LOCATION"
                    label="Device Location"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_LOCATION')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-select
                    *ngIf="!isCreate"
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_STATUS"
                    label="Status"
                    [options]="cloverPosStatuses"
                    [disabled]="isFieldDisabled('CLOVER_POS_STATUS')"
                    [spaceBetweenCapsOnLabels]="true">
                </lib-vitu-form-field-select>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_MAI"
                    label="MAI"
                    [disabled]="isFieldDisabled('CLOVER_POS_MAI')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="CLOVER_POS_DEVICE_ACTIVATION_CODE"
                    label="Device Activation Code"
                    [disabled]="isFieldDisabled('CLOVER_POS_DEVICE_ACTIVATION_CODE')"
                    [errorOverrides]="[{'pattern': 'Up to 8 numeric digits only'}]">
                </lib-vitu-form-field-input>

            </ng-container>

            <ng-container *ngIf="gateway === GatewayEnum.RC">

                <lib-vitu-form-field-input
                    id="fieldRcTid"
                    [formGroup]="tidForm"
                    formFieldName="RC_TID"
                    label="TID"
                    [disabled]="isFieldDisabled('RC_TID')"
                    [maxlength]="8"
                    [errorOverrides]="[{'pattern': 'Up to 8 alphanumeric chars only'}]">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="RC_MAI"
                    label="MAI"
                    [disabled]="isFieldDisabled('RC_MAI')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    id="fieldRcDatawireId"
                    #dataWireIdInput
                    [formGroup]="tidForm"
                    formFieldName="RC_DATAWIRE_ID"
                    label="Datawire ID"
                    [disabled]="isFieldDisabled('RC_DATAWIRE_ID')"
                    type="obscured"
                    [(showObscured)]="showDatawireId">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-switch
                    formControlName="RC_REGENERATE_DATAWIRE_ID"
                    label="Request Regenerate Datawire ID"
                    (changed)="onRegenerateDatawireIdToggled($event, dataWireIdInput)">
                </lib-vitu-form-field-switch>

                <lib-vitu-form-field-input
                    id="fieldRcTermsAndConditionsUrl"
                    [formGroup]="tidForm"
                    formFieldName="RC_TERMS_AND_CONDITIONS_URL"
                    label="Terms and Conditions URL"
                    [disabled]="isFieldDisabled('RC_TERMS_AND_CONDITIONS_URL')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="RC_SERVICE_CONTACT_NUMBER"
                    label="Service Contact Number"
                    [disabled]="isFieldDisabled('RC_SERVICE_CONTACT_NUMBER')"
                    type="telephone">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="RC_CLIENT_TIMEOUT_SECONDS"
                    label="Client Timeout (/secs)"
                    [disabled]="isFieldDisabled('RC_CLIENT_TIMEOUT_SECONDS')"
                    type="number">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="RC_GROUP_ID"
                    label="Group ID"
                    [disabled]="isFieldDisabled('RC_GROUP_ID')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    [formGroup]="tidForm"
                    formFieldName="RC_TA_TOKEN_TYPE"
                    label="TransArmor Token Type"
                    [disabled]="isFieldDisabled('RC_TA_TOKEN_TYPE')">
                </lib-vitu-form-field-input>

                <lib-vitu-form-field-input
                    *ngIf="!isCreate"
                    [formGroup]="tidForm"
                    formFieldName="RC_PROJECT"
                    label="Project"
                    [disabled]="isFieldDisabled('RC_PROJECT')">
                </lib-vitu-form-field-input>

                <ng-container *ngIf="!isCreate">

                    <div
                        class="label">
                        GOOGLE PAY
                    </div>

                    <lib-vitu-form-field-switch
                        [ngClass]="{'hide-item': formInitializedWithGoogleMerchantId}"
                        id="fieldRcEnableGooglePay"
                        formControlName="RC_ENABLE_GOOGLE_PAY"
                        label="Enable Google Pay"
                        (changed)="onEnableGooglePayToggled($event)">
                    </lib-vitu-form-field-switch>

                    <lib-vitu-form-field-input
                        [ngClass]="{'hide-item': !googlePayMerchantIdFieldHasValue}"
                        id="fieldRcGooglePayMerchantId"
                        [formGroup]="tidForm"
                        formFieldName="RC_GOOGLE_PAY_MERCHANT_ID"
                        label="Google Pay Merchant ID"
                        [disabled]="isFieldDisabled('RC_GOOGLE_PAY_MERCHANT_ID')"
                        [readonly]="true">
                    </lib-vitu-form-field-input>

                    <div class="label">
                        APPLE PAY
                    </div>

                    <div *ngIf="configErrorNoAppleMerchantIdCert" class="config-error-message">
                        {{ configErrorNoAppleMerchantIdCert }} [<span class="config-error-message-link" [routerLink]="'/dashboard/digital-wallets/apple-pay'">Click here</span>]
                    </div>

                    <lib-vitu-form-field-switch
                        [ngClass]="{'hide-item': (configErrorNoAppleMerchantIdCert || formInitializedWithAppleMerchantId)}"
                        id="fieldRcEnableApplePay"
                        formControlName="RC_ENABLE_APPLE_PAY"
                        label="Enable Apple Pay"
                        (changed)="onEnableApplePayToggled($event)">
                    </lib-vitu-form-field-switch>

                    <div [ngClass]="{'hide-item': !applePayMerchantIdFieldHasValue}" id="fieldRcApplePayRegisteredStatus">
                        <lib-status-chip
                            class="apple-registered-statuses"
                            [status]="applePayRegistered ? 'Registered' : 'Not Registered'">
                        </lib-status-chip>
                    </div>

                    <lib-vitu-form-field-input
                        [ngClass]="{'hide-item': !applePayMerchantIdFieldHasValue}"
                        id="fieldRcApplePayMerchantId"
                        [formGroup]="tidForm"
                        formFieldName="RC_APPLE_PAY_MERCHANT_ID"
                        label="Apple Pay Merchant ID"
                        [disabled]="isFieldDisabled('RC_APPLE_PAY_MERCHANT_ID')"
                        [readonly]="true">
                    </lib-vitu-form-field-input>

                    <lib-vitu-form-field-input
                        [ngClass]="{'hide-item': !applePayMerchantIdFieldHasValue}"
                        [formGroup]="tidForm"
                        formFieldName="RC_APPLE_PAY_MERCHANT_NAME"
                        label="Apple Pay Merchant Name"
                        [disabled]="isFieldDisabled('RC_APPLE_PAY_MERCHANT_NAME')">
                    </lib-vitu-form-field-input>

                    <lib-vitu-form-field-input
                        [ngClass]="{'hide-item': applePayMerchantUrlFieldIsHidden}"
                        [formGroup]="tidForm"
                        formFieldName="RC_APPLE_PAY_MERCHANT_URL"
                        label="Apple Pay Merchant URL"
                        [disabled]="isFieldDisabled('RC_APPLE_PAY_MERCHANT_URL')">
                    </lib-vitu-form-field-input>

                    <ng-container formArrayName="RC_APPLE_PAY_DOMAIN_NAMES">

                        <ng-container *ngFor="let applePayDomainNamesForm of applePayDomainNamesControl.controls; let i = index">

                            <div [ngClass]="{'hide-item': !applePayMerchantIdFieldHasValue, 'first-item': (i === 0)}" class="add-apple-pay-domain-container"
                                [formGroup]="applePayDomainNamesForm" [libVituForm]="applePayDomainNamesForm">

                                <div class="apple-pay-domain-name-input">
                                    <lib-vitu-form-field-input
                                        [formGroup]="applePayDomainNamesForm"
                                        formFieldName="name"
                                        [label]="'Apple Pay Domain Name ' + (i + 1)"
                                        type="domainName"
                                        [disabled]="isApplePayDomainDisabled(i)"
                                        [errorOverrides]="[{'required': (i > 0) ? 'Add value or remove field' : 'Field is required'}]">
                                    </lib-vitu-form-field-input>
                                    <div *ngIf="isApplePayDomainDisabled(i)" class="apple-pay-domain-name-indicators">
                                        <div class="apple-pay-domain-name-indicators-should-be-deleted">
                                            {{ appleDomainShouldBeDeleted(applePayDomainNamesForm) }}
                                        </div>
                                        <div class="apple-pay-domain-name-indicators-not-verified"
                                            [ngClass]="{'apple-pay-domain-name-indicators-verified': isAppleDomainVerifed(applePayDomainNamesForm)}">
                                            {{ isAppleDomainVerifed(applePayDomainNamesForm) ? "Verified" : "Not Verified" }}
                                        </div>
                                    </div>
                                </div>

                                <button *ngIf="showRemoveApplePayDomainButton(i)" mat-icon-button matSuffix color="primary"
                                    class="remove-button"
                                    (click)="onClickRemoveApplePayDomain(i)">
                                    <mat-icon>remove_circle_outline</mat-icon>
                                </button>

                            </div>

                        </ng-container>

                    </ng-container>

                    <div #applePayDomainAdd [ngClass]="{'hide-item': !applePayMerchantIdFieldHasValue}" id="fieldRcApplePayDomainNamesAdd" class="apple-pay-button-container">
                        <lib-action-button
                            *ngIf="editAvailable"
                            label="Add Apple Pay Domain"
                            [kind]="ActionButtonKind.SECONDARY"
                            fontImage="far fa-plus-circle fa-bg"
                            (click)="onClickAddApplePayDomain()">
                        </lib-action-button>
                    </div>

                    <div #applePaySync [ngClass]="{'hide-item': !applePayMerchantIdFieldHasValue}" id="fieldRcApplePaySync" class="apple-pay-button-container">
                        <lib-action-button
                            *ngIf="editAvailable"
                            label="Synchronize Apple Pay Configuration"
                            [kind]="ActionButtonKind.SECONDARY"
                            fontImage="far fa-sync fa-bg"
                            (click)="onClickSyncApplePay(vituForm.dirty)">
                        </lib-action-button>
                    </div>

                    <div [ngClass]="{'hide-item': !applePayMerchantIdFieldHasValue}" id="fieldRcDomainValidationFile" class="apple-pay-button-container">
                        <lib-action-button
                            *ngIf="editAvailable"
                            label="Download Domain Validation File"
                            [kind]="ActionButtonKind.SECONDARY"
                            fontImage="far fa-file-download fa-bg"
                            (click)="onClickGetDomainValidationFile()">
                        </lib-action-button>
                    </div>

                </ng-container>

            </ng-container>

            <ng-container *ngIf="gateway === GatewayEnum.PayPal">

                <lib-vitu-form-field-input
                    id="fieldPayPalTid"
                    [formGroup]="tidForm"
                    formFieldName="PAYPAL_TID"
                    label="TID"
                    [disabled]="isFieldDisabled('PAYPAL_TID')">
                </lib-vitu-form-field-input>

            </ng-container>

        </div>

        <div *ngIf="!isCreate" class="actions-line">

            <div *ngIf="(gateway === GatewayEnum.CloverPos) && !isCreate && deviceNotAssociatedWithCloverMerchant"
                class="extra-message-label-container">
                Device Serial not associated to the Clover Merchant ID. Click 'Reassign Device' to attach to an associated Clover Merchant ID.
            </div>

            <div class="message-spacer"></div>

            <div class="action-buttons">
    
                <lib-action-button
                    *ngIf="!tidDeleted"
                    id="deleteTidButton"
                    class="action-button"
                    label="Delete TID"
                    fontImage="far fa-trash fa-bg"
                    [kind]="ActionButtonKind.ALERT"
                    [disabled]="tidLoading || !tid"
                    (click)="onClickDelete()">
                </lib-action-button>

                <lib-action-button
                    *ngIf="(gateway === GatewayEnum.CloverPos) && editAvailable"
                    id="pairDeviceButton"
                    class="action-button"
                    label="Pair Device"
                    fontImage="far fa-phone-laptop fa-bg"
                    [kind]="ActionButtonKind.SECONDARY"
                    [disabled]="tidLoading || !tid"
                    (click)="onClickPairDevice()">
                </lib-action-button>

                <lib-action-button *ngIf="(gateway === GatewayEnum.CloverPos) && editAvailable"
                    id="reassignDeviceButton"
                    class="action-button"
                    label="Reassign Device"
                    fontImage="far fa-code-branch fa-bg"
                    [kind]="ActionButtonKind.SECONDARY"
                    [disabled]="tidLoading || !tid"
                    (click)="onClickReassignDevice()">
                </lib-action-button>
    
            </div>
    
        </div>

        <lib-vitu-form-buttons class="form-buttons-line">

            <lib-vitu-form-abort-button
                (onAbort)="onCancel()">
            </lib-vitu-form-abort-button>

            <lib-vitu-form-submit-button
                *ngIf="editAvailable"
                [label]="submitButtonText"
                [neverDisable]="isCreate">
            </lib-vitu-form-submit-button>

        </lib-vitu-form-buttons>

    </form>

</ng-template>

<ng-template #spinner>
    <lib-busy-indicator>
    </lib-busy-indicator>
</ng-template>
