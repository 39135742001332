import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { GoBackAction, NavigateAction, PageLoadFailAction } from "../router/router.actions";
import {
    ActionTypes,
    CreateAdjustmentAction,
    DeleteAdjustmentAction,
    GetAdjustmentAction,
    GetAdjustmentFailAction,
    GetAdjustmentSucceedAction,
    GetNewAdjustmentAction
} from "./adjustment.actions";
import { ConfirmationModalComponent, ConfirmationModalData, GlobalSpinnerService,
    LocalTimePoint, VituToastService, VituToastTone } from "shared-lib";
import { BillingService } from "@admin_api/services/billing.service";
import { AdjustmentDto } from "@admin_api/models/adjustment-dto";
import { GenericNoOpAction } from "../generic.actions";
import { MatDialog } from "@angular/material/dialog";


@Injectable()
export class AdjustmentEffects {

    constructor(
        private actions$: Actions,
        private billingService: BillingService,
        private globalSpinner: GlobalSpinnerService,
        private toast: VituToastService,
        private dialog: MatDialog
    ) {}

    getAdjustment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAdjustmentAction>(ActionTypes.GetAdjustment),
            switchMap(action =>
                this.billingService.adjustmentsGet({ token: action.token }).pipe(
                    switchMap(adjustment => of(new GetAdjustmentSucceedAction(adjustment))),
                    catchError((error) => of(new GetAdjustmentFailAction(error)))
                ))
        ),
    );

    getNewAdjustment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetNewAdjustmentAction>(ActionTypes.GetNewAdjustment),
            switchMap((action) => {
                const newAdjustment: AdjustmentDto = {};
                return of(new GetAdjustmentSucceedAction(newAdjustment, true));
            })
        ),
    );

    getAdjustmentFail = createEffect(() =>
        this.actions$.pipe(
            ofType<GetAdjustmentFailAction>(ActionTypes.GetAdjustmentFail),
            switchMap(() => of(PageLoadFailAction()))
        )
    );

    createAdjustment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<CreateAdjustmentAction>(ActionTypes.CreateAdjustment),
            switchMap(({ adjustment }) =>
                this.globalSpinner.apply(
                    this.billingService.adjustmentsPost({
                        body: {
                            ...adjustment,
                            adjustmentDate: LocalTimePoint.convertLocalValueToUtcValue(adjustment.adjustmentDate)
                        }}).pipe(
                            switchMap(() => {
                                this.toast.open(`Adjustment creation success.`, VituToastTone.Positive);
                                return of(NavigateAction({ payload: { path: ["/dashboard/funding/adjustments"] } }));
                            }),
                            catchError((error) => {
                                const messageLines = [`Adjustment creation failed.`];
                                if (error?.message) {
                                    messageLines.push(`${error.message}`);
                                }
                                this.toast.open(messageLines, VituToastTone.Negative);
                                return of(new GenericNoOpAction());
                            })
                        )
                )
            )
        )
    );

    deleteAdjustment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteAdjustmentAction>(ActionTypes.DeleteAdjustment),
            switchMap(({ token }) =>
                this.dialog.open(ConfirmationModalComponent, {
                    data: {
                        title: "Delete Adjustment",
                        subtitle: "Are you sure you want to permanently delete this Adjustment?",
                        confirmButtonText: "Delete"
                    } as ConfirmationModalData
                }).afterClosed().pipe(
                    map((deleteConfirmed: boolean) => ({ token, deleteConfirmed })),
                )),
            filter(({ deleteConfirmed }) => deleteConfirmed),
            switchMap(({ token }) =>
                this.globalSpinner.apply(
                    this.billingService.adjustmentsDelete({ token }).pipe(
                        switchMap(() => {
                            this.toast.open([`Adjustment deleted :`, `${token}`], VituToastTone.Positive);
                            return of(NavigateAction({ payload: { path: ["/dashboard/funding/adjustments"] } }));
                        }),
                        catchError((error) => {
                            const messageLines = [`Adjustment deletion failed :`, `${token}`];
                            if (error?.message) {
                                messageLines.push(`${error.message}`);
                            }
                            this.toast.open(messageLines, VituToastTone.Negative);
                            return of(new GenericNoOpAction());
                        })
                    )
                )
            )
        )
    );

}
