import { ReportFileOrderByEnum } from "@admin_api/models/report-file-order-by-enum";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-paypal-sftp-table-cell-content",
    templateUrl: "./paypal-sftp-table-cell-content.component.html",
    styleUrls: ["./paypal-sftp-table-cell-content.component.less"],
})
export class PayPalSftpTableCellContentComponent {

    @Input() columnId: string;
    @Input() element: any;
    @Output() download = new EventEmitter<number>();
    @Output() reprocess = new EventEmitter<number>();

    ReportFileOrderByEnum = ReportFileOrderByEnum;

    constructor() {}

    onClickReprocess() {
        this.reprocess.emit(this.element.id);
    }

    onClickDownload() {
        this.download.emit(this.element.id);
    }

}
