import { Action } from "@ngrx/store";
import { CurrentUserDto } from "./current-user.state";

export enum ActionTypes {
    GetCurrentUser = "[CurrentUser] GetCurrentUser",
    GetCurrentUserSucceed = "[CurrentUser] GetCurrentUserSucceed",
    GetCurrentUserFail = "[CurrentUser] GetCurrentUserFail"
}

export class GetCurrentUserAction implements Action {

  constructor() {}

  readonly type = ActionTypes.GetCurrentUser;
}

export class GetCurrentUserSucceedAction implements Action {

    constructor(public record: CurrentUserDto) {}

    readonly type = ActionTypes.GetCurrentUserSucceed;
}

export class GetCurrentUserFailAction implements Action {

    constructor(public error: any) {}

    readonly type = ActionTypes.GetCurrentUserFail;
}
