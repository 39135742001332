import { RootStoreConfig } from "@ngrx/store";
import { IStore } from "./store";
import { CurrentUserReducer } from "./current-user/current-user.reducer";
import { CurrentUserState } from "./current-user/current-user.state";
import { InventoryReducer } from "./inventory/inventory.reducers";
import { InventoryState } from "./inventory/inventory.state";
import { RouterReducer } from "./router/router.reducers";
import { ConfigReducer } from "./config/config.reducer";
import { ConfigState } from "./config/config.state";
import { DeviceState } from "./device/device.state";
import { DeviceReducer } from "./device/device.reducers";
import { MidsState } from "./mids/mids.state";
import { MidsReducer } from "./mids/mids.reducers";
import { TidsState } from "./tids/tids.state";
import { TidsReducer } from "./tids/tids.reducers";
import { MidState } from "./mid/mid.state";
import { MidReducer } from "./mid/mid.reducer";
import { FeePlansState } from "./fee-plans/fee-plans.state";
import { FeePlansReducer } from "./fee-plans/fee-plans.reducers";
import { FeePlanState } from "./fee-plan/fee-plan.state";
import { FeePlanReducer } from "./fee-plan/fee-plan.reducer";
import { FeeTypesReducer } from "./fee-types/fee-types.reducers";
import { FeeTypesState } from "./fee-types/fee-types.state";
import { TidState } from "./tid/tid.state";
import { TidReducer } from "./tid/tid.reducer";
import { ReportReducer } from "./report/report.reducers";
import { ReportState } from "./report/report.state";
import { StatementsReducer } from "./statements/statements.reducers";
import { StatementsState } from "./statements/statements.state";
import { UsersState } from "./users/users.state";
import { UserState } from "./user/user.state";
import { UsersReducer } from "./users/users.reducers";
import { UserReducer } from "./user/user.reducer";
import { WebhooksState } from "./webhooks/webhooks.state";
import { WebhooksReducer } from "./webhooks/webhooks.reducers";
import { WebhookReducer } from "./webhook/webhook.reducer";
import { WebhookState } from "./webhook/webhook.state";
import { EventDeliveriesState } from "./event-deliveries/event-deliveries.state";
import { EventDeliveriesReducer } from "./event-deliveries/event-deliveries.reducers";
import { EventDeliveryState } from "./event-delivery/event-delivery.state";
import { EventDeliveryReducer } from "./event-delivery/event-delivery.reducers";
import { FundingState } from "./funding/funding.state";
import { FundingReducer } from "./funding/funding.reducers";
import { FundingItemReducer } from "./funding-item/funding-item.reducers";
import { FundingItemState } from "./funding-item/funding-item.state";
import { DfmFilesState } from "./dfm-files/dfm-files.state";
import { DfmFilesReducer } from "./dfm-files/dfm-files.reducers";
import { BinFilesReducer } from "./bin-files/bin-files.reducers";
import { BinFilesState } from "./bin-files/bin-files.state";
import { DfmFileReducer } from "./dfm-file/dfm-file.reducers";
import { DfmFileState } from "./dfm-file/dfm-file.state";
import { BinFileState } from "./bin-file/bin-file.state";
import { BinFileReducer } from "./bin-file/bin-file.reducers";
import { EventsState } from "./events/events.state";
import { EventsReducer } from "./events/events.reducers";
import { EventState } from "./event/event.state";
import { EventReducer } from "./event/event.reducers";
import { GooglePayKeysReducer } from "./google-pay-keys/google-pay-keys.reducer";
import { GooglePayKeysState } from "./google-pay-keys/google-pay-keys.state";
import { GooglePayKeyReducer } from "./google-pay-key/google-pay-key.reducer";
import { GooglePayKeyState } from "./google-pay-key/google-pay-key.state";
import { PaymentsReducer } from "./payments/payments.reducers";
import { TransactionsReducer } from "./transactions/transactions.reducers";
import { PaymentsState } from "./payments/payments.state";
import { TransactionsState } from "./transactions/transactions.state";
import { PaymentReducer } from "./payment/payment.reducers";
import { TransactionReducer } from "./transaction/transaction.reducers";
import { TransactionSiblingsReducer } from "./transaction-siblings/transaction-siblings.reducers";
import { PaymentState } from "./payment/payment.state";
import { TransactionState } from "./transaction/transaction.state";
import { TransactionSiblingsState } from "./transaction-siblings/transaction-siblings.state";
import { RoleReducer } from "./role/role.reducer";
import { RolesReducer } from "./roles/roles.reducers";
import { RoleState } from "./role/role.state";
import { RolesState } from "./roles/roles.state";
import { AccountsReducer } from "./accounts/accounts.reducers";
import { AccountsState } from "./accounts/accounts.state";
import { AccountReducer } from "./account/account.reducer";
import { AccountState } from "./account/account.state";
import { OrganizationsReducer } from "./organizations/organizations.reducers";
import { OrganizationsState } from "./organizations/organizations.state";
import { OrganizationState } from "./organization/organization.state";
import { OrganizationReducer } from "./organization/organization.reducer";
import { PayPalEventsReducer } from "./paypal-events/paypal-events.reducers";
import { PayPalEventsState } from "./paypal-events/paypal-events.state";
import { PayPalSftpFilesReducer } from "./paypal-sftp-files/paypal-sftp-files.reducers";
import { PayPalSftpFileReducer } from "./paypal-sftp-file/paypal-sftp-file.reducers";
import { PayPalSftpFilesState } from "./paypal-sftp-files/paypal-sftp-files.state";
import { PayPalSftpFileState } from "./paypal-sftp-file/paypal-sftp-file.state";
import { AdjustmentsState } from "./adjustments/adjustments.state";
import { AdjustmentsReducer } from "./adjustments/adjustments.reducers";
import { AdjustmentState } from "./adjustment/adjustment.state";
import { AdjustmentReducer } from "./adjustment/adjustment.reducer";
import { ApplePayKeysReducer } from "./apple-pay-keys/apple-pay-keys.reducer";
import { ApplePayKeysState } from "./apple-pay-keys/apple-pay-keys.state";
import { ApplePayKeyReducer } from "./apple-pay-key/apple-pay-key.reducer";
import { ApplePayKeyState } from "./apple-pay-key/apple-pay-key.state";
import { DisputesState } from "./disputes/disputes.state";
import { DisputesReducer } from "./disputes/disputes.reducers";
import { DisputeState } from "./dispute/dispute.state";
import { DisputeReducer } from "./dispute/dispute.reducers";
import { MerchantHistoryState } from "./merchant-history/merchant-history.state";
import { MerchantHistoryReducer } from "./merchant-history/merchant-history.reducer";

export const ReducerMap: { [key in keyof IStore]: (state: any, action: any) => any } = {
    config: ConfigReducer,
    router: RouterReducer,
    currentUser: CurrentUserReducer,
    inventory: InventoryReducer,
    device: DeviceReducer,
    mids: MidsReducer,
    mid: MidReducer,
    tids: TidsReducer,
    tid: TidReducer,
    feePlans: FeePlansReducer,
    feePlan: FeePlanReducer,
    feeTypes: FeeTypesReducer,
    report: ReportReducer,
    statements: StatementsReducer,
    users: UsersReducer,
    user: UserReducer,
    webhook: WebhookReducer,
    webhooks: WebhooksReducer,
    eventDeliveries: EventDeliveriesReducer,
    eventDelivery: EventDeliveryReducer,
    funding: FundingReducer,
    fundingItem: FundingItemReducer,
    dfmFiles: DfmFilesReducer,
    dfmFile: DfmFileReducer,
    binFiles: BinFilesReducer,
    binFile: BinFileReducer,
    payPalSftpFiles: PayPalSftpFilesReducer,
    payPalSftpFile: PayPalSftpFileReducer,
    events: EventsReducer,
    event: EventReducer,
    payPalEvents: PayPalEventsReducer,
    googlePayKey: GooglePayKeyReducer,
    googlePayKeys: GooglePayKeysReducer,
    applePayKey: ApplePayKeyReducer,
    applePayKeys: ApplePayKeysReducer,
    payments: PaymentsReducer,
    transactions: TransactionsReducer,
    payment: PaymentReducer,
    transaction: TransactionReducer,
    transactionSiblings: TransactionSiblingsReducer,
    role: RoleReducer,
    roles: RolesReducer,
    accounts: AccountsReducer,
    account: AccountReducer,
    organizations: OrganizationsReducer,
    organization: OrganizationReducer,
    adjustments: AdjustmentsReducer,
    adjustment: AdjustmentReducer,
    disputes: DisputesReducer,
    dispute: DisputeReducer,
    merchantHistory: MerchantHistoryReducer
};

export const ReducerConfiguration: RootStoreConfig<IStore> = {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictStateSerializability: false,
    }
};

// for unit tests
export const GetInitialState = (): IStore =>
    ({
        config: new ConfigState(),
        router: {
            state: {
                url: "",
                queryParams: {},
                params: {},
                routerPathSegments: [],
                routerPath: "",
            },
            navigationId: 0,
        },
        currentUser: new CurrentUserState(),
        inventory: new InventoryState(),
        device: new DeviceState(),
        mids: new MidsState(),
        mid: new MidState(),
        tids: new TidsState(),
        tid: new TidState(),
        feePlans: new FeePlansState(),
        feePlan: new FeePlanState(),
        feeTypes: new FeeTypesState(),
        report: new ReportState(),
        statements: new StatementsState(),
        users: new UsersState(),
        user: new UserState(),
        webhook: new WebhookState(),
        webhooks: new WebhooksState(),
        eventDeliveries: new EventDeliveriesState(),
        eventDelivery: new EventDeliveryState(),
        funding: new FundingState(),
        fundingItem: new FundingItemState(),
        dfmFiles: new DfmFilesState(),
        dfmFile: new DfmFileState(),
        binFiles: new BinFilesState(),
        binFile: new BinFileState(),
        payPalSftpFiles: new PayPalSftpFilesState(),
        payPalSftpFile: new PayPalSftpFileState(),
        events: new EventsState(),
        event: new EventState(),
        payPalEvents: new PayPalEventsState(),
        googlePayKey: new GooglePayKeyState(),
        googlePayKeys: new GooglePayKeysState(),
        applePayKey: new ApplePayKeyState(),
        applePayKeys: new ApplePayKeysState(),
        payments: new PaymentsState(),
        transactions: new TransactionsState(),
        payment: new PaymentState(),
        transaction: new TransactionState(),
        transactionSiblings: new TransactionSiblingsState(),
        role: new RoleState(),
        roles: new RolesState(),
        accounts: new AccountsState(),
        account: new AccountState(),
        organizations: new OrganizationsState(),
        organization: new OrganizationState(),
        adjustments: new AdjustmentsState(),
        adjustment: new AdjustmentState(),
        disputes: new DisputesState(),
        dispute: new DisputeState(),
        merchantHistory: new MerchantHistoryState()
    });

