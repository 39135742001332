import { BinFileState } from "./bin-file.state";
import { ActionTypes } from "./bin-file.actions";

export const BinFileReducer = (
    state: BinFileState = new BinFileState(),
    action: any,
): BinFileState => {

    switch (action.type) {
        default:
            return state;
    }
};
