/* tslint:disable */
/* eslint-disable */
export enum DfmFileOrderByEnum {
  Id = 'Id',
  FullName = 'FullName',
  Status = 'Status',
  Owner = 'Owner',
  CreatedUtc = 'CreatedUtc',
  UpdatedUtc = 'UpdatedUtc'
}
